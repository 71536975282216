import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Note } from 'src/app/models/note';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';

@Component({
  selector: 'app-note-button',
  templateUrl: './note-button.component.html',
  styleUrls: ['./note-button.component.scss'],
})
export class NoteButtonComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  insertNotesForm: FormArray;
  notesStatuslist: string[] = [];
  supervisorNotes: Note[];
  disableButtons: boolean = false;
  parentNotesSubject: BehaviorSubject<Note[]> = new BehaviorSubject<Note[]>([]);
  parentNotes: Note[];

  @Input() isAgent: boolean;
  @Input() isAgentSupervisor: boolean;
  @Input() isLabManager: boolean;
  @Input() isLabManagerInternal: boolean;
  @Input() isLabManagerSupervisor: boolean;
  @Input() isSupervisor: boolean;
  @Input() isDirection: boolean;

  @Input()
  set notes(inputNotes: Note[]) {
    this.parentNotesSubject.next(inputNotes);
  }
  get notes(): Note[] {
    return this.parentNotesSubject.getValue();
  }
  @Input() supervisorNotesObs: Observable<Note[]>;
  @Input() idProject: number;
  @Input() tab: string;
  @Input() userRole: string;

  @Output() refreshNotes: EventEmitter<any> = new EventEmitter<any>();
  @Output() refreshSupervisorNotes: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('noteListDialog') noteListDialog: TemplateRef<any>;
  @ViewChild('insertNotesDialog') insertNotesDialog: TemplateRef<any>;

  constructor(
    private dialog: MatDialog,
    private irFormService: IrFormService,
    private fb: FormBuilder
  ) {
    this.insertNotesForm = this.fb.array([]);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.parentNotesSubject.subscribe({
        next: (notes: Note[]) => {
          this.parentNotes = notes;
          if (this.isAgent || this.isLabManager) {
            if (this.parentNotes?.length) {
              this.initializeNotes();
            }
          }
        },
      })
    );
    if (this.isAgentSupervisor || this.isLabManagerSupervisor) {
      this.subscriptions.add(
        this.supervisorNotesObs.subscribe({
          next: (notes: any[]) => {
            this.supervisorNotes = notes[this.tab];
          },
        })
      );
    }
  }

  initializeNotes(): void {
    this.notesStatuslist = [];
    this.parentNotes.forEach((note: Note) => {
      if (note.checked) {
        this.notesStatuslist.push('checked');
      } else {
        this.notesStatuslist.push('unchecked');
      }
    });
  }

  initializeSupervisorNotes(): void {
    this.insertNotesForm.clear();
    this.insertNotesForm.reset();
    this.supervisorNotes?.forEach((note: Note) => {
      this.addNote(note);
    });
  }

  showNoteListDialog(): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '650px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {};
    const dialogRef = this.dialog.open(this.noteListDialog, dialogConfig);
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        const checkedNotes: Note[] = [];
        this.notesStatuslist.forEach((note: string, index: number) => {
          if (note === 'checked') {
            this.parentNotes[index].checked = true;
            checkedNotes.push(this.parentNotes[index]);
          }
        });
        if (checkedNotes.length) {
          this.subscriptions.add(
            this.irFormService
              .checkNote(this.idProject, checkedNotes, true)
              .subscribe({
                complete: () => {
                  this.refreshNotes.emit();
                },
              })
          );
        }
      }
    });
  }

  openInsertNotesDialog(): void {
    this.initializeSupervisorNotes();
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '650px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {};
    this.dialog.open(this.insertNotesDialog, dialogConfig);
  }

  closeInsertNotesDialog(): void {
    this.dialog.closeAll();
  }

  addNote(note?: Note): void {
    this.insertNotesForm.push(
      this.fb.group({
        idProjectNote: [note?.idProjectNote || null],
        riTab: [this.tab],
        description: [note?.description || '', Validators.required],
      })
    );
  }

  removeNote(i: number): void {
    const idNote: number = this.insertNotesForm
      .at(i)
      .get('idProjectNote')?.value;
    if (idNote) {
      this.insertNotesForm.disable();
      this.disableButtons = true;
      this.subscriptions.add(
        this.irFormService.deleteSupervisorNote(idNote, true).subscribe({
          complete: () => {
            this.insertNotesForm.removeAt(i);
            this.insertNotesForm.enable();
            this.disableButtons = false;
            this.refreshSupervisorNotes.emit();
          },
        })
      );
    } else {
      this.insertNotesForm.removeAt(i);
    }
  }

  saveNotes(): void {
    if (this.insertNotesForm.dirty && this.insertNotesForm.valid) {
      this.insertNotesForm.disable();
      this.disableButtons = true;
      this.subscriptions.add(
        this.irFormService
          .createUpdateSupervisorNotes(
            this.idProject,
            this.insertNotesForm.getRawValue(),
            true
          )
          .subscribe({
            complete: () => {
              this.refreshSupervisorNotes.emit();
              this.dialog.closeAll();
              this.insertNotesForm.enable();
              this.disableButtons = false;
            },
          })
      );
    } else {
      if (this.insertNotesForm.valid) {
        this.dialog.closeAll();
      }
    }
  }

  notesStatus(event: MatCheckboxChange, index: number): void {
    if (event.checked) {
      this.notesStatuslist[index] = 'checked';
    } else if (!event.checked) {
      this.notesStatuslist[index] = 'unchecked';
    }
  }
}
