import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'src/app/services/guard/auth.guard.service';
import { NoAuthGuard } from 'src/app/services/guard/noauth.guard.service';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { LayoutComponent } from 'src/app/pages/layout/layout.component';
import { SignupComponent } from 'src/app/pages/signup/signup.component';
import { ResetPasswordComponent } from 'src/app/pages/reset-password/reset-password.component';
import { IrToPdfComponent } from 'src/app/pages/ir-document/ir-to-pdf.component';
import { IrInProgressComponent } from 'src/app/pages/ir-in-progress/ir-in-progress.component';
import { IrToApproveComponent } from 'src/app/pages/ir-to-approve/ir-to-approve.component';
import { IrApprovedComponent } from 'src/app/pages/ir-approved/ir-approved.component';
import { IrRejectedComponent } from 'src/app/pages/ir-rejected/ir-rejected.component';
import { IrToAssignComponent } from 'src/app/pages/ir-to-assign/ir-to-assign.component';
import { IspAssignedComponent } from 'src/app/pages/isp-assigned/isp-assigned.component';
import { UsersManagementComponent } from 'src/app/pages/users-management/users-management.component';
import { ProjectDetailComponent } from 'src/app/pages/project-detail/project-detail.component';
import { IrFormComponent } from 'src/app/pages/ir-form/ir-form.component';
import { IrSentComponent } from 'src/app/pages/ir-sent/ir-sent.component';
import { MeetingsComponent } from 'src/app/pages/meetings/meetings.component';
import { CustomersComponent } from 'src/app/pages/customers/customers.component';
import { CustomerComponent } from 'src/app/pages/customers/customer/customer.component';
import { AccountSettingsComponent } from 'src/app/pages/account-settings/account-settings.component';
import { AllProjectsComponent } from 'src/app/pages/all-projects/all-projects.component';
import { SearchProductComponent } from 'src/app/pages/search-product/search-product.component';
import { ChannelsRegistryComponent } from 'src/app/pages/channels-registry/channels-registry.component';
import { APP_CONTEX, RI_STATUS_DESCRIPTION } from 'src/app/config';
import { CrmComponent } from './pages/crm/crm.component';
import { CrmDetailComponent } from './pages/crm/crm-detail/crm-detail.component';
import { PsFormComponent } from 'src/app/pages/ps-form/ps-form.component';
import { AssignProjectComponent } from 'src/app/pages/assign-project/assign-project.component';
import { CloneProjectComponent } from './components/clone-project/clone-project.component';
import { ContactDealsComponent } from './components/contact-deals/contact-deals.component';
import { CrmBusinessMatchingComponent } from './pages/crm/crm-business-matching/crm-business-matching.component';
import { CrmContactDetailComponent } from './components/crm-contact-detail/crm-contact-detail.component';
import { BusinessOpportunitiesComponent } from './pages/business-opportunities/business-opportunities.component';
import { NoticesBoardComponent } from 'src/app/pages/notices-board/notices-board.component';

export const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [NoAuthGuard] },
  { path: 'signup', component: SignupComponent, canActivate: [NoAuthGuard] },
  {
    path: 'reset',
    component: ResetPasswordComponent,
    canActivate: [NoAuthGuard],
  },
  {
    path: APP_CONTEX,
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: `${APP_CONTEX}dashboard`,
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      // TODO: Chiedere se serve ancora questo link
      // {
      //   path: `${APP_CONTEX}ir-to-pdf/:idProject`,
      //   component: IrToPdfComponent,
      //   canActivate: [AuthGuard],
      // },
      {
        path: `${APP_CONTEX}assessment`,
        component: IrInProgressComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}assessment/:idProject`,
        component: IrFormComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}assessment-readonly/:idProject`,
        component: IrFormComponent,
        canActivate: [AuthGuard],
        data: { readonly: true },
      },
      {
        path: `${APP_CONTEX}ps`,
        component: IrInProgressComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}ps/:idProject`,
        component: IrFormComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}ps-readonly/:idProject`,
        component: IrFormComponent,
        canActivate: [AuthGuard],
        data: { readonly: true },
      },
      {
        path: `${APP_CONTEX}ps-supervision`,
        component: IrInProgressComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}projects`,
        component: IrInProgressComponent, // DIREZIONE
        canActivate: [AuthGuard],
      },
      // TODO: valutare se il link sia deprecato realmente o meno
      // {
      //   path: `${APP_CONTEX}ir-sent`,
      //   component: IrSentComponent,
      //   data: { riStatus: RI_STATUS_DESCRIPTION.RiDelivered },
      //   canActivate: [AuthGuard],
      // },
      {
        path: `${APP_CONTEX}meetings`,
        component: MeetingsComponent,
        canActivate: [AuthGuard],
      },
      // TODO: valutare se il link sia deprecato realmente o meno
      // {
      //   path: `${APP_CONTEX}ir-to-approve`,
      //   component: IrToApproveComponent,
      //   data: { riStatus: RI_STATUS_DESCRIPTION.RiDelivered },
      //   canActivate: [AuthGuard],
      // },
      // TODO: valutare se il link sia deprecato realmente o meno
      // {
      //   path: `${APP_CONTEX}ir-approved`,
      //   component: IrApprovedComponent,
      //   data: { riStatus: RI_STATUS_DESCRIPTION.RiApproved },
      //   canActivate: [AuthGuard],
      // },
      // TODO: valutare se il link sia deprecato realmente o meno
      // {
      //   path: `${APP_CONTEX}ir-rejected`,
      //   component: IrRejectedComponent,
      //   data: { riStatus: RI_STATUS_DESCRIPTION.RiWorking },
      //   canActivate: [AuthGuard],
      // },
      {
        path: `${APP_CONTEX}users-management`,
        component: UsersManagementComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}account`,
        component: AccountSettingsComponent,
        canActivate: [AuthGuard],
      },
      // {
      //   path: `${APP_CONTEX}ir-to-assign`,
      //   component: IrToAssignComponent,                                       // DIREZIONE
      //   canActivate: [AuthGuard],
      // },
      {
        path: `${APP_CONTEX}projects/:idProject`,
        component: ProjectDetailComponent,
        canActivate: [AuthGuard],
      },
      // TODO: valutare se il link sia deprecato realmente o meno
      // {
      //   path: `${APP_CONTEX}isp-sent`,
      //   component: IrSentComponent,
      //   data: { riStatus: RI_STATUS_DESCRIPTION.IspDelivered },
      //   canActivate: [AuthGuard],
      // },
      // TODO: valutare se il link sia deprecato realmente o meno
      // {
      //   path: `${APP_CONTEX}isp-to-approve`,
      //   component: IrToApproveComponent,
      //   data: { riStatus: RI_STATUS_DESCRIPTION.IspDelivered },
      //   canActivate: [AuthGuard],
      // },
      // TODO: valutare se il link sia deprecato realmente o meno
      // {
      //   path: `${APP_CONTEX}isp-approved`,
      //   component: IrApprovedComponent,
      //   /* Il riStatus è stato cambiato per permettere di mostrare gli approvati.
      //   Se in futuro è necessario, valutare come e cosa cambiare */
      //   data: { riStatus: RI_STATUS_DESCRIPTION.IspWorking },
      //   canActivate: [AuthGuard],
      // },
      // TODO: valutare se il link sia deprecato realmente o meno
      // {
      //   path: `${APP_CONTEX}isp-rejected`,
      //   component: IrRejectedComponent,
      //   data: { riStatus: RI_STATUS_DESCRIPTION.IspWorking },
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: `${APP_CONTEX}isp-assigned`,
      //   component: IspAssignedComponent,
      //   data: { riStatus: RI_STATUS_DESCRIPTION.IspWorking },                             // DIREZIONE
      //   canActivate: [AuthGuard],
      // },
      // {
      //   path: `${APP_CONTEX}all-project`,
      //   component: AllProjectsComponent,                                                 // DIREZIONE
      //   canActivate: [AuthGuard],
      // },
      {
        path: `${APP_CONTEX}all-project`,
        component: AllProjectsComponent, // AMMINISTRATORE
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}customers`,
        component: CustomersComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}customers/:idCustomer`,
        component: CustomerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}search-product`,
        component: SearchProductComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}channels-registry`,
        component: ChannelsRegistryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}crm`,
        component: CrmComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}crm-business-matching/:idProject`,
        component: CrmBusinessMatchingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}crm-business-matching-readonly/:idProject`,
        component: CrmBusinessMatchingComponent,
        canActivate: [AuthGuard],
        data: { readonly: true },
      },
      // {
      //   path: `${APP_CONTEX}crm-detail/:idProject/:idCustomer`,
      //   component: CrmDetailComponent,
      //   canActivate: [AuthGuard],
      // },
      {
        path: `${APP_CONTEX}crm/customer/:idCustomer/project/:idProject`,
        component: CrmDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}crm/customer/:idCustomer/project/:idProject/contact/new`,
        component: CrmContactDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}crm/customer/:idCustomer/project/:idProject/contact/:idContact`,
        component: CrmContactDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}crm-detail-readonly/:idProject/:idCustomer`,
        component: CrmDetailComponent,
        canActivate: [AuthGuard],
        data: { readonly: true },
      },
      {
        path: `${APP_CONTEX}ps-form/:idProject`,
        component: PsFormComponent,
        canActivate: [AuthGuard],
        canDeactivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}ps-form-readonly/:idProject/:idCustomer/:idProjectStatus`,
        component: PsFormComponent,
        canActivate: [AuthGuard],
        data: { readonly: true },
      },
      {
        path: `${APP_CONTEX}assign-project/:idProject`,
        component: AssignProjectComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}deals/:idProject/:idCustomer`,
        component: ContactDealsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}deals-readonly/:idProject/:idCustomer`,
        component: ContactDealsComponent,
        canActivate: [AuthGuard],
        data: { readonly: true },
      },
      {
        path: `${APP_CONTEX}clone-project/:idProject`,
        component: CloneProjectComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}business-opportunities`,
        component: BusinessOpportunitiesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}business-opportunities-custom`,
        component: BusinessOpportunitiesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: `${APP_CONTEX}notices-board`,
        component: NoticesBoardComponent,
        canActivate: [AuthGuard],
      },
      { path: `**`, redirectTo: `${APP_CONTEX}dashboard` },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
