<tr class="mat-row">
  <td [colSpan]="columnsName.length">
    <div class="table-row-details-content">
      <p *ngFor="let column of columnsName| slice:detailsColumnsToShowStart:detailsColumnsToShowEnd">
        <span class="mobile-label">test{{ column.name | translate }}</span>
        <span class="mobile-value">{{ element[column.value] | translate }}</span>
      </p>
    </div>
  </td>
</tr>
