<div class="row">
  <div class="col-5">
    <div class="section-card h100">
      <div class="header h70">
        <div class="title">
          <span>{{ "NOTICES_BOARD.NoticesList" | translate }}</span>
        </div>
      </div>
      <div class="content">
        <div 
          class="notice" 
          *ngFor="let notice of notices"
          [ngClass]="notice?.idServiceNotice === selectedNotice?.idServiceNotice ?
          'selected' : ''
          "
        >
          <div class="date">
            {{ notice.creationDate | date: "dd/MM/yyyy - HH:mm" }}
          </div>
          <div class="title">{{ notice.title }}</div>
          <p class="description">{{ notice.description }}</p>
          <button
            type="button"
            class="only-icon-button primary arrow"
            (click)="selectNotice(notice)"
          >
            <mat-icon>arrow_forward</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="section-card h100">
      <div class="header h70">
        <div class="title">
          <span>{{ "NOTICES_BOARD.NoticeDetail" | translate }}</span>
        </div>
      </div>
      <div class="content">
        <div *ngIf="!selectedNotice" class="select-notice-advise mt-30">
          {{ "COMMON.SelectANoticeForSeeDetails" | translate }}
        </div>
        <div *ngIf="selectedNotice" class="notice-detail">
          <div class="date">
            {{ selectedNotice.creationDate | date: "dd/MM/yyyy - HH:mm" }}
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div class="title">{{ selectedNotice.title }}</div>
            <div *ngIf="isDirection" class="d-flex align-items-center">
              <!-- <button
                type="button"
                class="only-icon-button primary"
                (click)="openNoticeDialog('Edit')"
              >
                <svg-icon name="edit_icon" class="w20"></svg-icon>
              </button> -->
              <button
                type="button"
                class="only-icon-button primary ml-20"
                (click)="deleteNotice()"
              >
                <svg-icon name="trash_icon" class="w20"></svg-icon>
              </button>
            </div>
          </div>
          <p class="description">{{ selectedNotice.description }}</p>
          <div
            *ngIf="selectedNotice?.document?.length"
            class="attachments-container"
          >
            <button
              *ngFor="let attach of selectedNotice.document"
              type="button"
              class="attachment only-text-button primary"
              (click)="downloadAttachment(attach)"
            >
              {{ attach.name }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="section-card h100">
      <div *ngIf="isDirection" class="add-content">
        <button
          type="button"
          class="rounded-button primary-bg-button"
          (click)="openNoticeDialog('New')"
        >
          {{ "COMMON.AddNotice" | translate }}
        </button>
      </div>
      <div class="header multi">
        <div class="title">
          <span>{{ "COMMON.Filters" | translate }}</span>
        </div>
        <button
          class="only-text-button primary"
          (click)="resetFilters()"
          [disabled]="lockFilters"
        >
          {{ "COMMON.Reset" | translate | lowercase }}
        </button>
      </div>
      <div class="content">
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label for="descriptionFilter">
              {{ "COMMON.SearchANotice" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              [formControl]="descriptionFilter"
              name="descriptionFilter"
              (keyup)="filterNotices()"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label>
              {{ "COMMON.NoticeDate" | translate }}
            </mat-label>
            <input
              matInput
              readonly
              [formControl]="dateFilter"
              [matDatepicker]="picker"
              (dateChange)="filterNotices()"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noticeDialog let-close="close" let-data>
  <form [formGroup]="noticeForm" (ngSubmit)="saveNotice()">
    <div class="header h70">
      <div class="title">
        <span>
          {{
            data.noticeDialogType === "New"
              ? ("COMMON.NewNotice" | translate)
              : ("COMMON.EditNotice" | translate)
          }}
        </span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        (click)="closeNoticeDialog()"
        [disabled]="lockButtons"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field appearance="standard" class="col">
          <mat-label for="title">{{ "COMMON.Title" | translate }}</mat-label>
          <input matInput type="text" formControlName="title" name="title" />
          <mat-error>
            {{
              noticeForm.get("title").errors
                | getErrorMessage: ("COMMON.Title" | translate)
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col">
          <mat-label for="description">{{
            "COMMON.Description" | translate
          }}</mat-label>
          <textarea
            matInput
            type="text"
            formControlName="description"
            name="description"
          ></textarea>
          <mat-error>
            {{
              noticeForm.get("description").errors
                | getErrorMessage: ("COMMON.Description" | translate)
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <button
        type="button"
        class="rounded-button primary-border-button upload-button"
      >
        <label class="upload-label" for="idAttachment">{{
          "COMMON.AddAttachment" | translate
        }}</label>
        <input
          #attachment
          id="idAttachment"
          type="file"
          (change)="handleFileInput($event.target?.files)"
        />
      </button>
      <div class="mt-20">
        <div
          *ngFor="let attach of attachmentList; let i = index"
          class="attachment"
        >
          <button
            type="button"
            class="only-icon-button primary mr-10"
            (click)="attachmentList.splice(i, 1)"
          >
            <mat-icon>clear</mat-icon>
          </button>
          {{ attach.name }}
        </div>
      </div>
    </div>
    <div *ngIf="!isReadOnly" class="buttons center">
      <button
        *ngIf="noticeDialogType === 'Edit'"
        type="button"
        class="rounded-button error-transparent-bg-button mr-20"
        (click)="deleteNotice()"
        [disabled]="lockButtons"
      >
        {{ "COMMON.Delete" | translate }}
      </button>
      <button
        type="submit"
        class="rounded-button primary-bg-button"
        [disabled]="lockButtons"
      >
        {{ "COMMON.Save" | translate }}
      </button>
    </div>
  </form>
</ng-template>
