<form
  appSrollOnError
  [formGroup]="competitorForm"
  class="ir-form"
  (ngSubmit)="onSubmit()"
>
  <div
    class="ta-center"
    *ngIf="psCompetitor.length === 0 && !isIspDelivered && !isReadOnly"
  >
    <div>
      <span>
        {{ "PS_FORM.ErrorCompetitorPositioning" | translate }}
      </span>
    </div>
    <div class="center-buttons mt-40">
      <button
        type="button"
        class="rounded-button primary-bg-button"
        (click)="goToCrmContactCompetitors()"
      >
        {{ "PS_FORM.GoToPmi" | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="psCompetitor.length" class="section-card">
    <div class="header h70 multi no-green-deco">
      <div></div>
      <div class="search-bar">
        <app-search-bar
          [disabled]="!competitors?.value?.length"
          (emitSearchValue)="filterCompetitorsTable($event)"
        ></app-search-bar>
      </div>
    </div>
    <div
      class="header h70 mt-20 d-block"
      formGroupName="customerInformationMktMixDTO"
    >
      <div class="row" style="margin-left: 0">
        <mat-form-field
          appearance="standard"
          class="col"
          [ngClass]="
            !customerData?.businessName ? 'red-field-for-lab-manager' : ''
          "
        >
          <mat-label for="companyName">{{
            "PS_FORM.CompanyName" | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            name="companyName"
            [value]="customerData?.businessName"
            readonly
          />
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-2"
          [ngClass]="
            !customer.get('product')?.value ? 'red-field-for-lab-manager' : ''
          "
        >
          <mat-label for="product">{{
            "PS_FORM.Product" | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            min="1"
            max="10"
            step="0.01"
            name="product"
            formControlName="product"
          />
          <mat-error>
            {{
              customer.get("product").errors
                | getErrorMessage: translations.Product
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-2"
          [ngClass]="
            !customer.get('distribution')?.value
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="distribution">{{
            "PS_FORM.Distribution" | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            min="1"
            max="10"
            step="0.01"
            name="distribution"
            formControlName="distribution"
          />
          <mat-error>
            {{
              customer.get("distribution").errors
                | getErrorMessage: translations.Distribution
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-2"
          [ngClass]="
            !customer.get('priceSelling')?.value
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="priceSelling">{{
            "PS_FORM.PriceSelling" | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            min="1"
            max="10"
            step="0.01"
            name="priceSelling"
            formControlName="priceSelling"
          />
          <mat-error>
            {{
              customer.get("priceSelling").errors
                | getErrorMessage: translations.PriceSelling
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-2"
          [ngClass]="
            !customer.get('communicationMarketing')?.value
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="communicationMarketing">{{
            "PS_FORM.CommunicationAndMarketing" | translate
          }}</mat-label>
          <input
            matInput
            type="number"
            min="1"
            max="10"
            step="0.01"
            name="communicationMarketing"
            formControlName="communicationMarketing"
          />
          <mat-error>
            {{
              customer.get("communicationMarketing").errors
                | getErrorMessage: translations.CommunicationAndMarketing
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="content" formArrayName="competitorCompanyCrmDTO">
      <ng-container
        *ngFor="let competitor of filteredCompetitors.controls; let i = index"
        [formGroupName]="i"
      >
        <div class="row" style="margin-left: 0">
          <mat-form-field
            appearance="standard"
            class="col"
            [ngClass]="
              !competitor.get('companyName')?.value
                ? 'red-field-for-lab-manager'
                : ''
            "
          >
            <mat-label for="companyName">{{
              "PS_FORM.CompanyName" | translate
            }}</mat-label>
            <mat-checkbox
              matPrefix
              color="primary"
              formControlName="isSelected"
              class="mr-10"
            ></mat-checkbox>
            <input
              matInput
              formControlName="companyName"
              type="text"
              name="companyName"
            />
          </mat-form-field>
          <!-- <mat-form-field
                  appearance="standard"
                  formGroupName="country"
                  class="col-2"
                  [ngClass]="
                    !competitor.get('country.country')?.value
                      ? 'red-field-for-lab-manager'
                      : ''
                  "
                >
                  <mat-label for="country">{{
                    "PS_FORM.Country" | translate
                  }}</mat-label>
                  <input
                    matInput
                    formControlName="country"
                    type="text"
                    name="country"
                  />
                </mat-form-field> -->
          <mat-form-field
            appearance="standard"
            class="col-2"
            [ngClass]="
              !competitor.get('product')?.value
                ? 'red-field-for-lab-manager'
                : ''
            "
          >
            <mat-label class="bold-title" for="product">
              {{ "PS_FORM.Product" | translate }}
            </mat-label>
            <input
              matInput
              formControlName="product"
              type="number"
              min="1"
              max="10"
              step="0.01"
              name="product"
            />
            <mat-error>
              {{
                competitor.get("product").errors
                  | getErrorMessage: translations.Product
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="standard"
            class="col-2"
            [ngClass]="
              !competitor.get('distribution')?.value
                ? 'red-field-for-lab-manager'
                : ''
            "
          >
            <mat-label class="bold-title" for="distribution">
              {{ "PS_FORM.Distribution" | translate }}
            </mat-label>
            <input
              matInput
              formControlName="distribution"
              type="number"
              min="1"
              max="10"
              step="0.01"
              name="distribution"
            />
            <mat-error>
              {{
                competitor.get("distribution").errors
                  | getErrorMessage: translations.Distribution
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="standard"
            class="col-2"
            [ngClass]="
              !competitor.get('priceSelling')?.value
                ? 'red-field-for-lab-manager'
                : ''
            "
          >
            <mat-label class="bold-title" for="priceSelling">
              {{ "PS_FORM.PriceSelling" | translate }}
            </mat-label>
            <input
              matInput
              formControlName="priceSelling"
              type="number"
              min="1"
              max="10"
              step="0.01"
              name="priceSelling"
            />
            <mat-error>
              {{
                competitor.get("priceSelling").errors
                  | getErrorMessage: translations.PriceSelling
              }}
            </mat-error>
          </mat-form-field>
          <mat-form-field
            appearance="standard"
            class="col-2"
            [ngClass]="
              !competitor.get('communicationMarketing')?.value
                ? 'red-field-for-lab-manager'
                : ''
            "
          >
            <mat-label class="bold-title" for="communicationMarketing">
              {{ "PS_FORM.CommunicationAndMarketing" | translate }}
            </mat-label>
            <input
              matInput
              formControlName="communicationMarketing"
              type="number"
              min="1"
              max="10"
              step="0.01"
              name="communicationMarketing"
            />
            <mat-error>
              {{
                competitor.get("communicationMarketing").errors
                  | getErrorMessage: translations.CommunicationAndMarketing
              }}
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
    </div>
  </div>
  <div
    *ngIf="psCompetitor.length && !isIspDelivered && !isReadOnly"
    class="center-buttons mt-30"
  >
    <button type="submit" class="rounded-button primary-bg-button">
      {{ "COMMON.SaveChanges" | translate }}
    </button>
  </div>
</form>
<div class="mt-40">
  <button
    *ngIf="psCompetitor.length > 3"
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
