import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { finalize, map, share } from 'rxjs/operators';
import { UUID } from 'angular2-uuid';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { ToastStatus } from 'src/app/models/utility';
import { FILE_HEADER } from 'src/app/config';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private layoutService: LayoutService) {}

  private cache: Map<any, HttpResponse<unknown>> = new Map();
  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const transactionId = `PPS_TRANS_${UUID.UUID()}`;
    const hideLoader: boolean =
      request.headers.has('hideloader') &&
      request.headers.get('hideloader') === 'true';
    const toCache: boolean =
      request.headers.has('toCache') &&
      request.headers.get('toCache') === 'true';

    // Show the loader only if hideLoader is false
    if (!hideLoader) {
      this.layoutService.triggerLoadersChangedObservable(transactionId, true);
    }

    request = request.clone({
      headers: request.headers
        .set('transactionId', transactionId)
        .delete('hideLoader'),
    });
    if (request.method !== 'GET') {
      return next.handle(request).pipe(
        map((response: HttpEvent<unknown>) => {
          if (response instanceof HttpResponse) {
            if (
              response.status === 202 ||
              response.status === 201 ||
              (response.status === 200 &&
                (request.method.toLowerCase() === 'put' ||
                  request.method.toLowerCase() === 'delete'))
            ) {
              this.layoutService.triggerToastChangedObservable({
                messageKey: 'API_RESPONSE.SuccessOperation',
                status: ToastStatus.success,
                duration: 2500,
              });
            }
          }
          return response;
        }),
        finalize(() => {
          if (request.headers.has('transactionId') && !hideLoader) {
            this.layoutService.triggerLoadersChangedObservable(
              request.headers.get('transactionId'),
              false
            );
          }
        })
      );
    }
    if (toCache) {
      const cachedResponse: HttpResponse<unknown> = this.cache.get(request.url);
      if (cachedResponse) {
        if (request.headers.has('transactionId') && !hideLoader) {
          this.layoutService.triggerLoadersChangedObservable(
            request.headers.get('transactionId'),
            false
          );
        }
        return of(cachedResponse.clone());
      } else {
        return next.handle(request).pipe(
          map((stateEvent) => {
            if (stateEvent instanceof HttpResponse) {
              if (
                stateEvent.status === 202 ||
                stateEvent.status === 201 ||
                stateEvent.status === 200
              ) {
                this.cache.set(request.url, stateEvent.clone());
              }
            }
            return stateEvent;
          }),
          finalize(() => {
            if (request.headers.has('transactionId') && !hideLoader) {
              this.layoutService.triggerLoadersChangedObservable(
                request.headers.get('transactionId'),
                false
              );
            }
          })
        );
      }
    }
    return next.handle(request).pipe(
      map((stateEvent) => {
        if (stateEvent instanceof HttpResponse) {
          if (stateEvent.headers.has(FILE_HEADER)) {
            this.layoutService.triggerFileDownload({
              file: stateEvent.body,
              fileName: stateEvent.headers.get(FILE_HEADER),
            });
          }
        }
        return stateEvent;
      }),
      finalize(() => {
        if (request.headers.has('transactionId') && !hideLoader) {
          this.layoutService.triggerLoadersChangedObservable(
            request.headers.get('transactionId'),
            false
          );
        }
      })
    );
  }
}

export const HttpRequestInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpRequestInterceptor,
  multi: true,
};
