<div class="search-container">
  <input
    #search
    type="text"
    [placeholder]="
      placeholder === 'Search'
        ? ('COMMON.' + placeholder | translate)
        : placeholder
    "
    (keyup)="searchValue(search.value)"
    [disabled]="disabledValue"
  />
  <mat-icon>search</mat-icon>
</div>
