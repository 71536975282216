<div class="row">
  <div class="col-9">
    <div class="section-card">
      <div class="content">
        <table *ngIf="!loadingUsers" class="users-table" cellpadding="10">
          <thead>
            <tr>
              <th>{{ "USERS_MANAGEMENT.User" | translate }}</th>
              <th>{{ "USERS_MANAGEMENT.Email" | translate }}</th>
              <th>{{ "USERS_MANAGEMENT.Roles" | translate }}</th>
              <th>{{ "USERS_MANAGEMENT.Supervisor" | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let user of usersData
                  | filterNotDynamicTable: searchValue.value;
                let i = index
              "
              [ngClass]="user.enabled ? '' : 'disabled-user'"
            >
              <td>{{ user?.name }} {{ user?.surname }}</td>
              <td>{{ user?.email }}</td>
              <td>
                <span *ngFor="let role of user?.roles; let ind = index">
                  {{ "USERS_MANAGEMENT." + role | translate }}
                  {{
                    user?.roles?.length > 1 && ind < user?.roles?.length - 1
                      ? ","
                      : null
                  }}
                </span>
              </td>
              <td>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  *ngIf="checkSelectSupervisor(user)"
                  [value]="user.parentUserId"
                  [placeholder]="'COMMON.SelectSupervisor' | translate"
                  (selectionChange)="openAssignSupervisorConfirm($event, user)"
                  class="supervisor-select"
                  [matTooltip]="'COMMON.SelectSupervisor' | translate"
                >
                  <mat-option
                    *ngFor="
                      let superVisor of oldUsersData
                        | supervisorfilter: user:translations
                    "
                    [value]="superVisor.value"
                  >
                    {{ superVisor.label }}
                  </mat-option>
                </mat-select>
              </td>
              <td>
                <div class="d-flex">
                  <button
                    type="button"
                    class="only-icon-button primary"
                    (click)="showUserDialog(user)"
                    [matTooltip]="translations.EditUser"
                  >
                    <svg-icon name="edit_user_icon" class="w20"></svg-icon>
                  </button>
                  <button
                    type="button"
                    class="only-icon-button ml-10"
                    [ngClass]="user.enabled ? 'error' : 'primary'"
                    [matTooltip]="
                      user.enabled
                        ? translations.DisableUser
                        : translations.EnableUser
                    "
                    (click)="
                      openEnableDisableDialog(user, user.enabled ? false : true)
                    "
                  >
                    <svg-icon
                      [name]="
                        user.enabled ? 'remove_user_icon' : 'add_user_icon'
                      "
                      class="w20"
                    ></svg-icon>
                  </button>
                  <button
                    type="button"
                    class="only-icon-button primary ml-10"
                    (click)="openResetPasswordDialog(user)"
                    [matTooltip]="translations.ResetPassword"
                  >
                    <svg-icon name="key_icon" class="w20"></svg-icon>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="loadingUsers" class="skeleton-loader">
          <div class="header"></div>
          <div class="body-row"></div>
          <div class="body-row"></div>
          <div class="body-row"></div>
        </div>
        <div *ngIf="!usersData?.length && !loadingUsers" class="no-data">
          <span>{{
            noDataMessage || ("COMMON.NoDataAvailable" | translate)
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="section-card h100">
      <div class="add-user">
        <button
          type="button"
          class="rounded-button primary-bg-button"
          (click)="showUserDialog()"
          [disabled]="loadingUsers"
        >
          <span class="white-color">
            {{ "USERS_MANAGEMENT.AddUser" | translate }}
          </span>
        </button>
      </div>
      <div class="header multi">
        <div class="title">
          <span>{{ "COMMON.Filters" | translate }}</span>
        </div>
        <div class="text-button">
          <span
            *ngIf="!loadingUsers"
            class="primary-color"
            (click)="resetFilters()"
          >
            {{ "COMMON.Reset" | translate | lowercase }}
          </span>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label>{{ "USERS_MANAGEMENT.Research" | translate }}</mat-label>
            <input
              #searchValue
              matInput
              type="text"
              [disabled]="loadingUsers"
            />
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label>{{ "USERS_MANAGEMENT.Role" | translate }}</mat-label>
            <mat-select
              #roleFilter
              (selectionChange)="filterTable('role', $event)"
              [disabled]="loadingUsers"
            >
              <mat-option *ngFor="let role of roles" [value]="role">
                {{ "USERS_MANAGEMENT." + role | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label>{{
              "USERS_MANAGEMENT.UserStatus" | translate
            }}</mat-label>
            <mat-select
              #userTypeFilter
              (selectionChange)="filterTable('type', $event)"
              [disabled]="loadingUsers"
            >
              <mat-option value="enabled">
                {{ "USERS_MANAGEMENT.Enabled" | translate }}
              </mat-option>
              <mat-option value="disabled">
                {{ "USERS_MANAGEMENT.Disabled" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label>
              {{ "USERS_MANAGEMENT.TeamLeader" | translate }}
            </mat-label>
            <mat-select
              #teamLeaderFilter
              (selectionChange)="filterTable('teamLeader', $event)"
              [disabled]="loadingUsers"
            >
              <mat-option
                *ngFor="let teamLeader of teamLeaders"
                [value]="teamLeader.idUser"
              >
                {{ teamLeader?.name }} {{ teamLeader?.surname }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- CODICE VECCHIO -->
<!-- CODICE VECCHIO -->
<!-- <div class="wrap-1400">
  <div style="height: 1px"></div>
  <app-user-managment-form
    [initializeForm]="$initializeFormEmitter"
    [roleList]="roles"
    (submitFunction)="saveUser($event)"
  ></app-user-managment-form>
  <div class="search-user-container">
    <mat-form-field class="col">
      <mat-label>{{ "USERS_MANAGEMENT.SearchUser" | translate }}</mat-label>
      <input
        matInput
        name="ALL"
        (keyup)="applyFilter($event)"
        #input
        maxlength="40"
      />
    </mat-form-field>
  </div>
  <div class="disable-user-checkbox-container">
    <mat-checkbox name="enabled" (change)="applyFilter($event)">{{
      "USERS_MANAGEMENT.ExcludeDisabledUsers" | translate
    }}</mat-checkbox>
  </div>
  <div class="table-container mat-elevation-z8">
    <mat-table
      mat-table
      class="user-table"
      [hidden]="usersDataSource.data.length <= 0"
      [dataSource]="usersDataSource"
      matSort
      style="width: 100%"
    >
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          "USERS_MANAGEMENT.Name" | translate
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          [ngClass]="{ disabled: user.enabled == false }"
        >
          <span class="mobile-label">{{
            "USERS_MANAGEMENT.Name" | translate
          }}</span>
          {{ user.surname }} {{ user.name }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="roles">
        <mat-header-cell *matHeaderCellDef>{{
          "USERS_MANAGEMENT.Roles" | translate
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          [ngClass]="{ disabled: user.enabled == false }"
        >
          <div>
            <span class="mobile-label">{{
              "USERS_MANAGEMENT.Roles" | translate
            }}</span>
          </div>
          <span *ngFor="let role of user.roles; let last = last">
            {{ "USERS_MANAGEMENT." + role | translate
            }}<span *ngIf="!last">,&nbsp;</span>
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="supervisor">
        <mat-header-cell *matHeaderCellDef>{{
          "USERS_MANAGEMENT.Supervisor" | translate
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          [ngClass]="{ disabled: user.enabled == false }"
        >
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            *ngIf="checkSelectSupervisor(user)"
            [value]="user.parentUserId"
            placeholder="Seleziona supervisore"
            (selectionChange)="openAssignSupervisorConfirm($event, user)"
            class="supervisor-select"
          >
            <mat-option
              *ngFor="let superVisor of users | supervisorfilter: user"
              [value]="superVisor.value"
            >
              {{ superVisor.label }}
            </mat-option>
          </mat-select>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{
          "USERS_MANAGEMENT.Email" | translate
        }}</mat-header-cell>
        <mat-cell
          *matCellDef="let user"
          [ngClass]="{ disabled: user.enabled == false }"
        >
          <div>
            <span class="mobile-label">{{
              "USERS_MANAGEMENT.Email" | translate
            }}</span>
          </div>
          {{ user.email }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="tools">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let user"> -->
<!--only display OVER 991px windows width-->
<!-- <button
            id="tools-menu"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            matTooltip="Menù strumenti"
          >
            <mat-icon>more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="emitInitializeForm(user)">
              {{ "USERS_MANAGEMENT.EditUser" | translate }}
            </button>

            <button
              mat-menu-item
              *ngIf="user.enabled == true"
              (click)="openEnableDisableDialog(user, false)"
            >
              {{ "USERS_MANAGEMENT.DisableUser" | translate }}
            </button>

            <button
              mat-menu-item
              *ngIf="user.enabled == false"
              (click)="openEnableDisableDialog(user, true)"
            >
              {{ "USERS_MANAGEMENT.EnableUser" | translate }}
            </button>

            <button mat-menu-item (click)="openResetPasswordDialog(user)">
              {{ "USERS_MANAGEMENT.ResetPassword" | translate }}
            </button>
          </mat-menu> -->
<!--only display UNDER 991px windows width-->
<!-- <div class="tools-button-responsive-container">
            <button
              mat-stroked-button
              color="basic"
              class="tools-button-responsive"
              (click)="emitInitializeForm(user)"
              matTooltip="Modifica utente"
            >
              <mat-icon>create</mat-icon>
            </button>

            <button
              mat-stroked-button
              color="basic"
              class="tools-button-responsive"
              *ngIf="user.enabled == true"
              (click)="openEnableDisableDialog(user, false)"
              [matTooltip]="translate.instant('USERS_MANAGEMENT.DisableUser')"
            >
              <mat-icon>person_remove</mat-icon>
            </button>

            <button
              mat-stroked-button
              color="basic"
              class="tools-button-responsive"
              *ngIf="user.enabled == false"
              (click)="openEnableDisableDialog(user, true)"
              [matTooltip]="translate.instant('USERS_MANAGEMENT.EnableUser')"
            >
              <mat-icon>person_add</mat-icon>
            </button>

            <button
              class="tools-button-responsive"
              mat-menu-item
              [matTooltip]="translate.instant('USERS_MANAGEMENT.ResetPassword')"
              (click)="openResetPasswordDialog(user)"
            >
              <mat-icon>vpn_key</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let user; columns: displayedColumns"></mat-row>
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">
          {{ "COMMON.NoMatchesFor" | translate }} "{{ input.value }}"
        </td>
      </tr>
    </mat-table>
    <mat-paginator
      [hidden]="usersDataSource.data.length <= 0"
      [pageSizeOptions]="[5, 10, 25, 100]"
    ></mat-paginator>
    <div
      *ngIf="!usersDataSource.data.length && loading == false"
      class="no-data-available-container mat-elevation-z8"
    >
      <span>{{ "COMMON.NoDataAvailable" | translate }}</span>
    </div>
  </div>
</div> -->
