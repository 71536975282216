<mat-accordion>
  <mat-expansion-panel [expanded]="expanded" (opened)="onCollapse(true)" (closed)="onCollapse(false)">
    <mat-expansion-panel-header>
      <mat-panel-description *ngIf="!expanded" class="closed-panel-description">
        <mat-icon color="primary">comment</mat-icon>
        <span class="panel-title">{{ 'IR_FORM.RevisionNotes' | translate }}</span>
      </mat-panel-description>
      <mat-panel-description *ngIf="expanded" class="opened-panel-description">
        <mat-icon>edit</mat-icon>
        <span class="panel-title">{{ 'IR_FORM.InsertRevisionNotes' | translate }}</span>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form [formGroup]="revisionNotesForm" (ngSubmit)="onSubmit()">
      <div formArrayName="notes">
        <div *ngFor="let note of notes.controls; let i = index" [formGroupName]="i">
          <button type="button" mat-button (click)="removeNote(i)" [disabled]="saveNotesLoader" >
            <mat-icon class="delete-note" [ngClass]="saveNotesLoader ? 'disabled-color' : ''">clear</mat-icon>
          </button>
          <span *ngIf="note.dateInsert" class="note-date">{{ note.dateInsert | date:'dd/MM/yyyy' }}</span>
          <mat-form-field class="note-description-field">
            <mat-label for="description">{{ 'IR_FORM.Description' | translate }}</mat-label>
            <input matInput type="text" formControlName="description" name="description" [readonly]="saveNotesLoader">
            <mat-error *ngIf="note.get('description').invalid">
              {{ note.get('description').errors | getErrorMessage:translations.Description }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="buttons-container">
          <button type="button" mat-button (click)="addNote()" class="add-note">
            <mat-icon class="add-note">add</mat-icon>
            {{ 'IR_FORM.AddNote' | translate }}
          </button>
          <button type="submit" mat-raised-button color="primary" class="save-notes" [disabled]="saveNotesLoader">
            <span *ngIf="!saveNotesLoader">{{ 'IR_FORM.Save' | translate }}</span>
            <mat-spinner *ngIf="saveNotesLoader" class="save-notes-loader" diameter="20"></mat-spinner>
          </button>
        </div>
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>