import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { IReport } from 'src/app/models/report';
import { ISectors } from 'src/app/models/sectors';
import {
  IBMOChecker,
  ITableButton,
  ITableColumn,
  ToastStatus,
} from 'src/app/models/utility';
import { CrmService } from 'src/app/services/crm/crm.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { LayoutService } from 'src/app/services/layout/layout.service';
import * as moment from 'moment';
import { ICountries } from 'src/app/models/countries';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { MatRadioChange } from '@angular/material/radio';
import { Roles } from 'src/app/config';
import { Router } from '@angular/router';
import { IDeal } from 'src/app/models/contact';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { MatSelectChange } from '@angular/material/select';
import { CommonService } from 'src/app/services/common/common.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-business-opportunities',
  templateUrl: './business-opportunities.component.html',
  styleUrls: ['./business-opportunities.component.scss'],
})
export class BusinessOpportunitiesComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  translations: any;
  translationsLostOffers: any;
  commerceSectors: ISectors[];
  countries: ICountries[];

  userRole: string;
  user: string;
  isDirection: boolean;
  isLabManager: boolean;
  allCustomers: any[] = [];
  customerProjects: any[] = [];
  allProjects: any[] = [];
  selectedProject: any;

  bmOpportunities: any[];
  bmOpportunitiesForCSV: any[];
  bmOpportunitiesForTable: any[];
  bmOpportunitiesTableColumns: ITableColumn[];
  bmOpportunitiesTableButtons: ITableButton[];
  bmOpportunitiesLoader: boolean = false;
  showBMBadge: boolean = false;
  resetBMTablePaginator: boolean = false;

  lostOffersOpportunities: any[];
  lostOffersOpportunitiesForCSV: any[];
  lostOffersOpportunitiesForTable: any[];
  lostOffersOpportunitiesTableColumns: ITableColumn[];
  lostOffersOpportunitiesTableButtons: ITableButton[];
  lostOffersOpportunitiesLoader: boolean = false;
  showLostOffersBadge: boolean = false;
  resetLostOffersTablePaginator: boolean = false;

  reportsOpportunities: IReport[];
  reportsOpportunitiesForCSV: any[];
  reportsOpportunitiesForTable: IReport[];
  reportsOpportunitiesTableColumns: ITableColumn[];
  reportsOpportunitiesTableButtons: ITableButton[];
  reportsOpportunitiesLoader: boolean = false;
  showReportsBadge: boolean = false;
  resetReportsTablePaginator: boolean = false;

  bmFilters: FormGroup;
  lostOffersFilters: FormGroup;
  reportsFilters: FormGroup;
  index: number = 0;

  checkBMOpportunitiesStatus: FormControl = new FormControl(false);
  checkLostOfferStatus: FormControl = new FormControl(false);
  checkReportStatus: FormControl = new FormControl(false);

  optionsForCSV: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    headers: [],
    title: 'Your title',
    useBom: true,
    noDownload: false,
    useHeader: false,
    nullToEmptyString: true,
  };

  downloadCSVDateFrom: FormControl = new FormControl({
    value: null,
    disabled: true,
  });
  downloadCSVDateTo: FormControl = new FormControl({
    value: null,
    disabled: true,
  });

  pageIsCustom: boolean;
  avoidCloseDialog: boolean = false;

  emailSettingsForm: FormGroup;

  showFilters: boolean = false;
  showCsv: boolean = false;

  @ViewChild('opportunityDetailDialog') opportunityDetailDialog: any;
  @ViewChild('CSVDownloadQuantityDialog')
  CSVDownloadQuantityDialog: TemplateRef<any>;
  @ViewChild('confirmExportReportsDialog') confirmExportReportsDialog: any;
  @ViewChild('confirmLostOffersExport') confirmLostOffersExport: any;
  @ViewChild('settingsEmailDialog') settingsEmailDIalog: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private layoutService: LayoutService,
    private translate: TranslateService,
    private crm: CrmService,
    private irFormService: IrFormService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private commonService: CommonService
  ) {
    this.bmFilters = this.fb.group({
      idCommerceSector: 'all',
      wordKey: '',
      isSold: false,
      isClient: 'all',
      // isChecked: false,
      startDate: moment().subtract(6, 'months').startOf('day').utc().format(),
      endDate: moment().endOf('day').utc().format(),
    });
    this.lostOffersFilters = this.fb.group({
      idCommerceSector: 'all',
      productDescription: null,
      idCountry: 'all',
      startDate: moment().subtract(1, 'year').startOf('day').utc().format(),
      endDate: moment().endOf('day').utc().format(),
    });
    this.reportsFilters = this.fb.group({
      idCommerceSector: 'all',
      productDescription: null,
      idCountry: 'all',
      startDate: moment().subtract(1, 'year').startOf('day').utc().format(),
      endDate: moment().endOf('day').utc().format(),
    });
    this.emailSettingsForm = this.fb.group({
      idBmEmailFormatter: [null],
      productName: [false],
      soldPurchase: [false],
      orderQuantity: [false],
      quantityYear: [false],
      priceYear: [false],
      nameCustomer: [false],
      referenceName: [false],
      referencePhone: [false],
      referenceEmail: [false],
      dlName: [false],
      dlPhone: [false],
      dlEmail: [false],
      numberMatch: [true],
    });
  }

  ngOnInit(): void {
    // Check page type
    if (this.router.url.includes('custom')) {
      this.pageIsCustom = true;
    } else {
      this.pageIsCustom = false;
    }
    // Check user role
    this.userRole = JSON.parse(localStorage.getItem('session_user')).activeRole;
    this.user = JSON.parse(localStorage.getItem('session_user'));
    if (this.userRole === Roles.Direction) {
      this.isDirection = true;
    }
    if (this.userRole === Roles.LabManager) {
      // this.isLabManager;
    }
    this.subscriptions.add(
      this.translate.get('BUSINESS_OPPORTUNITIES').subscribe({
        next: (data: any) => {
          this.translations = data;
          this.generateBreadCrumb();
          this.getCommerceSectors();
          this.getCountries();
          if (this.pageIsCustom) {
            this.checkNewItems();
          }
          this.getBMOpportunities(
            null,
            '',
            false,
            null,
            moment(this.bmFilters.get('startDate').value).toISOString(),
            moment(this.bmFilters.get('endDate').value).toISOString()
          );
          this.getLostOffersOpportunities(
            null,
            null,
            null,
            moment(this.lostOffersFilters.get('startDate').value).toISOString(),
            moment(this.lostOffersFilters.get('endDate').value).toISOString()
          );
          this.getReportsOpportunities(
            null,
            null,
            null,
            moment(this.reportsFilters.get('startDate').value).toISOString(),
            moment(this.reportsFilters.get('endDate').value).toISOString()
          );
        },
      })
    );
    // Get all customers and projects managed by logged user
    if (this.pageIsCustom) {
      this.crm.getProjects().subscribe((result) => {
        result.forEach((item: any) => {
          if (
            !this.allCustomers.some((customer: any) => {
              return customer.idCustomer === item.idCustomer;
            })
          ) {
            this.allCustomers.push(item);
          }
        });
        this.allProjects = result;
      });
    }
    this.downloadCSVDateFrom.disable();
    this.downloadCSVDateTo.disable();
  }

  generateBreadCrumb(): void {
    this.layoutService.generateBreadcrumb([
      {
        label: this.translate.instant(
          this.pageIsCustom
            ? 'PAGES.business-opportunities-custom'
            : 'PAGES.business-opportunities'
        ),
        path: this.pageIsCustom
          ? '/business-opportunities-custom'
          : '/business-opportunities',
        pageTitle: true,
      },
    ]);
  }

  getCommerceSectors(): void {
    this.subscriptions.add(
      this.irFormService.getAllSectors(true).subscribe({
        next: (commerceSectors: ISectors[]) => {
          this.commerceSectors = commerceSectors;
        },
      })
    );
  }

  getCountries(): void {
    this.subscriptions.add(
      this.irFormService.getAllCountries(true).subscribe({
        next: (countries: ICountries[]) => {
          this.countries = countries;
        },
      })
    );
  }

  checkNewItems(): void {
    this.crm.checkNewBusinessOpportunities().subscribe({
      next: (data: IBMOChecker) => {
        if (data.bm) {
          this.showBMBadge = true;
        } else {
          this.showBMBadge = false;
        }
        if (data.lostOffers) {
          this.showLostOffersBadge = true;
        } else {
          this.showLostOffersBadge = false;
        }
        if (data.reports) {
          this.showReportsBadge = true;
        } else {
          this.showReportsBadge = false;
        }
      },
    });
  }

  downloadCSV(): void {
    let type: string;
    switch (this.index) {
      case 0:
        type = 'bm';
        break;
      case 1:
        type = 'lostOffers';
        break;
      case 2:
        type = 'reports';
        break;
    }
    console.log(type);
    let dataToDownload: any[] = [];
    if (this.downloadCSVDateFrom?.value && this.downloadCSVDateTo?.value) {
      dataToDownload = this.checkWichCSVType(type).filter((csv: any) => {
        return (
          moment(csv?.dateInsert).valueOf() >=
            moment(this.downloadCSVDateFrom.value).startOf('day').valueOf() &&
          moment(csv?.dateInsert).valueOf() <=
            moment(this.downloadCSVDateTo.value).endOf('day').valueOf()
        );
      });
      if (dataToDownload?.length) {
        const download = new AngularCsv(
          dataToDownload,
          'Data',
          this.optionsForCSV
        );
        this.downloadCSVDateFrom.reset();
        this.downloadCSVDateTo.reset();
        this.dialog.closeAll();
        this.showCsv = false;
      } else {
        this.commonService.showToast(
          this.translate.instant('COMMON.NoDataAvailable'),
          ToastStatus.warning,
          3000
        );
      }
    }
    if (this.downloadCSVDateFrom.disabled && this.downloadCSVDateTo.disabled) {
      dataToDownload = this.checkWichCSVType(type);
      if (dataToDownload?.length) {
        const download = new AngularCsv(
          dataToDownload,
          'Data',
          this.optionsForCSV
        );
        this.downloadCSVDateFrom.reset();
        this.downloadCSVDateTo.reset();
        this.dialog.closeAll();
        this.showCsv = false;
      } else {
        this.commonService.showToast(
          this.translate.instant('COMMON.NoDataAvailable'),
          ToastStatus.warning,
          3000
        );
      }
    }
  }

  checkWichCSVType(type: string): any[] {
    if (type === 'bm') {
      this.optionsForCSV.headers = [
        'Settore merceologico',
        'Prodotto',
        'Tipo',
        'Quantità ordine',
        'Quantità anno',
        'Valore acquisto/anno',
        'Ragione sociale/Referente',
        'Cliente/Utente',
        'Referente DLike',
      ];
      return this.bmOpportunitiesForCSV;
    }
    if (type === 'lostOffers') {
      this.optionsForCSV.headers = [
        'Settore merceologico',
        'Prodotto',
        'Quantità ordine',
        'Quantità anno',
        'Valore acquisto/anno',
        'Paese',
      ];
      return this.lostOffersOpportunitiesForCSV;
    }
    if (type === 'reports') {
      this.optionsForCSV.headers = [
        'Settore merceologico',
        'Prodotto',
        'Quantità ordine',
        'Quantità anno',
        'Valore acquisto/anno',
        'Paese',
      ];
      return this.reportsOpportunitiesForCSV;
    }
  }

  getBMOpportunities(
    idCommerceSector?: number,
    wordKey?: string,
    isSold?: boolean,
    // isChecked?: boolean,
    isClient?: boolean,
    startDate?: string,
    endDate?: string
  ): void {
    this.bmOpportunitiesLoader = true;
    this.subscriptions.add(
      this.crm
        .getBMOpportunities(
          this.pageIsCustom,
          idCommerceSector,
          wordKey,
          isSold,
          this.pageIsCustom ? false : null,
          isClient,
          startDate,
          endDate
        )
        .subscribe({
          next: (data: any) => {
            this.bmOpportunities = data;
            this.bmOpportunitiesForCSV = data.map((item: any) => ({
              commerceSector: item.commerceSector.name,
              product: item.productName,
              typology: item.isSold
                ? this.translations.Sold
                : this.translations.Bought,
              quantityOrder: item?.productQuantityOrder,
              quantityYear: item?.productQuantityYear,
              valueYear: item.productValueYear,
              businessNameReferent: `${item?.businessName}
                ${item?.customerRefName} ${item?.customerRefSurname}
                ${item?.customerRefTelephone}
                ${item?.customerRefEmail}
              `,
              type: item.isClient
                ? this.translations.Client
                : this.translations.User,
              /** DON'T FORMAT TEXT BELOW */
              agentOrManager: `${item?.agent?.name || item?.manager?.name} ${
                item?.agent?.surname || item?.manager?.surname
              }
                ${item?.agent?.telephone || item?.manager?.telephone}
                ${item?.agent?.email || item?.manager?.email}
              `,
            }));
            this.bmOpportunitiesForTable = data.map(
              (item: any, ind: number) => ({
                idProductAllBusinessMatching: item.idProductAllBusinessMatching,
                customer: `
                  <strong>${item?.businessNameMatched}</strong>
                  <br>
                  ${item?.refNameMatched} ${item?.refSurnameMatched}
                  <br>
                  ${item?.refTelephoneMatched}
                  <br>
                  ${item?.refEmailMatched}
                `,
                typology: item.isSold
                  ? this.translations.Sold
                  : this.translations.Bought,
                commerceSector: item.commerceSector.name,
                quantityOrder: item?.productQuantityOrder,
                quantityYear: item?.productQuantityYear,
                valueYear: item.productValueYear,
                businessNameReferent: `
                  <strong>${item?.businessName}</strong>
                  <br>
                  ${item?.customerRefName} ${item?.customerRefSurname}
                  <br>
                  ${item?.customerRefTelephone}
                  <br>
                  ${item?.customerRefEmail}
                `,
                manager: `
                  <strong>${item?.manager?.name || item?.agent?.name} ${
                  item?.manager?.surname || item?.agent?.surname
                }</strong>
                  <br>
                  ${item?.manager?.telephone || item?.agent?.telephone}
                  <br>
                  ${item?.manager?.email || item?.agent?.email}
                `,
                companyBusinessName: item.businessName,
                type: item?.isClient
                  ? this.translations.Client
                  : this.translations.User,
                referentName: `${item.customerRefName} ${item.customerRefSurname}`,
                referentPhone: item.customerRefTelephone,
                referentEmail: item.customerRefEmail,
                userName: `${item?.manager?.name || item?.agent?.name} ${
                  item?.manager?.surname || item?.agent.surname
                }`,
                userPhone: item?.manager?.telephone || item?.agent.telephone,
                userEmail: item?.manager?.email || item?.agent.email,
                product: item?.productName,
                englishProductDescription: item?.productDescEn,
                index: ind,
                isSold: item.isSold,
                alreadyRead: item?.alreadyRead,
              })
            );

            // in progress
            // console.log(this.bmOpportunitiesForTable);
            // this.bmOpportunitiesForTable.forEach((bm) => {
            //   if (bm.commerceSector.includes('/')) {

            //   }
            // })

            this.bmOpportunitiesTableColumns = [
              {
                name: 'commerceSector',
                translatedName: this.translations.CommerceSector,
              },
              {
                name: 'product',
                translatedName: this.translations.Product,
              },
              {
                name: 'typology',
                translatedName: this.translations.Type,
              },
              {
                name: 'quantityOrder',
                translatedName: this.translations.QuantityOrder,
                type: 'number',
                align: 'center',
              },
              {
                name: 'quantityYear',
                translatedName: this.translations.QuantityYear,
                type: 'number',
                align: 'center',
              },
              {
                name: 'valueYear',
                translatedName: this.translations.PurchaseValueYear,
                type: 'currency',
                align: 'center',
              },
              {
                name: 'businessNameReferent',
                translatedName: this.translations.BusinessNameReferent,
                type: 'innerHTML',
              },
              {
                name: 'type',
                translatedName: this.translations.CustomerUser,
              },
              {
                name: 'manager',
                translatedName: this.translations.Referent,
                type: 'innerHTML',
              },
              {
                name: 'buttons',
                disableSort: true,
              },
            ];
            if (this.pageIsCustom) {
              this.bmOpportunitiesTableColumns.shift();
              this.bmOpportunitiesTableColumns.unshift({
                name: 'customer',
                translatedName: this.translations.Company,
                type: 'innerHTML',
              });
            }
            this.bmOpportunitiesTableButtons = [
              {
                icon: 'search_icon',
                class: 'primary',
                clickFunction: (item: any) => {
                  this.showOpportunityDetailDialog(
                    'bm',
                    this.bmOpportunities[item.index]
                  );
                },
              },
            ];
            this.bmOpportunitiesLoader = false;
            this.resetBMTablePaginator = false;
          },
          error: () => {
            this.bmOpportunitiesLoader = false;
          },
        })
    );
  }

  checkUser(bm: any): string {
    if (bm?.managerPurchaseDTO?.name) {
      return `
        ${this.translations.DLManager}
        <br>
        ${bm?.managerPurchaseDTO?.name} ${bm?.managerPurchaseDTO?.surname}
        <br>
        ${bm?.managerPurchaseDTO?.telephone}
        <br>
        ${bm?.managerPurchaseDTO?.email}
      `;
    } else {
      return `
        ${this.translations.Agent}
        <br>
        ${bm?.agentPurchaseDTO?.name} ${bm?.agentPurchaseDTO?.surname}
        <br>
        ${bm?.agentPurchaseDTO?.telephone}
        <br>
        ${bm?.agentPurchaseDTO?.email}
      `;
    }
  }

  getLostOffersOpportunities(
    idCommerceSector?: number,
    productDescription?: string,
    idCountry?: number,
    startDate?: string,
    endDate?: string
  ): void {
    this.lostOffersOpportunitiesLoader = true;
    this.subscriptions.add(
      this.crm
        .getLostOffersOpportunities(
          this.pageIsCustom,
          idCommerceSector,
          productDescription,
          idCountry,
          startDate,
          endDate
        )
        .subscribe({
          next: (data: any) => {
            this.lostOffersOpportunities = data;
            this.lostOffersOpportunitiesForCSV = data.map((item: any) => ({
              commerceSector: item?.commerceSector?.name,
              product: item?.productName,
              estimatedQuantityOrder: item?.estimatedQuantityOrder,
              estimatedQuantityYear: item?.estimatedQuantityYear,
              estimatedValueYear: item?.estimatedValueYear,
              country: item.country.country,
            }));
            this.lostOffersOpportunitiesForTable = data.map(
              (opp: any, ind: number): any => ({
                idDeal: opp?.idDeal,
                alreadyRead: opp?.alreadyRead,
                commerceSector: opp?.commerceSector?.name,
                product: opp?.productName,
                estimatedQuantityOrder: opp?.estimatedQuantityOrder,
                estimatedQuantityYear: opp?.estimatedQuantityYear,
                estimatedValueYear: opp?.estimatedValueYear,
                country: opp?.country?.country,
                businessNameAndBuyerInfo: `
                  <strong>${opp?.contact?.businessName}</strong>
                  <br>
                  ${opp?.contact?.contactMainReference?.name || ''}
                  ${opp?.contact?.contactMainReference?.surname || ''}
                  <br>
                  ${opp?.contact?.contactMainReference?.email || ''}
                  <br>${opp?.contact?.contactMainReference?.tel || ''}
                `,
                dlmanager: `
                  <strong>${opp?.userInfo?.name} ${opp?.userInfo?.surname}</strong>
                  <br>
                  ${opp?.userInfo?.telephone}
                  <br>
                  ${opp?.userInfo?.email}
                `,
                index: ind,
              })
            );
            // Mapping data for CSV file
            // this.lostOffersOpportunitiesForCSV = data.map();
            this.lostOffersOpportunitiesTableColumns = [
              {
                name: 'commerceSector',
                translatedName: this.translations.CommerceSector,
              },
              {
                name: 'product',
                translatedName: this.translations.Product,
              },
              {
                name: 'estimatedQuantityOrder',
                translatedName: this.translations.QuantityOrder,
                type: 'number',
                align: 'center',
              },
              {
                name: 'estimatedQuantityYear',
                translatedName: this.translations.QuantityYear,
                type: 'number',
                align: 'center',
              },
              {
                name: 'estimatedValueYear',
                translatedName: this.translations.PurchaseValueYear,
                type: 'currency',
                align: 'center',
              },
              {
                name: 'country',
                translatedName: this.translations.Country,
              },
              // {
              //   name: 'businessNameAndBuyerInfo',
              //   translatedName: this.translations.BusinessNameBuyer,
              //   innerHTML: true,
              // },
              // {
              //   name: 'dlmanager',
              //   translatedName: this.translations.DLManager,
              //   innerHTML: true,
              // },
              {
                name: 'buttons',
                disableSort: true,
              },
            ];
            if (this.pageIsCustom) {
              // this.lostOffersOpportunitiesTableColumns.shift();
              this.lostOffersOpportunitiesTableColumns.unshift({
                name: 'businessNameAndBuyerInfo',
                translatedName: this.translations.Company,
                type: 'innerHTML',
              });
            }
            this.lostOffersOpportunitiesTableButtons = [
              {
                icon: 'search_icon',
                class: 'primary',
                clickFunction: (item: any) => {
                  this.showOpportunityDetailDialog(
                    'lostOffer',
                    this.lostOffersOpportunities[item.index]
                  );
                },
              },
              {
                icon: 'folder_icon',
                class: 'primary',
                disabled: (item: any) =>
                  !this.lostOffersOpportunities[item.index].dealDocuments
                    ?.length,
                clickFunction: (item: any) => {
                  if (
                    this.lostOffersOpportunities[item.index].dealDocuments
                      ?.length
                  ) {
                    this.subscriptions.add(
                      this.crm
                        .downloadDealDocument(
                          this.lostOffersOpportunities[item.index]
                            .dealDocuments[0].idDealDocument
                        )
                        .subscribe()
                    );
                  }
                },
              },
            ];
            // this.lostOffersOpportunities = data;
            this.lostOffersOpportunitiesLoader = false;
            this.resetLostOffersTablePaginator = false;
          },
          error: () => {
            this.lostOffersOpportunitiesLoader = false;
          },
        })
    );
  }

  getReportsOpportunities(
    idCommerceSector?: number,
    productDescription?: string,
    idCountry?: number,
    startDate?: string,
    endDate?: string
  ): void {
    this.reportsOpportunitiesLoader = true;
    this.subscriptions.add(
      this.crm
        .getReportsOpportunities(
          this.pageIsCustom,
          idCommerceSector,
          productDescription,
          idCountry,
          startDate,
          endDate
        )
        .subscribe({
          next: (data: IReport[]) => {
            this.reportsOpportunities = data;
            this.reportsOpportunitiesForCSV = data.map((item: any) => ({
              commerceSector: item?.commerceSector?.name,
              product: item?.productName,
              quantity: item?.quantity,
              quantityYear: item?.quantityYear,
              valueYear: item?.valueYear,
              country: item?.contact?.country?.country,
            }));
            this.reportsOpportunitiesForTable = data.map(
              (report: IReport): any => ({
                idOpportunity: report?.idOpportunity,
                alreadyRead: report?.alreadyRead,
                product: report?.productName,
                quantity: report?.quantity,
                quantityYear: report?.quantityYear,
                valueYear: report?.valueYear,
                country: report?.contact?.country?.country,
                commerceSector: report?.commerceSector?.name,
                idCommerceSector: report?.commerceSector?.idCommerceSector,
                businessNameAndBuyerInfo: `
                  <strong>${report?.contact?.businessName}</strong>
                  <br>
                  ${report?.contactReference?.name}
                  ${report?.contactReference?.surname}
                  <br>
                  ${report?.contactReference?.tel}
                  <br>
                  ${report?.contactReference?.email}
                `,
                dlmanager: `
                  <strong>${report?.userInsertDTO?.name} ${report?.userInsertDTO?.surname}</strong>
                  <br>
                  ${report?.userInsertDTO?.telephone}
                  <br>
                  ${report?.userInsertDTO?.email}
                `,
              })
            );
            this.reportsOpportunitiesTableColumns = [
              {
                name: 'commerceSector',
                translatedName: this.translations.CommerceSector,
              },
              {
                name: 'product',
                translatedName: this.translations.Product,
              },
              {
                name: 'quantity',
                translatedName: this.translations.QuantityOrder,
                type: 'number',
                align: 'center',
              },
              {
                name: 'quantityYear',
                translatedName: this.translations.QuantityYear,
                type: 'number',
                align: 'center',
              },
              {
                name: 'valueYear',
                translatedName: this.translations.PurchaseValueYear,
                type: 'currency',
                align: 'center',
              },
              {
                name: 'country',
                translatedName: this.translations.Country,
              },
              // {
              //   name: 'businessNameAndBuyerInfo',
              //   translatedName: this.translations.BusinessNameBuyer,
              //   innerHTML: true,
              // },
              // {
              //   name: 'dlmanager',
              //   translatedName: this.translations.DLManager,
              //   innerHTML: true,
              // },
              {
                name: 'buttons',
                disableSort: true,
              },
            ];
            if (this.pageIsCustom) {
              // this.reportsOpportunitiesTableColumns.shift();
              this.reportsOpportunitiesTableColumns.unshift({
                name: 'businessNameAndBuyerInfo',
                translatedName: this.translations.Company,
                type: 'innerHTML',
              });
            }
            this.reportsOpportunitiesTableButtons = [
              {
                icon: 'search_icon',
                class: 'primary',
                clickFunction: (item: any) => {
                  let index: number;
                  index = this.reportsOpportunitiesForTable.findIndex(
                    (report: any) => {
                      return report.idOpportunity === item.idOpportunity;
                    }
                  );
                  this.showOpportunityDetailDialog(
                    'report',
                    this.reportsOpportunities[index]
                  );
                },
              },
            ];
            this.reportsOpportunitiesLoader = false;
            this.resetReportsTablePaginator = false;
          },
        })
    );
  }

  applyBmFilters(event?: string): void {
    if (event === 'reset') {
      this.resetBMTablePaginator = true;
    }
    const filtersRawData: any = this.bmFilters.getRawValue();
    if (filtersRawData.idCommerceSector === 'all') {
      filtersRawData.idCommerceSector = null;
    }
    if (filtersRawData.isSold === 'all') {
      filtersRawData.isSold = null;
    }
    // if (filtersRawData.isChecked === 'all') {
    //   filtersRawData.isChecked = null;
    // }
    if (filtersRawData.isClient === 'all') {
      filtersRawData.isClient = null;
    }
    this.getBMOpportunities(
      filtersRawData.idCommerceSector,
      filtersRawData.wordKey,
      filtersRawData.isSold,
      // filtersRawData.isChecked,
      filtersRawData.isClient,
      filtersRawData.startDate.toISOString(),
      moment(filtersRawData.endDate).endOf('day').toISOString()
    );
    if (this.pageIsCustom) {
      this.checkNewItems();
    }
  }

  applyLostOffersFilters(event?: string): void {
    if (event === 'reset') {
      this.resetLostOffersTablePaginator = true;
    }
    const filtersRawData: any = this.lostOffersFilters.getRawValue();
    if (filtersRawData.idCommerceSector === 'all') {
      filtersRawData.idCommerceSector = null;
    }
    if (filtersRawData.idCountry === 'all') {
      filtersRawData.idCountry = null;
    }

    this.getLostOffersOpportunities(
      filtersRawData.idCommerceSector,
      filtersRawData.productDescription,
      filtersRawData.idCountry,
      filtersRawData.startDate.toISOString(),
      moment(filtersRawData.endDate).endOf('day').toISOString()
    );
    if (this.pageIsCustom) {
      this.checkNewItems();
    }
  }

  applyReportsFilters(event?: string): void {
    if (event === 'reset') {
      this.resetReportsTablePaginator = true;
    }
    const filtersRawData: any = this.reportsFilters.getRawValue();
    if (filtersRawData.idCommerceSector === 'all') {
      filtersRawData.idCommerceSector = null;
    }
    if (filtersRawData.idCountry === 'all') {
      filtersRawData.idCountry = null;
    }
    this.getReportsOpportunities(
      filtersRawData.idCommerceSector,
      filtersRawData.productDescription,
      filtersRawData.idCountry,
      filtersRawData.startDate.toISOString(),
      moment(filtersRawData.endDate).endOf('day').toISOString()
    );
    if (this.pageIsCustom) {
      this.checkNewItems();
    }
  }

  resetBmFilters(): void {
    this.bmFilters.reset();
    this.bmFilters.get('idCommerceSector').setValue('all');
    this.bmFilters.get('wordKey').setValue('');
    this.bmFilters.get('isSold').setValue(false);
    // this.bmFilters.get('isChecked').setValue(false);
    this.bmFilters.get('isClient').setValue('all');
    this.bmFilters
      .get('startDate')
      .setValue(moment().subtract(6, 'months').startOf('day').utc().format());
    this.bmFilters
      .get('endDate')
      .setValue(moment().endOf('day').utc().format());
  }

  resetLostOffersFilters(): void {
    this.lostOffersFilters.reset();
    this.lostOffersFilters.get('idCommerceSector').setValue('all');
    this.lostOffersFilters.get('idCountry').setValue('all');
    this.lostOffersFilters
      .get('startDate')
      .setValue(moment().subtract(1, 'year').startOf('day').utc().format());
    this.lostOffersFilters
      .get('endDate')
      .setValue(moment().endOf('day').utc().format());
  }

  resetReportsFilters(): void {
    this.reportsFilters.reset();
    this.reportsFilters.get('idCommerceSector').setValue('all');
    this.reportsFilters.get('idCountry').setValue('all');
    this.reportsFilters
      .get('startDate')
      .setValue(moment().subtract(1, 'year').startOf('day').utc().format());
    this.reportsFilters
      .get('endDate')
      .setValue(moment().endOf('day').utc().format());
  }

  showOpportunityDetailDialog(type: string, itemData: any): void {
    let refreshDataAfterClosed: boolean = false;
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '1200px';
    dialogConfig.maxWidth = '80vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      opportunityType: type,
      item: itemData,
    };
    this.checkBMOpportunitiesStatus.setValue(itemData.checked);
    this.checkLostOfferStatus.setValue(itemData.checked);
    this.checkReportStatus.setValue(itemData.checked);
    const dialogRef: any = this.dialog.open(
      this.opportunityDetailDialog,
      dialogConfig
    );
    console.log(itemData);
    if (!itemData?.alreadyRead && this.pageIsCustom) {
      switch (type) {
        case 'bm':
          this.avoidCloseDialog = true;
          this.crm.checkBMAsRead(itemData).subscribe({
            complete: () => {
              refreshDataAfterClosed = true;
              this.avoidCloseDialog = false;
            },
          });
          break;
        case 'lostOffer':
          this.avoidCloseDialog = true;
          this.crm.checkLostOfferAsRead(itemData.idDeal).subscribe({
            complete: () => {
              refreshDataAfterClosed = true;
              this.avoidCloseDialog = false;
            },
          });
          break;
        case 'report':
          this.avoidCloseDialog = true;
          this.crm.checkReportAsRead(itemData.idOpportunity).subscribe({
            complete: () => {
              refreshDataAfterClosed = true;
              this.avoidCloseDialog = false;
            },
          });
          break;
      }
    }
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (this.checkBMOpportunitiesStatus.dirty) {
          this.saveBMOpportunitiesCheckStatus(
            itemData.idProductAllBusinessMatching,
            itemData.isSold ? null : this.checkBMOpportunitiesStatus.value,
            itemData.isSold ? this.checkBMOpportunitiesStatus.value : null
          );
        }
        if (this.checkLostOfferStatus.dirty) {
          this.crm
            .checkLostOfferAsRead(itemData.idDeal, true, {
              checked: this.checkLostOfferStatus.value,
            })
            .subscribe({
              complete: () => {
                this.getLostOffersOpportunities(
                  null,
                  '',
                  this.lostOffersFilters.get('idCountry').value === 'all'
                    ? null
                    : this.lostOffersFilters.get('idCountry').value,
                  moment(
                    this.lostOffersFilters.get('startDate').value
                  ).toISOString(),
                  moment(this.lostOffersFilters.get('endDate').value)
                    .endOf('day')
                    .toISOString()
                );
                this.checkNewItems();
              },
            });
        }
        if (this.checkReportStatus.dirty) {
          this.crm
            .checkReportAsRead(itemData.idOpportunity, true, {
              checked: this.checkReportStatus.value,
            })
            .subscribe({
              complete: () => {
                this.dialog.closeAll();
                this.getReportsOpportunities(
                  null,
                  '',
                  this.reportsFilters.get('idCountry').value === 'all'
                    ? null
                    : this.reportsFilters.get('idCountry').value,
                  moment(
                    this.reportsFilters.get('startDate').value
                  ).toISOString(),
                  moment(this.reportsFilters.get('endDate').value)
                    .endOf('day')
                    .toISOString()
                );
                this.checkNewItems();
              },
            });
        }
      }
      if (refreshDataAfterClosed && this.pageIsCustom) {
        const filtersRawData: any = this.lostOffersFilters.getRawValue();
        if (filtersRawData.idCountry === 'all') {
          filtersRawData.idCountry = null;
        }
        switch (type) {
          case 'bm':
            this.applyBmFilters();
            break;
          case 'lostOffer':
            this.applyLostOffersFilters();
            break;
          case 'report':
            this.applyReportsFilters();
            break;
        }
      }
    });
  }

  saveBMOpportunitiesCheckStatus(
    idBm: number,
    checkedBuyer?: boolean,
    checkedSell?: boolean
  ): void {
    this.subscriptions.add(
      this.crm.checkBMOpportunities(idBm, checkedBuyer, checkedSell).subscribe({
        complete: () => {
          const rawDataFilters: any = this.bmFilters.getRawValue();
          if (rawDataFilters.idCommerceSector === 'all') {
            rawDataFilters.idCommerceSector = null;
          }
          // if (rawDataFilters.isChecked === 'all') {
          //   rawDataFilters.isChecked = null;
          // }
          if (rawDataFilters.isClient === 'all') {
            rawDataFilters.isClient = null;
          }
          this.getBMOpportunities(
            rawDataFilters.idCommerceSector,
            !this.pageIsCustom ? rawDataFilters.product : '',
            rawDataFilters.isSold,
            // rawDataFilters.isChecked,
            rawDataFilters.isClient,
            rawDataFilters.startDate.toISOString(),
            moment(rawDataFilters.endDate).endOf('day').toISOString()
          );
          this.checkNewItems();
          this.checkBMOpportunitiesStatus.markAsPristine();
        },
      })
    );
  }

  showCSVQuantityDialog(opportunityType?: string): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      type: opportunityType,
    };
    const dialogRef: any = this.dialog.open(
      this.CSVDownloadQuantityDialog,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.downloadCSV();
      }
    });
  }

  exportBM(bm: any): void {
    this.subscriptions.add(
      this.crm.exportBM(bm.idProductAllBusinessMatching).subscribe({
        complete: () => {
          this.dialog.closeAll();
          this.getBMOpportunities(
            null,
            '',
            this.bmFilters.get('isSold').value,
            null,
            moment(this.bmFilters.get('startDate').value).toISOString(),
            moment(this.bmFilters.get('endDate').value)
              .endOf('day')
              .toISOString()
          );
          this.checkNewItems();
        },
      })
    );
  }

  exportLostOffer(deal: IDeal): void {
    this.crm
      .checkLostOfferAsRead(deal.idDeal, true, {
        exported: true,
        checked: true,
      })
      .subscribe({
        complete: () => {
          this.dialog.closeAll();
          this.getLostOffersOpportunities(
            null,
            '',
            this.lostOffersFilters.get('idCountry').value === 'all'
              ? null
              : this.lostOffersFilters.get('idCountry').value,
            moment(this.lostOffersFilters.get('startDate').value).toISOString(),
            moment(this.lostOffersFilters.get('endDate').value)
              .endOf('day')
              .toISOString()
          );
          this.checkNewItems();
        },
      });
  }

  confirmExportLostOffer(deal: IDeal) {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '750px';
    dialogConfig.maxWidth = '80vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      idDeal: deal?.idDeal,
    };
    const dialogRef: any = this.dialog.open(
      this.confirmLostOffersExport,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.crm
          .checkLostOfferAsRead(deal?.idDeal, false, {
            exported: true,
            checked: true,
            projectExported: result.idProject,
          })
          .subscribe({
            complete: () => {
              this.dialog.closeAll();
              this.getLostOffersOpportunities(
                null,
                '',
                this.lostOffersFilters.get('idCountry').value === 'all'
                  ? null
                  : this.lostOffersFilters.get('idCountry').value,
                moment(
                  this.lostOffersFilters.get('startDate').value
                ).toISOString(),
                moment(this.lostOffersFilters.get('endDate').value)
                  .endOf('day')
                  .toISOString()
              );
              this.checkNewItems();
            },
          });
      }
    });
  }

  confirmExportReports(opp: any) {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '750px';
    dialogConfig.maxWidth = '80vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      idOpportunity: opp.idOpportunity,
    };
    const dialogRef: any = this.dialog.open(
      this.confirmExportReportsDialog,
      dialogConfig
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.crm
          .checkReportAsRead(opp.idOpportunity, false, {
            exported: true,
            checked: true,
            projectExported: result.idProject,
          })
          .subscribe({
            complete: () => {
              this.dialog.closeAll();
              this.getReportsOpportunities(
                null,
                '',
                this.reportsFilters.get('idCountry').value === 'all'
                  ? null
                  : this.reportsFilters.get('idCountry').value,
                moment(
                  this.reportsFilters.get('startDate').value
                ).toISOString(),
                moment(this.reportsFilters.get('endDate').value)
                  .endOf('day')
                  .toISOString()
              );
              this.checkNewItems();
            },
          });
      }
    });
  }

  selectCustomer(select: MatSelectChange): void {
    this.customerProjects = [];
    const idCustomer: number = select.value.idCustomer;
    this.allProjects.forEach((project: any) => {
      if (project.idCustomer === idCustomer) {
        this.customerProjects.push(project);
      }
    });
  }

  selectProject(select: MatSelectChange): void {
    this.selectedProject = select.value;
  }

  checkChoiceForDownloadCSV(event: MatRadioChange): void {
    if (event.value === 'specific') {
      this.downloadCSVDateFrom.enable();
      this.downloadCSVDateTo.enable();
    } else {
      this.downloadCSVDateFrom.reset();
      this.downloadCSVDateTo.reset();
      this.downloadCSVDateFrom.disable();
      this.downloadCSVDateTo.disable();
    }
  }

  showSettingsEmailDialog(): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    this.emailSettingsForm.reset();
    this.dialog.open(this.settingsEmailDIalog, dialogConfig);
    this.commonService.getEmailFormatter().subscribe({
      next: (emailFormatter: any) => {
        this.emailSettingsForm.patchValue({
          idBmEmailFormatter: emailFormatter?.idBmEmailFormatter || null,
          productName: emailFormatter?.productName || false,
          soldPurchase: emailFormatter?.soldPurchase || false,
          orderQuantity: emailFormatter?.orderQuantity || false,
          quantityYear: emailFormatter?.quantityYear || false,
          priceYear: emailFormatter?.priceYear || false,
          nameCustomer: emailFormatter?.nameCustomer || false,
          referenceName: emailFormatter?.referenceName || false,
          referencePhone: emailFormatter?.referencePhone || false,
          referenceEmail: emailFormatter?.referenceEmail || false,
          dlName: emailFormatter?.dlName || false,
          dlPhone: emailFormatter?.dlPhone || false,
          dlEmail: emailFormatter?.dlEmail || false,
          numberMatch: emailFormatter?.numberMatch || true,
        });
        this.emailSettingsForm.markAsPristine();
      },
    });
  }

  saveEmailSettings(): void {
    if (this.emailSettingsForm.dirty) {
      this.commonService
        .updateEmailFormatter(this.emailSettingsForm.getRawValue())
        .subscribe({
          complete: () => {
            this.dialog.closeAll();
            this.emailSettingsForm.markAsPristine();
          },
        });
    } else {
      this.dialog.closeAll();
    }
  }

  checkDataForStickyLabels(): boolean {
    let result: boolean = false;
    switch (this.index) {
      case 0:
        if (!this.bmOpportunitiesLoader) {
          result = true;
        }
        break;
      case 1:
        if (!this.lostOffersOpportunitiesLoader) {
          result = true;
        }
        break;
      case 2:
        if (!this.reportsOpportunitiesLoader) {
          result = true;
        }
        break;
    }
    return result;
  }

  resetDownloadCSVOptions(): void {
    this.downloadCSVDateFrom.disable();
    this.downloadCSVDateTo.disable();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
