<div class="crm-header">
  <div class="row">
    <mat-form-field class="col-3">
      <mat-label>{{ "CRM.Customer" | translate }}</mat-label>
      <mat-select
        [(value)]="selectedIdCustomer"
        (selectionChange)="resetSelectedProject($event)"
        [disabled]="disabled"
      >
        <mat-option
          *ngFor="let customer of filteredCustomers"
          [value]="customer?.idCustomer"
        >
          {{ customer.customer.businessName }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-3">
      <mat-label>{{ "CRM.Code" | translate }}</mat-label>
      <mat-select
        [(value)]="idProject"
        (selectionChange)="refreshProject($event)"
        [disabled]="disabled"
      >
        <mat-option
          *ngFor="let project of filteredProjects"
          [value]="project.idProject"
        >
          {{ project.code }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="col-6" style="display: flex">
      <div class="crm-project-info">
        <div>
          <span>{{ "CRM.Product" | translate }}:</span>
          <span>{{ selectedProject?.productName }}</span>
        </div>
        <div>
          <span>{{ "CRM.ProjectTypology" | translate }}:</span>
          <span *ngIf="selectedProject">
            {{ selectedProject?.reason}}
          </span>
        </div>
        <div>
          <span>{{ "CRM.Version" | translate }}:</span>
          <span>{{ psVersion[selectedProject?.projectStatusCode] }}</span>
        </div>
      </div>
      <!-- <div class="h50 mt-10">
        <div>
          <span>{{ "CRM.MarketsInStrategy" | translate }}:</span>
          <span
            *ngFor="let market of marketsInStrategy; let i = index"
            class="fw-700"
          >
            {{ i === 0 ? null : "," }} {{ market }}
          </span>
        </div>
      </div> -->
    </div>
  </div>
</div>

<!-- <mat-card class="customer-info-container">
  <div class="row align-items-center">
    <div [ngClass]="isReadonly ? 'col-11' : 'col-12'">
      <div class="row">
        <div class="col-4 b-r-g">
          <span class="text-color">
            {{ 'PS_FORM.Client' | translate }}:
          </span>
          <span class="bold-text-color">
            {{ customerInfoData?.customer?.businessName }}
          </span>
        </div>
        <div class="col-3 b-r-g">
          <span class="text-color">
            {{ 'PS_FORM.Code' | translate }}:
          </span>
          <span class="bold-text-color">
            {{ customerInfoData?.code }}
          </span>
        </div>
        <div class="col-2 b-r-g">
          <span class="text-color">
            {{ 'PS_FORM.Version' | translate }}:
          </span>
          <span class="bold-text-color">
            {{ psVersion }}
          </span>
        </div>
        <div class="col-3">
          <span class="text-color">
            {{ 'PS_FORM.Product' | translate }}:
          </span>
          <span class="bold-text-color">
            {{ customerInfoData?.productName }}
          </span>
        </div>
      </div>
      <div *ngIf="customerInfoData?.reason" class="row">
        <div class="col-3">
          <span class="text-color">
            {{ 'CRM.SubProjectType' | translate }}:
          </span>
          <span class="bold-text-color">
            {{ customerInfoData?.reason }}
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="!isReadonly" class="col-1">
      <div class="row align-items-center">
        <div class="col-12 ta-center" style="z-index: 2;">
          <button mat-mini-fab color="primary" [matMenuTriggerFor]="menu">
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="goToCustomer()">
              <mat-icon class="dark-iconcolor mr-05">person</mat-icon>
              <span>{{ 'CRM_DETAIL.Customer' | translate }}</span>
            </button>
            <button mat-menu-item (click)="goToCrmDetail()">
              <mat-icon class="dark-iconcolor mr-05">workspaces</mat-icon>
              <span>{{ 'CRM_DETAIL.CRM' | translate }}</span>
            </button>
            <button mat-menu-item (click)="goToCrmBM()">
              <mat-icon class="dark-iconcolor mr-05">search</mat-icon>
              <span>{{ 'CRM_DETAIL.BM' | translate }}</span>
            </button>
            <button mat-menu-item (click)="goToPSForm()">
              <mat-icon class="dark-iconcolor mr-05">assignment</mat-icon>
              <span>{{ 'CRM_DETAIL.CurrentPS' | translate }}</span>
            </button>
            <button mat-menu-item (click)="goToDealsStatus()">
              <mat-icon class="dark-iconcolor mr-05">card_travel</mat-icon>
              <span>{{ 'CRM_DETAIL.DealsStatus' | translate }}</span>
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
  </div>
</mat-card> -->
