<div class="custom-header">
  <div>
    <div class="page-title">
      <span>{{ pageTitle }}</span>
    </div>
    <div
      *ngIf="pageTitle != 'Dashboard' && breadcrumb?.length"
      class="custom-breadcrumb"
    >
      <span class="clickable-link" (click)="goTo('dashboard')">
        Dashboard
      </span>
      <span> /</span>
      <span
        *ngFor="let link of breadcrumb; let i = index"
        (click)="i + 1 < breadcrumb.length ? goTo(link.path) : null"
        [ngClass]="i + 1 < breadcrumb?.length ? 'clickable-link' : ''"
      >
        {{ i + 1 < breadcrumb?.length ? link.label + " / " : link.label }}
      </span>
    </div>
  </div>
  <div class="user-info-container">
    <div class="notifications mr-30 p-relative">
      <button
        class="only-icon-button notices-bell"
        (click)="showNoticesBoard()"
        [disabled]="disableNotices"
      >
        <svg-icon name="bell" class="w20 black-icon"></svg-icon>
      </button>
      <div *ngIf="showNoticesBadge" class="bell-badge"></div>
    </div>
    <div class="user-name ml-30">
      <div class="name" (click)="goToAccount()">
        <span>{{ user?.name }} {{ user?.surname }}</span>
      </div>
      <div class="role">
        <span [matMenuTriggerFor]="submenu">{{
          "ROLES." + activeUserRole | translate
        }}</span>
        <mat-icon [matMenuTriggerFor]="submenu"> expand_more </mat-icon>
      </div>
      <mat-menu #submenu="matMenu">
        <ng-container *ngFor="let role of user?.roles">
          <button
            *ngIf="role !== activeUserRole"
            mat-menu-item
            (click)="changeRole(role)"
          >
            {{ "ROLES." + role | translate }}
          </button>
        </ng-container>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </div>
    <div class="user-image" (click)="goToAccount()">
      <img *ngIf="userImage" [src]="userImage" />
    </div>
  </div>
</div>
<ng-template #noticesBoard let-close="close" let-data>
  <div class="header h70 no-margin-top">
    <div class="title">
      <span>{{ "COMMON.NoticesBoard" | translate }}</span>
    </div>
    <button class="close-dialog" mat-dialog-close mat-icon-button>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="notices-container">
      <div *ngFor="let notice of notices" class="notice">
        <div class="date">
          {{ notice?.creationDate | date: "dd/MM/yyyy - hh:mm" }}
        </div>
        <div class="title">{{ notice?.title }}</div>
        <div class="description">{{ notice?.description }}</div>
        <button
          type="button"
          class="only-icon-button primary arrow"
          (click)="goToNotice(notice)"
          mat-dialog-close
        >
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </div>
    <button
      type="button"
      class="only-text-button primary go-to-board"
      (click)="goTo('notices-board')"
      mat-dialog-close
    >
      {{ "COMMON.ViewAllNotices" | translate }}
    </button>
  </div>
</ng-template>
