import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { CanActivate, CanDeactivate, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { IrFormComponent } from 'src/app/pages/ir-form/ir-form.component';
import { PsFormComponent } from 'src/app/pages/ps-form/ps-form.component';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanDeactivate<IrFormComponent | PsFormComponent> {
  constructor(
    private authService: AuthService,
    public router: Router,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['login']);
    return false;
  }

  // QUESTO BLOCCO DI CODICE VALE ORO!!!
  canDeactivate(target: IrFormComponent | PsFormComponent): Observable<boolean> {
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      const result = target.checkIfCurrentTabIsDirty();
      if (result.bool) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.width = '600px';
        dialogConfig.panelClass = 'custom-dialog-container';
        dialogConfig.data = {
          title: this.translate.instant('COMMON.Attention'),
          message: this.translate.instant('IR_FORM.UnsavedDataInFormSection', {
            section: this.translate.instant('INVALID_TABS.' + result.tabName),
          }),
          messageClass: 'error-color mb-0 fw-700',
          buttonTrue: this.translate.instant('COMMON.Stay'),
          buttonFalse: this.translate.instant('COMMON.Continue'),
        };
        const dialogRef: MatDialogRef<ConfirmationDialogComponent, any> =
          this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
          if (dialogResult) {
            subscriber.next(false);
          } else {
            subscriber.next(true);
          }
        });
      } else {
        subscriber.next(true);
      }
    });
  }
}
