import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { IrFormComponent } from 'src/app/components/ir-form-abstract/ir-form-abstract-components';
// import { CurrencyMaskConfig } from 'ng2-currency-mask';
import {
  ICustomerInformation,
  ICustomerRevenues,
} from 'src/app/models/customer-information';
import { Project } from 'src/app/models/project';
import { Note } from 'src/app/models/note';
import {
  currencyDefaultConfig,
  currencyQuantityConfig,
  currencyQuantityConfigWithoutDecimal,
  LAB_MANAGER_INTERNAL,
  NOTE_TYPE,
  RI_STATUS,
} from 'src/app/config';
import { NgxCurrencyModule } from 'ngx-currency';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { ISectors } from 'src/app/models/sectors';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ICountries } from 'src/app/models/countries';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-customer-information-form',
  templateUrl: './customer-information-form.component.html',
  styleUrls: ['./customer-information-form.component.scss'],
})
export class CustomerInformationFormComponent
  extends IrFormComponent
  implements OnInit, OnDestroy
{
  customerInformationForm: FormGroup;
  // noteForm: FormGroup;
  private subscriptions: Subscription = new Subscription();

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  // @Input() isIsp: boolean;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;
  @Input() noContract: boolean = false;

  @Output() submitFuncEmitter: EventEmitter<ICustomerInformation> =
    new EventEmitter();
  @Output() submitNoteFuncEmitter: EventEmitter<Note[]> = new EventEmitter();

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;
  currencyQuantityConfigWithoutDecimal: NgxCurrencyModule =
    currencyQuantityConfigWithoutDecimal;

  commerceSectors: ISectors[] = [];
  startDataOnInit: any;
  dataIsReady: boolean;
  isIsp: boolean = false;

  countries: ICountries[];

  constructor(
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private authService: AuthService,
    private irFormService: IrFormService
  ) {
    super(fb);
    this.customerInformationForm = this.fb.group({
      idCustomerInformation: [null],
      idCustomer: [null],
      startup: [null, [Validators.required]],
      hasExport: [null, [Validators.required]],
      productionType: [null, [Validators.required]],
      commerceSector: this.fb.group({
        idCommerceSector: [null, [Validators.required]],
      }),
      // productionDescription: [null, [Validators.maxLength(255)]],
      product: [null],
      distribution: [null],
      priceSelling: [null],
      communicationMarketing: [null],
      revenueCustomerList: this.fb.array([
        this.fb.group({
          idCustomerInfoRevenue: [null],
          year: [null],
          revenueCustomer: [null, Validators.required],
          revenueExport: [null],
          revenueImport: [null],
        }),
        this.fb.group({
          idCustomerInfoRevenue: [null],
          year: [null],
          revenueCustomer: [null, Validators.required],
          revenueExport: [null],
          revenueImport: [null],
        }),
        this.fb.group({
          idCustomerInfoRevenue: [null],
          year: [null],
          revenueCustomer: [null, Validators.required],
          revenueExport: [null],
          revenueImport: [null],
        }),
      ]),
      countriesOfImport: [null, [Validators.maxLength(255)]],
      hasExpDedicatedResource: [null, [Validators.required]],
      resourceTrained: [null, [Validators.required]],
      foreignLanguageKnown: [null, [Validators.maxLength(255)]],
      productCertification: [null, [Validators.maxLength(255)]],
      processCertification: [null, [Validators.maxLength(255)]],
      sectorProductAssociation: [null, [Validators.maxLength(255)]],
      // note: [null],
      // TODO: remove
      // exportStrategyDescription: [null, [Validators.maxLength(255)]],
      exportStrategyMarketAdopted: [null, [Validators.required]],
      exportStrategyChannelAdopted: [null, [Validators.required]],
      exportStrategyScoutingClients: [null, [Validators.required]],
      customerTechnicalInfo: this.fb.group({
        idCustomerTechnicalInfo: [null],
        employeeProd: [null],
        employeeCommerceItaly: [null],
        employeeCommerceExport: [null],
        employeeOther: [null],
        unexpressedProdCapacity: [null, [Validators.max(100)]],
        seasonalityProducts: [null],
        seasonalityProductsDesc: [null],
        incidenceTrasportCost: [null],
        // incidenceTrasportCostDesc: [null],
        existsPriceList: [null],
        priceListDesc: [null],
        paymentSystemDesc: [null],
        creditProtectionAdopted: [null],
        creditProtectionAdoptedDesc: [null],
        surrenderDesc: [null],
        packingDesc: [null],
        deliveryTimeDesc: [null],
        degreeReliability: [null, [Validators.max(100)]],
        annualTurnoverCoverCost: [null],
        annualSalesTargetValue: [null],
        annualSalesTargetQty: [null],
      }),
    });
    // this.noteForm = fb.group({
    //   notes: fb.array([
    //     fb.group({
    //       idAgentNote: [null],
    //       description: [null],
    //       idAgentNoteType: [NOTE_TYPE.ReferentNote],
    //       idProject: [null],
    //     }),
    //     fb.group({
    //       idAgentNote: [null],
    //       description: [null],
    //       idAgentNoteType: [NOTE_TYPE.CustomNote],
    //       idProject: [null],
    //     }),
    //     fb.group({
    //       idAgentNote: [null],
    //       description: [null],
    //       idAgentNoteType: [NOTE_TYPE.ProductNote],
    //       idProject: [null],
    //     }),
    //     fb.group({
    //       idAgentNote: [null],
    //       description: [null],
    //       idAgentNoteType: [NOTE_TYPE.ManagerNote],
    //       idProject: [null],
    //     }),
    //   ]),
    // });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialiValues: Project) => {
          if (initialiValues.idProjectStatus >= RI_STATUS.IspWorking) {
            this.isIsp = true;
          }
          this.dataIsReady = true;
          if (this.isIsp) {
            this.commerceSectors = initialiValues.commerceSector;
            this.customerInformationForm.patchValue({
              ...initialiValues.customerInformation,
              idCustomer: initialiValues?.customer?.idCustomer,
              revenueCustomerList: this.getRevenueCustomersList(
                initialiValues.customerInformation?.revenueCustomerList
              ),
              commerceSector:
                initialiValues?.customerInformation?.commerceSector || {},
              customerTechnicalInfo:
                initialiValues?.customerInformation?.customerTechnicalInfo ||
                {},
            });
            this.customerInformationForm
              .get('product')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('product')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('distribution')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('distribution')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('priceSelling')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('priceSelling')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('communicationMarketing')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('communicationMarketing')
              .updateValueAndValidity();

            // Technical info section
            this.customerInformationForm
              .get('customerTechnicalInfo.employeeProd')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.employeeProd')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.employeeCommerceItaly')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.employeeCommerceItaly')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.employeeCommerceExport')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.employeeCommerceExport')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.employeeOther')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.employeeOther')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.unexpressedProdCapacity')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.unexpressedProdCapacity')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.seasonalityProducts')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.seasonalityProducts')
              .updateValueAndValidity();
            if (
              this.customerInformationForm.get(
                'customerTechnicalInfo.seasonalityProducts'
              ).value
            ) {
              this.customerInformationForm
                .get('customerTechnicalInfo.seasonalityProductsDesc')
                .setValidators(Validators.required);
              this.customerInformationForm
                .get('customerTechnicalInfo.seasonalityProductsDesc')
                .updateValueAndValidity();
            }
            this.customerInformationForm
              .get('customerTechnicalInfo.incidenceTrasportCost')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.incidenceTrasportCost')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.existsPriceList')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.existsPriceList')
              .updateValueAndValidity();
            if (
              this.customerInformationForm.get(
                'customerTechnicalInfo.existsPriceList'
              ).value
            ) {
              this.customerInformationForm
                .get('customerTechnicalInfo.priceListDesc')
                .setValidators(Validators.required);
              this.customerInformationForm
                .get('customerTechnicalInfo.priceListDesc')
                .updateValueAndValidity();
            }
            this.customerInformationForm
              .get('customerTechnicalInfo.paymentSystemDesc')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.paymentSystemDesc')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.creditProtectionAdopted')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.creditProtectionAdopted')
              .updateValueAndValidity();
            if (
              this.customerInformationForm.get(
                'customerTechnicalInfo.creditProtectionAdopted'
              ).value
            ) {
              this.customerInformationForm
                .get('customerTechnicalInfo.creditProtectionAdoptedDesc')
                .setValidators(Validators.required);
              this.customerInformationForm
                .get('customerTechnicalInfo.creditProtectionAdoptedDesc')
                .updateValueAndValidity();
            }
            this.customerInformationForm
              .get('customerTechnicalInfo.surrenderDesc')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.surrenderDesc')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.packingDesc')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.packingDesc')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.deliveryTimeDesc')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.deliveryTimeDesc')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.degreeReliability')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.degreeReliability')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.annualTurnoverCoverCost')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.annualTurnoverCoverCost')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.annualSalesTargetValue')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.annualSalesTargetValue')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('customerTechnicalInfo.annualSalesTargetQty')
              .setValidators(Validators.required);
            this.customerInformationForm
              .get('customerTechnicalInfo.annualSalesTargetQty')
              .updateValueAndValidity();
            this.customerInformationForm.markAsPristine();
            this.customerInformationForm.markAsUntouched();
            // this.notes.controls.forEach((noteForm) =>
            //   noteForm.get('idProject').setValue(initialiValues.idProject)
            // );
            // initialiValues.notes?.forEach((note: Note): void =>
            //   this.initilizaNoteForm(note.idAgentNoteType - 1, note)
            // );
            // this.noteForm.markAsPristine();
            // this.noteForm.markAsUntouched();
          } else {
            this.commerceSectors = initialiValues.commerceSector;
            this.customerInformationForm.patchValue({
              ...initialiValues.customerInformation,
              idCustomer: initialiValues?.customer?.idCustomer,
              revenueCustomerList: this.getRevenueCustomersList(
                initialiValues.customerInformation?.revenueCustomerList
              ),
              commerceSector:
                initialiValues?.customerInformation?.commerceSector || {},
              customerTechnicalInfo:
                initialiValues?.customerInformation?.customerTechnicalInfo ||
                {},
            });
            this.customerInformationForm
              .get('product')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('product')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('distribution')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('distribution')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('priceSelling')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('priceSelling')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('communicationMarketing')
              .setValidators(this.__getValidators());
            this.customerInformationForm
              .get('communicationMarketing')
              .updateValueAndValidity();
            this.customerInformationForm.markAsPristine();
            this.customerInformationForm.markAsUntouched();
          }
          if (this.noContract) {
            (
              this.customerInformationForm.get(
                'revenueCustomerList'
              ) as FormArray
            )
              .at(0)
              .get('revenueCustomer')
              .setValidators(null);
            (
              this.customerInformationForm.get(
                'revenueCustomerList'
              ) as FormArray
            )
              .at(0)
              .get('revenueCustomer')
              .updateValueAndValidity();
            (
              this.customerInformationForm.get(
                'revenueCustomerList'
              ) as FormArray
            )
              .at(1)
              .get('revenueCustomer')
              .setValidators(null);
            (
              this.customerInformationForm.get(
                'revenueCustomerList'
              ) as FormArray
            )
              .at(1)
              .get('revenueCustomer')
              .updateValueAndValidity();
            (
              this.customerInformationForm.get(
                'revenueCustomerList'
              ) as FormArray
            )
              .at(2)
              .get('revenueCustomer')
              .setValidators(null);
            (
              this.customerInformationForm.get(
                'revenueCustomerList'
              ) as FormArray
            )
              .at(2)
              .get('revenueCustomer')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('exportStrategyMarketAdopted')
              .setValidators(null);
            this.customerInformationForm
              .get('exportStrategyMarketAdopted')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('exportStrategyChannelAdopted')
              .setValidators(null);
            this.customerInformationForm
              .get('exportStrategyChannelAdopted')
              .updateValueAndValidity();
            this.customerInformationForm
              .get('exportStrategyScoutingClients')
              .setValidators(null);
            this.customerInformationForm
              .get('exportStrategyScoutingClients')
              .updateValueAndValidity();
          }
          if (this.isLabManagerInternal || this.isReadOnly) {
            this.customerInformationForm.disable();
            // this.noteForm.disable();
          }
          this.startDataOnInit = {
            info: this.customerInformationForm.getRawValue(),
            // note: this.noteForm.getRawValue(),
          };
          this.countries = initialiValues.countries;
          // this.customerInformationForm
          //   .get('customerTechnicalInfo.employeeProd')
          //   .setValidators([this.checkPercentageRepartition()]);
          // this.customerInformationForm
          //   .get('customerTechnicalInfo.employeeCommerceItaly')
          //   .setValidators([this.checkPercentageRepartition()]);
          // this.customerInformationForm
          //   .get('customerTechnicalInfo.employeeCommerceExport')
          //   .setValidators([this.checkPercentageRepartition()]);
          // this.customerInformationForm
          //   .get('customerTechnicalInfo.employeeOther')
          //   .setValidators([this.checkPercentageRepartition()]);
          // this.updateValidity();
        },
      })
    );
  }

  get revenuesCustomer(): FormArray {
    return this.customerInformationForm.get('revenueCustomerList') as FormArray;
  }

  toggleDescriptionRequired(field: string, value: boolean): void {
    if (value) {
      this.customerInformationForm
        .get(`customerTechnicalInfo.${field}`)
        .setValidators(Validators.required);
    } else {
      this.customerInformationForm
        .get(`customerTechnicalInfo.${field}`)
        .clearValidators();
      this.customerInformationForm
        .get(`customerTechnicalInfo.${field}`)
        .reset();
    }

    this.customerInformationForm
      .get(`customerTechnicalInfo.${field}`)
      .updateValueAndValidity();
  }

  /**
   * @description Get the notes formArray in FormGroup for easy access
   */
  // private get notes(): FormArray {
  //   return this.noteForm.get('notes') as FormArray;
  // }

  /**
   * @description Inizialize form array of revenueCustomerList
   * @param revenueCustomerList The revenues list of customer
   */
  getRevenueCustomersList(
    revenueCustomerList: ICustomerRevenues[]
  ): ICustomerRevenues[] {
    if (!revenueCustomerList?.length) {
      const year = new Date().getFullYear() - 1;
      return [
        {
          year,
          revenueCustomer: null,
          revenueExport: null,
          revenueImport: null,
        },
        {
          year: year - 1,
          revenueCustomer: null,
          revenueExport: null,
          revenueImport: null,
        },
        {
          year: year - 2,
          revenueCustomer: null,
          revenueExport: null,
          revenueImport: null,
        },
      ];
    }
    const fillArray = [
      {
        idCustomerInfoRevenue: revenueCustomerList[0].idCustomerInfoRevenue,
        year: revenueCustomerList[0].year,
        revenueCustomer: revenueCustomerList[0].revenueCustomer,
        revenueExport: revenueCustomerList[0].revenueExport,
        revenueImport: revenueCustomerList[0].revenueImport,
      },
      {
        idCustomerInfoRevenue: revenueCustomerList[1]?.idCustomerInfoRevenue,
        year: revenueCustomerList[1]?.year || revenueCustomerList[0].year - 1,
        revenueCustomer: revenueCustomerList[1]?.revenueCustomer,
        revenueExport: revenueCustomerList[1]?.revenueExport,
        revenueImport: revenueCustomerList[1]?.revenueImport,
      },
      {
        idCustomerInfoRevenue: revenueCustomerList[2]?.idCustomerInfoRevenue,
        year: revenueCustomerList[2]?.year || revenueCustomerList[0].year - 2,
        revenueCustomer: revenueCustomerList[2]?.revenueCustomer,
        revenueExport: revenueCustomerList[2]?.revenueExport,
        revenueImport: revenueCustomerList[2]?.revenueImport,
      },
    ];
    fillArray.sort((a, b) => a.year - b.year);
    return fillArray;
  }

  /**
   * @description Initialize the form in `idxNoteth` item of formArray
   * @param idxNote index of noteform in array to inizialize
   * @param note the value which initialize the form
   */
  // initilizaNoteForm(idxNote: number, note: Note): void {
  //   this.notes.at(idxNote).patchValue(note);
  // }

  onSubmit(): void {
    if (this.isIsp) {
      if (
        this.customerInformationForm.valid &&
        this.customerInformationForm.dirty
      ) {
        this.submitFuncEmitter.emit(this.customerInformationForm.getRawValue());
        // this.customerInformationForm.markAsPristine();
        // this.customerInformationForm.markAsUntouched();
      }
      // if (this.noteForm.valid && this.noteForm.dirty) {
      //   const dirtyNotes: FormArray = this.fb.array(
      //     this.notes.controls.filter((note: FormGroup) => note.dirty)
      //   );
      //   const body: Note[] = dirtyNotes.getRawValue();
      //   this.submitNoteFuncEmitter.emit(body);
      //   // this.noteForm.markAsPristine();
      //   // this.noteForm.markAsUntouched();
      // }
    } else {
      if (
        this.customerInformationForm.valid &&
        this.customerInformationForm.dirty
      ) {
        this.submitFuncEmitter.emit(this.customerInformationForm.getRawValue());
        // this.customerInformationForm.markAsPristine();
        // this.customerInformationForm.markAsUntouched();
      }
    }
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        {
          info: this.customerInformationForm.getRawValue(),
          // note: this.noteForm.getRawValue(),
        },
        this.irFormService.replacer
      )
    );
  }

  checkInvalidForm(): boolean {
    this.customerInformationForm.markAllAsTouched();
    return this.customerInformationForm?.invalid;
  }

  // checkPercentageRepartition(): any {
  //   return () => {
  //     const value1 = this.customerInformationForm?.get(
  //       'customerTechnicalInfo.employeeProd'
  //     ).value;
  //     const value2 = this.customerInformationForm?.get(
  //       'customerTechnicalInfo.employeeCommerceItaly'
  //     ).value;
  //     const value3 = this.customerInformationForm?.get(
  //       'customerTechnicalInfo.employeeCommerceExport'
  //     ).value;
  //     const value4 = this.customerInformationForm?.get(
  //       'customerTechnicalInfo.employeeOther'
  //     ).value;
  //     if (value1 + value2 + value3 + value4 > 100) {
  //       return { percentageBreakdownSurplus: true };
  //     }
  //     return null;
  //   };
  //   // return (control: AbstractControl) => {
  //   //   if (
  //   //     this.customerInformationForm?.get('customerTechnicalInfo.employeeProd')
  //   //       ?.value +
  //   //       this.customerInformationForm?.get(
  //   //         'customerTechnicalInfo.employeeCommerceItaly'
  //   //       )?.value +
  //   //       this.customerInformationForm?.get(
  //   //         'customerTechnicalInfo.employeeCommerceExport'
  //   //       )?.value +
  //   //       this.customerInformationForm?.get(
  //   //         'customerTechnicalInfo.employeeOther'
  //   //       )?.value >
  //   //     100
  //   //   ) {
  //   //     if (this.isIsp) {
  //   //       return { percentageBreakdownSurplus: true };
  //   //     } else {
  //   //       return null;
  //   //     }
  //   //   } else if (
  //   //     this.customerInformationForm?.get('customerTechnicalInfo.employeeProd')
  //   //       ?.value +
  //   //       this.customerInformationForm?.get(
  //   //         'customerTechnicalInfo.employeeCommerceItaly'
  //   //       )?.value +
  //   //       this.customerInformationForm?.get(
  //   //         'customerTechnicalInfo.employeeCommerceExport'
  //   //       )?.value +
  //   //       this.customerInformationForm?.get(
  //   //         'customerTechnicalInfo.employeeOther'
  //   //       )?.value <
  //   //     100
  //   //   ) {
  //   //     if (this.isIsp) {
  //   //       return { percentageBreakdownLack: true };
  //   //     } else {
  //   //       return null;
  //   //     }
  //   //   } else {
  //   //     return null;
  //   //   }
  //   // };
  // }

  // updateValidity(): void {
  //   this.customerInformationForm
  //     ?.get('customerTechnicalInfo.employeeProd')
  //     ?.updateValueAndValidity();
  //   this.customerInformationForm
  //     ?.get('customerTechnicalInfo.employeeCommerceItaly')
  //     ?.updateValueAndValidity();
  //   this.customerInformationForm
  //     ?.get('customerTechnicalInfo.employeeCommerceExport')
  //     ?.updateValueAndValidity();
  //   this.customerInformationForm
  //     ?.get('customerTechnicalInfo.employeeOther')
  //     ?.updateValueAndValidity();
  //   this.customerInformationForm
  //     ?.get('customerTechnicalInfo.employeeProd')
  //     ?.markAsTouched();
  //   this.customerInformationForm
  //     ?.get('customerTechnicalInfo.employeeCommerceItaly')
  //     ?.markAsTouched();
  //   this.customerInformationForm
  //     ?.get('customerTechnicalInfo.employeeCommerceExport')
  //     ?.markAsTouched();
  //   this.customerInformationForm
  //     ?.get('customerTechnicalInfo.employeeOther')
  //     ?.markAsTouched();
  // }

  private __getValidators(): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    // This Controls must be required only if User have isp functionality create and edit
    if (this.functionalities.isp.create || this.functionalities.isp.edit) {
      validators.push(
        Validators.required,
        Validators.min(1),
        Validators.max(10)
      );
    }
    return validators;
  }

  calculatePercentage(type: string): number {
    let ret: number;
    const total: number =
      this.customerInformationForm.get('customerTechnicalInfo.employeeProd')
        ?.value +
      this.customerInformationForm.get(
        'customerTechnicalInfo.employeeCommerceItaly'
      )?.value +
      this.customerInformationForm.get(
        'customerTechnicalInfo.employeeCommerceExport'
      )?.value +
      this.customerInformationForm.get('customerTechnicalInfo.employeeOther')
        ?.value;
    switch (type) {
      case 'prod':
        ret =
          (this.customerInformationForm.get(
            'customerTechnicalInfo.employeeProd'
          )?.value /
            total) *
          100;
        break;
      case 'italy':
        ret =
          (this.customerInformationForm.get(
            'customerTechnicalInfo.employeeCommerceItaly'
          )?.value /
            total) *
          100;
        break;
      case 'export':
        ret =
          (this.customerInformationForm.get(
            'customerTechnicalInfo.employeeCommerceExport'
          )?.value /
            total) *
          100;
        break;
      case 'other':
        ret =
          (this.customerInformationForm.get(
            'customerTechnicalInfo.employeeOther'
          )?.value /
            total) *
          100;
        break;
    }
    return ret;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
