<!--<div class="note-container">
      <app-note-button *ngIf="projectEntities?.supervisorNotes?.length"
        [expanded]="noteIsExpanded"
        [notes]="projectEntities?.supervisorNotes" 
        (emitAction)="closeOpenNote($event)">
      </app-note-button>
    </div>-->

<!--NO CONTRACT GROUP-->
<div *ngIf="isNoContract" class="p-relative">
  <mat-tab-group
    mat-align-tabs="center"
    dynamicHeight
    class="custom-mat-tab"
    #matTabGroup
    [(selectedIndex)]="index"
    (focusChange)="showDialogCurrentTabDirty()"
  >
    <!--AZIENDA-->
    <mat-tab>
      <ng-template matTabLabel>
        <span>{{ "PROJECT_SECTIONS.CompanyInfo" | translate }}</span>
      </ng-template>
      <app-customer-form
        #customer
        [isReadOnly]="isReadOnly"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [isNoContract]="isNoContract"
        (submitFuncEmitter)="updateCustomer($event)"
      ></app-customer-form>
    </mat-tab>
    <!--SCHEDA AZIENDA-->
    <mat-tab>
      <ng-template matTabLabel>
        <span>{{ "PROJECT_SECTIONS.CompanyTab" | translate }}</span>
      </ng-template>
      <app-customer-information-form
        #customerInformation
        [initialValues]="$getProjectReady"
        [isReadOnly]="isReadOnly"
        [noContract]="isNoContract"
        [translations]="translations"
        [functionalities]="functionalities"
        (submitFuncEmitter)="updateCustomerInformation($event)"
      ></app-customer-information-form>
    </mat-tab>
    <!--PRODOTTI-->
    <mat-tab>
      <ng-template matTabLabel>
        <span>{{ "PROJECT_SECTIONS.Products&Services" | translate }}</span>
      </ng-template>
      <app-products-services-form
        #productsServices
        [functionalities]="functionalities"
        [isReadOnly]="isReadOnly"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [index]="index"
        [isAgent]="isAgent"
        [tabIndex]="!isNoContract ? 3 : 2"
        [noContract]="isNoContract"
        (submitFuncEmitter)="updateProductsServices($event)"
        (deleteProductEmitter)="deleteProduct($event)"
        (deleteCompanyEmitter)="deleteProductCompany($event)"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (reloadContentsEmitter)="refreshProductsServices()"
      ></app-products-services-form>
    </mat-tab>
  </mat-tab-group>
</div>

<!--AGENT GROUP-->
<div
  *ngIf="
    !isNoContract &&
    !isIsp &&
    (!functionalities.isp?.enabled || isAdminOrDirection)
  "
  class="p-relative"
>
  <div class="custom-border-bottom"></div>
  <div
    *ngIf="!isReadOnly && projectEntities && project?.type === 0"
    class="global-actions"
  >
    <button
      *ngIf="isAgent || isLabManager || isLabManagerInternal"
      type="button"
      class="only-icon-button primary"
      (click)="sendIr()"
    >
      <svg-icon name="send_icon" class="w20"></svg-icon>
      <span class="ml-10 primary-color">{{ "COMMON.Send" | translate }}</span>
    </button>
    <div
      [matTooltip]="
        atLeastOneUncheckedNote
          ? ('IR_FORM.ThereAreSupervisorNotesOnProject' | translate)
          : null
      "
    >
      <button
        *ngIf="isAgentSupervisor || isLabManagerSupervisor"
        type="button"
        class="only-icon-button primary"
        [disabled]="atLeastOneUncheckedNote"
        (click)="approveIr()"
      >
        <svg-icon name="accept_2_icon" class="w20"></svg-icon>
        <span class="ml-10 primary-color">{{
          "COMMON.Approve" | translate
        }}</span>
      </button>
    </div>
    <button
      *ngIf="isAgentSupervisor || isLabManagerSupervisor"
      type="button"
      class="only-icon-button error ml-20"
      (click)="rejectIr()"
    >
      <svg-icon name="decline_2_icon" class="w20"></svg-icon>
      <span class="ml-10 error-color">{{ "COMMON.Reject" | translate }}</span>
    </button>
  </div>
  <mat-tab-group
    mat-align-tabs="center"
    dynamicHeight
    class="custom-mat-tab"
    #matTabGroup
    [(selectedIndex)]="index"
    (focusChange)="showDialogCurrentTabDirty()"
    [ngClass]="{
      'two-buttons': isAgentSupervisor,
      'one-button': !isAgentSupervisor && !isReadOnly
    }"
  >
    <!--AZIENDA-->
    <mat-tab label="customer">
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Customer?.length"
          [matBadge]="splittedNotes?.Customer?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.CompanyInfo" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Customer?.length">
          {{ "PROJECT_SECTIONS.CompanyInfo" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="splittedNotes?.Customer?.length || (isSupervisor && !isReadOnly)"
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Customer"
        [notes]="splittedNotes?.Customer"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-customer-form
        #customer
        [tabHasChanged]="tabHasChanged"
        [initialValues]="$getProjectReady"
        [isReadOnly]="isReadOnly"
        [translations]="translations"
        (submitFuncEmitter)="updateCustomer($event)"
      ></app-customer-form>
    </mat-tab>
    <!--SCHEDA AZIENDA-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.CustomerInformation?.length"
          [matBadge]="splittedNotes?.CustomerInformation?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.CompanyTab" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.CustomerInformation?.length">
          {{ "PROJECT_SECTIONS.CompanyTab" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.CustomerInformation?.length ||
          (isSupervisor && !isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.CustomerInformation"
        [notes]="splittedNotes?.CustomerInformation"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-customer-information-form
        #customerInformation
        [initialValues]="$getProjectReady"
        [isReadOnly]="isReadOnly"
        [translations]="translations"
        [functionalities]="functionalities"
        (submitFuncEmitter)="updateCustomerInformation($event)"
      ></app-customer-information-form>
    </mat-tab>
    <!--SCHEDA CONTRATTO-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Contract?.length"
          [matBadge]="splittedNotes?.Contract?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.ContractTab" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Contract?.length">
          {{ "PROJECT_SECTIONS.ContractTab" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="splittedNotes?.Contract?.length || (isSupervisor && !isReadOnly)"
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Contract"
        [notes]="splittedNotes?.Contract"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-contract-form
        #contract
        [initialValues]="$getProjectReady"
        [otherDocumentsObs]="otherDocuments.asObservable()"
        [isReadOnly]="isReadOnly"
        [translations]="translations"
        [userRole]="userRole"
        [documentChanged]="$documetLoadedEmitter"
        (uploadDocumentEmitter)="uploadContractDocument($event)"
        (uploadOtherDocumentEmitter)="uploadOtherDocument($event)"
        (refreshOtherDocumentsEmitter)="refreshOtherDocuments($event)"
        (submitFuncEmitter)="saveContract($event)"
        (viewDocumentEmitter)="viewDocument($event)"
      ></app-contract-form>
    </mat-tab>
    <!--PRODOTTI/SERVIZI-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Products?.length"
          [matBadge]="splittedNotes?.Products?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.Products&Services" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Products?.length">
          {{ "PROJECT_SECTIONS.Products&Services" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="splittedNotes?.Products?.length || (isSupervisor && !isReadOnly)"
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Products"
        [notes]="splittedNotes?.Products"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-products-services-form
        #productsServices
        [functionalities]="functionalities"
        [isReadOnly]="isReadOnly"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [index]="index"
        [tabIndex]="!isNoContract ? 3 : 2"
        [noContract]="isNoContract"
        [isIsp]="isIsp"
        [isAgent]="isAgent"
        (submitFuncEmitter)="updateProductsServices($event)"
        (deleteProductEmitter)="deleteProduct($event)"
        (deleteCompanyEmitter)="deleteProductCompany($event)"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (reloadContentsEmitter)="refreshProductsServices()"
      ></app-products-services-form>
    </mat-tab>
    <!--MERCATI-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Markets?.length"
          [matBadge]="splittedNotes?.Markets?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.Markets" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Markets?.length">
          {{ "PROJECT_SECTIONS.Markets" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="splittedNotes?.Markets?.length || (isSupervisor && !isReadOnly)"
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Markets"
        [notes]="splittedNotes?.Markets"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-market-form
        #markets
        [functionalities]="functionalities"
        [isReadOnly]="isReadOnly"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [index]="index"
        [tabIndex]="4"
        [isIsp]="isIsp"
        (submitFuncEmitter)="updateMarkets($event)"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (refreshMarkets)="refreshMarkets()"
      ></app-market-form>
    </mat-tab>
    <!--DISTRIBUZIONE-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Distribution?.length"
          [matBadge]="splittedNotes?.Distribution?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.Distribution" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Distribution?.length">
          {{ "PROJECT_SECTIONS.Distribution" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.Distribution?.length || (isSupervisor && !isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Distribution"
        [notes]="splittedNotes?.Distribution"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-channels-form
        #distribution
        [functionalities]="functionalities"
        [isReadOnly]="isReadOnly"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [index]="index"
        [tabIndex]="5"
        [isIsp]="isIsp"
        [isAgent]="isAgent"
        (submitFuncEmitter)="saveChannel($event)"
        (deleteCompanyEmitter)="deleteChannelCompany($event)"
        (deleteChannelEmitter)="deleteChannel($event)"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (refreshChannels)="refreshChannels()"
      ></app-channels-form>
    </mat-tab>
    <!--CONCORRENTI DI RIFERIMENTO-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.ReferenceCompetitors?.length"
          [matBadge]="splittedNotes?.ReferenceCompetitors?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.ReferenceCompetitors" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.ReferenceCompetitors?.length">
          {{ "PROJECT_SECTIONS.ReferenceCompetitors" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.ReferenceCompetitors?.length ||
          (isSupervisor && !isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.ReferenceCompetitors"
        [notes]="splittedNotes?.ReferenceCompetitors"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-reference-competitors
        #referenceCompetitors
        [initialValues]="$getProjectReady"
        [isReadOnly]="isReadOnly"
        [translations]="translations"
        [index]="index"
        [tabIndex]="6"
        [isIsp]="isIsp"
        [isAgent]="isAgent"
        (submitFuncEmitter)="updateReferenceCompetitors($event)"
        (deleteCompetitorCustomerEmitter)="
          deleteCompetitorCustomerEmitter($event)
        "
        (deleteProductEmitter)="deleteCompetitorProduct($event)"
        (deletePresenceMarketEmitter)="deleteCompetitorMarketArea($event)"
        (deleteDistributionChannelEmitter)="
          deleteCompetitorDistributionChannel($event)
        "
        [functionalities]="functionalities"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (refreshReferenceCompetitors)="refreshReferenceCompetitors()"
      ></app-reference-competitors>
    </mat-tab>
    <!--NOTE-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Notes?.length"
          [matBadge]="splittedNotes?.Notes?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.Notes" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Notes?.length">
          {{ "PROJECT_SECTIONS.Notes" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="splittedNotes?.Notes?.length || (isSupervisor && !isReadOnly)"
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Notes"
        [notes]="splittedNotes?.Notes"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-note-form
        #notes
        [isSupervisor]="isSupervisor"
        [isReadOnly]="isReadOnly"
        [isIsp]="isIsp"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [isUserAndPreviousUser]="isUserAndPreviousUser"
        (submitFuncEmitter)="saveNotes($event)"
        (sendIrEmitter)="sendIr($event)"
        (rejectIrEmitter)="rejectIr($event)"
        (approveIrEmitter)="approveIr($event)"
      ></app-note-form>
    </mat-tab>
  </mat-tab-group>
</div>

<!--lab manager GROUP-->
<div
  *ngIf="
    !isNoContract &&
    isIsp &&
    (functionalities.isp?.enabled || isAdminOrDirection)
  "
  class="p-relative"
>
  <div
    *ngIf="!isReadOnly && projectEntities && project?.type === 0"
    class="global-actions"
  >
    <button
      *ngIf="isAgent || isLabManager || isLabManagerInternal"
      type="button"
      class="only-icon-button primary"
      (click)="sendIr()"
    >
      <svg-icon name="send_icon" class="w20"></svg-icon>
      <span class="ml-10 primary-color">{{
        isIsp ? ("COMMON.SendPS" | translate) : ("COMMON.Send" | translate)
      }}</span>
    </button>
    <div
      [matTooltip]="
        atLeastOneUncheckedNote
          ? ('IR_FORM.ThereAreSupervisorNotesOnProject' | translate)
          : null
      "
    >
      <button
        *ngIf="isAgentSupervisor || isLabManagerSupervisor"
        type="button"
        class="only-icon-button primary"
        [disabled]="atLeastOneUncheckedNote"
        (click)="approveIr()"
      >
        <svg-icon name="accept_2_icon" class="w20"></svg-icon>
        <span class="ml-10 primary-color">{{
          "COMMON.Approve" | translate
        }}</span>
      </button>
    </div>
    <button
      *ngIf="isAgentSupervisor || isLabManagerSupervisor"
      type="button"
      class="only-icon-button error ml-20"
      (click)="rejectIr()"
    >
      <svg-icon name="decline_2_icon" class="w20"></svg-icon>
      <span class="ml-10 error-color">{{ "COMMON.Reject" | translate }}</span>
    </button>
  </div>
  <div
    *ngIf="
      isReadOnly &&
      isLabManagerSupervisor &&
      (project.idProjectStatus === projectStatus.IspDelivered ||
        project.idProjectStatus >= projectStatus.IspDelivered2) &&
      project?.type === 0
    "
    class="global-actions"
  >
    <button
      type="button"
      class="only-icon-button primary"
      (click)="approveIr()"
    >
      <svg-icon name="accept_2_icon" class="w20"></svg-icon>
      <!-- <span class="ml-10 primary-color">{{
        "COMMON.Approve" | translate
      }}</span> -->
    </button>
    <button
      type="button"
      class="only-icon-button error ml-20"
      (click)="rejectIr()"
    >
      <svg-icon name="decline_2_icon" class="w20"></svg-icon>
      <!-- <span class="ml-10 error-color">{{ "COMMON.Reject" | translate }}</span> -->
    </button>
  </div>
  <mat-tab-group
    mat-align-tabs="center"
    dynamicHeight
    class="custom-mat-tab"
    #matTabGroup
    [(selectedIndex)]="index"
    (focusChange)="showDialogCurrentTabDirty()"
    [ngClass]="{
      'two-buttons': isLabManagerSupervisor || isManagerAndSupervisor,
      'one-button':
        !isLabManagerSupervisor && !isManagerAndSupervisor && !isReadOnly
    }"
  >
    <!--AZIENDA-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Customer?.length"
          [matBadge]="splittedNotes?.Customer?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.CompanyInfo" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Customer?.length">
          {{ "PROJECT_SECTIONS.CompanyInfo" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.Customer?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Customer"
        [notes]="splittedNotes?.Customer"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-customer-form
        #customer
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        (submitFuncEmitter)="updateCustomer($event)"
      ></app-customer-form>
    </mat-tab>
    <!--SCHEDA AZIENDA-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.CustomerInformation?.length"
          [matBadge]="splittedNotes?.CustomerInformation?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.CompanyTab" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.CustomerInformation?.length">
          {{ "PROJECT_SECTIONS.CompanyTab" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.CustomerInformation?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.CustomerInformation"
        [notes]="splittedNotes?.CustomerInformation"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-customer-information-form
        #customerInformation
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [functionalities]="functionalities"
        [isIsp]="isIsp"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        (submitFuncEmitter)="updateCustomerInformation($event)"
        (submitNoteFuncEmitter)="saveNotes($event)"
      ></app-customer-information-form>
    </mat-tab>
    <!--SCHEDA CONTRATTO-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Contract?.length"
          [matBadge]="splittedNotes?.Contract?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.ContractTab" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Contract?.length">
          {{ "PROJECT_SECTIONS.ContractTab" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.Contract?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Contract"
        [notes]="splittedNotes?.Contract"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-contract-form
        #contract
        [initialValues]="$getProjectReady"
        [otherDocumentsObs]="otherDocuments.asObservable()"
        [translations]="translations"
        [userRole]="userRole"
        [documentChanged]="$documetLoadedEmitter"
        [isLabManagerInternal]="isLabManagerInternal"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [isReadOnly]="isReadOnly"
        (uploadDocumentEmitter)="uploadContractDocument($event)"
        (uploadOtherDocumentEmitter)="uploadOtherDocument($event)"
        (refreshOtherDocumentsEmitter)="refreshOtherDocuments($event)"
        (submitFuncEmitter)="saveContract($event)"
        (viewDocumentEmitter)="viewDocument($event)"
      ></app-contract-form>
    </mat-tab>
    <!--PRODOTTI/SERVIZI-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Products?.length"
          [matBadge]="splittedNotes?.Products?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.Products&Services" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Products?.length">
          {{ "PROJECT_SECTIONS.Products&Services" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.Products?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Products"
        [notes]="splittedNotes?.Products"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-products-services-form
        #productsServices
        [functionalities]="functionalities"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [index]="index"
        [tabIndex]="!isNoContract ? 3 : 2"
        [noContract]="isNoContract"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        [isIsp]="isIsp"
        (submitFuncEmitter)="updateProductsServices($event)"
        (deleteProductEmitter)="deleteProduct($event)"
        (deleteCompanyEmitter)="deleteProductCompany($event)"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (reloadContentsEmitter)="refreshProductsServices()"
      ></app-products-services-form>
    </mat-tab>
    <!--MERCATI-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Markets?.length"
          [matBadge]="splittedNotes?.Markets?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.Markets" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Markets?.length">
          {{ "PROJECT_SECTIONS.Markets" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.Markets?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Markets"
        [notes]="splittedNotes?.Markets"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-market-form
        #markets
        [functionalities]="functionalities"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [index]="index"
        [tabIndex]="4"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        [isIsp]="isIsp"
        (submitFuncEmitter)="updateMarkets($event)"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (refreshMarkets)="refreshMarkets()"
      ></app-market-form>
    </mat-tab>
    <!--DISTRIBUZIONE-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Distribution?.length"
          [matBadge]="splittedNotes?.Distribution?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.Distribution" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Distribution?.length">
          {{ "PROJECT_SECTIONS.Distribution" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.Distribution?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Distribution"
        [notes]="splittedNotes?.Distribution"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-channels-form
        #distribution
        [functionalities]="functionalities"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [index]="index"
        [tabIndex]="5"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        [isIsp]="isIsp"
        [isAgent]="isAgent"
        (submitFuncEmitter)="saveChannel($event)"
        (deleteCompanyEmitter)="deleteChannelCompany($event)"
        (deleteChannelEmitter)="deleteChannel($event)"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (refreshChannels)="refreshChannels()"
      ></app-channels-form>
    </mat-tab>
    <!--CONCORRENTI DI RIFERIMENTO-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.ReferenceCompetitors?.length"
          [matBadge]="splittedNotes?.ReferenceCompetitors?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.ReferenceCompetitors" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.ReferenceCompetitors?.length">
          {{ "PROJECT_SECTIONS.ReferenceCompetitors" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.ReferenceCompetitors?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.ReferenceCompetitors"
        [notes]="splittedNotes?.ReferenceCompetitors"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-reference-competitors
        #referenceCompetitors
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [index]="index"
        [tabIndex]="6"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        [isIsp]="isIsp"
        [isAgent]="isAgent"
        (submitFuncEmitter)="updateReferenceCompetitors($event)"
        (deleteCompetitorCustomerEmitter)="
          deleteCompetitorCustomerEmitter($event)
        "
        (deleteProductEmitter)="deleteCompetitorProduct($event)"
        (deletePresenceMarketEmitter)="deleteCompetitorMarketArea($event)"
        (deleteDistributionChannelEmitter)="
          deleteCompetitorDistributionChannel($event)
        "
        [functionalities]="functionalities"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (refreshReferenceCompetitors)="refreshReferenceCompetitors()"
      ></app-reference-competitors>
    </mat-tab>
    <!--SWOT & USP-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Swot?.length"
          [matBadge]="splittedNotes?.Swot?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.Swot" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Swot?.length">
          {{ "PROJECT_SECTIONS.Swot" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.Swot?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Swot"
        [notes]="splittedNotes?.Swot"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-swot-form
        #swot
        [functionalities]="functionalities"
        [index]="index"
        [tabIndex]="7"
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        (submitFuncEmitter)="saveSwot($event)"
        (customerDescrSubmitEmitter)="saveCustomerDescriptions($event)"
        (refreshSwot)="refreshSwot()"
      ></app-swot-form>
    </mat-tab>
    <!--LEAD GENERATION-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.LeadGeneration?.length"
          [matBadge]="splittedNotes?.LeadGeneration?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.LeadGeneration" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.LeadGeneration?.length">
          {{ "PROJECT_SECTIONS.LeadGeneration" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.LeadGeneration?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.LeadGeneration"
        [notes]="splittedNotes?.LeadGeneration"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-lead-generation-form
        #leadGeneration
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [functionalities]="functionalities"
        [index]="index"
        [tabIndex]="8"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        (submitFuncEmitter)="updateLeadGeneration($event)"
        (deleteLeadActivity)="deleteLeadActivity($event)"
        (deleteLeadCompany)="deleteLeadCompany($event)"
        (deleteLeadProfile)="deleteLeadProfile($event)"
        (deleteActivityProfileEmitter)="deleteActivityProfileEmitter($event)"
        (refreshLeadGeneration)="refreshLeadGeneration()"
      ></app-lead-generation-form>
    </mat-tab>
    <!--MARKETS ANALYSIS-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.MarketsStrategyAnalysis?.length"
          [matBadge]="splittedNotes?.MarketsStrategyAnalysis?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.MarketsAnalysis" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.MarketsStrategyAnalysis?.length">
          {{ "PROJECT_SECTIONS.MarketsAnalysis" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.MarketsStrategyAnalysis?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.MarketsStrategyAnalysis"
        [notes]="splittedNotes?.MarketsStrategyAnalysis"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-markets-strategy-analysis
        #marketsAnalysis
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [isSupervisor]="isSupervisor || isProjectSupervisor"
        [isLabManager]="isLabManager"
        [isIspDelivered]="isIspDelivered"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        [canModifyStrategy]="canModifyStrategy"
        [isManagerAndSupervisor]="isManagerAndSupervisor"
        [index]="index"
        [tabIndex]="9"
        (selectedInterchangeTypologyEmitter)="
          getInterchangeTypologyData($event)
        "
        (saveMarketsAnalysisEmitter)="saveMarketAnalysis($event)"
        (saveMatrixEmitter)="saveMatrixMarketsAnalysis($event)"
        (deleteStrategyEmitter)="deleteStrategy($event)"
      ></app-markets-strategy-analysis>
    </mat-tab>
    <!--STRATEGY-->
    <mat-tab>
      <ng-template matTabLabel>
        <span
          *ngIf="splittedNotes?.Strategy?.length"
          [matBadge]="splittedNotes?.Strategy?.length"
          matBadgeColor="warn"
          matBadgeOverlap="false"
        >
          {{ "PROJECT_SECTIONS.Strategy" | translate }}
        </span>
        <span *ngIf="!splittedNotes?.Strategy?.length">
          {{ "PROJECT_SECTIONS.Strategy" | translate }}
        </span>
      </ng-template>
      <app-note-button
        *ngIf="
          splittedNotes?.Strategy?.length ||
          (isSupervisor && !isReadOnly) ||
          (isLabManagerSupervisor && isReadOnly)
        "
        [isAgent]="isAgent"
        [isAgentSupervisor]="isAgentSupervisor"
        [isLabManager]="isLabManager"
        [isLabManagerSupervisor]="isLabManagerSupervisor"
        [userRole]="userRole"
        [tab]="riTab.Strategy"
        [notes]="splittedNotes?.Strategy"
        [supervisorNotesObs]="supervisorNotes.asObservable()"
        [idProject]="project.idProject"
        (refreshNotes)="getUncheckedNotes($event)"
        (refreshSupervisorNotes)="getSupervisorNotes()"
      >
      </app-note-button>
      <app-strategy-form
        #strategy
        [initialValues]="$getProjectReady"
        [translations]="translations"
        [isSupervisor]="isSupervisor || isProjectSupervisor"
        [isLabManager]="isLabManager"
        [isIspDelivered]="isIspDelivered"
        [isLabManagerInternal]="isLabManagerInternal"
        [isReadOnly]="isReadOnly"
        [canModifyStrategy]="canModifyStrategy"
        [isManagerAndSupervisor]="isManagerAndSupervisor"
        [index]="index"
        [tabIndex]="9"
        (selectedInterchangeTypologyEmitter)="
          getInterchangeTypologyData($event)
        "
        (submitFuncEmitter)="saveMarketAnalysis($event)"
        (deleteStrategyEmitter)="deleteStrategy($event)"
        (saveMatrixMarketsAnalysis)="saveMatrixMarketsAnalysis($event)"
        (deleteIndexMarketObjective)="deleteIndexMarketObjective($event)"
        (saveStrategyEmitter)="saveStrategy($event)"
        (sendIspEmitter)="sendIsp($event)"
        (rejectIspEmitter)="rejectIsp($event)"
        (approveIspEmitter)="approveIsp($event)"
        (refreshMarketAnalysis)="refreshMarketAnalysis()"
        (refreshMatrixMarketsAnalysis)="refreshMatrixMarketsAnalysis()"
      ></app-strategy-form>
    </mat-tab>
    <!--NOTE-->
    <!-- <mat-tab>
          <ng-template matTabLabel>
            <span *ngIf="dirtyTabs?.note" matBadge="&#8288;" matBadgeColor="warn" matBadgeOverlap="false">Note</span>
            <span *ngIf="!dirtyTabs?.note">Note</span>
          </ng-template>
          <app-note-button
            *ngIf="splittedNotes?.Notes?.length && (isAgent || isLabManager)"
            [notes]="splittedNotes?.Notes"
            (checkedNote)="childOnChecked($event)"
          ></app-note-button>
          <app-insert-notes-panel
            *ngIf="isSupervisor"
            [supervisorNotes]="supervisorNotes"
            [riTab]="riTab.Notes"
            [translations]="translations"
            (submitEmitter)="createUpdateNotes($event)"
            (deleteNoteEmitter)="deleteNote($event)"
            [saveNotesLoader]="saveNotesLoader"
          ></app-insert-notes-panel>
          <app-note-form
            [isSupervisor]="isSupervisor"
            [isIsp]="isIsp"
            [initialValues]="$getProjectReady"
            [translations]="translations"
            (submitFuncEmitter)="saveNotes($event)"
            (rejectIrEmitter)="rejectIr($event)"
            (approveIrEmitter)="approveIr($event)"
          ></app-note-form>
        </mat-tab>         -->
  </mat-tab-group>
</div>

<!--OOOLLLLLDDD-->

<!-- <div class="main-form-button-container">
      <div class="visualize-button main-form-button">
        <button
          mat-raised-button
          color="primary"
          (click)="viewIr()"
        >
          <fa-icon [icon]="['fas', 'file-pdf']" size="lg"></fa-icon>
          {{ 'IR_FORM.VisualizeAssessment' | translate }}
        </button>
      </div>      
    </div> -->
