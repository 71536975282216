<form
  appSrollOnError
  [formGroup]="contractForm"
  (ngSubmit)="submit()"
  class="ir-form"
>
  <div class="row">
    <div class="col-6">
      <div class="section-card h100">
        <div class="content">
          <div class="row">
            <mat-form-field
              appearance="standard"
              class="col-6 datepicker-readonly"
              [ngClass]="
                !contractForm.get('dateStart')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="dateStart">{{
                "IR_FORM.DateInsertContract" | translate
              }}</mat-label>
              <input
                matInput
                readonly
                [matDatepicker]="picker"
                formControlName="dateStart"
              />
              <mat-datepicker-toggle
                [disableRipple]="true"
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <mat-error *ngIf="controls.dateStart.invalid">
                {{
                  controls.dateStart.errors
                    | getErrorMessage: translations.DateInsertContract
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              appearance="standard"
              class="col-6"
              [ngClass]="
                !contractForm.get('contractNumber')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="contractNumber">{{
                "IR_FORM.ContractNumber" | translate
              }}</mat-label>
              <input
                matInput
                formControlName="contractNumber"
                type="text"
                name="contractNumber"
              />
              <mat-error *ngIf="!controls.contractNumber.valid">
                {{
                  controls.contractNumber.errors
                    | getErrorMessage: translations.ContractNumber
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field
              formGroupName="user"
              appearance="standard"
              class="col-6"
            >
              <mat-label for="idUser">{{
                "IR_FORM.Agent" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                name="idUser"
                readonly
                [value]="user"
              />
            </mat-form-field>
            <mat-form-field
              appearance="standard"
              class="col-6"
              [ngClass]="
                !contractForm.get('commissionAgent')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="commissionAgent">{{
                "IR_FORM.AgentCommissions" | translate
              }}</mat-label>
              <input
                matInput
                formControlName="commissionAgent"
                type="number"
                min="0"
                max="100"
                name="commissionAgent"
              />
              <span class="custom-suffix" matSuffix>%</span>
              <mat-error *ngIf="!controls.commissionAgent.valid">
                {{
                  controls.commissionAgent.errors
                    | getErrorMessage: translations.AgentCommissions
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field
              appearance="standard"
              class="col-6"
            >
              <mat-label for="commissionStandard">
                {{ "IR_FORM.CommissionStandard" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="commissionStandard"
                type="number"
                min="0"
                max="100"
                name="commissionStandard"
              />
              <span class="custom-suffix" matSuffix>%</span>
            </mat-form-field>
            <mat-form-field
              *ngIf="
                isLabManager || isLabManagerInternal || isLabManagerSupervisor
              "
              appearance="standard"
              class="col-6 datepicker-readonly"
              [ngClass]="
                !contractForm.get('dateStartProject')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="dateStartProject">{{
                "IR_FORM.DateStartContract" | translate
              }}</mat-label>
              <input
                matInput
                readonly
                [matDatepicker]="picker1"
                formControlName="dateStartProject"
              />
              <mat-datepicker-toggle
                [disableRipple]="true"
                matSuffix
                [for]="picker1"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
              <mat-error *ngIf="controls.dateStartProject.invalid">
                {{
                  controls.dateStartProject.errors
                    | getErrorMessage: translations.DateStartContract
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="section-card h100">
        <div class="header h70">
          <div class="title">
            <span>{{ "IR_FORM.Contract" | translate }}</span>
          </div>
        </div>
        <div class="content">
          <div formGroupName="documentDTO" class="upload-container">
            <app-upload-file
              [fileToHandle]="fileToChild"
              [fileType]="'IR_FORM.ContractForButton' | translate"
              [downloadFileFromName]="true"
              [showDocumentName]="true"
              [namePosition]="'top'"
              [hideButton]="isLabManagerInternal || isReadOnly"
              [idInputFile]="'upload-contract-document'"
              (uploadFileEmitter)="uploadDocument($event)"
              (downloadFileEmitter)="viewDocument()"
              [parentUploadError]="showUploadError"
            ></app-upload-file>
            <input
              matInput
              hidden
              formControlName="path"
              type="text"
              name="path"
            />
            <!--<mat-error class="error" *ngIf="documentControls.path && !documentControls.path.valid">
              {{ documentControls.path.errors | getErrorMessage:translations.Contract }}
            </mat-error>-->
            <!--<mat-hint class="contract-info" *ngIf="documentControls.path.dirty">
              {{ 'IR_FORM.DocumentChanged' | translate  }}
            </mat-hint>
            <mat-hint class="contract-info" *ngIf="documentControls.idDocument.value && !documentControls.path.dirty">
              {{ 'IR_FORM.DocumentAlreadyLoaded' | translate  }}
            </mat-hint>-->
            <!-- <button
              *ngIf="documentControls.idDocument.value"
              mat-raised-button color="primary"
              (click)="viewDocument()"
              [matTooltip]="translations.DownloadContract"
            >
              <mat-icon>save_alt</mat-icon>-->
            <!--{{ 'IR_FORM.DownloadDocument' | translate }}-->
            <!--</button> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="section-card h100">
        <div class="header h70">
          <div class="title">
            <span>{{ "IR_FORM.OtherDocuments" | translate }}</span>
          </div>
        </div>
        <div class="content">
          <div class="other-documents-list">
            <div
              *ngFor="let otherDoc of otherDocuments"
              class="d-flex align-items-center justify-content-between mb-20"
            >
              <button
                type="button"
                class="only-icon-button primary mr-20"
                (click)="downloadOtherDocument(otherDoc.idDocument)"
              >
                <svg-icon name="download_icon" class="w24"></svg-icon>
              </button>
              <button
                type="button"
                class="only-text-button primary"
                (click)="downloadOtherDocument(otherDoc.idDocument)"
              >
                <span class="bold fs-18 truncate-other-document">
                  {{ otherDoc?.name }}
                </span>
              </button>
              <button
                *ngIf="!isReadOnly && !isLabManagerInternal"
                type="button"
                class="only-icon-button error"
                (click)="deleteOtherDocument(otherDoc.idDocument)"
              >
                <svg-icon name="trash_icon" class="w24"></svg-icon>
              </button>
            </div>
          </div>
          <div style="height: 60px">
            <app-upload-file
              [uploadDirectType]="true"
              [buttonClass]="'border'"
              [fileType]="translations?.DocumentForButton"
              [idInputFile]="'upload-other-document'"
              (uploadFileEmitter)="uploadOtherDocument($event)"
              [hideButton]="isLabManagerInternal || isReadOnly"
            ></app-upload-file>
            <!--<mat-error class="error" *ngIf="documentControls.path && !documentControls.path.valid">
              {{ documentControls.path.errors | getErrorMessage:translations.Contract }}
            </mat-error>-->
            <!--<mat-hint class="contract-info" *ngIf="documentControls.path.dirty">
              {{ 'IR_FORM.DocumentChanged' | translate  }}
            </mat-hint>
            <mat-hint class="contract-info" *ngIf="documentControls.idDocument.value && !documentControls.path.dirty">
              {{ 'IR_FORM.DocumentAlreadyLoaded' | translate  }}
            </mat-hint>-->
            <!-- <button
              *ngIf="documentControls.idDocument.value"
              mat-raised-button color="primary"
              (click)="viewDocument()"
              [matTooltip]="translations.DownloadContract"
            >
              <mat-icon>save_alt</mat-icon>-->
            <!--{{ 'IR_FORM.DownloadDocument' | translate }}-->
            <!--</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="center-buttons mt-40"
    *ngIf="!isLabManagerInternal && !isReadOnly"
  >
    <button class="rounded-button primary-bg-button" type="submit">
      {{ "COMMON.SaveChanges" | translate }}
    </button>
  </div>
</form>
