<div class="custom-container">
  <div class="wrap-794"  #saveArea> 
    <!-- COVER -->
    <img class="first-page-img unbreackeable" src="assets/img/ri_firstpage.png">
    <!-- /COVER -->
    <div class="ir-content" >

      <!-- PRESENTETION PAGE -->
      <div class="presentation-page page">
        <div  class="row">
          <p class="col-12">© {{project.customer?.businessName}}</p>
          <p class="col-12">{{project.customer?.address?.address}}</p>
          <p class="col-12">{{project.customer?.address?.cap}} {{project.customer?.address?.city}}</p>
        </div>
        <div class="row">
          <div class="col-12 report-info" [innerHTML]="'IR_DOCUMENT.ReaportInfo' | translate:{ businessName: project.customer?.businessName }"></div>
          <div class="col-12 report-info" [innerHTML]="'IR_DOCUMENT.InfoReportDeepening' | translate:{ site: project.customer?.webSiteLink, number: project.customer?.telephoneNumber  }"></div>
        </div>
      </div>
      <!-- /PRESENTETION PAGE -->

      <!-- CONTENT LIST -->
      <div class="content-list page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.ContentListTitle'  | translate }}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 info-section">
            <ul>
              <li>{{ 'IR_DOCUMENT.PremiseTitle' | translate }}</li>
              <li>{{ 'IR_DOCUMENT.IntroductionTitle' | translate }}</li>
              <li>{{ 'IR_DOCUMENT.RevenueTitle' | translate }}</li>
              <li>{{ 'IR_DOCUMENT.ExportTitle' | translate }}</li>
              <li>{{ 'IR_DOCUMENT.ExportStrategyTitle' | translate }}</li>
              <li>{{ 'IR_DOCUMENT.ProductTitle'  | translate }}</li>
              <li>{{ 'IR_DOCUMENT.PresenceMarketsTitle' | translate }}</li>
              <li>{{ 'IR_DOCUMENT.DesiredMarketsTitle'  | translate }}</li>
              <li>{{ 'IR_DOCUMENT.DistributionsChannelTitle'  | translate }}</li>
              <li>{{ 'IR_DOCUMENT.NoteTitle'  | translate }}</li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /CONTENT LIST -->

      <!-- PREMISE -->
      <div class="premise-page page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.PremiseTitle'  | translate }}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 two-column">
            <img class="img-fluid" src="assets/img/lench.jpg" alt="Masimo Lench" >
            <p class="column">{{ 'IR_DOCUMENT.PremiseColumnLeft' | translate }}</p>
          </div>
        </div>
      </div>
      <!-- /PREMISE -->

      <!-- INTRODUCTION -->
      <div class="introduction-page page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.IntroductionTitle'  | translate}}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.IntroductionContract' | translate:{ 
            businessName: project?.customer?.businessName,
            yearFoundation: project?.customer?.yearFoundation,
            address: project?.customer?.address?.address + ' ' + project?.customer?.address?.cap + ' ' + project?.customer?.address?.city + ' ' + project?.customer?.address?.city, 
            contractAgent: project?.contract?.user?.name + ' ' + project?.contract?.user?.surname,
            dateStart: project?.contract?.dateStart,
            dateEnd: project?.contract?.dateEnd,
            contractNumber: project?.contract?.contractNumber
          }"></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.IntroductionQuote' | translate:{ 
            commissionAgent: project?.contract?.commissionAgent,  
            commissionStandard: project?.contract?.commissionStandard 
          }"></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.IntroductionReferent' | translate:{ 
            firstContact: firstContact  
          }"></div>
          <div class="col-12 referent-list" *ngIf="project?.customer?.customerContact?.length > 0"  [innerHTML]="'IR_DOCUMENT.IntroductionAlternativeReferent' | translate">
          </div>
          <div class="col-12" *ngIf="project?.customer?.customerContact?.length > 0">
            <ul>
              <li *ngFor="let referent of project?.customer?.customerContact"
                [innerHTML]="'IR_DOCUMENT.IntroductionAlternativeReferentItem' | translate:{ 
                  referent: referent  
                }">
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- /INTRODUCTION -->

      <!-- REVENUE -->
      <div class="revenue-page page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.RevenueTitle'  | translate}}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.RevenueDescriptions' | translate:{ 
            businessName: project?.customer?.businessName,
            yearFoundation: project?.customer?.yearFoundation, 
            commerceSector: project?.customerInformation?.commerceSector?.name ? project?.customerInformation?.commerceSector?.name : 'N.A.',
            isStartup: project?.customerInformation?.startup ? readyTranslations.IS : readyTranslations.IsNot,
            productionType: project?.customerInformation?.productionType ? readyTranslations[project?.customerInformation?.productionType] : 'N.A.'
          }">
          </div>
          <div *ngIf="project?.customerInformation?.productionDescription" class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.RevenueProductDescriptions' | translate:{ 
            productionDescription: project?.customerInformation?.productionDescription
          }">
          </div>
          <div class="col-12 info-section" *ngIf="project?.customerInformation?.revenueImport" [innerHTML]="'IR_DOCUMENT.RevenueImportDescriptions' | translate:{ 
            revenueImport: project?.customerInformation?.revenueImport
          }">
          </div>
          <div class="col-12" *ngIf="project?.countryImport?.length > 0">
            <ul>
              <li *ngFor="let country of project?.countryImport"
                [innerHTML]="country">
              </li>
            </ul>
          </div>
          <div class="col-12 info-section" *ngIf="project?.customerInformation?.revenueExport" [innerHTML]="'IR_DOCUMENT.RevenueExportDescriptions' | translate:{ 
            revenueExport: project?.customerInformation?.revenueExport
          }"></div>
        </div>
      </div>
      <!-- /REVENUE -->

      <!--EXPORT ORGANIZATION-->
      <div class="export-page page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.ExportTitle'  | translate}}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.ThereISExport' | translate:{ 
            hasExport: project?.customerInformation?.hasExport ? readyTranslations.Exist : readyTranslations.DontExist
          }">
          </div>
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.ExpoertIsFormed' | translate:{ 
            formed: project?.customerInformation?.hasExpDedicatedResource ? readyTranslations.Result : readyTranslations.DontResult
          }">
          </div>
          <div class="col-12 info-section" *ngIf="project?.customerInformation?.revenueExport" [innerHTML]="'IR_DOCUMENT.ForeignLanguages' | translate:{ 
            foreignLanguageKnown: project?.customerInformation?.foreignLanguageKnown
          }"></div>
          <div class="col-12 info-section" *ngIf="project?.customerInformation?.processCertification" [innerHTML]="'IR_DOCUMENT.ExportProcessCertification' | translate:{ 
            processCertification: project?.customerInformation?.processCertification,
            businessName: project?.customer?.businessName
          }"></div>
          <div class="col-12 info-section" *ngIf="!project?.customerInformation?.processCertification" [innerHTML]="'IR_DOCUMENT.ExportNoProcessCertification' | translate:{
            businessName: project?.customer?.businessName
          }">
          </div>
          <div class="col-12 info-section" *ngIf="project?.customerInformation?.sectorProductAssociation" [innerHTML]="'IR_DOCUMENT.ExportSectorProductAssociation' | translate:{
            sectorProdutAssociation: project?.customerInformation?.sectorProductAssociation,
            businessName: project?.customer?.businessName
          }">
          </div>
          <div class="col-12 info-section" *ngIf="!project?.customerInformation?.sectorProductAssociation" [innerHTML]="'IR_DOCUMENT.ExportNoSectorProductAssociation' | translate:{
            businessName: project?.customer?.businessName
          }">
          </div>
          <div class="col-12 info-section" *ngIf="project?.customerInformation?.note" [innerHTML]="'IR_DOCUMENT.AthorsNote' | translate: {
            note: project?.customerInformation?.note
          }">
          </div>
        </div>
      </div>
      <!-- /EXPORT ORGANIZATION -->

      <!--EXPORT STRATEGY-->
      <div class="export-strategy-page page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.ExportStrategyTitle'  | translate}}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.ExportStrategyDesc' | translate">
          </div>
          <div class="col-12 info-section" *ngIf="project?.customerInformation?.exportStrategyMarketAdopted" [innerHTML]="'IR_DOCUMENT.ExportStrategyMarkets' | translate:{ 
            exportStrategyMarketAdopted: project?.customerInformation?.exportStrategyMarketAdopted,
            businessName: project?.customer?.businessName
          }">
          </div>
          <div class="col-12 info-section" *ngIf="!project?.customerInformation?.exportStrategyMarketAdopted" [innerHTML]="'IR_DOCUMENT.ExportNoStrategyMarkets' | translate:{ 
            businessName: project?.customer?.businessName
          }">
          </div>
          <div class="col-12 info-section" *ngIf="project?.customerInformation?.exportStrategyChannelAdopted" [innerHTML]="'IR_DOCUMENT.ExportStrategyChannels' | translate:{ 
            exportStrategyChannelAdopted: project?.customerInformation?.exportStrategyChannelAdopted,
            businessName: project?.customer?.businessName
          }">
          </div>
          <div class="col-12 info-section" *ngIf="!project?.customerInformation?.exportStrategyChannelAdopted" [innerHTML]="'IR_DOCUMENT.ExportNoStrategyChannels' | translate">
          </div>
          <div class="col-12 info-section" *ngIf="project?.customerInformation?.exportStrategyScoutingClients" [innerHTML]="'IR_DOCUMENT.ExportStrategyScoutingClients' | translate: {
            exportStrategyScoutingClients: project?.customerInformation?.exportStrategyScoutingClients
          }">
          </div>
          <div class="col-12 info-section" *ngIf="!project?.customerInformation?.exportStrategyScoutingClients" [innerHTML]="'IR_DOCUMENT.ExportNoStrategyScoutingClients' | translate">
          </div>
        </div>
      </div>
      <!-- /EXPORT STRATEGY -->

      <!--PRODUCT-->
      <div class="product-page array page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.ProductTitle'  | translate }}</b></h3></div>
        </div>
        <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.ProductDesc' | translate:{
          businessName: project?.customer?.businessName
        }">
        </div>
        <div class="col-12 info-section">
          <div class="col-12 item unbreackeable" *ngFor="let product of project.soldProduct; let idx = index"
            [ngClass]="{ 'new-page-item':(idx +1)%4 === 0 }">
            <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.ProductName' | translate:{
              idx: idx + 1,
              productName: product.name
            }"></p>
            <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.ProductCode' | translate:{
              productCode: product.productCode
            }"></p>
            <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.ProductDescription' | translate:{
              description: product.description
            }"></p>
            <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.ProductQuote' | translate:{
              revenuePercentage: product.revenuePercentage
            }"></p>
            <p class="unbreackeable" *ngIf="product.exported" [innerHTML]="'IR_DOCUMENT.ProductIsExported' | translate"></p>
            <p class="unbreackeable" *ngIf="!product.exported" [innerHTML]="'IR_DOCUMENT.ProductIsntExported' | translate"></p>
            <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.ProducImportanceLvl' | translate:{
              importanceLevel: product.importanceLevel
            }"></p>
            <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.ProducImportanceReason' | translate:{
              reason: product.reason
            }"></p>
          </div>
        </div>
      </div>
      <!-- /PRODUCT -->

      <!--PRESENCE MARKETS-->
      <div class="precense-markets-page array page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.PresenceMarketsTitle'  | translate}}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.PresdenceMarketsDescr' | translate:{
            businessName: project?.customer?.businessName
          }"></div>
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.PresenceMarketsDescrPost' | translate">
          </div>
          <div *ngIf="project.presenceMarkets?.length" class="col-12 info-section">
            <div class="col-12 item unbreackeable" *ngFor="let market of project.presenceMarkets; let idx = index"
              [ngClass]="{ 'new-page-item':(idx +1)%6 === 0 }">
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.MarketsName' | translate:{
                idx: idx + 1,
                name: market.country?.country
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.MarketsQuote' | translate:{
                revenueShare: market.revenueShare
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.MarketsmportanceLvl' | translate:{
                levelOfInterest: market.levelOfInterest
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.MarketsmportanceReason' | translate:{
                reason: market.reason
              }"></p>
            </div>
          </div>
          <div *ngIf="!project.presenceMarkets?.length" class="col-12 info-section">
            <p><i>{{ 'IR_DOCUMENT.NoMarkets'  | translate }}</i></p>
          </div>
        </div>
      </div>
      <!-- /PRESENCE MARKETS -->

      <!--DESIRED MARKETS-->
      <div id="desired-markets" class="desired-markets-page array page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.DesiredMarketsTitle'  | translate }}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.DesiredMarketsDescr' | translate:{
            businessName: project?.customer?.businessName
          }"></div>
          <div class="col-12 info-section"  *ngIf="project.desiredMarkets?.length" >
            <div class="col-12 item unbreackeable" *ngFor="let market of project.desiredMarkets; let idx = index"
              [ngClass]="{ 'new-page-item':(idx +1)%6 === 0 }">
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.MarketsName' | translate:{
                idx: idx + 1,
                name: market.country?.country
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.MarketsQuote' | translate:{
                revenueShare: market.revenueShare
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.MarketsmportanceLvl' | translate:{
                levelOfInterest: market.levelOfInterest
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.MarketsmportanceReason' | translate:{
                reason: market.reason
              }"></p>
            </div>
          </div>
          <div *ngIf="!project.desiredMarkets?.length" class="col-12 info-section">
            <p><i>{{ 'IR_DOCUMENT.NoDesiredMarkets'  | translate }}</i></p>
          </div>
        </div>
      </div>
      <!-- /DESIRED MARKETS -->

      <!--DISTRIBUTION CHANNEL -->
      <div class="distribution-channel-page array page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.DistributionsChannelTitle'  | translate}}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.DistributionsChannelDescr' | translate"></div>
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.DistributionsChannelDescrPost' | translate: {
            businessName: project?.customer?.businessName
          }">
          </div>
          <div class="col-12 info-section">
            <div class="col-12 item unbreackeable" *ngFor="let channel of project.channels; let idx = index"
              [ngClass]="{ 'new-page-item':idx !== 0 }">
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelName' | translate:{
                idx: idx + 1,
                name: channel.foreignMarket?.country?.country
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelSpecification' | translate:{
                channelSpecification: channel.channelSpecification
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelQuote' | translate:{
                revenueShare: channel.revenueShare
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelUse' | translate:{
                use: channel.channelGlobal ? readyTranslations.Global : readyTranslations.National
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelmportanceLvl' | translate:{
                levelOfInterest: channel.levelOfInterest
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelmportanceReason' | translate:{
                reason: channel.reason
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelTargetDescr' | translate:{
                customerTargetDescription: channel.customerTargetDescription
              }"></p>
              <p class="unbreackeable" ><b>{{ 'IR_DOCUMENT.DistributionsChannelListDescr' | translate}}</b></p>
              <div class="table-container" *ngIf="channel.companies?.length">
                <table class="custom-table">
                  <tr>
                    <th>{{ 'IR_DOCUMENT.Company' | translate }}</th>
                    <th>{{ 'IR_DOCUMENT.Nationality' | translate }}</th>
                  </tr>
                  <tr *ngFor="let company of channel.companies">
                    <td>{{ company.company }}</td>
                    <td>{{ company.description}}</td>
                  </tr>
                </table>
              </div>
              <p class="unbreackeable" *ngIf="!channel.companies?.length"><i>{{ 'IR_DOCUMENT.DistributionsChannelListEmpty' | translate}}</i></p>
            </div>
          </div>
        </div>
      </div>
      <!--/DISTRIBUTION CHANNEL -->

      <!--COMPETITORS REFERENT -->
      <!--<div class="distribution-channel-page array page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.DistributionsChannelTitle'  | translate}}</b></h3></div>
        </div>
        <div class="row">
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.DistributionsChannelDescr' | translate"></div>
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.DistributionsChannelDescrPost' | translate: {
            businessName: project?.customer?.businessName
          }">
          </div>
          <div class="col-12 info-section">
            <div class="col-12 item unbreackeable" *ngFor="let channel of project.channels; let idx = index"
              [ngClass]="{ 'new-page-item':idx !== 0 }">
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelName' | translate:{
                idx: idx + 1,
                name: channel.foreignMarket?.country?.country
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelSpecification' | translate:{
                channelSpecification: channel.channelSpecification
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelQuote' | translate:{
                revenueShare: channel.revenueShare
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelUse' | translate:{
                use: channel.channelGlobal ? readyTranslations.Global : readyTranslations.National
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelmportanceLvl' | translate:{
                levelOfInterest: channel.levelOfInterest
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelmportanceReason' | translate:{
                reason: channel.reason
              }"></p>
              <p class="unbreackeable" [innerHTML]="'IR_DOCUMENT.DistributionsChannelTargetDescr' | translate:{
                customerTargetDescription: channel.customerTargetDescription
              }"></p>
              <p class="unbreackeable" ><b>{{ 'IR_DOCUMENT.DistributionsChannelListDescr' | translate}}</b></p>
              <div class="table-container" *ngIf="channel.companies?.length">
                <table class="custom-table">
                  <tr>
                    <th>{{ 'IR_DOCUMENT.Company' | translate }}</th>
                    <th>{{ 'IR_DOCUMENT.Nationality' | translate }}</th>
                  </tr>
                  <tr *ngFor="let company of channel.companies">
                    <td>{{ company.company }}</td>
                    <td>{{ company.description}}</td>
                  </tr>
                </table>
              </div>
              <p class="unbreackeable" *ngIf="!channel.companies?.length"><i>{{ 'IR_DOCUMENT.DistributionsChannelListEmpty' | translate}}</i></p>
            </div>
          </div>
        </div>
      </div>-->
      <!--/COMPETITORS REFERENT -->

      <!--NOTES -->
      <div class="note-page page">
        <div class="row">
          <div class="col-12 title-page"><h3><b>{{ 'IR_DOCUMENT.NoteTitle'  | translate}}</b></h3></div>
        </div>
        <div class="row">
          <div *ngIf="project.referentNote" class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.NoteDescr' | translate"></div>
          <div class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.NoteReferent' | translate: {
            referentNote: project.referentNote
          }"></div>
          <div *ngIf="project.customerNote" class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.NoteCustomer' | translate: {
            customerNote: project.customerNote
          }"></div>
          <div *ngIf="project.productNote" class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.NoteProduct' | translate: {
            productNote: project.productNote
          }"></div>
          <div *ngIf="!project.productNote && !project.customerNote && !project.referentNote" class="col-12 info-section" [innerHTML]="'IR_DOCUMENT.NoteNoNote' | translate"></div>
        </div>
      </div>
      <!--/NOTES -->
    </div>

  </div>


  <div class="save-button">
    <button 
      mat-fab
      color="primary"
      (click)="createPdf()"
    >
      <mat-icon>save</mat-icon>
    </button>
  </div>

</div>