<!-- <mat-tab-group
  mat-align-tabs="center"
  dynamicHeight
  class="custom-mat-tab"
  #matTabGroup
  [(selectedIndex)]="index"
> -->
<mat-tab-group mat-align-tabs="center" dynamicHeight class="custom-mat-tab">
  <mat-tab>
    <ng-template matTabLabel>
      <span>
        {{ "LIST_MANAGEMENT.Channels" | translate }}
      </span>
    </ng-template>
    <div class="section-card">
      <div class="content">
        <form [formGroup]="channelsRegistryForm" (ngSubmit)="saveChannels()">
          <div class="row" formArrayName="channelsType">
            <div
              *ngFor="let channel of channelsType.controls; let i = index"
              [formGroupName]="i"
              class="col-4"
            >
              <div class="row">
                <mat-form-field
                  appearance="standard"
                  class="note-description-field col-12"
                >
                  <mat-label for="description">{{
                    "COMMON.Description" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="description"
                    name="description"
                  />
                  <mat-error>
                    {{ "CHANNELS_REGISTRY.ChannelAlreadyExists" | translate }}
                  </mat-error>
                </mat-form-field>
                <!-- <div class="d-flex align-items-center">
                  <button
                    *ngIf="
                      channel.get('idCustomer').value ||
                      !channel.get('idChannelType').value
                    "
                    type="button"
                    class="only-icon-button"
                    (click)="removechannelType(i)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-50">
            <button
              type="button"
              (click)="addChannels(null)"
              class="rounded-button primary-border-button"
            >
              {{ "COMMON.AddChannel" | translate }}
            </button>
            <button
              type="submit"
              class="rounded-button primary-bg-button ml-30"
            >
              {{ "COMMON.Save" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template matTabLabel>
      <span>
        {{ "LIST_MANAGEMENT.UnitsOfMeasure" | translate }}
      </span>
    </ng-template>
    <div class="section-card">
      <div class="content">
        <form
          [formGroup]="unitsOfMeasureForm"
          (ngSubmit)="saveUnitsOfMeasure()"
        >
          <div class="row" formArrayName="units">
            <div
              *ngFor="let uom of uomFormArray.controls; let i = index"
              [formGroupName]="i"
              class="col-6"
            >
              <div class="row">
                <mat-form-field appearance="standard" class="col-4">
                  <mat-label for="name">
                    {{ "COMMON.Name" | translate }}
                  </mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="name"
                    name="name"
                  />
                  <mat-error>
                    {{ "CHANNELS_REGISTRY.NameUomAlreadyExists" | translate }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-8">
                  <mat-label for="description">{{
                    "COMMON.Description" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="description"
                    name="description"
                  />
                  <mat-error>
                    {{
                      "CHANNELS_REGISTRY.DescriptionUomAlreadyExists"
                        | translate
                    }}
                  </mat-error>
                </mat-form-field>
                <!-- <div class="d-flex align-items-center">
                  <button
                    *ngIf="
                      uom.get('idCustomer').value || !uom.get('idUom').value
                    "
                    type="button"
                    class="only-icon-button"
                    (click)="removeUom(i)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-50">
            <button
              type="button"
              (click)="addUom(null)"
              class="rounded-button primary-border-button"
            >
              {{ "COMMON.AddUnitOfMeasure" | translate }}
            </button>
            <button
              type="submit"
              class="rounded-button primary-bg-button ml-30"
            >
              {{ "COMMON.Save" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </mat-tab>
  <mat-tab>
    <ng-template matTabLabel>
      <span>
        {{ "LIST_MANAGEMENT.CommerceSectors" | translate }}
      </span>
    </ng-template>
    <div class="section-card">
      <div class="content">
        <form
          [formGroup]="commerceSectorsFormGroup"
          (ngSubmit)="saveCommerceSectors()"
        >
          <div formArrayName="commerceSectors" class="row">
            <div
              *ngFor="
                let sector of commerceSectorsFormArray.controls;
                let i = index
              "
              [formGroupName]="i"
              class="col-4"
            >
              <div class="row">
                <mat-form-field
                  appearance="standard"
                  class="note-description-field col-12"
                >
                  <mat-label for="name">{{
                    "COMMON.Name" | translate
                  }}</mat-label>
                  <input
                    matInput
                    type="text"
                    formControlName="name"
                    name="name"
                  />
                  <mat-error>
                    {{
                      "CHANNELS_REGISTRY.CommerceSectorAlreadyExists"
                        | translate
                    }}
                  </mat-error>
                </mat-form-field>
                <!-- <div class="d-flex align-items-center">
                  <button
                    type="button"
                    class="only-icon-button"
                    (click)="removeCommerceSector(i)"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div> -->
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center mt-50">
            <button
              type="button"
              (click)="addCommerceSector(null)"
              class="rounded-button primary-border-button"
            >
              {{ "LIST_MANAGEMENT.AddCommerceSector" | translate }}
            </button>
            <button
              type="submit"
              class="rounded-button primary-bg-button ml-30"
            >
              {{ "COMMON.Save" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
