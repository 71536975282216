import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  startOfDay,
  endOfDay,
  startOfMonth,
  endOfMonth,
  isSameDay,
  isSameMonth,
} from 'date-fns';
import {
  CalendarDateFormatter,
  CalendarEvent,
  CalendarEventAction,
  CalendarEventTitleFormatter,
  CalendarView,
  DateAdapter,
  getWeekViewPeriod,
} from 'angular-calendar';
import { TranslateService } from '@ngx-translate/core';
import { CustomDateFormatter } from './custom-date-formatter.provider';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { IAppointment, IAppointmentReason } from 'src/app/models/appointment';
import { CustomEventTitleFormatter } from './custom-event-title-formatter.provider';
import { formatDate, Location } from '@angular/common';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastStatus } from 'src/app/models/utility';
import { MustBeAfter, MustBeBefore } from 'src/app/utility/validators';
import { APPOINTMENT_TYPE, RI_STATUS, VARIABLES_COLORS } from 'src/app/config';
import { ActivatedRoute, Router } from '@angular/router';
import { IAgent } from 'src/app/models/roles';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

const colors: any = {
  T: {
    // primary: '#e3bc08',
    primary: VARIABLES_COLORS.Error,
    secondary: VARIABLES_COLORS.Error,
  },
  F: {
    // primary: '#1e90ff',
    primary: VARIABLES_COLORS.Problem,
    secondary: VARIABLES_COLORS.Problem,
  },
  Rescheduled: {
    primary: VARIABLES_COLORS.Chart,
    secondary: VARIABLES_COLORS.Chart,
  },
};

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter,
    },
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
})
export class CalendarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  appointmentType: any = APPOINTMENT_TYPE;

  meetForm: FormGroup;
  reasonForm: FormGroup;
  eventToHandle: any;
  reasonMeetSelection: string = null;
  disableButtons: boolean = false;

  @ViewChild('modalContent') modalContent: TemplateRef<any>;

  @Input() isSupervisorAgent: boolean;
  @Input() isAgent: boolean;
  @Input() idUser: number;

  translations: any;
  calendarLoader: boolean = false;
  view: CalendarView = CalendarView.Week;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  activeDayIsOpen: boolean = false;
  locale: string = navigator.language;
  weekStartsOn: number = 1;
  selectedReasonAction: string;
  actions: CalendarEventAction[] = [];
  events: CalendarEvent[];
  calendarEvent: CalendarEvent = {
    id: null,
    start: null,
    end: null,
    title: null,
    color: {
      primary: null,
      secondary: null,
    },
    actions: this.actions,
    allDay: false,
    cssClass: null,
    resizable: {
      beforeStart: false,
      afterEnd: false,
    },
    draggable: false,
    meta: null,
  };
  agents: IAgent[] = [];
  idUserAgent: number;
  today: any = moment();

  idSpecificMeet: number;

  /** filtered variables */
  filteredAgents: IAgent[];

  reasons: IAppointmentReason[] = [];

  /** control for the MatSelect filter keyword */
  public entityFilterCtrl: FormControl = new FormControl();
  public entityCtrl: FormControl = new FormControl();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private common: CommonService,
    private translate: TranslateService,
    private irFormService: IrFormService,
    private dateAdapter: DateAdapter,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.meetForm = this.fb.group({
      idAppointment: [null],
      idCustomerDataSheet: [null],
      startDate: [null, [Validators.required, MustBeAfter('endDate')]],
      endDate: [null, [Validators.required, MustBeBefore('startDate')]],
      // title: [null, [Validators.required]],
      businessName: [null],
      address: [null, [Validators.required]],
      note: [null],
      reason: [null],
      telephoneNumber: [null],
      email: [null],
      appointmentType: [null],
      referent: [null],
    });
    this.reasonForm = this.fb.group({
      idAppointment: [null],
      idCustomerDataSheet: [null],
      idProjectStatus: [null],
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('CALENDAR').subscribe((translations: any) => {
        this.translations = translations;
      })
    );
    this.activatedRoute?.queryParams?.subscribe({
      next: (queryParams: {
        id?: string;
        date?: Date | string;
        showDay?: boolean;
      }) => {
        if (queryParams?.id) {
          this.viewDate = new Date(queryParams?.date);
          this.view = CalendarView.Day;
          this.idSpecificMeet = parseInt(queryParams.id, 10);
        }
        if (queryParams?.showDay) {
          this.viewDate = new Date(queryParams?.date);
          this.view = CalendarView.Day;
          setTimeout(() => {
            this.location.replaceState('/meetings');
          }, 1000);
        }
      },
    });
    // if (this.isSupervisorAgent) {
    //   this.meetForm.disable();
    // }
    this.meetForm.disable();
    this.meetForm.reset();
    if (this.isSupervisorAgent) {
      this.subscriptions.add(
        this.irFormService.getAgent().subscribe((data: IAgent[]) => {
          if (data?.length) {
            data.forEach((agent: IAgent) => {
              if (agent.idUser !== this.idUser) {
                this.agents.push(agent);
              }
            });
            /** Init variables and check on change */
            if (this.agents) {
              this.filteredAgents = this.agents;
              this.filterEntities();
            }
            this.entityFilterCtrl.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterEntities();
              });
            if (this.agents?.length) {
              this.handleAgentSelect({ value: this.agents[0].idUser });
            }
          }
        })
      );
    } else {
      this.getViewAppointment(this.idUser);
    }
    this.meetForm.markAsPristine();
    this.meetForm.markAsUntouched();
    this.getReasons();
  }

  getViewAppointment(userId: number): void {
    this.events = [];
    this.calendarLoader = true;
    let viewStart;
    let viewEnd;
    if (this.view === CalendarView.Month) {
      viewStart = startOfMonth(this.viewDate);
      viewEnd = endOfMonth(this.viewDate);
    }
    if (this.view === CalendarView.Week) {
      viewStart = getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn
      ).viewStart;
      viewEnd = getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn
      ).viewEnd;
    }
    if (this.view === CalendarView.Day) {
      viewStart = startOfDay(this.viewDate);
      viewEnd = endOfDay(this.viewDate);
    }
    this.subscriptions.add(
      this.irFormService
        .getAgentAppointment(
          viewStart.toISOString(),
          viewEnd.toISOString(),
          // this.idUser
          userId
        )
        .subscribe({
          next: (appointments: IAppointment[]) => {
            this.patchAppointments(appointments);
            this.refresh.next();
            if (this.idSpecificMeet) {
              appointments.forEach((meet: any) => {
                if (meet.idAppointment === this.idSpecificMeet) {
                  meet = { ...this.calendarEvent, ...meet };
                  Object.keys(meet).forEach((key: any) => {
                    if (key === 'idAppointment') {
                      meet.id = meet[key];
                    }
                    if (key === 'startDate') {
                      meet.start = new Date(meet[key]);
                    }
                    if (key === 'endDate') {
                      meet.end = new Date(meet[key]);
                    }
                    if (key === 'title') {
                      meet.title = meet[key];
                    }
                    if (key === 'appointmentType') {
                      // if (key === 'appointmentType' && !meet.isRescheduled) {
                      meet.color = colors[meet[key]];
                    }
                  });
                  if (meet?.isRescheduled) {
                    meet.color = colors.Rescheduled;
                  }
                  this.openEventDialog(meet);
                }
              });
            }
          },
          complete: () => {
            this.calendarLoader = false;
          },
          error: () => {
            this.calendarLoader = false;
          },
        })
    );
  }

  // getViewAppointmentForSupervisor(): void {
  //   this.events = [];
  //   this.calendarLoader = true;
  //   let viewStart;
  //   let viewEnd;
  //   if (this.view === CalendarView.Month) {
  //     viewStart = startOfMonth(this.viewDate);
  //     viewEnd = endOfMonth(this.viewDate);
  //   }
  //   if (this.view === CalendarView.Week) {
  //     viewStart = getWeekViewPeriod(
  //       this.dateAdapter,
  //       this.viewDate,
  //       this.weekStartsOn
  //     ).viewStart;
  //     viewEnd = getWeekViewPeriod(
  //       this.dateAdapter,
  //       this.viewDate,
  //       this.weekStartsOn
  //     ).viewEnd;
  //   }
  //   if (this.view === CalendarView.Day) {
  //     viewStart = startOfDay(this.viewDate);
  //     viewEnd = endOfDay(this.viewDate);
  //   }
  //   this.subscriptions.add(
  //     this.irFormService
  //       .getAgentAppointment(
  //         viewStart.toISOString(),
  //         viewEnd.toISOString(),
  //         this.idUserAgent
  //       )
  //       .subscribe({
  //         next: (appointments: IAppointment[]) => {
  //           this.patchAppointments(appointments);
  //           this.refresh.next();
  //           if (this.idSpecificMeet) {
  //             appointments.forEach((meet: any) => {
  //               if (meet.idAppointment === this.idSpecificMeet) {
  //                 meet = { ...this.calendarEvent, ...meet };
  //                 Object.keys(meet).forEach((key: any) => {
  //                   if (key === 'idAppointment') {
  //                     meet.id = meet[key];
  //                   }
  //                   if (key === 'startDate') {
  //                     meet.start = new Date(meet[key]);
  //                   }
  //                   if (key === 'endDate') {
  //                     meet.end = new Date(meet[key]);
  //                   }
  //                   if (key === 'title') {
  //                     meet.title = meet[key];
  //                   }
  //                   if (key === 'appointmentType') {
  //                     meet.color = colors[meet[key]];
  //                   }
  //                 });
  //                 this.openEventDialog(meet);
  //               }
  //             });
  //           }
  //         },
  //         complete: () => {
  //           this.calendarLoader = false;
  //         },
  //         error: () => {
  //           this.calendarLoader = false;
  //         },
  //       })
  //   );
  // }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(date, this.viewDate) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  openEventDialog(event: any): void {
    this.meetForm.reset();
    this.eventToHandle = event;
    // this.editForm.reset();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    (dialogConfig.panelClass = 'custom-dialog-container'),
      (dialogConfig.autoFocus = false);
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      event,
    };
    this.meetForm.patchValue({
      idAppointment: event?.idAppointment || null,
      idCustomerDataSheet:
        event?.customerDataSheet?.idCustomerDataSheet || null,
      startDate:
        formatDate(event?.start, 'yyyy-MM-ddTHH:mm:ss', this.locale) || null,
      endDate:
        formatDate(event?.end, 'yyyy-MM-ddTHH:mm:ss', this.locale) || null,
      // title: event?.title || null,
      businessName: event?.customerDataSheet?.businessName || null,
      address: event?.address || null,
      note: event?.note || null,
      reason: event?.reason || null,
      telephoneNumber: event?.customerDataSheet?.mainContactTel,
      email: event?.customerDataSheet?.mainContactEmail,
      appointmentType: event?.appointmentType,
      referent: event?.customerDataSheet?.mainContactName,
    });
    this.meetForm.updateValueAndValidity();
    this.dialog.open(this.modalContent, dialogConfig);
  }

  setView(view: CalendarView): void {
    this.activeDayIsOpen = false;
    this.view = view;
    if (this.isSupervisorAgent) {
      this.getViewAppointment(this.idUserAgent);
    } else {
      this.getViewAppointment(this.idUser);
    }
  }

  closeOpenMonthViewDay(): void {
    this.activeDayIsOpen = false;
    if (this.isSupervisorAgent) {
      this.getViewAppointment(this.idUserAgent);
    } else {
      this.getViewAppointment(this.idUser);
    }
  }

  patchAppointments(appointments: IAppointment[]): void {
    appointments.forEach((appointment, i) => {
      this.events.push({ ...this.calendarEvent, ...appointment });
      Object.keys(appointment).forEach((key: any) => {
        if (key === 'idAppointment') {
          this.events[i].id = appointment[key];
        }
        if (key === 'startDate') {
          this.events[i].start = new Date(appointment[key]);
        }
        if (key === 'endDate') {
          this.events[i].end = new Date(appointment[key]);
        }
        if (key === 'title') {
          this.events[i].title = appointment[key];
        }
        if (key === 'appointmentType') {
          // if (key === 'appointmentType' && !appointment.isRescheduled) {
          this.events[i].color = colors[appointment[key]];
        }
      });
      if (appointment?.isRescheduled) {
        this.events[i].color = colors.Rescheduled;
      }
      // UPPER CODE IS BETTER THEN THIS BELOW
      // for (const key in appointment) {
      //   if (key === 'idAppointment') {
      //     this.events[i].id = appointment[key];
      //   }
      //   if (key === 'startDate') {
      //     this.events[i].start = new Date(appointment[key]);
      //   }
      //   if (key === 'endDate') {
      //     this.events[i].end = new Date(appointment[key]);
      //   }
      //   if (key === 'title') {
      //     this.events[i].title = appointment[key];
      //   }
      //   if (key === 'appointmentType') {
      //     this.events[i].color = colors[appointment[key]];
      //   }
      // }
    });
  }

  submitMeet(): void {
    if (this.meetForm.valid && this.meetForm.dirty) {
      const body = this.meetForm.getRawValue();
      body.startDate = new Date(body.startDate).toISOString();
      body.endDate = new Date(body.endDate).toISOString();
      this.dialog.closeAll();
      this.subscriptions.add(
        this.irFormService
          .updateAgentAppointment(body.idAppointment, body, false)
          .subscribe({
            next: (event: any) => {
              this.eventToHandle.title = event.title;
              this.eventToHandle.start = new Date(event.startDate);
              this.eventToHandle.end = new Date(event.endDate);
              this.eventToHandle.address = event.address;
            },
            complete: () => {
              if (this.idSpecificMeet) {
                this.location.replaceState('/meetings');
                this.idSpecificMeet = null;
              }
              if (this.isSupervisorAgent) {
                this.getViewAppointment(this.idUserAgent);
              } else {
                this.getViewAppointment(this.idUser);
              }
            },
            error: () => {
              if (this.idSpecificMeet) {
                this.location.replaceState('/meetings');
                this.idSpecificMeet = null;
              }
              this.common.showToast(
                this.translations.ShowGeneralErrorToast,
                ToastStatus.error,
                3000
              );
            },
          })
      );
    } else {
      this.dialog.closeAll();
    }
  }

  deleteMeet(): void {
    const body: any = this.meetForm.getRawValue();
    this.meetForm.disable();
    this.disableButtons = true;
    this.subscriptions.add(
      this.irFormService
        .deleteAgentAppointment(body.idAppointment, body)
        .subscribe({
          complete: () => {
            if (this.idSpecificMeet) {
              this.location.replaceState('/meetings');
              this.idSpecificMeet = null;
            }
            this.activeDayIsOpen = false;
            this.dialog.closeAll();
            this.meetForm.enable();
            this.disableButtons = false;
            if (this.isSupervisorAgent) {
              this.getViewAppointment(this.idUserAgent);
            } else {
              this.getViewAppointment(this.idUser);
            }
          },
          error: () => {
            if (this.idSpecificMeet) {
              this.location.replaceState('/meetings');
              this.idSpecificMeet = null;
            }
            this.common.showToast(
              this.translations.ShowGeneralErrorToast,
              ToastStatus.error,
              3000
            );
            this.meetForm.enable();
            this.disableButtons = false;
          },
        })
    );
  }

  handleAgentSelect(event: any): void {
    this.activeDayIsOpen = false;
    this.idUserAgent = event.value;
    this.entityCtrl.setValue(this.idUserAgent);
    this.getViewAppointment(this.idUserAgent);
  }

  /** filter the entities */
  private filterEntities(): void {
    if (!this.agents) {
      return;
    }
    // get the search keyword
    let search = this.entityFilterCtrl.value;
    if (!search) {
      this.filteredAgents = this.agents;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the data
    this.filteredAgents = this.agents
      .filter(
        (entity) =>
          entity.name.toLowerCase().indexOf(search) > -1 ||
          entity.surname.toLowerCase().indexOf(search) > -1 ||
          entity.email.toLowerCase().indexOf(search) > -1
      )
      .sort(
        (a, b) =>
          b.name.toLowerCase().indexOf(search) -
          a.name.toLowerCase().indexOf(search)
      );
  }

  initializeReasonForm(reason: string, event: any): void {
    this.reasonForm.patchValue({
      idAppointment: event.idAppointment,
      idCustomerDataSheet: event.customerDataSheet.idCustomerDataSheet,
    });
    if (reason === 'NotInterested') {
      this.reasonForm.addControl(
        'idNoContractReason',
        new FormControl(null, Validators.required)
      );
      this.reasonForm.addControl(
        'noContractReason',
        new FormControl(null, Validators.required)
      );
      this.reasonForm.addControl(
        'idNoBmReason',
        new FormControl(null, Validators.required)
      );
      this.reasonForm.addControl(
        'noBmReason',
        new FormControl(null, Validators.required)
      );
      this.reasonForm.patchValue({
        idProjectStatus: RI_STATUS.NotInterested,
      });
    }
    if (reason === 'CompileBM') {
      this.reasonForm.addControl(
        'idNoContractReason',
        new FormControl(null, Validators.required)
      );
      this.reasonForm.addControl(
        'noContractReason',
        new FormControl(null, Validators.required)
      );
      this.reasonForm.patchValue({
        idProjectStatus: RI_STATUS.NoContract,
      });
    }
    if (reason === 'CompileASS') {
      this.reasonForm.addControl(
        'expectedContractStartDate',
        new FormControl(null, Validators.required)
      );
      this.reasonForm.addControl(
        'expectedContractDuration',
        new FormControl(null, Validators.required)
      );
      this.reasonForm.patchValue({
        idProjectStatus: RI_STATUS.RiWorking,
      });
    }
    this.reasonMeetSelection = reason;
  }

  submitReason(): void {
    if (this.reasonForm.valid && this.reasonForm.valid) {
      const body: any = this.reasonForm.getRawValue();
      if (body.expectedContractStartDate) {
        body.expectedContractStartDate = new Date(
          body.expectedContractStartDate
        ).toISOString();
      }
      this.reasonForm.disable();
      this.disableButtons = true;
      this.subscriptions.add(
        this.irFormService
          .createProjectFromAppointment(body.idAppointment, body)
          .subscribe({
            next: (response: any) => {
              if (this.idSpecificMeet) {
                this.location.replaceState('/meetings');
                this.idSpecificMeet = null;
              }
              if (this.reasonMeetSelection === 'NotInterested') {
                this.activeDayIsOpen = false;
                this.dialog.closeAll();
                if (this.isSupervisorAgent) {
                  this.getViewAppointment(this.idUserAgent);
                } else {
                  this.getViewAppointment(this.idUser);
                }
              } else {
                this.activeDayIsOpen = false;
                this.dialog.closeAll();
                this.router.navigate(['/assessment', response.idProject]);
              }
              this.reasonForm.enable();
              this.disableButtons = false;
            },
            error: (err: any) => {
              if (this.idSpecificMeet) {
                this.location.replaceState('/meetings');
                this.idSpecificMeet = null;
              }
              this.common.showToast(
                this.translations[err?.error?.errors?.message] ||
                  this.translations.ShowGeneralErrorToast,
                ToastStatus.error,
                3000
              );
              this.reasonForm.enable();
              this.disableButtons = false;
            },
          })
      );
    }
  }

  cancelReasonForm(): void {
    this.reasonForm.reset();
    this.reasonMeetSelection = null;
    this.reasonForm.removeControl('noContractReason');
    this.reasonForm.removeControl('noBmReason');
    this.reasonForm.removeControl('expectedContractStartDate');
    this.reasonForm.removeControl('expectedContractDuration');
    if (this.idSpecificMeet) {
      this.location.replaceState('/meetings');
      this.idSpecificMeet = null;
    }
  }

  sendEmail(): void {
    window.location.href = `mailto: ${this.meetForm.get('email')?.value}`;
  }

  getReasons(): void {
    this.irFormService.getNoContractReasons().subscribe({
      next: (data: IAppointmentReason[]) => {
        this.reasons = data;
      },
    });
  }

  printPage(): void {
    setTimeout(() => {
      window.print();
    }, 100);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();

    /** destroy entity variables */
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
