<div class="wrap-1200-no-top-padding">
  <app-customer-info-panel *ngIf="idProject" [idProject]="idProject" [isReadonly]="isReadonly"></app-customer-info-panel>
  <div class="bm-section pb-30 mt-30">
    <div class="ta-center mb-30">
      <span class="bold-text-color">{{ 'CRM_BM.BmOnMarketedProduct' | translate }}</span>
    </div>
    <div *ngIf="showBmOnMarketedProductSection">
      <div>
        <span class="text-color">{{ 'CRM_BM.BmOnProductDescriptionPart1' | translate }}</span>
        <span class="bold-text-color">{{ bmOnMarketedProduct?.productName }}</span>
        <span class="text-color">{{ 'CRM_BM.BmOnProductDescriptionPart2' | translate }}</span>
        <span class="bold-text-color">{{ bmOnMarketedProduct?.nCustomers || '0' }} </span>
        <span class="text-color">{{ 'CRM_BM.BmOnProductDescriptionPart3' | translate }}</span>
        <span class="bold-text-color">{{ bmOnMarketedProduct?.totQuantityYear || '0'}} </span>
        <span class="text-color">{{ 'CRM_BM.BmOnProductDescriptionPart4' | translate }}</span>
        <span class="bold-text-color">€ {{ bmOnMarketedProduct?.totValueYear || '0'|currency:'EUR':'':'1.2-2':'it' }}.</span>
      </div>
      <div *ngIf="bmOnMarketedProduct?.nCustomers > 0">
        <span class="text-color">{{ 'CRM_BM.BmOnProductDescriptionPart5' | translate }}</span>
        <span class="clickable-span" (click)="loadBmOnMarketedProductTable()">{{ 'COMMON.Here' | translate }}.</span>
      </div>
    </div>
    <div *ngIf="showBmOnMarketedProductTable" class="my-30">
      <app-table
        [dataSource]="bmProductDataSource"
        [columnsName]="bmProductColumnsName"
        [displayedColumns]="bmProductDisplayedColumns"
        [tableOptions]="bmProductOptions"
        [loader]="bmProductLoader"
        [checkbox]="productCheckbox"
      ></app-table>

      <div *ngIf="(selectedProducts && selectedProducts.length>0) && !isReadonly" class="my-30 flex-end">
        <button mat-raised-button color="primary" class="mr-20 justify-content-md-center" (click)="excludeSelected('product')">
          {{ 'CRM_DETAIL.Save' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="bm-section my-30 pb-30">
    <div class="ta-center mb-30">
      <span class="bold-text-color">{{ 'CRM_BM.DealOpportunityMatchingTitle' | translate }}</span>
    </div>
    <div *ngIf="showBmOnDealOpportunitySection">
      <div>
        <span class="text-color">{{ 'CRM_BM.DealOpportunityMatchingText1' | translate }}</span>
        <span class="bold-text-color">{{ bmOnDealOpportunity?.italianDescription }} / {{ bmOnDealOpportunity?.englishDescription }} </span>
        <span class="text-color">{{ 'CRM_BM.DealOpportunityMatchingText2' | translate }}</span>
        <span class="bold-text-color">{{ bmOnDealOpportunity?.note || '0' }} </span>
        <span class="text-color">{{ 'CRM_BM.DealOpportunityMatchingText3' | translate }}</span>
        <span class="bold-text-color">{{ bmOnDealOpportunity?.quantity || '0' }} </span>
        <span class="text-color">{{ 'CRM_BM.DealOpportunityMatchingText4' | translate }}</span>
        <span class="bold-text-color">€ {{ bmOnDealOpportunity?.revenue || '0'|currency:'EUR':'':'1.2-2':'it' }}.</span>
      </div>
      <div *ngIf="bmOnDealOpportunity?.note > 0">
        <span class="text-color">{{ 'CRM_BM.DealOpportunityMatchingText5' | translate }}</span>
        <span class="clickable-span" (click)="loadBmOnDealOpportunityTable()">{{ 'COMMON.Here' | translate }}.</span>
      </div>
    </div>
    <div *ngIf="showBmOnDealOpportunityTable" class="my-30">
      <app-table
        [dataSource]="bmDealOpportunityDataSource"
        [columnsName]="bmDealOpportunityColumnsName"
        [displayedColumns]="bmDealOpportunityDisplayedColumns"
        [tableOptions]="bmDealOpportunityOptions"
        [tableMenuButtons]="bmDealOpportunityMenuButtons"
        [loader]="bmDealOpportunityLoader"
        [checkbox]="dealOpportunityCheckbox"
      ></app-table>
      <div *ngIf="(selectedDealOpportunities && selectedDealOpportunities.length>0) && !isReadonly" class="my-30 flex-end">
        <button mat-raised-button color="primary" class="mr-20 justify-content-md-center" (click)="excludeSelected('deal')">
          {{ 'CRM_DETAIL.Confirm' | translate }}</button>
      </div>
    </div>
  </div>
  <div class="my-30 pb-30">
    <div class="ta-center mb-30">
      <span class="bold-text-color">{{ 'CRM_BM.OpportunityMatchingTitle' | translate }}</span>
    </div>
    <div *ngIf="showBmOnOpportunitySection">
      <div>
        <span class="text-color">{{ 'CRM_BM.OpportunityMatchinText1' | translate }}</span>
        <span class="bold-text-color">{{ bmOnOpportunity?.productDescription }} / {{ bmOnOpportunity?.productEnglishDescription }} </span>
        <span class="text-color">{{ 'CRM_BM.OpportunityMatchinText2' | translate }}</span>
        <span class="bold-text-color">{{ bmOnOpportunity?.valueOfUnit || '0' }} </span>
        <span class="text-color">{{ 'CRM_BM.OpportunityMatchinText3' | translate }}</span>
        <span class="bold-text-color">{{ bmOnOpportunity?.quantityYear || '0' }} </span>
        <span class="text-color">{{ 'CRM_BM.OpportunityMatchinText4' | translate }}</span>
        <span class="bold-text-color">€ {{ bmOnOpportunity?.valueYear || '0'|currency:'EUR':'':'1.2-2':'it' }}.</span>
      </div>
      <div *ngIf="bmOnOpportunity?.valueOfUnit" class="mt-30">
        <span class="text-color">{{ 'CRM_BM.OpportunityMatchinText5' | translate }}</span>
        <span class="clickable-span" (click)="loadBmOnOpportunityTable()">{{ 'COMMON.Here' | translate }}.</span>
      </div>
    </div>
    <div *ngIf="showBmOnOpportunityTable" class="my-30">
      <app-table
        [dataSource]="bmOpportunityDataSource"
        [columnsName]="bmOpportunityColumnsName"
        [displayedColumns]="bmOpportunityDisplayedColumns"
        [tableOptions]="bmOpportunityOptions"
        [loader]="bmOpportunityLoader"
        [checkbox]="opportunityCheckbox"
      ></app-table>
      <div *ngIf="(selectedOpportunities && selectedOpportunities.length>0) && !isReadonly" class="my-30 flex-end">
        <button mat-raised-button color="primary" class="mr-20 justify-content-md-center" (click)="excludeSelected('opportunity')">
          {{ 'CRM_DETAIL.Confirm' | translate }}</button>
      </div>
    </div>
  </div>
</div>
