<div class="custom-sidebar" [ngClass]="expandSidebar ? 'open' : ''">
  <div class="logo-container">
    <svg-icon
      *ngIf="!expandSidebar"
      name="new-logo-icon-small"
      class="w42"
    ></svg-icon>
    <svg-icon
      *ngIf="expandSidebar"
      name="new-logo-icon-big"
      class="w140 white-icon"
    ></svg-icon>
    <!-- <img *ngIf="!expandSidebar" class="logo" src="assets/icons/logo.svg" />
    <img *ngIf="expandSidebar" class="logo-open" src="assets/icons/logo_2_icon.svg" /> -->
  </div>
  <div class="links-container">
    <div
      *ngFor="let link of links"
      class="link"
      [routerLink]="link.path"
      routerLinkActive="active-link"
      (click)="expandSidebar = false"
    >
      <svg-icon [name]="link.icon" class="w24 white-icon"></svg-icon>
      <!-- <img src="{{ 'assets/icons/' + link.icon + '.svg' }}" /> -->
      <span *ngIf="expandSidebar">{{ link.label }}</span>
      <div
        *ngIf="
          link.path.includes('business-opportunities-custom') &&
          showNewBusinessOpportunitiesBadge
        "
        class="custom-badge-menu"
      ></div>
    </div>
  </div>
  <button
    type="button"
    class="only-icon-button primary expand-sidebar"
    (click)="expandSidebar = !expandSidebar"
  >
    <svg-icon *ngIf="!expandSidebar" name="right_arrow_icon"></svg-icon>
    <svg-icon *ngIf="expandSidebar" name="left_arrow_icon"></svg-icon>
  </button>
</div>
