import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PsFormService } from 'src/app/services/ps-form/ps-form.service';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { CommonService } from 'src/app/services/common/common.service';
import { UserFunctionality } from 'src/app/models/user';
import { IProjectCustomer, Project, ProjectDTO } from 'src/app/models/project';
import { PsSwot } from 'src/app/models/swot';
import {
  ICompetitorPositioning,
  ICompetitorPositioningData,
} from 'src/app/models/competitor-positioning';
import { ToastStatus } from 'src/app/models/utility';
import {
  APP_CONTEX,
  RI_STATUS,
  RI_TAB,
  PR_STATUS_PS_VERSION,
  PS_TAB,
  Roles,
  PS_VERSION,
} from 'src/app/config';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { Note } from 'src/app/models/note';
import { SplitUncheckedNotesArray } from 'src/app/utility/elaborations';
import { CrmService } from 'src/app/services/crm/crm.service';
import { PsSwotComponent } from './ps-swot/ps-swot.component';
import { PsCompetitorComponent } from './ps-competitor/ps-competitor.component';
import { PsStrategyComponent } from './ps-strategy/ps-strategy.component';
import { PsCurrentStrategyComponent } from './ps-current-strategy/ps-current-strategy.component';

@Component({
  selector: 'app-ps-form',
  templateUrl: './ps-form.component.html',
  styleUrls: ['./ps-form.component.scss'],
})
export class PsFormComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  translations: any = {};

  project: ProjectDTO;
  projectEntities: Project;
  private getProjectReady: Subject<Project> = new Subject<Project>();
  $getProjectReady: Observable<Project> = this.getProjectReady.asObservable();
  idUser: string | number;
  loggedUserName: string;
  userRole: string;
  functionalities: UserFunctionality;
  idProject: number;
  idCustomer: number;
  idProjectStatus: number;
  isSupervisor: boolean = false;
  isDirection: boolean;
  isIspDelivered: boolean = false;
  isLabManager: boolean = false;
  isLabManagerInternal: boolean = false;
  isReadonly: boolean = false;
  // supervisorNotes: Note[];
  psTab = PS_TAB;
  saveNotesLoader: boolean = false;
  hasComplete: boolean = false;
  isManagerAndSupervisorManagar: boolean = false;
  splittedNotes: { [key: string]: Note[] } = {};
  supervisorNotes: Subject<any> = new Subject<any>();

  index: number = 0;
  dirtyTabs: any = {
    psSwot: null,
    psCompetitor: null,
    psStrategy: null,
  };

  followedCustomers: IProjectCustomer[];

  invalidTabs: any = {
    psSwot: null,
    psCompetitor: null,
    psStrategy: null,
  };
  tabsPsForm: string[] = ['psSwot', 'psCompetitor', 'psStrategy'];

  @ViewChild('currentStrategy') currentStrategy: PsCurrentStrategyComponent;
  @ViewChild('swot') swot: PsSwotComponent;
  @ViewChild('competitors') competitors: PsCompetitorComponent;
  @ViewChild('strategy') strategy: PsStrategyComponent;

  constructor(
    private authService: AuthService,
    private psFormService: PsFormService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private layoutService: LayoutService,
    private router: Router,
    private common: CommonService,
    private dialog: MatDialog,
    private crmService: CrmService
  ) {}

  ngOnInit(): void {
    this.layoutService.triggerDirtyForm({
      psSwot: false,
      psCompetitor: false,
      psStrategy: false,
    });
    this.subscriptions.add(
      this.route?.params?.subscribe({
        next: (params: Params) => {
          this.idProject = parseInt(params.idProject, 10);
          this.idCustomer = parseInt(params.idCustomer, 10);
          this.idProjectStatus = parseInt(params.idProjectStatus, 10);
          this.getProjectById();
        },
      })
    );
    this.subscriptions.add(
      this.route?.data?.subscribe((data: any) => {
        if (data.readonly) {
          this.isReadonly = true;
        }
      })
    );
    this.subscriptions.add(
      this.translate.get('PS_FORM').subscribe((translations) => {
        this.translations = translations;
        this.idUser = this.authService.userProfile?.idUser;
        this.loggedUserName = `${this.authService.userProfile?.name || ''} ${
          this.authService.userProfile?.surname || ''
        }`;
        this.userRole = this.authService.userProfile?.activeRole;
        if (this.userRole === 'LAB_MANAGER_SUPERVISOR') {
          this.isSupervisor = true;
        }
        if (this.userRole === 'LAB_MANAGER') {
          this.isLabManager = true;
        }
        if (this.userRole === 'LAB_MANAGER_INTERNAL') {
          this.isLabManagerInternal = true;
        }
        if (this.userRole === Roles.Direction) {
          this.isDirection = true;
        }
        if (
          this.authService?.userProfile?.roles?.includes(Roles.LabManager) &&
          this.authService?.userProfile?.roles?.includes(
            Roles.LabManagerSupervisor
          )
        ) {
          this.isManagerAndSupervisorManagar = true;
        }
        this.functionalities = this.authService.functionality;
      })
    );
    this.layoutService.dirtyFormListner$.subscribe((event) => {
      this.dirtyTabs = event;
    });
    this.layoutService.invalidFormListener.subscribe((event) => {
      this.invalidTabs = event;
    });
  }

  getProjectById(): void {
    this.subscriptions.add(
      this.psFormService.getProjectById(this.idProject).subscribe({
        next: (project: ProjectDTO) => {
          // Generate breadcrumb
          const labels: any[] = [{ label: 'PS', path: '/ps' }];
          if (this.isReadonly) {
            labels.push({
              label: `${this.translate.instant('COMMON.Visualize')} ${
                PR_STATUS_PS_VERSION['PR_STATUS_' + this.idProjectStatus]
              } - ${project?.customer?.businessName}`,
              path: null,
              pageTitle: true,
            });
          } else {
            labels.push({
              label: `${this.translate.instant('PAGES.PsDelivery')} - ${
                project?.customer?.businessName
              }`,
              path: null,
              pageTitle: true,
            });
          }
          this.layoutService.generateBreadcrumb(labels);
          this.project = project;
          this.idCustomer = project.idCustomer;
          if (
            project.idProjectStatus === RI_STATUS.IspDelivered ||
            project.idProjectStatus === RI_STATUS.IspDelivered2 ||
            project.idProjectStatus === RI_STATUS.IspDelivered3 ||
            project.idProjectStatus === RI_STATUS.IspDelivered4 ||
            project.idProjectStatus === RI_STATUS.IspDelivered5 ||
            project.idProjectStatus === RI_STATUS.IspDelivered6
          ) {
            this.isIspDelivered = true;
          }
        },
        complete: () => {
          this.getProjectEntities();
        },
      })
    );
  }

  getProjectEntities(): void {
    this.subscriptions.add(
      this.psFormService
        .getProjectEntities(this.project, this.idProjectStatus)
        .subscribe((projectEntities) => {
          this.projectEntities = projectEntities;
          this.emitGetProjectReady(projectEntities);
          this.hasComplete = true;
          this.getUncheckedNotes();
        })
    );
  }

  emitGetProjectReady(initialValues: Project): void {
    this.getProjectReady.next({ ...this.project, ...initialValues });
  }

  saveAndSendStrategy(): void {
    const dialogConfig = new MatDialogConfig();
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;
    const aTabIsDirty: any = this.checkIfCurrentTabIsDirty();
    if (!this.projectEntities?.psCompetitor?.competitorCompanyCrmDTO?.length) {
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant(
          'PS_FORM.TheCompetitorsListMustContainAtLeastOneCompetitor'
        ),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.Ok'),
      };
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    } else if (this.swot.checkInvalidForm()) {
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant(
          'PS_FORM.TheSwotListMustContainAtLeastOneElement'
        ),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.Ok'),
      };
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    } else {
      if (aTabIsDirty.bool) {
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.width = '600px';
        dialogConfig.panelClass = 'custom-dialog-container';
        dialogConfig.data = {
          title: this.translate.instant('COMMON.Attention'),
          message: this.translate.instant('IR_FORM.UnsavedDataInFormSection', {
            section: this.translate.instant(
              'INVALID_TABS.' + aTabIsDirty.tabName
            ),
          }),
          messageClass: 'error-color mb-0 fw-700',
          buttonTrue: this.translate.instant('COMMON.Stay'),
          buttonFalse: this.translate.instant('COMMON.Continue'),
        };
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((dirtyTabResult: boolean) => {
          if (!dirtyTabResult) {
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = false;
            dialogConfig.panelClass = 'custom-dialog-container';
            dialogConfig.width = '400px';
            if (this.checkWhichTabsAreInvalids().length) {
              dialogConfig.data = {
                title: this.translations?.Attention,
                message: `${this.translate.instant(
                  'IR_FORM.TheFollowingSectionsAreIncomplete'
                )}:`,
                messageClass: 'error-color fw-700',
                secondMessage: this.checkWhichTabsAreInvalids().join(', '),
                secondMessageClass: 'fw-700',
              };
            } else {
              if (this.isIspDelivered) {
                dialogConfig.data = {
                  title: this.translations?.Attention,
                  message: this.translations?.ApproveIspMessageConfirmation,
                  buttonTrue: this.translations.Approve,
                  buttonFalse: this.translations.Reject,
                };
              } else {
                dialogConfig.data = {
                  title: this.translations?.Attention,
                  message: this.translations?.SendIspMessageConfirmation,
                  buttonTrue: this.translate.instant('COMMON.Send'),
                  buttonFalse: this.translate.instant('COMMON.Cancel'),
                };
              }
            }
            dialogRef = this.dialog.open(
              ConfirmationDialogComponent,
              dialogConfig
            );
            this.subscriptions.add(
              dialogRef.afterClosed().subscribe((result: boolean) => {
                if (result) {
                  this.subscriptions.add(
                    this.psFormService
                      .saveStrategy(
                        this.project.idProject,
                        {
                          activeMarket:
                            this.projectEntities.psStrategy.activeStrategy
                              .projectObjectiveCustomDTO.marketProjectObjective,
                          projectObjective:
                            this.projectEntities.psStrategy.proposedStrategy
                              .projectObjective,
                          marketProjectObjective:
                            this.projectEntities.psStrategy.proposedStrategy
                              .marketProjectObjective,
                          channelProjectObjective:
                            this.projectEntities.psStrategy.proposedStrategy
                              .channelProjectObjective,
                          actionPlan:
                            this.projectEntities.psStrategy.proposedStrategy
                              .actionPlan,
                        },
                        1
                      )
                      .subscribe({
                        complete: () => {
                          this.router.navigate([`/ps`]);
                        },
                        error: (err: HttpErrorResponse) => {
                          if (err.error.errors.message === 'SE001') {
                            this.common.showToast(
                              this.translate.instant('API_RESPONSE.SE001'),
                              ToastStatus.error,
                              3000
                            );
                          }
                          if (err.error.errors.message === 'US013') {
                            this.common.showToast(
                              this.translate.instant('API_RESPONSE.US013'),
                              ToastStatus.error,
                              3000
                            );
                          }
                          throw err;
                        },
                      })
                  );
                }
              })
            );
          }
        });
      } else {
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = 'custom-dialog-container';
        dialogConfig.width = '400px';
        if (this.checkWhichTabsAreInvalids().length) {
          dialogConfig.data = {
            title: this.translations?.Attention,
            message: `${this.translate.instant(
              'IR_FORM.TheFollowingSectionsAreIncomplete'
            )}:`,
            messageClass: 'error-color fw-700',
            secondMessage: this.checkWhichTabsAreInvalids().join(', '),
            secondMessageClass: 'fw-700',
          };
        } else {
          if (this.isIspDelivered) {
            dialogConfig.data = {
              title: this.translations?.Attention,
              message: this.translations?.ApproveIspMessageConfirmation,
              buttonTrue: this.translations.Approve,
              buttonFalse: this.translations.Reject,
            };
          } else {
            dialogConfig.data = {
              title: this.translations?.Attention,
              message: this.translations?.SendIspMessageConfirmation,
              buttonTrue: this.translate.instant('COMMON.Send'),
              buttonFalse: this.translate.instant('COMMON.Cancel'),
            };
          }
        }
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        this.subscriptions.add(
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.subscriptions.add(
                this.psFormService
                  .saveStrategy(
                    this.project.idProject,
                    {
                      activeMarket:
                        this.projectEntities.psStrategy.activeStrategy
                          .projectObjectiveCustomDTO.marketProjectObjective,
                      projectObjective:
                        this.projectEntities.psStrategy.proposedStrategy
                          .projectObjective,
                      marketProjectObjective:
                        this.projectEntities.psStrategy.proposedStrategy
                          .marketProjectObjective,
                      channelProjectObjective:
                        this.projectEntities.psStrategy.proposedStrategy
                          .channelProjectObjective,
                      actionPlan:
                        this.projectEntities.psStrategy.proposedStrategy
                          .actionPlan,
                    },
                    1
                  )
                  .subscribe({
                    complete: () => {
                      this.router.navigate([`/ps`]);
                    },
                    error: (err: HttpErrorResponse) => {
                      throw err;
                    },
                  })
              );
            }
          })
        );
      }
    }
  }

  saveStrategy(strategy: any): void {
    if (strategy.sendToApprove) {
      if (!this.dirtyTabs.psSwot && !this.dirtyTabs.psCompetitor) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        if (this.isIspDelivered) {
          dialogConfig.data = {
            title: this.translations?.Attention,
            message: this.translations?.ApproveIspMessageConfirmation,
            buttonTrue: this.translations.Approve,
            buttonFalse: this.translations.Reject,
          };
        } else {
          dialogConfig.data = {
            title: this.translations?.Attention,
            message: this.translations?.SendIspMessageConfirmation,
            buttonTrue: this.translations.Send,
            buttonFalse: this.translate.instant('COMMON.Cancel'),
          };
        }
        const dialogRef = this.dialog.open(
          ConfirmationDialogComponent,
          dialogConfig
        );
        this.subscriptions.add(
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.subscriptions.add(
                this.psFormService
                  .saveStrategy(
                    this.project.idProject,
                    strategy,
                    strategy.sendToApprove
                  )
                  .subscribe({
                    complete: () => {
                      if (
                        this.project?.manager?.idUser ===
                        this.project?.supervisor?.idUser
                      ) {
                        this.router.navigate([`${APP_CONTEX}/isp-in-progress`]);
                      } else {
                        this.router.navigate([`${APP_CONTEX}/isp-sent`]);
                      }
                    },
                    error: (err: HttpErrorResponse) => {
                      throw err;
                    },
                  })
              );
            }
          })
        );
      } else {
        this.common.showToast(
          this.translations.SendPSError,
          ToastStatus.error,
          5000
        );
      }
    } else {
      this.subscriptions.add(
        this.psFormService
          .saveStrategy(this.project.idProject, strategy)
          .subscribe({
            complete: () => this.refreshStrategy(),
            error: (err: HttpErrorResponse) => {
              throw err;
            },
          })
      );
    }
  }

  refreshStrategy(): void {
    this.subscriptions.add(
      this.psFormService.getStrategy(this.project.idProject).subscribe({
        next: (strategyResponse: any) => {
          this.projectEntities.psStrategy = strategyResponse;
          this.emitGetProjectReady(this.projectEntities);
        },
      })
    );
  }

  saveCurrentStrategy(body: any): void {
    this.subscriptions.add(
      this.psFormService.saveStrategy(this.idProject, body).subscribe({
        complete: () => {
          this.refreshStrategy();
        },
        error: () => {},
      })
    );
  }

  saveSwot(body: PsSwot): void {
    if (this.projectEntities?.psSwot?.idSwotUspProjectAnalysis) {
      this.subscriptions.add(
        this.psFormService
          .saveSwot(body, this.project.idProject, this.project.idProjectStatus)
          .subscribe({
            complete: () => this.refreshSwot(),
            error: (err: HttpErrorResponse) => {
              throw err;
            },
          })
      );
    } else {
      this.subscriptions.add(
        this.psFormService.createSwot(body, this.project.idProject).subscribe({
          complete: () => this.refreshSwot(),
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
      );
    }
  }

  refreshSwot(): void {
    this.subscriptions.add(
      this.psFormService
        .getSwotByIdCustomer(
          this.project.idProject,
          this.project.idProjectStatus
        )
        .subscribe({
          next: (swotResponse: PsSwot) => {
            this.projectEntities.psSwot = swotResponse;
            this.emitGetProjectReady(this.projectEntities);
          },
        })
    );
  }

  saveCompetitorPositioning(body: ICompetitorPositioningData): void {
    this.subscriptions.add(
      this.psFormService
        .saveCompetitorPositioning(
          this.project.idProject,
          this.project.idProjectStatus,
          body
        )
        .subscribe({
          complete: () => this.refreshCompetitorPositioning(),
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
    );
  }

  refreshCompetitorPositioning(): void {
    this.subscriptions.add(
      this.psFormService
        .getCompetitorPositioning(
          this.project.idProject,
          this.project.idProjectStatus
        )
        .subscribe({
          next: (competitorPositioningResponse: ICompetitorPositioningData) => {
            this.projectEntities.psCompetitor = competitorPositioningResponse;
            this.emitGetProjectReady(this.projectEntities);
          },
        })
    );
  }

  rejectPs(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations.RejectIspMessageConfirmation,
      buttonTrue: this.translate.instant('COMMON.Yes'),
      buttonFalse: this.translate.instant('COMMON.No'),
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.subscriptions.add(
            this.psFormService.rejectIsp(this.project.idProject).subscribe({
              complete: () => {
                this.router.navigate([`${APP_CONTEX}/ps`]);
              },
            })
          );
        }
      })
    );
  }

  approvePs(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations.ApproveIspMessageConfirmation,
      buttonTrue: this.translate.instant('COMMON.Yes'),
      buttonFalse: this.translate.instant('COMMON.No'),
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.subscriptions.add(
            this.psFormService.approveIsp(this.project.idProject).subscribe({
              complete: () => {
                this.router.navigate([`${APP_CONTEX}/isp-to-approve`]);
              },
            })
          );
        }
      })
    );
  }

  getSupervisorNotes(): void {
    if (!this.isReadonly) {
      this.subscriptions.add(
        this.psFormService
          .getSupervisorNotes(this.project.idProject, true)
          .subscribe((notes: Note[]) => {
            this.supervisorNotes.next(SplitUncheckedNotesArray(notes));
          })
      );
    }
  }

  createUpdateNotes(note: Note[]): void {
    this.saveNotesLoader = true;
    this.subscriptions.add(
      this.psFormService
        .createUpdateSupervisorNotes(this.project.idProject, note, true)
        .subscribe({
          complete: () => {
            this.getSupervisorNotes();
            this.saveNotesLoader = false;
          },
        })
    );
  }

  deleteNote(id: number): void {
    this.subscriptions.add(
      this.psFormService.deleteSupervisorNote(id, true).subscribe({
        complete: () => {
          this.getSupervisorNotes();
        },
      })
    );
  }

  getUncheckedNotes(): void {
    if (!this.isReadonly) {
      this.subscriptions.add(
        this.psFormService
          .getSupervisorNotes(this.project.idProject, true)
          .subscribe((notes: Note[]) => {
            this.splittedNotes = SplitUncheckedNotesArray(notes);
            this.supervisorNotes.next(SplitUncheckedNotesArray(notes));
          })
      );
    }
  }

  childOnChecked(event: any): void {
    const noteArray: Note[] = [];
    event.note.checked = true;
    noteArray.push(event.note);
    this.psFormService
      .checkNote(this.project.idProject, noteArray, true)
      .subscribe({
        error: (err) => {
          this.getUncheckedNotes();
          throw err;
        },
      });
  }

  checkWhichTabsAreInvalids(): string[] {
    const invalidTabs: string[] = [];
    if (this.swot.checkInvalidForm()) {
      invalidTabs.push(this.translate.instant('INVALID_TABS.' + 'psSwot'));
    }
    if (this.competitors.checkInvalidForm()) {
      invalidTabs.push(
        this.translate.instant('INVALID_TABS.' + 'psCompetitor')
      );
    }
    if (this.strategy.checkInvalidForm()) {
      invalidTabs.push(this.translate.instant('INVALID_TABS.' + 'psStrategy'));
    }
    return invalidTabs;
  }

  checkIfCurrentTabIsDirty(): any {
    const result: { bool: boolean; tabName: string; prevIndex: number } = {
      bool: null,
      tabName: null,
      prevIndex: this.index,
    };
    switch (result.prevIndex) {
      case 0:
        if (this.currentStrategy.checkDirtyForm()) {
          result.bool = true;
          result.tabName = 'psCurrentStrategy';
        }
        break;
      case 1:
        if (this.swot.checkDirtyForm()) {
          result.bool = true;
          result.tabName = 'psSwot';
        }
        break;
      case 2:
        if (this.competitors.checkDirtyForm()) {
          result.bool = true;
          result.tabName = 'psCompetitor';
        }
        break;
      case 3:
        if (this.strategy.checkDirtyForm()) {
          result.bool = true;
          result.tabName = 'psStrategy';
        }
        break;
    }
    return result;
  }

  showDialogCurrentTabDirty(): void {
    const result = this.checkIfCurrentTabIsDirty();
    if (result.bool) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant('IR_FORM.UnsavedDataInFormSection', {
          section: this.translate.instant('INVALID_TABS.' + result.tabName),
        }),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.GoBack'),
        buttonFalse: this.translate.instant('COMMON.Continue'),
      };
      const dialogRef: MatDialogRef<ConfirmationDialogComponent, any> =
        this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this.index = result.prevIndex;
        }
      });
    }
  }

  showDialogIfSwotsAreInvalid(): void {
    const prevIndex: number = this.index;
    if (prevIndex === 1) {
      if (this.swot.checkInvalidForm()) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.width = '600px';
        dialogConfig.panelClass = 'custom-dialog-container';
        dialogConfig.data = {
          title: this.translate.instant('COMMON.Attention'),
          message: this.translate.instant(
            'PS_FORM.TheSwotListMustContainAtLeastOneElement'
          ),
          messageClass: 'error-color mb-0 fw-700',
          buttonTrue: this.translate.instant('COMMON.GoBack'),
        };
        const dialogRef: MatDialogRef<ConfirmationDialogComponent, any> =
          this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
          if (dialogResult) {
            this.index = prevIndex;
          }
        });
      }
    }
  }

  showDialogIfCompetitorsAreInvalid(): void {
    const prevIndex: number = this.index;
    if (prevIndex === 2) {
      if (
        !this.projectEntities?.psCompetitor?.competitorCompanyCrmDTO?.length
      ) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.width = '600px';
        dialogConfig.panelClass = 'custom-dialog-container';
        dialogConfig.data = {
          title: this.translate.instant('COMMON.Attention'),
          message: this.translate.instant(
            'PS_FORM.TheCompetitorsListMustContainAtLeastOneCompetitor'
          ),
          messageClass: 'error-color mb-0 fw-700',
          buttonTrue: this.translate.instant('COMMON.GoBack'),
        };
        const dialogRef: MatDialogRef<ConfirmationDialogComponent, any> =
          this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
          if (dialogResult) {
            this.index = prevIndex;
          }
        });
      }
    }
  }

  // goBackToCrmDetail(): void {
  //   this.router.navigate(['/crm-detail-readonly', this.idProject, this.idCustomer]);
  // }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
