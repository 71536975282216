<div class="ir-form reference-competitors-form">
  <form [formGroup]="referenceCompetitorsForm" (ngSubmit)="submitCompetitors()">
    <div class="section mb-50">
      <div class="header">
        <div>
          <div class="title">
            <span>{{ "IR_FORM.Nationals" | translate }}</span>
          </div>
          <div class="description">
            <span>{{
              "IR_FORM.SubTitleNationalsReferenceCompetitorsDescription"
                | translate
            }}</span>
          </div>
        </div>
      </div>
      <div class="content">
        <ng-container formArrayName="nationalCompetitors">
          <div
            *ngFor="
              let nationalCompetitor of nationalCompetitors.controls;
              let i = index
            "
            [formGroupName]="i"
            class="section-card my-20"
          >
            <div class="content with-left-element-70">
              <div class="row">
                <mat-form-field class="col-3">
                  <mat-label for="companyName">
                    {{ "IR_FORM.BusinessName" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="companyName"
                    type="text"
                    name="companyName"
                  />
                  <mat-error
                    *ngIf="nationalCompetitor.get('companyName').invalid"
                  >
                    {{
                      nationalCompetitor.get("companyName").errors
                        | getErrorMessage: translations.BusinessName
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label for="idCompetitorType">
                    {{ "IR_FORM.Tipology" | translate }}
                  </mat-label>
                  <mat-select
                    disableOptionCentering
                    panelClass="custom-panel-class"
                    formControlName="idCompetitorType"
                    name="idCompetitorType"
                  >
                    <mat-option [value]="1">{{
                      "IR_FORM.Favorable" | translate
                    }}</mat-option>
                    <mat-option [value]="2">{{
                      "IR_FORM.Unfavorable" | translate
                    }}</mat-option>
                    <mat-option [value]="3">{{
                      "IR_FORM.Similar" | translate
                    }}</mat-option>
                    <mat-option [value]="4">{{
                      "IR_FORM.NeverMetDirectly" | translate
                    }}</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="nationalCompetitor.get('idCompetitorType').invalid"
                  >
                    {{
                      nationalCompetitor.get("idCompetitorType").errors
                        | getErrorMessage: translations.Tipology
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-12">
                  <mat-label for="note">{{
                    "IR_FORM.Note" | translate
                  }}</mat-label>
                  <input matInput formControlName="note" name="note" />
                  <mat-error *ngIf="nationalCompetitor.get('note').invalid">
                    {{ nationalCompetitor.get("note").errors
                        | getErrorMessage: translations.Note
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="header h42">
              <div class="title small">
                <span>{{ "IR_FORM.CompanyInfo" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <div class="row">
                <mat-form-field class="col">
                  <mat-label for="idCountry">{{
                    "IR_FORM.Nation" | translate
                  }}</mat-label>
                  <mat-select
                    disableOptionCentering
                    panelClass="custom-panel-class"
                    formControlName="idCountry"
                    name="idCountry"
                    (closed)="filter.value = ''"
                  >
                    <div class="select-global-filter">
                      <input
                        #filter
                        matInput
                        type="text"
                        [placeholder]="'COMMON.Search...' | translate"
                      />
                      <button
                        matSuffix
                        *ngIf="filter.value !== ''"
                        class="only-icon-button"
                        type="button"
                        (click)="filter.value = null"
                      >
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                    <mat-option
                      [ngStyle]="{
                        display:
                          (
                            countries | filterSelect: filter.value:'country'
                          ).includes(country) || filter.value === ''
                            ? 'flex'
                            : 'none'
                      }"
                      *ngFor="let country of countries"
                      [value]="country.idCountry"
                    >
                      {{ country.country }}
                    </mat-option>
                    <div
                      *ngIf="
                        !(countries | filterSelect: filter.value:'country')
                          ?.length
                      "
                      class="select-global-filter-no-result"
                    >
                      {{ "COMMON.NoResult" | translate }}
                    </div>
                  </mat-select>
                  <mat-error
                    *ngIf="nationalCompetitor.get('idCountry').invalid"
                  >
                    {{
                      nationalCompetitor.get("idCountry").errors
                        | getErrorMessage: translations.Nation
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="province">{{
                    "COMMON.StateProvince" | translate
                  }}</mat-label>
                  <input
                    #provinceInput
                    matInput
                    formControlName="province"
                    type="text"
                    name="province"
                    (keyup)="
                      provinceInput.value = $event.target.value.toUpperCase()
                    "
                  />
                  <mat-error *ngIf="nationalCompetitor.get('province').invalid">
                    {{
                      nationalCompetitor.get("province").errors
                        | getErrorMessage: translations.StateProvince
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="city">
                    {{ "COMMON.City" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="city"
                    type="text"
                    name="city"
                  />
                  <mat-error *ngIf="nationalCompetitor.get('city').invalid">
                    {{
                      nationalCompetitor.get("city").errors
                        | getErrorMessage: translations.City
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="webSiteUrl">
                    {{ "COMMON.Website" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="webSiteUrl"
                    type="text"
                    name="webSiteUrl"
                  />
                  <mat-error
                    *ngIf="nationalCompetitor.get('webSiteUrl').invalid"
                  >
                    {{
                      nationalCompetitor.get("webSiteUrl").errors
                        | getErrorMessage: translations.Website
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="commercialBrands">
                    {{ "IR_FORM.CommercialBrands" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="commercialBrands"
                    type="text"
                    name="commercialBrands"
                  />
                  <mat-error
                    *ngIf="nationalCompetitor.get('commercialBrands').invalid"
                  >
                    {{
                      nationalCompetitor.get("commercialBrands").errors
                        | getErrorMessage: translations.CommercialBrands
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col">
                  <mat-label for="revenueCustomer">
                    {{ "IR_FORM.RevenueCustomer" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="revenueCustomer"
                    name="revenueCustomer"
                    currencyMask
                    autocomplete="off"
                    [options]="currencyDefaultConfig"
                  />
                  <mat-error
                    *ngIf="nationalCompetitor.get('revenueCustomer').invalid"
                  >
                    {{
                      nationalCompetitor.get("revenueCustomer").errors
                        | getErrorMessage: translations.RevenueCustomer
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="revenueExport">
                    {{ "IR_FORM.RevenueExport" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="revenueExport"
                    name="revenueExport"
                    currencyMask
                    autocomplete="off"
                    [options]="currencyDefaultConfig"
                  />
                  <mat-error
                    *ngIf="nationalCompetitor.get('revenueExport').invalid"
                  >
                    {{
                      nationalCompetitor.get("revenueExport").errors
                        | getErrorMessage: translations.RevenueExport
                    }}
                  </mat-error>
                </mat-form-field>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
              </div>
            </div>
            <div class="header h42">
              <div class="title small">
                <span>{{ "IR_FORM.Products" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <div class="products-list">
                <mat-checkbox
                  #nationalCheckbox
                  *ngFor="let product of products"
                  color="primary"
                  class="mr-20"
                  [disabled]="isReadOnly || isLabManagerInternal"
                  [checked]="
                    nationalCompetitor
                      .get('idProducts')
                      ?.value?.includes(product.idProduct)
                  "
                  (change)="
                    handleProductSelect(
                      nationalCompetitor.get('isGlobal').value,
                      nationalCheckbox,
                      i,
                      product.idProduct
                    )
                  "
                >
                  {{ product.name }}
                </mat-checkbox>
              </div>
            </div>
            <div class="header h42">
              <div class="title small">
                <span>{{ "IR_FORM.PresenceMarkets" | translate }}</span>
              </div>
              <button
                type="button"
                [matMenuTriggerFor]="marketsDescription"
                class="only-icon-button primary ml-05"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>info</mat-icon>
              </button>
              <mat-menu #marketsDescription="matMenu">
                <div style="padding: 0 10px">
                  {{ "IR_FORM.MarketsLegend" | translate }}
                </div>
              </mat-menu>
            </div>
            <div class="content">
              <div class="row">
                <mat-form-field class="col-12">
                  <mat-label for="countryCompetitorMarketAreas">
                    {{ "IR_FORM.Nations" | translate }}
                  </mat-label>
                  <mat-select
                    disableOptionCentering
                    panelClass="custom-panel-class"
                    #nationalCountrySelect
                    formControlName="countryCompetitorMarketAreas"
                    name="countryCompetitorMarketAreas"
                    multiple
                    (closed)="filterArea.value = ''"
                    [typeaheadDebounceInterval]="300"
                  >
                    <div class="select-global-filter">
                      <input
                        #filterArea
                        matInput
                        type="text"
                        [placeholder]="'COMMON.Search...' | translate"
                      />
                      <button
                        matSuffix
                        *ngIf="filterArea.value !== ''"
                        class="only-icon-button"
                        type="button"
                        (click)="filterArea.value = null"
                      >
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                    <cdk-virtual-scroll-viewport [itemSize]="10">
                      <div
                        *cdkVirtualFor="let area of macroArea; let inde = index"
                      >
                        <div
                          *ngIf="
                            (
                              filteredCountriesByArea[area.toLowerCase()]
                              | filterSelect: filterArea.value:'country'
                            )?.length || filterArea.value === ''
                          "
                          class="custom-mat-option"
                          (click)="
                            macroAreaBoolean[inde] = !macroAreaBoolean[inde]
                          "
                        >
                          <span>{{ "MACRO_AREA." + area | translate }}</span>
                          <button
                            class="only-text-button primary fs-12 ml-20"
                            (click)="
                              checkMacroArea('select', area, i, 'national')
                            "
                          >
                            {{ "COMMON.SelectAll" | translate }}
                          </button>
                          <button
                            class="only-text-button primary fs-12 ml-20"
                            (click)="
                              checkMacroArea('deselect', area, i, 'national')
                            "
                          >
                            {{ "COMMON.DeselectAll" | translate }}
                          </button>
                          <mat-icon>{{
                            macroAreaBoolean[inde]
                              ? "expand_less"
                              : "expand_more"
                          }}</mat-icon>
                        </div>
                        <mat-optgroup
                          [ngStyle]="{
                            display: macroAreaBoolean[inde] ? '' : 'none'
                          }"
                          [label]="'COMMON.' + area | translate"
                        >
                          <mat-option
                            *ngFor="
                              let country of filteredCountriesByArea[
                                area.toLowerCase()
                              ]
                            "
                            [ngStyle]="{
                              display:
                                (
                                  filteredCountriesByArea[area.toLowerCase()]
                                  | filterSelect: filterArea.value:'country'
                                ).includes(country) || filterArea.value === ''
                                  ? 'flex'
                                  : 'none'
                            }"
                            [value]="country.idCountry"
                          >
                            {{ country.country }}
                          </mat-option>
                        </mat-optgroup>
                      </div>
                    </cdk-virtual-scroll-viewport>
                    <div
                      *ngIf="
                        !(countries | filterSelect: filterArea.value:'country')
                          ?.length
                      "
                      class="select-global-filter-no-result"
                    >
                      {{ "COMMON.NoResult" | translate }}
                    </div>
                  </mat-select>
                  <!-- <button
                    type="button"
                    matSuffix
                    [matMenuTriggerFor]="macroareaDescription"
                    class="only-icon-button primary"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-icon>info</mat-icon>
                  </button>
                  <mat-menu #macroareaDescription="matMenu">
                    <div style="padding: 0 10px">
                      {{
                        "IR_FORM.IsPossibleToSelectAreaByMacroArea" | translate
                      }}
                    </div>
                  </mat-menu> -->
                  <mat-error>
                    {{
                      nationalCompetitor.get("countryCompetitorMarketAreas")
                        .errors | getErrorMessage: translations?.Nations
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div
              *ngIf="functionalities?.isp.create || functionalities?.isp.edit"
              class="header h42 multi"
            >
              <div class="title small">
                <span>{{ "IR_FORM.AnagraphicsDetailed" | translate }}</span>
              </div>
              <button
                type="button"
                class="rounded-button primary-bg-button"
                [disabled]="isReadOnly || isLabManagerInternal"
                (click)="
                  addDistribution(
                    nationalCompetitor.get('isGlobal').value,
                    i,
                    getNationalCompetitorDistribution(i).getRawValue().length
                  )
                "
              >
                {{ "IR_FORM.AddAnagraphicCombi" | translate }}
              </button>
            </div>
            <div
              *ngIf="functionalities?.isp.create || functionalities?.isp.edit"
              class="content"
              formArrayName="competitorDistributionChannelDTOAggregation"
            >
              <div
                *ngFor="
                  let channel of getNationalCompetitorDistribution(i).controls;
                  let ind = index
                "
                [formGroupName]="ind"
                class="channel-container"
              >
                <div class="row">
                  <mat-form-field appearance="standard" class="col-6">
                    <mat-label for="idChannelType">
                      {{ "IR_FORM.Distribution" | translate }}
                    </mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idChannelType"
                      name="idChannelType"
                      (selectionChange)="
                        activateSpecificationsSelect($event, channel)
                      "
                    >
                      <mat-option
                        #channelTypeOption
                        *ngFor="let channelType of channelsType"
                        [value]="channelType.idChannelType"
                      >
                        {{ channelType.description }}
                      </mat-option>
                    </mat-select>
                    <button
                      *ngIf="!isReadOnly && !isLabManagerInternal"
                      type="button"
                      class="only-icon-button primary"
                      matSuffix
                      (click)="
                        showChannelsManagementDialog($event, 'channel', channel)
                      "
                    >
                      <svg-icon name="list_icon_2" class="w20"></svg-icon>
                    </button>
                    <!-- <input
                      matInput
                      type="text"
                      name="distributionName"
                      formControlName="distributionName"
                    /> -->
                    <mat-error>
                      {{
                        channel.get("idChannelType").errors
                          | getErrorMessage: translations?.Channel
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-5">
                    <mat-label for="idSpecificationChannelType">{{
                      "IR_FORM.ChannelSpec" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idSpecificationChannelType"
                      name="idSpecificationChannelType"
                      (selectionChange)="
                        setSpecificationToRelativeAnagrafics($event, channel)
                      "
                    >
                      <mat-option
                        *ngFor="
                          let channelSpec of specificationsChannels
                            | filterSelectByCondition
                              : channel.get('idChannelType').value
                              : 'idChannelType'
                        "
                        [value]="channelSpec.idSpecificationChannelType"
                      >
                        {{ channelSpec.description }}
                      </mat-option>
                    </mat-select>
                    <div class="d-flex" matSuffix>
                      <button
                        type="button"
                        class="only-icon-button primary"
                        [disabled]="
                          isReadonly || !channel.get('idChannelType').value
                        "
                        (click)="
                          showChannelsManagementDialog(
                            $event,
                            'channelSpec',
                            channel
                          )
                        "
                      >
                        <svg-icon name="list_icon_2" class="w20"></svg-icon>
                      </button>
                      <button
                        type="button"
                        [matMenuTriggerFor]="channelSpecDescription"
                        class="only-icon-button primary ml-10"
                        (click)="$event.stopPropagation()"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                    </div>
                    <mat-menu #channelSpecDescription="matMenu">
                      <div style="padding: 0 10px">
                        {{
                          "IR_FORM.ChannelSpecDescription" | translate
                        }}
                      </div>
                    </mat-menu>
                    <mat-error>
                      {{
                        channel.get("idSpecificationChannelType").errors
                          | getErrorMessage: translations?.ChannelSpecification
                      }}
                    </mat-error>
                  </mat-form-field>
                  <div class="col-1">
                    <div class="d-flex justify-content-end">
                      <button
                        class="only-text-button error"
                        type="button"
                        [disabled]="isReadOnly || isLabManagerInternal"
                        (click)="
                          removeChannel(
                            nationalCompetitor.get('isGlobal').value,
                            i,
                            ind
                          )
                        "
                      >
                        {{ "COMMON.Delete" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <mat-form-field appearance="standard" class="col-6">
                    <mat-label for="importanceLevel">
                      {{ "IR_FORM.ImportanceLevel" | translate }}
                    </mat-label>
                    <mat-select formControlName="importanceLevel">
                      <mat-option
                        *ngFor="
                          let item of [].constructor(10);
                          let itemIndex = index
                        "
                        [value]="itemIndex + 1"
                      >
                        {{ itemIndex + 1 }}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      {{ channel.get("importanceLevel").errors
                          | getErrorMessage: translations?.ImportanceLevel
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <ng-container formArrayName="competitorDistributionChannel">
                  <div
                    *ngFor="
                      let anagrafic of getNationalCompetitorDistributionAnagafics(
                        i,
                        ind
                      )?.controls;
                      let _i = index
                    "
                    [formGroupName]="_i"
                    class="row"
                  >
                    <mat-form-field class="col-4">
                      <mat-label for="name">
                        {{ "IR_FORM.BusinessName" | translate }}
                      </mat-label>
                      <input
                        matInput
                        name="name"
                        formControlName="name"
                        type="text"
                      />
                      <mat-error *ngIf="!anagrafic.get('name').valid">
                        {{
                          anagrafic.get("name").errors
                            | getErrorMessage: translations.BusinessName
                        }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label for="country">
                        {{ "IR_FORM.Nationality" | translate }}
                      </mat-label>
                      <mat-select
                        disableOptionCentering
                        panelClass="custom-panel-class"
                        formControlName="country"
                        name="country"
                        (closed)="filter.value = ''"
                      >
                        <div class="select-global-filter">
                          <input
                            #filter
                            matInput
                            type="text"
                            [placeholder]="'COMMON.Search...' | translate"
                          />
                          <button
                            matSuffix
                            *ngIf="filter.value !== ''"
                            class="only-icon-button"
                            type="button"
                            (click)="filter.value = null"
                          >
                            <mat-icon>clear</mat-icon>
                          </button>
                        </div>
                        <mat-option
                          [ngStyle]="{
                            display:
                              (
                                countries | filterSelect: filter.value:'country'
                              ).includes(country) || filter.value === ''
                                ? 'flex'
                                : 'none'
                          }"
                          *ngFor="let country of countries"
                          [value]="country.idCountry"
                        >
                          {{ country.country }}
                        </mat-option>
                        <div
                          *ngIf="
                            !(countries | filterSelect: filter.value:'country')
                              ?.length
                          "
                          class="select-global-filter-no-result"
                        >
                          {{ "COMMON.NoResult" | translate }}
                        </div>
                      </mat-select>
                      <mat-error *ngIf="anagrafic.get('country').invalid">
                        {{
                          anagrafic.get("country").errors
                            | getErrorMessage: translations.Nationality
                        }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-4">
                      <mat-label for="webSiteUrl">
                        {{ "IR_FORM.Website" | translate }}
                      </mat-label>
                      <input
                        matInput
                        formControlName="webSiteUrl"
                        type="text"
                        name="webSiteUrl"
                      />
                      <mat-error *ngIf="!anagrafic.get('webSiteUrl').valid">
                        {{
                          anagrafic.get("webSiteUrl").errors
                            | getErrorMessage: translations.Website
                        }}
                      </mat-error>
                    </mat-form-field>
                    <div class="col-1">
                      <button
                        class="only-icon-button error"
                        type="button"
                        [disabled]="isLabManagerInternal || isReadOnly"
                        (click)="
                          removeDistributionAnagrafic(
                            nationalCompetitor.get('isGlobal').value,
                            i,
                            ind,
                            _i
                          )
                        "
                      >
                        <svg-icon name="trash_icon"></svg-icon>
                      </button>
                    </div>
                  </div>
                  <div class="center-buttons mt-10">
                    <button
                      class="rounded-button primary-border-button"
                      type="button"
                      [disabled]="isLabManagerInternal || isReadOnly"
                      (click)="
                        addDistributionAnagrafic(
                          nationalCompetitor.get('isGlobal').value,
                          i,
                          channel.getRawValue(),
                          ind
                        )
                      "
                    >
                      {{ "IR_FORM.AddAnagraphic" | translate }}
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
            <div *ngIf="functionalities.isp.create || functionalities.isp.edit">
              <div class="ml-15">
                <span class="bold-text-color">{{
                  "IR_FORM.ValueForPositioningChart" | translate
                }}</span>
              </div>
              <div class="fs-12 ml-15 mb-10" style="color: #4d4f5c">
                <span>{{
                  "IR_FORM.ValueForCompetitorPositioningChartDescription"
                    | translate
                }}</span>
              </div>
              <div class="row">
                <mat-form-field appearance="standard" class="col-3">
                  <mat-label for="product">
                    {{ "IR_FORM.Product" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="product"
                    type="number"
                    min="1"
                    max="10"
                    step="0.01"
                    name="product"
                  />
                  <mat-error *ngIf="nationalCompetitor.get('product').invalid">
                    {{
                      nationalCompetitor.get("product").errors
                        | getErrorMessage: translations.Product
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-3">
                  <mat-label for="distribution">
                    {{ "IR_FORM.distribution" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="distribution"
                    type="number"
                    min="1"
                    max="10"
                    step="0.01"
                    name="distribution"
                  />
                  <mat-error
                    *ngIf="nationalCompetitor.get('distribution').invalid"
                  >
                    {{
                      nationalCompetitor.get("distribution").errors
                        | getErrorMessage: translations.distribution
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-3">
                  <mat-label for="priceSelling">
                    {{ "IR_FORM.PriceSelling" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="priceSelling"
                    type="number"
                    min="1"
                    max="10"
                    step="0.01"
                    name="priceSelling"
                  />
                  <mat-error
                    *ngIf="nationalCompetitor.get('priceSelling').invalid"
                  >
                    {{
                      nationalCompetitor.get("priceSelling").errors
                        | getErrorMessage: translations.PriceSelling
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="standard" class="col-3">
                  <mat-label for="communicationMarketing">
                    {{ "IR_FORM.CommunicationMarketing" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="communicationMarketing"
                    type="number"
                    min="1"
                    max="10"
                    step="0.01"
                    name="communicationMarketing"
                  />
                  <mat-error
                    *ngIf="
                      nationalCompetitor.get('communicationMarketing').invalid
                    "
                  >
                    {{
                      nationalCompetitor.get("communicationMarketing").errors
                        | getErrorMessage: translations.CommunicationMarketing
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="center-buttons pb-20 mt-20">
              <button
                type="button"
                class="rounded-button primary-border-button"
                [disabled]="
                  getNationalDistributionNames(i)?.length > 0 ||
                  isLabManagerInternal ||
                  isReadOnly
                "
                (click)="
                  removeCompetitor(i, nationalCompetitor.get('isGlobal').value)
                "
              >
                {{ "COMMON.Delete" | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <button
          *ngIf="!isReadOnly && !isLabManagerInternal"
          type="button"
          class="rounded-button primary-bg-button ml-auto"
          (click)="addCompetitor(CompetitorType.National)"
        >
          {{ "IR_FORM.AddContent" | translate }}
        </button>
      </div>
    </div>
    <div class="section mb-50">
      <div class="header">
        <div>
          <div class="title">
            <span>{{ "IR_FORM.Foreigners" | translate }}</span>
          </div>
          <div class="description">
            <span>
              {{
                "IR_FORM.SubTitleForeignersReferenceCompetitorsDescription"
                  | translate
              }}
            </span>
          </div>
        </div>
      </div>
      <div class="content">
        <ng-container formArrayName="foreignCompetitors">
          <div
            *ngFor="
              let foreignCompetitor of foreignCompetitors.controls;
              let i = index
            "
            [formGroupName]="i"
            class="section-card my-20"
          >
            <div class="content with-left-element-70">
              <div class="row">
                <mat-form-field class="col-3">
                  <mat-label for="companyName">
                    {{ "IR_FORM.BusinessName" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="companyName"
                    type="text"
                    name="companyName"
                  />
                  <mat-error
                    *ngIf="foreignCompetitor.get('companyName').invalid"
                  >
                    {{
                      foreignCompetitor.get("companyName").errors
                        | getErrorMessage: translations.BusinessName
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col-3">
                  <mat-label for="idCompetitorType">
                    {{ "IR_FORM.Tipology" | translate }}
                  </mat-label>
                  <mat-select
                    disableOptionCentering
                    panelClass="custom-panel-class"
                    formControlName="idCompetitorType"
                    name="idCompetitorType"
                  >
                    <mat-option [value]="1">{{
                      "IR_FORM.Favorable" | translate
                    }}</mat-option>
                    <mat-option [value]="2">{{
                      "IR_FORM.Unfavorable" | translate
                    }}</mat-option>
                    <mat-option [value]="3">{{
                      "IR_FORM.Similar" | translate
                    }}</mat-option>
                    <mat-option [value]="4">{{
                      "IR_FORM.NeverMetDirectly" | translate
                    }}</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="foreignCompetitor.get('idCompetitorType').invalid"
                  >
                    {{
                      foreignCompetitor.get("idCompetitorType").errors
                        | getErrorMessage: translations.Tipology
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col-12">
                  <mat-label for="note">{{
                    "IR_FORM.Note" | translate
                  }}</mat-label>
                  <input matInput formControlName="note" name="note" />
                </mat-form-field>
              </div>
            </div>
            <div class="header h42">
              <div class="title small">
                <span>{{ "IR_FORM.CompanyInfo" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <div class="row">
                <mat-form-field class="col">
                  <mat-label for="idCountry">{{
                    "IR_FORM.Nation" | translate
                  }}</mat-label>
                  <mat-select
                    disableOptionCentering
                    panelClass="custom-panel-class"
                    formControlName="idCountry"
                    name="idCountry"
                    (closed)="filter.value = ''"
                  >
                    <div class="select-global-filter">
                      <input
                        #filter
                        matInput
                        type="text"
                        [placeholder]="'COMMON.Search...' | translate"
                      />
                      <button
                        matSuffix
                        *ngIf="filter.value !== ''"
                        class="only-icon-button"
                        type="button"
                        (click)="filter.value = null"
                      >
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                    <mat-option
                      [ngStyle]="{
                        display:
                          (
                            countries | filterSelect: filter.value:'country'
                          ).includes(country) || filter.value === ''
                            ? 'flex'
                            : 'none'
                      }"
                      *ngFor="let country of countries"
                      [value]="country.idCountry"
                    >
                      {{ country.country }}
                    </mat-option>
                    <div
                      *ngIf="
                        !(countries | filterSelect: filter.value:'country')
                          ?.length
                      "
                      class="select-global-filter-no-result"
                    >
                      {{ "COMMON.NoResult" | translate }}
                    </div>
                  </mat-select>
                  <mat-error *ngIf="foreignCompetitor.get('idCountry').invalid">
                    {{
                      foreignCompetitor.get("idCountry").errors
                        | getErrorMessage: translations.Nation
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="province">{{
                    "COMMON.StateProvince" | translate
                  }}</mat-label>
                  <input
                    matInput
                    formControlName="province"
                    type="text"
                    name="province"
                  />
                  <mat-error *ngIf="foreignCompetitor.get('province').invalid">
                    {{
                      foreignCompetitor.get("province").errors
                        | getErrorMessage: translations.StateProvince
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="city">
                    {{ "COMMON.City" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="city"
                    type="text"
                    name="city"
                  />
                  <mat-error *ngIf="foreignCompetitor.get('city').invalid">
                    {{
                      foreignCompetitor.get("city").errors
                        | getErrorMessage: translations.City
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="webSiteUrl">
                    {{ "COMMON.Website" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="webSiteUrl"
                    type="text"
                    name="webSiteUrl"
                  />
                  <mat-error
                    *ngIf="foreignCompetitor.get('webSiteUrl').invalid"
                  >
                    {{
                      foreignCompetitor.get("webSiteUrl").errors
                        | getErrorMessage: translations.Website
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="commercialBrands">
                    {{ "IR_FORM.CommercialBrands" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="commercialBrands"
                    type="text"
                    name="commercialBrands"
                  />
                  <mat-error
                    *ngIf="foreignCompetitor.get('commercialBrands').invalid"
                  >
                    {{
                      foreignCompetitor.get("commercialBrands").errors
                        | getErrorMessage: translations.CommercialBrands
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field class="col">
                  <mat-label for="revenueCustomer">
                    {{ "IR_FORM.RevenueCustomer" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="revenueCustomer"
                    name="revenueCustomer"
                    currencyMask
                    autocomplete="off"
                    [options]="currencyDefaultConfig"
                  />
                  <mat-error
                    *ngIf="foreignCompetitor.get('revenueCustomer').invalid"
                  >
                    {{
                      foreignCompetitor.get("revenueCustomer").errors
                        | getErrorMessage: translations.RevenueCustomer
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field class="col">
                  <mat-label for="revenueExport">
                    {{ "IR_FORM.RevenueExport" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="revenueExport"
                    name="revenueExport"
                    currencyMask
                    autocomplete="off"
                    [options]="currencyDefaultConfig"
                  />
                  <mat-error
                    *ngIf="foreignCompetitor.get('revenueExport').invalid"
                  >
                    {{
                      foreignCompetitor.get("revenueExport").errors
                        | getErrorMessage: translations.RevenueExport
                    }}
                  </mat-error>
                </mat-form-field>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
              </div>
              <div
                *ngIf="functionalities.isp.create || functionalities.isp.edit"
              >
                <div class="ml-15 mt-10">
                  <span class="bold-text-color">{{
                    "IR_FORM.ValueForPositioningChart" | translate
                  }}</span>
                </div>
                <div class="fs-12 ml-15 mb-30" style="color: #4d4f5c">
                  <span>{{
                    "IR_FORM.ValueForCompetitorPositioningChartDescription"
                      | translate
                  }}</span>
                </div>
                <div class="row">
                  <mat-form-field appearance="standard" class="col-3">
                    <mat-label class="bold-title" for="product">
                      {{ "IR_FORM.Product" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="product"
                      type="number"
                      min="1"
                      max="10"
                      step="0.01"
                      name="product"
                    />
                    <mat-error *ngIf="foreignCompetitor.get('product').invalid">
                      {{
                        foreignCompetitor.get("product").errors
                          | getErrorMessage: translations.Product
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-3">
                    <mat-label class="bold-title" for="distribution">
                      {{ "IR_FORM.distribution" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="distribution"
                      type="number"
                      min="1"
                      max="10"
                      step="0.01"
                      name="distribution"
                    />
                    <mat-error
                      *ngIf="foreignCompetitor.get('distribution').invalid"
                    >
                      {{
                        foreignCompetitor.get("distribution").errors
                          | getErrorMessage: translations.distribution
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-3">
                    <mat-label class="bold-title" for="priceSelling">
                      {{ "IR_FORM.PriceSelling" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="priceSelling"
                      type="number"
                      min="1"
                      max="10"
                      step="0.01"
                      name="priceSelling"
                    />
                    <mat-error
                      *ngIf="foreignCompetitor.get('priceSelling').invalid"
                    >
                      {{
                        foreignCompetitor.get("priceSelling").errors
                          | getErrorMessage: translations.PriceSelling
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-3">
                    <mat-label class="bold-title" for="communicationMarketing">
                      {{ "IR_FORM.CommunicationMarketing" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="communicationMarketing"
                      type="number"
                      min="1"
                      max="10"
                      step="0.01"
                      name="communicationMarketing"
                    />
                    <mat-error
                      *ngIf="
                        foreignCompetitor.get('communicationMarketing').invalid
                      "
                    >
                      {{
                        foreignCompetitor.get("communicationMarketing").errors
                          | getErrorMessage: translations.CommunicationMarketing
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="header h42">
              <div class="title small">
                <span>{{ "IR_FORM.Products" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <div class="products-list">
                <mat-checkbox
                  #foreignCheckbox
                  *ngFor="let product of products"
                  color="primary"
                  class="mr-20"
                  [disabled]="isReadOnly || isLabManagerInternal"
                  [checked]="
                    foreignCompetitor
                      .get('idProducts')
                      ?.value?.includes(product.idProduct)
                  "
                  (change)="
                    handleProductSelect(
                      foreignCompetitor.get('isGlobal').value,
                      foreignCheckbox,
                      i,
                      product.idProduct
                    )
                  "
                >
                  {{ product.name }}
                </mat-checkbox>
              </div>
            </div>
            <div class="header h42">
              <div class="title small">
                <span>{{ "IR_FORM.PresenceMarkets" | translate }}</span>
              </div>
              <button
                type="button"
                [matMenuTriggerFor]="marketsDescription2"
                class="only-icon-button primary ml-05"
                (click)="$event.stopPropagation()"
              >
                <mat-icon>info</mat-icon>
              </button>
              <mat-menu #marketsDescription2="matMenu">
                <div style="padding: 0 10px">
                  {{ "IR_FORM.MarketsLegend" | translate }}
                </div>
              </mat-menu>
            </div>
            <div class="content">
              <div class="row">
                <mat-form-field class="col-12">
                  <mat-label for="countryCompetitorMarketAreas">
                    {{ "IR_FORM.Nations" | translate }}
                  </mat-label>
                  <mat-select
                    disableOptionCentering
                    panelClass="custom-panel-class"
                    #foreignCountrySelect
                    formControlName="countryCompetitorMarketAreas"
                    name="countryCompetitorMarketAreas"
                    multiple
                    (closed)="filterArea.value = ''"
                  >
                    <div class="select-global-filter">
                      <input
                        #filterArea
                        matInput
                        type="text"
                        [placeholder]="'COMMON.Search...' | translate"
                      />
                      <button
                        matSuffix
                        *ngIf="filterArea.value !== ''"
                        class="only-icon-button"
                        type="button"
                        (click)="filterArea.value = null"
                      >
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                    <cdk-virtual-scroll-viewport [itemSize]="10">
                      <div
                        *cdkVirtualFor="let area of macroArea; let inde = index"
                      >
                        <div
                          *ngIf="
                            (
                              filteredCountriesByArea[area.toLowerCase()]
                              | filterSelect: filterArea.value:'country'
                            )?.length || filterArea.value === ''
                          "
                          class="custom-mat-option"
                          (click)="
                            macroAreaBoolean[inde] = !macroAreaBoolean[inde]
                          "
                        >
                          <span>{{ "MACRO_AREA." + area | translate }}</span>
                          <button
                            class="only-text-button primary fs-12 ml-20"
                            (click)="
                              checkMacroArea('select', area, i, 'foreign')
                            "
                          >
                            {{ "COMMON.SelectAll" | translate }}
                          </button>
                          <button
                            class="only-text-button primary fs-12 ml-20"
                            (click)="
                              checkMacroArea('deselect', area, i, 'foreign')
                            "
                          >
                            {{ "COMMON.DeselectAll" | translate }}
                          </button>
                          <mat-icon>{{
                            macroAreaBoolean[inde]
                              ? "expand_less"
                              : "expand_more"
                          }}</mat-icon>
                        </div>
                        <mat-optgroup
                          [ngStyle]="{
                            display: macroAreaBoolean[inde] ? '' : 'none'
                          }"
                          [label]="'COMMON.' + area | translate"
                        >
                          <mat-option
                            [ngStyle]="{
                              display:
                                (
                                  filteredCountriesByArea[area.toLowerCase()]
                                  | filterSelect: filterArea.value:'country'
                                ).includes(country) || filterArea.value === ''
                                  ? 'flex'
                                  : 'none'
                            }"
                            *ngFor="
                              let country of filteredCountriesByArea[
                                area.toLowerCase()
                              ]
                            "
                            [value]="country.idCountry"
                          >
                            {{ country.country }}
                          </mat-option>
                        </mat-optgroup>
                      </div>
                    </cdk-virtual-scroll-viewport>
                    <div
                      *ngIf="
                        !(countries | filterSelect: filterArea.value:'country')
                          ?.length
                      "
                      class="select-global-filter-no-result"
                    >
                      {{ "COMMON.NoResult" | translate }}
                    </div>
                  </mat-select>
                  <!-- <button
                    type="button"
                    matSuffix
                    [matMenuTriggerFor]="macroareaDescriptionForeign"
                    class="only-icon-button primary"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-icon>info</mat-icon>
                  </button>
                  <mat-menu #macroareaDescriptionForeign="matMenu">
                    <div style="padding: 0 10px">
                      {{
                        "IR_FORM.IsPossibleToSelectAreaByMacroArea" | translate
                      }}
                    </div>
                  </mat-menu> -->
                  <mat-error>
                    {{
                      foreignCompetitor.get("countryCompetitorMarketAreas")
                        .errors | getErrorMessage: translations?.Nations
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div
              *ngIf="functionalities?.isp.create || functionalities?.isp.edit"
              class="header h42 multi"
            >
              <div class="title small">
                <span>{{ "IR_FORM.AnagraphicsDetailed" | translate }}</span>
              </div>
              <button
                class="rounded-button primary-bg-button"
                type="button"
                [disabled]="isReadOnly || isLabManagerInternal"
                (click)="
                  addDistribution(
                    foreignCompetitor.get('isGlobal').value,
                    i,
                    getForeignCompetitorDistribution(i).getRawValue().length
                  )
                "
              >
                {{ "IR_FORM.AddAnagraphicCombi" | translate }}
              </button>
            </div>
            <div
              *ngIf="functionalities?.isp.create || functionalities?.isp.edit"
              class="content"
              formArrayName="competitorDistributionChannelDTOAggregation"
            >
              <div
                *ngFor="
                  let channel of getForeignCompetitorDistribution(i).controls;
                  let ind = index
                "
                [formGroupName]="ind"
                class="channel-container"
              >
                <div class="row">
                  <mat-form-field appearance="standard" class="col-6">
                    <mat-label for="idChannelType">
                      {{ "IR_FORM.Distribution" | translate }}
                    </mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idChannelType"
                      name="idChannelType"
                      (selectionChange)="
                        activateSpecificationsSelect($event, channel)
                      "
                    >
                      <mat-option
                        #channelTypeOption
                        *ngFor="let channelType of channelsType"
                        [value]="channelType.idChannelType"
                      >
                        {{ channelType.description }}
                      </mat-option>
                    </mat-select>
                    <button
                      *ngIf="!isReadOnly && !isLabManagerInternal"
                      type="button"
                      class="only-icon-button primary"
                      matSuffix
                      (click)="
                        showChannelsManagementDialog($event, 'channel', channel)
                      "
                    >
                      <svg-icon name="list_icon_2" class="w20"></svg-icon>
                    </button>
                    <mat-error>
                      {{ channel.get("idChannelType").errors
                          | getErrorMessage: translations?.Channel
                      }}
                    </mat-error>
                    <!-- <input
                      matInput
                      type="text"
                      name="distributionName"
                      formControlName="distributionName"
                    /> -->
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-5">
                    <mat-label for="idSpecificationChannelType">{{
                      "IR_FORM.ChannelSpec" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idSpecificationChannelType"
                      name="idSpecificationChannelType"
                    >
                      <mat-option
                        *ngFor="
                          let channelSpec of specificationsChannels
                            | filterSelectByCondition
                              : channel.get('idChannelType').value
                              : 'idChannelType'
                        "
                        [value]="channelSpec.idSpecificationChannelType"
                      >
                        {{ channelSpec.description }}
                      </mat-option>
                    </mat-select>
                    <div class="d-flex" matSuffix>
                      <button
                        type="button"
                        class="only-icon-button primary"
                        [disabled]="
                          isReadonly || !channel.get('idChannelType').value
                        "
                        (click)="
                          showChannelsManagementDialog(
                            $event,
                            'channelSpec',
                            channel
                          )
                        "
                      >
                        <svg-icon name="list_icon_2" class="w20"></svg-icon>
                      </button>
                      <button
                        type="button"
                        [matMenuTriggerFor]="channelSpecDescription"
                        class="only-icon-button primary ml-10"
                        (click)="$event.stopPropagation()"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                    </div>
                    <mat-menu #channelSpecDescription="matMenu">
                      <div style="padding: 0 10px">
                        {{
                          "IR_FORM.ChannelSpecDescription" | translate
                        }}
                      </div>
                    </mat-menu>
                    <mat-error>
                      {{
                        channel.get("idSpecificationChannelType").errors
                          | getErrorMessage: translations?.ChannelSpecification
                      }}
                    </mat-error>
                  </mat-form-field>
                  <div class="col-1">
                    <div class="d-flex justify-content-end">
                      <button
                        class="only-text-button error"
                        type="button"
                        [disabled]="isReadOnly || isLabManagerInternal"
                        (click)="
                          removeChannel(
                            foreignCompetitor.get('isGlobal').value,
                            i,
                            ind
                          )
                        "
                      >
                        {{ "COMMON.Delete" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <mat-form-field appearance="standard" class="col-6">
                    <mat-label for="importanceLevel">
                      {{ "IR_FORM.ImportanceLevel" | translate }}
                    </mat-label>
                    <mat-select formControlName="importanceLevel">
                      <mat-option
                        *ngFor="
                          let item of [].constructor(10);
                          let itemIndex = index
                        "
                        [value]="itemIndex + 1"
                      >
                        {{ itemIndex + 1 }}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      {{ channel.get("importanceLevel").errors
                          | getErrorMessage: translations?.ImportanceLevel
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <ng-container formArrayName="competitorDistributionChannel">
                  <div
                    *ngFor="
                      let anagrafic of getForeignCompetitorDistributionAnagafics(
                        i,
                        ind
                      )?.controls;
                      let _i = index
                    "
                    [formGroupName]="_i"
                    class="row"
                  >
                    <mat-form-field class="col-4">
                      <mat-label for="name">
                        {{ "IR_FORM.BusinessName" | translate }}
                      </mat-label>
                      <input
                        matInput
                        name="name"
                        formControlName="name"
                        type="text"
                      />
                      <mat-error *ngIf="!anagrafic.get('name').valid">
                        {{
                          anagrafic.get("name").errors
                            | getErrorMessage: translations.BusinessName
                        }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-3">
                      <mat-label for="country">
                        {{ "IR_FORM.Nationality" | translate }}
                      </mat-label>
                      <mat-select
                        disableOptionCentering
                        panelClass="custom-panel-class"
                        formControlName="country"
                        name="country"
                        (closed)="filter.value = ''"
                      >
                        <div class="select-global-filter">
                          <input
                            #filter
                            matInput
                            type="text"
                            [placeholder]="'COMMON.Search...' | translate"
                          />
                          <button
                            matSuffix
                            *ngIf="filter.value !== ''"
                            class="only-icon-button"
                            type="button"
                            (click)="filter.value = null"
                          >
                            <mat-icon>clear</mat-icon>
                          </button>
                        </div>
                        <mat-option
                          [ngStyle]="{
                            display:
                              (
                                countries | filterSelect: filter.value:'country'
                              ).includes(country) || filter.value === ''
                                ? 'flex'
                                : 'none'
                          }"
                          *ngFor="let country of countries"
                          [value]="country.idCountry"
                        >
                          {{ country.country }}
                        </mat-option>
                        <div
                          *ngIf="
                            !(countries | filterSelect: filter.value:'country')
                              ?.length
                          "
                          class="select-global-filter-no-result"
                        >
                          {{ "COMMON.NoResult" | translate }}
                        </div>
                      </mat-select>
                      <mat-error *ngIf="anagrafic.get('country').invalid">
                        {{
                          anagrafic.get("country").errors
                            | getErrorMessage: translations.Nationality
                        }}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field class="col-4">
                      <mat-label for="webSiteUrl">
                        {{ "IR_FORM.Website" | translate }}
                      </mat-label>
                      <input
                        matInput
                        formControlName="webSiteUrl"
                        type="text"
                        name="webSiteUrl"
                      />
                      <mat-error *ngIf="!anagrafic.get('webSiteUrl').valid">
                        {{
                          anagrafic.get("webSiteUrl").errors
                            | getErrorMessage: translations.Website
                        }}
                      </mat-error>
                    </mat-form-field>
                    <div class="col-1">
                      <button
                        class="only-icon-button error"
                        type="button"
                        [disabled]="isLabManagerInternal || isReadOnly"
                        (click)="
                          removeDistributionAnagrafic(
                            foreignCompetitor.get('isGlobal').value,
                            i,
                            ind,
                            _i
                          )
                        "
                      >
                        <svg-icon name="trash_icon"></svg-icon>
                      </button>
                    </div>
                  </div>
                  <div class="center-buttons mt-10">
                    <button
                      class="rounded-button primary-border-button"
                      type="button"
                      [disabled]="isLabManagerInternal || isReadOnly"
                      (click)="
                        addDistributionAnagrafic(
                          foreignCompetitor.get('isGlobal').value,
                          i,
                          channel.getRawValue(),
                          ind
                        )
                      "
                    >
                      {{ "IR_FORM.AddAnagraphic" | translate }}
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
            <div class="center-buttons pb-20 mt-20">
              <button
                type="button"
                class="rounded-button primary-border-button"
                [disabled]="isLabManagerInternal || isReadOnly"
                (click)="
                  removeCompetitor(i, foreignCompetitor.get('isGlobal').value)
                "
              >
                {{ "COMMON.Delete" | translate }}
              </button>
            </div>
          </div>
        </ng-container>
        <button
          *ngIf="!isReadOnly && !isLabManagerInternal"
          type="button"
          class="rounded-button primary-bg-button ml-auto"
          (click)="addCompetitor(CompetitorType.Foreign)"
        >
          {{ "IR_FORM.AddContent" | translate }}
        </button>
      </div>
    </div>
    <div *ngIf="isIsp" class="row mb-20" #topView>
      <mat-form-field
        class="col"
        [ngClass]="
          !customerDescriptionForm.get('indirectCompetitionProdDesc')?.value &&
          isIsp
            ? 'red-field-for-lab-manager'
            : ''
        "
      >
        <mat-label for="indirectCompetitionProdDesc">
          {{ "IR_FORM.CommentForPS" | translate }}
        </mat-label>
        <textarea
          rows="2"
          matInput
          type="text"
          name="indirectCompetitionProdDesc"
          [formControl]="
            customerDescriptionForm.get('indirectCompetitionProdDesc')
          "
        ></textarea>
        <mat-error
          *ngIf="
            customerDescriptionForm.get('indirectCompetitionProdDesc').invalid
          "
        >
          {{
            customerDescriptionForm.get("indirectCompetitionProdDesc").errors
              | getErrorMessage: translations.CommentForPS
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="!isLabManagerInternal && !isReadOnly" class="center-buttons">
      <button type="submit" class="rounded-button primary-bg-button">
        {{ "COMMON.SaveChanges" | translate }}
      </button>
    </div>
    <div
      *ngIf="
        ((functionalities.isp.create || functionalities.isp.edit) &&
          competitorExportOrientation?.graphResult?.length) ||
        (isReadOnly && isIsp)
      "
      class="section mb-50"
    >
      <div class="header">
        <div>
          <div class="title mb-30">
            <span>{{ "IR_FORM.GraphsAndTables" | translate }}</span>
          </div>
          <div class="subtitle">
            <span>
              {{ "IR_FORM.ExportOrientation" | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="content mt-30">
        <div class="row">
          <div class="col-6">
            <div class="section-card h100">
              <div class="content small-table">
                <app-table
                  [loader]="false"
                  [columns]="bubbleChartColumnsName"
                  [data]="bubbleChartDataTable"
                ></app-table>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="section-card h100">
              <div class="content">
                <div
                  *ngIf="data1"
                  class="chart"
                  echarts
                  [options]="exportOrientationChartOptions"
                ></div>
                <div class="chart-legend">
                  <div
                    *ngFor="let competitor of data2; let i = index"
                    class="legend-item"
                  >
                    <div
                      class="color-square"
                      [ngStyle]="{ 'background-color': chartColors[i] }"
                    ></div>
                    <div class="label">{{ competitor?.name }}</div>
                  </div>
                </div>
                <!-- <div
                  *ngIf="competitorExportOrientation?.graphResult?.length"
                  class="chart-container"
                >
                  <canvas
                    baseChart
                    [datasets]="bubbleChartData"
                    [options]="bubbleChartOptions"
                    [chartType]="bubbleChartType"
                  ></canvas>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="header mt-50">
        <div>
          <div class="subtitle">
            <span>
              {{ "IR_FORM.MktgMixPositioning" | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="content mt-30">
        <div class="row">
          <div class="col-6">
            <div class="section-card h100">
              <div class="content positioning small-table">
                <app-table
                  [loader]="false"
                  [columns]="radarChartColumnsName"
                  [data]="radarChartDataTable"
                ></app-table>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="section-card h100">
              <div class="content">
                <div
                  *ngIf="data2"
                  class="chart"
                  echarts
                  [options]="positioningAnalysisChartOptions"
                ></div>
                <!-- <div class="chart-legend">
                  <div
                    *ngFor="let competitor of data1; let i = index"
                    class="legend-item"
                  >
                    <div
                      class="color-square"
                      [ngStyle]="{ 'background-color': chartColors[i] }"
                    ></div>
                    <div class="label">{{ competitor?.name }}</div>
                  </div>
                </div> -->
                <!-- <div *ngIf="radarChartData" class="chart-container">
                  <canvas
                    baseChart
                    [datasets]="radarChartData"
                    [options]="radarChartOptions"
                    [labels]="radarChartLabels"
                    [chartType]="radarChartType"
                  ></canvas>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<div class="mt-40">
  <button
    *ngIf="allCompetitors?.length"
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
