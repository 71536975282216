import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { NgxCurrencyModule } from 'ngx-currency';
import { currencyDefaultConfig, currencyQuantityConfig } from 'src/app/config';
import { CrmService } from 'src/app/services/crm/crm.service';

@Component({
  selector: 'app-contact-competitors-dialog',
  templateUrl: './contact-competitors-dialog.component.html',
  styleUrls: ['./contact-competitors-dialog.component.scss'],
})
export class ContactCompetitorsDialogComponent implements OnInit, OnDestroy {
  translations: any;
  competitorForm: FormGroup;

  addContactCompetitorEmitter: EventEmitter<any> = new EventEmitter();

  contactCompetitor: any;
  private subscriptions: Subscription = new Subscription();
  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;
  currencyQuantityConfig: NgxCurrencyModule = currencyQuantityConfig;
  idContact: number | string;
  savingCompetitorLoader: boolean = false;
  isReadonly: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private crmService: CrmService
  ) {
    this.translations = data.translations;
    this.idContact = data.idContact;
    this.contactCompetitor = data.contactCompetitor;
    this.isReadonly = data.isReadonly;
    this.competitorForm = fb.group({
      idCompetitorCompanyCrm: [null],
      country: this.fb.group({
        idCountry: [null],
      }),
      companyName: [null, [Validators.required]],
      valueRevenueYear: [null, Validators.required],
      dateInsert: [null],
      userInsert: this.fb.group({
        idUser: [null],
      }),
      userModify: [null],
      dateModify: [null],
    });
  }

  ngOnInit(): void {
    if (this.contactCompetitor) {
      this.competitorForm.patchValue(this.contactCompetitor);
      this.competitorForm.get('companyName').disable();
    }
    if (this.isReadonly) {
      this.competitorForm.disable();
    }
  }

  onSubmit(): void {
    if (this.competitorForm.valid && this.competitorForm.dirty) {
      this.savingCompetitorLoader = true;
      this.subscriptions.add(
        this.crmService
          .addContactCompetitor(
            this.idContact,
            this.competitorForm.getRawValue(),
            false
          )
          .subscribe({
            complete: () => {
              this.savingCompetitorLoader = false;
              this.addContactCompetitorEmitter.emit();
            },
            error: () => {
              this.savingCompetitorLoader = false;
            },
          })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
