import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {faFile} from '@fortawesome/free-solid-svg-icons';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {IconTypes} from 'src/app/config';
import {IDeal, IDealOpportunityForTable, IOpportunityForTable} from 'src/app/models/contact';
import {DocumentDTO} from 'src/app/models/contract';
import {IColumnsName, ITableButtons, ITableOptions} from 'src/app/models/utility';
import {CrmService} from 'src/app/services/crm/crm.service';
import {
  fromBusinessMatchingToDataForTable,
  fromDealOpportunityToDataForTable,
  fromOpportunityToDataForTable
} from 'src/app/utility/elaborations';
import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';

@Component({
  selector: 'app-crm-business-matching',
  templateUrl: './crm-business-matching.component.html',
  styleUrls: ['./crm-business-matching.component.scss']
})
export class CrmBusinessMatchingComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();

  translations: any;

  idProject: number;

  isReadonly: boolean = false;

  documentType: any = IconTypes;

  // BM on marketed product
  bmOnMarketedProduct: any;
  showBmOnMarketedProductSection: boolean = false;
  showBmOnMarketedProductTable: boolean = false;
  bmProductDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  bmProductDisplayedColumns: string[] = [
    'businessNameMatched',
    'countryMatchedDesc',
    'hasContract',
    'purchaseRef',
    'customerSellRef',
    'productDesc',
    'productDescEn',
  ];
  bmProductColumnsName: IColumnsName[];
  bmProductOptions: ITableOptions = {
    showButtons: false,
    showFilter: true,
    showPaginator: true,
    showSort: true
  };
  bmProductLoader: boolean;

  selectedProducts:any[] = [];
  productCheckbox: any = {
    isSelected: (): boolean => { return false; },
    disabled: (): boolean => { return false; },
    click: (event: any, product: any) => {
      this.addSelectedToArray(event, this.selectedProducts, product, 'product'); },
  }

  // BM on deal opportunity
  bmOnDealOpportunity: any;
  showBmOnDealOpportunitySection: boolean = false;
  showBmOnDealOpportunityTable: boolean = false;
  bmDealOpportunityDataSource: MatTableDataSource<IDealOpportunityForTable> = new MatTableDataSource<IDealOpportunityForTable>();
  bmDealOpportunityDisplayedColumns: string[] = [
    'businessName',
    'country',
    'dealProductMatchedDesc',
    'dealProductMatchedDescEn',
    'buyer',
    'owner',
    'sales',
    'dealMatchedValue',
    'dealMatchedQuantity'
  ];
  bmDealOpportunityColumnsName: IColumnsName[];
  bmDealOpportunityMenuButtons: ITableButtons[] = [
    {
      name:'test',
      icon:'get_app',
      disabled: (deal: any): boolean => this.getDocumentDisabled(deal),
      menuElementEleborator: (deal: any): ITableButtons[] =>  this.getDocumentList(deal)
    }
  ];
  bmDealOpportunityOptions: ITableOptions = {
    showButtons: false,
    showFilter: true,
    showPaginator: true,
    showSort: true,
    menuButtonIcon: 'folder'
  };
  bmDealOpportunityLoader: boolean;

  selectedDealOpportunities: any[] = [];
  dealOpportunityCheckbox: any = {
    isSelected: (): boolean => false,
    disabled: (): boolean => false,
    click: (event: any, deal: any) => {
      this.addSelectedToArray(event, this.selectedDealOpportunities, deal, 'deal');
    },
    label: 'CRM.Delete'
  };

  // BM on opportunity
  bmOnOpportunity: any;
  showBmOnOpportunitySection: boolean = false;
  showBmOnOpportunityTable: boolean = false;
  bmOpportunityDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  bmOpportunityDisplayedColumns: string[] = [
    'businessName',
    'country',
    'opportunityProductMatchedDesc',
    'opportunityProductMatchedDescEn',
    'buyer',
    'owner',
    'sales',
    'valueYear',
    'quantityYear'
  ];
  bmOpportunityColumnsName: IColumnsName[];
  bmOpportunityOptions: ITableOptions = {
    showButtons: false,
    showFilter: true,
    showPaginator: true,
    showSort: true
  };
  bmOpportunityLoader: boolean;

  selectedOpportunities: any[] = [];
  opportunityCheckbox: any = {
    isSelected: (): boolean => false,
    disabled: (): boolean => false,
    click: (event: any, opportunity: any) => {
      this.addSelectedToArray(event, this.selectedOpportunities, opportunity, 'opportunity');
    },
    label: 'CRM.Delete'
  };

  routeState: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private crmService: CrmService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route?.data?.subscribe((data: any) => {
        if (data?.readonly) {
          this.isReadonly = true;
        } else {
          this.bmProductDisplayedColumns.push('select');
          this.bmDealOpportunityDisplayedColumns.push('select');
          this.bmOpportunityDisplayedColumns.push('select');
        }
        this.bmDealOpportunityDisplayedColumns.push('menuButtons');
      })
    );
    registerLocaleData(localeIt, 'it');
    this.bmDealOpportunityColumnsName = [
      { value: 'businessName', name: this.translate.instant('CRM.Company') },
      { value: 'country', name: this.translate.instant('CRM_DETAIL.Country') },
      { value: 'dealProductMatchedDesc', name: this.translate.instant('COMMON.DescriptionITA') },
      { value: 'dealProductMatchedDescEn', name: this.translate.instant('COMMON.DescriptionEN') },
      { value: 'buyer', name: this.translate.instant('CRM_COMMON.Buyer') },
      { value: 'owner', name: this.translate.instant('CRM_COMMON.Owner') },
      { value: 'sales', name: this.translate.instant('CRM_COMMON.Sales') },
      { value: 'dealMatchedValue', name: this.translate.instant('CRM_DETAIL.Revenue') },
      { value: 'dealMatchedQuantity', name: this.translate.instant('CRM_DETAIL.Quantity') },
    ];
    this.bmOpportunityColumnsName = [
      { value: 'businessName', name: this.translate.instant('CRM.Company') },
      { value: 'country', name: this.translate.instant('CRM_DETAIL.Country') },
      { value: 'opportunityProductMatchedDesc', name: this.translate.instant('COMMON.DescriptionITA') },
      { value: 'opportunityProductMatchedDescEn', name: this.translate.instant('COMMON.DescriptionEN') },
      { value: 'buyer', name: this.translate.instant('CRM_COMMON.Buyer') },
      { value: 'owner', name: this.translate.instant('CRM_COMMON.Owner') },
      { value: 'sales', name: this.translate.instant('CRM_COMMON.Sales') },
      { value: 'valueYear', name: this.translate.instant('CRM_DETAIL.Revenue') },
      { value: 'quantityYear', name: this.translate.instant('CRM_DETAIL.Quantity') },
    ];
    this.bmProductColumnsName = [
      { value: 'businessNameMatched', name: this.translate.instant('CRM.Company') },
      { value: 'countryMatchedDesc', name: this.translate.instant('CRM_DETAIL.Country') },
      { value: 'hasContract', name: this.translate.instant('IR_FORM.Contract') },
      { value: 'purchaseRef', name: this.translate.instant('CRM_BM.PurchaseRef') },
      { value: 'customerSellRef', name: this.translate.instant('CRM_BM.CustomerSellRef') },
      { value: 'productDesc', name: this.translate.instant('COMMON.DescriptionITA') },
      { value: 'productDescEn', name: this.translate.instant('COMMON.DescriptionEN') }
    ];
    this.subscriptions.add(
      this.route.params.subscribe((params) => {
        this.idProject = parseInt(params.idProject, 10);
        if (this.idProject && !this.bmOnMarketedProduct) {
          this.subscriptions.add(
            this.crmService.getBmOnProductByIdProject(this.idProject).subscribe((bmOnMarketedProduct: any) => {
              this.bmOnMarketedProduct = bmOnMarketedProduct;
              this.showBmOnMarketedProductSection = true;
            })
          );
        }
        if (this.idProject && !this.bmOnDealOpportunity) {
          this.subscriptions.add(
            this.crmService.getBmOnDealOpportunityByIdProject(this.idProject).subscribe((bmOnDealOpportunity: IDeal) => {
              this.bmOnDealOpportunity = bmOnDealOpportunity;
              this.showBmOnDealOpportunitySection = true;
            })
          );
        }
        if (this.idProject && !this.bmOnOpportunity) {
          this.subscriptions.add(
            this.crmService.getBmOnOpportunityByIdProject(this.idProject).subscribe((bmOnOpportunity: any) => {
              this.bmOnOpportunity = bmOnOpportunity;
              this.showBmOnOpportunitySection = true;
            })
          );
        }
      })
    );
  }

  loadBmOnMarketedProductTable(): void {
    this.showBmOnMarketedProductTable = true;
    this.bmProductLoader = true;
    this.subscriptions.add(
      this.crmService.getBmDetailsOnProductsByIdProject(this.idProject, true).subscribe((bmDetailsOnMarketedProduct: any) => {
        this.bmProductDataSource.data = bmDetailsOnMarketedProduct.map(fromBusinessMatchingToDataForTable);
        this.bmProductLoader = false;
      })
    );
  }

  loadBmOnDealOpportunityTable(): void {
    this.showBmOnDealOpportunityTable = true;
    this.bmDealOpportunityLoader = true;
    this.subscriptions.add(
      this.crmService.getBmDetailsOnDealOpportunityByIdProject(this.idProject, true).subscribe((bmDetailsOnDealOpportunity: IDeal[]) => {
        const mappedDealOpportunities: any[] = bmDetailsOnDealOpportunity.map(fromDealOpportunityToDataForTable);
        this.bmDealOpportunityDataSource.data = mappedDealOpportunities;
        this.bmDealOpportunityLoader = false;
      })
    );
  }

  loadBmOnOpportunityTable(): void {
    this.showBmOnOpportunityTable = true;
    this.bmOpportunityLoader = true;
    this.subscriptions.add(
      this.crmService.getBmDetailsOnOpportunityByIdProject(this.idProject, true).subscribe((bmDetailsOnOpportunity: any) => {
        const mappedOpportunities: IOpportunityForTable[] = bmDetailsOnOpportunity.map(fromOpportunityToDataForTable);
        this.bmOpportunityDataSource.data = mappedOpportunities;
        this.bmOpportunityLoader = false;
      })
    );
  }

  getDocumentDisabled(deal: any): boolean {
    return !deal?.dealDocuments?.length;
  }

  getDocumentList(deal: any): ITableButtons[] {
    console.log(deal)
    return deal?.dealDocuments?.map((doc: DocumentDTO): ITableButtons => ({
      click: () => { this.downloadDealDocument(doc.idDealDocument); },
      name: doc.name,
      fontAwesomeIcon: this.documentType[doc.mimeType] || faFile
    }));
  }

  downloadDealDocument(idDealDocument: string | number): void {
    this.subscriptions.add(
      this.crmService.downloadDealDocument(idDealDocument, true).subscribe()
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addSelectedToArray(event: any, selectedItems:any[], item:any, type:string): void {
    if (event.checked) {
      selectedItems.push(item);
    } else {
      selectedItems.forEach((element, i) => {
        if (type === 'product') {
          if (item.businessName === element.businessName && item.name === element.name) {
            selectedItems.splice(i, 1);
          }
        }
        if (type === 'deal') {
          if (item.businessName === element.businessName && item.name === element.name) {
            selectedItems.splice(i, 1);
          }
        }
        if (type === 'opportunity') {
          if (item.businessName === element.businessName && item.name === element.name) {
            selectedItems.splice(i, 1);
          }
        }
      });
    }
  }

  excludeSelected(type:string){
    let values;
    if (type === 'product') {
      values = Object.keys(this.selectedProducts).map(k => this.selectedProducts[k]['idBusinessMatching']);
      this.subscriptions.add(
        this.crmService.excludeSelectedBusinessMatching(this.idProject, values).subscribe((bmDetailsOnMarketedProduct: any) => {
          this.bmProductDataSource.data = bmDetailsOnMarketedProduct.map(fromBusinessMatchingToDataForTable);
          this.bmProductLoader = false;
          this.crmService.getBmOnProductByIdProject(this.idProject).subscribe((bmOnMarketedProduct: any) => {
            this.bmOnMarketedProduct = bmOnMarketedProduct;
            this.showBmOnMarketedProductSection = true;
            this.showBmOnMarketedProductTable = bmDetailsOnMarketedProduct.length>0;
          })
        })
      );
    }
    if (type === 'deal') {
      values = Object.keys(this.selectedDealOpportunities).map(
        k => this.selectedDealOpportunities[k]['idDealOpportunityBusinessMatching']);
      this.subscriptions.add(
        this.crmService.excludeSelectedDealOpportunityBusinessMatching(this.idProject, values).subscribe((bmDetailsOnDealOpportunity: IDeal[]) => {
          const mappedDealOpportunities: any[] = bmDetailsOnDealOpportunity.map(fromDealOpportunityToDataForTable);
          this.bmDealOpportunityDataSource.data = mappedDealOpportunities;
          this.bmDealOpportunityLoader = false;
          this.crmService.getBmOnDealOpportunityByIdProject(this.idProject).subscribe((bmDealOpportunity: IDeal) => {
            this.bmOnDealOpportunity = bmDealOpportunity;
            this.showBmOnDealOpportunitySection = true;
            this.showBmOnDealOpportunityTable = bmDetailsOnDealOpportunity.length>0;
          })
        })
      );
    }
    if (type === 'opportunity') {
      values = Object.keys(this.selectedOpportunities).map(
        k => this.selectedOpportunities[k]['idOpportunityBusinessMatching']);
      this.subscriptions.add(
        this.crmService.excludeSelectedOpportunityBusinessMatching(this.idProject, values).subscribe((bmDetailsOnOpportunity: any) => {
          const mappedOpportunities: IOpportunityForTable[] = bmDetailsOnOpportunity.map(fromOpportunityToDataForTable);
          this.bmOpportunityDataSource.data = mappedOpportunities;
          this.bmOpportunityLoader = false;
          this.crmService.getBmOnOpportunityByIdProject(this.idProject).subscribe((bmOnOpportunity: any) => {
            this.bmOnOpportunity = bmOnOpportunity;
            this.showBmOnOpportunitySection = true;
            this.showBmOnOpportunityTable = bmDetailsOnOpportunity.length>0;
          })
        })
      );
    }
  }

}
