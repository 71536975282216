import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { Roles } from 'src/app/config';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss'],
})
export class MeetingsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  today: any = moment();
  isAgentSupervisor: boolean = false;
  isAgent: boolean = false;
  idUser: number;
  locale: string = navigator.language;
  translations: any;

  constructor(
    private layoutService: LayoutService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    // Check idUser
    this.idUser = JSON.parse(localStorage.getItem('session_user')).idUser;
    // Check role
    const userRole: string = JSON.parse(
      localStorage.getItem('session_user')
    ).activeRole;
    // Check role conditions
    if (userRole === Roles.Agent) {
      this.isAgent = true;
    }
    if (userRole === Roles.AgentSupervisor) {
      this.isAgentSupervisor = true;
    }
    this.subscriptions.add(
      this.translate.get('PAGES').subscribe({
        next: (data: any) => {
          this.translations = data;
          this.generateBreadCrumb();
        },
      })
    );
  }

  generateBreadCrumb(): void {
    this.subscriptions.add(
      this.layoutService.generateBreadcrumb([
        {
          label: this.translations.meetings,
          path: null,
          pageTitle: true,
        },
      ])
    );
  }

  printPage(): void {
    window.print();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
