import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import { MustBeRequired } from 'src/app/utility/validators';
import {
  IProductsServices,
  IRelevantRegistry,
} from 'src/app/models/products-services';
import { IrFormComponent } from 'src/app/components/ir-form-abstract/ir-form-abstract-components';
import { Project } from 'src/app/models/project';
import {
  currencyDefaultConfig,
  currencyQuantityConfig,
  PRODUCT_TYPOLOGY,
  PRODUCT_TYPE_NEW,
  currencyQuantityConfigWithoutDecimal,
  PRODUCT_TYPE,
  RI_STATUS,
} from 'src/app/config';
import { NgxCurrencyModule } from 'ngx-currency';
import {
  ITableButton,
  ITableColumn,
  ToastStatus,
  UnitsOfMeasure,
} from 'src/app/models/utility';
import { ICountries } from 'src/app/models/countries';
import { takeUntil } from 'rxjs/operators';
import { IProductMatching } from 'src/app/models/product-matching';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { MatInput } from '@angular/material/input';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SearchCustomsCodeComponent } from '../search-customs-code/search-customs-code.component';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { CommonService } from 'src/app/services/common/common.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ChannelsManagementDialogComponent } from '../channels-management-dialog/channels-management-dialog.component';
import { ISectors } from 'src/app/models/sectors';

@Component({
  selector: 'app-products-services-form',
  templateUrl: './products-services-form.component.html',
  styleUrls: ['./products-services-form.component.scss'],
})
export class ProductsServicesFormComponent
  extends IrFormComponent
  implements OnInit, OnDestroy
{
  private subscriptions: Subscription = new Subscription();

  /* General */
  idCustomer: number;
  countries: ICountries[];
  commerceSectors: ISectors[];
  hasExport: boolean;
  lockButtons: boolean = false;
  unitsOfMeasure: UnitsOfMeasure[] = [];
  isAuthorized: boolean;
  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;
  currencyQuantityConfigWithoutDecimal: NgxCurrencyModule =
    currencyQuantityConfigWithoutDecimal;

  /* Contents */
  contentsData: IProductsServices[];
  oldContents: IProductsServices[];
  filterSelects: any = {
    typology: null,
    type: null,
    importanceLevel: null,
  };
  contentForm: FormGroup;
  wordsKeyError: boolean = false;
  contentDialogType: string;
  idMarketAnalysisProduct: number;

  /* Contents table */
  contentsTableColumns: ITableColumn[];
  contentsTableColumnsButtons: ITableButton[];
  contentsLoader: boolean = false;

  isCommoditySectorSet: boolean = false;
  startDataOnInit: any;

  contentsList: boolean = true;
  contentDetail: boolean = false;

  productType: any = PRODUCT_TYPE;

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  @Input() index: any;
  @Input() tabIndex: any;
  @Input() noContract: boolean;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;
  @Input() isAgent: boolean;
  // @Input() isIsp: boolean;

  isIsp: boolean = false;

  @Output() reloadContentsEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('typologyFilter') typologyFilter: MatSelect;
  @ViewChild('typeFilter') typeFilter: MatSelect;
  @ViewChild('importanceLevelFilter') importanceLevelFilter: MatSelect;
  @ViewChild('contentDialog') contentDialog: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private irFormService: IrFormService,
    private layoutService: LayoutService,
    private common: CommonService,
    private translate: TranslateService
  ) {
    super(fb);

    this.contentForm = fb.group({
      idProductType: [null, [Validators.required]],
      correlationBrand: [null],
      idProduct: [null],
      commerceSector: this.fb.group({
        idCommerceSector: [null, Validators.required],
      }),
      description: [null, [Validators.required, Validators.maxLength(250)]],
      englishDescription: [
        null,
        [Validators.required, Validators.maxLength(250)],
      ],
      name: [null, [Validators.required, Validators.maxLength(200)]],
      productCode: [null, Validators.required],
      revenuePercentage: [
        { value: 0, disabled: true },
        [Validators.required, Validators.min(0), Validators.max(100)],
      ],
      quantity: [
        { value: null, disabled: true },
        [Validators.required, Validators.maxLength(45), Validators.min(0)],
      ],
      quantityYear: [
        { value: null, disabled: true },
        [Validators.required, Validators.maxLength(45), Validators.min(0)],
      ],
      unitOfMeasure: this.fb.group({
        idUom: [{ value: null, disabled: true }, [Validators.required]],
      }),
      valueOfUnit: [
        { value: null, disabled: true },
        [Validators.required, Validators.maxLength(45)],
      ],
      valueYear: [{ value: null, disabled: true }],
      importanceLevel: [null, [Validators.required]],
      type: [null, [Validators.required]],
      importedExported: [
        { value: null, disabled: true },
        [Validators.required],
      ],
      reason: [
        { value: null, disabled: true },
        [Validators.required, Validators.maxLength(500)],
      ],
      wordKey: this.fb.group({
        productMatching: this.fb.array([]),
        isWordKey: [null],
      }),
      relevantRegistry: this.fb.array([]),
      relatedComplementary: this.fb.array([]),
      indirectCompetition: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          this.idMarketAnalysisProduct =
            +initialValues?.marketAnalysis?.marketAnalysis?.idProduct;
          if (initialValues.idProjectStatus >= RI_STATUS.IspWorking) {
            this.isIsp = true;
          }
          if (
            initialValues?.customerInformation?.commerceSector?.idCommerceSector
          ) {
            this.isCommoditySectorSet = true;
          }
          // Mapping data for table and filters
          this.contentsData = initialValues?.productsServices?.map(
            (content: IProductsServices): any => ({
              ...content,
              typologyForTable:
                this.translations[PRODUCT_TYPOLOGY[content?.type]],
              typeForTable:
                this.translations[PRODUCT_TYPE_NEW[content?.idProductType]],
              nameForTable: content?.name,
              importanceLevelForTable: content?.importanceLevel,
              customsCodeForTable: content?.productCode,
              turnoverQuoteForTable: content?.revenuePercentage,
              udmForTable: content?.unitOfMeasure?.name,
              idProductForTable: content?.idProduct,
            })
          );
          this.oldContents = initialValues?.productsServices?.map(
            (content: IProductsServices): any => ({
              ...content,
              typologyForTable:
                this.translations[PRODUCT_TYPOLOGY[content?.type]],
              typeForTable:
                this.translations[PRODUCT_TYPE_NEW[content?.idProductType]],
              nameForTable: content?.name,
              importanceLevelForTable: content?.importanceLevel,
              customsCodeForTable: content?.productCode,
              turnoverQuoteForTable: content?.revenuePercentage,
              udmForTable: content?.unitOfMeasure?.name,
              idProductForTable: content?.idProduct,
            })
          );
          this.contentsTableColumns = [
            {
              name: 'nameForTable',
              translatedName: this.translations.Name,
            },
            // {
            //   name: 'typologyForTable',
            //   translatedName: this.translations.Typology,
            // },
            {
              name: 'typeForTable',
              translatedName: this.translations.Type,
            },
            {
              name: 'importanceLevelForTable',
              translatedName: this.translations.ImportanceLevelLong,
              align: 'center',
            },
            {
              name: 'customsCodeForTable',
              translatedName: this.translations.CustomsCode,
            },
            {
              name: 'turnoverQuoteForTable',
              translatedName: this.translations.TurnoverQuote,
              symbol: ' %',
              align: 'center',
            },
            // {
            //   name: 'udmForTable',
            //   translatedName: this.translations.UDM,
            // },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.contentsTableColumnsButtons = [
            {
              icon: (content) => {
                if (
                  this.isReadOnly ||
                  this.isLabManagerInternal ||
                  content.idProduct === this.idMarketAnalysisProduct
                ) {
                  return 'arrow_green_3_icon';
                } else {
                  return 'edit_icon';
                }
              },
              class: 'primary',
              disabled: () => false,
              clickFunction: (content: IProductsServices) => {
                this.showContentDetail(content);
              },
            },
          ];
          if (!this.isLabManagerInternal && !this.isReadOnly) {
            this.contentsTableColumnsButtons.push({
              icon: 'trash_icon',
              class: 'primary',
              disabled: (content) =>
                content.idProduct === this.idMarketAnalysisProduct,
              tooltip: (content) => {
                if (content.idProduct === this.idMarketAnalysisProduct) {
                  return this.translations.CantDeleteProductService;
                }
              },
              clickFunction: (content: IProductsServices) => {
                this.deleteContentFromTable(content.idProduct);
              },
            });
          }
          this.hasExport = initialValues?.customerInformation?.hasExport;
          if (!this.unitsOfMeasure?.length) {
            this.unitsOfMeasure = initialValues.unitsOfMeasure;
          }
          this.updateIdCustomerInfoDescription(
            initialValues?.customerDescription
          );
          this.countries = initialValues.countries;
          this.idCustomer = initialValues.customer.idCustomer;
          if (this.isLabManagerInternal || this.isReadOnly) {
            this.contentForm.disable();
            this.contentForm.markAsPristine();
            this.contentForm.markAsUntouched();
            this.customerDescriptionForm.disable();
            this.customerDescriptionForm.markAsPristine();
            this.customerDescriptionForm.markAsUntouched();
          }
          this.startDataOnInit =
            this.customerDescriptionForm.get('currentProdDesc').value;
          this.commerceSectors = initialValues?.commerceSector;
          if (this.isAgent && !this.noContract) {
            this.contentForm.get('productCode').clearValidators();
            this.contentForm.get('englishDescription').clearValidators();
            this.contentForm.updateValueAndValidity();
          }
        },
      })
    );
  }

  get productMatching(): FormArray {
    return this.contentForm.get('wordKey.productMatching') as FormArray;
  }

  get relevantRegistryList(): FormArray {
    return this.contentForm.get('relevantRegistry') as FormArray;
  }

  get relatedComplementaryProducts(): FormArray {
    return this.contentForm.get('relatedComplementary') as FormArray;
  }

  get indirectCompetitionProducts(): FormArray {
    return this.contentForm.get('indirectCompetition') as FormArray;
  }

  updateValueYear(): void {
    this.contentForm
      .get('valueYear')
      .setValue(
        (this.contentForm.get('valueOfUnit').value || 0) *
          (this.contentForm.get('quantityYear').value || 0)
      );
  }

  saveContent(): void {
    let hasIdProduct: boolean = false;
    if (this.contentForm.get('idProduct').value) {
      hasIdProduct = true;
    }
    if (
      (this.contentForm.get('wordKey.productMatching') as FormArray)?.length
    ) {
      this.contentForm.get('wordKey.isWordKey').setValue(true);
    }
    if (
      !(this.contentForm.get('wordKey.productMatching') as FormArray)?.length &&
      this.contentForm.get('idProductType').value < 3
    ) {
      this.wordsKeyError = true;
    }
    // Check if wordkeys are duplicate
    const wordsKeys: any[] = (
      this.contentForm.get('wordKey.productMatching') as FormArray
    )
      .getRawValue()
      .map((item: any): any => {
        return item.wordKey?.toLowerCase();
      });
    if (
      wordsKeys.some((key: any, index: number) => {
        return wordsKeys.indexOf(key) !== index;
      })
    ) {
      (
        this.contentForm.get('wordKey.productMatching') as FormArray
      ).controls.forEach((control: FormGroup) => {
        control.get('wordKey').setErrors({ duplicateWord: true });
      });
    } else {
      (
        this.contentForm.get('wordKey.productMatching') as FormArray
      ).controls.forEach((control: FormGroup) => {
        control.get('wordKey').updateValueAndValidity();
        // control.get('wordKey').setErrors({ duplicateWord: null });
      });
    }
    // Check if english wordkeys are duplicate
    const englishWordsKeys: any[] = (
      this.contentForm.get('wordKey.productMatching') as FormArray
    )
      .getRawValue()
      .map((item: any): any => {
        return item.englishWordKey?.toLowerCase();
      });
    if (
      englishWordsKeys.some((key: any, index: number) => {
        return englishWordsKeys.indexOf(key) !== index;
      })
    ) {
      (
        this.contentForm.get('wordKey.productMatching') as FormArray
      ).controls.forEach((control: FormGroup) => {
        control.get('englishWordKey').setErrors({ duplicateWord: true });
      });
    } else {
      (
        this.contentForm.get('wordKey.productMatching') as FormArray
      ).controls.forEach((control: FormGroup) => {
        control.get('englishWordKey').updateValueAndValidity();
        // control.get('englishWordKey').setErrors({ duplicateWord: null });
      });
    }
    if (
      this.contentForm.valid &&
      this.contentForm.dirty &&
      (this.contentForm.get('idProductType').value > 2 ||
        ((this.contentForm.get('wordKey.productMatching') as FormArray)
          ?.length &&
          this.contentForm.get('idProductType').value < 3))
    ) {
      this.lockButtons = true;
      this.contentForm.disable();
      this.subscriptions.add(
        this.irFormService
          .updateProductsServices(
            [this.contentForm.getRawValue()],
            this.idCustomer
          )
          .subscribe({
            complete: () => {
              this.lockButtons = false;
              this.resetContentDetail();
              this.contentForm.enable();
              this.contentForm.get('productCode').clearValidators();
              this.contentForm.get('productCode').updateValueAndValidity();
              this.contentDetail = false;
              this.contentsList = true;
              this.reloadContentsEmitter.emit();
            },
            error: (error: HttpErrorResponse) => {
              this.lockButtons = false;
              this.contentForm.enable();
              if (this.contentForm.get('idProductType').value > 2) {
                this.contentForm.get('revenuePercentage').disable();
                this.contentForm.get('quantity').disable();
                this.contentForm.get('quantityYear').disable();
                this.contentForm.get('unitOfMeasure.idUom').disable();
                this.contentForm.get('valueOfUnit').disable();
                this.contentForm.get('valueYear').disable();
                this.contentForm.get('importedExported').disable();
                this.contentForm.get('reason').disable();
                this.contentForm.get('wordKey').disable();
              }
              if (error.error.errors.message === 'CC001') {
                this.common.showToast(
                  this.translate.instant(
                    'API_RESPONSE.' + error.error.errors.message
                  ),
                  ToastStatus.error,
                  3000
                );
              } else if (error.error.errors.message === 'PM001') {
                this.common.showToast(
                  this.translate.instant(
                    'API_RESPONSE.' + error.error.errors.message
                  ),
                  ToastStatus.error,
                  3000
                );
              } else {
                this.common.showToast(
                  this.translations.ShowGeneralErrorToast,
                  ToastStatus.error,
                  3000
                );
              }
            },
          })
      );
    }
    if (
      hasIdProduct &&
      this.contentForm.valid &&
      (this.contentForm.get('idProductType').value > 2 ||
        ((this.contentForm.get('wordKey.productMatching') as FormArray)
          ?.length &&
          this.contentForm.get('idProductType').value < 3))
    ) {
      this.resetContentDetail();
      this.contentDetail = false;
      this.contentsList = true;
    }
  }

  submitComment(): void {
    this.customerDescrSubmit('currentProdDesc');
  }

  // Function deprecated beacuse changed logic for saving/creating Contents

  // checkDirtyForm(): void {
  //   let result;
  //   if (!this.isIsp) {
  //     const isEmpty =
  //       [
  //         ...this.soldProductsServices.getRawValue(),
  //         ...this.boughtProductsServices.getRawValue(),
  //         ...this.relatedProductsServices.getRawValue(),
  //         ...this.competitionProductsServices.getRawValue(),
  //       ].length === 0;

  //     result =
  //       this.productsServicesForm?.dirty ||
  //       this.productsServicesForm?.touched ||
  //       !this.productsServicesForm?.valid ||
  //       this.productsWithoutBM.sold?.length ||
  //       this.productsWithoutBM.bought?.length ||
  //       isEmpty;
  //   } else {
  //     const isEmpty =
  //       [
  //         ...this.soldProductsServices.getRawValue(),
  //         ...this.boughtProductsServices.getRawValue(),
  //         ...this.relatedProductsServices.getRawValue(),
  //         ...this.competitionProductsServices.getRawValue(),
  //         this.customerDescriptionForm?.getRawValue(),
  //       ].length === 0;

  //     result =
  //       this.productsServicesForm?.dirty ||
  //       this.productsServicesForm?.touched ||
  //       !this.productsServicesForm?.valid ||
  //       this.productsWithoutBM.sold?.length ||
  //       this.productsWithoutBM.bought?.length ||
  //       this.customerDescriptionForm?.dirty ||
  //       this.customerDescriptionForm?.touched ||
  //       this.customerDescriptionForm?.invalid ||
  //       !this.customerDescriptionForm?.get('currentProdDesc').value ||
  //       isEmpty;
  //   }

  //   if (!this.isLabManagerInternal && !this.isReadOnly) {
  //     this.layoutService.triggerDirtyForm({ productServices: result });
  //   }
  // }

  checkDirtyForm(): boolean {
    if (this.isIsp) {
      const result: boolean =
        JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
        JSON.stringify(
          this.customerDescriptionForm.get('currentProdDesc').value,
          this.irFormService.replacer
        );
      return result || this.contentForm.dirty;
    }
  }

  checkInvalidForm(): boolean {
    const atLeastOneSold: boolean = this.oldContents?.some(
      (content: IProductsServices) => {
        return content.idProductType === PRODUCT_TYPE.sold;
      }
    );
    const atLeastOneBought: boolean = this.oldContents?.some(
      (content: IProductsServices) => {
        return content.idProductType === PRODUCT_TYPE.bought;
      }
    );
    if (!atLeastOneSold || !atLeastOneBought) {
      return true;
    } else {
      return false;
    }
  }

  // DEPRECATED (task PPSE-135)
  // setDescriptionByName(productName: MatInput): void {
  //   this.contentForm.get('description').setValue(productName.value);
  //   this.contentForm.get('description').updateValueAndValidity();
  // }

  searchCode(value: string, group?: FormGroup): void {
    if (value.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.data = {
        description: value,
        codeIsClickable: true,
      };
      const dialogRef = this.dialog.open(
        SearchCustomsCodeComponent,
        dialogConfig
      );
      dialogRef.componentInstance.codeEmitter.subscribe((code: any) => {
        if (group) {
          group.get('productCode').setValue(code);
          group.markAsDirty();
        } else {
          this.contentForm.get('productCode').setValue(code);
          this.contentForm.markAsDirty();
        }
      });
    }
  }

  closeContentDetail(): void {
    this.resetContentDetail();
    this.contentDetail = false;
    this.contentsList = true;
  }

  showContentDetail(content?: IProductsServices): void {
    this.contentForm.enable();
    if (this.isCommoditySectorSet) {
      // this.contentForm.patchValue({
      //   idProductType: content?.idProductType,
      //   correlationBrand: content?.correlationBrand,
      //   idProduct: content?.idProduct,
      //   description: content?.description,
      //   englishDescription: content?.englishDescription,
      //   name: content?.name,
      //   productCode: content?.productCode,
      //   revenuePercentage: content?.revenuePercentage,
      //   quantity: content?.quantity,
      //   quantityYear: content?.quantityYear,
      //   unitOfMeasure: {
      //     idUom: content?.unitOfMeasure?.idUom,
      //   },
      //   commerceSector: {
      //     idCommerceSector: content?.commerceSector?.idCommerceSector,
      //   },
      //   valueOfUnit: content?.valueOfUnit,
      //   valueYear: content?.valueYear,
      //   importanceLevel: content?.importanceLevel,
      //   type: content?.type,
      //   importedExported: content?.importedExported,
      //   reason: content?.reason,
      //   wordKey: {
      //     productMatching: [],
      //     isWordKey: content?.wordKey?.isWordKey,
      //   },
      // });
      if (content) {
        if (!content.wordKey) {
          content.wordKey = {
            productMatching: [],
            isWordKey: content?.wordKey?.isWordKey,
          };
        }
        this.contentForm.patchValue(content);
      }
      if (content?.idProductType) {
        this.contentForm.get('idProductType').disable();
      } else {
        this.contentForm.get('idProductType').enable();
      }
      if (
        this.contentForm.get('idProductType')?.value === PRODUCT_TYPE.bought
      ) {
        this.contentForm.get('productCode').setValidators(null);
        this.contentForm.get('productCode').updateValueAndValidity();
        this.contentForm.get('reason').setValidators(null);
        this.contentForm.get('reason').updateValueAndValidity();
      }
      if (
        this.isReadOnly ||
        this.isLabManagerInternal ||
        content?.idProduct === this.idMarketAnalysisProduct
      ) {
        this.contentForm.disable();
      } else {
        this.contentForm.get('revenuePercentage').enable();
        this.contentForm.get('unitOfMeasure.idUom').enable();
        this.contentForm.get('quantity').enable();
        this.contentForm.get('quantityYear').enable();
        this.contentForm.get('valueOfUnit').enable();
        this.contentForm.get('valueYear').enable();
        this.contentForm.get('importedExported').enable();
        this.contentForm.get('reason').enable();
      }
      if (content) {
        this.irFormService
          .getProductMatching(content?.idProduct, true)
          .subscribe({
            next: (data: IProductMatching) => {
              data?.productMatching?.forEach((prodMatch: any) => {
                this.productMatching.push(
                  this.fb.group({
                    idProductMatching: [prodMatch?.idProductMatching || null],
                    wordKey: [
                      prodMatch?.wordKey || null,
                      [Validators.required, Validators.maxLength(250)],
                    ],
                    englishWordKey: [
                      prodMatch?.englishWordKey || null,
                      !this.isAgent
                        ? [Validators.required, Validators.maxLength(250)]
                        : [Validators.maxLength(250)],
                    ],
                  })
                );
              });
            },
          });
        content?.relatedComplementary?.forEach(
          (subProduct: IProductsServices) => {
            this.addSubProduct(subProduct, PRODUCT_TYPE.related);
          }
        );
        content?.indirectCompetition?.forEach(
          (subProduct: IProductsServices) => {
            this.addSubProduct(subProduct, PRODUCT_TYPE.competition);
          }
        );
      }
      this.contentDetail = true;
      this.contentsList = false;
    } else {
      this.common.showToast(
        this.translate.instant('API_RESPONSE.CC001'),
        ToastStatus.error,
        3000
      );
    }
  }

  deleteContentFromDetail(): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteProductWithSubProductsMessage,
      secondMessage: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef: any = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.lockButtons = true;
        if (this.contentForm.get('idProduct').value) {
          this.irFormService
            .deleteProduct(this.contentForm.get('idProduct').value)
            .subscribe({
              next: () => {
                this.reloadContentsEmitter.emit();
                this.resetContentDetail();
                this.wordsKeyError = false;
                this.lockButtons = false;
                this.contentDetail = false;
                this.contentsList = true;
              },
              error: (error: HttpErrorResponse) => {
                if (error.error.errors.message === 'PR007') {
                  this.common.showToast(
                    this.translate.instant(
                      'API_RESPONSE.' + error.error.errors.message
                    ),
                    ToastStatus.error,
                    3000
                  );
                } else {
                  this.common.showToast(
                    this.translations.ShowGeneralErrorToast,
                    ToastStatus.error,
                    3000
                  );
                }
                this.lockButtons = false;
              },
            });
        } else {
          this.lockButtons = false;
          this.contentForm.reset();
          this.productMatching.clear();
          this.wordsKeyError = false;
          this.contentDetail = false;
          this.contentsList = true;
        }
      }
    });
  }

  deleteContentFromTable(idItem: number): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteProductWithSubProductsMessage,
      secondMessage: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef: any = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.subscriptions.add(
          this.irFormService.deleteProduct(idItem).subscribe({
            complete: () => {
              this.reloadContentsEmitter.emit();
            },
            error: (error: HttpErrorResponse) => {
              if (error.error.errors.message === 'PR007') {
                this.common.showToast(
                  this.translate.instant(
                    'API_RESPONSE.' + error.error.errors.message
                  ),
                  ToastStatus.error,
                  3000
                );
              } else {
                this.common.showToast(
                  this.translations.ShowGeneralErrorToast,
                  ToastStatus.error,
                  3000
                );
              }
            },
          })
        );
      }
    });
  }

  closeContentDialog(): void {
    setTimeout(() => {
      this.contentForm.reset();
      this.productMatching.clear();
      this.relevantRegistryList.clear();
      this.wordsKeyError = false;
      this.contentForm.enable();
      this.contentForm.get('productCode').clearValidators();
      this.contentForm.get('productCode').updateValueAndValidity();
    }, 300);
  }

  filterTable(filter: string, select: MatSelectChange): void {
    this.filterSelects[filter] = select.value;
    if (filter === 'importanceLevel') {
      this.filterSelects[filter] = parseInt(select.value, 10);
    }
    this.contentsData = [];
    this.contentsData = this.oldContents.filter((content: any) => {
      if (this.filterSelects.typology) {
        return content.typologyForTable === this.filterSelects.typology;
      }
      return content;
    });
    this.contentsData = this.contentsData.filter((content: any) => {
      if (this.filterSelects.type) {
        return content.typeForTable === this.filterSelects.type;
      }
      return content;
    });
    this.contentsData = this.contentsData.filter((content: any) => {
      if (this.filterSelects.importanceLevel) {
        return (
          content.importanceLevelForTable === this.filterSelects.importanceLevel
        );
      }
      return content;
    });
  }

  addProductMatching(): void {
    this.productMatching.push(
      this.fb.group({
        idProductMatching: [null],
        wordKey: [null, [Validators.required, Validators.maxLength(250)]],
        englishWordKey: [
          null,
          !this.isAgent || this.noContract
            ? [Validators.required, Validators.maxLength(250)]
            : [Validators.maxLength(250)],
        ],
      })
    );
  }

  removeProductMatching(index: number): void {
    const idItem: number = this.productMatching
      .at(index)
      .get('idProductMatching').value;
    if (idItem) {
      this.subscriptions.add(
        this.irFormService
          .deleteProductMatchingEmitter(idItem, true)
          .subscribe({
            complete: () => {
              this.productMatching.removeAt(index);
            },
            error: () => {
              console.log(false);
            },
          })
      );
    } else {
      this.productMatching.removeAt(index);
    }
  }

  removeRelevantRegistry(index: number, subProduct: FormGroup): void {
    const idItem: number = (subProduct.get('relevantRegistry') as FormArray)
      .at(index)
      ?.get('idRelevantRegistry')?.value;
    if (idItem) {
      this.subscriptions.add(
        this.irFormService.deleteProductCompany(idItem).subscribe({
          complete: () => {
            (subProduct.get('relevantRegistry') as FormArray).removeAt(index);
            this.reloadContentsEmitter.emit();
          },
          error: () => {
            console.log('errore');
          },
        })
      );
    } else {
      (subProduct.get('relevantRegistry') as FormArray).removeAt(index);
    }
  }

  totalRevenuePercentageContents(): void {
    let totalRevenuePercentage: number = 0;
    this.oldContents.forEach((content: IProductsServices) => {
      if (
        content.idProductType === this.contentForm.get('idProductType').value &&
        content.idProduct !== this.contentForm.get('idProduct').value
      ) {
        totalRevenuePercentage = +content.revenuePercentage;
      }
    });
    const max: number = 100;
    if (
      totalRevenuePercentage + this.contentForm.get('revenuePercentage').value >
      100
    ) {
      this.contentForm
        .get('revenuePercentage')
        .setErrors({ overMax: { max, overMax: true } });
    }
  }

  resetFilters(): void {
    this.contentsData = this.oldContents;
    this.filterSelects = {
      typology: null,
      type: null,
      importanceLevel: null,
    };
    this.typologyFilter.value = [];
    this.typeFilter.value = [];
    this.importanceLevelFilter.value = [];
  }

  resetContentFormOnTypeChange(selection: MatSelectChange): void {
    let typology: string;
    let idContent: number;
    let name: string;
    let description: string;
    let englishDescription: string;
    if (this.contentForm.get('type')?.value) {
      typology = this.contentForm.get('type').value;
    }
    if (this.contentForm.get('idProduct')?.value) {
      idContent = this.contentForm.get('idProduct').value;
    }
    if (this.contentForm.get('name')?.value) {
      name = this.contentForm.get('name').value;
    }
    if (this.contentForm.get('description')?.value) {
      description = this.contentForm.get('description').value;
    }
    if (this.contentForm.get('englishDescription')?.value) {
      englishDescription = this.contentForm.get('englishDescription').value;
    }
    this.contentForm.reset();
    this.contentForm.get('type').setValue(typology);
    this.contentForm.get('type').updateValueAndValidity();
    this.contentForm.get('idProduct').setValue(idContent);
    this.contentForm.get('idProduct').updateValueAndValidity();
    this.contentForm.get('name').setValue(name);
    this.contentForm.get('name').updateValueAndValidity();
    this.contentForm.get('description').setValue(description);
    this.contentForm.get('description').updateValueAndValidity();
    this.contentForm.get('englishDescription').setValue(englishDescription);
    this.contentForm.get('englishDescription').updateValueAndValidity();
    this.productMatching.clear();
    this.relevantRegistryList.clear();
    this.wordsKeyError = false;
    this.contentForm.get('idProductType').setValue(selection.value);
    // this.contentForm.get('idProductType').updateValueAndValidity();
    // if (selection.value === 1) {
    //   this.contentForm.
    // }
    if (selection.value < 3) {
      this.contentForm.get('revenuePercentage').enable();
      this.contentForm.get('quantity').enable();
      this.contentForm.get('quantityYear').enable();
      this.contentForm.get('unitOfMeasure.idUom').enable();
      this.contentForm.get('valueOfUnit').enable();
      this.contentForm.get('valueYear').enable();
      this.contentForm.get('importedExported').enable();
      this.contentForm.get('reason').enable();
      this.contentForm.get('wordKey').enable();
      this.contentForm.get('relevantRegistry').disable();
    }
    if (selection.value === 2) {
      this.contentForm.get('revenuePercentage').disable();
      this.contentForm.get('productCode').clearValidators();
      this.contentForm.get('productCode').updateValueAndValidity();
      this.contentForm.get('importanceLevel').clearValidators();
      this.contentForm.get('importanceLevel').updateValueAndValidity();
      this.contentForm.get('reason').clearValidators();
      this.contentForm.get('reason').updateValueAndValidity();
    }
    if (selection.value !== 2) {
      if (!this.isAgent) {
        this.contentForm.get('productCode').setValidators(Validators.required);
      }
      this.contentForm
        .get('importanceLevel')
        .setValidators(Validators.required);
      this.contentForm.get('importanceLevel').updateValueAndValidity();
      this.contentForm
        .get('reason')
        .setValidators([Validators.required, Validators.maxLength(500)]);
      this.contentForm.get('reason').updateValueAndValidity();
    }
    if (selection.value > 2) {
      this.contentForm.get('revenuePercentage').disable();
      this.contentForm.get('quantity').disable();
      this.contentForm.get('quantityYear').disable();
      this.contentForm.get('unitOfMeasure.idUom').disable();
      this.contentForm.get('valueOfUnit').disable();
      this.contentForm.get('valueYear').disable();
      this.contentForm.get('importedExported').disable();
      this.contentForm.get('reason').disable();
      this.contentForm.get('wordKey').disable();
      this.contentForm.get('relevantRegistry').enable();
    }
    /* Set validators if customer export */
    if (this.contentForm.get('idProductType').value === 1) {
      if (this.noContract) {
        this.contentForm
          .get('productCode')
          .setValidators([Validators.required]);
        this.contentForm.get('productCode').updateValueAndValidity();
      }
      if (
        !this.oldContents.some((prod: IProductsServices) => prod.productCode)
      ) {
        if (!this.isAgent) {
          this.contentForm
            .get('productCode')
            .setValidators(MustBeRequired(this.hasExport));
          this.contentForm.get('productCode').updateValueAndValidity();
        }
      }
    } else {
      this.contentForm.get('productCode').clearValidators();
      this.contentForm.get('productCode').updateValueAndValidity();
    }
  }

  showUomManagementDialog(event: PointerEvent): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      managementType: 'uom',
      uom: this.unitsOfMeasure,
      idCustomer: this.idCustomer,
    };
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        this.subscriptions.add(
          this.irFormService
            .getUnitsOfMeasure(false, false, this.idCustomer)
            .subscribe({
              next: (uom: UnitsOfMeasure[]) => {
                this.unitsOfMeasure = uom;
              },
            })
        );
      }
    });
  }

  addSubProduct(subProduct?: IProductsServices, type?: PRODUCT_TYPE): void {
    const product: IProductsServices = this.contentForm.getRawValue();
    let tempLength: number;
    const tempProduct: FormGroup = this.fb.group({
      idProductType: [
        type === PRODUCT_TYPE.related
          ? PRODUCT_TYPE.related
          : PRODUCT_TYPE.competition,
      ],
      idProduct: [subProduct?.idProduct || null],
      commerceSector: this.fb.group({
        idCommerceSector: [
          type === PRODUCT_TYPE.related
            ? product?.commerceSector?.idCommerceSector
            : type === PRODUCT_TYPE.competition
            ? subProduct?.commerceSector?.idCommerceSector
            : null,
          [Validators.required],
        ],
      }),
      description: [
        subProduct?.description || null,
        [Validators.required, Validators.maxLength(250)],
      ],
      englishDescription: [
        subProduct?.englishDescription || null,
        !this.isAgent || this.noContract
          ? [Validators.required, Validators.maxLength(250)]
          : [Validators.maxLength(250)],
      ],
      name: [
        subProduct?.name || null,
        [Validators.required, Validators.maxLength(200)],
      ],
      // productCode: [subProduct?.productCode || null, this.isAgent ? null :  Validators.required],
      importanceLevel: [
        subProduct?.importanceLevel || null,
        [Validators.required],
      ],
      type: [product.type, [Validators.required]],
      relevantRegistry: this.fb.array([]),
      idProductRelated: [product?.idProduct || null],
    });
    if (!subProduct) {
      this.addRelevantRegistry(tempProduct);
    }
    switch (type) {
      case PRODUCT_TYPE.related:
        tempLength = this.relatedComplementaryProducts.getRawValue()?.length;
        this.relatedComplementaryProducts.push(tempProduct);
        break;
      case PRODUCT_TYPE.competition:
        tempLength = this.indirectCompetitionProducts.getRawValue()?.length;
        this.indirectCompetitionProducts.push(tempProduct);
        break;
    }
    subProduct?.relevantRegistry?.forEach((anagrafic: IRelevantRegistry) => {
      this.addRelevantRegistry(
        type === PRODUCT_TYPE.related
          ? (this.relatedComplementaryProducts.at(tempLength) as FormGroup)
          : (this.indirectCompetitionProducts.at(tempLength) as FormGroup),
        anagrafic
      );
    });
  }

  removeSubProduct(
    subProduct: FormGroup,
    type: PRODUCT_TYPE,
    index: number
  ): void {
    let subProductList: FormArray;
    if (type === PRODUCT_TYPE.related) {
      subProductList = this.relatedComplementaryProducts;
    } else {
      subProductList = this.indirectCompetitionProducts;
    }
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef: any = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      const idSubProduct: number = subProduct.get('idProduct')?.value;
      if (result) {
        if (idSubProduct) {
          this.irFormService.deleteProduct(idSubProduct).subscribe({
            complete: () => {
              subProductList.removeAt(index);
              this.reloadContentsEmitter.emit();
            },
            error: (error: HttpErrorResponse) => {
              if (error.error.errors.message === 'PR007') {
                this.common.showToast(
                  this.translate.instant(
                    'API_RESPONSE.' + error.error.errors.message
                  ),
                  ToastStatus.error,
                  3000
                );
              } else {
                this.common.showToast(
                  this.translations.ShowGeneralErrorToast,
                  ToastStatus.error,
                  3000
                );
              }
            },
          });
        } else {
          subProductList.removeAt(index);
        }
      }
    });
  }

  addRelevantRegistry(group: FormGroup, anagrafic?: IRelevantRegistry): void {
    (group.get('relevantRegistry') as FormArray).push(
      this.fb.group({
        idRelevantRegistry: [anagrafic?.idRelevantRegistry || null],
        companyName: [
          anagrafic?.companyName || null,
          [Validators.required, Validators.maxLength(45)],
        ],
        idCountry: [anagrafic?.idCountry || null, Validators.required],
      })
    );
  }

  resetContentDetail(): void {
    this.contentForm.reset();
    this.productMatching.clear();
    this.relevantRegistryList.clear();
    (this.contentForm.get('relatedComplementary') as FormArray).clear();
    (this.contentForm.get('indirectCompetition') as FormArray).clear();
    this.wordsKeyError = false;
    this.contentForm.get('idProductType').enable();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
