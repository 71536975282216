import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { HiddenArchorProps } from 'src/app/models/utility';

@Component({
  selector: 'app-hidden-archor',
  template: `<a #hiddenarchor target="_blank"></a>`,
  styleUrls: ['./hidden-archor.component.scss']
})
export class HiddenArchorComponent implements AfterViewInit, OnDestroy {

  subscriptions: Subscription = new Subscription();

  @ViewChild('hiddenarchor', { static: false }) hiddenarchor: ElementRef;
  @Input() urlListener: Observable<HiddenArchorProps>;

  @Output() emitRevokeUrl: EventEmitter<void> = new EventEmitter();

  constructor() { }

  ngAfterViewInit(): void {
    this.subscriptions.add(this.urlListener.subscribe({
      next: (props: HiddenArchorProps) => {
        if (props) {
          this.hiddenarchor.nativeElement.setAttribute('href', props.url );
          this.hiddenarchor.nativeElement.setAttribute('download', props.filename);
          const event: MouseEvent = new MouseEvent('click', { bubbles: true });
          this.hiddenarchor.nativeElement.dispatchEvent(event);
          this.emitRevokeUrl.emit();
        }
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
