import { Component, OnInit, Input, Output, EventEmitter,
  OnDestroy, OnChanges, SimpleChanges
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { fromProjectDtoToProjectTable } from 'src/app/utility/elaborations';
import { IProjectForTable, ProjectDTO } from 'src/app/models/project';
import { IColumnsName, ITableButtons, ITableOptions } from 'src/app/models/utility';
import { DOC_TYPE, RI_TAB, RI_STATUS } from 'src/app/config';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ir-rejected-table',
  templateUrl: './ir-rejected-table.component.html',
  styleUrls: ['./ir-rejected.component.css']
})
export class IrRejectedTableComponent implements OnInit, OnChanges, OnDestroy {

  subscriptions: Subscription = new Subscription();

  projectDataTable: MatTableDataSource<IProjectForTable> = new MatTableDataSource<IProjectForTable>();

  riTabs: string[] = Object.values(RI_TAB);

  @Input() loader: boolean;
  @Input() translations: any;
  @Input() isLabSupervisor: boolean;
  @Input() projectData: ProjectDTO[];
  @Input() columnsName: IColumnsName[] = [
    {value: 'idProject', name: 'ID Progetto'},
    {value: 'businessName', name: 'Ragione Sociale'},
    {value: 'telephoneNumber', name: 'Telefono'},
    {value: 'email', name: 'E-Mail'},
    {value: 'address', name: 'Indirizzo'},
    {value: 'startWorking', name: 'Data'}
  ];
  @Input() displayedColumns: string[] = [
    'startWorking',
    'idProject',
    'businessName',
    'telephoneNumber',
    'email',
    'address',
    'menuButtons'
  ];
  @Input() tableMenuButtons: ITableButtons[] = [];
  @Input() tableOptions: ITableOptions = {
    showFilter: true,
    showSort: true,
    showPaginator: true,
    showButtons: true
  };

  @Output() reloadDataEmitter: EventEmitter<void> = new EventEmitter();

  constructor(
    private router: Router,
    private irFormService: IrFormService
  ) { }

  ngOnInit(): void {
    this.tableMenuButtons = [
      {
        name: 'Visualizza ASS',
        disabled: () => false,
        conditions: (): boolean => !this.isLabSupervisor,
        click: (project: IProjectForTable): void => { this.router.navigate([`/assessment-readonly`, project.idProject]); },
        icon: 'assignment',
        iconClass: 'dark-icon-color'
      },
      {
        name: 'Visualizza PS corrente',
        disabled: () => false,
        conditions: (): boolean => this.isLabSupervisor,
        click: (project: IProjectForTable): void => { this.checkPsStatus(project); },
        icon: 'assignment',
        iconClass: 'dark-icon-color'
      },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const projectData: ProjectDTO[]  = changes.projectData?.currentValue || [];
    if (projectData?.length || projectData.length === 0) {
      this.projectDataTable.data = projectData.map(fromProjectDtoToProjectTable);
    }
  }

  visualizeIr(project: IProjectForTable): void {
    let idDocument: number | string;
    project.documents.forEach(data => {
      if (data.type === DOC_TYPE.RI || data.type === DOC_TYPE.CONTRACT) {
        idDocument = data.idDocument;
      }
    });
    this.subscriptions.add(this.irFormService.downloadDocument(idDocument).subscribe());
  }

  checkPsStatus(project: IProjectForTable): void {
    if (project.idProjectStatus >= RI_STATUS.IspWorking2) {
      this.router.navigate([ '/ps-form-readonly', project.idProject, project?.customer?.idCustomer ]);
    } else {
      this.router.navigate([`/assessment-readonly`, project.idProject]);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
