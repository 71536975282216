<div class="section-card">
  <div class="header h70">
    <div class="title">
      <span>{{ "CUSTOMER.InfoCustomer" | translate }}</span>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <div class="col-1">
        <div class="profile-pic">
          <img *ngIf="!customerLogoPath && !loaderProjects" mat-card-avatar class="mat-card-avatar-custom" src="../../../../assets/img/default-user-image.png">
          <img *ngIf="customerLogoPath && !loaderProjects" mat-card-avatar class="mat-card-avatar-custom" [src]="customerLogoPath">
          <mat-spinner *ngIf="loaderProjects" class="d-inline-block mt-ml-25" diameter="20"></mat-spinner>
          <div class="edit">
            <button id="upload-logo-button" mat-icon-button type="button" class="calendar-action-button button-logo-custom">
              <label class="upload-label" for="upload-logo">
                <mat-icon class="primary-color avatar-icon-custom">edit</mat-icon>
              </label>        
              <input id="upload-logo" type="file" style="display: none;" (change)="handleLogoFileInput($event.target?.files)">
            </button>
          </div>
        </div>
      </div>
      <mat-form-field appearance="standard" class="col-3">
        <mat-label for="businessName">
          {{ "CUSTOMER.BusinessName" | translate }}
        </mat-label>        
        <input
          matInput
          type="text"
          name="businessName"
          [value]="businessName || ''"
          readonly
        >
      </mat-form-field>
      <mat-form-field appearance="standard" class="col-3">
        <mat-label for="address">
          {{ "CUSTOMER.Address" | translate }}
        </mat-label>   
        <input
          matInput
          type="text"
          name="address"
          [value]="customerAddress || ''"
          readonly
        >
      </mat-form-field>
      <mat-form-field appearance="standard" class="col-3">
        <mat-label for="email">
          {{ "CUSTOMER.Email" | translate }}
        </mat-label>   
        <input
          matInput
          type="email"
          name="email"
          [value]="customer?.customer?.email || null"
          readonly
        >
      </mat-form-field>
      <mat-form-field appearance="standard" class="col-2">
        <mat-label for="telephoneNumber">
          {{ "CUSTOMER.TelephoneNumber" | translate }}
        </mat-label>   
        <input
          matInput
          type="tel"
          name="telephoneNumber"
          [value]="customer?.customer?.telephoneNumber || ''"
          readonly
        >
      </mat-form-field>
    </div>
  </div>
</div>

<div *ngIf="administrativeReferent || projectReferent">
  <div *ngIf="administrativeReferent && isDirection" class="section-card mt-30">
    <div class="header h70">
      <div class="title">
        <span>{{ "CUSTOMER.AdministrativeReferent" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field appearance="standard" class="col-3">
          <mat-label>{{ 'CUSTOMER.Name' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="name"
            [value]="administrativeReferent?.name"
            readonly
          >
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3">
          <mat-label>{{ 'CUSTOMER.Surname' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="surname"
            [value]="administrativeReferent?.surname"
            readonly
          >
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3">
          <mat-label>{{ 'CUSTOMER.TelephoneNumber' | translate }}</mat-label>
          <input
            matInput
            type="tel"
            name="phone"
            [value]="administrativeReferent?.telephoneNumber"
            readonly
          >
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3">
          <mat-label>{{ 'CUSTOMER.Email' | translate }}</mat-label>
          <input
            matInput
            type="email"
            name="email"
            [value]="administrativeReferent?.email"
            readonly
          >
        </mat-form-field>      
      </div>
    </div>
  </div>

  <div *ngIf="projectReferent" class="section-card mt-30">
    <div class="header h70">
      <div class="title">
        <span>{{ "CUSTOMER.ProjectReferent" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field appearance="standard" class="col-3">
          <mat-label>{{ 'CUSTOMER.Name' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="name"
            [value]="projectReferent?.name"
            readonly
          >
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3">
          <mat-label>{{ 'CUSTOMER.Surname' | translate }}</mat-label>
          <input
            matInput
            type="text"
            name="surname"
            [value]="projectReferent?.surname"
            readonly
          >
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3">
          <mat-label>{{ 'CUSTOMER.TelephoneNumber' | translate }}</mat-label>
          <input
            matInput
            type="tel"
            name="phone"
            [value]="projectReferent?.telephoneNumber"
            readonly
          >
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-3">
          <mat-label>{{ 'CUSTOMER.Email' | translate }}</mat-label>
          <input
            matInput
            type="email"
            name="email"
            [value]="projectReferent?.email"
            readonly
          >
        </mat-form-field>      
      </div>
    </div>
  </div>
</div>

<!-- Projects -->
<!-- <div *ngIf="projectsDataSource && projectsDataSource.data && projectsDataSource.data.length">
  <div class="ta-center mt-60">
    <span class="bold-text-color">{{ 'CUSTOMER.Projects' | translate }}</span>
  </div>
  <div class="mb-40">
    <app-table
      [dataSource]="projectsDataSource"
      [columnsName]="projectsColumnsName"
      [displayedColumns]="projectsDisplayedColumns"
      [tableButtons]="projectsTableButtons"
      [tableSubMenuButtons]="projectsTableSubMenuButtons"
      [tableOptions]="projectsTableOptions"
      [loader]="loaderProjects"
    ></app-table>
  </div>
</div> -->
<div class="section-card mt-30">
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "CUSTOMER.Projects" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!projectsDataSource?.length"
        (emitSearchValue)="searchValue($event, 'projects')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="loaderProjects"
      [columns]="projectsTableColumns"
      [columnsButtons]="projectsTableColumnsButtons"
      [columnsButtonsWithHeading]="projectsTableColumnsButtonsWithHeading"
      [data]="projectsDataSource"
      [searchValue]="searchValueProjects"
    >
    </app-table>
  </div>
</div>

<!-- Documents -->
<!-- <div class="ta-center mt-60">
  <span class="bold-text-color">{{ 'CUSTOMER.Documents' | translate }}</span>
</div>
<div>
  <app-table
    [dataSource]="documentsDataSource"
    [columnsName]="documentsColumnsName"
    [displayedColumns]="documentsDisplayedColumns"
    [tableButtons]="documentsTableButtons"
    [tableOptions]="documentsTableOptions"
    [loader]="loaderDocuments"
  ></app-table>
</div>
<div class="mt-40">
  <app-upload-file
    id="upload-document-app-file"
    [uploadDirectType]="true"
    [fileType]="translations?.Document"
    (uploadFileEmitter)="uploadDocument($event)"
  ></app-upload-file>
</div> -->
<div class="section-card mt-30">
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "CUSTOMER.Documents" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!documentsDataSource?.length"
        (emitSearchValue)="searchValue($event, 'documents')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="loaderDocuments"
      [columns]="documentsTableColumns"
      [columnsButtons]="documentsTableColumnsButtons"
      [data]="documentsDataSource"
      [searchValue]="searchValueDocuments"
    >
    </app-table>
    <div class="mt-40 d-flex justify-content-center">
      <app-upload-file
        id="upload-document-app-file"
        [uploadDirectType]="true"
        [buttonClass]="'primary'"
        [uploadingFileIcon]="'new_document_icon'"
        [fileType]="translations?.Document"
        (uploadFileEmitter)="uploadDocument($event)"
      ></app-upload-file>
    </div>
  </div>
</div>

<!-- Actions -->
<div class="d-flex justify-content-center mt-60">
  <button *ngIf="isEnabled && isAuthorized" class="rounded-button error-border-button" (click)="disableCustomer()">
    <svg-icon class="w20 mr-10" name="decline_icon"></svg-icon>
    {{ 'COMMON.DisableCustomer' | translate }}
  </button>
  <button *ngIf="!isEnabled && isAuthorized" class="rounded-button primary-border-button" (click)="enableCustomer()">
    <svg-icon class="w20 mr-10" name="accept_icon"></svg-icon>
    {{ 'COMMON.EnableCustomer' | translate }}
  </button>
</div>