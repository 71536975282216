import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { ICustomer } from 'src/app/models/customer';
import { ICustomerInformation } from 'src/app/models/customer-information';
import { Contract, DocumentDTO } from 'src/app/models/contract';
import { IProjectSections, Project, ProjectDTO } from 'src/app/models/project';
import {
  ICnProductsService,
  IInterchangeData,
  IProductsServices,
  IProductWithoutBM,
} from 'src/app/models/products-services';
import {
  IMarkets,
  IMatrixMarketsAnalysis,
  MarketsAnalysisResponse,
} from 'src/app/models/markets';
import { IChannel } from 'src/app/models/channels';
import { ICountries } from 'src/app/models/countries';
import { IReferenceCompetitor } from 'src/app/models/reference-competitor';
import { Note } from 'src/app/models/note';
import { Swot } from 'src/app/models/swot';
import { IRoles, UserFunctionality } from 'src/app/models/user';
import {
  APP_CONTEX,
  ID_PROJECT_STATUS,
  RI_STATUS,
  RI_TAB,
  Roles,
} from 'src/app/config';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { CustomerDescriptions } from 'src/app/models/customer-descriptions';
import {
  SplitNotesArray,
  SplitUncheckedNotesArray,
} from 'src/app/utility/elaborations';
import { ILeadGeneration } from 'src/app/models/lead-generation';
import { ToastStatus } from 'src/app/models/utility';
import { CommonService } from 'src/app/services/common/common.service';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { IProductMatching } from 'src/app/models/product-matching';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { CompileTemplateMetadata } from '@angular/compiler';
import { ICustomerStrategy } from 'src/app/models/strategy';
import { CustomerFormComponent } from 'src/app/components/customer-form/customer-form.component';
import { ExportResponse } from 'src/app/models/chart';
import { CustomerInformationFormComponent } from 'src/app/components/customer-information-form/customer-information-form.component';
import { ComponentType } from '@angular/cdk/portal';
import { ContractFormComponent } from 'src/app/components/contract-form/contract-form.component';
import { ProductsServicesFormComponent } from 'src/app/components/products-services-form/products-services-form.component';
import { MarketFormComponent } from 'src/app/components/market-form/market-form.component';
import { ChannelsFormComponent } from 'src/app/components/channels-form/channels-form.component';
import { ReferenceCompetitorsComponent } from 'src/app/components/reference-competitors/reference-competitors.component';
import { SwotFormComponent } from 'src/app/components/swot-form/swot-form.component';
import { LeadGenerationFormComponent } from 'src/app/components/lead-generation-form/lead-generation-form.component';
import { MarketsStrategyAnalysisComponent } from 'src/app/components/markets-strategy-analysis/markets-strategy-analysis.component';
import { StrategyFormComponent } from 'src/app/components/strategy-form/strategy-form.component';
import { NoteFormComponent } from 'src/app/components/note-form/note-form.component';
import { IChannelsType } from 'src/app/models/channels-type';
/**
 * @TODO Remove logic that is of form component from here
 */
@Component({
  selector: 'app-ir-form',
  templateUrl: './ir-form.component.html',
  styleUrls: ['./ir-form.component.scss'],
})
export class IrFormComponent implements OnInit, OnDestroy {
  @ViewChild('matTabGroup') tab: MatTabGroup;

  /**
   * @description ProjectDTO from api response
   */
  project: ProjectDTO;
  /**
   * @description All entities of project
   */
  projectEntities: Project;

  private subscriptions: Subscription = new Subscription();

  translations: any = {};
  /**
   * @description Contract Tab initialValues
   */
  contractInitialValues: Contract;
  /**
   * @desacription Name and surname of logged user
   */
  loggedUserName: string;
  /**
   * @description The availe functionality of logged user
   */
  functionalities: UserFunctionality = new UserFunctionality();
  /**
   * @description Subject of project, emit event when project is ready
   */
  private getProjectReady: Subject<Project> = new Subject<Project>();
  /**
   * @description getPReadyEmitter used as an observable
   */
  $getProjectReady: Observable<Project> = this.getProjectReady.asObservable();
  /**
   * @description Subject of document, emit event when document is loaded
   */
  private documetLoadedEmitter: Subject<DocumentDTO> =
    new Subject<DocumentDTO>();
  /**
   * @description initializeFormEmitter used as an observable
   */
  $documetLoadedEmitter: Observable<DocumentDTO> =
    this.documetLoadedEmitter.asObservable();

  supervisorNotes: Subject<any> = new Subject<any>();
  otherDocuments: Subject<DocumentDTO[]> = new Subject<DocumentDTO[]>();
  atLeastOneUncheckedNote: boolean = false;

  splittedNotes: { [key: string]: Note[] } = {};

  countries: ICountries[];

  index: number = 0;
  idCustomer: number;

  riTab = RI_TAB;
  // isSupervisor: boolean = false;
  isIsp: boolean = false;
  isReadOnly: boolean = false;
  isIspDelivered: boolean = false;
  isNoContract: boolean = false;
  // isAgent: boolean = false;
  saveNotesLoader: boolean = false;
  isValidPM: boolean = false;
  // isLabManagerInternal: boolean = false;
  // isLabManager: boolean = false;
  isProjectSupervisor: boolean = false;
  isAdminOrDirection: boolean = false;
  canModifyStrategy: boolean = false;
  isManagerAndSupervisor: boolean = false;
  isUserAndPreviousUser: boolean = false;

  isAgent: boolean = false;
  isAgentSupervisor: boolean = false;
  isLabManager: boolean = false;
  isLabManagerInternal: boolean = false;
  isLabManagerNoSupervisorMode: boolean = false;
  isLabManagerSupervisor: boolean = false;
  isSupervisor: boolean = false;
  isDirection: boolean = false;

  userRole: string;

  dirtyTabs: any = {
    customer: null,
    customerInformation: null,
    contract: null,
    productServices: null,
    market: null,
    channels: null,
    referenceCompetitors: null,
    leadGeneration: null,
    note: null,
    swot: null,
    marketsAnalysis: null,
    strategy: null,
  };
  invalidTabs: any = {
    customer: null,
    customerInformation: null,
    contract: null,
    productServices: null,
    market: null,
    channels: null,
    referenceCompetitors: null,
    leadGeneration: null,
    note: null,
    swot: null,
    marketsAnalysis: null,
    strategy: null,
  };

  productsForAnalysis: ICnProductsService;
  interchangeTypologyData: any;
  matrixMarketAnalysis: any;

  /**** NEW ****/

  tabsNoContract: string[] = [
    'customer',
    'customerInformation',
    'productServices',
  ];
  tabsAss: string[] = [
    'customer',
    'customerInformation',
    'contract',
    'productServices',
    'market',
    'channels',
    'referenceCompetitors',
    'note',
  ];
  tabsPs: string[] = [
    'customer',
    'customerInformation',
    'contract',
    'productServices',
    'market',
    'channels',
    'referenceCompetitors',
    'swot',
    'leadGeneration',
    'marketsAnalysis',
    'strategy',
  ];
  selectedTab: string = RI_TAB.Customer;

  checkDirtyTabBeforeSendProject: Observable<boolean> =
    new Observable<boolean>();

  projectStatus: any = RI_STATUS;
  tabsComponentsArray: any[];

  @ViewChild('customer') customer: CustomerFormComponent;
  @ViewChild('customerInformation')
  customerInformation: CustomerInformationFormComponent;
  @ViewChild('contract') contract: ContractFormComponent;
  @ViewChild('productsServices')
  productsServices: ProductsServicesFormComponent;
  @ViewChild('markets') markets: MarketFormComponent;
  @ViewChild('distribution') distribution: ChannelsFormComponent;
  @ViewChild('referenceCompetitors')
  referenceCompetitors: ReferenceCompetitorsComponent;
  @ViewChild('swot') swot: SwotFormComponent;
  @ViewChild('leadGeneration') leadGeneration: LeadGenerationFormComponent;
  @ViewChild('marketsAnalysis')
  marketsAnalysis: MarketsStrategyAnalysisComponent;
  @ViewChild('strategy') strategy: StrategyFormComponent;
  @ViewChild('notes') notes: NoteFormComponent;

  constructor(
    private authService: AuthService,
    private irFormService: IrFormService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private layoutService: LayoutService,
    private tranlsate: TranslateService,
    private router: Router,
    private common: CommonService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    // Fill array of tabs component
    this.tabsComponentsArray = [this.customer, this.customerInformation];
    this.layoutService.triggerDirtyForm({
      customer: false,
      contract: false,
      customerInformation: false,
      productServices: false,
      market: false,
      channels: false,
      referenceCompetitors: false,
      leadGeneration: false,
      swot: false,
      note: false,
    });
    this.layoutService.triggerInvalidForm({
      customer: false,
      contract: false,
      customerInformation: false,
      productServices: false,
      market: false,
      channels: false,
      referenceCompetitors: false,
      leadGeneration: false,
      swot: false,
      note: false,
    });
    this.subscriptions.add(
      this.translate.get('IR_FORM').subscribe((translations) => {
        this.translations = translations;
        this.contractInitialValues = {
          idUser: this.authService.userProfile?.idUser,
        };
        this.loggedUserName = `${this.authService.userProfile?.name || ''} ${
          this.authService.userProfile?.surname || ''
        }`;
        this.userRole = this.authService.userProfile?.activeRole;
        if (
          this.userRole === Roles.AgentSupervisor ||
          this.userRole === Roles.LabManagerSupervisor
        ) {
          this.isSupervisor = true;
        }
        if (this.userRole === Roles.Agent) {
          this.isAgent = true;
        }
        if (this.userRole === Roles.AgentSupervisor) {
          this.isAgentSupervisor = true;
        }
        if (this.userRole === Roles.LabManagerInternal) {
          this.isLabManagerInternal = true;
        }
        if (this.userRole === Roles.LabManager) {
          this.isLabManager = true;
        }
        if (this.userRole === Roles.LabManagerSupervisor) {
          this.isLabManagerSupervisor = true;
        }
        if (this.userRole === Roles.Direction) {
          this.isDirection = true;
        }
        if (
          this.userRole === Roles.Admin ||
          this.userRole === Roles.Direction
        ) {
          this.isAdminOrDirection = true;
        }
        this.functionalities = this.authService.functionality;
        this.subscriptions.add(
          this.route.params.subscribe((params) => {
            this.project = { idProject: params.idProject };
          })
        );
        this.subscriptions.add(
          this.irFormService.getProjectById(this.project.idProject).subscribe({
            next: (project: ProjectDTO) => {
              this.project = project;
              if (project.idProjectStatus === RI_STATUS.NoContract) {
                this.isNoContract = true;
              }
              if (project.idProjectStatus >= RI_STATUS.IspWorking) {
                this.isIsp = true;
              }
              if (project.idProjectStatus >= RI_STATUS.IspWorking2) {
                this.isReadOnly = true;
              }
              if (
                project.idProjectStatus === RI_STATUS.IspDelivered ||
                project.idProjectStatus === RI_STATUS.IspDelivered2 ||
                project.idProjectStatus === RI_STATUS.IspDelivered3 ||
                project.idProjectStatus === RI_STATUS.IspDelivered4 ||
                project.idProjectStatus === RI_STATUS.IspDelivered5 ||
                project.idProjectStatus === RI_STATUS.IspDelivered6
              ) {
                if (
                  this.isLabManager &&
                  this.project.idUser === this.authService.userProfile?.idUser
                ) {
                  this.isLabManager = false;
                  if (!this.isSupervisor) {
                    this.isProjectSupervisor = true;
                    this.isReadOnly = true;
                    this.canModifyStrategy = true;
                  }
                }
                this.isIspDelivered = true;
              }
              if (
                project.manager &&
                project.supervisor &&
                project.manager?.idUser === project.supervisor?.idUser
              ) {
                this.isManagerAndSupervisor = true;
              }
              if (project.user?.idUser === project.previousUser?.idUser) {
                this.isUserAndPreviousUser = true;
              }
              this.subscriptions.add(
                this.route?.data?.subscribe((data: any) => {
                  if (
                    data?.readonly &&
                    (this.authService?.userProfile?.idUser ===
                      this.project?.supervisor?.idUser ||
                      this.isAdminOrDirection ||
                      this.project?.idUser !== this.project?.previousOwnerId)
                  ) {
                    this.isReadOnly = true;
                  }
                })
              );
            },
            complete: () => {
              this.getProjectEntities();
              this.generateBreadCrumb();
            },
          })
        );
      })
    );
    this.layoutService.dirtyFormListner$.subscribe((event) => {
      this.dirtyTabs = event;
    });
    this.layoutService.invalidFormListener.subscribe((event: any) => {
      this.invalidTabs = event;
    });
  }

  generateBreadCrumb(): void {
    this.subscriptions.add(
      this.layoutService.generateBreadcrumb([
        {
          label: this.isIsp
            ? this.tranlsate.instant('PAGES.ps')
            : this.tranlsate.instant('PAGES.assessment'),
          path: this.isIsp ? '/ps' : '/assessment',
          pageTitle: false,
        },
        {
          label: this.project?.customer?.businessName,
          path: null,
          pageTitle: true,
        },
      ])
    );
  }

  /**
   * @description Call the service method to get all Supervisor Notes
   */
  getSupervisorNotes(): void {
    if (this.isAgentSupervisor || this.isLabManagerSupervisor) {
      this.subscriptions.add(
        this.irFormService
          .getSuperVisorNotes(this.project.idProject, true)
          .subscribe((notes: Note[]) => {
            this.supervisorNotes.next(SplitUncheckedNotesArray(notes));
            this.splittedNotes = SplitUncheckedNotesArray(notes);
            this.atLeastOneUncheckedNote = notes.some(
              (note: Note) => !note.checked
            );
          })
      );
    }
  }

  /**
   * @description Call the service method to get all Project entities
   */
  getProjectEntities(): void {
    this.subscriptions.add(
      this.irFormService
        .getProjectEntities(
          this.project,
          false,
          this.isReadOnly,
          this.isAdminOrDirection,
          this.isIsp
        )
        .subscribe((projectEntities: IProjectSections) => {
          this.idCustomer = projectEntities.customer.idCustomer;
          this.projectEntities = projectEntities;
          // if (projectEntities?.supervisorNotes) {
          //   this.splittedNotes = SplitUncheckedNotesArray(projectEntities?.supervisorNotes);
          // }
          if (projectEntities?.marketAnalysis) {
            this.subscriptions.add(
              this.irFormService
                .getInterchangeTypologyData(
                  projectEntities.marketAnalysis.marketAnalysis.productCN8,
                  projectEntities.marketAnalysis.marketAnalysis
                    .interchangeTypology,
                  projectEntities.customer.idCustomer,
                  projectEntities.marketAnalysis.marketAnalysis.yearEnd
                )
                .subscribe({
                  next: (
                    interchangeTypologyDataResponse: IInterchangeData[]
                  ) => {
                    this.projectEntities.interchangeTypologyData =
                      interchangeTypologyDataResponse;
                  },
                  complete: () => {
                    this.emitGetProjectReady(projectEntities);
                  },
                })
            );
          } else {
            this.emitGetProjectReady(projectEntities);
          }
        })
    );
  }

  /**
   * @description Emit initialization form in all form component of ir-form page
   * @param initialValues All entities of the selected project
   */
  emitGetProjectReady(initialValues: Project): void {
    this.getUncheckedNotes();
    this.getSupervisorNotes();
    this.refreshOtherDocuments();
    this.getProjectReady.next({ ...this.project, ...initialValues });
  }

  /**
   * @description Save the customer
   * @param customer The customer to save
   */
  updateCustomer(customer: ICustomer): void {
    this.subscriptions.add(
      this.irFormService.updateCustomer(customer).subscribe({
        complete: () => {
          this.layoutService.scrollToTop();
          this.subscriptions.add(
            this.irFormService
              .getCustomerById(this.project.idCustomer)
              .subscribe({
                next: (customerResponse: ICustomer) => {
                  this.projectEntities.customer = customerResponse;
                  this.emitGetProjectReady(this.projectEntities);
                },
              })
          );
        },
        error: (err: HttpErrorResponse) => {
          throw err;
        },
      })
    );
  }

  /**
   * @description CALL THE SERVICE METHOD TO UPLOAD THE DOCUMENT
   * @param document File to upload
   */
  uploadContractDocument(document: File): void {
    this.subscriptions.add(
      this.irFormService.uploadDocument(document).subscribe({
        next: (resp: DocumentDTO) => {
          this.documetLoadedEmitter.next({
            type: 'CONTRACT',
            path: resp.path,
            name: resp.name,
          });
        },
        error: (err: HttpErrorResponse) => {
          throw err;
        },
      })
    );
  }

  /**
   * @description Call the service method to download or view the document
   * @param idDocument Id of document to view
   */
  viewDocument(idDocument: number | string): void {
    this.subscriptions.add(
      this.irFormService.downloadDocument(idDocument).subscribe()
    );
  }

  uploadOtherDocument(otherDocument: File): void {
    this.subscriptions.add(
      this.irFormService
        .uploadCustomerDocument(true, this.customer.idCustomer, otherDocument)
        .subscribe({
          complete: () => {
            this.refreshOtherDocuments();
          },
        })
    );
  }

  refreshOtherDocuments(): void {
    this.subscriptions.add(
      this.irFormService
        .getCustomerDocuments(true, this.idCustomer)
        .subscribe((data: DocumentDTO[]) => {
          this.otherDocuments.next(data);
        })
    );
  }

  /**
   * @description Save the contract
   * @param contract The contract to save
   */
  saveContract(contract: Contract): void {
    if (contract.idContract) {
      this.subscriptions.add(
        this.irFormService.updateContract(contract).subscribe({
          complete: () => {
            this.subscriptions.add(
              this.irFormService
                .getContractByIdProject(this.project.idProject)
                .subscribe({
                  next: (contractResponse: Contract) => {
                    this.projectEntities.contract = contractResponse;
                    this.emitGetProjectReady(this.projectEntities);
                  },
                })
            );
          },
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
      );
    }
    if (!contract.idContract) {
      this.subscriptions.add(
        this.irFormService.saveContract(contract).subscribe({
          complete: () => {
            this.subscriptions.add(
              this.irFormService
                .getContractByIdProject(this.project.idProject)
                .subscribe({
                  next: (contractResponse: Contract) => {
                    this.projectEntities.contract = contractResponse;
                    this.emitGetProjectReady(this.projectEntities);
                  },
                })
            );
          },
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
      );
    }
  }

  /**
   * @description Save the customer informations
   * @param customerInformation The customer informations to save
   */
  updateCustomerInformation(customerInformation: ICustomerInformation): void {
    if (customerInformation.idCustomerInformation) {
      this.subscriptions.add(
        this.irFormService
          .updateCustomerInformation(customerInformation)
          .subscribe({
            complete: () => {
              this.layoutService.scrollToTop();
              this.subscriptions.add(
                this.irFormService
                  .getCustomerInformationByIdCustomer(this.project.idCustomer)
                  .subscribe({
                    next: (
                      customerInformationResponse: ICustomerInformation
                    ) => {
                      this.projectEntities.customerInformation =
                        customerInformationResponse;
                      if (this.isIsp) {
                        this.subscriptions.add(
                          this.irFormService
                            .getCompetitorExportOrientation(
                              this.project.idCustomer
                            )
                            .subscribe({
                              next: (
                                competitorExportOrientation: ExportResponse
                              ) => {
                                this.projectEntities.competitorExportOrientation =
                                  competitorExportOrientation;
                                this.emitGetProjectReady(this.projectEntities);
                              },
                            })
                        );
                      } else {
                        this.emitGetProjectReady(this.projectEntities);
                      }
                    },
                  })
              );
            },
            error: (err: HttpErrorResponse) => {
              throw err;
            },
          })
      );
    }
    if (!customerInformation.idCustomerInformation) {
      this.subscriptions.add(
        this.irFormService
          .createCustomerInformation(customerInformation)
          .subscribe({
            complete: () => {
              this.layoutService.scrollToTop();
              this.subscriptions.add(
                this.irFormService
                  .getCustomerInformationByIdCustomer(this.project.idCustomer)
                  .subscribe({
                    next: (
                      customerInformationResponse: ICustomerInformation
                    ) => {
                      this.projectEntities.customerInformation =
                        customerInformationResponse;
                      this.emitGetProjectReady(this.projectEntities);
                    },
                  })
              );
            },
            error: (err: HttpErrorResponse) => {
              throw err;
            },
          })
      );
    }
  }

  /**
   * @description Update the products/services of the customer
   * @param productsServices The array with all products/services of the customer
   * @param idCustomer The id of the customer
   */
  updateProductsServices(productsServices: IProductsServices[]): void {
    this.subscriptions.add(
      this.irFormService
        .updateProductsServices(productsServices, this.project.idCustomer)
        .subscribe({
          complete: () => {
            this.layoutService.scrollToTop();
            this.refreshProductsServices();
          },
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
    );
  }

  /**
   * @description
   */
  refreshProductsServices(): void {
    this.subscriptions.add(
      this.irFormService
        .getProductsServicesByIdCustomer(this.project.idCustomer)
        .subscribe({
          next: (productsServicesResponse: IProductsServices[]) => {
            this.projectEntities.productsServices = productsServicesResponse;
            this.subscriptions.add(
              this.irFormService
                .getProductsWithoutBM(this.project.idCustomer)
                .subscribe({
                  next: (productsServicesWithoutBM: IProductWithoutBM[]) => {
                    this.projectEntities.productsServicesWithoutBM =
                      productsServicesWithoutBM;
                    this.emitGetProjectReady(this.projectEntities);
                    this.refreshReferenceCompetitors();
                  },
                })
            );
          },
        })
    );
  }

  updateMarkets(markets: IMarkets[]): void {
    this.subscriptions.add(
      this.irFormService
        .updateMarkets(markets, this.project.idCustomer)
        .subscribe({
          complete: () => {
            this.layoutService.scrollToTop();
            this.refreshMarkets();
          },
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
    );
  }

  refreshMarkets(): void {
    this.subscriptions.add(
      this.irFormService.getMarketsById(this.project.idCustomer).subscribe({
        next: (marketsResponse: IMarkets[]) => {
          this.projectEntities.markets = marketsResponse;
          this.emitGetProjectReady(this.projectEntities);
          this.refreshMarketAnalysis();
        },
      })
    );
  }

  /**
   * @description Save The distributions channel array emitted from channel-form
   * @param channels The array woth distribution channels to save
   */
  saveChannel(channels: IChannel[]): void {
    this.subscriptions.add(
      this.irFormService
        .saveChannelsOfCustomer(channels, this.project.idCustomer)
        .subscribe({
          complete: () => {
            this.layoutService.scrollToTop();
            this.refreshChannels();
          },
          error: (err: HttpErrorResponse) => {
            console.log(err);
            throw err;
          },
        })
    );
  }

  refreshChannels(): void {
    this.subscriptions.add(
      this.irFormService
        .getChannelsByIdCustomer(this.project.idCustomer)
        .subscribe({
          next: (channelsResponse: IChannel[]) => {
            this.projectEntities.channels = channelsResponse;
            this.emitGetProjectReady(this.projectEntities);
            if (
              this.projectEntities?.strategy?.projectObjective &&
              (this.isLabManager || this.isLabManagerSupervisor)
            ) {
              this.refreshStrategy();
            }
          },
        })
    );
  }

  // /**
  //  * @description Delete a channel company emitted from channel-form
  //  * @param idCompany the id of company to delete
  //  */
  // deleteChannelCompany(idCompany: number | string): void {
  //   this.subscriptions.add(this.irFormService.deleteChannelCompany(idCompany).subscribe({
  //     complete: () => this.getProjectEntities(),
  //     error: (err: HttpErrorResponse) => {
  //       this.getProjectEntities();
  //       throw err;
  //     }
  //   }));
  // }

  // /**
  //  * @description Delete a distributioon channel emitted from channel-form
  //  * @param idChannel the id of channel to delete
  //  */
  // deleteChannel(idChannel: number | string): void {
  //   this.subscriptions.add(this.irFormService.deleteChannel(idChannel).subscribe({
  //     complete: () => this.getProjectEntities(),
  //     error: (err: HttpErrorResponse) => {
  //       this.getProjectEntities();
  //       throw err;
  //     }
  //   }));
  // }

  /**
   * @description Save the notes array emitted from note-form
   * @param notes The array of notes to save
   */
  saveNotes(notes: Note[]): void {
    this.subscriptions.add(
      this.irFormService.saveNotes(notes, this.project.idProject).subscribe({
        complete: () => {
          this.subscriptions.add(
            this.irFormService
              .getNoteByProject(this.project.idProject)
              .subscribe({
                next: (data: Note[]) => {
                  this.layoutService.scrollToTop();
                  this.projectEntities.notes = data;
                  this.emitGetProjectReady(this.projectEntities);
                },
              })
          );
        },
        error: (err: HttpErrorResponse) => {
          throw err;
        },
      })
    );
  }

  /**
   * @description Save the swot emitted from swot-form
   * @param notes The swot to save
   */
  saveSwot(body: Swot): void {
    if (this.projectEntities?.swot?.idSwotUspAnalysis) {
      this.subscriptions.add(
        this.irFormService.saveSwot(body, this.project.idCustomer).subscribe({
          complete: () => {
            this.layoutService.scrollToTop();
            this.refreshSwot();
          },
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
      );
    } else {
      this.subscriptions.add(
        this.irFormService.createSwot(body).subscribe({
          complete: () => this.refreshSwot(),
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
      );
    }
  }

  refreshSwot(): void {
    this.subscriptions.add(
      this.irFormService
        .getSwotByIdCustomer(this.project.idCustomer)
        .subscribe({
          next: (swotResponse: Swot) => {
            this.projectEntities.swot = swotResponse;
            this.emitGetProjectReady(this.projectEntities);
          },
        })
    );
  }

  /**
   * @description Save the reference competitors arry emitted from reference-competitors form
   * @param referenceCompetitors The array of reference competitors to save
   */
  updateReferenceCompetitors(
    referenceCompetitors: IReferenceCompetitor[]
  ): void {
    this.subscriptions.add(
      this.irFormService
        .saveReferenceCompetitors(referenceCompetitors, this.project.idCustomer)
        .subscribe({
          complete: () => {
            this.layoutService.scrollToTop();
            this.refreshReferenceCompetitors();
          },
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
    );
  }

  refreshReferenceCompetitors(): void {
    this.subscriptions.add(
      this.irFormService
        .getReferenceCompetitorsDetailsByIdCustomer(this.project.idCustomer)
        .subscribe({
          next: (referenceCompetitorsResponse: IReferenceCompetitor[]) => {
            this.projectEntities.referenceCompetitors =
              referenceCompetitorsResponse;
            // TODO
            // AGGIUNGERE CONDIZIONE PER FARE LA CHIAMATA SOLO SE SI E' LAB MANAGER
            if (this.isLabManager || this.isLabManagerSupervisor) {
              this.subscriptions.add(
                this.irFormService
                  .getCompetitorExportOrientation(this.project.idCustomer)
                  .subscribe({
                    next: (competitorExportOrientation: ExportResponse) => {
                      this.projectEntities.competitorExportOrientation =
                        competitorExportOrientation;
                      this.emitGetProjectReady(this.projectEntities);
                      this.refreshMarketAnalysis();
                      this.refreshStrategy();
                    },
                  })
              );
            } else {
              this.emitGetProjectReady(this.projectEntities);
            }
          },
        })
    );
  }

  // /**
  //  * @description Deletes the entire competitor customer, including market areas, distribution channels and products
  //  * @param idCompetitorCompany The id of the competitor customer
  //  */
  // deleteCompetitorCustomerEmitter(idCompetitorCompany: string|number): void {
  //   this.subscriptions.add(this.irFormService.deleteCustomerCompetitor(idCompetitorCompany).subscribe({
  //     complete: () => this.getProjectEntities(),
  //     error: (err: HttpErrorResponse) => {
  //       this.getProjectEntities();
  //       throw err;
  //     }
  //   }));
  // }

  // /**
  //  * @description Deletes the competitor customer distribution channel
  //  * @param idCompetitorDistributionChannel The id of the competitor customer distribution channel
  //  */
  // deleteCompetitorDistributionChannel(idCompetitorDistributionChannel: string|number): void {
  //   this.subscriptions.add(this.irFormService.deleteCustomerCompetitorDistributionChannel(idCompetitorDistributionChannel).subscribe({
  //     complete: () => this.getProjectEntities(),
  //     error: (err: HttpErrorResponse) => {
  //       this.getProjectEntities();
  //       throw err;
  //     }
  //   }));
  // }

  // /**
  //  * @description Deletes the competitor customer market area
  //  * @param marketAreaId The id of the competitor customer market area
  //  */
  // deleteCompetitorMarketArea(marketAreaId: string|number): void {
  //   this.subscriptions.add(this.irFormService.deleteCustomerCompetitorMarketArea(marketAreaId).subscribe({
  //     complete: () => this.getProjectEntities(),
  //     error: (err: HttpErrorResponse) => {
  //       this.getProjectEntities();
  //       throw err;
  //     }
  //   }));
  // }

  // /**
  //  * @description Deletes the competitor customer product
  //  * @param idProduct The id of the competitor customer product
  //  */
  // deleteCompetitorProduct(idProduct: string|number): void {
  //   this.subscriptions.add(this.irFormService.deleteCustomerCompetitorProduct(idProduct).subscribe({
  //     complete: () => this.getProjectEntities(),
  //     error: (err: HttpErrorResponse) => {
  //       this.getProjectEntities();
  //       throw err;
  //     }
  //   }));
  // }

  saveCustomerDescriptions(customerDescriptions: CustomerDescriptions): void {
    const afterPromise = {
      complete: () => this.refreshCustomerDescriptions(),
      error: (err: HttpErrorResponse) => {
        throw err;
      },
    };
    if (customerDescriptions.idCustomerInfoDescription) {
      this.subscriptions.add(
        this.irFormService
          .updateCustomerDescription(
            this.project.idProject,
            customerDescriptions
          )
          .subscribe(afterPromise)
      );
    }
    if (!customerDescriptions.idCustomerInfoDescription) {
      this.subscriptions.add(
        this.irFormService
          .createCustomerDescription(
            this.project.idProject,
            customerDescriptions
          )
          .subscribe(afterPromise)
      );
    }
  }

  refreshCustomerDescriptions(): void {
    this.subscriptions.add(
      this.irFormService
        .getCustomerDescriptionByIdProject(this.project.idProject)
        .subscribe({
          next: (descriptionsResponse: CustomerDescriptions) => {
            this.projectEntities.customerDescription = descriptionsResponse;
            this.emitGetProjectReady(this.projectEntities);
          },
        })
    );
  }

  /*sendIr(): void {
    this.project.idProjectStatus = RI_STATUS.RiDelivered;
    this.subscriptions.add(this.irFormService.updateProject(this.project).subscribe());
    this.router.navigate(['/ir-sent']);
  }*/

  notesAreUnchecked(): boolean {
    let unchecked: boolean = false;
    for (const tab of Object.keys(this.splittedNotes)) {
      this.splittedNotes[tab].forEach((note: Note) => {
        if (note.checked === false) {
          unchecked = true;
        }
      });
    }
    return unchecked;
  }

  /* DEPRECATED: il RI non deve più essere visualizzato ma direttamente inviato */
  /*viewIr(): void {
    if (this.customerExport === true) {
      if (this.soldProducts.length >= 1) {
        this.router.navigate([`${APP_CONTEX}/ir-to-pdf`, this.project.idProject]);
      } else {
        this.common.showToast(this.translations.SoldProductError, ToastStatus.error, 4000);
      }
    } else {
      this.router.navigate([`${APP_CONTEX}/ir-to-pdf`, this.project.idProject]);
    }
  }*/

  checkAuthCustomerForSendingProject(): boolean {
    const soldProducts: IProductsServices[] = [];
    this.projectEntities.productsServices.forEach((product) => {
      if (product.idProductType === 1) {
        if (product.productCode) {
          soldProducts.push(product);
        }
      }
    });
    if (this.projectEntities?.customerInformation?.hasExport === true) {
      if (soldProducts.length >= 1) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  sendIr(): void {
    const dialogConfig = new MatDialogConfig();
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;
    const aTabIsDirty: any = this.checkIfCurrentTabIsDirty();
    if (this.productsServices.checkInvalidForm()) {
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant(
          'IR_FORM.TheProductListMustContainAtLeastOneProductSoldAndOnePurchased'
        ),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.Ok'),
      };
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    } else if (!this.markets.checkAtLeastOnePresenceMarket()) {
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant(
          'IR_FORM.ThePresenceMarketsListMustContainAtLeastOneMarket'
        ),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.Ok'),
      };
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    } else if (!this.markets.checkIfPresenceMarketsPercentageIs100()) {
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant(
          'IR_FORM.TheTurnoverOfPresenceMarketsIsNotEqualTo100'
        ),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.Ok'),
      };
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    } else if (!this.distribution.checkIfCurrentChannelsPercentageIs100()) {
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant(
          'IR_FORM.CurrentDistributionChannelsRevenueNotEqualTo100'
        ),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.Ok'),
      };
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    } else if (!this.referenceCompetitors.checkAtLeastOneNationalCompetitor()) {
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant(
          'IR_FORM.AtLeastOneNationalCompetitor'
        ),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.Ok'),
      };
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    } else {
      if (aTabIsDirty.bool) {
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.width = '600px';
        dialogConfig.panelClass = 'custom-dialog-container';
        dialogConfig.data = {
          title: this.translate.instant('COMMON.Attention'),
          message: this.translate.instant('IR_FORM.UnsavedDataInFormSection', {
            section: this.translate.instant(
              'INVALID_TABS.' + aTabIsDirty.tabName
            ),
          }),
          messageClass: 'error-color mb-0 fw-700',
          buttonTrue: this.translate.instant('COMMON.Stay'),
          buttonFalse: this.translate.instant('COMMON.Continue'),
        };
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((dirtyTabResult: boolean) => {
          if (!dirtyTabResult) {
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = false;
            dialogConfig.panelClass = 'custom-dialog-container';
            dialogConfig.width = '400px';
            if (this.checkWhichTabsAreInvalids().length) {
              dialogConfig.data = {
                title: this.translations?.Attention,
                message: `${this.translations.TheFollowingSectionsAreIncomplete}:`,
                messageClass: 'error-color fw-700',
                secondMessage: this.checkWhichTabsAreInvalids().join(', '),
                secondMessageClass: 'fw-700',
              };
            } else {
              // Check if customer coordinates is setted
              if (!this.customer?.customer?.address?.position?.length) {
                dialogConfig.data = {
                  title: this.translations?.Attention,
                  message:
                    this.translations.MissingCoordinatesForTheAddressCompany,
                };
              } else {
                if (
                  this.isAgentSupervisor ||
                  (this.isLabManager &&
                    (this.project.idProjectStatus === RI_STATUS.IspDelivered ||
                      this.project.idProjectStatus >=
                        RI_STATUS.IspDelivered2)) ||
                  this.isLabManagerSupervisor
                ) {
                  dialogConfig.data = {
                    title: this.translations?.Attention,
                    message: this.isIsp
                      ? this.translations?.ApproveIspMessageConfirmation
                      : this.translations?.ApproveIrMessageConfirmation,
                    buttonTrue: this.translations.Approve,
                    buttonFalse: this.translations.Reject,
                  };
                } else if (
                  this.isAgent ||
                  this.isLabManager ||
                  this.isLabManagerInternal
                ) {
                  dialogConfig.data = {
                    title: this.translations?.Attention,
                    message: this.isIsp
                      ? this.translations?.SendIspMessageConfirmation
                      : this.translations?.SendIrMessageConfirmation,
                    buttonTrue: this.translations.Send,
                    buttonFalse: this.translate.instant('COMMON.Cancel'),
                  };
                }
              }
            }
            dialogRef = this.dialog.open(
              ConfirmationDialogComponent,
              dialogConfig
            );
            this.subscriptions.add(
              dialogRef.afterClosed().subscribe((invalidTabResult) => {
                if (invalidTabResult) {
                  if (this.checkAuthCustomerForSendingProject()) {
                    if (this.isIsp) {
                      this.subscriptions.add(
                        this.irFormService
                          .sendIsp(this.project.idProject)
                          .subscribe({
                            complete: () => {
                              this.router.navigate([
                                `${APP_CONTEX}/assessment`,
                              ]);
                            },
                          })
                      );
                    } else {
                      this.subscriptions.add(
                        this.irFormService
                          .sendIr(this.project.idProject)
                          .subscribe({
                            complete: () => {
                              this.router.navigate([
                                `${APP_CONTEX}/assessment`,
                              ]);
                            },
                            error: (err: HttpErrorResponse) => {
                              if (err.error.errors.message === 'US013') {
                                this.common.showToast(
                                  this.translate.instant('API_RESPONSE.US013'),
                                  ToastStatus.error,
                                  3000
                                );
                              }
                            },
                          })
                      );
                    }
                  } else {
                    this.common.showToast(
                      this.translations.SoldProductError,
                      ToastStatus.error,
                      4000
                    );
                  }
                }
              })
            );
          }
        });
      } else {
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = false;
        dialogConfig.panelClass = 'custom-dialog-container';
        dialogConfig.width = '400px';
        if (this.checkWhichTabsAreInvalids().length) {
          dialogConfig.data = {
            title: this.translations?.Attention,
            message: `${this.translations.TheFollowingSectionsAreIncomplete}:`,
            messageClass: 'error-color fw-700',
            secondMessage: this.checkWhichTabsAreInvalids().join(', '),
            secondMessageClass: 'fw-700',
          };
        } else {
          if (!this.customer?.customer?.address?.position?.length) {
            dialogConfig.data = {
              title: this.translations?.Attention,
              message: this.translations.MissingCoordinatesForTheAddressCompany,
            };
          } else {
            if (
              this.isAgentSupervisor ||
              (this.isLabManager &&
                (this.project.idProjectStatus === RI_STATUS.IspDelivered ||
                  this.project.idProjectStatus >= RI_STATUS.IspDelivered2)) ||
              this.isLabManagerSupervisor
            ) {
              dialogConfig.data = {
                title: this.translations?.Attention,
                message: this.isIsp
                  ? this.translations?.ApproveIspMessageConfirmation
                  : this.translations?.ApproveIrMessageConfirmation,
                buttonTrue: this.translations.Approve,
                buttonFalse: this.translations.Reject,
              };
            } else if (
              this.isAgent ||
              this.isLabManager ||
              this.isLabManagerInternal
            ) {
              dialogConfig.data = {
                title: this.translations?.Attention,
                message: this.isIsp
                  ? this.translations?.SendIspMessageConfirmation
                  : this.translations?.SendIrMessageConfirmation,
                buttonTrue: this.translations.Send,
                buttonFalse: this.translate.instant('COMMON.Cancel'),
              };
            }
          }
        }
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        this.subscriptions.add(
          dialogRef.afterClosed().subscribe((invalidTabResult) => {
            if (invalidTabResult) {
              if (this.checkAuthCustomerForSendingProject()) {
                if (this.isIsp) {
                  this.subscriptions.add(
                    this.irFormService
                      .sendIsp(this.project.idProject)
                      .subscribe({
                        complete: () => {
                          this.router.navigate([`${APP_CONTEX}/assessment`]);
                        },
                      })
                  );
                } else {
                  this.subscriptions.add(
                    this.irFormService
                      .sendIr(this.project.idProject)
                      .subscribe({
                        complete: () => {
                          this.router.navigate([`${APP_CONTEX}/assessment`]);
                        },
                      })
                  );
                }
              } else {
                this.common.showToast(
                  this.translations.SoldProductError,
                  ToastStatus.error,
                  4000
                );
              }
            }
          })
        );
      }
    }
  }

  sendIsp(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    if (this.isManagerAndSupervisor) {
      dialogConfig.data = {
        title: this.translations?.Attention,
        message: this.isIsp
          ? this.translations?.ApproveIspMessageConfirmation
          : this.translations?.ApproveIrMessageConfirmation,
        centerTextMessage: true,
      };
    } else {
      dialogConfig.data = {
        title: this.translations?.Attention,
        message: this.isIsp
          ? this.translations?.SendIspMessageConfirmation
          : this.translations?.SendIrMessageConfirmation,
        centerTextMessage: true,
      };
    }
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (this.checkAuthCustomerForSendingProject()) {
            this.subscriptions.add(
              this.irFormService.sendIsp(this.project.idProject).subscribe({
                complete: () => {
                  if (this.isManagerAndSupervisor) {
                    this.router.navigate([`${APP_CONTEX}/isp-approved`]);
                  } else {
                    this.router.navigate([`${APP_CONTEX}/isp-sent`]);
                  }
                },
              })
            );
          } else {
            this.common.showToast(
              this.translations.SoldProductError,
              ToastStatus.error,
              4000
            );
          }
        }
      })
    );
  }

  rejectIr(): void {
    if (this.project.idProjectStatus >= RI_STATUS.IspWorking) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.width = '400px';
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        title: this.translations?.Attention,
        message: this.translations.RejectIspMessageConfirmation,
        buttonTrue: this.translations.Yes,
        buttonFalse: this.translations.No,
      };
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      this.subscriptions.add(
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService.rejectIsp(this.project.idProject).subscribe({
                complete: () => {
                  this.router.navigate([`${APP_CONTEX}/assessment`]);
                },
              })
            );
          }
        })
      );
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.width = '400px';
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        title: this.translations?.Attention,
        message: this.translations.RejectIrMessageConfirmation,
        buttonTrue: this.translations.Yes,
        buttonFalse: this.translations.No,
      };
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      this.subscriptions.add(
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService.rejectIr(this.project.idProject).subscribe({
                complete: () => {
                  this.router.navigate([`${APP_CONTEX}/assessment`]);
                },
              })
            );
          }
        })
      );
    }
  }

  rejectIsp(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations.RejectIspMessageConfirmation,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.subscriptions.add(
            this.irFormService.rejectIsp(this.project.idProject).subscribe({
              complete: () => {
                this.router.navigate([`${APP_CONTEX}/isp-to-approve`]);
              },
            })
          );
        }
      })
    );
  }

  approveIr(): void {
    const invalidTabsName: string[] = [];
    Object.keys(this.invalidTabs).forEach((tab: string) => {
      if (this.invalidTabs[tab] === true) {
        invalidTabsName.push(this.translate.instant('INVALID_TABS.' + tab));
      }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    if (invalidTabsName.length) {
      dialogConfig.data = {
        title: this.translations?.Attention,
        message: `${this.translations.TheFollowingSectionsAreIncomplete}:`,
        messageClass: 'error-color fw-700',
        secondMessage: invalidTabsName.join(', '),
        secondMessageClass: 'fw-700',
      };
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
    } else {
      if (this.project.idProjectStatus >= RI_STATUS.IspWorking) {
        dialogConfig.data = {
          title: this.translations?.Attention,
          message: this.translations.ApproveIspMessageConfirmation,
          buttonTrue: this.translations.Yes,
          buttonFalse: this.translations.No,
        };
        const dialogRef = this.dialog.open(
          ConfirmationDialogComponent,
          dialogConfig
        );
        this.subscriptions.add(
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.subscriptions.add(
                this.irFormService
                  .approveIsp(this.project.idProject)
                  .subscribe({
                    complete: () => {
                      this.router.navigate([`${APP_CONTEX}/assessment`]);
                    },
                  })
              );
            }
          })
        );
      } else {
        dialogConfig.data = {
          title: this.translations.Attention,
          message: this.translations.ApproveIrMessageConfirmation,
          buttonTrue: this.translations.Yes,
          buttonFalse: this.translations.No,
        };
        const dialogRef = this.dialog.open(
          ConfirmationDialogComponent,
          dialogConfig
        );
        this.subscriptions.add(
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.subscriptions.add(
                this.irFormService.approveIr(this.project.idProject).subscribe({
                  complete: () => {
                    this.router.navigate([`${APP_CONTEX}/assessment`]);
                  },
                })
              );
            }
          })
        );
      }
    }
  }

  approveIsp(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations.ApproveIspMessageConfirmation,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.subscriptions.add(
            this.irFormService.approveIsp(this.project.idProject).subscribe({
              complete: () => {
                this.router.navigate([`${APP_CONTEX}/isp-to-approve`]);
              },
            })
          );
        }
      })
    );
  }

  createUpdateNotes(note: Note[]): void {
    this.saveNotesLoader = true;
    this.subscriptions.add(
      this.irFormService
        .createUpdateSupervisorNotes(this.project.idProject, note, true)
        .subscribe({
          complete: () => {
            this.getSupervisorNotes();
            this.saveNotesLoader = false;
          },
        })
    );
  }

  deleteNote(id: number): void {
    this.subscriptions.add(
      this.irFormService.deleteSupervisorNote(id, true).subscribe({
        complete: () => {
          this.getSupervisorNotes();
        },
      })
    );
  }

  childOnChecked(event: any): void {
    const noteArray: Note[] = [];
    event.note.checked = true;
    noteArray.push(event.note);
    this.irFormService
      .checkNote(this.project.idProject, noteArray, true)
      .subscribe({
        error: (err) => {
          this.getUncheckedNotes();
          throw err;
        },
      });
  }

  getUncheckedNotes(): void {
    if (!this.isReadOnly && !this.isSupervisor && !this.isProjectSupervisor) {
      this.subscriptions.add(
        this.irFormService
          .getSuperVisorNotes(this.project.idProject, true)
          .subscribe((notes: Note[]) => {
            this.splittedNotes = SplitUncheckedNotesArray(notes);
          })
      );
    }
  }

  // deleteProduct(idProduct: number): void {
  //   this.subscriptions.add(this.irFormService.deleteProduct(idProduct).subscribe({
  //     complete: () => this.getProjectEntities(),
  //     error: (err: HttpErrorResponse) => {
  //       this.getProjectEntities();
  //       throw err;
  //     }
  //   }));
  // }

  // deleteProductCompany(idCompany: number): void {
  //   this.subscriptions.add(this.irFormService.deleteProductCompany(idCompany).subscribe({
  //     complete: () => this.getProjectEntities(),
  //     error: (err: HttpErrorResponse) => {
  //       this.getProjectEntities();
  //       throw err;
  //     }
  //   }));
  // }

  updateLeadGeneration(leadGeneration: ILeadGeneration): void {
    this.subscriptions.add(
      this.irFormService
        .updateLeadGeneration(this.project.idCustomer, leadGeneration)
        .subscribe({
          complete: () => {
            this.layoutService.scrollToTop();
            this.refreshLeadGeneration();
          },
          error: (err) => {
            throw err;
          },
        })
    );
  }

  refreshLeadGeneration(): void {
    this.subscriptions.add(
      this.irFormService
        .getLeadGenerationByIdCustomer(this.project.idCustomer)
        .subscribe({
          next: (leadGenerationResponse: ILeadGeneration) => {
            this.projectEntities.leadGeneration = leadGenerationResponse;
            this.emitGetProjectReady(this.projectEntities);
          },
        })
    );
  }

  // deleteLeadActivity(idLeadGeneration: number): void {
  //   this.subscriptions.add(this.irFormService.deleteLeadGenerationActivity(idLeadGeneration).subscribe({
  //     complete: () => this.getProjectEntities(),
  //     error: (err: HttpErrorResponse) => {
  //       this.getProjectEntities();
  //       throw err;
  //     }
  //   }));
  // }

  // deleteLeadCompany(idCompanyLead: number): void {
  //   this.subscriptions.add(this.irFormService.deleteLeadGenerationCompany(idCompanyLead).subscribe({
  //     complete: () => this.getProjectEntities()
  //   }));
  // }

  // deleteLeadProfile(idLeadProfile: number): void {
  //   this.subscriptions.add(this.irFormService.deleteLeadGenerationProfile(idLeadProfile).subscribe({
  //     complete: () => this.getProjectEntities()
  //   }));
  // }

  // deleteActivityProfileEmitter(idLeadProfileActivity: number): void {
  //   this.subscriptions.add(this.irFormService.deleteLeadGenerationProfileActivity(idLeadProfileActivity).subscribe({
  //     complete: () => this.getProjectEntities()
  //   }));
  // }

  // addProductMatchingServices({ body, dialogRef }): void {
  //   this.subscriptions.add(this.irFormService.addProductMatchingServices(body).subscribe({
  //     complete: () => {
  //       dialogRef.close();
  //       // this.getProjectEntities();
  //     },
  //     error: (err: HttpErrorResponse) => {
  //       throw err;
  //     }
  //   }));
  // }

  // deleteProductMatchingEmitter(idProductMatching: number): void {
  //   this.subscriptions.add(this.irFormService.deleteProductMatchingEmitter(idProductMatching, true ).subscribe());
  // }

  // TODO: cambiare in dinamici i parametri della chiamata
  searchCodeMarkestStrategyAnalysis(description: string): void {
    this.subscriptions.add(
      this.irFormService
        .getCN8(description, 'IT', 0, 10)
        .subscribe((cnProducts: ICnProductsService) => {
          this.productsForAnalysis = cnProducts;
        })
    );
  }

  changePageMarkestStrategyAnalysis(options: any): void {
    this.subscriptions.add(
      this.irFormService
        .getCN8(
          options.description,
          'IT',
          options.pageIndex,
          options.itemsPerPage
        )
        .subscribe((cnProducts: ICnProductsService) => {
          this.productsForAnalysis = cnProducts;
        })
    );
  }

  // getInterchangeTypologyData(options: any): void {
  //   this.subscriptions.add(
  //     this.irFormService.getInterchangeTypologyData(
  //       options.cnCode,
  //       options.interchangeTypology,
  //       this.project.idCustomer,
  //       options.yearEnd
  //     ).subscribe((interchangeData: IInterchangeData[]) => {
  //       this.interchangeTypologyData = interchangeData;
  //     })
  //   );
  // }

  saveMarketAnalysis(analysis: MarketsAnalysisResponse): void {
    this.subscriptions.add(
      this.irFormService
        .saveMarketsAnalysis(this.project.idProject, analysis)
        .subscribe({
          complete: () => this.refreshMarketAnalysis(),
          error: (err) => {
            throw err;
          },
        })
    );
  }

  refreshMarketAnalysis(): void {
    this.subscriptions.add(
      this.irFormService.getMarketsAnalysis(this.project.idProject).subscribe({
        next: (marketAnalysisResponse: MarketsAnalysisResponse) => {
          this.projectEntities.marketAnalysis = marketAnalysisResponse;
          if (marketAnalysisResponse) {
            this.subscriptions.add(
              this.irFormService
                .getInterchangeTypologyData(
                  this.projectEntities.marketAnalysis.marketAnalysis.productCN8,
                  this.projectEntities.marketAnalysis.marketAnalysis
                    .interchangeTypology,
                  this.projectEntities.customer.idCustomer,
                  this.projectEntities.marketAnalysis.marketAnalysis.yearEnd
                )
                .subscribe({
                  next: (
                    interchangeTypologyDataResponse: IInterchangeData[]
                  ) => {
                    this.projectEntities.interchangeTypologyData =
                      interchangeTypologyDataResponse;
                    this.refreshMatrixMarketsAnalysis();
                  },
                })
            );
          } else {
            this.refreshMatrixMarketsAnalysis();
          }
        },
      })
    );
  }

  deleteStrategy(): void {
    this.subscriptions.add(
      this.irFormService.deleteStrategy(this.project.idProject).subscribe({
        complete: () => {
          this.refreshStrategy();
          this.refreshMarketAnalysis(), this.refreshProductsServices();
        },
        error: (err) => {
          throw err;
        },
      })
    );
  }

  // getMatrixMarketAnalysis(): void {
  //   this.subscriptions.add(
  //     this.irFormService.getMatrixMarketsAnalysis(
  //       this.project.idCustomer, this.authService.userProfile.idUser
  //     ).subscribe((data: IMatrixMarketsAnalysis) => {
  //       this.matrixMarketAnalysis = data;
  //     })
  //   );
  // }

  saveMatrixMarketsAnalysis(matrix: IMatrixMarketsAnalysis): void {
    this.subscriptions.add(
      this.irFormService
        .saveMatrixMarketsAnalysis(this.project.idProject, matrix)
        .subscribe({
          complete: () => {
            this.layoutService.scrollToTop();
            this.refreshMatrixMarketsAnalysis();
            this.refreshStrategy();
          },
          error: (err) => {
            throw err;
          },
        })
    );
  }

  refreshMatrixMarketsAnalysis(): void {
    this.subscriptions.add(
      this.irFormService
        .getMatrixMarketsAnalysis(this.project.idProject)
        .subscribe({
          next: (matrixMarketAnalysisResponse: IMatrixMarketsAnalysis) => {
            this.projectEntities.matrixMarketAnalysis =
              matrixMarketAnalysisResponse;
            this.emitGetProjectReady(this.projectEntities);
          },
        })
    );
  }

  // deleteIndexMarketObjective(idIndexType: number): void {
  //   this.subscriptions.add(
  //     this.irFormService.deleteIndexMarketObjective(
  //       this.project.idProject, idIndexType
  //     ).subscribe({
  //       complete: () => {
  //         this.getProjectEntities();
  //       }
  //     })
  //   );
  // }

  saveStrategy(strategy: ICustomerStrategy): void {
    this.subscriptions.add(
      this.irFormService
        .saveStrategy(this.project.idProject, strategy)
        .subscribe({
          complete: () => {
            this.layoutService.scrollToTop();
            this.refreshStrategy();
          },
          error: (err: HttpErrorResponse) => {
            throw err;
          },
        })
    );
  }

  refreshStrategy(): void {
    this.subscriptions.add(
      this.irFormService.getStrategy(this.project.idProject, 9).subscribe({
        next: (strategyResponse: ICustomerStrategy) => {
          this.projectEntities.strategy = strategyResponse;
          this.emitGetProjectReady(this.projectEntities);
        },
      })
    );
  }

  checkIfCurrentTabIsDirty(): any {
    const result: { bool: boolean; tabName: string; prevIndex: number } = {
      bool: null,
      tabName: null,
      prevIndex: this.index,
    };
    if (this.isNoContract) {
      switch (result.prevIndex) {
        case 0:
          if (this.customer.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'customer';
          }
          break;
        case 1:
          if (this.customerInformation.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'customerInformation';
          }
          break;
        case 2:
          if (this.productsServices.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'productsServices';
          } else {
            this.productsServices.contentDetail = false;
            this.productsServices.contentsList = true;
            this.productsServices.resetContentDetail();
          }
          break;
      }
    }
    if (!this.isIsp && !this.isNoContract) {
      switch (result.prevIndex) {
        case 0:
          if (this.customer.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'customer';
          }
          break;
        case 1:
          if (this.customerInformation.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'customerInformation';
          }
          break;
        case 2:
          if (this.contract.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'contract';
          }
          break;
        case 3:
          if (this.productsServices.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'productsServices';
          } else {
            this.productsServices.contentDetail = false;
            this.productsServices.contentsList = true;
            this.productsServices.resetContentDetail();
          }
          break;
        case 4:
          if (this.markets.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'market';
          }
          break;
        case 5:
          if (this.distribution.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'channels';
          }
          break;
        case 6:
          if (this.referenceCompetitors.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'referenceCompetitors';
          }
          break;
        case 7:
          if (this.notes.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'notes';
          }
          break;
      }
    }
    if (this.isIsp) {
      switch (result.prevIndex) {
        case 0:
          if (this.customer.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'customer';
          }
          break;
        case 1:
          if (this.customerInformation.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'customerInformation';
          }
          break;
        case 2:
          if (this.contract.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'contract';
          }
          break;
        case 3:
          if (this.productsServices.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'productsServices';
          } else {
            this.productsServices.contentDetail = false;
            this.productsServices.contentsList = true;
            this.productsServices.resetContentDetail();
          }
          break;
        case 4:
          if (this.markets.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'market';
          }
          break;
        case 5:
          if (this.distribution.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'channels';
          }
          break;
        case 6:
          if (this.referenceCompetitors.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'referenceCompetitors';
          }
          break;
        case 7:
          if (this.swot.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'swot';
          }
          break;
        case 8:
          if (this.leadGeneration.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'leadGeneration';
          }
          break;
        case 9:
          if (this.marketsAnalysis.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'marketsAnalysis';
          }
          break;
        case 10:
          if (this.strategy.checkDirtyForm()) {
            result.bool = true;
            result.tabName = 'strategy';
          }
          break;
      }
    }
    return result;
    // TODO: rimuovere i result se non si usa la funzione sul click di invia IR-PS
    // const previuosIndex: number = this.index;
    // let tabsToUse: string[];
    // if (this.isIsp) {
    //   tabsToUse = this.tabsPs;
    // }
    // if (!this.isIsp && !this.isNoContract) {
    //   tabsToUse = this.tabsAss;
    // }
    // if (this.isNoContract) {
    //   tabsToUse = this.tabsNoContract;
    // }
    // if (this.dirtyTabs[tabsToUse[this.index]]) {
    //   const dialogConfig = new MatDialogConfig();
    //   dialogConfig.disableClose = true;
    //   dialogConfig.autoFocus = false;
    //   dialogConfig.width = '600px';
    //   dialogConfig.panelClass = 'custom-dialog-container';
    //   dialogConfig.data = {
    //     title: this.translate.instant('COMMON.Attention'),
    //     message: this.translate.instant('IR_FORM.UnsavedDataInFormSection', {
    //       section: this.translate.instant(
    //         'INVALID_TABS.' + tabsToUse[this.index]
    //       ),
    //     }),
    //     messageClass: 'error-color mb-0 fw-700',
    //     buttonTrue:
    //       eventType === 'clickSendProject'
    //         ? this.translate.instant('COMMON.Stay')
    //         : this.translate.instant('COMMON.GoBack'),
    //     buttonFalse: this.translate.instant('COMMON.Continue'),
    //   };
    //   const dialogRef: MatDialogRef<ConfirmationDialogComponent, any> =
    //     this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    //   dialogRef.afterClosed().subscribe((result: boolean) => {
    //     if (result) {
    //       this.index = previuosIndex;
    //     } else {
    //       console.log('false');
    //       this.checkDirtyTabBeforeSendProject.subscribe(() => {});
    //       return false;
    //     }
    //   });
    //   return true;
    // }
    // return false;
  }

  checkWhichTabsAreInvalids(): string[] {
    const invalidTabs: string[] = [];
    if (this.customer.checkInvalidForm()) {
      invalidTabs.push(this.translate.instant('INVALID_TABS.' + 'customer'));
    }
    if (this.customerInformation.checkInvalidForm()) {
      invalidTabs.push(
        this.translate.instant('INVALID_TABS.' + 'customerInformation')
      );
    }
    if (!this.isNoContract) {
      if (this.contract.checkInvalidForm()) {
        invalidTabs.push(this.translate.instant('INVALID_TABS.' + 'contract'));
      }
    }
    if (this.productsServices.checkInvalidForm()) {
      invalidTabs.push(
        this.translate.instant('INVALID_TABS.' + 'productServices')
      );
    }
    if (!this.isNoContract) {
      if (this.markets.checkInvalidForm()) {
        invalidTabs.push(this.translate.instant('INVALID_TABS.' + 'market'));
      }
      if (this.distribution.checkInvalidForm()) {
        invalidTabs.push(this.translate.instant('INVALID_TABS.' + 'channels'));
      }
      if (this.referenceCompetitors.checkInvalidForm()) {
        invalidTabs.push(
          this.translate.instant('INVALID_TABS.' + 'referenceCompetitors')
        );
      }
      if (this.notes?.checkInvalidForm()) {
        invalidTabs.push(
          this.translate.instant('INVALID_TABS.' + 'note')
        );
      }
      if (this.isIsp) {
        if (this.leadGeneration.checkInvalidForm()) {
          invalidTabs.push(
            this.translate.instant('INVALID_TABS.' + 'leadGeneration')
          );
        }
        if (this.swot.checkInvalidForm()) {
          invalidTabs.push(this.translate.instant('INVALID_TABS.' + 'swot'));
        }
        if (this.marketsAnalysis.checkInvalidForm()) {
          invalidTabs.push(
            this.translate.instant('INVALID_TABS.' + 'marketsAnalysis')
          );
        }
        if (this.strategy.checkInvalidForm()) {
          invalidTabs.push(
            this.translate.instant('INVALID_TABS.' + 'strategy')
          );
        }
      }
    }
    return invalidTabs;
  }

  showDialogCurrentTabDirty(): void {
    const result = this.checkIfCurrentTabIsDirty();
    if (result.bool) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant('IR_FORM.UnsavedDataInFormSection', {
          section: this.translate.instant('INVALID_TABS.' + result.tabName),
        }),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.GoBack'),
        buttonFalse: this.translate.instant('COMMON.Continue'),
      };
      const dialogRef: MatDialogRef<ConfirmationDialogComponent, any> =
        this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this.index = result.prevIndex;
        } else {
          if (this.productsServices.contentDetail) {
            this.productsServices.contentDetail = false;
            this.productsServices.contentsList = true;
            this.productsServices.resetContentDetail();
          }
        }
      });
    }
  }

  checkIfProductsAreInvalid(): boolean {
    if (!this.isSupervisor && !this.isReadOnly) {
      const prevIndex: number = this.index;
      if (this.isNoContract) {
        if (prevIndex === 2) {
          if (this.productsServices.checkInvalidForm()) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        if (prevIndex === 3) {
          if (this.productsServices.checkInvalidForm()) {
            return true;
          } else {
            return false;
          }
        }
      }
    }
  }

  showDialogIfProductsAreInvalid(): void {
    if (this.checkIfProductsAreInvalid()) {
      const prevIndex: number = this.index;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '600px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant(
          'IR_FORM.TheProductListMustContainAtLeastOneProductSoldAndOnePurchased'
        ),
        messageClass: 'error-color mb-0 fw-700',
        buttonTrue: this.translate.instant('COMMON.GoBack'),
      };
      const dialogRef: MatDialogRef<ConfirmationDialogComponent, any> =
        this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((dialogResult: boolean) => {
        if (dialogResult) {
          this.index = prevIndex;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
