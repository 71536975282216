import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LoggedUser, SignUpRequest } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();

  translations: any;
  token: string;
  user: LoggedUser;
  name: string = '';
  surname: string = '';

  constructor(
    private router: Router,
    private activetedRouter: ActivatedRoute,
    private jwtHelper: JwtHelperService,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('USERS_MANAGEMENT').subscribe((translations) => {
        this.translations = translations;
        this.subscriptions.add(
          this.activetedRouter.queryParams.subscribe((params) => {
            this.token = params.token;
            console.log(this.token);
            this.user = this.jwtHelper.decodeToken(this.token);
            this.name = this.user.name;
            this.surname = this.user.surname;
          })
        );
      })
    );
  }

  confirmSignUp(account: LoggedUser): void {
    const signup: SignUpRequest = {
      ...account,
      username: account.email,
      jwt: this.token,
    };
    this.subscriptions.add(
      this.authService.confirmSignUp(signup).subscribe({
        complete: () => {
          this.router.navigate(['/login']);
        },
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
