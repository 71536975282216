<div class="page-wrapper">
  <div class="title-message-container">
    <div class="title">
      <span>{{ user.email }}</span>
    </div>
    <div class="message">
      <span>{{ 'RESET_PASSWORD.InsertNewPassword' | translate }}</span>
    </div>
  </div>
  <div class="reset-password-form-container">
    <mat-card class="mat-elevation-z8">
      <form [formGroup]="formResetPassword"> 
        <mat-form-field class="form-field">          
          <mat-label for="password">{{ 'COMMON.Password' | translate }}</mat-label>
          <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" name="password">
          <mat-error *ngIf="!formResetPassword.get('password').valid">{{ formResetPassword.get('password').errors | getErrorMessage:'Password' }}</mat-error>
          <button mat-icon-button matSuffix (click)="hide = !hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field>  
        <mat-form-field class="form-field">          
          <mat-label for="confirmPassword">{{ 'RESET_PASSWORD.ConfirmPassword' | translate }}</mat-label>
          <input matInput formControlName="confirmPassword" [type]="hideTwo ? 'password' : 'text'" name="confirmPassword">      
          <mat-error *ngIf="!formResetPassword.get('confirmPassword').valid">{{ formResetPassword.get('confirmPassword').errors | getErrorMessage:'Password' }}</mat-error>
          <button mat-icon-button matSuffix (click)="hideTwo = !hideTwo">
            <mat-icon>{{hideTwo ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
        </mat-form-field> 
        <button 
          mat-raised-button 
          color="primary" 
          class="submit-form-button app-bg-color" 
          [disabled]="!formResetPassword.valid" 
          (click)="onSubmit()"
        >
          {{ 'COMMON.Confirm' | translate }}
        </button>
      </form>
    </mat-card>
  </div>
</div>