import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  disabledValue: boolean;

  @Input() placeholder: string = 'Search';
  @Output() emitSearchValue: EventEmitter<string> = new EventEmitter();
  @Input()
  set disabled(value: boolean) {
    this.disabledValue = value;
  }
  get disabled(): boolean {
    return this.disabledValue;
  }

  constructor() { }

  ngOnInit(): void {}

  searchValue(value: string): void {
    this.emitSearchValue.emit(value);
  }

}
