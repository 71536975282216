<div *ngIf="isAgentSupervisor" class="section-card mb-30">
  <div class="left-element-70">
    <div class="general-info-agent-supervisor">
      <div class="general-info">
        <div>
          {{ "COMMON.FilterBy" | translate }}
        </div>
      </div>
      <div>
        <mat-form-field style="width: 300px; top: 10px">
          <mat-label for="filterData">{{
            "COMMON.Agents" | translate
          }}</mat-label>
          <mat-select
            [formControl]="filterStatisticsAgent"
            (selectionChange)="reinitializeAgentSupervisorData($event)"
          >
            <mat-option value="entireSalesForce">
              {{ "COMMON.AllTheAgents" | translate }}
            </mat-option>
            <mat-option
              *ngFor="let agent of agentsManagedBySupervisor"
              [value]="agent.idUser"
            >
              {{ agent.name }} {{ agent.surname }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          style="width: 300px; top: 10px"
          class="ml-20"
        >
          <mat-label>
            {{ "COMMON.Date" | translate }}
          </mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input
              readonly
              matStartDate
              matInput
              [formControl]="filterStatisticsStartDate"
              placeholder="{{ 'COMMON.From' | translate }}"
            />
            <input
              readonly
              matEndDate
              matInput
              [formControl]="filterStatisticsEndDate"
              placeholder="{{ 'COMMON.to' | translate }}"
              (dateChange)="reinitializeAgentSupervisorData($event)"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAgent || isAgentSupervisor" class="row">
  <div class="col-3">
    <div class="section-card h100">
      <div class="header h70">
        <div class="title">
          <span>{{ "HOME.AppointmentsCalendar" | translate }}</span>
        </div>
      </div>
      <div class="content">
        <app-custom-calendar [appointments]="meetings"></app-custom-calendar>
      </div>
    </div>
  </div>
  <div class="col-5">
    <div class="section-card h100">
      <div class="header h70 multi">
        <div class="title">
          <span>{{ "HOME.NextAppointments" | translate }}</span>
          <div *ngIf="isAgentSupervisor" class="next-meetings-statistics">
            <span class="fw-700 t-meet">
              {{ agentSupervisorAppointmentsStatistics?.T || "0" }}
            </span>
            <span>
              {{
                agentSupervisorAppointmentsStatistics?.T > 1
                  ? ("COMMON.Telephonics" | translate)
                  : ("COMMON.Telephonic" | translate)
              }}
              |
            </span>
            <span class="fw-700 f-meet">
              {{ agentSupervisorAppointmentsStatistics?.F || "0" }}
            </span>
            <span>
              {{
                agentSupervisorAppointmentsStatistics?.F > 1
                  ? ("COMMON.Personals" | translate)
                  : ("COMMON.Personal" | translate)
              }}
            </span>
          </div>
        </div>
        <div class="legend">
          <div class="item phone">
            <span>{{ "COMMON.Telephonic" | translate }}</span>
          </div>
          <div class="item operator">
            <span>{{ "COMMON.Personal" | translate }}</span>
          </div>
        </div>
      </div>
      <div *ngIf="nextMeetingsLoader" class="content">
        <div class="full-wrapper-center">
          <mat-spinner diameter="40"></mat-spinner>
        </div>
      </div>
      <div *ngIf="!nextMeetings?.length && !nextMeetingsLoader" class="content">
        <div class="full-wrapper-center">
          {{ "COMMON.NoDataAvailable" | translate }}
        </div>
      </div>
      <div
        *ngIf="nextMeetings?.length && !nextMeetingsLoader"
        class="content flex align-content-start"
      >
        <div
          class="item half-width with-arrow appointment"
          *ngFor="let meet of nextMeetings"
          (click)="goToSpecificMeet(meet)"
        >
          <div class="truncate-text-container">
            <div class="date">
              <span>{{ meet?.showStartDate | date: "short" }}</span>
            </div>
            <div *ngIf="isAgentSupervisor" class="agent-name">
              <div>{{ "COMMON.Agent" | translate }}:</div>
              <div class="fw-700 ml-05 truncate-text">
                {{ meet.userAgentName }} {{ meet.userAgentSurname }}
              </div>
            </div>
            <div
              class="name"
              [ngClass]="
                meet?.appointmentType === appointmentType.Telephonic
                  ? 'phone'
                  : 'operator'
              "
            >
              <span
                *ngIf="meet?.customerDataSheet?.address?.province"
                class="name"
              >
                {{ meet?.customerDataSheet?.businessName }} ({{
                  meet?.customerDataSheet?.address?.province
                }})
              </span>
              <span
                *ngIf="!meet?.customerDataSheet?.address?.province"
                class="name"
              >
                {{ meet?.customerDataSheet?.businessName }}
              </span>
              <!-- <span>{{ meet?.customerDataSheet?.businessName }}</span> -->
            </div>
          </div>
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </div>
      <div class="buttons center">
        <button
          class="only-text-button primary fw-700 fs-11"
          type="button"
          (click)="navigateTo(pages.Meetings)"
        >
          {{ "HOME.ViewAllAppointments" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="col-4">
    <div class="section-card h100">
      <div class="header h70">
        <div class="title">
          <span>{{ "HOME.LastRegisteredCustomersUsers" | translate }}</span>
        </div>
      </div>
      <div *ngIf="customersLoader" class="content">
        <div class="full-wrapper-center">
          <mat-spinner diameter="40"></mat-spinner>
        </div>
      </div>
      <div *ngIf="!customers?.length && !customersLoader" class="content">
        <div class="full-wrapper-center">
          {{ "COMMON.NoDataAvailable" | translate }}
        </div>
      </div>
      <div *ngIf="customers?.length && !customersLoader" class="content flex">
        <div
          class="item full-width with-arrow customer"
          *ngFor="let customer of customers"
          (click)="navigateTo(pages.Customers, customer)"
        >
          <div>
            <div class="date-name">
              <span class="date">
                {{ customer?.dateInsert | date: "shortDate" }} -
              </span>
              <span *ngIf="customer?.address?.province" class="name">
                {{ customer?.businessName }} ({{ customer?.address?.province }})
              </span>
              <span *ngIf="!customer?.address?.province" class="name">
                {{ customer?.businessName }}
              </span>
            </div>
            <div *ngIf="!isAgentSupervisor" class="type mb-05">
              <span>{{ "COMMON.Type" | translate }}: </span>
              <span *ngIf="customer?.customerType === 'Active'" class="fw-700">
                {{ "COMMON.Client" | translate | uppercase }}
              </span>
              <span *ngIf="customer?.customerType === 'BM'" class="fw-700">
                {{ "COMMON.User" | translate | uppercase }}
              </span>
            </div>
            <div *ngIf="isAgentSupervisor" class="client-type-agent-name">
              <div class="fw-700">
                {{
                  customer?.customerType === "Active"
                    ? ("COMMON.Client" | translate | uppercase)
                    : ("COMMON.User" | translate | uppercase)
                }}
              </div>
              <span
                *ngIf="filterStatisticsAgent.value === 'entireSalesForce'"
                class="mx-05"
              >
                |
              </span>
              <div *ngIf="filterStatisticsAgent.value === 'entireSalesForce'">
                {{ "COMMON.Agent" | translate }}:
              </div>
              <div
                *ngIf="filterStatisticsAgent.value === 'entireSalesForce'"
                class="fw-700 ml-05"
              >
                {{ customer?.agent?.name }} {{ customer?.agent?.surname }}
              </div>
            </div>
            <div class="address">
              <span>
                {{ customer?.address?.address }}, {{ customer?.address?.city }},
              </span>
            </div>
            <div class="email-phone">
              <span>
                {{ customer?.email }} - {{ customer?.telephoneNumber }}
              </span>
            </div>
          </div>
          <mat-icon>arrow_forward</mat-icon>
        </div>
      </div>
      <div class="buttons center">
        <button
          class="only-text-button primary fw-700 fs-11"
          type="button"
          (click)="navigateTo(pages.Customers)"
        >
          {{ "HOME.ViewAllCustomers" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isAgent || isAgentSupervisor" class="row mt-40">
  <div class="col-12">
    <div class="section-card">
      <div class="header h70 multi">
        <div class="title">
          <span>{{ "HOME.DataRelatingToPeriod" | translate }}</span>
        </div>
        <div class="select-period">
          <mat-form-field appearance="legacy">
            <mat-label>{{ "HOME.Period" | translate }}</mat-label>
            <mat-select
              [(value)]="selectedRangeTime"
              (selectionChange)="
                isAgent
                  ? getUserStatistics($event)
                  : getDashboardGraphStatistics($event)
              "
            >
              <mat-option value="currentYear">
                {{ "HOME.CurrentYear" | translate }}
              </mat-option>
              <mat-option value="lastThreeYears">
                {{ "HOME.LastThreeYears" | translate }}
              </mat-option>
              <mat-option value="fromBeginning">
                {{ "HOME.FromBeginning" | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="content statistics-section">
        <div class="row">
          <div class="col-3">
            <div class="statistics-container">
              <div class="statistic">
                <div class="name">
                  <span>{{ "HOME.AppointmentsDone" | translate }}</span>
                </div>
                <div class="value">
                  <span>{{ userStatistics?.totalAppointment || 0 }}</span>
                </div>
              </div>
              <div class="statistic">
                <div class="name">
                  <span>{{ "HOME.NumberOfBusinessMatching" | translate }}</span>
                </div>
                <div class="value">
                  <span>{{ userStatistics?.totalBM || 0 }}</span>
                </div>
              </div>
              <div class="statistic">
                <div class="name">
                  <span>{{ "HOME.NumberOfContracts" | translate }}</span>
                </div>
                <div class="value">
                  <span>{{ userStatistics?.totalContract || 0 }}</span>
                </div>
              </div>
              <!-- <div class="statistic">
                <div class="name">
                  <span>{{ "HOME.WorkedAssessments" | translate }}</span>
                </div>
                <div class="value">
                  <span>{{ userStatistics?.totalAssessment || 0 }}</span>
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-9">
            <div class="chart-container">
              <div class="title">
                <span>{{ "HOME.Statistics" | translate }}</span>
              </div>
              <div *ngIf="statisticsLoader" class="content">
                <div class="full-wrapper-center">
                  <mat-spinner diameter="40"></mat-spinner>
                </div>
              </div>
              <div
                *ngIf="!statisticsLoader"
                class="chart"
                echarts
                [options]="userStatisticsChartOptions"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="isLabManager || isLabManagerInternal || isLabManagerSupervisor"
  class="row"
>
  <div class="col-6">
    <div class="section-card h100">
      <div class="header h70 multi">
        <div class="title">
          <span>{{ "COMMON.Customers" | translate }}</span>
        </div>
        <div class="search-bar">
          <app-search-bar
            [disabled]="!followedCustomers?.length"
            (emitSearchValue)="searchValue($event, 'followedCustomers')"
          ></app-search-bar>
        </div>
      </div>
      <div class="content">
        <app-table
          [loader]="followedCustomersLoader"
          [columns]="followedCustomersTableColumns"
          [columnsButtons]="followedCustomersTableButtons"
          [data]="followedCustomers"
          [searchValue]="followedCustomersSearchValue"
        ></app-table>
      </div>
    </div>
  </div>
  <div class="col-6">
    <div class="section-card h100">
      <div class="header h70">
        <div>
          <div class="title">
            <span>{{ "COMMON.StatisticsData" | translate }}</span>
          </div>
          <div class="description">
            <span>{{
              "COMMON.StatisticsDataLabManagerDescription" | translate
            }}</span>
          </div>
        </div>
      </div>
      <div class="content statistics-container">
        <div class="row">
          <div class="col-6">
            <div class="statistic h100">
              <div class="name">
                <span>{{ "COMMON.NumberOfActiveCustomers" | translate }}</span>
              </div>
              <div class="value">
                <span>{{ managerStatistics?.activeCustomers || 0 }}</span>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="statistic">
              <div class="name">
                <span>{{ "COMMON.TotalTurnover" | translate }}</span>
              </div>
              <div class="value">
                <span>{{
                  managerStatistics?.totalRevenue || 0
                    | currency
                      : this._defaultCurrencyCode
                      : "symbol"
                      : "1.0-0"
                      : undefined
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-50">
          <div class="col-6">
            <div class="statistic h100">
              <div class="ta-center w100">
                <div class="fs-16">
                  <span>{{
                    "COMMON.NumberOfGeneratedOffers" | translate
                  }}</span>
                </div>
                <div class="fs-26 fw-700">
                  <span>{{ managerStatistics?.totalOffers || 0 }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="statistic">
              <div class="name">
                <span>{{ "COMMON.NumberOfPendingOffers" | translate }}</span>
              </div>
              <div class="value">
                <span>{{ managerStatistics?.pendingOffers || 0 }}</span>
              </div>
            </div>
            <div class="statistic">
              <div class="name">
                <span>{{ "COMMON.NumberOfLostOffers" | translate }}</span>
              </div>
              <div class="value">
                <span>{{ managerStatistics?.lostOffers || 0 }}</span>
              </div>
            </div>
            <div class="statistic">
              <div class="name">
                <span>{{ "COMMON.NumberOfGeneratedOrders" | translate }}</span>
              </div>
              <div class="value">
                <span>{{ managerStatistics?.totalOrders || 0 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  *ngIf="isLabManager || isLabManagerInternal || isLabManagerSupervisor"
  class="section-card mt-30"
>
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "CRM.MandatoryActivities" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!mandatoryActivities?.length"
        (emitSearchValue)="searchValue($event, 'mandatoryActivities')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="mandatoryActivitiesLoader"
      [columns]="mandatoryActivitiesTableColumns"
      [columnsButtons]="mandatoryActivitiesTableButtons"
      [data]="mandatoryActivities"
      [searchValue]="mandatoryActivitiesSearchValue"
    ></app-table>
  </div>
</div>
<div *ngIf="isDirection || isAdmin" class="section-card">
  <div class="header h70">
    <div>
      <div class="title">
        <span>{{ "HOME.InfoRelatedToCurrentYear" | translate }}</span>
      </div>
      <div class="description">
        <span>{{ "HOME.CumulativeForYourEntireNetwork" | translate }}</span>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <div class="col-4">
        <div class="statistics-container">
          <div class="statistic" style="height: 50%">
            <div>
              <div class="name">
                <span>{{ "HOME.TotalRevenue" | translate }}</span>
              </div>
              <div class="value">
                <span>
                  {{
                    top3ManagersData?.totalRevenue || 0
                      | currency
                        : this._defaultCurrencyCode
                        : "symbol"
                        : "1.0-2"
                        : undefined
                  }}
                </span>
              </div>
            </div>
          </div>
          <div class="statistic" style="height: 50%">
            <div>
              <div class="name">
                <span>{{ "HOME.DLManagerNumber" | translate }}</span>
              </div>
              <div class="value">
                <span>{{ top3ManagersData?.totalManagers || 0 }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="top-managers-title">
          {{ "HOME.Top3DLManager" | translate }}
        </div>
        <div class="top-managers-container">
          <div
            *ngFor="let manager of [].constructor(3); let i = index"
            class="top-manager"
          >
            <div class="position">{{ i + 1 }}</div>
            <div class="name">
              {{ top3ManagersData?.topManagers[i]?.name || "-" }}
              {{ top3ManagersData?.topManagers[i]?.surname || "-" }}
            </div>
            <div class="email">
              {{ top3ManagersData?.topManagers[i]?.email || "--" }}
            </div>
            <div class="revenue">
              {{
                top3ManagersData?.topManagers[i]?.totalRevenue || 0
                  | currency
                    : this._defaultCurrencyCode
                    : "symbol"
                    : "1.0-2"
                    : undefined
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="isDirection" class="mt-30">
  <div class="section-card">
    <div class="header h70">
      <div class="title">
        <span>{{ "HOME.ProjectsToAssign" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <app-table
        [loader]="projectsToAssignLoader"
        [columns]="projectsToAssignTableColumns"
        [columnsButtons]="projectsToAssignTableButtons"
        [data]="projectsToAssign"
      ></app-table>
    </div>
  </div>
  <div class="section-card mt-30">
    <div class="header h70">
      <div class="title">
        <span>{{ "HOME.AssignedProjects" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <app-table
        [loader]="assignedProjectsLoader"
        [columns]="assignedProjectsTableColumns"
        [columnsButtons]="assignedProjectsTableButtons"
        [data]="assignedProjects"
      ></app-table>
    </div>
  </div>
</div>
<div *ngIf="isDirection || isAdmin" class="section-card mt-30">
  <div class="header h70 multi">
    <div>
      <div class="title">
        <span>{{ "HOME.DataRelatingToPeriod" | translate }}</span>
      </div>
      <div class="description">
        <span>{{ "HOME.CumulativeForYourEntireNetwork" | translate }}</span>
      </div>
    </div>
    <div class="select-period">
      <mat-form-field appearance="legacy">
        <mat-label>{{ "HOME.Period" | translate }}</mat-label>
        <mat-select
          [(value)]="selectedRangeTime"
          (selectionChange)="getDirectionGrapichStatistics($event)"
        >
          <mat-option value="currentYearPreviousYear">
            {{ "HOME.CurrentYearPreviousYear" | translate }}
          </mat-option>
          <mat-option value="lastThreeYears">
            {{ "HOME.LastThreeYears" | translate }}
          </mat-option>
          <mat-option value="fromBeginning">
            {{ "HOME.FromBeginning" | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
  <div class="content statistics-section">
    <div class="row">
      <div class="col-3">
        <div class="statistics-container py-50">
          <div class="statistic" style="height: 50%">
            <div class="name">
              <span>{{
                "STATISTICS_CHART.AcquiredCustomers" | translate
              }}</span>
            </div>
            <div class="value">
              <span>{{ userStatistics?.totalCustomersAcquired || 0 }}</span>
            </div>
          </div>
          <div class="statistic" style="height: 50%">
            <div class="name">
              <span>{{ "STATISTICS_CHART.ClosedCustomers" | translate }}</span>
            </div>
            <div class="value">
              <span>{{
                userStatistics?.totalCustomersWithContractTerminated || 0
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9">
        <div class="chart-container">
          <div *ngIf="statisticsLoader" class="content">
            <div class="full-wrapper-center">
              <mat-spinner diameter="40"></mat-spinner>
            </div>
          </div>
          <div
            *ngIf="!statisticsLoader"
            class="chart"
            echarts
            [options]="directionStatisticsChartOptions"
          ></div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #customerDialog let-close="close" let-data>
  <div class="header">
    <div class="title">
      <span class="fw-700">{{ data.customer.businessName }}: </span>
      <span></span>
    </div>
    <button
      class="close-dialog"
      mat-dialog-close
      mat-icon-button
      [disabled]="disableButtons"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="projects-container">
      <div *ngFor="let project of data.projects" class="project">
        <div>
          <span>{{ "CRM.Code" | translate }}:</span>
          <span>{{ project.code }}</span>
        </div>
        <div>
          <span>{{ "CRM.Product" | translate }}:</span>
          <span>{{ project.productName }}</span>
        </div>
        <div>
          <span>{{ "CRM.Version" | translate }}:</span>
          <span>{{ project.version }}</span>
        </div>
        <div>
          <span>{{ "CRM.Typology" | translate }}:</span>
          <span>{{ project.reason }}</span>
        </div>
        <div>
          <button
            type="button"
            class="rounded-button primary-border-button mt-20"
            [mat-dialog-close]="project"
          >
            {{ "CRM.SelectProject" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
