<div class="row">
  <div class="col-9">
    <div class="section-card">
      <div class="content">
        <app-table
          [loader]="projectsLoader"
          [columns]="projectsTableColumns"
          [columnsButtons]="projectsTableButtons"
          [data]="projects"
          [searchValue]="searchValueProjects.value"
        >
        </app-table>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="section-card" style="height: 100%">
      <div class="header multi">
        <div class="title">
          <span>{{ "COMMON.Filters" | translate }}</span>
        </div>
        <div class="text-button">
          <span class="primary-color" (click)="resetFilters()">
            {{ "COMMON.Reset" | translate | lowercase }}
          </span>
        </div>
      </div>
      <div class="content">
        <div class="row" style="flex-direction: column">
          <mat-form-field appearance="standard" class="col">
            <mat-label for="searchValueProjects">{{
              "COMMON.Research" | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              [formControl]="searchValueProjects"
              name="searchValueProjects"
            />
            <svg-icon matSuffix name="search_icon" class="research"></svg-icon>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col">
            <mat-label for="filterTableControl">{{
              "COMMON.ProjectStatus" | translate
            }}</mat-label>
            <mat-select
              (selectionChange)="filterTable($event)"
              [formControl]="filterTableControl"
              name="filterTableControl"
            >
              <mat-option
                *ngFor="let status of statusSelect"
                [value]="status.value"
              >
                {{ status.label | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
