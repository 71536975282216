import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceExtension } from 'src/app/services/serviceExtension';
import { IProjectCustomer, ProjectDTO } from 'src/app/models/project';
import Config, { DOC_TYPE } from 'src/app/config';
import { Params } from '@angular/router';
import { IReport } from 'src/app/models/report';
import {
  IBuyerStatus,
  IBuyerStatusReason,
  IContact,
  IContactActivity,
  IContactBuyer,
  IContactCommercialSummaryDTO,
  IContactOrigins,
  IDeal,
  IDealState,
  IDealStateCount,
  IDealStateReason,
} from 'src/app/models/contact';
import { ICrmStatistics } from 'src/app/models/crm';
import { IClonable } from 'src/app/models/clonable';
import { IrFormService } from '../ir-form/ir-form.service';
import { CommonService } from '../common/common.service';
import { IBMOChecker } from 'src/app/models/utility';

@Injectable({
  providedIn: 'root',
})
export class CrmService extends ServiceExtension {
  idCommerceSector: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  idCommerceSectorObs: Observable<number> =
    this.idCommerceSector.asObservable();

  constructor(
    private http: HttpClient,
    private irFormService: IrFormService,
    private common: CommonService
  ) {
    super();
  }

  triggerIdCommerceSector(id: number): void {
    this.idCommerceSector.next(id);
  }

  getDataForSelects(idCustomer?: number): Observable<any> {
    return this.executeForkJoin({
      commerceSectors: this.irFormService.getAllSectors(),
      dealStates: this.getAllDealStates(),
      dealStatesReasons: this.getDealStateReasons(),
      unitsOfMeasure: this.irFormService.getUnitsOfMeasure(
        false,
        false,
        idCustomer
      ),
      countries: this.irFormService.getAllCountries(),
      channelTypes: this.irFormService.getChannelsTypeByIdCustomer(idCustomer),
      specificationsChannels: this.common.getSpecificationChannels(idCustomer),
      buyerStatus: this.getAllBuyerStatus(),
      buyerStatusReasons: this.getAllBuyerStatusReason(),
      referentRoles: this.irFormService.getReferentRoles(),
      contactOrigins: this.getAllContactOrigins()
    });
  }

  getProjectById(
    id: number | string,
    hideLoader: boolean = true
  ): Observable<ProjectDTO> {
    return this.http
      .get<ProjectDTO>(`${Config.apiRootUrl}/project/${id}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description API to get all the followed customers with project status 'PS_2'
   * @param hideLoader Set true to hide global loader (default TRUE)
   * @returns Observable of IProjectCustomer[]
   */
  getFollowedCustomers(
    hideLoader: boolean = true
  ): Observable<IProjectCustomer[]> {
    return this.http
      .get<IProjectCustomer[]>(`${Config.apiRootUrl}/crm/customer/managed`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getManagedCustomersForSupervisor(
    hideLoader: boolean = true
  ): Observable<any> {
    return this.http
      .get<any>(
        `${Config.apiRootUrl}/crm/customer/managed/supervisor/manager`,
        {
          headers: this._getHeaders(hideLoader),
        }
      )
      .pipe();
  }

  /**
   * @description API to get all the mandatory activities by logged user
   * @param hideLoader true or false to hide or not loader
   */
  getAllMandatoryActivitiesByLoggedUser(
    hideLoader: boolean = true
  ): Observable<IContactActivity[]> {
    return this.http
      .get<IContactActivity[]>(
        `${Config.apiRootUrl}/crm/contact/activity/mandatory`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getAllActivitiesByProject(
    idProject: number,
    hideLoader: boolean = false,
    idBuyerStatus?: number
  ): Observable<IContactActivity[]> {
    let par: Params;
    par = new HttpParams().set('idProject', idProject.toString());
    if (idBuyerStatus) {
      par = par.set('idBuyerStatus', idBuyerStatus.toString());
    }
    return this.http
      .get<IContactActivity[]>(`${Config.apiRootUrl}/crm/contact/activity`, {
        params: par,
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description API to get the activities filtered by params: mandatory, idCustomer or nothing.
   * If 'nothing' get all the activities;
   * if 'mandatory' get all the activities by mandatory type (see param 'mandatory' for description);
   * if 'idCustomer' get all the activities for specific customer
   * @param mandatory 0 for non mandatory activities, 1 for mandatory activities, null for all activities
   * @param idCustomer id of the specific customer
   * @param hideLoader true or false to hide or not loader
   */
  getCrmActivities(
    mandatory?: string,
    idCustomer?: number,
    hideLoader: boolean = false
  ): Observable<any> {
    let par: Params;
    if (mandatory !== null) {
      par = new HttpParams().set('mandatory', mandatory.toString());
    }
    if (idCustomer !== null && idCustomer !== undefined) {
      par = new HttpParams().set('idCustomer', idCustomer.toString());
    }
    return this.http
      .get<any>(`${Config.apiRootUrl}/crm/contact/activity`, {
        params: par,
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description API to get all the buyer status type
   * @param hideLoader set true to hide loader
   */
  getAllBuyerStatus(
    hideLoader: boolean = false,
    toCache: boolean = true
  ): Observable<IBuyerStatus[]> {
    return this.http
      .get<IBuyerStatus[]>(`${Config.apiRootUrl}/common/buyerstatus`, {
        headers: this._getHeaders(hideLoader, false, toCache),
      })
      .pipe();
  }

  /**
   * @description API to get all the buyer status reason
   * @param hideLoader set true to hide loader
   */
  getAllBuyerStatusReason(
    hideLoader: boolean = false,
    toCache: boolean = true
  ): Observable<IBuyerStatusReason[]> {
    return this.http
      .get<IBuyerStatusReason[]>(
        `${Config.apiRootUrl}/common/buyerstatusreason`,
        { headers: this._getHeaders(hideLoader, false, toCache) }
      )
      .pipe();
  }

  /**
   * @description API to get contact origin list
   * @param hideLoader set true to hide loader
   */
  getAllContactOrigins(
    hideLoader: boolean = false,
    toCache: boolean = true
  ): Observable<IContactOrigins> {
    return this.http
      .get<IContactOrigins>(
        `${Config.apiRootUrl}/contact/contactOrigin`,
        { headers: this._getHeaders(hideLoader, false, toCache) }
      )
      .pipe();
  }

  /**
   * @description Create or update a contact associated to a customer
   * @param idProject Id of the project
   * @param body Object that contains all the contact informations
   */
  createUpdateContact(
    idProject: number,
    body: IContact,
    hideLoader: boolean = false
  ): Observable<IContact> {
    return this.http
      .put<IContact>(
        `${Config.apiRootUrl}/contact/project/${idProject}`,
        body,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to delete a buyer from contact
   * @param idContactBuyer ID of the buyer
   * @param hideLoader Set true to hide loader
   */
  deleteContactBuyerBydId(
    idContactBuyer: number,
    hideLoader: boolean = false
  ): Observable<IContactBuyer> {
    return this.http
      .delete<IContactBuyer>(
        `${Config.apiRootUrl}/contact/reference/${idContactBuyer}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to get all contacts by idProject
   * @param idProject Id of the project
   * @param hideLoader Set true to hide loader
   */
  getContactsByIdProject(
    idProject: number,
    hideLoader: boolean = false
  ): Observable<IContact[]> {
    return this.http
      .get<IContact[]>(`${Config.apiRootUrl}/contact/project/${idProject}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description Get all contact details by id contact
   * @param idContact Id of the contact
   * @param hideLoader Set true to hide loader
   */
  getContactDetails(
    idContact: number,
    hideLoader: boolean = false
  ): Observable<IContact> {
    return this.http
      .get<IContact>(`${Config.apiRootUrl}/contact/details/${idContact}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description Get all contact activities by id customer and id contact
   * @param idCustomer Id of the customer
   * @param idContact Id of the contact
   * @param hideLoader Set true to hide loader
   */
  getContactActivities(
    idCustomer: number,
    idContact: number,
    hideLoader: boolean = false
  ): Observable<any> {
    return this.http
      .get<any>(
        `${Config.apiRootUrl}/contact/activity/customer/${idCustomer}/contact/${idContact}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getContactReports(
    idContact: number | string,
    hideLoader: boolean = false
  ): Observable<IReport[]> {
    return this.http
      .get<IReport[]>(`${Config.apiRootUrl}/opportunity/contact/${idContact}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  addContactReport(
    idContact: number | string,
    body: IReport,
    hideLoader: boolean = false
  ): Observable<IReport> {
    return this.http
      .put<IReport>(
        `${Config.apiRootUrl}/opportunity/contact/${idContact}`,
        body,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  deleteContactReport(
    idOpportunity: number | string,
    hideLoader: boolean = false
  ): Observable<any> {
    return this.http
      .delete<any>(`${Config.apiRootUrl}/opportunity/${idOpportunity}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getContactActivity(
    idCustomer: number | string,
    idContact: number | string,
    hideLoader: boolean = false
  ): Observable<IContactActivity[]> {
    return this.http
      .get<IContactActivity[]>(
        `${Config.apiRootUrl}/contact/activity/customer/${idCustomer}/contact/${idContact}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to get all deals by id contact
   * @param idContact Id of the contact
   * @param hideLoader Set true to hide loader
   */
  getDealsByIdContact(
    idContact: number,
    hideLoader: boolean = false
  ): Observable<IDeal[]> {
    return this.http
      .get<IDeal[]>(`${Config.apiRootUrl}/deal/contact/${idContact}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  addContactActivity(
    idContact: number | string,
    body: IContactActivity,
    hideLoader: boolean = false
  ): Observable<IContactActivity> {
    return this.http
      .put<IContactActivity>(
        `${Config.apiRootUrl}/contact/activity/${idContact}`,
        body,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  deleteContactActivity(
    idContactActivity: number | string,
    hideLoader: boolean = false
  ): Observable<any> {
    return this.http
      .delete<any>(`${Config.apiRootUrl}/contact/activity/${idContactActivity}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getContactBuyerStatus(
    hideLoader: boolean = false,
    toCache: boolean = true
  ): Observable<IContactActivity[]> {
    return this.http
      .get<IContactActivity[]>(`${Config.apiRootUrl}/common/buyerstatus`, {
        headers: this._getHeaders(hideLoader, false, toCache),
      })
      .pipe();
  }

  getContactCompetitors(
    idContact: number | string,
    hideLoader: boolean = false
  ): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${Config.apiRootUrl}/contactcompetitorcompany/contact/${idContact}`,
        { headers: this._getHeaders(hideLoader) }
      );
  }

  getAllContactCompetitors(
    idProject: number | string,
    hideLoader: boolean = false
  ): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${Config.apiRootUrl}/contactcompetitorcompany/allcontactcompetitor/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      );
  }

  /**
   * @description API to get all deals state
   * @param hideLoader Set true to hide loader
   */
  getAllDealStates(
    hideLoader: boolean = false,
    toCache: boolean = true
  ): Observable<IDealState[]> {
    return this.http
      .get<IDealState[]>(`${Config.apiRootUrl}/common/dealstate`, {
        headers: this._getHeaders(hideLoader, false, toCache),
      })
      .pipe();
  }

  /**
   * @description API to create or update a deal to contact
   * @param idContact Id of the contact
   * @param deal The deal that will be saved
   * @param hideLoader Set true to hide loader
   */
  saveDeal(
    idContact: number,
    deal: IDeal,
    hideLoader: boolean = false
  ): Observable<IDeal> {
    return this.http
      .put<IDeal>(`${Config.apiRootUrl}/deal/contact/${idContact}`, deal, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  addContactCompetitor(
    idContact: number | string,
    body: any,
    hideLoader: boolean = false
  ): Observable<any> {
    return this.http
      .put<any>(
        `${Config.apiRootUrl}/contactcompetitorcompany/contact/${idContact}`,
        {...body, idContact},
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  deleteContactCompetitor(
    idContactCompetitorCompany: number | string,
    hideLoader: boolean = false
  ): Observable<any> {
    return this.http
      .delete<any>(
        `${Config.apiRootUrl}/contactcompetitorcompany/${idContactCompetitorCompany}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to save or update the document to deal
   * @param idDeal Id of the deal
   * @param document Document that must be uploaded
   * @param hideLoader Set true to hide loader
   */
  saveUpdateDealDocument(
    idDeal: number,
    document: File,
    hideLoader: boolean = false,
    idDealDocument?: number
  ): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', document, document.name);
    let par: Params = new HttpParams();
    if (idDealDocument) {
      par = par.set('idDealDocument', idDealDocument.toString());
    }
    return this.http
      .put<any>(
        `${Config.apiRootUrl}/deal/dealdocument/deal/${idDeal}/${DOC_TYPE.Deal}`,
        formData,
        { params: par, headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to download the deal document
   * @param idDocument Id of the deal document
   * @param hideLoader Set true to hide loader
   */
  downloadDealDocument(
    idDocument: string | number,
    hideLoader: boolean = false
  ): Observable<Blob> {
    return this.http
      .get(`${Config.apiRootUrl}/deal/download/${idDocument}`, {
        headers: this._getHeaders(hideLoader),
        responseType: 'blob',
      })
      .pipe();
  }

  /**
   * @description API to get contact by business name
   * @param businessName Set business name
   */
  getContactByBusinessName(
    businessName: string,
    hideLoader: boolean = false
  ): Observable<IContactBuyer> {
    return this.http
      .get<IContactBuyer>(
        `${Config.apiRootUrl}/contact/businessname/${businessName}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to get deals status count by contact id
   * @param idContact Set contact id
   */
  getDealStatusCountByProjectId(
    projectId: number,
    hideLoader: boolean = false
  ): Observable<IDealStateCount> {
    return this.http
      .get<IDealStateCount>(
        `${Config.apiRootUrl}/deal/dealstate/count/${projectId}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to get all deal state reasons
   * @param hideLoader Set true to hide loader
   */
  getDealStateReasons(
    hideLoader: boolean = false,
    toCache: boolean = true
  ): Observable<IDealStateReason[]> {
    return this.http
      .get<IDealStateReason[]>(`${Config.apiRootUrl}/common/dealstatereason`, {
        headers: this._getHeaders(hideLoader, false, true),
      })
      .pipe();
  }

  /**
   * @description API to get all the other contacts (Not interested, not interesting, losed) of the current PMI
   * @param idProject Id of the project
   * @param idBuyerStatus Id of the buyer status
   * @param hideLoader Set true to hide the loader
   */
  getOtherContactsCrmByIdBuyerStatus(
    idProject: number,
    idBuyerStatus: number,
    hideLoader: boolean = false
  ): Observable<IContact[]> {
    const par: Params = new HttpParams().set(
      'idBuyerStatus',
      idBuyerStatus.toString()
    );
    return this.http
      .get<IContact[]>(`${Config.apiRootUrl}/contact/project/${idProject}`, {
        params: par,
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getClonableByProject(
    idProject: number,
    hideLoader: boolean = false
  ): Observable<IClonable> {
    return this.http
      .get<IClonable>(`${Config.apiRootUrl}/crm/clone/project/${idProject}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description API to get the business matching on marketed product by id project
   * @param idProject Id of the project
   * @param hideLoader Set true to hide full screen loader
   */
  getBmOnProductByIdProject(
    idProject: number,
    hideLoader: boolean = false
  ): Observable<any> {
    return this.http
      .get<any>(
        `${Config.apiRootUrl}/product/matching/description/customeraggregation/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to get details of the business matching on marketed product by id project
   * @param idProject Id of the project
   * @param hideLoader Set true to hide full screen loader
   */
  getBmDetailsOnProductsByIdProject(
    idProject: number,
    hideLoader: boolean = false
  ): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${Config.apiRootUrl}/product/matching/description/customeraggregation/information/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to get the business matching on deal opportunity by id project
   * @param idProject Id of the project
   * @param hideLoader Set true to hide full screen loader
   */
  getBmOnDealOpportunityByIdProject(
    idProject: number,
    hideLoader: boolean = false
  ): Observable<IDeal> {
    return this.http
      .get<IDeal>(
        `${Config.apiRootUrl}/dealopportunitymatching/wordkeys/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to get details of the business matching on deal opportunity by id project
   * @param idProject Id of the project
   * @param hideLoader Set true to hide full screen loader
   */
  getBmDetailsOnDealOpportunityByIdProject(
    idProject: number,
    hideLoader: boolean = false
  ): Observable<IDeal[]> {
    return this.http
      .get<IDeal[]>(
        `${Config.apiRootUrl}/dealopportunitymatching/project/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to get the business matching on opportunity by id project
   * @param idProject Id of the project
   * @param hideLoader Set true to hide full screen loader
   */
  getBmOnOpportunityByIdProject(
    idProject: number,
    hideLoader: boolean = false
  ): Observable<IDeal> {
    return this.http
      .get<IDeal>(
        `${Config.apiRootUrl}/opportunitymatching/wordkeys/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to get details of the business matching on opportunity by id project
   * @param idProject Id of the project
   * @param hideLoader Set true to hide full screen loader
   */
  getBmDetailsOnOpportunityByIdProject(
    idProject: number,
    hideLoader: boolean = false
  ): Observable<any[]> {
    return this.http
      .get<any[]>(
        `${Config.apiRootUrl}/opportunitymatching/project/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  cloneToProject(
    idProject: number,
    body: IClonable,
    hideLoader: boolean = false
  ): Observable<void> {
    return this.http
      .put<void>(`${Config.apiRootUrl}/crm/import/project/${idProject}`, body, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  excludeSelectedBusinessMatching(
    idProject: number,
    businessMatching: any[],
    hideLoader: boolean = false
  ): Observable<any[]> {
    return this.http
      .put<any[]>(
        `${Config.apiRootUrl}/product/businessmatching/check/${idProject}`,
        businessMatching,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  excludeSelectedDealOpportunityBusinessMatching(
    idProject: number,
    dealOpportunityBusinessMatching: any[],
    hideLoader: boolean = false
  ): Observable<any[]> {
    return this.http
      .put<any[]>(
        `${Config.apiRootUrl}/deal/businessmatching/check/${idProject}`,
        dealOpportunityBusinessMatching,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  excludeSelectedOpportunityBusinessMatching(
    idProject: number,
    opportunityBusinessMatching: any[],
    hideLoader: boolean = false
  ): Observable<any[]> {
    return this.http
      .put<any[]>(
        `${Config.apiRootUrl}/opportunity/businessmatching/check/${idProject}`,
        opportunityBusinessMatching,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  /**
   * @description API to download the deal document
   * @param idDeal Id of the deal document
   * @param hideLoader Set true to hide loader
   */
  getDealDocuments(
    idDeal: string | number,
    hideLoader: boolean = false
  ): Observable<any[]> {
    return this.http
      .get<any[]>(`${Config.apiRootUrl}/deal/dealdocument/deal/${idDeal}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description API to get customer info to show in the panel
   * @param idProject Id of the project
   * @param hideLoader Set false to show loader
   */
  getCustomerInfoData(
    idProject: number,
    hideLoader: boolean = true
  ): Observable<any> {
    return this.http
      .get<any>(`${Config.apiRootUrl}/crm/customer/managed/${idProject}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description API to check if exist Crm Business Matching
   * @param idProject Id of the project
   * @param hideLoader Set false to show loader
   */
  checkCrmBusinessMatchingExist(
    idProject: number,
    hideLoader: boolean = true
  ): Observable<any> {
    return this.http
      .get<any>(
        `${Config.apiRootUrl}/crm/businessmatching/exist/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getCommercialSummary(
    idProject: number,
    hideLoader: boolean = true
  ): Observable<IContactCommercialSummaryDTO[]> {
    return this.http
      .get<IContactCommercialSummaryDTO[]>(
        `${Config.apiRootUrl}/contact/allcontactbusinesssummary/project/${idProject}`,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  getProjectStatisticsCrm(
    idProject: number,
    hideLoader: boolean = true
  ): Observable<ICrmStatistics> {
    return this.http
      .get<ICrmStatistics>(
        `${Config.apiRootUrl}/deal/statistics/period/manager/${idProject}`,
        {
          headers: this._getHeaders(hideLoader),
        }
      )
      .pipe();
  }

  // TODO da gestire la tipicizzazione
  updateContactStatus(
    idContact: number,
    idBuyerStatus: number,
    hideLoader: boolean = true
  ): Observable<any> {
    return this.http
      .put<any>(
        `${Config.apiRootUrl}/contact/updatestate/${idContact}/${idBuyerStatus}`,
        {
          headers: this._getHeaders(hideLoader),
        }
      )
      .pipe();
  }

  getBMOpportunities(
    custom: boolean,
    idCommerceSector?: number,
    wordKey?: string,
    isSold?: boolean,
    isChecked?: boolean,
    isClient?: boolean,
    startDate?: string,
    endDate?: string,
    hideLoader: boolean = true
  ): Observable<any> {
    let par: HttpParams = new HttpParams();
    par = par.set('custom', custom.toString());
    if (idCommerceSector) {
      par = par.set('idCommerceSector', idCommerceSector.toString());
    }
    if (wordKey !== '') {
      par = par.set('wordKey', wordKey);
    }
    if (isSold || isSold === false) {
      par = par.set('isSold', isSold.toString());
    }
    if (isChecked || isChecked === false) {
      par = par.set('isChecked', isChecked.toString());
    }
    if (isClient || isClient === false) {
      par = par.set('isClient', isClient.toString());
    }
    if (startDate) {
      par = par.set('dateFrom', startDate);
    }
    if (endDate) {
      par = par.set('dateTo', endDate);
    }
    return this.http
      .get<any>(
        `${Config.apiRootUrl}/productmatching/productAllBusinessMatching`,
        {
          params: par,
          headers: this._getHeaders(hideLoader),
        }
      )
      .pipe();
  }

  checkBMOpportunities(
    idBm: number,
    checkedBuyer?: boolean,
    checkedSell?: boolean
  ): Observable<any> {
    let par: HttpParams = new HttpParams();
    if (checkedBuyer || checkedBuyer === false) {
      par = par.set('checkedBuyer', checkedBuyer.toString());
    }
    if (checkedSell || checkedSell === false) {
      par = par.set('checkedSell', checkedSell.toString());
    }
    return this.http
      .put(
        `${Config.apiRootUrl}/productmatching/productAllBusinessMatching/${idBm}`,
        null,
        {
          params: par,
        }
      )
      .pipe();
  }

  getLostOffersOpportunities(
    custom: boolean,
    idCommerceSector?: number,
    productDescription?: string,
    idCountry?: number,
    startDate?: string,
    endDate?: string,
    hideLoader: boolean = true
  ): Observable<any> {
    let par: HttpParams = new HttpParams();
    par = par.set('custom', custom.toString());
    if (idCommerceSector) {
      par = par.set('idCommerceSector', idCommerceSector.toString());
    }
    if (productDescription) {
      par = par.set('description', productDescription);
    }
    if (idCountry) {
      par = par.set('idCountry', idCountry.toString());
    }
    if (startDate) {
      par = par.set('dateFrom', startDate);
    }
    if (endDate) {
      par = par.set('dateTo', endDate);
    }
    return this.http
      .get<any>(`${Config.apiRootUrl}/deal/lostoffers`, {
        params: par,
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getReportsOpportunities(
    custom: boolean,
    idCommerceSector?: number,
    productDescription?: string,
    idCountry?: number,
    startDate?: string,
    endDate?: string,
    hideLoader: boolean = true
  ): Observable<any> {
    let par: HttpParams = new HttpParams();
    par = par.set('custom', custom.toString());
    if (idCommerceSector) {
      par = par.set('idCommerceSector', idCommerceSector.toString());
    }
    if (productDescription) {
      par = par.set('description', productDescription);
    }
    if (idCountry) {
      par = par.set('idCountry', idCountry.toString());
    }
    if (startDate) {
      par = par.set('dateFrom', startDate);
    }
    if (endDate) {
      par = par.set('dateTo', endDate);
    }
    return this.http
      .get<any>(`${Config.apiRootUrl}/product/opportunities`, {
        params: par,
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getExportedBM(idCustomer: number, idProject: number): Observable<any> {
    return this.http
      .get<any>(
        `${Config.apiRootUrl}/productmatching/productAllBusinessMatching/exported/${idCustomer}/${idProject}`
      )
      .pipe();
  }

  getExportedLO(idProject: number): Observable<any> {
    return this.http
      .get<any>(`${Config.apiRootUrl}/deal/lostoffers/exported/${idProject}`)
      .pipe();
  }

  getExportedReport(idProject: number): Observable<any> {
    return this.http
      .get<any>(`${Config.apiRootUrl}/product/opportunities/exported/${idProject}`)
      .pipe();
  }

  getProjects(): Observable<any> {
    return this.http
      .get<any>(`${Config.apiRootUrl}/opportunity/projectManaged`)
      .pipe();
  }

  exportBM(idProductAllBusinessMatching: number): Observable<any> {
    const par: HttpParams = new HttpParams().set('exported', 'true');
    return this.http
      .put<any>(
        `${Config.apiRootUrl}/productmatching/productAllBusinessMatching/exported/${idProductAllBusinessMatching}`,
        null,
        {
          params: par,
        }
      )
      .pipe();
  }

  checktExportedBMAsWorked(
    idProductAllBusinessMatching: number
  ): Observable<any> {
    return this.http.put<any>(
      `${Config.apiRootUrl}/productmatching/productAllBusinessMatching/worked/${idProductAllBusinessMatching}`,
      null
    );
  }

  getContactStartDataFromBM(
    idProductAllBusinessMatching
  ): Observable<IContact> {
    return this.http.get<IContact>(
      `${Config.apiRootUrl}/productmatching/productAllBusinessMatching/contact/${idProductAllBusinessMatching}`
    );
  }

  checkBMAsRead(bm: any, hideLoader: boolean = true): Observable<any> {
    return this.http.put<any>(
      `${Config.apiRootUrl}/notice/businessmatchingnotice`,
      bm,
      {
        headers: this._getHeaders(hideLoader),
      }
    );
  }

  checkLostOfferAsRead(
    idDeal: number,
    hideLoader: boolean = true,
    body?: any
  ): Observable<any> {
    return this.http.put<any>(
      `${Config.apiRootUrl}/notice/dealnotice/${idDeal}`,
      body ? body : null,
      {
        headers: this._getHeaders(hideLoader),
      }
    );
  }

  checkReportAsRead(
    idOpportunity: number,
    hideLoader: boolean = true,
    body?: any
  ): Observable<any> {
    return this.http.put<any>(
      `${Config.apiRootUrl}/notice/opportunitynotice/${idOpportunity}`,
      body ? body : null,
      {
        headers: this._getHeaders(hideLoader),
      }
    );
  }

  checkNewBusinessOpportunities(
    hideLoader: boolean = true
  ): Observable<IBMOChecker> {
    return this.http.get<IBMOChecker>(
      `${Config.apiRootUrl}/notice/bmglobalnotification`,
      { headers: this._getHeaders(hideLoader) }
    );
  }
}
