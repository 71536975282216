import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  AbstractControl,
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Project } from 'src/app/models/project';
import {
  ICompanyLead,
  ILeadGeneration,
  ILeadGenerationActivity,
  ILeadProfile,
} from 'src/app/models/lead-generation';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { IrFormComponent } from 'src/app/components/ir-form-abstract/ir-form-abstract-components';
import { MaxInArrayFields } from 'src/app/utility/validators';
import {
  LEAD_GENERATION_ACTIVITY_TYPE,
  currencyDefaultConfig,
} from 'src/app/config';
import { IChannel } from 'src/app/models/channels';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { faTshirt } from '@fortawesome/free-solid-svg-icons';
import { NgxCurrencyModule } from 'ngx-currency';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatRadioChange } from '@angular/material/radio';
import { MatExpansionPanel } from '@angular/material/expansion';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { CommonService } from 'src/app/services/common/common.service';
import { ToastStatus } from 'src/app/models/utility';
import { fromProductSearchToProductSearchTable } from 'src/app/utility/elaborations';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  IChannelSpecification,
  IChannelsType,
} from 'src/app/models/channels-type';
import { ChannelsManagementDialogComponent } from '../channels-management-dialog/channels-management-dialog.component';

@Component({
  selector: 'app-lead-generation-form',
  templateUrl: './lead-generation-form.component.html',
  styleUrls: ['./lead-generation-form.component.scss'],
})
export class LeadGenerationFormComponent
  extends IrFormComponent
  implements OnInit, OnDestroy
{
  leadGenerationForm: FormGroup;
  idCustomer: number;
  channels: IChannel[];
  private subscriptions: Subscription = new Subscription();
  dialogSubscription: Subscription;
  actualProfileActivities: any[];
  desiredProfileActivities: any[];
  channelsType: IChannelsType[];
  specificationsChannelsTypes: IChannelSpecification[];
  selectedIndex: number = 1;

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  @Input() index: any;
  @Input() tabIndex: any;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;

  @Output() submitFuncEmitter: EventEmitter<ILeadGeneration> =
    new EventEmitter();
  @Output() deleteLeadActivity: EventEmitter<number> = new EventEmitter();
  @Output() deleteLeadCompany: EventEmitter<number> = new EventEmitter();
  @Output() deleteLeadProfile: EventEmitter<number> = new EventEmitter();
  @Output() deleteActivityProfileEmitter: EventEmitter<number> =
    new EventEmitter();
  @Output() refreshLeadGeneration: EventEmitter<any> = new EventEmitter();

  @ViewChildren('actualProfileSelect')
  actualProfileSelect: QueryList<MatSelect>;
  @ViewChildren('desiredProfileSelect')
  desiredProfileSelect: QueryList<MatSelect>;
  @ViewChildren('formPanel') formPanel: QueryList<MatExpansionPanel>;

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;

  startDataOnInit: any;

  constructor(
    private fb: FormBuilder,
    private layoutservice: LayoutService,
    private dialog: MatDialog,
    private irFormService: IrFormService,
    private common: CommonService
  ) {
    super(fb);
    this.leadGenerationForm = fb.group({
      leadGenerationRoot: fb.group({
        useLead: [false, [Validators.required]],
        reasonDescription: [null, [Validators.maxLength(500)]],
        dedicatedResource: [false],
        descriptionCompany: [null],
        descriptionBackground: [null],
        // descriptionActivity: [null],
        descriptionPerformance: [null],
        idLeadGenerationRoot: [null],
      }),
      actualOnlineActivities: fb.array(
        [],
        MaxInArrayFields('revenueShare', 100)
      ),
      actualOfflineActivities: fb.array(
        [],
        MaxInArrayFields('revenueShare', 100)
      ),
      desiredOnlineActivities: fb.array([]),
      desiredOfflineActivities: fb.array([]),
      leadProfiles: fb.array([]),
      companiesLead: fb.array([]),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          this.updateIdCustomerInfoDescription(
            initialValues?.customerDescription
          );
          this.idCustomer = initialValues.customer.idCustomer;
          if (!this.channelsType?.length) {
            this.channelsType = initialValues?.channelsType;
          }
          if (!this.specificationsChannelsTypes?.length) {
            this.specificationsChannelsTypes =
              initialValues?.specificationsChannels;
          }
          // this.channelsType = initialValues.channelsType.map((channelType) => ({
          //   idChannelType: channelType?.idChannelType,
          //   description: channelType?.description,
          // }));
          this.channels = initialValues.channels;
          this.leadGenerationForm
            .get('leadGenerationRoot')
            .patchValue(initialValues.leadGeneration?.leadGenerationRoot || {});
          if (!this.leadGenerationRoot.get('useLead').value) {
            this.leadGenerationRoot.get('reasonDescription')
              .setValidators(Validators.required);
          } else if (this.leadGenerationRoot.get('dedicatedResource').value) {
            this.leadGenerationRoot.get('descriptionBackground')
              .setValidators(Validators.required);
            this.leadGenerationRoot.get('descriptionPerformance')
              .setValidators(Validators.required);
          };
          this.actualOnlineActivities.clear();
          this.actualOfflineActivities.clear();
          this.desiredOnlineActivities.clear();
          this.desiredOfflineActivities.clear();
          this.leadProfiles.clear();
          this.companiesLead.clear();
          this.actualProfileActivities = [];
          this.desiredProfileActivities = [];
          initialValues.leadGeneration?.leadGenerationActivity?.forEach(
            (leadGeneration: ILeadGenerationActivity) => {
              if (leadGeneration.isActual === true) {
                if (
                  leadGeneration.idActivityType ===
                  LEAD_GENERATION_ACTIVITY_TYPE.Online
                ) {
                  this.addActualOnlineActivity(leadGeneration);
                }
                if (
                  leadGeneration.idActivityType ===
                  LEAD_GENERATION_ACTIVITY_TYPE.Offline
                ) {
                  this.addActualOfflineActivity(leadGeneration);
                }
                this.addActualProfileActivity(leadGeneration);
              }
              if (leadGeneration.isActual === false) {
                if (
                  leadGeneration.idActivityType ===
                  LEAD_GENERATION_ACTIVITY_TYPE.Online
                ) {
                  this.addDesiredOnlineActivity(leadGeneration);
                }
                if (
                  leadGeneration.idActivityType ===
                  LEAD_GENERATION_ACTIVITY_TYPE.Offline
                ) {
                  this.addDesiredOfflineActivity(leadGeneration);
                }
                this.addDesiredProfileActivity(leadGeneration);
              }
            }
          );
          initialValues.leadGeneration?.leadProfile?.forEach(
            (leadProfile: ILeadProfile) => {
              this.addLeadProfile(leadProfile);
            }
          );
          initialValues.leadGeneration?.companyLead?.forEach(
            (companylead: ICompanyLead) => {
              this.addCompanyLead(companylead);
            }
          );
          this.leadGenerationForm.markAsPristine();
          this.leadGenerationForm.markAsUntouched();
          if (this.isLabManagerInternal || this.isReadOnly) {
            this.leadGenerationForm.disable();
          }
          this.startDataOnInit = this.leadGenerationForm.getRawValue();
        },
      })
    );
  }

  get leadGenerationRoot(): FormGroup {
    return this.leadGenerationForm.get('leadGenerationRoot') as FormGroup;
  }

  get actualOnlineActivities(): FormArray {
    return this.leadGenerationForm.get('actualOnlineActivities') as FormArray;
  }

  get actualOfflineActivities(): FormArray {
    return this.leadGenerationForm.get('actualOfflineActivities') as FormArray;
  }

  get desiredOnlineActivities(): FormArray {
    return this.leadGenerationForm.get('desiredOnlineActivities') as FormArray;
  }

  get desiredOfflineActivities(): FormArray {
    return this.leadGenerationForm.get('desiredOfflineActivities') as FormArray;
  }

  get leadProfiles(): FormArray {
    return this.leadGenerationForm.get('leadProfiles') as FormArray;
  }

  get companiesLead(): FormArray {
    return this.leadGenerationForm.get('companiesLead') as FormArray;
  }

  addActualOnlineActivity(leadGeneration?: ILeadGenerationActivity): void {
    this.actualOnlineActivities.push(
      this.fb.group({
        idLeadGeneration: [leadGeneration?.idLeadGeneration || null],
        idActivityType: [LEAD_GENERATION_ACTIVITY_TYPE.Online],
        isActual: [true],
        description: [
          leadGeneration?.description || null,
          [Validators.required, Validators.maxLength(250)],
        ],
        importanceLevel: [
          leadGeneration?.importanceLevel || null,
          [Validators.required],
        ],
        reason: [leadGeneration?.reason || null, 
          [Validators.required, Validators.maxLength(500)]],
        revenueShare: [
          leadGeneration?.revenueShare || null,
          // [Validators.required, Validators.min(0), Validators.max(100)],
        ],
      })
    );
  }

  addActualProfileActivity(leadGeneration?: ILeadGenerationActivity): void {
    this.actualProfileActivities.push({
      idLeadGeneration: leadGeneration?.idLeadGeneration || null,
      isActual: true,
      description: leadGeneration?.description || null,
    });
  }

  addDesiredProfileActivity(leadGeneration?: ILeadGenerationActivity): void {
    this.desiredProfileActivities.push({
      idLeadGeneration: leadGeneration?.idLeadGeneration || null,
      isActual: true,
      description: leadGeneration?.description || null,
    });
  }

  addActualOfflineActivity(leadGeneration?: ILeadGenerationActivity): void {
    this.actualOfflineActivities.push(
      this.fb.group({
        idLeadGeneration: [leadGeneration?.idLeadGeneration || null],
        idActivityType: [LEAD_GENERATION_ACTIVITY_TYPE.Offline],
        isActual: [true],
        description: [
          leadGeneration?.description || null,
          [Validators.required, Validators.maxLength(250)],
        ],
        importanceLevel: [
          leadGeneration?.importanceLevel || null,
          [Validators.required],
        ],
        reason: [leadGeneration?.reason || null, 
          [Validators.required, Validators.maxLength(500)]],
        revenueShare: [
          leadGeneration?.revenueShare || null,
          // [Validators.required, Validators.min(0), Validators.max(100)],
        ],
      })
    );
  }

  addDesiredOnlineActivity(leadGeneration?: ILeadGenerationActivity): void {
    this.desiredOnlineActivities.push(
      this.fb.group({
        idLeadGeneration: [leadGeneration?.idLeadGeneration || null],
        idActivityType: [LEAD_GENERATION_ACTIVITY_TYPE.Online],
        isActual: [false],
        description: [
          leadGeneration?.description || null,
          [Validators.required, Validators.maxLength(250)],
        ],
        importanceLevel: [
          leadGeneration?.importanceLevel || null,
          [Validators.required],
        ],
        reason: [leadGeneration?.reason || null, 
          [Validators.required, Validators.maxLength(500)]],
      })
    );
  }

  addDesiredOfflineActivity(leadGeneration?: ILeadGenerationActivity): void {
    this.desiredOfflineActivities.push(
      this.fb.group({
        idLeadGeneration: [leadGeneration?.idLeadGeneration || null],
        idActivityType: [LEAD_GENERATION_ACTIVITY_TYPE.Offline],
        isActual: [false],
        description: [
          leadGeneration?.description || null,
          [Validators.required, Validators.maxLength(250)],
        ],
        importanceLevel: [
          leadGeneration?.importanceLevel || null,
          [Validators.required],
        ],
        reason: [leadGeneration?.reason || null, 
          [Validators.required, Validators.maxLength(500)]],
      })
    );
  }

  addLeadProfile(leadProfile?: ILeadProfile): void {
    this.leadProfiles.push(
      this.fb.group({
        idLeadProfile: [leadProfile?.idLeadProfile || null],
        idChannelType: [
          leadProfile?.idChannelType || null,
          Validators.required,
        ],
        idSpecificationChannelType: [
          leadProfile?.idSpecificationChannelType || null,
          Validators.required,
        ],
        /* Deprecated logic */
        // channel: [leadProfile?.channel || null],
        // channelSpecification: [
        //   leadProfile?.channelSpecification || null,
        //   Validators.required,
        // ],
        // revenue: [leadProfile?.revenue || null],
        isGlobal: [leadProfile?.isGlobal, [Validators.required]],
        // idActualLeadProfileActivities: [
        //   this._addInitialActivities(
        //     leadProfile?.actualLeadProfileActivities
        //   ) || null,
        // ],
        // idDesiredLeadProfileActivities: [
        //   this._addInitialActivities(
        //     leadProfile?.desiredLeadProfileActivities
        //   ) || null,
        // ],
        // actualLeadProfileActivities: this.fb.array(
        //   leadProfile?.actualLeadProfileActivities || []
        // ),
        // desiredLeadProfileActivities: this.fb.array(
        //   leadProfile?.desiredLeadProfileActivities || []
        // ),
      })
    );
  }

  _addInitialActivities(leadProfileActivities?: any): number[] {
    const initialValues: number[] = [];
    if (leadProfileActivities) {
      leadProfileActivities.forEach((element) => {
        initialValues.push(element.idLeadGeneration);
      });
    }
    return initialValues;
  }

  addCompanyLead(companyLead?: ICompanyLead): void {
    this.companiesLead.push(
      this.fb.group({
        idCompanyLead: [companyLead?.idCompanyLead || null],
        businessName: [companyLead?.businessName || null,
          [Validators.required]],
        description: [companyLead?.description || null,
          [Validators.required]]
      })
    );
  }

  isNewLeadGeneration(leadGeneration: FormGroup): boolean {
    if (!leadGeneration.getRawValue().idLeadGeneration) {
      return true;
    }
  }

  isNewLeadProfile(leadProfile: FormGroup): boolean {
    if (!leadProfile.getRawValue().idLeadProfile) {
      return true;
    }
  }

  isNewCompanyLead(companyLead: FormGroup): boolean {
    if (!companyLead.getRawValue().idCompanyLead) {
      return true;
    }
  }

  removeActivity(activity: FormGroup, index: number): void {
    let activities: FormArray;
    if (
      activity.get('idActivityType').value ===
        LEAD_GENERATION_ACTIVITY_TYPE.Offline &&
      activity.get('isActual').value === true
    ) {
      activities = this.actualOfflineActivities;
    } else if (
      activity.get('idActivityType').value ===
        LEAD_GENERATION_ACTIVITY_TYPE.Online &&
      activity.get('isActual').value === true
    ) {
      activities = this.actualOnlineActivities;
    } else if (
      activity.get('idActivityType').value ===
        LEAD_GENERATION_ACTIVITY_TYPE.Offline &&
      activity.get('isActual').value === false
    ) {
      activities = this.desiredOfflineActivities;
    } else if (
      activity.get('idActivityType').value ===
        LEAD_GENERATION_ACTIVITY_TYPE.Online &&
      activity.get('isActual').value === false
    ) {
      activities = this.desiredOnlineActivities;
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;
    const id = activities.at(index).get('idLeadGeneration')?.value;
    if (id) {
      if (this.leadGenerationForm.dirty) {
        this.common.showToast(
          this.translations.SaveChangesBefore,
          ToastStatus.warning,
          3000
        );
      } else {
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService.deleteLeadGenerationActivity(id).subscribe({
                complete: () => {
                  activities.removeAt(index);
                  this.refreshLeadGeneration.emit();
                },
              })
            );
          }
        });
      }
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          activities.removeAt(index);
        }
      });
    }
  }

  // removeActualOnlineActivity(i: number): void {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations?.DeleteConfirmationMessage,
  //     centerTextMessage: true
  //   };
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if (result) {
  //       const id = this.actualOnlineActivities.at(i).get('idLeadGeneration')?.value;
  //       if (id) {
  //         this.showConfirmationDialog(this.deleteLeadActivity, id);
  //       } else {
  //         this.actualOnlineActivities.removeAt(i);
  //       }
  //     }
  //   });
  // }

  // removeActualOfflineActivity(i: number): void {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations?.DeleteConfirmationMessage,
  //     centerTextMessage: true
  //   };
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if (result) {
  //       const id = this.actualOfflineActivities.at(i).get('idLeadGeneration')?.value;
  //       if (id) {
  //         this.showConfirmationDialog(this.deleteLeadActivity, id);
  //       } else {
  //         this.actualOfflineActivities.removeAt(i);
  //       }
  //     }
  //   });
  // }

  // removeDesiredOnlineActivity(i: number): void {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations?.DeleteConfirmationMessage,
  //     centerTextMessage: true
  //   };
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if (result) {
  //       const id = this.desiredOnlineActivities.at(i).get('idLeadGeneration')?.value;
  //       if (id) {
  //         this.showConfirmationDialog(this.deleteLeadActivity, id);
  //       } else {
  //         this.desiredOnlineActivities.removeAt(i);
  //       }
  //     }
  //   });
  // }

  // removeDesiredOfflineActivity(i: number): void {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations?.DeleteConfirmationMessage,
  //     centerTextMessage: true
  //   };
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if (result) {
  //       const id = this.desiredOfflineActivities.at(i).get('idLeadGeneration')?.value;
  //       if (id) {
  //         this.showConfirmationDialog(this.deleteLeadActivity, id);
  //       } else {
  //         this.desiredOfflineActivities.removeAt(i);
  //       }
  //     }
  //   });
  // }

  removeLeadProfile(i: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const id = this.leadProfiles.at(i).get('idLeadProfile')?.value;
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;
    if (id) {
      if (this.leadGenerationForm.dirty) {
        this.common.showToast(
          this.translations.SaveChangesBefore,
          ToastStatus.warning,
          3000
        );
      } else {
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService.deleteLeadGenerationProfile(id).subscribe({
                complete: () => {
                  this.leadProfiles.removeAt(i);
                  this.refreshLeadGeneration.emit();
                },
              })
            );
          }
        });
      }
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          this.leadProfiles.removeAt(i);
        }
      });
    }
  }

  removeCompanyLead(i: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;
    const id = this.companiesLead.at(i).get('idCompanyLead')?.value;
    if (id) {
      if (this.leadGenerationForm.dirty) {
        this.common.showToast(
          this.translations.SaveChangesBefore,
          ToastStatus.warning,
          3000
        );
      } else {
        dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService.deleteLeadGenerationCompany(id).subscribe({
                complete: () => {
                  this.companiesLead.removeAt(i);
                  this.refreshLeadGeneration.emit();
                },
              })
            );
          }
        });
      }
    } else {
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.companiesLead.removeAt(i);
        }
      });
    }
  }

  toggleNoLeadGenReasonRequired(radioValue: boolean) {
    if (radioValue) {
      this.leadGenerationRoot.get('reasonDescription').clearValidators();
      this.leadGenerationRoot.get('reasonDescription').reset();
    } else {
      this.leadGenerationRoot.get('reasonDescription').setValidators(Validators.required);
    };
    this.leadGenerationRoot.get('reasonDescription').updateValueAndValidity()
  }

  toggleLeadGenResourcesRequired(radioValue: boolean) {
    if (radioValue) {
      this.leadGenerationRoot.get('descriptionBackground').setValidators(Validators.required);
      this.leadGenerationRoot.get('descriptionPerformance').setValidators(Validators.required);
    } else {
      this.leadGenerationRoot.get('descriptionBackground').clearValidators();
      this.leadGenerationRoot.get('descriptionPerformance').clearValidators();
      this.leadGenerationRoot.get('descriptionBackground').reset();
      this.leadGenerationRoot.get('descriptionPerformance').reset();      
    };
    this.leadGenerationRoot.get('descriptionBackground').updateValueAndValidity()
    this.leadGenerationRoot.get('descriptionPerformance').updateValueAndValidity()
  };

  onSubmit(): void {
    if (this.leadGenerationForm.valid && this.leadGenerationForm.dirty) {
      const body: ILeadGeneration = {
        leadGenerationRoot: this.leadGenerationRoot.getRawValue(),
        leadGenerationActivity: [
          ...this.actualOfflineActivities.getRawValue(),
          ...this.actualOnlineActivities.getRawValue(),
          ...this.desiredOfflineActivities.getRawValue(),
          ...this.desiredOnlineActivities.getRawValue(),
        ],
        companyLead: [...this.companiesLead.getRawValue()],
        leadProfile: [...this.leadProfiles.getRawValue()],
      };
      this.submitFuncEmitter.emit(body);
    }
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        this.leadGenerationForm.getRawValue(),
        this.irFormService.replacer
      )
    );
  }

  checkInvalidForm(): boolean {
    this.leadGenerationForm.markAllAsTouched();
    return this.leadGenerationForm.invalid;
  }

  // showConfirmationDialog(kindOfEmit: EventEmitter<any>, id: number): void {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations.ConfirmDeletion,
  //     centerTextMessage: true
  //   };
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  //   this.dialogSubscription = dialogRef.afterClosed().subscribe((result: any): any => {
  //     if (result) {
  //       kindOfEmit.emit(id);
  //     }
  //   });
  // }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  handleActualActivitySelect(
    activity: any,
    option: MatCheckbox,
    i: number
  ): void {
    if (option.checked) {
      this.addActualActivityLeadProfile(i, activity);
    }
    if (!option.checked) {
      this.removeActualActivityLeadProfile(i, activity);
    }
    // if (option._selected) {
    //   this.addActualActivityLeadProfile(i, option.value , description);
    // }
    // if (!option._selected) {
    //   this.removeActualActivityLeadProfile(i, option.value);
    // }
  }

  addActualActivityLeadProfile(i, activity): void {
    const addActivity = this.fb.group({
      idLeadGeneration: activity.idLeadGeneration,
      isActual: true,
      description: activity.description,
    });
    let activities: FormArray;
    activities = this.leadProfiles
      .at(i)
      .get('actualLeadProfileActivities') as FormArray;

    addActivity.markAsPristine();
    addActivity.markAsUntouched();
    activities.push(addActivity);
    this.leadProfiles.markAsDirty();
  }

  removeActualActivityLeadProfile(index, activity): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let activityId;
    let activityIndex;
    this.leadProfiles.value[index].actualLeadProfileActivities.forEach(
      (element, i) => {
        if (element.idLeadGeneration === activity.idLeadGeneration) {
          activityId = element.idProfileActivity;
          activityIndex = i;
        }
      }
    );
    if (activityId) {
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          // this.actualProfileSelect.toArray()[index].close();
          this.subscriptions.add(
            this.irFormService
              .deleteLeadGenerationProfileActivity(activityId)
              .subscribe({
                complete: () => {
                  (
                    this.leadProfiles
                      .at(index)
                      .get('actualLeadProfileActivities') as FormArray
                  ).removeAt(activityIndex);
                },
              })
          );
        } else {
          this.addActualActivityLeadProfile(index, activity);
          // this.leadProfiles
          //   .at(index)
          //   .get('idActualLeadProfileActivities')
          //   .setValue(
          //     (
          //       this.leadProfiles
          //         .at(index)
          //         .get('actualLeadProfileActivities') as FormArray
          //     )
          //       .getRawValue()
          //       .map((element) => {
          //         return element.idLeadGeneration;
          //       })
          //   );
          // this.leadProfiles
          //   .at(index)
          //   .get('idActualLeadProfileActivities')
          //   .updateValueAndValidity();
        }
      });
    } else {
      (
        this.leadProfiles
          .at(index)
          .get('actualLeadProfileActivities') as FormArray
      ).removeAt(activityIndex);
      this.leadProfiles
        .at(index)
        .get('idActualLeadProfileActivities')
        .setValue(
          (
            this.leadProfiles
              .at(index)
              .get('actualLeadProfileActivities') as FormArray
          )
            .getRawValue()
            .map((element) => {
              return element.idLeadGeneration;
            })
        );
      this.leadProfiles
        .at(index)
        .get('idActualLeadProfileActivities')
        .updateValueAndValidity();
      this.leadProfiles.markAsDirty();
    }
  }

  handleDesiredActivitySelect(
    activity: any,
    option: MatCheckbox,
    i: number
  ): void {
    if (option.checked) {
      this.addDesiredActivityLeadProfile(i, activity);
    }
    if (!option.checked) {
      this.removeDesiredActivityLeadProfile(i, activity);
    }
  }

  addDesiredActivityLeadProfile(i, activity): void {
    const addActivity = this.fb.group({
      idLeadGeneration: activity.idLeadGeneration,
      isActual: true,
      description: activity.description,
    });
    let activities: FormArray;
    activities = this.leadProfiles
      .at(i)
      .get('desiredLeadProfileActivities') as FormArray;

    addActivity.markAsPristine();
    addActivity.markAsUntouched();
    activities.push(addActivity);
    this.leadProfiles.markAsDirty();
  }

  removeDesiredActivityLeadProfile(index, activity): void {
    let returnValue: boolean;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.DeleteConfirmationMessage,
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let activityId;
    let activityIndex;
    this.leadProfiles.value[index].desiredLeadProfileActivities.forEach(
      (element, i) => {
        if (element.idLeadGeneration === activity.idLeadGeneration) {
          activityId = element.idProfileActivity;
          activityIndex = i;
        }
      }
    );
    if (activityId) {
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          // this.desiredProfileSelect.toArray()[index].close();
          this.subscriptions.add(
            this.irFormService
              .deleteLeadGenerationProfileActivity(activityId)
              .subscribe({
                complete: () => {
                  (
                    this.leadProfiles
                      .at(index)
                      .get('desiredLeadProfileActivities') as FormArray
                  ).removeAt(activityIndex);
                  returnValue = true;
                },
              })
          );
        } else {
          this.addDesiredActivityLeadProfile(index, activity);
          // this.leadProfiles
          //   .at(index)
          //   .get('idDesiredLeadProfileActivities')
          //   .setValue(
          //     (
          //       this.leadProfiles
          //         .at(index)
          //         .get('desiredLeadProfileActivities') as FormArray
          //     )
          //       .getRawValue()
          //       .map((element) => {
          //         return element.idLeadGeneration;
          //       })
          //   );
          // this.leadProfiles
          //   .at(index)
          //   .get('idDesiredLeadProfileActivities')
          //   .updateValueAndValidity();
        }
      });
    } else {
      (
        this.leadProfiles
          .at(index)
          .get('desiredLeadProfileActivities') as FormArray
      ).removeAt(activityIndex);
      this.leadProfiles
        .at(index)
        .get('idActualLeadProfileActivities')
        .setValue(
          (
            this.leadProfiles
              .at(index)
              .get('actualLeadProfileActivities') as FormArray
          )
            .getRawValue()
            .map((element) => {
              return element.idLeadGeneration;
            })
        );
      this.leadProfiles
        .at(index)
        .get('idActualLeadProfileActivities')
        .updateValueAndValidity();
      this.leadProfiles.markAsDirty();
    }
  }

  handleChannelTypeSelect(
    leadProfile: FormGroup,
    channelTypeOption: MatOption
  ): void {
    if (leadProfile.get('idChannelType').value === 1) {
      leadProfile.get('channel').setValue('');
      leadProfile.get('channelSpecification').setValue('');
      leadProfile.get('channel').setValidators([Validators.required]);
      leadProfile.get('channel').updateValueAndValidity();
      leadProfile.get('channelSpecification').updateValueAndValidity();
    } else {
      leadProfile.get('channel').setValue(channelTypeOption.viewValue);
      leadProfile
        .get('channelSpecification')
        .setValue(channelTypeOption.viewValue);
    }
  }

  handleChannelDescription(
    leadProfile: FormGroup,
    channelDescription: MatInput
  ): void {
    leadProfile.get('channelSpecification').setValue(channelDescription.value);
    leadProfile.get('channelSpecification').updateValueAndValidity();
  }

  showChannelsManagementDialog(
    event: PointerEvent,
    type: string,
    leadProfile: FormGroup
  ): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    if (type === 'channel') {
      dialogConfig.data = {
        managementType: 'channel',
        channels: this.channelsType,
        idCustomer: this.idCustomer,
      };
    } else {
      dialogConfig.data = {
        managementType: 'channelSpecification',
        channelsSpecifications: this.specificationsChannelsTypes.filter(
          (spec: IChannelSpecification) => {
            return (
              spec.idChannelType === leadProfile.get('idChannelType').value
            );
          }
        ),
        idCustomer: this.idCustomer,
        idChannelType: leadProfile.get('idChannelType').value,
      };
    }
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        if (type === 'channel') {
          this.subscriptions.add(
            this.irFormService
              .getChannelsTypeByIdCustomer(this.idCustomer, false)
              .subscribe({
                next: (channels: IChannelsType[]) => {
                  this.channelsType = channels;
                  if (
                    !channels.some((ch: any) => {
                      return (
                        ch.idChannelType ===
                        leadProfile.get('idChannelType').value
                      );
                    })
                  ) {
                    leadProfile.get('idChannelType').setValue(null);
                    this.activateSpecificationsSelect(
                      new MatSelectChange(null, null),
                      leadProfile
                    );
                  }
                },
              })
          );
        } else {
          this.subscriptions.add(
            this.common
              .getSpecificationChannels(this.idCustomer, false)
              .subscribe({
                next: (channelsSpec: IChannelSpecification[]) => {
                  this.specificationsChannelsTypes = channelsSpec;
                  if (
                    !channelsSpec.some((ch: any) => {
                      return (
                        ch.idSpecificationChannelType ===
                        leadProfile.get('idSpecificationChannelType').value
                      );
                    })
                  ) {
                    leadProfile
                      .get('idSpecificationChannelType')
                      .setValue(null);
                  }
                },
              })
          );
        }
      }
    });
  }

  activateSpecificationsSelect(
    change: MatSelectChange,
    leadProfile: FormGroup
  ): void {
    if (change.value) {
      leadProfile.get('idSpecificationChannelType').reset();
      leadProfile.get('idSpecificationChannelType').enable();
    } else {
      leadProfile.get('idSpecificationChannelType').reset();
      leadProfile.get('idSpecificationChannelType').disable();
    }
  }

  // TODO: applicare funzione per abilitare il tasto TORNA SU

  expandPanelOnScroll(): void {
    this.formPanel.toArray().forEach((panel, index) => {
      setTimeout(() => {
        const invalidControl: HTMLElement =
          panel._body.nativeElement.querySelector('.mat-form-field-invalid');
        if (invalidControl) {
          this.formPanel.toArray()[index].open();
        }
      });
    });
  }
}
