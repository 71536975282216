import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatInput } from '@angular/material/input';
import { Subscription } from 'rxjs';
import { CustomerDescriptions } from '../models/customer-descriptions';
import { IrFormService } from '../services/ir-form/ir-form.service';

@Directive({
  selector: '[appCustomerDescr]'
})
export class CustomerDescrDirective implements AfterViewInit {

  subscriptions: Subscription = new Subscription();
  customerDescription: CustomerDescriptions;

  @Input() appCustomerDescr: number | string | null;
  @Input() initCustomerDescr: string;

  constructor(
    private el: ElementRef<HTMLElement>,
    // private irFormService: IrFormService
  ) { }


  ngAfterViewInit(): void {
    const cutomerDescrField: MatInput[] = (this.el.nativeElement.querySelector('.customerDescr') as unknown) as MatInput[];
    if (cutomerDescrField && cutomerDescrField.length) {
      this.customerDescription = cutomerDescrField.reduce(
        (initialValue, matInput: MatInput) => ({ ...initialValue, [matInput.id]: matInput.value }), {});
    }
  }

  @HostListener('submit')
  onFormSubmit(): void {
    const cutomerDescrField: MatInput[] = (this.el.nativeElement.querySelector('.customerDescr') as unknown) as MatInput[];
    if (cutomerDescrField && cutomerDescrField.length) {

    }
  }

  /*saveCustomerDescriptionField(fieldValue: string): void {
    if (this.appCustomerDescr) {
      this.subscriptions.add(this.irFormService.updateCustomerDescription(appCustomerDescr, {}).subscribe());
    }
    if (!this.appCustomerDescr) {

    }
  }*/

}
