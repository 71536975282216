import { CalendarDateFormatter, DateFormatterParams, getWeekViewPeriod } from 'angular-calendar';
import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {

    public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'EEE', locale);
    }

    public monthViewTitle({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'MMM y', locale);
    }

    public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'EEE', locale);
    }

    public weekViewColumnSubHeader({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'd MMM', locale);
    }

    public weekViewTitle({ date, locale, weekStartsOn }: DateFormatterParams): string {
        const { viewStart, viewEnd } = getWeekViewPeriod(this.dateAdapter, date, weekStartsOn);
        if (locale.includes('en')) {
          return `${formatDate(viewStart, 'MMM d', locale)} - ${formatDate(viewEnd, 'MMM d, yyyy', locale)}`;
        }
        return `${formatDate(viewStart, 'd MMM', locale)} - ${formatDate(viewEnd, 'd MMM, yyyy', locale)}`;
    }

    public weekViewHour({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'HH:mm', locale);
    }

    public dayViewHour({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'HH:mm', locale);
    }

    public dayViewTitle({ date, locale }: DateFormatterParams): string {
        return formatDate(date, 'EEEE, d MMM, y', locale);
    }
}