import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserFunctionality } from 'src/app/models/user';
import {
  FileBody,
  IBreadcrumb,
  LoadersObj,
  NavBarProps,
  ToastParams,
} from 'src/app/models/utility';
import Config, { APP_CONTEX, DOC_TYPE } from 'src/app/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ServiceExtension } from '../serviceExtension';
import { INotice } from 'src/app/models/note';
@Injectable({
  providedIn: 'root',
})
export class LayoutService extends ServiceExtension {
  /**
   * @description This object keep track of pending promise
   */
  private loaders: BehaviorSubject<LoadersObj> = new BehaviorSubject({});
  /**
   * @description Use loaders as observable to enable subscription
   */
  loadersChanged$ = this.loaders.asObservable();
  /**
   * @description This object keep data of snack bar canfig
   */
  private toast: BehaviorSubject<ToastParams> = new BehaviorSubject(null);
  /**
   * @description Use toast as observable to enable subscription
   */
  Triggertoast$ = this.toast.asObservable();
  /**
   * @description This object keep data of file from service
   */
  private fileBody: BehaviorSubject<FileBody> = new BehaviorSubject(null);
  /**
   * @description Use fileBody as observable to enable subscription
   */
  fileBodyListner$ = this.fileBody.asObservable();
  /**
   * @description This object keep data of file from service
   */
  private dirtyForm: BehaviorSubject<any> = new BehaviorSubject(null);
  /**
   * @description Use fileBody as observable to enable subscription
   */
  dirtyFormListner$ = this.dirtyForm.asObservable();

  private invalidForm: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  invalidFormListener = this.invalidForm.asObservable();

  breadcrumb: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  breadcrumbObservable: Observable<any> = this.breadcrumb.asObservable();
  triggerRefreshUserImage: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  triggerRefreshUserImageObservable: Observable<any> =
    this.triggerRefreshUserImage.asObservable();
  userImage: any;

  constructor(public routes: Router, private http: HttpClient) {
    super();
  }

  /**
   * @description Dinamically create routes of the application so nav-bar menu
   * @param functionalities the functionality which user is enabled
   * @param translations the translations object for i18n of menus
   * @TODO ADD BADGE
   */
  getNavigationMenu(
    functionalities: UserFunctionality,
    translations: { [param: string]: string }
  ): NavBarProps[] {
    const navBar: NavBarProps[] = [
      {
        path: `${APP_CONTEX}/dashboard`,
        icon: 'dashboard_icon',
        label: translations.Dashboard,
      },
    ];
    if (functionalities.appointment.enabled) {
      navBar.push({
        path: `${APP_CONTEX}/meetings`,
        icon: 'calendar_icon',
        label: translations.Appointment,
        badge: '',
      });
    }
    if (
      functionalities.infoReport.create ||
      functionalities.infoReport.edit ||
      functionalities.infoReport.approve
    ) {
      navBar.push({
        path: `${APP_CONTEX}/assessment`,
        icon: 'assessment_icon',
        label: translations.Assessment,
        badge: '',
      });
    }
    if (functionalities.infoReport.assign) {
      navBar.push({
        path: `${APP_CONTEX}/projects`,
        icon: 'plans_icon',
        label: translations.Projects,
      }); // DIREZIONE
    }
    // if (functionalities.infoReport.approve) {
    //   navBar.push({ path: `${APP_CONTEX}/ir-to-approve`, icon: 'send', label: translations.IrToApprove });   // SUPERVISORE_AGENTE
    // }
    // if (functionalities.infoReport.approve) {
    //   navBar.push({ path: `${APP_CONTEX}/ir-rejected`, icon: 'unpublished', label: translations.IrRejected });   // SUPERVISORE_AGENTE
    // }
    // if (functionalities.infoReport.approve) {
    //   navBar.push({ path: `${APP_CONTEX}/ir-approved`, icon: 'task_alt', label: translations.IrApproved });    // SUPERVISORE_AGENTE
    // }
    // if (functionalities.infoReport.assign) {
    //   navBar.push({ path: `${APP_CONTEX}/ir-to-assign`, icon: 'assignment_ind', label: translations.ProjectsToAssign }); // DIREZIONE
    // }
    // if (functionalities.infoReport.delivered && !functionalities.infoReport.approve) {
    //   navBar.push({ path: `${APP_CONTEX}/ir-sent`, icon: 'send', label: translations.IrSended });
    // }
    if (functionalities.administrator.enabled) {
      navBar.push({
        path: `${APP_CONTEX}/users-management`,
        icon: 'edit_user_icon',
        label: translations.AccountManagment,
      });
    }
    if (functionalities.isp.create && functionalities.isp.edit) {
      navBar.push({
        path: `${APP_CONTEX}/ps`,
        icon: 'plans_icon',
        label: translations.MyPS,
      });
    }
    if (
      functionalities.isp.create &&
      functionalities.isp.edit &&
      functionalities.isp.approve &&
      functionalities.isp.reject
    ) {
      navBar.push({
        path: `${APP_CONTEX}/ps-supervision`,
        icon: 'plans_internal_icon',
        label: translations.PSSupervision,
      });
    }
    if (
      functionalities.isp.approve &&
      functionalities.isp.reject &&
      !functionalities.isp.create &&
      !functionalities.isp.edit
    ) {
      navBar.push({
        path: `${APP_CONTEX}/ps`,
        icon: 'plans_internal_icon',
        label: translations.PS,
      });
    }
    // if (functionalities.isp.delivered) {
    //   navBar.push({
    //     path: `${APP_CONTEX}/isp-sent`,
    //     icon: 'send',
    //     label: translations.IspSend,
    //   });
    // }
    // if (functionalities.isp.approve) {
    //   navBar.push({
    //     path: `${APP_CONTEX}/isp-to-approve`,
    //     icon: 'fact_check',
    //     label: translations.IspToApprove,
    //   });
    // }
    // if (functionalities.isp.reject) {
    //   navBar.push({
    //     path: `${APP_CONTEX}/isp-rejected`,
    //     icon: 'unpublished',
    //     label: translations.IspRejected,
    //   });
    // }
    // if (functionalities.isp.approve || functionalities.isp.delivered) {
    //   navBar.push({
    //     path: `${APP_CONTEX}/isp-approved`,
    //     icon: 'task_alt',
    //     label: translations.IspApproved,
    //   });
    // }
    if (functionalities.crm && functionalities.crm.enabled) {
      navBar.push({
        path: `${APP_CONTEX}/crm`,
        icon: 'crm_icon',
        label: translations.Crm,
      });
    }
    // if (functionalities.isp.assign) {                                                                     // DIREZIONE
    //   navBar.push({ path: `${APP_CONTEX}/isp-assigned`, icon: 'assignment_turned_in', label: translations.AssignedProjects });
    // }
    // if (functionalities.infoReport.assign) {
    //   navBar.push({ path: `${APP_CONTEX}/all-project`, icon: 'assignment', label: translations.AllProjects}); // DIREZIONE
    // }
    if (functionalities.administrator.enabled) {
      navBar.push({
        path: `${APP_CONTEX}/all-project`,
        icon: 'plans_icon',
        label: translations.AllProjects,
      }); // AMMINISTRATORE
    }
    if (functionalities.customer.enabled) {
      navBar.push({
        path: `${APP_CONTEX}/customers`,
        icon: 'client_icon',
        label: translations.Customers,
      });
    }
    // 2022/01/26
    // Attualmente questa pagina non è visualizzabile. Il Cliente manderà conferma
    // if (functionalities.bm.enabled) {
    //   navBar.push({
    //     path: `${APP_CONTEX}/search-product`,
    //     icon: 'search_2_icon',
    //     label: translations.BusinessMatching,
    //   });
    // }
    if (functionalities.administrator.enabled) {
      navBar.push({
        path: `${APP_CONTEX}/channels-registry`,
        icon: 'list_icon_2',
        label: translations.ListManagement,
      });
    }
    if (functionalities.bmCustom.enabled) {
      navBar.push({
        path: `${APP_CONTEX}/business-opportunities-custom`,
        icon: 'icon_find',
        label: translations.BusinessOpportunitiesCustom,
      });
    }
    if (functionalities.bm.enabled) {
      navBar.push({
        path: `${APP_CONTEX}/business-opportunities`,
        icon: 'opportunities_icon',
        label: translations.BusinessOpportunities,
      });
    }
    navBar.push({
      path: `${APP_CONTEX}/notices-board`,
      icon: 'bell',
      label: translations.NoticesBoard,
    });
    return navBar;
  }

  generateBreadcrumb(breadcrumb: IBreadcrumb[]): void {
    this.breadcrumb.next(breadcrumb);
  }

  /**
   * @description This method trigger event to the loadersChanged$ subscribed component
   * @param key the transactionId of API CALL
   * @param value the status of API CALL (`true` is pending, `false` is finish)
   */
  triggerLoadersChangedObservable(key: string, value: boolean): void {
    const loaders: LoadersObj = { ...this.loaders.value, [key]: value };
    this.loaders.next(loaders);
  }

  triggerLoadersToFalse(): void {
    const loaders: LoadersObj = { all: false };
    this.loaders.next(loaders);
  }

  /**
   * @description reset the loaders, use it to keep clean the object
   */
  triggerLoadersReset(): void {
    this.loaders.next({});
  }

  /**
   * @description This method trigger event to the Triggertoast$ subscribed component
   * @param toastParam parameter for showToast function
   */
  triggerToastChangedObservable(toastParam: ToastParams): void {
    this.toast.next(toastParam);
  }

  /**
   * @description Thigger event to start download of file
   * @param fileBody The file and fileName
   */
  triggerFileDownload(fileBody: FileBody): void {
    this.fileBody.next(fileBody);
  }

  /**
   * @description Thigger event to set form dirty
   * @param dirtyForm The file and fileName
   */
  triggerDirtyForm(dirtyForm: any): void {
    this.dirtyForm.next({ ...this.dirtyForm.value, ...dirtyForm });
  }

  /**
   * @description Trigger event when form is invalid
   * @param dirtyForm The file and fileName
   */
  triggerInvalidForm(invalidForm: any): void {
    this.invalidForm.next({ ...this.invalidForm.value, ...invalidForm });
  }

  /**
   * @description Clear file download property
   */
  clearFileDownload(): void {
    this.fileBody.next(null);
  }

  getUserImage(hideLoader: boolean = true): Observable<any> {
    return this.http
      .get<any>(`${Config.apiRootUrl}/user/image`, {
        responseType: 'blob' as 'json',
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  updateUserImage(image: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('image', image, image.name);
    return this.http
      .put<any>(`${Config.apiRootUrl}/user/image`, formData)
      .pipe();
  }

  refreshUserImage(image): void {
    this.triggerRefreshUserImage.next(image);
  }

  scrollToTop(): void {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  getLanguage(): string {
    return navigator.language;
  }

  getNotices(
    limitTo?: number,
    hideLoader: boolean = true
  ): Observable<INotice[]> {
    let par: HttpParams = new HttpParams();
    if (limitTo) {
      par = par.set('limit', limitTo.toString());
    }
    return this.http.get<INotice[]>(
      `${Config.apiRootUrl}/notice/servicenotice`,
      { headers: this._getHeaders(hideLoader), params: par }
    );
  }

  saveNotice(
    notice: INotice[],
    hideLoader: boolean = true
  ): Observable<number> {
    return this.http.put<number>(
      `${Config.apiRootUrl}/notice/servicenotices`,
      notice,
      { headers: this._getHeaders(hideLoader) }
    );
  }

  markNoticesAsRead(
    notices: number[],
    hideLoader: boolean = true
  ): Observable<number[]> {
    return this.http.put<number[]>(
      `${Config.apiRootUrl}/notice/noticeservicenotice`,
      notices,
      { headers: this._getHeaders(hideLoader) }
    );
  }

  deleteNotice(idNotice: number): Observable<INotice> {
    return this.http.delete<INotice>(
      `${Config.apiRootUrl}/notice/servicenotices/${idNotice}`
    );
  }

  uploadNoticeAttachments(
    idServiceNotice: number,
    attachments: File[],
    hideLoader: boolean = false
  ): Observable<any> {
    const formData: FormData = new FormData();
    attachments.forEach((attach: File) => {
      formData.append('files', attach, attach.name);
    });
    return this.http
      .put<any>(
        `${Config.apiRootUrl}/notice/servicenoticesdocument/${idServiceNotice}`,
        formData,
        { headers: this._getHeaders(hideLoader) }
      )
      .pipe();
  }

  downloadAttachment(
    idServiceNoticeDocument: number,
    hideLoader: boolean = true
  ): Observable<any> {
    return this.http.get(
      `${Config.apiRootUrl}/notice/servicenoticesdocument/${idServiceNoticeDocument}`,
      {
        headers: this._getHeaders(hideLoader),
        responseType: 'blob',
      }
    );
  }
}
