import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ICnProduct, ICnProductsService } from 'src/app/models/products-services';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';

@Component({
  selector: 'app-search-customs-code',
  templateUrl: './search-customs-code.component.html',
  styleUrls: ['./search-customs-code.component.scss']
})
export class SearchCustomsCodeComponent implements OnInit, OnDestroy {

  description: string;
  codeIsClickable: boolean;
  loader: boolean = false;
  internalLoader: boolean = false;
  private subscriptions: Subscription = new Subscription();
  startPageIndex: number = 0;
  itemsPerPage: number = 10;
  cnProductsService: ICnProductsService;
  cnProductsDataSource: MatTableDataSource<ICnProduct> = new MatTableDataSource<ICnProduct>();
  displayedColumns: string[] = ['cnCode', 'description'];

  codeEmitter: EventEmitter<any> = new EventEmitter();

  // @ViewChild('description') description: ElementRef;
  // @ViewChild('searchCodeDialog') searchCodeDialog: TemplateRef<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private irFormService: IrFormService
  ) {
    this.description = data.description;
    this.codeIsClickable = data.codeIsClickable;
  }

  ngOnInit(): void {
    this.loader = true;
    this.subscriptions.add(
      this.irFormService.getCN8(
        this.description,
        'IT',
        this.startPageIndex,
        this.itemsPerPage
      ).subscribe((cnProductsService: ICnProductsService) => {
        this.cnProductsService = cnProductsService;
        this.cnProductsDataSource.data = cnProductsService.content;
        this.loader = false;
      })
    );
  }

  changePage(event: PageEvent): void {
    this.internalLoader = true;
    this.subscriptions.add(
      this.irFormService.getCN8(
        this.description,
        'IT',
        event.pageIndex,
        this.itemsPerPage,
        true
      ).subscribe((cnProductsService: ICnProductsService) => {
        this.cnProductsService = cnProductsService;
        this.cnProductsDataSource.data = cnProductsService.content;
        this.internalLoader = false;
      })
    );
  }

  codeSelected(code: any): void {
    this.codeEmitter.emit(code);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
