<form
  #strategyFormEl
  appSrollOnError
  [formGroup]="strategyForm"
  #formDirective="ngForm"
  (ngSubmit)="saveStrategy()"
>
  <div class="section-card">
    <div class="header h70">
      <div class="title">
        <span>
          {{ "PS_FORM.NewStrategyDescription" | translate }}
          {{
            idProjectStatus[
              project?.psStrategy?.proposedStrategy?.projectObjective
                ?.idProjectStatus
            ]
          }}
        </span>
      </div>
    </div>
    <div class="content" formGroupName="projectObjective">
      <div class="row">
        <mat-form-field
          class="col-3"
          [ngClass]="
            !strategyForm.get('projectObjective.dateEnd')?.value
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="dateEnd">{{ "COMMON.Timing" | translate }}</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateEnd" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error
            *ngIf="strategyForm.get('projectObjective').get('dateEnd').invalid"
          >
            {{
              strategyForm.get("projectObjective").get("dateEnd").errors
                | getErrorMessage: ("COMMON.Timing" | translate)
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          class="col-3"
          [ngClass]="
            !strategyForm.get('projectObjective.revenue')?.value
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="revenue">
            {{ "COMMON.Turnover" | translate }}
          </mat-label>
          <input
            currencyMask
            matInput
            autocomplete="off"
            formControlName="revenue"
            [options]="currencyDefaultConfig"
            name="revenue"
          />
          <mat-error
            *ngIf="strategyForm.get('projectObjective').get('revenue').invalid"
          >
            {{
              strategyForm.get("projectObjective").get("revenue").errors
                | getErrorMessage: ("COMMON.Turnover" | translate)
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="section-card mt-30">
    <div class="header h70">
      <div class="title">
        <span>{{ "PS_FORM.Strategy" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="mb-10">
        <span>{{ "PS_FORM.Product" | translate }}</span>
      </div>
      <div class="row">
        <mat-form-field class="col-6" formGroupName="projectObjective">
          <input
            matInput
            type="text"
            formControlName="productName"
            name="productName"
          />
        </mat-form-field>
      </div>
      <div class="my-30">
        <button
          type="button"
          class="rounded-button primary-bg-button"
          (click)="setMarketsAndChannelsFromActiveStrategy()"
        >
          {{ "PS_FORM.SelectMarketsAndChannelsFromCurrentPS" | translate }}
        </button>
      </div>
      <div>
        <span>{{ "IR_FORM.Markets" | translate }}</span>
      </div>
      <div class="row">
        <mat-form-field class="col-12">
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idsMarketsSelected"
            name="idsMarketsSelected"
            multiple
            (closed)="filter.value = ''"
          >
            <div class="select-global-filter">
              <input
                #filter
                matInput
                type="text"
                [placeholder]="'COMMON.Search...' | translate"
              />
              <button
                matSuffix
                *ngIf="filter.value !== ''"
                class="only-icon-button"
                type="button"
                (click)="filter.value = null"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </div>
            <mat-option
              #marketStrategyOption
              [ngStyle]="{
                display:
                  (countries | filterSelect: filter.value:'country').includes(
                    country
                  ) || filter.value === ''
                    ? 'flex'
                    : 'none'
              }"
              *ngFor="let country of countries"
              [value]="country.idCountry"
              (click)="handleMarketStrategy(marketStrategyOption)"
            >
              {{ country.country }}
            </mat-option>
            <div
              *ngIf="
                !(countries | filterSelect: filter.value:'country')?.length
              "
              class="select-global-filter-no-result"
            >
              {{ "COMMON.NoResult" | translate }}
            </div>
          </mat-select>
          <mat-error>
            {{
              strategyForm.get("idsMarketsSelected").errors
                | getErrorMessage: translations.Market
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div
        *ngIf="strategyForm.controls.idsMarketsSelected?.value?.length"
        class="markets-container"
        formArrayName="marketProjectObjective"
      >
        <div class="mb-10">
          {{ "PS_FORM.SelectedMarketsPriority" | translate }}
        </div>
        <div
          *ngFor="let country of marketProjectObjective.controls; let i = index"
          [formGroupName]="i"
          class="market"
        >
          <div class="country-name">
            {{ getCountryName(country.controls.idCountry.value) }}
          </div>
          <mat-form-field class="priority-select">
            <mat-label for="priority">
              {{ "IR_FORM.Priority" | translate }}
            </mat-label>
            <mat-select
              disableOptionCentering
              panelClass="custom-panel-class"
              name="countryMarketsPriority"
              formControlName="priority"
              (selectionChange)="checkMarketsPriorityDuplicates()"
            >
              <mat-option
                *ngFor="
                  let item of marketProjectObjective.controls;
                  let i = index
                "
                [value]="i + 1"
              >
                {{ i + 1 }}
              </mat-option>
            </mat-select>
            <mat-error>
              {{ "IR_FORM.DuplicatedPriority" | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="mt-30">
        {{ "PS_FORM.Channels" | translate }}
      </div>
      <div formArrayName="channelProjectObjective" class="mt-20">
        <div
          *ngFor="
            let channelItem of channelProjectObjective.controls;
            let channelIndex = index
          "
          [formGroupName]="channelIndex"
          class="d-flex align-items-center"
        >
          <mat-checkbox
            color="primary"
            class="select-channel"
            formControlName="selectedForStrategy"
            (change)="toggleChannelPriority($event.checked, channelItem)"
          ></mat-checkbox>
          <div class="d-flex w100">
            <mat-form-field
              class="col-3"
              [ngClass]="
                !channelItem.get('idChannelType')?.value
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="idChannelType">
                {{ "IR_FORM.Channel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="idChannelType"
                name="idChannelType"
                (selectionChange)="
                  activateSpecificationsSelect($event, channelIndex)
                "
              >
                <mat-option
                  *ngFor="let channelType of channelsType"
                  [value]="channelType.idChannelType"
                >
                  {{ channelType.description }}
                </mat-option>
              </mat-select>
              <button
                type="button"
                class="only-icon-button primary"
                matSuffix
                [disabled]="isReadOnly"
                (click)="showChannelsManagementDialog($event, 'channel')"
              >
                <svg-icon name="list_icon_2" class="w20"></svg-icon>
              </button>
              <mat-error>
                {{
                  channelItem.controls.idChannelType.errors
                    | getErrorMessage: translations.Channel
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="col-5"
              [ngClass]="
                !channelItem.get('idSpecificationChannelType')?.value
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="idSpecificationChannelType">
                {{ "IR_FORM.ChannelSpec" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="idSpecificationChannelType"
                name="idSpecificationChannelType"
              >
                <mat-option
                  *ngFor="
                    let spec of channelsSpecifications
                      | filterSelectByCondition
                        : channelItem.get('idChannelType').value
                        : 'idChannelType'
                  "
                  [value]="spec.idSpecificationChannelType"
                >
                  {{ spec.description }}
                </mat-option>
              </mat-select>
              <div class="d-flex" matSuffix>
                <button
                  type="button"
                  class="only-icon-button primary"
                  [disabled]="
                    isReadOnly || !channelItem.get('idChannelType').value
                  "
                  (click)="
                    showChannelsManagementDialog(
                      $event,
                      'channelSpecification',
                      channelItem.get('idChannelType').value
                    )
                  "
                >
                  <svg-icon name="list_icon_2" class="w20"></svg-icon>
                </button>
                <button
                  type="button"
                  [matMenuTriggerFor]="channelSpecDescription"
                  class="only-icon-button primary ml-10"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon>info</mat-icon>
                </button>
              </div>
              <mat-menu #channelSpecDescription="matMenu">
                <div style="padding: 0 10px">
                  {{
                    "IR_FORM.ChannelSpecDescription" | translate
                  }}
                </div>
              </mat-menu>
              <mat-error>
                {{
                  channelItem.controls.idSpecificationChannelType.errors
                    | getErrorMessage: translations.ChannelSpec
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-2">
              <mat-label for="levelOfInterest">
                {{ "IR_FORM.ImportanceLevel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="levelOfInterest"
                name="levelOfInterest"
              >
                <mat-option
                  *ngFor="let item of [].constructor(10); let i = index"
                  [value]="i + 1"
                >
                  {{ i + 1 }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{
                  channelItem.controls.levelOfInterest.errors
                    | getErrorMessage: translations.ImportanceLevel
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              *ngIf="channelItem.controls.selectedForStrategy.value"
              class="col-2"
            >
              <mat-label for="channelsPriority">
                {{ "IR_FORM.Priority" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                name="channelsPriority"
                formControlName="priority"
              >
                <mat-option
                  *ngFor="let item of [].constructor(10); let i = index"
                  [value]="i + 1"
                >
                  {{ i + 1 }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{
                  channelItem.controls.priority.errors
                    | getErrorMessage: translations.Priority
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="remove-channel-container">
            <button
              *ngIf="!isReadOnly && channelItem.get('isCustom').value"
              type="button"
              class="only-icon-button error"
              (click)="removeChannel(channelIndex)"
            >
              <svg-icon name="close_icon" class="w16"></svg-icon>
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-center mt-20">
          <button
            *ngIf="!isReadOnly"
            type="button"
            class="rounded-button primary-bg-button"
            (click)="addChannelDistributionStrategy(null, true)"
            [disabled]="isSupervisor"
          >
            {{ "IR_FORM.AddChannel" | translate }}
          </button>
        </div>
        <!-- TODO: aspettare completamento BE -->
        <!-- <div class="mt-30">
              <span>{{ "IR_FORM.LeadGeneration" | translate }}</span>
            </div>
            <div class="row mt-20">
              <mat-form-field class="col-12">
                <mat-label for="leadGeneration">
                  {{ "IR_FORM.LeadGeneration" | translate }}
                </mat-label>
                <input
                  matInput
                  type="text"
                  name="leadGeneration"
                  formControlName="leadGeneration"
                />
              </mat-form-field>
            </div> -->
      </div>
      <!-- <mat-error *ngIf="strategyForm.get('channelProjectObjective').invalid">
        {{ "PS_FORM.SelectAtLeastOneChannel" | translate }}
      </mat-error> -->
    </div>
  </div>
  <div *ngIf="project" class="section-card pt-20 mt-30">
    <div class="header">
      <div class="title">
        <span>{{ "IR_FORM.ActionPlan" | translate }}</span>
      </div>
    </div>
    <div class="content">
      <div class="action-plan-items-container">
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.Product" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                productPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                productPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.distribution" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                distributionPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                distributionPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.PriceSelling" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                priceSellingPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                priceSellingPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.LeadGeneration" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                communicationMktgLgPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                communicationMktgLgPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
        <div class="action-plan-item">
          <div class="title">
            <span>{{ "IR_FORM.OtherAction" | translate }}</span>
          </div>
          <div class="icons">
            <svg-icon
              [name]="
                otherActionPlan.get('actionLm').value
                  ? 'manager_true'
                  : 'manager_false'
              "
            ></svg-icon>
            <svg-icon
              [name]="
                otherActionPlan.get('actionCustomer').value
                  ? 'client_true'
                  : 'client_false'
              "
            ></svg-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="project" class="row mt-50">
    <div class="col-6">
      <div class="section-card pt-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.Product" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="productPlan">
          <mat-form-field class="col-12">
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field class="col-12">
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="section-card pt-20 mt-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.PriceSelling" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="priceSellingPlan">
          <mat-form-field class="col-12">
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field class="col-12">
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="section-card pt-20 mt-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.OtherAction" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="otherActionPlan">
          <mat-form-field class="col-12">
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field class="col-12">
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="section-card pt-20 mb-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.distribution" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="distributionPlan">
          <mat-form-field class="col-12">
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field class="col-12">
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
      <div class="section-card pt-20">
        <div class="header">
          <div class="title">
            <span>{{ "IR_FORM.LeadGeneration" | translate }}</span>
          </div>
        </div>
        <div class="content" [formGroup]="communicationMktgLgPlan">
          <mat-form-field class="col-12">
            <mat-label for="actionLm">
              {{ "IR_FORM.ExportSpecialist" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="actionLm"
              name="actionLm"
            ></textarea>
          </mat-form-field>
          <mat-form-field class="col-12">
            <mat-label for="actionCustomer">{{
              "IR_FORM.CompanyClient" | translate
            }}</mat-label>
            <textarea
              matInput
              formControlName="actionCustomer"
              name="actionCustomer"
            ></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!isIspDelivered && !isReadOnly" class="center-buttons mt-50">
    <button type="submit" class="rounded-button primary-bg-button">
      {{ "COMMON.SaveChanges" | translate }}
    </button>
  </div>
</form>
<div class="mt-40">
  <button
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
