<div class="row" style="margin-right: 0; margin-left: 0">
  <div class="col-6">
    <img class="logo-image" src="/assets/icons/svg/logo_salesguide.svg" />
  </div>
  <div class="col-6">
    <form
      [formGroup]="loginForm"
      (ngSubmit)="onSubmit()"
      class="login-container"
    >
      <div class="title">
        <span>{{ "COMMON.Welcome" | translate }}</span>
      </div>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Email</mat-label>
          <input matInput formControlName="username" type="email" />
          <mat-error *ngIf="loginForm.controls.username.invalid">{{
            getErrorMessageEmail()
          }}</mat-error>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="standard">
          <mat-label>Password</mat-label>
          <input
            matInput
            formControlName="password"
            [type]="hide ? 'password' : 'text'"
          />
          <button
            type="button"
            tabindex="-1"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-error *ngIf="loginForm.controls.password.invalid">{{
            getErrorMessagePassword()
          }}</mat-error>
        </mat-form-field>
      </div>
      <!-- <div class="reset-password">
        <span (click)="resetPassword()">{{ "COMMON.ResetPassword" | translate }}</span>
      </div> -->
      <button
        type="submit"
        class="rounded-button primary-bg-button login-button"
      >
        <span>{{ "COMMON.Login" | translate }}</span>
      </button>
    </form>
  </div>
</div>
