<div class="row">
  <div class="col-3">
    <div class="ta-center image-container">
      <img
        *ngIf="userImage"
        [src]="userImage"
        class="account-image"
        alt="user-image"
      />
      <div class="edit-image" (click)="changeUserImage()">
        <mat-icon>edit</mat-icon>
      </div>
    </div>
    <div class="mt-20 ta-center">
      <span *ngFor="let role of user?.roles; let i = index">
        {{
          i + 1 < user?.roles?.length
            ? ("ROLES." + role | translate) + ", "
            : ("ROLES." + role | translate)
        }}
      </span>
    </div>
  </div>
  <div class="col-9">
    <div class="section-card">
      <div class="header">
        <div class="title">
          {{ "IR_FORM.GeneralInformation" | translate }}
        </div>
      </div>
      <div class="content">
        <form [formGroup]="accountForm" (ngSubmit)="saveEditAccount()">
          <div class="row">
            <mat-form-field appearance="standard" class="col-6">
              <mat-label for="name">
                {{ "USERS_MANAGEMENT.Name" | translate }}</mat-label
              >
              <input matInput formControlName="name" type="text" name="name" />
              <mat-error *ngIf="!controls.name.valid">{{
                controls.name.errors | getErrorMessage: translations?.Name
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-6">
              <mat-label for="surname">{{
                "USERS_MANAGEMENT.Surname" | translate
              }}</mat-label>
              <input
                matInput
                formControlName="surname"
                type="text"
                name="surname"
              />
              <mat-error *ngIf="!controls.surname.valid">{{
                controls.surname.errors | getErrorMessage: translations?.Surname
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="standard" class="col-6">
              <mat-label for="email">{{
                "USERS_MANAGEMENT.Email" | translate
              }}</mat-label>
              <input
                matInput
                formControlName="email"
                type="text"
                name="email"
              />
              <mat-error *ngIf="!controls.email.valid">{{
                controls.email.errors | getErrorMessage: translations?.Email
              }}</mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-6">
              <mat-label for="telephone">{{
                "USERS_MANAGEMENT.Phone" | translate
              }}</mat-label>
              <input
                matInput
                formControlName="telephone"
                type="text"
                name="telephone"
              />
              <mat-error *ngIf="!controls.telephone.valid">{{
                controls.telephone.errors | getErrorMessage: translations?.Phone
              }}</mat-error>
            </mat-form-field>
          </div>
          <div
            *ngIf="showPasswordForm"
            class="mt-50 d-flex justify-content-center"
          >
            <button
              type="submit"
              class="rounded-button primary-bg-button ml-20"
            >
              {{ "COMMON.SaveChanges" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="showPasswordForm" class="section-card mt-30">
      <div class="header">
        <div class="title">
          {{ "COMMON.Password" | translate }}
        </div>
      </div>
      <div class="content">
        <form [formGroup]="passwordForm" (ngSubmit)="changePassword()">
          <div class="row">
            <mat-form-field appearance="standard" class="col-6">
              <mat-label for="password">{{
                "COMMON.Password" | translate
              }}</mat-label>
              <input
                matInput
                formControlName="password"
                [type]="hide ? 'password' : 'text'"
                name="password"
              />
              <mat-error>{{
                passwordForm.controls.password.errors
                  | getErrorMessage: translations?.Password
              }}</mat-error>
              <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="hide = !hide"
              >
                <mat-icon>{{
                  hide ? "visibility_off" : "visibility"
                }}</mat-icon>
              </button>
              <mat-hint>{{ "COMMON.PasswordHint" | translate }}</mat-hint>
            </mat-form-field>
            <mat-form-field appearance="standard" class="col-6">
              <mat-label for="confirmPassword">{{
                "RESET_PASSWORD.ConfirmPassword" | translate
              }}</mat-label>
              <input
                matInput
                formControlName="confirmPassword"
                [type]="hideTwo ? 'password' : 'text'"
                name="confirmPassword"
              />
              <mat-error>{{
                passwordForm.controls.confirmPassword.errors
                  | getErrorMessage: translations?.ConfirmPassword
              }}</mat-error>
              <button
                type="button"
                mat-icon-button
                matSuffix
                (click)="hideTwo = !hideTwo"
              >
                <mat-icon>
                  {{ hideTwo ? "visibility_off" : "visibility" }}
                </mat-icon>
              </button>
            </mat-form-field>
          </div>
          <div class="mt-50 d-flex justify-content-center">
            <button
              type="submit"
              class="rounded-button primary-bg-button ml-20"
            >
              {{ "COMMON.SaveNewPassword" | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div class="account-form-container">
  <div class="title">
    <span>{{ "ACCOUNT_SETTINGS.EditAccount" | translate }}</span>
  </div>
  <app-account-form
    [initialValues]="user"
    [translations]="translations"
    [mandatoryPassword]="false"
  ></app-account-form>
</div> -->

<ng-template #editUserImageDialog let-close="close" let-data>
  <div class="header">
    <div class="title">
      <span>{{ "ACCOUNT_SETTINGS.UpdateUserImage" | translate }}</span>
    </div>
    <button
      class="close-dialog"
      mat-dialog-close
      mat-icon-button
      [disabled]="loading"
      (click)="closeEditImageDialog()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="upload-image mt-30">
      <app-upload-file
        [uploadDirectType]="true"
        [disableButton]="loading"
        (uploadFileEmitter)="uploadImage($event)"
      ></app-upload-file>
    </div>
    <div class="or-bar">
      <hr />
      <div>{{ "ACCOUNT_SETTINGS.Or" | translate }}</div>
    </div>
    <div class="default-image-container">
      <div class="default-image">
        <img
          src="assets/img/user_default_image/man_1.jpg"
          (click)="checkImageType('assets/img/user_default_image/man_1.jpg')"
        />
      </div>
      <div class="default-image">
        <img
          src="assets/img/user_default_image/man_2.jpg"
          (click)="checkImageType('assets/img/user_default_image/man_2.jpg')"
        />
      </div>
      <div class="default-image">
        <img
          src="assets/img/user_default_image/man_3.jpg"
          (click)="checkImageType('assets/img/user_default_image/man_3.jpg')"
        />
      </div>
      <div class="default-image">
        <img
          src="assets/img/user_default_image/woman_1.jpg"
          (click)="checkImageType('assets/img/user_default_image/woman_1.jpg')"
        />
      </div>
      <div class="default-image">
        <img
          src="assets/img/user_default_image/woman_2.jpg"
          (click)="checkImageType('assets/img/user_default_image/woman_2.jpg')"
        />
      </div>
      <div class="default-image">
        <img
          src="assets/img/user_default_image/woman_3.jpg"
          (click)="checkImageType('assets/img/user_default_image/woman_3.jpg')"
        />
      </div>
    </div>
  </div>
</ng-template>
