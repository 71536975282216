import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-svg-icon',
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss']
})
export class SvgIconComponent implements OnInit {

  @Input() iconName: string;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSan: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.matIconRegistry.addSvgIcon(
      this.iconName,
      this.domSan.bypassSecurityTrustResourceUrl(`/assets/icons/${this.iconName}.svg`)
    );
  }

}
