import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoggedUser } from 'src/app/models/user';
import Config from '../../config';
import { ServiceExtension } from '../serviceExtension';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends ServiceExtension {
  constructor(private http: HttpClient) {
    super();
  }

  /**
   * @description the ` GET /user` api call, Get all users
   */
  getUsers(hideLoader: boolean = true): Observable<LoggedUser[]> {
    return this.http
      .get<LoggedUser[]>(`${Config.apiRootUrl}/user`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description the `POST account/signup` api call, signup the user passed in param `body`
   * @param body The user data to signup
   */
  signUpUser(body: LoggedUser): Observable<void> {
    return this.http
      .post<void>(`${Config.apiRootUrl}/account/signup`, body)
      .pipe(map((): void => {}));
  }

  /**
   * @description the `PUT user/:idUser` api call, update the user passed in param `body`
   * @param body the user with field to change
   */
  updateUser(body: LoggedUser, hideLoader: boolean = true): Observable<void> {
    return this.http
      .put<void>(`${Config.apiRootUrl}/user/${body.idUser}`, body, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  /**
   * @description the `PUT user/supervisor` api call, change the supervisor to the user passed in param `idUser`
   * @param idUser the id user who change supervisor
   * @param parentId the supervisor assigned
   */
  assignSupervisor(
    idUser: number | string,
    parentUserId: number | string
  ): Observable<void> {
    return this.http
      .put<void>(`${Config.apiRootUrl}/user/supervisor`, {
        idUser,
        parentUserId,
      })
      .pipe();
  }

  /**
   * @description the `PUT user/:isUser` api call, disabled/enabled user passed in param `idUser`
   * @param idUser the id of user to disable/enable
   * @param value The action, `true=enabled` | `false=disabled`
   */
  enableDisableUser(idUser: number | string, value: boolean): Observable<void> {
    return this.http
      .put<void>(`${Config.apiRootUrl}/user/${idUser}`, { enabled: value })
      .pipe();
  }

  /**
   * @description the `PUT account/passwordReset` api call, send an email to reset password
   * @param email the of user who reset password
   */
  resetPassword(email: string): Observable<void> {
    return this.http
      .post<void>(`${Config.apiRootUrl}/account/sendPasswordReset`, { email })
      .pipe();
  }

  getUserRoles(hideLoader: boolean = true): Observable<any[]> {
    return this.http
      .get<any[]>(`${Config.apiRootUrl}/common/user/roles`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  getDLManagerExpertiseInfo(
    idDlManager: number,
    hideLoader: boolean = true
  ): Observable<any> {
    return this.http
      .get<any>(`${Config.apiRootUrl}/user/manager/provinces/${idDlManager}`, {
        headers: this._getHeaders(hideLoader),
      })
      .pipe();
  }

  checkRoleToRemove(idUser: number, role: string): Observable<any> {
    return this.http.get<any>(
      `${Config.apiRootUrl}/user/rolereplacementcheck/${idUser}/${role}`
    );
  }
}
