<form
  appSrollOnError
  [formGroup]="channelsForm"
  (ngSubmit)="onSubmit()"
  class="ir-form"
>
  <div class="section mb-50">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.ActualChannels" | translate }}</span>
        </div>
        <div class="description">
          <span>
            {{ "IR_FORM.ActualDistributionChannelsDescription" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="content" formArrayName="channels">
      <div
        *ngFor="let channelItem of channels.controls; let i = index"
        [formGroupName]="i"
        class="section-card my-20"
        #channelFormGroup
      >
        <div class="content">
          <div class="row">
            <mat-form-field
              class="col-3"
              [ngClass]="
                !channelItem.get('idChannelType')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="idChannelType">
                {{ i + 1 }}° {{ "IR_FORM.ActualChannel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="idChannelType"
                name="idChannelType"
                (selectionChange)="
                  activateSpecificationsSelect($event, 'current', i)
                "
              >
                <mat-option
                  #channelTypeOption
                  *ngFor="let channelType of channelsType"
                  [value]="channelType.idChannelType"
                >
                  {{ channelType.description }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="!isReadOnly && !isLabManagerInternal"
                type="button"
                class="only-icon-button primary"
                matSuffix
                (click)="
                  showChannelsManagementDialog(
                    $event,
                    'channel',
                    null,
                    'current'
                  )
                "
              >
                <svg-icon name="list_icon_2" class="w20"></svg-icon>
              </button>
              <mat-error *ngIf="!channelItem.controls.idChannelType.valid">{{
                channelItem.controls.idChannelType.errors
                  | getErrorMessage: translations.ActualChannel
              }}</mat-error>
            </mat-form-field>
            <!-- <mat-form-field
              class="col-3"
              *ngIf="channelItem.get('idChannelType').value === 1"
              [ngClass]="
                !channelItem.get('description')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="description">
                {{ "IR_FORM.ChannelDescription" | translate }}
              </mat-label>
              <input
                #channelDescription
                matInput
                formControlName="description"
                type="text"
                name="description"
                (keyup)="
                  handleChannelDescription(channelItem, channelDescription)
                "
              />
              <mat-error *ngIf="!channelItem.controls.description.valid">{{
                channelItem.controls.description.errors
                  | getErrorMessage: translations.ChannelDescription
              }}</mat-error>
            </mat-form-field> -->
            <mat-form-field
              class="col-9"
              [ngClass]="
                !channelItem.get('idSpecificationChannelType')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="idSpecificationChannelType">
                {{ "IR_FORM.ChannelSpecification" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="idSpecificationChannelType"
                name="idSpecificationChannelType"
              >
                <mat-option
                  #channelTypeOption
                  *ngFor="
                    let channelTypeSpec of specificationsChannels
                      | filterSelectByCondition
                        : channelItem?.get('idChannelType').value
                        : 'idChannelType'
                  "
                  [value]="channelTypeSpec.idSpecificationChannelType"
                >
                  {{ channelTypeSpec.description }}
                </mat-option>
              </mat-select>
              <div class="d-flex" matSuffix>
                <button
                  *ngIf="!isReadOnly && !isLabManagerInternal"
                  type="button"
                  class="only-icon-button primary"
                  [disabled]="!channelItem.get('idChannelType').value"
                  (click)="
                    showChannelsManagementDialog(
                      $event,
                      'channelSpecification',
                      channelItem.get('idChannelType').value,
                      'current'
                    )
                  "
                >
                  <svg-icon name="list_icon_2" class="w20"></svg-icon>
                </button>
                <button
                  type="button"
                  [matMenuTriggerFor]="channelSpecDescription"
                  class="only-icon-button primary ml-10"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon>info</mat-icon>
                </button>
              </div>
              <mat-menu #channelSpecDescription="matMenu">
                <div style="padding: 0 10px">
                  {{
                    "IR_FORM.ChannelSpecDescription" | translate
                  }}
                </div>
              </mat-menu>
              <mat-error
                *ngIf="!channelItem.controls.idSpecificationChannelType.valid"
                >{{
                  channelItem.controls.idSpecificationChannelType.errors
                    | getErrorMessage: translations.ChannelSpecification
                }}</mat-error
              >
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field
              class="col-4"
              [ngClass]="
                !channelItem.get('revenueShare')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="revenueShare">
                {{ "IR_FORM.TurnoverQuote" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="revenueShare"
                type="number"
                min="0"
                max="100"
                name="revenueShare"
              />
              <button
                type="button"
                matSuffix
                [matMenuTriggerFor]="revenueShareDescription"
                class="only-icon-button primary"
                (click)="$event.stopPropagation()"
              >
                <span class="custom-suffix">%</span>
                <mat-icon>info</mat-icon>
              </button>
              <mat-menu #revenueShareDescription="matMenu">
                <div style="padding: 0 10px">
                  {{
                    "IR_FORM.DistributionRevenueShareSumDescription" | translate
                  }}
                </div>
              </mat-menu>
              <mat-error *ngIf="!channelItem.controls.revenueShare.valid">{{
                channelItem.controls.revenueShare.errors
                  | getErrorMessage: translations.TurnoverQuote
              }}</mat-error>
            </mat-form-field>
            <mat-form-field
              class="col-4"
              [ngClass]="
                !channelItem.get('isChannelGlobal')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="isChannelGlobal">
                {{ "IR_FORM.ChannelEmployed2" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="isChannelGlobal"
                name="isChannelGlobal"
              >
                <mat-option [value]="channelUsed.National">
                  {{ "IR_FORM.National" | translate }}
                </mat-option>
                <mat-option [value]="channelUsed.Foreign">
                  {{ "IR_FORM.Abroad" | translate }}
                </mat-option>
                <mat-option [value]="channelUsed.NationalForeign">
                  {{ "IR_FORM.NationalForeign" | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="!channelItem.get('isChannelGlobal').valid">
                {{
                  channelItem.get("isChannelGlobal").errors
                    | getErrorMessage: translations.ChannelEmployed2
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field
              class="col-4"
              [ngClass]="
                !channelItem.get('levelOfInterest')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="levelOfInterest">
                {{ "IR_FORM.ImportanceLevel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="levelOfInterest"
                name="levelOfInterest"
              >
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>
              </mat-select>
              <mat-error *ngIf="!channelItem.controls.levelOfInterest.valid">{{
                channelItem.controls.levelOfInterest.errors
                  | getErrorMessage: translations.ImportanceLevel
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field
              class="col-12"
              [ngClass]="
                !channelItem.get('reason')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="reason">
                {{ "IR_FORM.Reason" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="reason"
                type="text"
                name="reason"
              />
              <mat-error *ngIf="!channelItem.controls.reason.valid">
                {{
                  channelItem.controls.reason.errors
                    | getErrorMessage: translations.Reason
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field
              class="col-12"
              [ngClass]="
                !channelItem.get('customerChannelTargetDescription')?.value &&
                isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="customerChannelTargetDescription">
                {{ "IR_FORM.TargetCustomerChannelDescription" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="customerChannelTargetDescription"
                type="text"
                name="customerChannelTargetDescription"
              />
              <mat-error *ngIf="!channelItem.controls.customerChannelTargetDescription.valid">
                {{
                  channelItem.controls.customerChannelTargetDescription.errors
                    | getErrorMessage: translations.Field
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="section anagrafic-list">
            <div class="header">
              <div style="width: calc(100% - 200px)">
                <div class="title">
                  <span>{{ "IR_FORM.AnagraficListTitle" | translate }}</span>
                </div>
                <div class="description">
                  <span>{{
                    "IR_FORM.ChannelAnagraficRequiredDesc" | translate
                  }}</span>
                </div>
              </div>
              <button
                *ngIf="!isReadOnly && !isLabManagerInternal"
                type="button"
                class="rounded-button primary-border-button"
                (click)="addCompanyToChannel(i)"
              >
                {{ "IR_FORM.AddAnagrafic" | translate }}
              </button>
            </div>
            <div class="mt-20" formArrayName="companies">
              <div class="row">
                <div
                  *ngFor="
                    let companyItem of channelItem.controls.companies.controls;
                    let cIndex = index
                  "
                  [formGroupName]="cIndex"
                  class="col-6"
                >
                  <div class="row">
                    <mat-form-field
                      class="col-6"
                      [ngClass]="
                        !companyItem.get('company')?.value && isIsp
                          ? 'red-field-for-lab-manager'
                          : ''
                      "
                    >
                      <mat-label for="company">
                        {{ "IR_FORM.CompanyName" | translate }}
                      </mat-label>
                      <input
                        matInput
                        formControlName="company"
                        type="text"
                        name="company"
                      />
                      <mat-error *ngIf="!companyItem.controls.company.valid">{{
                        companyItem.controls.company.errors
                          | getErrorMessage: translations.CompanyName
                      }}</mat-error>
                    </mat-form-field>
                    <mat-form-field
                      class="col-5"
                      [ngClass]="
                        !companyItem.get('idCountry')?.value && isIsp
                          ? 'red-field-for-lab-manager'
                          : ''
                      "
                    >
                      <mat-label for="idCountry">
                        {{ "IR_FORM.Nationality" | translate }}
                      </mat-label>
                      <mat-select
                        disableOptionCentering
                        panelClass="custom-panel-class"
                        formControlName="idCountry"
                        name="idCountry"
                        (closed)="filter.value = ''"
                      >
                        <div class="select-global-filter">
                          <input
                            #filter
                            matInput
                            type="text"
                            [placeholder]="'COMMON.Search...' | translate"
                          />
                          <button
                            matSuffix
                            *ngIf="filter.value !== ''"
                            class="only-icon-button"
                            type="button"
                            (click)="filter.value = null"
                          >
                            <mat-icon>clear</mat-icon>
                          </button>
                        </div>
                        <mat-option
                          [ngStyle]="{
                            display:
                              (
                                countries | filterSelect: filter.value:'country'
                              ).includes(country) || filter.value === ''
                                ? 'flex'
                                : 'none'
                          }"
                          *ngFor="let country of countries"
                          [value]="country.idCountry"
                        >
                          {{ country.country }}
                        </mat-option>
                        <div
                          *ngIf="
                            !(countries | filterSelect: filter.value:'country')
                              ?.length
                          "
                          class="select-global-filter-no-result"
                        >
                          {{ "COMMON.NoResult" | translate }}
                        </div>
                      </mat-select>
                      <mat-error *ngIf="companyItem.controls.idCountry.invalid">
                        {{
                          companyItem.controls.idCountry.errors
                            | getErrorMessage: translations.Nationality
                        }}
                      </mat-error>
                    </mat-form-field>
                    <div
                      *ngIf="!isReadOnly && !isLabManagerInternal"
                      class="col-1 d-flex justify-content-center"
                    >
                      <button
                        class="only-icon-button primary"
                        type="button"
                        (click)="removeCompany(i, cIndex)"
                      >
                        <svg-icon name="trash_icon" class="w20"></svg-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!isReadOnly && !isLabManagerInternal"
            class="center-buttons mt-20"
          >
            <button
              type="button"
              class="rounded-button primary-border-button"
              (click)="removeChannel(i)"
            >
              {{ "COMMON.Delete" | translate }}
            </button>
          </div>
        </div>
      </div>
      <button
        *ngIf="!isReadOnly && !isLabManagerInternal"
        type="button"
        class="rounded-button primary-bg-button ml-auto"
        (click)="addChannel()"
      >
        {{ "IR_FORM.AddContent" | translate }}
      </button>
    </div>
  </div>
  <div class="section mb-50">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.PotentialDistributionChannels" | translate }}</span>
        </div>
        <div class="description">
          <span>{{
            "IR_FORM.PotentialDistributionChannelsDescription" | translate
          }}</span>
        </div>
      </div>
    </div>
    <div class="content" formArrayName="desiredChannels">
      <div
        *ngFor="let channelItem of desiredChannels.controls; let i = index"
        [formGroupName]="i"
        class="section-card my-20"
      >
        <div class="content">
          <div class="row">
            <mat-form-field
              class="col-3"
              [ngClass]="
                !channelItem.get('idChannelType')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="idChannelType">
                {{ i + 1 }}° {{ "IR_FORM.DesiredChannel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="idChannelType"
                name="idChannelType"
                (selectionChange)="
                  activateSpecificationsSelect($event, 'desired', i)
                "
              >
                <mat-option
                  *ngFor="let channelType of channelsType"
                  [value]="channelType.idChannelType"
                >
                  {{ channelType.description }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="!isReadOnly && !isLabManagerInternal"
                type="button"
                class="only-icon-button primary"
                matSuffix
                (click)="
                  showChannelsManagementDialog(
                    $event,
                    'channel',
                    null,
                    'desired'
                  )
                "
              >
                <svg-icon name="list_icon_2" class="w20"></svg-icon>
              </button>
              <mat-error *ngIf="!channelItem.controls.idChannelType.valid">{{
                channelItem.controls.idChannelType.errors
                  | getErrorMessage: translations.DesiredChannel
              }}</mat-error>
            </mat-form-field>
            <mat-form-field
              class="col-9"
              [ngClass]="
                !channelItem.get('idSpecificationChannelType')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="idSpecificationChannelType">
                {{ "IR_FORM.ChannelSpecification" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="idSpecificationChannelType"
                name="idSpecificationChannelType"
              >
                <mat-option
                  *ngFor="
                    let channelTypeSpec of specificationsChannels
                      | filterSelectByCondition
                        : channelItem.get('idChannelType').value
                        : 'idChannelType'
                  "
                  [value]="channelTypeSpec.idSpecificationChannelType"
                >
                  {{ channelTypeSpec.description }}
                </mat-option>
              </mat-select>
              <div class="d-flex" matSuffix>
                <button
                  *ngIf="!isReadOnly && !isLabManagerInternal"
                  type="button"
                  class="only-icon-button primary"
                  [disabled]="!channelItem.get('idChannelType').value"
                  (click)="
                    showChannelsManagementDialog(
                      $event,
                      'channelSpecification',
                      channelItem.get('idChannelType').value,
                      'desired'
                    )
                  "
                >
                  <svg-icon name="list_icon_2" class="w20"></svg-icon>
                </button>
                <button
                  type="button"
                  [matMenuTriggerFor]="channelSpecDescription"
                  class="only-icon-button primary ml-10"
                  (click)="$event.stopPropagation()"
                >
                  <mat-icon>info</mat-icon>
                </button>
              </div>
              <mat-menu #channelSpecDescription="matMenu">
                <div style="padding: 0 10px">
                  {{
                    "IR_FORM.ChannelSpecDescription" | translate
                  }}
                </div>
              </mat-menu>
              <mat-error
                *ngIf="!channelItem.controls.idSpecificationChannelType.valid"
                >{{
                  channelItem.controls.idSpecificationChannelType.errors
                    | getErrorMessage: translations.ChannelSpecification
                }}</mat-error
              >
            </mat-form-field>
            <!-- <mat-form-field
              class="col-3"
              [ngClass]="
                !channelItem.get('channelSpecification')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="channelSpecification">
                {{ "IR_FORM.ChannelSpecification" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="channelSpecification"
                type="text"
                name="channelSpecification"
              />
              <mat-error
                *ngIf="!channelItem.controls.channelSpecification.valid"
                >{{
                  channelItem.controls.channelSpecification.errors
                    | getErrorMessage: translations.ChannelSpecification
                }}</mat-error
              >
            </mat-form-field> -->
            <mat-form-field
              class="col-2"
              [ngClass]="
                !channelItem.get('levelOfInterest')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="levelOfInterest">
                {{ "IR_FORM.ImportanceLevel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="levelOfInterest"
                name="levelOfInterest"
              >
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>
              </mat-select>
              <mat-error *ngIf="!channelItem.controls.levelOfInterest.valid">{{
                channelItem.controls.levelOfInterest.errors
                  | getErrorMessage: translations.ImportanceLevel
              }}</mat-error>
            </mat-form-field>
            <mat-form-field
              class="col-4"
              [ngClass]="
                !channelItem.get('reason')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="reason">
                {{ "IR_FORM.Reason" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="reason"
                type="text"
                name="reason"
              />
              <mat-error *ngIf="!channelItem.controls.reason.valid">
                {{
                  channelItem.controls.reason.errors
                    | getErrorMessage: translations.Reason
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="section anagrafic-list">
            <div class="header">
              <div style="width: calc(100% - 200px)">
                <div class="title">
                  <span>{{ "IR_FORM.AnagraficListTitle" | translate }}</span>
                </div>
                <div class="description">
                  <span *ngIf="isAgent">{{
                    "IR_FORM.ChannelAnagraficDesc" | translate
                  }}</span>
                  <span *ngIf="!isAgent">{{
                    "IR_FORM.ChannelAnagraficRequiredDesc" | translate
                  }}</span>
                </div>
              </div>
              <button
                *ngIf="!isReadOnly && !isLabManagerInternal"
                type="button"
                class="rounded-button primary-border-button"
                (click)="addCompanyToDesiredChannel(i)"
              >
                {{ "IR_FORM.AddAnagrafic" | translate }}
              </button>
            </div>
            <div class="mt-20" formArrayName="companies">
              <div class="row">
                <div
                  *ngFor="
                    let companyItem of channelItem.controls.companies.controls;
                    let cIndex = index
                  "
                  [formGroupName]="cIndex"
                  class="col-6"
                >
                  <div class="row">
                    <mat-form-field
                      class="col-6"
                      [ngClass]="
                        !companyItem.get('company')?.value && isIsp
                          ? 'red-field-for-lab-manager'
                          : ''
                      "
                    >
                      <mat-label for="company">
                        {{ "IR_FORM.CompanyName" | translate }}
                      </mat-label>
                      <input
                        matInput
                        formControlName="company"
                        type="text"
                        name="company"
                      />
                      <mat-error *ngIf="!companyItem.controls.company.valid">{{
                        companyItem.controls.company.errors
                          | getErrorMessage: translations.CompanyName
                      }}</mat-error>
                    </mat-form-field>
                    <mat-form-field
                      class="col-5"
                      [ngClass]="
                        !companyItem.get('idCountry')?.value && isIsp
                          ? 'red-field-for-lab-manager'
                          : ''
                      "
                    >
                      <mat-label for="idCountry">
                        {{ "IR_FORM.Nationality" | translate }}
                      </mat-label>
                      <mat-select
                        disableOptionCentering
                        panelClass="custom-panel-class"
                        formControlName="idCountry"
                        name="idCountry"
                        (closed)="filter.value = ''"
                      >
                        <div class="select-global-filter">
                          <input
                            #filter
                            matInput
                            type="text"
                            [placeholder]="'COMMON.Search...' | translate"
                          />
                          <button
                            matSuffix
                            *ngIf="filter.value !== ''"
                            class="only-icon-button"
                            type="button"
                            (click)="filter.value = null"
                          >
                            <mat-icon>clear</mat-icon>
                          </button>
                        </div>
                        <mat-option
                          [ngStyle]="{
                            display:
                              (
                                countries | filterSelect: filter.value:'country'
                              ).includes(country) || filter.value === ''
                                ? 'flex'
                                : 'none'
                          }"
                          *ngFor="let country of countries"
                          [value]="country.idCountry"
                        >
                          {{ country.country }}
                        </mat-option>
                        <div
                          *ngIf="
                            !(countries | filterSelect: filter.value:'country')
                              ?.length
                          "
                          class="select-global-filter-no-result"
                        >
                          {{ "COMMON.NoResult" | translate }}
                        </div>
                      </mat-select>
                      <mat-error *ngIf="companyItem.controls.idCountry.invalid">
                        {{
                          companyItem.controls.idCountry.errors
                            | getErrorMessage: translations.Nationality
                        }}
                      </mat-error>
                    </mat-form-field>
                    <div
                      *ngIf="!isReadOnly && !isLabManagerInternal"
                      class="col-1 d-flex justify-content-center"
                    >
                      <button
                        class="only-icon-button primary"
                        type="button"
                        (click)="removeCompanyToDesiredChannel(i, cIndex)"
                      >
                        <svg-icon name="trash_icon" class="w20"></svg-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!isReadOnly && !isLabManagerInternal"
            class="center-buttons mt-20"
          >
            <button
              type="button"
              class="rounded-button primary-border-button"
              (click)="removeDesiredChannel(i)"
            >
              {{ "COMMON.Delete" | translate }}
            </button>
          </div>
        </div>
      </div>
      <button
        *ngIf="!isReadOnly && !isLabManagerInternal"
        type="button"
        class="rounded-button primary-bg-button ml-auto"
        (click)="addDesiredChannel()"
      >
        {{ "IR_FORM.AddContent" | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="isIsp" class="row">
    <mat-form-field
      class="col"
      [ngClass]="
        !customerDescriptionForm.get('channelDesc')?.value && isIsp
          ? 'red-field-for-lab-manager'
          : ''
      "
    >
      <mat-label for="channelDesc">
        {{ "IR_FORM.CommentForPS" | translate }}
      </mat-label>
      <textarea
        rows="2"
        matInput
        type="text"
        name="channelDesc"
        [formControl]="customerDescriptionForm.get('channelDesc')"
      ></textarea>
      <mat-error *ngIf="customerDescriptionForm.get('channelDesc').invalid">
        {{
          customerDescriptionForm.get("channelDesc").errors
            | getErrorMessage: translations.CommentForPS
        }}
      </mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="!isLabManagerInternal && !isReadOnly" class="center-buttons">
    <button type="submit" class="rounded-button primary-bg-button">
      {{ "COMMON.SaveChanges" | translate }}
    </button>
  </div>
</form>
<div class="mt-40">
  <button
    *ngIf="
      channels.getRawValue().concat(desiredChannels.getRawValue()).length >= 2
    "
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
