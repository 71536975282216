<form [formGroup]="productForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    
      <h2 mat-dialog-title>Parole chiave {{productName}}</h2>
      <div formArrayName="productMatching">
        <div *ngFor="let matching of productMatching.controls; let i = index" [formGroupName]="i">
          <div class="row">
            <div class="col-1 mt-10">
              <button type="button" (click)="removeProductMatching(i)" mat-button [disabled]="isReadOnly">
                <mat-icon [ngClass]="isReadOnly ? 'disabled-color' : 'delete-note'">clear</mat-icon>
              </button>
            </div>
            <mat-form-field class="col-5">
              <mat-label for="wordKey">{{ 'COMMON.WordKey' | translate }}</mat-label>
              <input matInput type="text" formControlName="wordKey" name="wordKey">
              <mat-error *ngIf="matching.get('wordKey').invalid">
                {{ matching.get('wordKey').errors | getErrorMessage:translations.WordKey }}
              </mat-error>
            </mat-form-field>
            <mat-form-field class="col-5">
              <mat-label for="englishWordKey">{{ 'COMMON.EnglishWordKey' | translate }}</mat-label>
              <input matInput type="text" formControlName="englishWordKey" name="englishWordKey">
              <mat-error *ngIf="matching.get('englishWordKey').invalid">
                {{ matching.get('englishWordKey').errors | getErrorMessage:translations.EnglishWordKey }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="buttons-container">
          <button type="button" (click)="addProductMatching()" mat-button class="add-note" [disabled]="isReadOnly">
            <mat-icon [ngClass]="isReadOnly ? 'disabled-color' : 'add-note'">add</mat-icon>
            {{ 'COMMON.AddWordKey' | translate }}
          </button>
          
        </div>
      </div>
    
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close [disabled]="operationInProgress">
      {{ 'COMMON.Close' | translate }}
    </button>
    <button type="submit" mat-button [disabled]="isReadOnly || operationInProgress">
      {{ 'COMMON.Save' | translate }}
    </button>
  </mat-dialog-actions>
</form>
