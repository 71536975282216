<div class="note-buttons">
  <div>
    <button
      *ngIf="isAgent || isLabManager || isLabManagerInternal"
      type="button"
      (click)="showNoteListDialog()"
      class="icon-button-big error-bg-button"
    >
      <span>
        {{ "COMMON.Visualize" | translate }}
        {{ notesStatuslist?.length }}
        {{ "COMMON.Notes" | translate | lowercase }}
      </span>
      <svg-icon name="notes_icon" class="w24"></svg-icon>
      <div class="notes-counter">
        <span>{{ notesStatuslist?.length }}</span>
      </div>
    </button>
  </div>
  <div>
    <button
      *ngIf="isAgentSupervisor || isLabManagerSupervisor"
      type="button"
      (click)="openInsertNotesDialog()"
      class="icon-button-big secondary-bg-button"
    >
      <span>{{ "NOTES_BUTTONS.AddNotes" | translate }}</span>
      <svg-icon name="edit_icon" class="w24"></svg-icon>
    </button>
  </div>
</div>

<ng-template #noteListDialog let-close="close" let-data>
  <div class="header">
    <div class="title">
      <span>{{ "NOTES_BUTTONS.PresentNotesFor" | translate }}: </span>
      <span class="fw-700">{{
        "PROJECT_SECTIONS." + tab | translate | uppercase
      }}</span>
    </div>
    <button
      class="close-dialog"
      mat-dialog-close
      mat-icon-button
      [disabled]="lockButtons"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content scrollable">
    <div class="note" *ngFor="let note of parentNotes; let i = index">
      <mat-checkbox color="primary" (change)="notesStatus($event, i)">
        {{ note.description }}
      </mat-checkbox>
    </div>
  </div>
  <div class="buttons center">
    <button
      type="button"
      class="rounded-button primary-bg-button"
      [mat-dialog-close]="true"
    >
      {{ "NOTES_BUTTONS.MarkNotesAsResolved" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #insertNotesDialog let-close="close" let-data>
  <div class="header">
    <div class="title">
      <span>{{ "NOTES_BUTTONS.AddRevisionNotesFor" | translate }}: </span>
      <span class="fw-700">
        {{ "PROJECT_SECTIONS." + tab | translate | uppercase }}
      </span>
    </div>
    <button
      class="close-dialog"
      mat-icon-button
      [disabled]="disableButtons"
      (click)="closeInsertNotesDialog()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <form [formGroup]="insertNotesForm" (ngSubmit)="saveNotes()">
    <div class="content scrollable">
      <ng-container
        class=""
        *ngFor="let note of insertNotesForm.controls; let i = index"
      >
        <div class="row align-items-center" [formGroupName]="i">
          <mat-form-field class="col-10">
            <mat-label for="description">
              {{ "IR_FORM.Description" | translate }}
            </mat-label>
            <input
              matInput
              type="text"
              formControlName="description"
              name="description"
            />
            <!-- <mat-error *ngIf="note.get('description').invalid">
              {{
                note.get("description").errors
                  | getErrorMessage: translations.Description
              }}
            </mat-error> -->
          </mat-form-field>
          <div class="col-2 d-flex justify-content-center">
            <button
              type="button"
              class="only-icon-button primary"
              (click)="removeNote(i)"
              [disabled]="disableButtons"
            >
              <svg-icon name="trash_icon" class="w20"></svg-icon>
            </button>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="buttons space-between">
      <button
        type="button"
        class="rounded-button primary-border-button"
        (click)="addNote()"
        [disabled]="disableButtons"
      >
        Aggiungi Nota
      </button>
      <button
        type="submit"
        class="rounded-button primary-bg-button"
        [disabled]="disableButtons"
      >
        {{ "NOTES_BUTTONS.ConfirmNotes" | translate }}
      </button>
    </div>
  </form>
</ng-template>
