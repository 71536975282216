import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { IChannelsType } from 'src/app/models/channels-type';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { UnitsOfMeasure } from 'src/app/models/utility';
import { ISectors } from 'src/app/models/sectors';
import { ItemFieldValueInArrayCannotBeSameOnlyString } from 'src/app/utility/validators';

@Component({
  selector: 'app-channels-registry',
  templateUrl: './channels-registry.component.html',
  styleUrls: ['./channels-registry.component.scss'],
})
export class ChannelsRegistryComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  translations: any = {};

  channelsRegistryForm: FormGroup;
  unitsOfMeasureForm: FormGroup;
  commerceSectorsFormGroup: FormGroup;

  channelsRegistry: IChannelsType[];
  unitsOfMeasure: UnitsOfMeasure[];
  commerceSectors: ISectors[];

  commerceSectorsLoading: boolean;

  constructor(
    private irFormService: IrFormService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private layoutService: LayoutService
  ) {
    this.channelsRegistryForm = this.fb.group({
      channelsType: fb.array([]),
    });
    this.unitsOfMeasureForm = this.fb.group({
      units: fb.array([]),
    });
    this.commerceSectorsFormGroup = this.fb.group({
      commerceSectors: fb.array([]),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('COMMON').subscribe((translations) => {
        this.translations = translations;
        this.generateBreadCrumb();
      })
    );
    this.getChannel();
    this.getUnitsOfMeasure();
    this.getCommerceSectors();
  }

  generateBreadCrumb(): void {
    this.subscriptions.add(
      this.layoutService.generateBreadcrumb([
        {
          label: this.translate.instant('PAGES.management-list'),
          path: null,
          pageTitle: true,
        },
      ])
    );
  }

  get channelsType(): FormArray {
    return this.channelsRegistryForm.get('channelsType') as FormArray;
  }

  get uomFormArray(): FormArray {
    return this.unitsOfMeasureForm.get('units') as FormArray;
  }

  get commerceSectorsFormArray(): FormArray {
    return this.commerceSectorsFormGroup.get('commerceSectors') as FormArray;
  }

  getChannel(): void {
    this.subscriptions.add(
      this.irFormService.getChannelsType().subscribe({
        next: (channels: IChannelsType[]) => {
          channels?.forEach((channel: IChannelsType) => {
            this.addChannels(channel);
          });
        },
      })
    );
    this.channelsType.setValidators(
      ItemFieldValueInArrayCannotBeSameOnlyString('description')
    );
  }

  getUnitsOfMeasure(): void {
    this.subscriptions.add(
      this.irFormService.getUnitsOfMeasure().subscribe({
        next: (data: UnitsOfMeasure[]) => {
          data?.forEach((uom: UnitsOfMeasure) => {
            this.addUom(uom);
          });
        },
      })
    );
    this.uomFormArray.setValidators([
      ItemFieldValueInArrayCannotBeSameOnlyString('name'),
      ItemFieldValueInArrayCannotBeSameOnlyString('description'),
    ]);
  }

  getCommerceSectors(): void {
    this.commerceSectorsFormArray.clear();
    this.commerceSectorsFormArray.markAsPristine();
    this.irFormService.getAllSectors().subscribe({
      next: (data: ISectors[]) => {
        this.commerceSectors = data;
        data.forEach((item: ISectors) => {
          this.addCommerceSector(item);
        });
      },
    });
    this.commerceSectorsFormArray.setValidators(
      ItemFieldValueInArrayCannotBeSameOnlyString('name')
    );
  }

  addChannels(channel?: IChannelsType): void {
    this.channelsType.push(
      this.fb.group({
        idChannelType: [channel?.idChannelType || null],
        description: [
          channel?.description || null,
          [Validators.required, Validators.maxLength(100)],
        ],
        idCustomer: [channel?.idCustomer],
      })
    );
  }

  addUom(uom?: UnitsOfMeasure): void {
    this.uomFormArray.push(
      this.fb.group({
        idUom: [uom?.idUom || null],
        description: [
          uom?.description || null,
          [Validators.required, Validators.maxLength(255)],
        ],
        name: [
          uom?.name || null,
          [Validators.required, Validators.maxLength(255)],
        ],
        idCustomer: [uom?.idCustomer || null],
      })
    );
  }

  addCommerceSector(item: ISectors): void {
    this.commerceSectorsFormArray.push(
      this.fb.group({
        idCommerceSector: [item?.idCommerceSector || null],
        name: [item?.name || null, Validators.required],
      })
    );
  }

  // removechannelType(i: number): void {
  //   const dialogConfig: MatDialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.width = '400px';
  //   dialogConfig.panelClass = 'custom-dialog-container';
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations?.DeleteConfirmationMessage,
  //     buttonTrue: this.translations.Yes,
  //     buttonFalse: this.translations.No,
  //   };
  //   const dialogRef: MatDialogRef<any> = this.dialog.open(
  //     ConfirmationDialogComponent,
  //     dialogConfig
  //   );
  //   dialogRef.afterClosed().subscribe((result: boolean) => {
  //     if (result) {
  //       const idChannelType = this.channelsType
  //         .at(i)
  //         .get('idChannelType').value;
  //       if (idChannelType) {
  //         this.subscriptions.add(
  //           this.irFormService.deleteChannelType(idChannelType).subscribe()
  //         );
  //       }
  //       this.channelsType.removeAt(i);
  //     }
  //   });
  // }

  // removeUom(i: number): void {
  //   const dialogConfig: MatDialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.width = '400px';
  //   dialogConfig.panelClass = 'custom-dialog-container';
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations?.DeleteConfirmationMessage,
  //     buttonTrue: this.translations.Yes,
  //     buttonFalse: this.translations.No,
  //   };
  //   const dialogRef: MatDialogRef<any> = this.dialog.open(
  //     ConfirmationDialogComponent,
  //     dialogConfig
  //   );
  //   dialogRef.afterClosed().subscribe((result: boolean) => {
  //     if (result) {
  //       const idUom: number = this.uomFormArray.at(i).get('idUom').value;
  //       if (idUom) {
  //         // TODO: aggiungere API per eliminiazione unità di misura
  //         // (il servizio deve essere creato lato BE)
  //       }
  //       this.uomFormArray.removeAt(i);
  //     }
  //   });
  // }

  // removeCommerceSector(i: number): void {
  //   const dialogConfig: MatDialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.width = '400px';
  //   dialogConfig.panelClass = 'custom-dialog-container';
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations?.DeleteConfirmationMessage,
  //     buttonTrue: this.translations.Yes,
  //     buttonFalse: this.translations.No,
  //   };
  //   const dialogRef: MatDialogRef<any> = this.dialog.open(
  //     ConfirmationDialogComponent,
  //     dialogConfig
  //   );
  //   dialogRef.afterClosed().subscribe((result: boolean) => {
  //     if (result) {
  //       const idCommerceSector: number = this.commerceSectorsFormArray
  //         .at(i)
  //         .get('idCommerceSector').value;
  //       if (idCommerceSector) {
  //         this.commerceSectorsLoading = true;
  //         this.subscriptions.add(
  //           this.irFormService
  //             .deleteCommerceSector(idCommerceSector)
  //             .subscribe({
  //               complete: () => {
  //                 this.commerceSectorsFormArray.removeAt(i);
  //                 this.commerceSectorsLoading = false;
  //               },
  //               error: () => {
  //                 this.commerceSectorsLoading = false;
  //               },
  //             })
  //         );
  //       } else {
  //         this.commerceSectorsFormArray.removeAt(i);
  //       }
  //     }
  //   });
  // }

  saveChannels(): void {
    if (this.channelsRegistryForm.valid && this.channelsRegistryForm.dirty) {
      const body: IChannelsType[] = this.channelsType.getRawValue();
      this.subscriptions.add(
        this.irFormService.addChannelsType(body).subscribe({
          complete: () => {
            this.channelsType.clear();
            this.getChannel();
          },
        })
      );
      this.channelsType.markAsPristine();
      this.channelsType.markAsUntouched();
    }
  }

  saveUnitsOfMeasure(): void {
    if (this.unitsOfMeasureForm.valid && this.unitsOfMeasureForm.dirty) {
      const body: UnitsOfMeasure[] = this.uomFormArray.getRawValue();
      this.subscriptions.add(
        this.irFormService.updateUnitsOfMeasure(body).subscribe({
          complete: () => {
            this.uomFormArray.clear();
            this.uomFormArray.markAsPristine();
            this.getUnitsOfMeasure();
          },
        })
      );
    }
  }

  saveCommerceSectors(): void {
    if (
      this.commerceSectorsFormArray.valid &&
      this.commerceSectorsFormArray.dirty
    ) {
      this.commerceSectorsFormArray.disable();
      this.irFormService
        .updateCommerceSectors(this.commerceSectorsFormArray.getRawValue())
        .subscribe({
          complete: () => {
            this.commerceSectorsFormArray.enable();
            this.getCommerceSectors();
          },
          error: () => {
            this.commerceSectorsFormArray.enable();
          },
        });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
