import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { PsSwot, SwItem, OtItem } from 'src/app/models/swot';
import { Project } from 'src/app/models/project';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';

@Component({
  selector: 'app-ps-swot',
  animations: [
    trigger('addTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
    trigger('moveTrigger', [transition('* => *', [animate('300ms')])]),
  ],
  templateUrl: './ps-swot.component.html',
  styleUrls: ['./ps-swot.component.scss'],
})
export class PsSwotComponent implements OnInit, OnDestroy {
  swotForm: FormGroup;
  private subscriptions: Subscription = new Subscription();
  idCustomer: number | string;
  idSwotUspProjectAnalysis: number | string;
  selectedTabIndex: number;

  swot: {
    strengths: SwItem[];
    weaknesses: SwItem[];
    opportunities: OtItem[];
    threats: OtItem[];
  } = {
    strengths: [],
    weaknesses: [],
    opportunities: [],
    threats: [],
  };

  swHelperMenuItems: string[];
  otHelperMenuItems: string[];

  object = Object;

  startDataOnInit: any;

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  @Input() tabGroupIndex: any;
  @Input() tabIndex: any;
  @Input() isSupervisor: any;
  @Input() isIspDelivered: any;
  @Input() isReadOnly: any;

  @Output() submitFuncEmitter: EventEmitter<PsSwot> = new EventEmitter();
  @Output() refreshSwot: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private layoutService: LayoutService,
    private irFormService: IrFormService
  ) {
    this.swotForm = fb.group({
      idSwotUspProjectAnalysis: [null],
      idProject: [null],
      idProjectStatus: [null],
      idCustomer: [this.idCustomer || null],
      swDescription: [null, [Validators.maxLength(255)]],
      otDescription: [null, [Validators.maxLength(255)]],
      uspDescription: [null, [Validators.maxLength(255)]],
      swItems: fb.array([]),
      otItems: fb.array([]),
      dateInsert: [null],
      dateModify: [null],
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          this.swot.strengths = [];
          this.swot.weaknesses = [];
          this.swot.opportunities = [];
          this.swot.threats = [];
          this.idSwotUspProjectAnalysis =
            initialValues?.psSwot?.idSwotUspProjectAnalysis;
          initialValues?.psSwot?.swItems?.forEach((item: SwItem) => {
            if (item?.idSwotUspType === 1 || item?.idSwotUspType === 5) {
              this.swot.strengths.push(item);
            }
            if (item?.idSwotUspType === 2) {
              this.swot.weaknesses.push(item);
            }
          });
          initialValues?.psSwot?.otItems?.forEach((item: OtItem) => {
            if (item?.idSwotUspType === 3) {
              this.swot.opportunities.push(item);
            }
            if (item?.idSwotUspType === 4) {
              this.swot.threats.push(item);
            }
          });
          if (initialValues?.psSwot?.idSwotUspProjectAnalysis) {
            this.swotForm.patchValue(initialValues.psSwot);
          }
          this.swHelperMenuItems = [
            this.translations.Gamma,
            this.translations.Quality,
            this.translations.ReS,
            this.translations.Patents,
            this.translations.PrePostSalesServices,
            this.translations.CommercialCapacity,
            this.translations.LoyaltyCapacity,
            this.translations.Prices,
            this.translations.SalesConditions,
            this.translations.PrePostSalesServices,
            this.translations.Marketing,
            this.translations.Communication,
            this.translations.LeadGeneration,
            this.translations.OtherToInsert,
          ];
          this.otHelperMenuItems = [
            this.translations.BarriersToEntry,
            this.translations.MarketTrend,
            this.translations.SocioEconomicFactors,
            this.translations.MarketingAndCommunicationBarriers,
            this.translations.ConsumerPurchasingBehavior,
            this.translations.ExpressedUnexpressedNeeds,
            this.translations.ConsumedProductsFeatures,
            this.translations.CompetitionProductsFeatures,
            this.translations.CompetitionCommercialBehavior,
            this.translations.CompetitionPricesAndConditions,
            this.translations.CompetitorsCommunicationAndMarketing,
            this.translations.OtherToInsert,
          ];
          this.idCustomer = initialValues?.customer?.idCustomer;
          this.swotForm.controls.idCustomer.setValue(this.idCustomer);
          this.swItems.clear();
          this.otItems.clear();
          initialValues?.psSwot?.swItems?.forEach((swItem: SwItem) => {
            this.addSwItem(swItem);
          });
          initialValues?.psSwot?.otItems?.forEach((otItem: OtItem) => {
            this.addOtItem(otItem);
          });
          this.swotForm.markAsPristine();
          this.swotForm.markAsUntouched();
          if (this.isIspDelivered || this.isReadOnly) {
            this.swotForm.disable();
          }
          this.startDataOnInit = this.swotForm.getRawValue();
        },
      })
    );
  }

  get swItems(): FormArray {
    return this.swotForm.get('swItems') as FormArray;
  }

  get otItems(): FormArray {
    return this.swotForm.get('otItems') as FormArray;
  }

  addSwItem(swItem?: SwItem): void {
    this.swItems.push(
      this.fb.group({
        idSwotUspItem: [swItem?.idSwotUspItem || null],
        title: [
          swItem?.title || '',
          [Validators.required, Validators.maxLength(50)],
        ],
        description: [
          swItem?.description || '',
          [Validators.required, Validators.maxLength(500)],
        ],
        levelOfInterest: [swItem?.levelOfInterest || '', [Validators.required]],
        idSwotUspType: [swItem?.idSwotUspType || ''],
      })
    );
  }

  addOtItem(otItem?: OtItem): void {
    this.otItems.push(
      this.fb.group({
        idSwotUspItem: [otItem?.idSwotUspItem || null],
        title: [
          otItem?.title || '',
          [Validators.required, Validators.maxLength(50)],
        ],
        description: [
          otItem?.description || '',
          [Validators.required, Validators.maxLength(500)],
        ],
        levelOfInterest: [otItem?.levelOfInterest || '', [Validators.required]],
        idSwotUspType: [otItem?.idSwotUspType || ''],
      })
    );
  }

  removeSwItem(i: number): void {
    this.swItems.removeAt(i);
    this.swotForm.markAsDirty();
  }

  removeOtItem(i: number): void {
    this.otItems.removeAt(i);
    this.swotForm.markAsDirty();
  }

  checkUspValue(item): boolean {
    return item?.controls?.idSwotUspType?.value === 5;
  }

  uspModelChange(event, item): void {
    const value: number = event.checked === true ? 5 : 1;
    item?.controls?.idSwotUspType?.setValue(value);
    this.swotForm.markAsDirty();
  }

  setTitleFromSWHelper(item: string, index: number): void {
    (this.swItems.at(index) as FormGroup).get('title').setValue(item);
    this.swItems.markAsDirty();
    this.swItems.markAsTouched();
  }

  setTitleFromOTHelper(item: string, index: number): void {
    (this.otItems.at(index) as FormGroup).get('title').setValue(item);
    this.otItems.markAsDirty();
    this.otItems.markAsTouched();
  }

  onSubmit(): void {
    if (this.swotForm.valid && this.swotForm.dirty) {
      this.submitFuncEmitter.emit(this.swotForm.getRawValue());
    }
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(this.swotForm.getRawValue(), this.irFormService.replacer)
    );
  }

  checkInvalidForm(): boolean {
    this.swotForm.markAllAsTouched();
    const swotData: any = {
      strength: 0,
      weakness: 0,
      opportunity: 0,
      threat: 0,
    };
    let invalidSwotElements: boolean = false;
    this.swotForm.getRawValue().swItems.forEach((item: any) => {
      if (item.idSwotUspType === 1 || item.idSwotUspType === 5) {
        ++swotData.strength;
      }
      if (item.idSwotUspType === 2) {
        ++swotData.weakness;
      }
    });
    this.swotForm.getRawValue().otItems.forEach((item: any) => {
      if (item.idSwotUspType === 3) {
        ++swotData.opportunity;
      }
      if (item.idSwotUspType === 4) {
        ++swotData.threat;
      }
    });
    if (
      swotData.strength === 0 &&
      swotData.weakness === 0 &&
      swotData.opportunity === 0 &&
      swotData.threat === 0
    ) {
      invalidSwotElements = true;
    }
    return this.swotForm.invalid || invalidSwotElements;
  }

  checkSelectedTab(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
