import { Component, Input, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Note } from 'src/app/models/note';

@Component({
  selector: 'app-notes-dialog',
  templateUrl: './notes-dialog.component.html',
  styleUrls: ['./notes-dialog.component.css']
})
export class NotesDialogComponent implements OnInit {

  message: string;
  notes: Note[];
  translations: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.message = data.message;
    this.translations = data.translations;
    this.notes = data.notes;
  }

  ngOnInit(): void {
  }

}
