<table
  matSort
  (matSortChange)="sortData($event)"
  *ngIf="!loader && data?.length"
  class="test-table"
  cellpadding="10"
>
  <thead>
    <tr>
      <ng-container *ngFor="let column of columns; let i = index">
        <th
          *ngIf="!column.hide"
          [mat-sort-header]="column.name"
          [disabled]="column?.disableSort"
        >
          <div>{{ column.translatedName }}</div>
          <div *ngIf="column?.legend">
            <button
              type="button"
              [matMenuTriggerFor]="legend"
              class="only-icon-button primary"
              (click)="$event.stopPropagation()"
              style="margin-left: 5px"
            >
              <mat-icon>info</mat-icon>
            </button>
            <mat-menu #legend="matMenu">
              <div *ngFor="let legend of column.legend" style="padding: 0 10px">
                {{ legend | translate }}
              </div>
            </mat-menu>
          </div>
        </th>
        <!-- LOGICA PER ALLINEAMENTO COLONNE -->
        <!-- <th
          *ngIf="!column.hide"
          [mat-sort-header]="column.name"
          [disabled]="column?.disableSort"
          [ngClass]="column?.align ? 'align-' + column?.align : null"
        >
          {{ column.translatedName }}
        </th> -->
      </ng-container>
    </tr>
  </thead>
  <tbody>
    <tr
      *ngFor="let row of data; let colorIndex = index"
      [ngClass]="{
        'new-element-badge': rowBadgeNewElements && !row.alreadyRead
      }"
    >
      <ng-container *ngFor="let column of columns">
        <td *ngIf="column.name === 'checkbox'" style="text-align: left">
          <mat-checkbox
            color="primary"
            [disabled]="columnCheckbox?.disabled(row)"
            [checked]="columnCheckbox?.checked(row)"
            (change)="columnCheckbox?.change($event, row)"
          >
            {{ columnCheckbox?.label }}
          </mat-checkbox>
        </td>
        <td
          *ngIf="
            column.name !== 'buttons' &&
            column.name !== 'text' &&
            column.name !== 'checkbox' &&
            column.name !== 'multiRowContact' &&
            column.name !== 'buttonWithHeading' &&
            !column.hide
          "
          [ngClass]="[
            column?.addClass ? column?.addClass(row) : '',
            column?.chartColor ? 'td-chart-color' : ''
          ]"
        >
          <!-- LOGICA PER ALLINEAMENTO COLONNE -->
          <!-- <td
          *ngIf="
            column.name !== 'buttons' &&
            column.name !== 'text' &&
            column.name !== 'checkbox' &&
            column.name !== 'multiRowContact' &&
            column.name !== 'buttonWithHeading' &&
            !column.hide
          "
          [ngClass]="column?.addClass ? column?.addClass(row) : null"
          [ngStyle]="{
            'justify-content': column?.align || '',
            'text-align': column?.align || ''
          }"
        > -->
          <div
            *ngIf="column?.chartColor"
            class="chart-color"
            [ngStyle]="{
              'background-color':
                chartColors[colorIndex + pageIndexForChartColor * 5]
            }"
          ></div>
          <span
            *ngIf="
              column?.type !== 'currency' &&
              column?.type !== 'date' &&
              column?.type !== 'shortDate' &&
              column?.type !== 'number' &&
              column?.type !== 'innerHTML'
            "
          >
            {{
              column?.symbol
                ? row[column?.name] + " " + column?.symbol || "--"
                : row[column?.name] || "--"
            }}
          </span>
          <span *ngIf="column?.type === 'currency'">
            {{
              (row[column?.name]
                | currency
                  : this._defaultCurrencyCode
                  : "symbol"
                  : "1.0-2"
                  : undefined) || "--"
            }}
          </span>
          <span *ngIf="column?.type === 'date' || column?.type === 'shortDate'">
            {{
              row[column?.name]
                | date: (column?.type === "date" ? "short" : "shortDate")
            }}
          </span>
          <span *ngIf="column?.type === 'number'">
            {{ (row[column?.name] | number: "1.0-2":undefined) || "--" }}
          </span>
          <span
            *ngIf="column?.type === 'innerHTML'"
            [innerHTML]="row[column.name]"
          ></span>
          <svg-icon
            *ngIf="checkIfIcon(row, column)"
            [name]="row[column.name]"
            class="w20"
          ></svg-icon>
        </td>
        <td *ngIf="column.name === 'multiRowContact'">
          <div>
            <div>
              <span class="fw-700">{{ row[column.name] }}</span>
            </div>
            <div>
              <span>{{ row.multiRowContactPhone }}</span>
            </div>
            <div>
              <span>{{ row.multiRowContactEmail }}</span>
            </div>
          </div>
        </td>
        <td
          *ngIf="column.name === 'buttonWithHeading'"
          class="d-flex"
          [ngStyle]="{
            'justify-content': column?.align || 'flex-start'
          }"
        >
          <ng-container
            *ngFor="
              let button of columnsButtonsWithHeading | tableMenuSkim: row
            "
          >
            <button
              *ngIf="!button.subMenu"
              class="only-icon-button"
              [ngClass]="button?.class"
              [disabled]="button?.disabled"
              type="button"
              (click)="button?.clickFunction(row)"
            >
              <svg-icon [name]="button.icon" class="w20"></svg-icon>
            </button>
            <button
              *ngIf="
                (button?.subMenu?.buttons &&
                  (button?.subMenu?.buttons | tableMenuSkim: row)?.length) ||
                (button?.subMenu && !button?.subMenu?.buttons)
              "
              class="only-icon-button"
              [ngClass]="button?.class"
              [disabled]="button?.disabled"
              type="button"
              [matMenuTriggerFor]="customSubMenu"
            >
              <svg-icon [name]="button.icon" class="w20"></svg-icon>
            </button>
            <mat-menu #customSubMenu="matMenu">
              <ng-container *ngIf="button?.subMenu?.buttons?.length">
                <button
                  mat-menu-item
                  *ngFor="
                    let subButton of button?.subMenu?.buttons
                      | tableMenuSkim: row
                  "
                  (click)="subButton.clickFunction(row)"
                >
                  <svg-icon
                    *ngIf="subButton?.icon"
                    [name]="button.icon"
                    [ngClass]="button?.class"
                  ></svg-icon>
                  <span *ngIf="subButton?.text">
                    {{ subButton?.text }}
                  </span>
                </button>
              </ng-container>
              <ng-container *ngIf="button?.subMenu?.key">
                <button
                  mat-menu-item
                  *ngFor="
                    let subButtonElaborated of row[button?.subMenu?.key]
                      | tableMenuSkim: row
                  "
                  (click)="button?.subMenu?.clickFunction(subButtonElaborated)"
                >
                  <!-- <img
                *ngIf="button?.subMenuElaboratorOptions?.icon"
                src="{{ '/assets/icons/' + button?.subMenuElaboratorOptions?.icon + '.svg' }}"
              > -->
                  <span *ngIf="subButtonElaborated?.name">
                    {{ subButtonElaborated?.name }}
                  </span>
                </button>
              </ng-container>
            </mat-menu>
          </ng-container>
        </td>
        <td
          *ngIf="column.name === 'buttons'"
          [ngClass]="column?.addClass ? column?.addClass(row) : null"
        >
          <div class="buttons-td">
            <ng-container
              *ngFor="let button of columnsButtons | tableMenuSkim: row"
            >
              <div class="button-wrapper" [matTooltip]="button?.tooltip">
                <button
                  *ngIf="!button.subMenu"
                  class="only-icon-button"
                  [ngClass]="button?.class"
                  [disabled]="button?.disabled"
                  type="button"
                  (click)="button?.clickFunction(row)"
                >
                  <svg-icon [name]="button.icon" class="w20"></svg-icon>
                </button>
                <button
                  *ngIf="
                    (button?.subMenu?.buttons &&
                      (button?.subMenu?.buttons | tableMenuSkim: row)
                        ?.length) ||
                    (button?.subMenu && !button?.subMenu?.buttons)
                  "
                  class="only-icon-button"
                  [ngClass]="button?.class"
                  [disabled]="button?.disabled"
                  type="button"
                  [matMenuTriggerFor]="customSubMenu"
                >
                  <svg-icon [name]="button.icon" class="w20"></svg-icon>
                </button>
                <mat-menu #customSubMenu="matMenu">
                  <ng-container *ngIf="button?.subMenu?.buttons?.length">
                    <ng-container
                      *ngFor="
                        let subButton of button?.subMenu?.buttons
                          | tableMenuSkim: row
                      "
                    >
                      <button
                        *ngIf="!subButton?.subMenu"
                        mat-menu-item
                        (click)="subButton.clickFunction(row)"
                      >
                        <svg-icon
                          *ngIf="subButton?.icon"
                          [name]="button.icon"
                          [ngClass]="button?.class"
                        ></svg-icon>
                        <span *ngIf="subButton?.text">
                          {{ subButton?.text }}
                        </span>
                      </button>
                      <!-- SUB SUB MENU BUTTON -->
                      <button
                        *ngIf="subButton?.subMenu"
                        mat-menu-item
                        [matMenuTriggerFor]="customSubSubMenu"
                      >
                        <!-- <svg-icon
                          *ngIf="subButton?.icon"
                          [name]="button.icon"
                          [ngClass]="button?.class"
                        ></svg-icon> -->
                        <span *ngIf="subButton?.text">
                          {{ subButton?.text }}
                        </span>
                      </button>
                      <mat-menu #customSubSubMenu="matMenu">
                        <ng-container
                          *ngIf="subButton?.subMenu?.buttons?.length"
                        >
                          <button
                            *ngFor="
                              let subSubButton of subButton?.subMenu?.buttons
                                | tableMenuSkim: row
                            "
                            mat-menu-item
                            (click)="subSubButton?.clickFunction(row)"
                          >
                            {{ subSubButton?.text }}
                          </button>
                        </ng-container>
                      </mat-menu>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="button?.subMenu?.key">
                    <button
                      mat-menu-item
                      *ngFor="
                        let subButtonElaborated of row[button?.subMenu?.key]
                          | tableMenuSkim: row
                      "
                      (click)="
                        button?.subMenu?.clickFunction(subButtonElaborated)
                      "
                    >
                      <!-- <img
                      *ngIf="button?.subMenuElaboratorOptions?.icon"
                      src="{{ '/assets/icons/' + button?.subMenuElaboratorOptions?.icon + '.svg' }}"
                    > -->
                      <span *ngIf="subButtonElaborated?.name">
                        {{ subButtonElaborated?.name }}
                      </span>
                    </button>
                  </ng-container>
                </mat-menu>
              </div>
            </ng-container>
          </div>
        </td>
        <td *ngIf="column.name === 'text'">
          <span [ngClass]="column?.class">
            {{ row[column.name] }}
          </span>
        </td>
      </ng-container>
    </tr>
  </tbody>
</table>
<mat-paginator
  #paginator
  [ngStyle]="{
    display: !loader && oldData?.length > 5 ? 'block' : 'none'
  }"
  [length]="oldData?.length"
  [pageSize]="5"
  (page)="pageChanged($event)"
  [pageSizeOptions]="[5, 10, 25, 50]"
  [pageIndex]="0"
>
</mat-paginator>
<div *ngIf="loader" class="skeleton-loader">
  <div class="header"></div>
  <div class="body-row"></div>
  <div class="body-row"></div>
  <div class="body-row"></div>
</div>
<div *ngIf="!data?.length && !loader" class="no-data">
  <span>{{ noDataMessage || ("COMMON.NoDataAvailable" | translate) }}</span>
</div>
