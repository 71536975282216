import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterTable'
})
export class FilterTablePipe implements PipeTransform {

  transform(items: any[], columns: any[], searchValue: string): unknown {
    if (!items || !searchValue) {
      return items;
    }
    const result: any[] = [];
    items.forEach(item => {
      columns.forEach(col => {
        const value = '' + item[col.name];
        if (value?.toLowerCase().includes(searchValue.toLowerCase())) {
          if (!result.includes(item)) {
            result.push(item);
          }
        }
      });
    });
    return result;
  }

}
