import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { dataTool } from 'echarts';
import { Subscription } from 'rxjs';
import { Roles } from 'src/app/config';
import { INotice } from 'src/app/models/note';
import { LoggedUser } from 'src/app/models/user';
import { IBreadcrumb } from 'src/app/models/utility';
import { AuthService } from 'src/app/services/auth/auth.service';
import { LayoutService } from 'src/app/services/layout/layout.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  pageTitle: string;
  user: LoggedUser;
  activeUserRole: string;
  breadcrumb: IBreadcrumb[] = [];
  notTranslatedBreadcrumb: string[];
  crmCustomer: any;
  userImage: any;
  translations: any;

  notices: any[];
  showNoticesBadge: boolean = false;
  disableNotices: boolean = false;

  isDirection: boolean;
  isAdmin: boolean;

  @Output() logoutEmitter: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('noticesBoard') noticesBoard: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private layoutService: LayoutService,
    private sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.get('COMMON').subscribe({
      next: (data: any) => {
        this.translations = data;
      },
    });
    this.refreshUserImage();
    this.getBreadcrumb();
    this.getNotices();
    this.user = JSON.parse(localStorage.getItem('session_user'));
    this.activeUserRole = this.user.activeRole;
    if (this.activeUserRole === Roles.Direction) {
      this.isDirection = true;
    }
    if (this.activeUserRole === Roles.Admin) {
      this.isDirection = true;
    }
    if (window.location.toString().includes('dashboard')) {
      this.pageTitle = 'Dashboard';
    }
    if (window.location.toString().includes('notices-board')) {
      this.disableNotices = true;
    }
    this.subscription.add(
      this.router.events.subscribe({
        next: (data: NavigationEnd) => {
          if (window.location.toString().includes('dashboard')) {
            this.pageTitle = 'Dashboard';
          }
          if (window.location.toString().includes('notices-board')) {
            this.disableNotices = true;
          } else {
            this.disableNotices = false;
          }
          if (data instanceof NavigationEnd) {
            // Check new BM if DLManager
            this.getNotices();
            // if (
            //   JSON.parse(
            //     localStorage.getItem('session_user')
            //   ).activeRole.includes(Roles.LabManager)
            // ) {
            //   this.getNotices();
            // }
          }
        },
      })
    );
  }

  // getPageTitle(translations: any): void {
  //   let queryParam: string;
  //   this.route.queryParams.subscribe((params: any) => {
  //     this.breadcrumb = [];
  //     queryParam = params?.pageTitle;
  //     this.pageTitle =
  //       translations[this.router.url.split('/').pop()] || queryParam;
  //     // Generate breadcrumb
  //     if (window.location.toString().includes('/contact')) {
  //       this.notTranslatedBreadcrumb = [
  //         'crm',
  //         params?.customer,
  //         params?.pageTitle
  //       ];
  //       this.breadcrumb = [
  //         translations.crm,
  //         params?.customer,
  //         params?.pageTitle
  //       ];
  //       this.crmCustomer = {
  //         name: params?.customer,
  //         id: params?.
  //       }
  //     } else {
  //       this.notTranslatedBreadcrumb = this.router.url.split('/');
  //       this.notTranslatedBreadcrumb.forEach((item: string) => {
  //         this.breadcrumb.push(translations[item]);
  //       });
  //       if (params?.pageTitle) {
  //         const completeUrl: string[] = this.router.url.split('/');
  //         this.breadcrumb.splice(completeUrl.length - 1, 1);
  //         this.notTranslatedBreadcrumb.splice(completeUrl.length - 1, 1);
  //         const lastUrlElement: string = completeUrl.pop();
  //         let trimmedLastUrlElement: string;
  //         if (lastUrlElement.includes('id=')) {
  //           const pretrimmedLastUrlElement: string = lastUrlElement
  //             .split('pageTitle=')
  //             .pop();
  //           trimmedLastUrlElement = pretrimmedLastUrlElement.slice(
  //             0,
  //             pretrimmedLastUrlElement.lastIndexOf('&')
  //           );
  //         } else {
  //           trimmedLastUrlElement = lastUrlElement.split('=').pop();
  //         }
  //         const decodedLastUrlElement: string = decodeURIComponent(
  //           trimmedLastUrlElement
  //         );
  //         this.breadcrumb.push(decodedLastUrlElement);
  //         this.notTranslatedBreadcrumb.push(decodedLastUrlElement);
  //       }
  //       this.breadcrumb.shift();
  //       this.notTranslatedBreadcrumb.shift();
  //     }
  //   });
  // }

  getBreadcrumb(): void {
    this.subscription.add(
      this.layoutService.breadcrumbObservable.subscribe({
        next: (breadcrumb: IBreadcrumb[]) => {
          this.breadcrumb = breadcrumb;
          breadcrumb?.forEach((link: IBreadcrumb) => {
            if (link.pageTitle) {
              this.pageTitle = link.label;
            }
          });
        },
      })
    );
  }

  getUserImage(): void {
    this.subscription.add(
      this.layoutService.getUserImage().subscribe({
        next: (data: Blob) => {
          if (data.size > 0) {
            const unsafeImage = URL.createObjectURL(data);
            this.userImage = this.sanitizer.bypassSecurityTrustUrl(unsafeImage);
          } else {
            this.userImage = null;
          }
        },
      })
    );
  }

  refreshUserImage(): void {
    this.subscription.add(
      this.layoutService.triggerRefreshUserImageObservable.subscribe({
        next: () => {
          this.getUserImage();
        },
      })
    );
  }

  goTo(path: string): void {
    this.router.navigate([`/${path}`]);
  }

  goToAccount(): void {
    this.router.navigate(['/account']);
  }

  goToNotice(notice: INotice): void {
    this.router.navigate(['/notices-board'], {
      queryParams: { id: notice.idServiceNotice },
    });
  }

  logout(): void {
    this.logoutEmitter.emit();
  }

  changeRole(role: string): void {
    this.authService.setUserInSession(
      JSON.parse(localStorage.getItem('session_user')),
      role
    );
    this.router.navigate(['']).then(() => {
      window.location.reload();
    });
  }

  getNotices(): void {
    this.layoutService.getNotices(6, true).subscribe({
      next: (data: INotice[]) => {
        this.notices = data;
        if (
          data.some((notice: INotice) => {
            return (
              notice?.alreadyRead === false || notice?.alreadyRead === null
            );
          })
        ) {
          this.showNoticesBadge = this.isDirection ? false : true;
        } else {
          this.showNoticesBadge = false;
        }
      },
    });
  }

  showNoticesBoard(): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.width = '1000px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      notices: this.notices,
    };
    if (this.notices?.length) {
      this.dialog.open(this.noticesBoard, dialogConfig);
      if (
        this.notices.some((notice: INotice) => {
          return !notice.alreadyRead;
        }) &&
        !this.isDirection &&
        !this.isAdmin
      ) {
        const noticesToMarkAsReaded: number[] = [];
        this.notices.forEach((notice: INotice) => {
          if (!notice.alreadyRead) {
            noticesToMarkAsReaded.push(notice.idServiceNotice);
          }
        });
        this.layoutService.markNoticesAsRead(noticesToMarkAsReaded).subscribe({
          next: () => {},
          complete: () => {
            this.getNotices();
          },
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
