import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { IFileToHandle, ToastStatus } from 'src/app/models/utility';
import { FileLimit } from '../../config';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  translations: any;
  private uploadError: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  @Input() uploadDirectType: boolean = false; // Use true when the file will be uploaded immediately on click button
  @Input() downloadFileFromName: boolean = false;
  @Input() fileType: string;
  @Input() uploadingFileIcon: string;
  @Input() showDocumentName: boolean = false;
  @Input() namePosition: string = 'bottom';
  @Input() fileToHandle: IFileToHandle = null;
  @Input() disableButton: boolean = false;
  @Input() hideButton: boolean = false;
  @Input() uploadingFile: boolean = false;
  @Input() buttonClass: string;
  @Input() idInputFile: string = 'upload-document';
  @Input()
  set parentUploadError(value: boolean) {
    this.uploadError.next(value);
  }
  get parentUploadError(): boolean {
    return this.uploadError.getValue();
  }

  sizeTypeFileError: boolean = false;
  maxSize = FileLimit.maxSize;
  fileTypes = FileLimit.fileTypes;
  fileToUpload: File;
  showError: boolean = false;

  @Output() uploadFileEmitter: EventEmitter<File> = new EventEmitter();
  @Output() downloadFileEmitter: EventEmitter<any> = new EventEmitter();

  @ViewChild('file', { static: false }) documentToUpload: ElementRef;

  constructor(
    private translate: TranslateService,
    private common: CommonService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('UPLOAD_FILE').subscribe((data) => {
        this.translations = data;
      })
    );
    this.subscriptions.add(
      this.uploadError.subscribe((_uploadError) => {
        if (_uploadError) {
          this.fileToUpload = null;
          this.fileToHandle = null;
          this.showError = true;
        } else {
          this.showError = false;
        }
      })
    );
  }

  handleFileInput(files: FileList): void {
    this.sizeTypeFileError = false;
    if (files && files.item(0)) {
      const typeSpit = files.item(0).type.split('/');
      if (
        files.item(0).size <= FileLimit.maxSize * 1024 * 1024 &&
        FileLimit.fileTypesSplit.includes(typeSpit[1])
      ) {
        this.fileToUpload = files.item(0);
        if (this.fileToHandle) {
          this.fileToHandle.name = files.item(0).name;
        }
        this.uploadFileEmitter.emit(this.fileToUpload);
        this.documentToUpload.nativeElement.value = null;
      } else {
        this.sizeTypeFileError = true;
        this.translate
          .get('UPLOAD_FILE.UnableToUploadDocument', {
            size: this.maxSize,
            types: this.fileTypes,
          })
          .subscribe((error: string) => {
            this.common.showToast(error, ToastStatus.error, 3000);
          });
      }
    }
  }

  changeLabel(): string {
    if (this.uploadDirectType === true) {
      if (this.fileType) {
        return `${this.translations.Upload} ${this.fileType}`;
      } else {
        return this.translations.Upload;
      }
    } else {
      if (this.fileType) {
        if (this.fileToUpload?.name || this.fileToHandle?.name) {
          return `${this.translations.Change} ${this.fileType}`;
        } else {
          return `${this.translations.Upload} ${this.fileType}`;
        }
      } else {
        if (this.fileToUpload?.name || this.fileToHandle?.name) {
          return this.translations.Change;
        } else {
          return this.translations.Upload;
        }
      }
    }
  }

  // changeIcon(): string {
  //   if (this.uploadDirectType === true) {
  //     return 'publish';
  //   } else {
  //     if (this.fileToUpload?.name || this.fileToHandle?.name) {
  //       return 'autorenew';
  //     } else {
  //       if (this.uploadingFileIcon) {
  //         return this.uploadingFileIcon;
  //       } else {
  //         return 'publish';
  //       }
  //     }
  //   }
  // }

  downloadFile(): void {
    this.downloadFileEmitter.emit();
  }

  removeFile(): void {
    this.documentToUpload.nativeElement.value = null;
    this.fileToUpload = null;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
