import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  ISearchProductDetail,
  ISearchProductForTable,
} from 'src/app/models/search-product';
import { IColumnsName, ITableOptions } from 'src/app/models/utility';
import { fromProductSearchToProductSearchTable } from 'src/app/utility/elaborations';

@Component({
  selector: 'app-search-product-table',
  templateUrl: './search-product-table.component.html',
  styleUrls: ['./search-product.component.scss'],
})
export class SearchProductTableComponent implements OnInit, OnChanges {
  productsDataSource: MatTableDataSource<ISearchProductForTable> =
    new MatTableDataSource<ISearchProductForTable>();

  @Input() productDetails: ISearchProductDetail[];
  @Input() translations: any;

  columnsName: IColumnsName[] = [];
  displayedColumns: string[] = [
    'name',
    'description',
    'businessName',
    'country',
    'buyerReference',
    'telephone',
    'email',
    'valueYear',
  ];
  tableOptions: ITableOptions = {
    showFilter: true,
    showSort: true,
    showPaginator: true,
  };
  loader: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.columnsName = [
      { value: 'name', name: this.translations?.Product },
      { value: 'description', name: this.translations?.Description },
      { value: 'businessName', name: this.translations?.BusinessName },
      { value: 'country', name: this.translations?.Country },
      { value: 'buyerReference', name: this.translations?.BuyerReferent },
      { value: 'telephone', name: this.translations?.Telephone },
      { value: 'email', name: this.translations?.Email },
      { value: 'valueYear', name: this.translations?.ValueYear },
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    const products: ISearchProductDetail[] =
      changes.productDetails?.currentValue;
    if (products?.length) {
      this.productsDataSource.data = fromProductSearchToProductSearchTable(
        products,
        null
      );
    }
  }
}
