<!-- MASTER AND DEMO PROJECT (DL MANAGER DEMONSTRATOR) -->
<div
  *ngIf="isLabManager && isDemonstrator && !psSupervision"
  class="section-card"
>
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "IR_IN_PROGRESS.MasterPS" | translate }}</span>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="masterAndClonedProjectLoader"
      [columns]="masterAndClonedProjectsTableColumns"
      [columnsButtons]="masterProjectTableColumnsButtons"
      [data]="masterProject"
    >
    </app-table>
  </div>
  <div class="header multi">
    <div class="title">
      <span>
        {{ "IR_IN_PROGRESS.ClonedPS" | translate }}
      </span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!clonedProjects?.length"
        (emitSearchValue)="searchValue($event, 'masterAndCloned')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="masterAndClonedProjectLoader"
      [columns]="masterAndClonedProjectsTableColumns"
      [columnsButtons]="clonedProjectsTableColumnsButtons"
      [data]="clonedProjects"
      [searchValue]="searchValueMasterAndClonedProjects"
    >
    </app-table>
  </div>
</div>
<!-- REJECTED PROJECTS (AGENT & DLMANAGER & INTERNAL) -->
<div
  *ngIf="isAgent || (isLabManager && !psSupervision) || isLabManagerInternal"
  class="section-card mt-30"
>
  <div class="header h70 multi">
    <div class="title">
      <span *ngIf="isAgent">
        {{ "IR_IN_PROGRESS.RejectedAssessment" | translate }}
      </span>
      <span *ngIf="isLabManager || isLabManagerInternal">
        {{ "IR_IN_PROGRESS.RejectedStrategicPlans" | translate }}
      </span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!rejectedProjects?.length"
        (emitSearchValue)="searchValue($event, 'rejected')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="rejectedProjectsLoader"
      [columns]="rejectedProjectsTableColumns"
      [columnsButtons]="rejectedProjectsTableColumnsButtons"
      [data]="rejectedProjects"
      [searchValue]="searchValueRejectedProjects"
    >
    </app-table>
  </div>
</div>
<!-- WORKING PROJECTS (AGENT & DLMANAGER & INTERNAL) -->
<div
  *ngIf="isAgent || (isLabManager && !psSupervision) || isLabManagerInternal"
  class="section-card mt-30"
>
  <div class="header h70 multi">
    <div class="title">
      <span *ngIf="isAgent">
        {{ "IR_IN_PROGRESS.WorkingAssessment" | translate }}
      </span>
      <span *ngIf="isLabManager || isLabManagerInternal">
        {{ "IR_IN_PROGRESS.WorkingPs" | translate }}
      </span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!inProgressProjects?.length"
        (emitSearchValue)="searchValue($event, 'in progress')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="inProgressProjectsLoader"
      [columns]="inProgressProjectsTableColumns"
      [columnsButtons]="inProgressProjectsTableColumnsButtons"
      [data]="inProgressProjects"
      [searchValue]="searchValueInProgressProjects"
    >
    </app-table>
  </div>
</div>
<!-- SEND TO APPROVE PROJECTS (AGENT & DLMANAGER & INTERNAL) -->
<div
  *ngIf="isAgent || (isLabManager && !psSupervision) || isLabManagerInternal"
  class="section-card mt-30"
>
  <div class="header h70 multi">
    <div class="title">
      <span *ngIf="isAgent">
        {{ "IR_IN_PROGRESS.InApprovationAssessment" | translate }}
      </span>
      <span *ngIf="(isLabManager && !psSupervision) || isLabManagerInternal">
        {{ "IR_IN_PROGRESS.InApprovationPs" | translate }}
      </span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!sendToApproveProjects?.length"
        (emitSearchValue)="searchValue($event, 'send to approve')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="sendToApproveProjectsLoader"
      [columns]="sendToApproveProjectsTableColumns"
      [columnsButtons]="sendToApproveProjectsTableColumnsButtons"
      [data]="sendToApproveProjects"
      [searchValue]="searchValueSendToApproveProjects"
    >
    </app-table>
  </div>
</div>
<!-- TO APPROVE PROJECTS (AGENT_SUPERVISOR & DLMANAGER & DLMANAGER_SUPERVISOR ) -->
<div
  *ngIf="
    isAgentSupervisor ||
    (isLabManager && psSupervision) ||
    isLabManagerSupervisor
  "
  class="section-card"
>
  <div class="header h70 multi">
    <div class="title">
      <span *ngIf="isAgentSupervisor">
        {{ "IR_IN_PROGRESS.ToApproveAssessment" | translate }}
      </span>
      <span *ngIf="isLabManagerSupervisor || (isLabManager && psSupervision)">
        {{ "IR_IN_PROGRESS.ToApproveIsp" | translate }}
      </span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!toApproveProjects?.length"
        (emitSearchValue)="searchValue($event, 'to approve')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="toApproveProjectsLoader"
      [columns]="toApproveProjectsTableColumns"
      [columnsButtons]="toApproveProjectsTableColumnsButtons"
      [data]="psSupervision ? toApproveInternalPs : toApproveProjects"
      [searchValue]="searchValueToApproveProjects"
    >
    </app-table>
  </div>
</div>

<div
  *ngIf="
    isAgentSupervisor ||
    isLabManagerSupervisor ||
    (isLabManager && psSupervision)
  "
  class="section-card mt-30"
>
  <div class="header h70 multi">
    <div class="title">
      <span *ngIf="isAgentSupervisor">
        {{ "IR_IN_PROGRESS.RejectedAssessment" | translate }}
      </span>
      <span *ngIf="isLabManagerSupervisor || isLabManager">
        {{ "IR_IN_PROGRESS.RejectedStrategicPlans" | translate }}
      </span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!rejectedProjects?.length"
        (emitSearchValue)="searchValue($event, 'rejected')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="rejectedProjectsLoader"
      [columns]="rejectedProjectsTableColumns"
      [columnsButtons]="rejectedProjectsTableColumnsButtons"
      [data]="rejectedProjects"
      [searchValue]="searchValueRejectedProjects"
    >
    </app-table>
  </div>
</div>
<!-- BM PROJECTS (AGENT) -->
<div *ngIf="isAgent" class="section-card mt-30">
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "IR_IN_PROGRESS.BMAssessment" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!bmProjects?.length"
        (emitSearchValue)="searchValue($event, 'bm')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="bmProjectsLoader"
      [columns]="bmProjectsTableColumns"
      [columnsButtons]="bmProjectsTableColumnsButtons"
      [data]="bmProjects"
      [searchValue]="searchValueBmProjects"
    >
    </app-table>
  </div>
</div>
<!-- APPROVED PROJECTS (AGENT_SUPERVISOR & DLMANAGER & DLMANAGER_SUPERVISOR) -->
<div
  *ngIf="
    isAgentSupervisor ||
    isLabManagerSupervisor ||
    (isLabManager && psSupervision)
  "
  class="section-card mt-30"
>
  <div class="header h70 multi">
    <div class="title">
      <span *ngIf="isAgentSupervisor">
        {{ "IR_IN_PROGRESS.ApprovedAssessment" | translate }}
      </span>
      <span *ngIf="isLabManagerSupervisor || (isLabManager && psSupervision)">
        {{ "IR_IN_PROGRESS.ApprovedPs" | translate }}
      </span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!approvedProjects?.length"
        (emitSearchValue)="searchValue($event, 'approved')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="approvedProjectsLoader"
      [columns]="approvedProjectsTableColumns"
      [columnsButtons]="approvedProjectsTableColumnsButtons"
      [data]="approvedProjects"
      [searchValue]="searchValueApprovedProjects"
    >
    </app-table>
  </div>
</div>

<!-- DIRECTION -->
<div *ngIf="isDirection" class="section-card">
  <div class="header h70 multi">
    <div class="title">
      <span>
        {{ "IR_IN_PROGRESS.ProjectsToAssign" | translate }}
      </span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!projectsToAssign?.length"
        (emitSearchValue)="searchValue($event, 'projectsToAssign')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="projectsToAssignLoader"
      [columns]="projectsToAssignTableColumns"
      [columnsButtons]="projectsToAssignTableColumnsButtons"
      [data]="projectsToAssign"
      [searchValue]="searchValueProjectsToAssign"
    >
    </app-table>
  </div>
</div>

<div *ngIf="isDirection" class="section-card mt-30">
  <div class="header h70 multi">
    <div class="title">
      <span>
        {{ "IR_IN_PROGRESS.ProjectsAssigned" | translate }}
      </span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!projectsAssigned?.length"
        (emitSearchValue)="searchValue($event, 'projectsAssigned')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="projectsAssignedLoader"
      [columns]="projectsAssignedTableColumns"
      [columnsButtons]="projectsAssignedTableColumnsButtons"
      [data]="projectsAssigned"
      [searchValue]="searchValueProjectsAssigned"
    >
    </app-table>
  </div>
</div>

<div *ngIf="isDirection" class="section-card mt-30">
  <div class="header h70 multi">
    <div class="title">
      <span>
        {{ "IR_IN_PROGRESS.Projects" | translate }}
      </span>
    </div>
    <!-- <div class="search-bar">
      <app-search-bar
        [disabled]="!projects?.length"
        (emitSearchValue)="searchValue($event, 'projects')"
      ></app-search-bar>
    </div> -->
    <div class="d-flex">
      <div class="mt-30" style="width: 250px">
        <mat-form-field style="width: 250px">
          <mat-label for="status">{{
            "COMMON.ProjectStatus" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            name="status"
            (selectionChange)="setSelectedStatus()"
            [(value)]="statusSelected"
          >
            <mat-option
              *ngFor="let status of statusSelect"
              [value]="status.value"
            >
              {{ status.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="showWorkingVersionSelect"
        class="mt-30 ml-30"
        style="width: 250px"
      >
        <mat-form-field style="width: 250px">
          <mat-label for="status">{{ "COMMON.Version" | translate }}</mat-label>
          <mat-select
            disableOptionCentering
            name="version"
            (selectionChange)="setSelectedStatus()"
            [(value)]="workingVersionSelected"
          >
            <mat-option
              *ngFor="let version of workingVersionSelect"
              [value]="version.value"
            >
              {{ version.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div
        *ngIf="showToApproveVersionSelect"
        class="mt-30 ml-30"
        style="width: 250px"
      >
        <mat-form-field style="width: 250px">
          <mat-label for="status">{{ "COMMON.Version" | translate }}</mat-label>
          <mat-select
            disableOptionCentering
            name="version"
            (selectionChange)="setSelectedStatus()"
            [(value)]="toApproveVersionSelected"
          >
            <mat-option
              *ngFor="let version of toApproveVersionSelect"
              [value]="version.value"
            >
              {{ version.label | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="projectsLoader"
      [columns]="projectsTableColumns"
      [columnsButtons]="projectsTableColumnsButtons"
      [data]="projects"
      [searchValue]="searchValueProjects"
    >
    </app-table>
  </div>
</div>

<!-- Revision Notes Dialog -->
<ng-template #revisionNotesDialog let-data>
  <div class="header">
    <div class="title">
      <span>
        {{ "COMMON.Notes" | translate }}
        {{
          data.idProjectStatus < 3
            ? ("COMMON.Assessment" | translate)
            : ("COMMON.PS" | translate)
        }}
        {{ "COMMON.rejected" | translate }}:
      </span>
      <span class="ml-05 fw-700">
        {{ data.businessName }}
      </span>
    </div>
    <button class="close-dialog" mat-dialog-close mat-icon-button>
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content scrollable">
    <div class="project-section-notes" *ngFor="let section of projectSections">
      <div
        class="project-section mb-10"
        *ngIf="data?.projectSections[section]?.length"
      >
        <span class="fw-700">{{
          "PROJECT_SECTIONS." + section | translate
        }}</span>
      </div>
      <div
        class="note-container mb-10"
        *ngFor="let note of data.projectSections[section]"
      >
        <div class="note-date">
          <span>{{ note.dateInsert | date: "short" }}</span>
        </div>
        <div class="note-description">
          {{ note.description }}
        </div>
      </div>
    </div>
  </div>
</ng-template>
