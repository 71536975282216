import { Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { fromProjectDtoToProjectTable } from 'src/app/utility/elaborations';
import { IProjectForTable, ProjectDTO } from 'src/app/models/project';
import { IColumnsName, ITableButtons, ITableOptions } from 'src/app/models/utility';
import { DOC_TYPE, RI_TAB } from 'src/app/config';
import { checkIrDocument, checkIrStatus, checkIspDocument, checkIspStatus, checkIspDelivered2, checkModify } from 'src/app/utility/table';
import { IrRejectDialogComponent } from 'src/app/components/ir-reject-dialog/ir-reject-dialog.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-ir-to-approve-table',
  templateUrl: './ir-to-approve-table.component.html',
  styleUrls: ['./ir-to-approve.component.css']
})
export class IrToApproveTableComponent implements OnInit, OnChanges, OnDestroy {

  subscriptions: Subscription = new Subscription();

  projectDataTable: MatTableDataSource<IProjectForTable> = new MatTableDataSource<IProjectForTable>();

  riTabs: string[] = Object.values(RI_TAB);

  translate: any;
  isLabManager: boolean = false;
  isSupervisor: boolean = false;

  @Input() loader: boolean;
  @Input() translations: any;
  @Input() projectData: ProjectDTO[];
  @Input() columnsName: IColumnsName[] = [
    {value: 'businessName', name: 'Ragione Sociale'},
    {value: 'telephoneNumber', name: 'Telefono'},
    {value: 'email', name: 'E-Mail'},
    {value: 'address', name: 'Indirizzo'},
    {value: 'agent', name: 'Manager'},
    {value: 'lastModified', name: 'Ultima lavorazione'},
    {value: 'code', name: 'Codice Progetto'},
    {value: 'psVersion', name: 'Versione'}
  ];
  @Input() displayedColumns: string[] = [
    'lastModified',
    'code',
    'businessName',
    'telephoneNumber',
    'email',
    'address',
    'agent',
    'psVersion',
    'menuButtons'
  ];
  @Input() tableMenuButtons: ITableButtons[];
  @Input() tableOptions: ITableOptions = {
    showFilter: true,
    showSort: true,
    showPaginator: true,
    showButtons: true
  };

  @Output() reloadDataEmitter: EventEmitter<void> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private irFormService: IrFormService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.authService.userProfile?.roles.forEach((role) => {
      if (role === 'LAB_MANAGER'){
        this.isLabManager = true;
      }
      if (role === 'LAB_MANAGER_SUPERVISOR'){
        this.isSupervisor = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const projectData: ProjectDTO[]  = changes.projectData?.currentValue || [];
    if (projectData?.length || projectData.length === 0) {
      this.projectDataTable.data = projectData.map(fromProjectDtoToProjectTable);
    }
    if (this.translations) {
      this.translate = this.translations;
      this.tableMenuButtons = [
        {
          name: this.translate.Approve,
          disabled: (original: IProjectForTable) => !checkIrDocument(original),
          conditions: checkIrStatus,
          click: (original: IProjectForTable) => { this.approveIr(original); },
          icon: 'done'
        },
        {
          name: this.translate.Reject,
          conditions: checkIrStatus,
          click: (original: IProjectForTable) => { this.rejectIr(original); },
          icon: 'undo'
        },
        {
          name: this.translate.Approve,
          // disabled: (original: IProjectForTable) => !checkIspDocument(original),
          conditions: checkIspStatus,
          click: (original: IProjectForTable) => { this.approveIsp(original); },
          icon: 'done'
        },
        {
          name: this.translate.Reject,
          conditions: checkIspStatus,
          click: (original: IProjectForTable) => { this.rejectIsp(original); },
          icon: 'undo'
        },
        {
          name: this.isLabManager && !this.isSupervisor ? this.translate.Visualize : this.translate.Modify,
          conditions: checkModify,
          click: (original: IProjectForTable) => { this.modifyIr(original); },
          icon: this.isLabManager && !this.isSupervisor ? 'preview' : 'edit'
        },
        {
          name: this.translate.Visualize,
          conditions: checkIspDelivered2,
          click: (original: IProjectForTable) => { this.visualizeIsp(original); },
          icon: 'preview'
        }
      ];
    }
  }

  modifyIr(project: IProjectForTable): void {
    this.router.navigate(['/assessment', project.idProject]);
  }

  /**
   * @description Approve selected project
   * @param project The project selected
   */
  approveIr(project: IProjectForTable): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translate.ApproveIrMessageConfirmation,
      centerTextMessage: true
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    this.subscriptions.add(dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.subscriptions.add(this.irFormService.approveIr(project.idProject).subscribe({
          complete: () => {
            this.reloadDataEmitter.emit();
          }
        }));
      }
    }));
  }

  /**
   * @description Reject selected project
   * @param project The project selected
   */
  rejectIr(project: IProjectForTable): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translate.RejectIrMessageConfirmation,
      centerTextMessage: true
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    this.subscriptions.add(dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.subscriptions.add(this.irFormService.rejectIr(project.idProject).subscribe({
          complete: () => {
            this.reloadDataEmitter.emit();
          }
        }));
      }
    }));
  }

  /**
   * @description Approve selected ISP
   * @param project The ISP selected
   */
  approveIsp(project: IProjectForTable): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translate.ApproveIspMessageConfirmation,
      centerTextMessage: true
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    this.subscriptions.add(dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.subscriptions.add(this.irFormService.approveIsp(project.idProject).subscribe({
          complete: () => {
            this.reloadDataEmitter.emit();
          }
        }));
      }
    }));
  }

  /**
   * @description Reject selected ISP
   * @param project The ISP selected
   */
  rejectIsp(project: IProjectForTable): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations.RejectIspMessageConfirmation,
      centerTextMessage: true
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    this.subscriptions.add(dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.subscriptions.add(this.irFormService.rejectIsp(project.idProject).subscribe({
          complete: () => {
            this.reloadDataEmitter.emit();
          }
        }));
      }
    }));
  }

  visualizeIr(project: IProjectForTable): void {
    let idDocument: number | string;
    project.documents.forEach(data => {
      if (data.type === DOC_TYPE.RI || data.type === DOC_TYPE.CONTRACT) {
        idDocument = data.idDocument;
      }
    });
    this.subscriptions.add(this.irFormService.downloadDocument(idDocument).subscribe());
  }

  visualizeIsp(project: IProjectForTable): void {
    this.router.navigate(['/ps-form', project.idProject, project.customer.idCustomer]);
  }  

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
