import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MeetingsService } from 'src/app/services/meetings/meetings.service';
import {
  APPOINTMENT_STATE,
  APPOINTMENT_TYPE,
  APP_CONTEX,
  BUYER_STATUS,
  DashboardStatisticsOutputType,
  DATE_PIKER_FORMATS,
  Pages,
  PS_VERSION,
  RI_STATUS,
  RI_STATUS_DESCRIPTION,
  Roles,
  VARIABLES_COLORS,
} from 'src/app/config';
import {
  ITableButton,
  ITableColumn,
  ITableOptions,
  ITop3ManagersData,
  ITopManager,
} from 'src/app/models/utility';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import {
  IProjectCustomer,
  IProjectForTable,
  IProjectInfo,
  ProjectDTO,
} from 'src/app/models/project';
import { ICustomer, ICustomers } from 'src/app/models/customer';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { EChartsOption } from 'echarts';
import { IAppointment } from 'src/app/models/appointment';
import { MatSelectChange } from '@angular/material/select';
import { CrmService } from 'src/app/services/crm/crm.service';
import { IContactActivity } from 'src/app/models/contact';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { IAgent } from 'src/app/models/roles';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { FormControl } from '@angular/forms';
import { IManagerStatistics } from 'src/app/models/dashboard';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { fromProjectDtoToProjectTable } from 'src/app/utility/elaborations';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  translations: any;

  userRole: string;
  idUser: number;
  // Role conditions
  isAdmin: boolean = false;
  isAgent: boolean = false;
  isAgentSupervisor: boolean = false;
  isLabManager: boolean = false;
  isLabManagerInternal: boolean = false;
  isLabManagerSupervisor: boolean = false;
  isSupervisor: boolean = false;
  isDirection: boolean = false;

  pages: any = Pages;
  meetings: IAppointment[];
  nextMeetings: IAppointment[] = [];
  nextMeetingsLoader: boolean = false;
  irInProgress: ProjectDTO[];
  ispInProgress: ProjectDTO[];
  irDelivered: ProjectDTO[];
  customers: ICustomer[] = [];
  customersLoader: boolean = false;
  appointmentType: any = APPOINTMENT_TYPE;

  progressBar: { [key: string]: boolean } = {
    meeting: false,
    RiWorking: false,
    IspWorking: false,
    RiSended: false,
    Customer: false,
  };

  tableOptions: ITableOptions = {
    showSort: false,
    showButtons: true,
    showFilter: false,
    showPaginator: false,
  };

  // New data
  selectedRangeTime: string;
  userStatistics: any;
  userStatisticsChartOptions: EChartsOption = {};
  directionStatisticsChartOptions: EChartsOption = {};
  statisticsLoader: boolean = false;

  // selectedAgentSupervisorDataFilter: any = 'entireSalesForce';

  followedCustomers: any;
  followedCustomersLoader: boolean = false;
  followedCustomersTableColumns: ITableColumn[];
  followedCustomersTableButtons: ITableButton[];
  followedCustomersSearchValue: string;

  mandatoryActivities: IContactActivity[];
  mandatoryActivitiesLoader: boolean = false;
  mandatoryActivitiesTableColumns: ITableColumn[];
  mandatoryActivitiesTableButtons: ITableButton[];
  mandatoryActivitiesSearchValue: string;

  agentsManagedBySupervisor: any[];
  agentSupervisorAppointmentsStatistics: {};
  monthsForGraphs: string[] = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  filterStatisticsAgent: FormControl = new FormControl('entireSalesForce');
  filterStatisticsStartDate: FormControl = new FormControl(
    moment().utc().startOf('week').add(1, 'day').toISOString()
  );
  filterStatisticsEndDate: FormControl = new FormControl(
    moment().utc().endOf('week').add(1, 'day').toISOString()
  );

  managerStatistics: any;

  projectsToAssign: IProjectForTable[];
  projectsToAssignTableColumns: ITableColumn[];
  projectsToAssignTableButtons: ITableButton[];
  projectsToAssignLoader: boolean = false;
  assignedProjects: IProjectForTable[];
  assignedProjectsTableColumns: ITableColumn[];
  assignedProjectsTableButtons: ITableButton[];
  assignedProjectsLoader: boolean = false;

  top3ManagersData: ITop3ManagersData;
  topManagers: ITopManager[] = [];

  @ViewChild('customerDialog') customerDialog: TemplateRef<any>;

  constructor(
    public router: Router,
    private irFormService: IrFormService,
    private meetingsService: MeetingsService,
    private translate: TranslateService,
    private crmService: CrmService,
    private layoutService: LayoutService,
    private dashboardService: DashboardService,
    private dialog: MatDialog,
    private common: CommonService
  ) {}

  ngOnInit(): void {
    // Set actual user
    this.idUser = JSON.parse(localStorage.getItem('session_user')).idUser;
    // Check role
    this.userRole = JSON.parse(localStorage.getItem('session_user')).activeRole;
    // Get translations
    this.subscriptions.add(
      this.translate.get('CRM').subscribe({
        next: (data: any) => {
          this.translations = data;
          // Check role conditions
          if (this.userRole === Roles.Agent) {
            this.isAgent = true;
          }
          if (this.userRole === Roles.AgentSupervisor) {
            this.isAgentSupervisor = true;
            this.selectedRangeTime = 'currentYear';
            this.getAgentManagedBySupervisor();
            this.getAllCustomer();
            this.getAgentSupervisorAppointments();
            this.getSupervisorNextMeetings();
            // this.getAgentSupervisorAppointmentsStatistics();
            this.getDashboardGraphStatistics(
              new MatSelectChange(null, 'currentYear')
            );
          }
          // DLManager
          if (this.userRole === Roles.LabManager) {
            this.isLabManager = true;
            this.getFollowedCustomers();
            this.getManagerStatistics();
            this.getMandatoryActivities();
          }
          // DLManager Internal
          if (this.userRole === Roles.LabManagerInternal) {
            this.isLabManagerInternal = true;
            this.getMandatoryActivities();
            this.getFollowedCustomers();
          }
          if (this.userRole === Roles.LabManagerSupervisor) {
            this.isLabManagerSupervisor = true;
            this.getManagedCustomersForSupervisor();
            this.getMandatoryActivities();
            this.getSupervisorManagerStatistics();
          }
          if (this.userRole === Roles.Direction) {
            this.selectedRangeTime = 'currentYearPreviousYear';
            this.isDirection = true;
            this.getProjectsToAssign();
            this.getAssignedProjects();
            this.getDirectionGrapichStatistics(
              new MatSelectChange(null, 'currentYearPreviousYear')
            );
            this.getTop3Managers();
          }
          if (this.userRole === Roles.Admin) {
            this.selectedRangeTime = 'currentYearPreviousYear';
            this.isAdmin = true;
            this.getDirectionGrapichStatistics(
              new MatSelectChange(null, 'currentYearPreviousYear')
            );
            this.getTop3Managers();
          }
          // All gets
          if (this.isAgent) {
            this.selectedRangeTime = 'currentYear';
            this.getMeetings();
            this.getNextMeetings();
            this.getAllCustomer();
            this.getUserStatistics(new MatSelectChange(null, 'currentYear'));
          }
        },
      })
    );
  }

  getMeetings(): void {
    const firstDayOfCurrentMonth: any = moment()
      .utc()
      .startOf('month')
      .toISOString();
    const lastDayOfNextMonth: any = moment()
      .utc()
      .add(1, 'month')
      .endOf('month')
      .toISOString();
    // this.nextMeetingsLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getAgentAppointment(
          firstDayOfCurrentMonth,
          lastDayOfNextMonth,
          this.idUser,
          APPOINTMENT_STATE.Created
        )
        .subscribe({
          next: (appointments: IAppointment[]) => {
            this.meetings = appointments.map(
              (appointment: IAppointment): any => ({
                ...appointment,
                showStartDate: moment(appointment.startDate).format(
                  'DD/MM/YYYY - HH:mm'
                ),
              })
            );
            // this.nextMeetings = [];
            // this.meetings.forEach((meet: IAppointment, index: number) => {
            //   if (
            //     moment(meet.startDate).valueOf() >
            //     moment().startOf('week').add(1, 'day').valueOf()
            //   ) {
            //     if (
            //       index < 6 &&
            //       moment(meet.startDate).valueOf() >
            //         moment().endOf('week').add(1, 'day').valueOf()
            //     ) {
            //       this.nextMeetings.push(meet);
            //     }
            //   }
            // });
            // this.nextMeetingsLoader = false;
          },
        })
    );
  }

  getAgentSupervisorAppointments(): void {
    const firstDayOfCurrentMonth: any = moment()
      .utc()
      .startOf('month')
      .toISOString();
    const lastDayOfNextMonth: any = moment()
      .utc()
      .add(1, 'month')
      .endOf('month')
      .toISOString();
    // this.nextMeetingsLoader = true;
    this.subscriptions.add(
      this.dashboardService
        .getSupervisorAppointments(
          firstDayOfCurrentMonth,
          lastDayOfNextMonth,
          APPOINTMENT_STATE.Created
        )
        .subscribe({
          next: (appointments: IAppointment[]) => {
            this.meetings = appointments.map(
              (appointment: IAppointment): any => ({
                ...appointment,
                showStartDate: moment(appointment.startDate).format(
                  'DD/MM/YYYY - HH:mm'
                ),
              })
            );
          },
        })
    );
  }

  getNextMeetings(startDate?: Date | string, endDate?: Date | string): void {
    this.nextMeetingsLoader = true;
    const firstDayOfTheWeek: any = moment()
      .utc()
      .startOf('week')
      .add(1, 'day')
      .toISOString();
    const endDayOfTheWeek: any = moment()
      .utc()
      .endOf('week')
      .add(1, 'day')
      .toISOString();
    this.subscriptions.add(
      this.irFormService
        .getAgentAppointment(
          startDate || firstDayOfTheWeek,
          endDate || endDayOfTheWeek,
          this.idUser,
          APPOINTMENT_STATE.Created
        )
        .subscribe({
          next: (appointments: IAppointment[]) => {
            const filteredMeetings: IAppointment[] = [];
            appointments.forEach((meet: IAppointment, index: number) => {
              if (index < 6) {
                filteredMeetings.push(meet);
              }
            });
            this.nextMeetings = filteredMeetings.map(
              (appointment: IAppointment): any => ({
                ...appointment,
                showStartDate: appointment.startDate,
              })
            );
            this.nextMeetingsLoader = false;
          },
        })
    );
  }

  getSupervisorNextMeetings(): void {
    this.nextMeetingsLoader = true;
    this.subscriptions.add(
      this.dashboardService
        .getSupervisorAppointments(
          moment(this.filterStatisticsStartDate.value).utc().toISOString(),
          moment(this.filterStatisticsEndDate.value).utc().toISOString(),
          APPOINTMENT_STATE.Created,
          this.filterStatisticsAgent.value === 'entireSalesForce'
            ? null
            : this.filterStatisticsAgent.value
        )
        .subscribe({
          next: (appointments: IAppointment[]) => {
            const filteredMeetings: IAppointment[] = [];
            let tMeet: number = 0;
            let fMeet: number = 0;
            appointments.forEach((meet: IAppointment, index: number) => {
              if (meet.appointmentType === 'T') {
                ++tMeet;
              }
              if (meet.appointmentType === 'F') {
                ++fMeet;
              }
              if (index < 6) {
                filteredMeetings.push(meet);
              }
            });
            this.agentSupervisorAppointmentsStatistics = {
              T: tMeet,
              F: fMeet,
            };
            this.nextMeetings = filteredMeetings.map(
              (appointment: IAppointment): any => ({
                ...appointment,
                showStartDate: appointment.startDate,
              })
            );
            this.nextMeetingsLoader = false;
          },
        })
    );
  }

  getAgentSupervisorAppointmentsStatistics(): void {
    this.subscriptions.add(
      this.dashboardService
        .getAppointmentStats(
          moment(this.filterStatisticsStartDate.value).utc().toISOString(),
          moment(this.filterStatisticsEndDate.value).utc().toISOString(),
          APPOINTMENT_STATE.Created,
          this.filterStatisticsAgent.value === 'entireSalesForce'
            ? null
            : this.filterStatisticsAgent.value
        )
        .subscribe({
          next: (data: any) => {
            this.agentSupervisorAppointmentsStatistics = data;
          },
        })
    );
  }

  // getWorkingIR(): void {
  //   this.subscriptions.add(
  //     this.irFormService
  //       .getProjectByStatusDescription(RI_STATUS_DESCRIPTION.RiWorking, true)
  //       .subscribe((project: ProjectDTO[]) => {
  //         this.irInProgress = project.splice(0, 3);
  //         this.progressBar.RiWorking = false;
  //       })
  //   );
  // }

  // getDeliverdIR(): void {
  //   this.subscriptions.add(
  //     this.irFormService
  //       .getProjectByStatusDescription(RI_STATUS_DESCRIPTION.RiDelivered, true)
  //       .subscribe((project: ProjectDTO[]) => {
  //         this.irDelivered = project.splice(0, 3);
  //         this.progressBar.RiSended = false;
  //       })
  //   );
  // }

  // getWorkingIsp(): void {
  //   this.subscriptions.add(
  //     this.irFormService
  //       .getProjectByStatusDescription(RI_STATUS_DESCRIPTION.IspWorking, true)
  //       .subscribe((project: ProjectDTO[]) => {
  //         this.ispInProgress = project.splice(0, 3);
  //         this.progressBar.IspWorking = false;
  //       })
  //   );
  // }

  getAllCustomer(idUserAgent?: number): void {
    this.customersLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getAllCustomerByUser(idUserAgent || null, true)
        .subscribe((customers: ICustomers) => {
          const activeCustomersMapped: ICustomer[] =
            customers.customerActive.map((customer: ICustomer) => ({
              ...customer,
              customerType: 'Active',
            }));
          const bmCustomersMapped: ICustomer[] = customers.customerBM.map(
            (customer: ICustomer) => ({
              ...customer,
              customerType: 'BM',
            })
          );
          const allCustomers: ICustomer[] =
            activeCustomersMapped.concat(bmCustomersMapped);
          this.customers = allCustomers
            .sort((customerA, customerB) => {
              return (
                new Date(customerB.dateInsert).getTime() -
                new Date(customerA.dateInsert).getTime()
              );
            })
            .splice(0, 3);
          this.progressBar.Customer = false;
          this.customersLoader = false;
        })
    );
  }

  routerLink(path: string): void {
    this.router.navigate([`${APP_CONTEX}/${path}`]);
  }

  getUserStatistics(selection: MatSelectChange): void {
    let rangeTime: any = null;
    let outputType: number;
    if (selection?.value === 'currentYear') {
      rangeTime = moment().utc().startOf('year').toISOString();
      outputType = DashboardStatisticsOutputType.MonthlyAggregation;
    }
    if (selection?.value === 'lastThreeYears') {
      rangeTime = moment()
        .utc()
        .startOf('year')
        .subtract(2, 'year')
        .toISOString();
      outputType = DashboardStatisticsOutputType.AnnualAggregation;
    }
    if (selection?.value === 'fromBeginning') {
      rangeTime = moment()
        .utc()
        .startOf('year')
        .subtract(10, 'year')
        .toISOString();
      outputType = DashboardStatisticsOutputType.FromBegin;
    }
    this.statisticsLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getDashboardStatistics(
          rangeTime,
          moment().utc().endOf('year').toISOString(),
          outputType
        )
        .subscribe({
          next: (data: any) => {
            this.userStatistics = data;
            const dataAggregationsName: string[] = [];
            const dataAggregationsAssValue: number[] = [];
            const dataAggregationsBMValue: number[] = [];
            this.subscriptions.add(
              this.translate.get('STATISTICS_CHART').subscribe({
                next: (trans: any) => {
                  if (selection?.value === 'currentYear') {
                    data?.statistics?.forEach((aggregation: any, i: number) => {
                      dataAggregationsName.push(trans[this.monthsForGraphs[i]]);
                      dataAggregationsAssValue.push(
                        aggregation?.countAssessment
                      );
                      dataAggregationsBMValue.push(aggregation?.countBM);
                    });
                  }
                  if (
                    selection?.value === 'lastThreeYears' ||
                    selection?.value === 'fromBeginning'
                  ) {
                    data?.statistics?.forEach((aggregation: any, i: number) => {
                      dataAggregationsName.push(aggregation.aggregationKey);
                      dataAggregationsAssValue.push(
                        aggregation?.countAssessment
                      );
                      dataAggregationsBMValue.push(aggregation?.countBM);
                    });
                  }
                  this.userStatisticsChartOptions = {
                    grid: {
                      width: '100%',
                      left: '30',
                    },
                    legend: {
                      bottom: '0',
                      data: [
                        {
                          name: trans.ContractedAssessment,
                          icon: 'circle',
                        },
                        {
                          name: trans.BMAssessment,
                          icon: 'circle',
                        },
                      ],
                    },
                    xAxis: [
                      {
                        data: dataAggregationsName,
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                      },
                    ],
                    series: [
                      {
                        name: trans.ContractedAssessment,
                        type: 'line',
                        data: dataAggregationsAssValue,
                        itemStyle: {
                          color: VARIABLES_COLORS.Primary,
                        },
                        symbolSize: 10,
                      },
                      {
                        name: trans.BMAssessment,
                        type: 'line',
                        data: dataAggregationsBMValue,
                        itemStyle: {
                          color: VARIABLES_COLORS.Error,
                        },
                        symbolSize: 10,
                      },
                    ],
                  };
                },
              })
            );
            this.statisticsLoader = false;
          },
          error: () => {
            this.statisticsLoader = false;
          },
        })
    );
  }

  getDashboardGraphStatistics(selection: MatSelectChange): void {
    let rangeTime: any = null;
    let outputType: number;
    if (selection?.value === 'currentYear') {
      rangeTime = moment().utc().startOf('year').toISOString();
      outputType = DashboardStatisticsOutputType.MonthlyAggregation;
    }
    if (selection?.value === 'lastThreeYears') {
      rangeTime = moment()
        .utc()
        .startOf('year')
        .subtract(2, 'year')
        .toISOString();
      outputType = DashboardStatisticsOutputType.AnnualAggregation;
    }
    if (selection?.value === 'fromBeginning') {
      rangeTime = moment()
        .utc()
        .startOf('year')
        .subtract(10, 'year')
        .toISOString();
      outputType = DashboardStatisticsOutputType.FromBegin;
    }
    this.statisticsLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getSupervisorDashboardStatistics(
          rangeTime,
          moment().utc().endOf('year').toISOString(),
          outputType
        )
        .subscribe({
          next: (data: any) => {
            this.userStatistics = data;
            const dataAggregationsName: string[] = [];
            const dataAggregationsAssValue: number[] = [];
            const dataAggregationsBMValue: number[] = [];
            this.subscriptions.add(
              this.translate.get('STATISTICS_CHART').subscribe({
                next: (trans: any) => {
                  if (selection?.value === 'currentYear') {
                    data?.statistics?.forEach((aggregation: any, i: number) => {
                      dataAggregationsName.push(trans[this.monthsForGraphs[i]]);
                      dataAggregationsAssValue.push(
                        aggregation?.countAssessment
                      );
                      dataAggregationsBMValue.push(aggregation?.countBM);
                    });
                  }
                  if (
                    selection?.value === 'lastThreeYears' ||
                    selection?.value === 'fromBeginning'
                  ) {
                    data?.statistics?.forEach((aggregation: any, i: number) => {
                      dataAggregationsName.push(aggregation.aggregationKey);
                      dataAggregationsAssValue.push(
                        aggregation?.countAssessment
                      );
                      dataAggregationsBMValue.push(aggregation?.countBM);
                    });
                  }
                  this.userStatisticsChartOptions = {
                    grid: {
                      width: '100%',
                      left: '30',
                    },
                    legend: {
                      bottom: '0',
                      data: [
                        {
                          name: trans.ContractedAssessment,
                          icon: 'circle',
                        },
                        {
                          name: trans.BMAssessment,
                          icon: 'circle',
                        },
                      ],
                    },
                    xAxis: [
                      {
                        data: dataAggregationsName,
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                      },
                    ],
                    series: [
                      {
                        name: trans.ContractedAssessment,
                        type: 'line',
                        data: dataAggregationsAssValue,
                        itemStyle: {
                          color: VARIABLES_COLORS.Primary,
                        },
                        symbolSize: 10,
                      },
                      {
                        name: trans.BMAssessment,
                        type: 'line',
                        data: dataAggregationsBMValue,
                        itemStyle: {
                          color: VARIABLES_COLORS.Error,
                        },
                        symbolSize: 10,
                      },
                    ],
                  };
                  this.statisticsLoader = false;
                },
                error: () => {
                  this.statisticsLoader = false;
                },
              })
            );
          },
        })
    );
  }

  getDirectionGrapichStatistics(selection: MatSelectChange): void {
    let startDate: any;
    let endDate: any;
    let outputType: number;
    if (selection?.value === 'currentYearPreviousYear') {
      startDate = moment().utc().startOf('year').toISOString();
      endDate = moment().utc().toISOString();
      outputType = DashboardStatisticsOutputType.MonthlyAggregation;
    }
    if (selection?.value === 'lastThreeYears') {
      startDate = moment()
        .utc()
        .startOf('year')
        .subtract(3, 'year')
        .toISOString();
      (endDate = moment()
        .utc()
        .endOf('year')
        .subtract(1, 'year')
        .toISOString()),
        (outputType = DashboardStatisticsOutputType.AnnualAggregation);
    }
    if (selection?.value === 'fromBeginning') {
      startDate = moment()
        .utc()
        .startOf('year')
        .subtract(10, 'year')
        .toISOString();
      (endDate = moment()
        .utc()
        .endOf('year')
        .subtract(1, 'year')
        .toISOString()),
        (outputType = DashboardStatisticsOutputType.FromBegin);
    }
    this.statisticsLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getDirectionDashboardStatistics(startDate, endDate, outputType)
        .subscribe({
          next: (data: any) => {
            this.userStatistics = data;
            const dataAggregationsName: string[] = [];
            const dataAggregationsAcquiredCustomersValue: number[] = [];
            const dataAggregationsClosedCustomersValue: number[] = [];
            const dataAggregationsAcquiredCustomersValueLastYear: number[] = [];
            const dataAggregationsClosedCustomersValueLastYear: number[] = [];
            this.subscriptions.add(
              this.translate.get('STATISTICS_CHART').subscribe({
                next: (trans: any) => {
                  if (selection?.value === 'currentYearPreviousYear') {
                    data?.statistics?.forEach((aggregation: any, i: number) => {
                      if (i === data?.statistics?.length - 1) {
                        dataAggregationsName.push(
                          moment().format('DD/MM/YYYY')
                        );
                      } else {
                        dataAggregationsName.push(
                          trans[this.monthsForGraphs[i]]
                        );
                      }
                      dataAggregationsAcquiredCustomersValue.push(
                        aggregation?.customerActivated
                      );
                      dataAggregationsClosedCustomersValue.push(
                        aggregation?.customerDeactivated
                      );
                    });
                    data?.statisticsLastYear?.forEach(
                      (aggregation: any, i: number) => {
                        dataAggregationsAcquiredCustomersValueLastYear.push(
                          aggregation?.customerActivated
                        );
                        dataAggregationsClosedCustomersValueLastYear.push(
                          aggregation?.customerDeactivated
                        );
                      }
                    );
                  }
                  if (
                    selection?.value === 'lastThreeYears' ||
                    selection?.value === 'fromBeginning'
                  ) {
                    data?.statistics?.forEach((aggregation: any, i: number) => {
                      dataAggregationsName.push(aggregation.aggregationKey);
                      dataAggregationsAcquiredCustomersValue.push(
                        aggregation?.customerActivated
                      );
                      dataAggregationsClosedCustomersValue.push(
                        aggregation?.customerDeactivated
                      );
                    });
                  }
                  this.directionStatisticsChartOptions = {
                    grid: {
                      left: '30',
                      right: '30',
                    },
                    tooltip:
                      selection?.value === 'currentYearPreviousYear'
                        ? {
                            trigger: 'item',
                            formatter: (params) => {
                              if (params.name.includes('/')) {
                                return `${params.seriesName}<br />
                                ${params.marker} ${
                                  params.seriesIndex === 0 ||
                                  params.seriesIndex === 1
                                    ? `${moment(params.name, 'DD/MM/YYYY')
                                        .subtract(1, 'year')
                                        .format('DD/MM/YYYY')}`
                                    : params.name
                                } - <strong>${params.value}</strong>`;
                              } else {
                                return `${params.seriesName}<br />
                                  ${params.marker} ${
                                  params.seriesIndex === 0 ||
                                  params.seriesIndex === 1
                                    ? params.name +
                                      ` ${moment().subtract(1, 'year').year()}`
                                    : params.name + ` ${moment().year()}`
                                } - <strong>${params.value}</strong>`;
                              }
                            },
                          }
                        : {
                            trigger: 'axis',
                          },
                    legend: {
                      bottom: '0',
                      data: [
                        {
                          name: trans.AcquiredCustomers,
                          icon: 'circle',
                          itemStyle: {
                            opacity: 1,
                          },
                        },
                        {
                          name: trans.ClosedCustomers,
                          icon: 'circle',
                          itemStyle: {
                            opacity: 1,
                          },
                        },
                      ],
                    },
                    xAxis: [
                      {
                        data: dataAggregationsName,
                      },
                    ],
                    yAxis: [
                      {
                        type: 'value',
                      },
                    ],
                    series:
                      selection?.value === 'currentYearPreviousYear'
                        ? [
                            {
                              name: trans.AcquiredCustomers,
                              type: 'bar',
                              stack: 'one',
                              data: dataAggregationsAcquiredCustomersValueLastYear,
                              itemStyle: {
                                color: VARIABLES_COLORS.Primary,
                                opacity: 0.5,
                              },
                              barMaxWidth: 20,
                            },
                            {
                              name: trans.ClosedCustomers,
                              type: 'bar',
                              stack: 'one',
                              data: dataAggregationsClosedCustomersValueLastYear,
                              itemStyle: {
                                color: VARIABLES_COLORS.Error,
                                opacity: 0.5,
                              },
                              barMaxWidth: 20,
                            },
                            {
                              name: trans.AcquiredCustomers,
                              type: 'bar',
                              stack: 'two',
                              data: dataAggregationsAcquiredCustomersValue,
                              itemStyle: {
                                color: VARIABLES_COLORS.Primary,
                              },
                              barMaxWidth: 20,
                            },
                            {
                              name: trans.ClosedCustomers,
                              type: 'bar',
                              stack: 'two',
                              data: dataAggregationsClosedCustomersValue,
                              itemStyle: {
                                color: VARIABLES_COLORS.Error,
                              },
                              barMaxWidth: 20,
                            },
                          ]
                        : [
                            {
                              name: trans.AcquiredCustomers,
                              type: 'bar',
                              data: dataAggregationsAcquiredCustomersValue,
                              itemStyle: {
                                color: VARIABLES_COLORS.Primary,
                              },
                              barMaxWidth: 20,
                            },
                            {
                              name: trans.ClosedCustomers,
                              type: 'bar',
                              data: dataAggregationsClosedCustomersValue,
                              itemStyle: {
                                color: VARIABLES_COLORS.Error,
                              },
                              barMaxWidth: 20,
                            },
                          ],
                  };
                  this.statisticsLoader = false;
                },
                error: () => {
                  this.statisticsLoader = false;
                },
              })
            );
          },
        })
    );
  }

  // TODO: create function for getting statistics data of labmanager

  initializeFollowedCustomersTable(): void {
    this.followedCustomersTableColumns = [
      {
        name: 'businessName',
        translatedName: this.translations.Customer,
      },
      {
        name: 'projectsNumber',
        translatedName: this.translations.ProjectsNumber,
      },
      {
        name: 'buttons',
        disableSort: true,
      },
    ];
    this.followedCustomersTableButtons = [
      {
        icon: 'arrow_green_3_icon',
        class: 'primary',
        clickFunction: (customer: IProjectCustomer) => {
          this.showCustomerCrmDialog(customer);
        },
      },
    ];
  }

  getFollowedCustomers(): void {
    this.initializeFollowedCustomersTable();
    this.followedCustomersLoader = true;
    this.subscriptions.add(
      this.crmService.getFollowedCustomers().subscribe({
        next: (customers: any) => {
          const customersIds = [];
          const filteredCustomers = [];
          customers.forEach((customer: IProjectCustomer) => {
            if (!customersIds.includes(customer.idCustomer)) {
              customersIds.push(customer.idCustomer);
              filteredCustomers.push(customer);
            }
          });
          this.followedCustomers = filteredCustomers.map(
            (customer: IProjectCustomer): any => ({
              ...customer,
              businessName: customer.customer.businessName,
              projectsNumber: this.checkProjectsNumber(
                customers,
                customer.idCustomer
              ),
            })
          );
          this.followedCustomersLoader = false;
        },
      })
    );
  }

  getManagedCustomersForSupervisor(): void {
    this.initializeFollowedCustomersTable();
    this.followedCustomersLoader = true;
    this.subscriptions.add(
      this.crmService.getManagedCustomersForSupervisor().subscribe({
        next: (customers: any) => {
          const customersIds = [];
          const filteredCustomers = [];
          customers.forEach((customer: IProjectCustomer) => {
            if (!customersIds.includes(customer.idCustomer)) {
              customersIds.push(customer.idCustomer);
              filteredCustomers.push(customer);
            }
          });
          this.followedCustomers = filteredCustomers.map(
            (customer: IProjectCustomer): any => ({
              ...customer,
              businessName: customer.customer.businessName,
              projectsNumber: this.checkProjectsNumber(
                customers,
                customer.idCustomer
              ),
            })
          );
          this.followedCustomersLoader = false;
        },
      })
    );
  }

  checkProjectsNumber(data: IProjectCustomer[], idCustomer: number): number {
    const sameCustomer: number[] = [];
    data.forEach((item: IProjectCustomer) => {
      if (item.idCustomer === idCustomer) {
        sameCustomer.push(idCustomer);
      }
    });
    return sameCustomer.length;
  }

  getMandatoryActivities(): void {
    this.mandatoryActivitiesLoader = true;
    this.subscriptions.add(
      this.translate.get('CRM').subscribe({
        next: (data: any) => {
          this.mandatoryActivitiesTableColumns = [
            {
              name: 'customer',
              translatedName: data.Customer,
            },
            {
              name: 'contact',
              translatedName: data.Contact,
            },
            {
              name: 'status',
              translatedName: data.Status,
            },
            {
              name: 'country',
              translatedName: data.Country,
            },
            {
              name: 'buyer',
              translatedName: data.Buyer,
            },
            {
              name: 'buyerTelephone',
              translatedName: data.BuyerTelephone,
            },
            {
              name: 'buyerEmail',
              translatedName: data.BuyerEmail,
            },
            {
              name: 'contactDate',
              translatedName: data.ContactDate,
              type: 'date',
            },
            {
              name: 'description',
              translatedName: data.Description,
            },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.mandatoryActivitiesTableButtons = [
            {
              icon: 'arrow_green_3_icon',
              class: 'primary',
              clickFunction: (activity: IContactActivity) => {
                console.log(activity);
                // TODO: create link to navigate to crm detail activity
                this.router.navigate(
                  [
                    `crm/customer/${activity?.customerSummaryDTO?.idCustomer}/project/${activity?.idProject}/contact/${activity?.contactSummaryDTO?.idContact}`,
                  ],
                  {
                    queryParams: { activeTabIndex: 1 },
                  }
                );
              },
            },
          ];
          this.subscriptions.add(
            this.crmService.getAllMandatoryActivitiesByLoggedUser().subscribe({
              next: (activities: IContactActivity[]) => {
                this.mandatoryActivities = activities.map(
                  (activity: IContactActivity) => ({
                    ...activity,
                    customer: activity.customerSummaryDTO.businessName,
                    contact: activity.contactSummaryDTO.businessName,
                    status: this.translate.instant(
                      `BUYER_STATUS.${
                        BUYER_STATUS[
                          activity.contactSummaryDTO.buyerStatus.idBuyerStatus
                        ]
                      }`
                    ),
                    country: activity.contactSummaryDTO.country.country,
                    buyer: `${activity.contactRefName} ${activity.contactRefSurname}`,
                    buyerTelephone: activity.contactRefTel,
                    buyerEmail: activity.contactRefEmail,
                    contactDate: activity?.dateActivity,
                    description: activity?.feedbackDescription,
                  })
                );
                this.mandatoryActivitiesLoader = false;
              },
            })
          );
        },
      })
    );
  }

  navigateTo(page: string, customer?: ICustomer): void {
    if (customer) {
      this.router.navigate([`/${page}/${customer.idCustomer}`]);
    } else {
      this.router.navigate([`/${page}`]);
    }
  }

  goToSpecificMeet(meet: IAppointment): void {
    this.router.navigate(['/meetings'], {
      queryParams: { id: meet.idAppointment, date: meet.startDate },
    });
  }

  reinitializeAgentSupervisorData(select: MatSelectChange): void {
    if (select.value) {
      // this.getAgentSupervisorAppointmentsStatistics();
      this.getSupervisorNextMeetings();
      this.getAllCustomer(
        this.filterStatisticsAgent.value === 'entireSalesForce'
          ? null
          : this.filterStatisticsAgent.value
      );
    }
    // this.selectedAgentSupervisorDataFilter = select.value;
  }

  getAgentManagedBySupervisor(): void {
    this.subscriptions.add(
      this.irFormService.getAgent().subscribe({
        next: (agents: IAgent[]) => {
          this.agentsManagedBySupervisor = agents;
        },
      })
    );
  }

  getManagerStatistics(): void {
    this.subscriptions.add(
      this.dashboardService.getDLManagerDashboardStatistics(true).subscribe({
        next: (statistics: IManagerStatistics) => {
          this.managerStatistics = statistics;
        },
      })
    );
  }

  getSupervisorManagerStatistics(): void {
    this.subscriptions.add(
      this.dashboardService
        .getDLManagerSupervisorDashboardStatistics()
        .subscribe({
          next: (statistics: IManagerStatistics) => {
            this.managerStatistics = statistics;
          },
        })
    );
  }

  showCustomerCrmDialog(customer: IProjectCustomer): void {
    let projects: IProjectCustomer[] = [];
    this.followedCustomers.forEach((cust: IProjectCustomer) => {
      if (cust.idCustomer === customer.idCustomer) {
        projects.push(cust);
      }
    });
    projects = projects.map((project: IProjectCustomer): any => ({
      ...project,
      version: PS_VERSION[project.projectStatusCode],
    }));
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    // dialogConfig.width
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      customer,
      projects,
    };
    const dialogRef = this.dialog.open(this.customerDialog, dialogConfig);
    dialogRef.afterClosed().subscribe((project: any) => {
      if (project) {
        this.router.navigate([
          `/crm/customer/${project.idCustomer}/project/${project.idProject}`,
        ]);
      }
    });
  }

  getProjectsToAssign(): void {
    this.projectsToAssignLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getAllIrToAssign(RI_STATUS.RiApproved, true)
        .subscribe((projects: IProjectInfo[]) => {
          this.projectsToAssign = projects
            .map(fromProjectDtoToProjectTable)
            .slice(0, 5);
          this.projectsToAssignLoader = false;
          this.projectsToAssignTableColumns = [
            {
              name: 'lastModified',
              translatedName: this.translate.instant('CUSTOMER.LastWorking'),
              width: 25,
              type: 'date',
            },
            {
              name: 'businessName',
              translatedName: this.translations.BusinessName,
              width: 25,
            },
            {
              name: 'contactInfo',
              translatedName: this.translations.Contacts,
              type: 'innerHTML',
              width: 40,
            },
            {
              name: 'buttons',
              disableSort: true,
              width: 10,
            },
          ];
          this.projectsToAssignTableButtons = [
            {
              icon: 'arrow_green_3_icon',
              class: 'primary',
              clickFunction: (project: IProjectForTable) => {
                this.router.navigate([`/projects/${project.idProject}`], {
                  queryParams: {
                    idCustomer: project.customer.idCustomer,
                  },
                });
              },
            },
          ];
        })
    );
  }

  getAssignedProjects(): void {
    this.assignedProjectsLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getProjectByStatusDescription(RI_STATUS_DESCRIPTION.IspWorking, true)
        .subscribe((projects: IProjectInfo[]) => {
          this.assignedProjects = projects
            .map(fromProjectDtoToProjectTable)
            .slice(0, 5);
          this.assignedProjectsLoader = false;
          this.assignedProjectsTableColumns = [
            {
              name: 'lastModified',
              translatedName: this.translate.instant('CUSTOMER.LastWorking'),
              width: 25,
              type: 'date',
            },
            {
              name: 'businessName',
              translatedName: this.translations.BusinessName,
              width: 25,
            },
            {
              name: 'contactInfo',
              translatedName: this.translations.Contacts,
              type: 'innerHTML',
              width: 25,
            },
            {
              name: 'manager',
              translatedName: this.translate.instant('COMMON.DLManager'),
              width: 25,
            },
          ];
        })
    );
  }

  getTop3Managers(): void {
    const dateFrom: string = moment().utc().startOf('year').toISOString();
    const dateTo: string = moment().utc().toISOString();
    this.subscriptions.add(
      this.common.getTop3Managers(dateFrom, dateTo, true).subscribe({
        next: (data: ITop3ManagersData) => {
          this.top3ManagersData = data;
          this.top3ManagersData.topManagers = data?.topManagers?.slice(0, 3);
        },
        error: () => {},
      })
    );
  }

  searchValue(value: string, table: string): void {
    switch (table) {
      case 'followedCustomers':
        this.followedCustomersSearchValue = value;
        break;
      case 'mandatoryActivities':
        this.mandatoryActivitiesSearchValue = value;
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
