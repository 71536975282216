import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PS_VERSION } from 'src/app/config';
import {
  IContactActivity,
  IContactActivityForTable,
} from 'src/app/models/contact';
import { IProjectCustomer } from 'src/app/models/project';
import { ITableButton, ITableColumn } from 'src/app/models/utility';
import { CrmService } from 'src/app/services/crm/crm.service';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { fromContactMandatoryActivityToDataForTable } from 'src/app/utility/elaborations';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss'],
})
export class CrmComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  translations: any;
  searchValueFollowedPmi: string;
  searchValueMandatoryActivities: string;

  followedPMIs: any[] = [];
  followedPMIsFullData: IProjectCustomer[] = [];
  followedPMIsLoader: boolean = false;
  followedPMIsTableColumns: ITableColumn[];
  followedPMIsTableButtons: ITableButton[];
  mandatoryActivities: IContactActivityForTable[];
  mandatoryActivitiesTableColumns: ITableColumn[];
  mandatoryActivitiesTableButtons: ITableButton[];
  mandatoryActivitiesLoader: boolean = false;
  psVersion: any = PS_VERSION;
  showMandatoryActivitiesTable: boolean = false;

  @ViewChild('customerDialog') customerDialog: TemplateRef<any>;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private crmService: CrmService,
    private dialog: MatDialog,
    private layoutService: LayoutService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('CRM').subscribe((translations: any) => {
        this.translations = translations;
        this.layoutService.generateBreadcrumb([
          { label: 'CRM', path: '/crm', pageTitle: true },
        ]);
        this.followedPMIsTableColumns = [
          {
            name: 'businessName',
            translatedName: this.translations.Customer,
          },
          {
            name: 'totalContacts',
            translatedName: this.translations.ContactsN,
            align: 'center',
          },
          {
            name: 'totalMarkets',
            translatedName: this.translations.MarketsN,
            align: 'center',
          },
          {
            name: 'generatedOffers',
            translatedName: this.translations.GeneratedOffers,
            align: 'center',
          },
          {
            name: 'offersUnderManagement',
            translatedName: this.translations.OffersUnderManagement,
            align: 'center',
          },
          {
            name: 'losedOffers',
            translatedName: this.translations.LosedOffers,
            align: 'center',
          },
          {
            name: 'generatedOrders',
            translatedName: this.translations.AcquiredOrders,
            align: 'center',
          },
          {
            name: 'totalTurnover',
            translatedName: this.translations.TotalTurnover,
            type: 'currency',
            align: 'center',
          },
          {
            name: 'buttons',
            disableSort: true,
          },
        ];
        this.followedPMIsTableButtons = [
          {
            icon: 'arrow_green_3_icon',
            class: 'primary',
            clickFunction: (customer: IProjectCustomer) => {
              this.showCustomerDialog(customer);
            },
          },
        ];
        this.mandatoryActivitiesTableColumns = [
          {
            name: 'company',
            translatedName: this.translations.Client,
          },
          {
            name: 'contact',
            translatedName: this.translations.Contact,
          },
          {
            name: 'buyerStatus',
            translatedName: this.translations.Status2,
          },
          {
            name: 'country',
            translatedName: this.translations.Country,
          },
          {
            name: 'buyer',
            translatedName: this.translations.Buyer,
          },
          {
            name: 'buyerPhone',
            translatedName: this.translations.BuyerPhone,
          },
          {
            name: 'buyerEmail',
            translatedName: this.translations.BuyerEmail,
          },
          {
            name: 'dateActivity',
            translatedName: this.translations.DateActivity,
          },
          {
            name: 'feedbackDescription',
            translatedName: this.translations.Description,
          },
          {
            name: 'buttons',
            disableSort: true,
          },
        ];
      })
    );
    this.getFollowedPMI();
    this.getMandatoryActivities();
  }

  getFollowedPMI(): void {
    this.followedPMIsLoader = true;
    this.subscriptions.add(
      this.crmService
        .getFollowedCustomers(true)
        .subscribe((data: IProjectCustomer[]) => {
          this.followedPMIsFullData = data;
          const customersIds = [];
          const filteredCustomers = [];
          data.forEach((customer: IProjectCustomer) => {
            if (!customersIds.includes(customer.idCustomer)) {
              customersIds.push(customer.idCustomer);
              filteredCustomers.push(customer);
            }
          });
          this.followedPMIs = filteredCustomers.map(
            (customer: IProjectCustomer): any => ({
              ...customer,
              businessName: customer.customer.businessName,
              generatedOrders: customer?.totalStatistics?.totalOrders,
              generatedOffers: customer?.totalStatistics?.totalOffers,
              totalTurnover: customer?.totalStatistics?.totalRevenue,
              losedOffers: customer?.totalStatistics?.lostOffers,
              offersUnderManagement: customer?.totalStatistics?.pendingOffers,
              totalContacts: customer?.totalStatistics?.totalContacts,
              totalMarkets: customer?.totalStatistics?.totalMarkets,
              // projectsNumber: this.checkProjectsNumber(data, customer.idCustomer),
            })
          );
          this.followedPMIsLoader = false;
        })
    );
  }

  getMandatoryActivities(): void {
    this.showMandatoryActivitiesTable = true;
    this.mandatoryActivitiesLoader = true;
    this.subscriptions.add(
      this.crmService
        .getAllMandatoryActivitiesByLoggedUser(true)
        .subscribe((mandatoryActivities: IContactActivity[]) => {
          this.mandatoryActivities = mandatoryActivities.map(
            fromContactMandatoryActivityToDataForTable
          );
          this.mandatoryActivitiesTableButtons = [
            {
              icon: 'arrow_green_3_icon',
              class: 'primary',
              clickFunction: (activity: IContactActivityForTable) => {
                this.router.navigate(
                  [
                    `crm/customer/${activity?.idCustomer}/project/${activity?.idProject}/contact/${activity?.idContact}`,
                  ],
                  {
                    queryParams: { activeTabIndex: 1 },
                  }
                );
              },
            },
          ];
          this.mandatoryActivitiesLoader = false;
        })
    );
  }

  // checkProjectsNumber(data: IProjectCustomer[], idCustomer: number): number {
  //   const sameCustomer: number[] = [];
  //   data.forEach((item: IProjectCustomer) => {
  //     if (item.idCustomer === idCustomer) {
  //       sameCustomer.push(idCustomer);
  //     }
  //   });
  //   return sameCustomer.length;
  // }

  viewContactCrmDetail(customer: any): void {
    this.router.navigate([
      `/crm-detail`,
      customer?.idProject,
      customer?.idCustomer,
    ]);
  }

  showCustomerDialog(customer: IProjectCustomer): void {
    let projects: IProjectCustomer[] = [];
    this.followedPMIsFullData.forEach((cust: IProjectCustomer) => {
      if (cust.idCustomer === customer.idCustomer) {
        projects.push(cust);
      }
    });
    projects = projects.map((project: IProjectCustomer): any => ({
      ...project,
      version: PS_VERSION[project.projectStatusCode],
    }));
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    // dialogConfig.width
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      customer,
      projects,
    };
    const dialogRef = this.dialog.open(this.customerDialog, dialogConfig);
    dialogRef.afterClosed().subscribe((project: any) => {
      if (project) {
        this.router.navigate([
          `/crm/customer/${project.idCustomer}/project/${project.idProject}`,
        ]);
      }
    });
  }

  goToCustomerCrmDetails(project: IProjectCustomer): void {
    this.router.navigate([`/crm/${project.idProject}`], {
      queryParams: {
        pageTitle: project.customer.businessName,
        id: project.idCustomer,
      },
    });
  }

  searchValue(value: string, table: string): void {
    switch (table) {
      case 'followed pmi':
        this.searchValueFollowedPmi = value;
        break;
      case 'mandatory activities':
        this.searchValueMandatoryActivities = value;
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
