import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChildren,
  QueryList,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  ValidatorFn,
} from '@angular/forms';
import { Observable, Subscription, Subject } from 'rxjs';
import { IrFormComponent } from 'src/app/components/ir-form-abstract/ir-form-abstract-components';
import { ICountries } from 'src/app/models/countries';
import { IProductsServices } from 'src/app/models/products-services';
import { Project } from 'src/app/models/project';
import {
  IReferenceCompetitor,
  DistributionChannel,
  PresenceMarket,
  CompetitorProduct,
  DistributionChannelForm,
  ReferenceCompetitorForm,
  IReferenceCompetitorForRadarChart,
  IReferenceCompetitorForBubbleChartTable,
  IReferenceCompetitorForTable,
  IEvaluationConcurrency,
  IEvaluationConcurrencyForTable,
} from 'src/app/models/reference-competitor';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { LayoutService } from 'src/app/services/layout/layout.service';
// import { CurrencyMaskConfig } from 'ng2-currency-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import {
  Colors,
  COMPETITOR_TYPE,
  currencyDefaultConfig,
  newChartColors,
} from 'src/app/config';
// import { filterAndMapCompetitorDistributions } from 'src/app/utility/elaborations';
import { ExportResponse, GraphResult } from 'src/app/models/chart';
import {
  ChartDataSets,
  ChartOptions,
  ChartPoint,
  ChartType,
  RadialChartOptions,
} from 'chart.js';
import { Label } from 'ng2-charts';
import { MatTableDataSource } from '@angular/material/table';
import {
  IColumnsName,
  ITableColumn,
  ITableOptions,
  ToastStatus,
} from 'src/app/models/utility';
import { takeUntil } from 'rxjs/operators';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { MatExpansionPanel } from '@angular/material/expansion';
import { CommonService } from 'src/app/services/common/common.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import {
  IChannelSpecification,
  IChannelsType,
} from 'src/app/models/channels-type';
import { ChannelsManagementDialogComponent } from '../channels-management-dialog/channels-management-dialog.component';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-reference-competitors',
  templateUrl: './reference-competitors.component.html',
  styleUrls: ['./reference-competitors.component.scss'],
})
export class ReferenceCompetitorsComponent
  extends IrFormComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  testControl: FormControl = new FormControl([]);

  @Input() initialValues: Observable<Project>;
  @Input() translations: any;
  @Input() index: any;
  @Input() tabIndex: any;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;
  @Input() isIsp: boolean;
  @Input() isAgent: boolean;
  @Output() deleteProductEmitter: EventEmitter<number | string> =
    new EventEmitter();
  @Output() deletePresenceMarketEmitter: EventEmitter<number | string> =
    new EventEmitter();
  @Output() deleteDistributionChannelEmitter: EventEmitter<number | string> =
    new EventEmitter();
  @Output() deleteCompetitorCustomerEmitter: EventEmitter<number | string> =
    new EventEmitter();
  @Output() submitFuncEmitter: EventEmitter<IReferenceCompetitor[]> =
    new EventEmitter();
  @Output() refreshReferenceCompetitors: EventEmitter<any> = new EventEmitter();
  @ViewChildren('nationalProductSelect')
  nationalProductSelect: QueryList<MatSelect>;
  @ViewChildren('foreignProductSelect')
  foreignProductSelect: QueryList<MatSelect>;
  @ViewChildren('nationalCountrySelect')
  nationalCountrySelect: QueryList<MatSelect>;
  @ViewChildren('foreignCountrySelect')
  foreignCountrySelect: QueryList<MatSelect>;
  @ViewChildren('formPanel') formPanel: QueryList<MatExpansionPanel>;

  loader: boolean;
  private subscriptions: Subscription = new Subscription();
  dialogSubscription: Subscription;

  nationalCompetitorCountries: any[];
  foreignCompetitorCountries: any[];

  countries: ICountries[];
  filteredCountriesByArea: any = {
    europe: [],
    northamerica: [],
    southamerica: [],
    asia: [],
    africa: [],
    oceania: [],
    centralamerica: [],
    middleeast: [],
  };
  macroArea: any[] = [
    'Europe',
    'NorthAmerica',
    'CentralAmerica',
    'SouthAmerica',
    'Asia',
    'Africa',
    'Oceania',
    'MiddleEast',
  ];
  macroAreaBoolean: boolean[] = [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ];
  products: IProductsServices[] = [];
  CompetitorType = COMPETITOR_TYPE;
  startDataOnInit: any;

  competitorExportOrientation: ExportResponse;
  allXValues: number[] = [];
  allYValues: number[] = [];

  referenceCompetitorsForm: FormGroup;
  // nationalCompetitorsForm: FormGroup;
  // foreignCompetitorsForm: FormGroup;
  newNationalDistribution: FormControl;
  newForeignDistribution: FormControl;

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;

  /* Radar Chart Table */
  radarChartDataTable: any[];
  radarChartColumnsName: ITableColumn[];

  /* Bubble Chart Table */
  bubbleChartDataTable: any[];
  bubbleChartColumnsName: ITableColumn[];

  channelsType: any[];
  specificationsChannels: any[];
  idCustomer: number;

  data1: any[] = [];
  data2: any[] = [];
  biggestNrOfCountriesExport: number;
  exportOrientationChartOptions: EChartsOption = {};
  positioningAnalysisChartOptions: EChartsOption = {};

  chartColors: string[] = newChartColors;

  @ViewChildren('topView') topView: ElementRef<any>;

  constructor(
    private dialog: MatDialog,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private fb: FormBuilder,
    private common: CommonService,
    private irFormService: IrFormService,
    private el: ElementRef
  ) {
    super(fb);
    this.referenceCompetitorsForm = this.fb.group({
      nationalCompetitors: this.fb.array([]),
      foreignCompetitors: this.fb.array([]),
    });
    // this.nationalCompetitorsForm = this.fb.group({
    //   nationalCompetitors: this.fb.array([])
    // });
    // this.foreignCompetitorsForm = this.fb.group({
    //   foreignCompetitors: this.fb.array([])
    // });
    this.newNationalDistribution = this.fb.control(null);
    this.newForeignDistribution = this.fb.control(null);
    // this.subscriptions.add(this.nationalCompetitorsForm.valueChanges.subscribe(e => {
    //   this.checkDirtyForm();
    //   // this.checkNationalDirtyForm();
    // }));
    // this.subscriptions.add(this.foreignCompetitorsForm.valueChanges.subscribe(e => {
    //   this.checkDirtyForm();
    //   // this.checkForeignDirtyForm();
    // }));
  }

  ngOnInit(): void {
    this.loader = true;
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (initialValues: Project) => {
          this.idCustomer = initialValues?.idCustomer;
          // Fill channelsTypes for loops only if not already fetched
          if (!this.channelsType?.length) {
            this.channelsType = initialValues?.channelsType;
          }
          // Fill channelsTypesSpecifications for loops only if not already fetched
          if (!this.specificationsChannels?.length) {
            this.specificationsChannels = initialValues?.specificationsChannels;
          }
          if (this.isIsp) {
            // Init export orientation table
            this.bubbleChartColumnsName = [
              {
                name: 'companyName',
                translatedName: this.translations.BusinessName,
                chartColor: true,
              },
              {
                name: 'revenueCustomer',
                translatedName: this.translations.RevenueCustomer,
                type: 'currency',
                align: 'center',
              },
              {
                name: 'revenueExport',
                translatedName: this.translations.RevenueExport,
                type: 'currency',
                align: 'center',
              },
              {
                name: 'quotaExport',
                translatedName: this.translations.QuotaExport,
                type: 'number',
                align: 'center',
              },
              {
                name: 'nCountryExport',
                translatedName: this.translations.NCountryExport,
                type: 'number',
                align: 'center',
              },
            ];
            // Init Mktg-mix position table
            this.radarChartColumnsName = [
              {
                name: 'companyName',
                translatedName: this.translations.BusinessName,
                chartColor: true,
              },
              {
                name: 'weightedAverage',
                translatedName: this.translations.WeightedAverage,
                align: 'center',
                type: 'number',
                legend: this.translations.PositioningWeightedAverageLegend,
              },
              {
                name: 'product',
                translatedName: this.translations.Product,
                align: 'center',
              },
              {
                name: 'distribution',
                translatedName: this.translations.distribution,
                align: 'center',
              },
              {
                name: 'priceSelling',
                translatedName: this.translations.PriceSelling,
                align: 'center',
              },
              {
                name: 'communicationMarketing',
                translatedName: this.translations.CommunicationMarketing,
                align: 'center',
              },
              // { value: 'globalPosition', name: this.translations.GlobalPosition }
            ];
            this.radarChartDataTable = [];
            this.radarChartDataTable = initialValues.referenceCompetitors?.map(
              (
                competitor: IReferenceCompetitor
              ): IReferenceCompetitorForTable => ({
                companyName: competitor?.companyName,
                product: competitor?.product,
                distribution: competitor?.distribution,
                priceSelling: competitor?.priceSelling,
                communicationMarketing: competitor?.communicationMarketing,
                // globalPosition: Math.round(((
                //   competitor?.product +
                //   competitor?.distribution +
                //   competitor?.priceSelling +
                //   competitor?.communicationMarketing
                // ) + Number.EPSILON) * 100) / 100
              })
            );
            const customerDataForRadarChartDataTable: IReferenceCompetitorForTable =
              {
                companyName: initialValues?.customer?.businessName,
                product: initialValues?.customerInformation?.product,
                distribution: initialValues?.customerInformation?.distribution,
                priceSelling: initialValues?.customerInformation?.priceSelling,
                communicationMarketing:
                  initialValues?.customerInformation?.communicationMarketing,
                // globalPosition: Math.round(((
                //   initialValues?.customerInformation?.product +
                //   initialValues?.customerInformation?.distribution +
                //   initialValues?.customerInformation?.priceSelling +
                //   initialValues?.customerInformation?.communicationMarketing
                // ) + Number.EPSILON) * 100) / 100
              };
            this.radarChartDataTable?.unshift(
              customerDataForRadarChartDataTable
            );
            this.getCompetitorWeightedAverageForRadarChartTable();
            this.bubbleChartDataTable = [];
            this.bubbleChartDataTable =
              initialValues.competitorExportOrientation?.tableResult.map(
                (
                  competitor: IReferenceCompetitorForTable
                ): IReferenceCompetitorForBubbleChartTable => ({
                  companyName: competitor?.companyName,
                  revenueCustomer: competitor?.revenueCustomer,
                  revenueExport: competitor?.revenueExport,
                  quotaExport:
                    Math.round(
                      (competitor?.quotaExport + Number.EPSILON) * 100
                    ) / 100,
                  nCountryExport: competitor?.nCountryExport,
                })
              );
            const customerComparisionForTable: IReferenceCompetitorForBubbleChartTable =
              {
                companyName:
                  initialValues.competitorExportOrientation?.customerInfoResult
                    ?.businessName,
                revenueCustomer:
                  initialValues.competitorExportOrientation?.customerInfoResult
                    ?.revenueCustomer,
                revenueExport:
                  initialValues.competitorExportOrientation?.customerInfoResult
                    ?.revenueExport,
                quotaExport:
                  Math.round(
                    (initialValues.competitorExportOrientation
                      ?.customerInfoResult?.quotaExport +
                      Number.EPSILON) *
                      100
                  ) / 100,
                nCountryExport:
                  initialValues.competitorExportOrientation?.customerInfoResult
                    ?.nCountryExport,
              };
            this.bubbleChartDataTable?.unshift(customerComparisionForTable);
            const customerDataForRadarChart: any = {
              value: [
                initialValues?.customerInformation?.product,
                initialValues?.customerInformation?.distribution,
                initialValues?.customerInformation?.priceSelling,
                initialValues?.customerInformation?.communicationMarketing,
              ],
              name: initialValues?.customer?.businessName,
            };
            // Loop the competitors and get the biggest exportRevenue
            const biggestRevenue: number = Math.max(
              ...initialValues?.competitorExportOrientation?.graphResult?.map(
                (item: GraphResult) => item.dataPoints[0].z
              )
            );
            // Loop the competitors and get the biggest number of countries export
            this.biggestNrOfCountriesExport = Math.max(
              ...initialValues?.competitorExportOrientation?.graphResult?.map(
                (item: GraphResult) => item.dataPoints[0].x
              )
            );
            this.data1 =
              initialValues.competitorExportOrientation.graphResult.map(
                (item: any, index: number) => ({
                  name: item.name,
                  value: [
                    item.dataPoints[0].x,
                    item.dataPoints[0].y,
                    /**
                     * The below formula is used to get the
                     * value of the echarts bubble related to the looped competitor and biggest revenue
                     *
                     * (competitor exportRevenue * max value of the echarts bubble to display) / biggestRevenue
                     *
                     * Example 1
                     * (2.000.000 € * 100) / 3.000.000 € =  66,67
                     * Example 2
                     * (1.500.000 € * 100) / 3.000.000 € = 50
                     *
                     * In addition there is an inline condition that check if the
                     * expected result (value) is less than 20 (minimum acceptable
                     * bubble area) and set it to 20.
                     * But if the value is 0 the condition will return 0
                     */
                    Math.round(
                      ((item.dataPoints[0].z * 100) / biggestRevenue +
                        Number.EPSILON) *
                        100
                    ) /
                      100 <
                      10 &&
                    Math.round(
                      ((item.dataPoints[0].z * 100) / biggestRevenue +
                        Number.EPSILON) *
                        100
                    ) /
                      100 >
                      0
                      ? 20
                      : Math.round(
                          ((item.dataPoints[0].z * 100) / biggestRevenue +
                            Number.EPSILON) *
                            100
                        ) / 100,
                    item.name,
                    item.dataPoints[0].z,
                  ],
                  itemStyle: {
                    color: newChartColors[index],
                  },
                })
              );
            this.data2 = initialValues?.referenceCompetitors?.map(
              (comp: IReferenceCompetitor): any => ({
                value: [
                  comp.product,
                  comp.distribution,
                  comp.priceSelling,
                  comp.communicationMarketing,
                ],
                name: comp.companyName,
              })
            );
            this.data2?.unshift(customerDataForRadarChart);
            this.initBubbleChart();
            this.initRadarChart();
            this.competitorExportOrientation =
              initialValues.competitorExportOrientation;
            initialValues.competitorExportOrientation?.graphResult.map((item) =>
              item.dataPoints.forEach((obj) => {
                this.allXValues.push(obj.x);
                this.allYValues.push(obj.y);
              })
            );
          }
          this.updateIdCustomerInfoDescription(
            initialValues?.customerDescription
          );
          this.countries = initialValues.countries;
          this.filteredCountriesByArea = {
            europe: [],
            northamerica: [],
            southamerica: [],
            asia: [],
            africa: [],
            oceania: [],
            centralamerica: [],
            middleeast: [],
          };
          initialValues.countries.forEach((country: ICountries) => {
            switch (country.idMarketArea) {
              case 1:
                this.filteredCountriesByArea.europe.push(country);
                break;
              case 2:
                this.filteredCountriesByArea.northamerica.push(country);
                break;
              case 3:
                this.filteredCountriesByArea.southamerica.push(country);
                break;
              case 4:
                this.filteredCountriesByArea.asia.push(country);
                break;
              case 5:
                this.filteredCountriesByArea.africa.push(country);
                break;
              case 6:
                this.filteredCountriesByArea.oceania.push(country);
                break;
              case 7:
                this.filteredCountriesByArea.centralamerica.push(country);
                break;
              case 8:
                this.filteredCountriesByArea.middleeast.push(country);
                break;
            }
          });
          this.products = [];
          initialValues.productsServices.forEach(
            (product: IProductsServices) => {
              if (product.idProductType === 1) {
                this.products.push(product);
              }
            }
          );

          this.nationalCompetitors.clear();
          this.foreignCompetitors.clear();

          this.nationalCompetitorCountries = [];
          this.foreignCompetitorCountries = [];
          initialValues.referenceCompetitors?.forEach(
            (referenceCompetitor: IReferenceCompetitor, index) => {
              if (referenceCompetitor.isGlobal === COMPETITOR_TYPE.National) {
                this.addCompetitor(
                  COMPETITOR_TYPE.National,
                  referenceCompetitor
                );
              }
              if (referenceCompetitor.isGlobal === COMPETITOR_TYPE.Foreign) {
                this.addCompetitor(
                  COMPETITOR_TYPE.Foreign,
                  referenceCompetitor
                );
              }
            }
          );
          // this.nationalCompetitors.controls.forEach((competitor: FormGroup, index: number) => {
          //   if (!competitor.get('product').value) {
          //     competitor.get('product').markAsTouched();
          //   }
          // });

          this.referenceCompetitorsForm.markAsPristine();
          this.referenceCompetitorsForm.markAsUntouched();
          // Check positioning validators
          if (this.isIsp) {
            this.nationalCompetitors.controls.forEach(
              (competitor: FormGroup) => {
                competitor.get('product').markAsTouched();
                competitor.get('distribution').markAsTouched();
                competitor.get('priceSelling').markAsTouched();
                competitor.get('communicationMarketing').markAsTouched();
              }
            );
            this.foreignCompetitors.controls.forEach(
              (competitor: FormGroup) => {
                competitor.get('product').markAsTouched();
                competitor.get('distribution').markAsTouched();
                competitor.get('priceSelling').markAsTouched();
                competitor.get('communicationMarketing').markAsTouched();
              }
            );
          }

          // this.checkDirtyForm();

          // this.nationalCompetitorsForm.markAsPristine();
          // this.nationalCompetitorsForm.markAsUntouched();
          // this.foreignCompetitorsForm.markAsPristine();
          // this.foreignCompetitorsForm.markAsUntouched();

          // this.checkNationalDirtyForm();
          // this.checkForeignDirtyForm();
          this.loader = false;

          if (this.isLabManagerInternal || this.isReadOnly) {
            // this.nationalCompetitorsForm.disable();
            // this.foreignCompetitorsForm.disable();
            this.referenceCompetitorsForm.disable();
            this.customerDescriptionForm.disable();
          }
          this.startDataOnInit = {
            competitors: this.referenceCompetitorsForm.getRawValue(),
            description: this.customerDescriptionForm.get(
              'indirectCompetitionProdDesc'
            ).value,
          };
          this.startDataOnInit.competitors.nationalCompetitors.forEach(
            (comp: any) => {
              delete comp.idCountries;
            }
          );
          this.startDataOnInit.competitors.foreignCompetitors.forEach(
            (comp: any) => {
              delete comp.idCountries;
            }
          );
        },
      })
    );
  }

  ngAfterViewInit(): void {
    this.expandPanelOnScroll();
  }

  get allCompetitors(): any[] {
    return this.nationalCompetitors
      .getRawValue()
      .concat(this.foreignCompetitors.getRawValue());
  }

  /**
   * @DESCRIPTION Get to easy access to form arraies properties
   */
  get nationalCompetitors(): FormArray {
    return this.referenceCompetitorsForm.get(
      'nationalCompetitors'
    ) as FormArray;
  }

  getNationalCustomerCompetitos(competitorIndex: number): FormArray {
    return this.nationalCompetitors
      .at(competitorIndex)
      .get('customerCompetitors') as FormArray;
  }

  getNationalCompetitorMarketAreas(competitorIndex: number): FormArray {
    return this.nationalCompetitors
      .at(competitorIndex)
      .get('competitorMarketAreas') as FormArray;
  }

  getNationalCompetitorDistribution(competitorIndex: number): FormArray {
    return this.nationalCompetitors
      .at(competitorIndex)
      .get('competitorDistributionChannelDTOAggregation') as FormArray;
  }

  getNationalCompetitorDistributionAnagafics(
    competitorIndex: number,
    channelIndex?: number
  ): FormArray {
    return (
      this.getNationalCompetitorDistribution(competitorIndex).at(
        channelIndex
      ) as FormGroup
    ).controls.competitorDistributionChannel as FormArray;
    // return (
    //   (
    //     this.nationalCompetitors
    //       .at(competitorIndex)
    //       .get('competitorDistribution') as FormArray
    //   ).at[channelIndex] as FormGroup
    // ).controls.anagrafics as FormArray;
  }

  /**
   * @description Need to iterate the form group in competitorDistribution controls
   * @param competitorIndex The index of competitor
   */
  getNationalDistributionNames(
    competitorIndex: number
  ): DistributionChannelForm[] {
    return this.getNationalCompetitorDistribution(
      competitorIndex
    ).getRawValue();
  }

  get foreignCompetitors(): FormArray {
    return this.referenceCompetitorsForm.get('foreignCompetitors') as FormArray;
  }

  getForeignCustomerCompetitos(competitorIndex: number): FormArray {
    return this.foreignCompetitors
      .at(competitorIndex)
      .get('customerCompetitors') as FormArray;
  }

  getForeignCompetitorMarketAreas(competitorIndex: number): FormArray {
    return this.foreignCompetitors
      .at(competitorIndex)
      .get('competitorMarketAreas') as FormArray;
  }

  getForeignCompetitorDistribution(competitorIndex: number): FormArray {
    return this.foreignCompetitors
      .at(competitorIndex)
      .get('competitorDistributionChannelDTOAggregation') as FormArray;
  }

  getForeignCompetitorDistributionAnagafics(
    competitorIndex: number,
    channelIndex: number
  ): FormArray {
    return (
      this.getForeignCompetitorDistribution(competitorIndex).at(
        channelIndex
      ) as FormGroup
    ).controls.competitorDistributionChannel as FormArray;
  }

  /**
   * @description Need to iterate the form group in competitorDistribution controls
   * @param competitorIndex The index of competitor
   */
  getForeignDistributionNames(
    competitorIndex: number
  ): DistributionChannelForm[] {
    return this.getForeignCompetitorDistribution(competitorIndex).getRawValue();
  }
  // ************END GET

  addCompetitor(
    competitorType: COMPETITOR_TYPE,
    referenceCompetitor?: IReferenceCompetitor
  ): void {
    const competitor = this.fb.group({
      city: [
        referenceCompetitor?.city || '',
        this.isAgent ? 
          [Validators.maxLength(45)] :
          [Validators.required, Validators.maxLength(45)]
      ],
      commercialBrands: [
        referenceCompetitor?.commercialBrands || '',
        [Validators.maxLength(255)],
      ],
      communicationMarketing: [
        referenceCompetitor?.communicationMarketing,
        [...this.__getValidators()],
      ],
      companyName: [
        referenceCompetitor?.companyName || '',
        [Validators.required, Validators.maxLength(45)],
      ],
      competitorDistributionChannelDTOAggregation: this.fb.array([]),
      competitorMarketAreas: this.fb.array([]),
      countryCompetitorMarketAreas: [
        referenceCompetitor?.countryCompetitorMarketAreas || null,
        [Validators.required],
      ],
      customerCompetitors: this.fb.array([]),
      distribution: [
        referenceCompetitor?.distribution,
        [...this.__getValidators()],
      ],
      gamma: [referenceCompetitor?.gamma],
      idCompetitorCompany: [referenceCompetitor?.idCompetitorCompany || null],
      idCompetitorType: [
        referenceCompetitor?.idCompetitorType || null,
        [Validators.required],
      ],
      idCountry: [
        referenceCompetitor?.idCountry || null,
        [Validators.required],
      ],
      idCustomer: [referenceCompetitor?.idCustomer || null],
      idProduct: [referenceCompetitor?.idProduct || null],
      isGlobal: [competitorType],
      note: [referenceCompetitor?.note || '', [Validators.required]],
      prePostSellingService: [referenceCompetitor?.prePostSellingService],
      priceSelling: [
        referenceCompetitor?.priceSelling,
        [...this.__getValidators()],
      ],
      product: [referenceCompetitor?.product, [...this.__getValidators()]],
      province: [
        referenceCompetitor?.province || '',
        this.isAgent ? 
          [Validators.maxLength(45)] :
          [Validators.required, Validators.maxLength(45)]
      ],
      quality: [referenceCompetitor?.quality],
      revenueCustomer: [referenceCompetitor?.revenueCustomer || null,
        !this.isAgent ? [Validators.required] : []
      ],
      revenueExport: [referenceCompetitor?.revenueExport || 0],
      webSiteUrl: [
        referenceCompetitor?.webSiteUrl || '',
        this.isAgent ? 
          [Validators.maxLength(45)] :
          [Validators.required, Validators.maxLength(45)]
      ],
      // establishmentYear: [referenceCompetitor?.establishmentYear || '', [Validators.required, Validators.maxLength(4)]],
      // revenueImport: [referenceCompetitor?.revenueImport || null, [Validators.required, Validators.maxLength(45)]],
      // importCountryList: [referenceCompetitor?.importCountryList || '', [Validators.required, Validators.maxLength(255)]],
      // idCountries: [null],
      // otherProduct: [ referenceCompetitor?.otherProduct ],
      idProducts: [null],
      // competitorDistribution: this.fb.array([]),
    });
    // If isp mark those fields as required
    if (this.isIsp) {
      competitor.get('product').markAsTouched();
      competitor.get('distribution').markAsTouched();
      competitor.get('priceSelling').markAsTouched();
      competitor.get('communicationMarketing').markAsTouched();
    }

    let competitorIndex: number;
    if (competitorType === COMPETITOR_TYPE.National) {
      competitorIndex = this.nationalCompetitors.length;
      // this.nationalCompetitorCountries.push([]);
      this.nationalCompetitors.push(competitor);
    }
    if (competitorType === COMPETITOR_TYPE.Foreign) {
      competitorIndex = this.foreignCompetitors.length;
      // this.foreignCompetitorCountries.push([]);
      this.foreignCompetitors.push(competitor);
    }

    // Products
    if (referenceCompetitor?.customerCompetitors?.length > 0) {
      referenceCompetitor?.customerCompetitors?.forEach((product) => {
        this.addProduct(competitorType, competitorIndex, product);
      });
    }

    // Markets
    // if (referenceCompetitor?.competitorMarketAreas?.length > 0) {
    //   referenceCompetitor?.competitorMarketAreas?.forEach((presenceMarket) => {
    //     this.addPresenceMarket(competitorType, competitorIndex, presenceMarket);
    //   });
    // }

    // Channels
    if (this.functionalities.isp.create || this.functionalities.isp.edit) {
      referenceCompetitor?.competitorDistributionChannelDTOAggregation?.forEach(
        (channel: DistributionChannelForm, i: number) => {
          this.addDistribution(competitorType, competitorIndex, i, channel);
        }
      );
      // if (referenceCompetitor?.competitorDistributionChannels?.length > 0) {
      //   const distributions: DistributionChannelForm[] =
      //     filterAndMapCompetitorDistributions(
      //       referenceCompetitor?.competitorDistributionChannels
      //     );
      //   distributions.forEach((channel: DistributionChannelForm, i: number) => {
      //     this.addDistribution(competitorType, competitorIndex, i, channel);
      //   });
      // }
    }
  }

  isNewCompetitor(competitor: FormGroup): boolean {
    if (!competitor.getRawValue().idCompetitorCompany) {
      return true;
    }
  }

  private __getValidators(): ValidatorFn[] {
    const validators: ValidatorFn[] = [];
    // This Controls must be required only if User have isp functionality create and edit
    if (this.functionalities.isp.create || this.functionalities.isp.edit) {
      validators.push(
        Validators.required,
        Validators.min(1),
        Validators.max(10)
      );
    }
    return validators;
  }

  addPresenceMarket(
    competitorType: COMPETITOR_TYPE,
    competitorIndex: number,
    presenceMarket?: PresenceMarket,
    idCountry?: number
  ): void {
    const market = this.fb.group({
      idCountry: [presenceMarket?.idCountry || idCountry || null],
      idCompetitorMarketArea: [presenceMarket?.idCompetitorMarketArea || ''],
    });

    let markets: FormArray;
    if (competitorType === COMPETITOR_TYPE.National) {
      markets = this.nationalCompetitors
        .at(competitorIndex)
        .get('competitorMarketAreas') as FormArray;
      this.nationalCompetitorCountries[competitorIndex].push(
        presenceMarket?.idCountry
      );
    } else {
      markets = this.foreignCompetitors
        .at(competitorIndex)
        .get('competitorMarketAreas') as FormArray;
      this.foreignCompetitorCountries[competitorIndex].push(
        presenceMarket?.idCountry
      );
    }

    market.markAsPristine();
    market.markAsUntouched();
    markets.push(market);
  }

  addDistribution(
    competitorType: COMPETITOR_TYPE,
    competitorIndex: number,
    channelIndex?: number,
    channel?: DistributionChannelForm
  ): void {
    const distributionForm: FormGroup = this.fb.group({
      // distributionName: [channel?.distributionName?.trim() || null],
      idChannelType: [channel?.idChannelType || null, [Validators.required]],
      idSpecificationChannelType: [
        {
          value: channel?.idSpecificationChannelType || null,
          disabled: !channel?.idChannelType,
        },
        [Validators.required],
      ],
      importanceLevel: [channel?.importanceLevel || null, Validators.required],
      competitorDistributionChannel: this.fb.array([]),
    });

    if (competitorType === COMPETITOR_TYPE.National) {
      this.getNationalCompetitorDistribution(competitorIndex).push(
        distributionForm
      );
      this.newNationalDistribution.reset();
    }
    if (competitorType === COMPETITOR_TYPE.Foreign) {
      this.getForeignCompetitorDistribution(competitorIndex).push(
        distributionForm
      );
      this.newForeignDistribution.reset();
    }

    if (channel) {
      channel?.competitorDistributionChannel?.forEach(
        (anagrafic: DistributionChannel) => {
          this.addDistributionAnagrafic(
            competitorType,
            competitorIndex,
            channel,
            channelIndex,
            anagrafic
          );
        }
      );
    } else {
      this.addDistributionAnagrafic(
        competitorType,
        competitorIndex,
        channel,
        channelIndex
      );
    }
  }

  addDistributionAnagrafic(
    competitorType: COMPETITOR_TYPE,
    competitorIndex: number,
    channel?: DistributionChannelForm,
    channelIndex?: number,
    distributionAnagrafic?: DistributionChannel
  ): void {
    const anagraficForm = this.fb.group({
      idCompetitorDistributionChannel: [
        distributionAnagrafic?.idCompetitorDistributionChannel || null,
      ],
      idChannelType: [channel?.idChannelType],
      idSpecificationChannelType: [channel?.idSpecificationChannelType],
      name: [distributionAnagrafic?.name || '', [Validators.required]],
      country: [
        distributionAnagrafic?.country || '',
        [Validators.required, Validators.maxLength(45)],
      ],
      webSiteUrl: [
        distributionAnagrafic?.webSiteUrl || '',
        [Validators.required, Validators.maxLength(45)],
      ],
    });

    anagraficForm.markAsPristine();
    anagraficForm.markAsUntouched();

    if (competitorType === COMPETITOR_TYPE.National) {
      this.getNationalCompetitorDistributionAnagafics(
        competitorIndex,
        channelIndex
      ).push(anagraficForm);
    }
    if (competitorType === COMPETITOR_TYPE.Foreign) {
      this.getForeignCompetitorDistributionAnagafics(
        competitorIndex,
        channelIndex
      ).push(anagraficForm);
    }
  }

  addProduct(
    competitorType: COMPETITOR_TYPE,
    competitorIndex: number,
    customerProduct?: CompetitorProduct,
    idProduct?: number
  ): void {
    const product = this.fb.group({
      idProduct: [customerProduct?.idProduct || idProduct || null],
      idCompetitorCompany: [customerProduct?.idCompetitorCompany || null],
      idCustomerCompetitor: [customerProduct?.idCustomerCompetitor || null],
    });

    let products: FormArray;
    if (competitorType === COMPETITOR_TYPE.National) {
      products = this.nationalCompetitors
        .at(competitorIndex)
        .get('customerCompetitors') as FormArray;
    } else {
      products = this.foreignCompetitors
        .at(competitorIndex)
        .get('customerCompetitors') as FormArray;
    }

    product.markAsPristine();
    product.markAsUntouched();
    products.push(product);
    if (competitorType === COMPETITOR_TYPE.National) {
      this.nationalCompetitors
        .at(competitorIndex)
        .get('idProducts')
        .setValue(
          products.getRawValue().map((element) => {
            return element.idProduct;
          })
        );
      this.nationalCompetitors.markAsDirty();
    } else {
      this.foreignCompetitors
        .at(competitorIndex)
        .get('idProducts')
        .setValue(
          products.getRawValue().map((element) => {
            return element.idProduct;
          })
        );
      this.foreignCompetitors.markAsDirty();
    }
  }

  /*
    Check if competitor has one or more marketAreas, distributionChannels or products,
    if it does, a confirmation dialog will appear, otherwhise it will get removed from the formArray.
  */
  removeCompetitor(i: number, competitorType: COMPETITOR_TYPE): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    let dialogRef: MatDialogRef<ConfirmationDialogComponent, any>;
    let competitors: FormArray;
    let competitorHasItemWithin: boolean;
    if (competitorType === COMPETITOR_TYPE.National) {
      competitors = this.nationalCompetitors;
    }
    if (competitorType === COMPETITOR_TYPE.Foreign) {
      competitors = this.foreignCompetitors;
    }
    const idCompetitor: number =
      competitors.at(i).get('idCompetitorCompany').value || null;
    if (
      competitors.at(i).get('idCompetitorCompany').value ||
      (competitors.at(i).get('competitorMarketAreas') as FormArray)?.length >
        0 ||
      (competitors.at(i).get('competitorDistributionChannels') as FormArray)
        ?.length > 0 ||
      (competitors.at(i).get('customerCompetitors') as FormArray)?.length > 0
    ) {
      competitorHasItemWithin = true;
    } else {
      competitorHasItemWithin = false;
    }
    if (idCompetitor) {
      if (this.referenceCompetitorsForm.dirty) {
        this.common.showToast(
          this.translations.SaveChangesBefore,
          ToastStatus.warning,
          3000
        );
      } else {
        if (competitorHasItemWithin) {
          dialogConfig.data = {
            title: this.translate.instant('COMMON.Attention'),
            message: this.translate.instant('IR_FORM.RemoveCompetitorWarn'),
            buttonTrue: this.translations.Yes,
            buttonFalse: this.translations.No,
          };
          dialogRef = this.dialog.open(
            ConfirmationDialogComponent,
            dialogConfig
          );
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.subscriptions.add(
                this.irFormService
                  .deleteCustomerCompetitor(idCompetitor)
                  .subscribe({
                    complete: () => {
                      competitors.removeAt(i);
                      this.refreshReferenceCompetitors.emit();
                    },
                  })
              );
            }
          });
        } else {
          dialogConfig.data = {
            title: this.translate.instant('COMMON.Attention'),
            message: this.translate.instant('COMMON.DeleteConfirmationMessage'),
            buttonTrue: this.translations.Yes,
            buttonFalse: this.translations.No,
          };
          dialogRef = this.dialog.open(
            ConfirmationDialogComponent,
            dialogConfig
          );
          dialogRef.afterClosed().subscribe((result: boolean) => {
            if (result) {
              this.subscriptions.add(
                this.irFormService
                  .deleteCustomerCompetitor(idCompetitor)
                  .subscribe({
                    complete: () => {
                      competitors.removeAt(i);
                      this.refreshReferenceCompetitors.emit();
                    },
                  })
              );
            }
          });
        }
      }
    } else {
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translate.instant('COMMON.DeleteConfirmationMessage'),
        buttonTrue: this.translations.Yes,
        buttonFalse: this.translations.No,
      };
      dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          competitors.removeAt(i);
        }
      });
    }
  }

  removePresenceMarket(
    competitorType: COMPETITOR_TYPE,
    competitorIndex: number,
    idMarket: number
  ): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    dialogConfig.data = {
      title: this.translate.instant('COMMON.Attention'),
      message: this.translate.instant('COMMON.DeleteConfirmationMessage'),
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let competitors: FormArray;
    let competitorsSelect: QueryList<MatSelect>;
    if (competitorType === COMPETITOR_TYPE.National) {
      competitors = this.nationalCompetitors;
      competitorsSelect = this.nationalCountrySelect;
    } else if (competitorType === COMPETITOR_TYPE.Foreign) {
      competitors = this.foreignCompetitors;
      competitorsSelect = this.foreignCountrySelect;
    }
    let marketId;
    let marketIndex;
    competitors.value[competitorIndex].competitorMarketAreas.forEach(
      (element, i) => {
        if (element.idCountry === idMarket) {
          marketId = element.idCompetitorMarketArea;
          marketIndex = i;
        }
      }
    );
    if (marketId) {
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          competitorsSelect.toArray()[competitorIndex].close();
          this.subscriptions.add(
            this.irFormService
              .deleteCustomerCompetitorMarketArea(marketId)
              .subscribe({
                complete: () => {
                  (
                    competitors
                      .at(competitorIndex)
                      .get('competitorMarketAreas') as FormArray
                  ).removeAt(marketIndex);
                },
              })
          );
        } else {
          competitors
            .at(competitorIndex)
            .get('idCountries')
            .setValue(
              (
                competitors
                  .at(competitorIndex)
                  .get('competitorMarketAreas') as FormArray
              )
                .getRawValue()
                .map((element) => {
                  return element.idCountry;
                })
            );
          competitors
            .at(competitorIndex)
            .get('idCountries')
            .updateValueAndValidity();
        }
      });
    } else {
      (
        competitors
          .at(competitorIndex)
          .get('competitorMarketAreas') as FormArray
      ).removeAt(marketIndex);
    }
  }

  removeProduct(
    competitorType: COMPETITOR_TYPE,
    competitorIndex: number,
    idProduct: number
  ): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    dialogConfig.data = {
      title: this.translate.instant('COMMON.Attention'),
      message: this.translate.instant('COMMON.DeleteConfirmationMessage'),
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let competitors: FormArray;
    // let competitorsSelect: QueryList<MatSelect>;
    if (competitorType === COMPETITOR_TYPE.National) {
      competitors = this.nationalCompetitors;
      // competitorsSelect = this.nationalProductSelect;
    } else if (competitorType === COMPETITOR_TYPE.Foreign) {
      competitors = this.foreignCompetitors;
      // competitorsSelect = this.foreignProductSelect;
    }
    let productId: number;
    let productIndex: number;
    competitors.value[competitorIndex].customerCompetitors.forEach(
      (element, i) => {
        if (element.idProduct === idProduct) {
          productId = element.idCustomerCompetitor;
          productIndex = i;
        }
      }
    );
    if (productId) {
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      const prodBody = competitors
        .at(competitorIndex)
        .get('customerCompetitors').value;
      (
        competitors.at(competitorIndex).get('customerCompetitors') as FormArray
      ).removeAt(productIndex);
      competitors
        .at(competitorIndex)
        .get('idProducts')
        .setValue(
          (
            competitors
              .at(competitorIndex)
              .get('customerCompetitors') as FormArray
          )
            .getRawValue()
            .map((element) => {
              return element.idProduct;
            })
        );
      competitors
        .at(competitorIndex)
        .get('idProducts')
        .updateValueAndValidity();

      dialogRef.afterClosed().subscribe((result: boolean) => {
        if (result) {
          // competitorsSelect.toArray()[competitorIndex].close();
          this.subscriptions.add(
            this.irFormService
              .deleteCustomerCompetitorProduct(productId)
              .subscribe({
                complete: () => {},
              })
          );
        } else {
          this.addProduct(
            competitorType,
            competitorIndex,
            prodBody[productIndex],
            idProduct
          );

          // competitors.at(competitorIndex).get('idProducts').setValue((competitors.at(competitorIndex)
          // .get('customerCompetitors') as FormArray).getRawValue().map((element) => {
          //   return element.idProduct;
          // }));
          // competitors.at(competitorIndex).get('idProducts').updateValueAndValidity();
        }
      });
    } else {
      // const prodBody = competitors.at(competitorIndex).get('customerCompetitors').value;
      (
        competitors.at(competitorIndex).get('customerCompetitors') as FormArray
      ).removeAt(productIndex);
      competitors
        .at(competitorIndex)
        .get('idProducts')
        .setValue(
          (
            competitors
              .at(competitorIndex)
              .get('customerCompetitors') as FormArray
          )
            .getRawValue()
            .map((element) => {
              return element.idProduct;
            })
        );
      competitors
        .at(competitorIndex)
        .get('idProducts')
        .updateValueAndValidity();
    }
    competitors.markAsDirty();
  }

  removeChannel(
    competitorType: COMPETITOR_TYPE,
    competitorIndex: number,
    channelIndex
  ): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    dialogConfig.data = {
      title: this.translate.instant('COMMON.Attention'),
      message: this.translate.instant(
        'COMMON.DeleteCompetitorChannelConfirmationMessage'
      ),
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (competitorType === COMPETITOR_TYPE.National) {
          if (
            this.getNationalCompetitorDistributionAnagafics(
              competitorIndex,
              channelIndex
            )?.length
          ) {
            this.getNationalCompetitorDistributionAnagafics(
              competitorIndex,
              channelIndex
            )
              .getRawValue()
              .forEach((anagraphic: DistributionChannel) => {
                if (anagraphic.idCompetitorDistributionChannel) {
                  this.subscriptions.add(
                    this.irFormService
                      .deleteCustomerCompetitorDistributionChannel(
                        anagraphic.idCompetitorDistributionChannel
                      )
                      .subscribe({
                        complete: () => {
                          this.getNationalCompetitorDistributionAnagafics(
                            competitorIndex,
                            channelIndex
                          ).removeAt(0);
                          if (
                            !this.getNationalCompetitorDistributionAnagafics(
                              competitorIndex,
                              channelIndex
                            )?.length
                          ) {
                            this.getNationalCompetitorDistribution(
                              competitorIndex
                            ).removeAt(channelIndex);
                          }
                        },
                      })
                  );
                } else {
                  this.getNationalCompetitorDistributionAnagafics(
                    competitorIndex,
                    channelIndex
                  ).removeAt(0);
                  if (
                    !this.getNationalCompetitorDistributionAnagafics(
                      competitorIndex,
                      channelIndex
                    )?.length
                  ) {
                    this.getNationalCompetitorDistribution(
                      competitorIndex
                    ).removeAt(channelIndex);
                  }
                }
              });
          } else {
            this.getNationalCompetitorDistribution(competitorIndex).removeAt(
              channelIndex
            );
          }
        }
        if (competitorType === COMPETITOR_TYPE.Foreign) {
          if (
            this.getForeignCompetitorDistributionAnagafics(
              competitorIndex,
              channelIndex
            )?.length
          ) {
            this.getForeignCompetitorDistributionAnagafics(
              competitorIndex,
              channelIndex
            )
              .getRawValue()
              .forEach((anagraphic: DistributionChannel) => {
                if (anagraphic.idCompetitorDistributionChannel) {
                  this.subscriptions.add(
                    this.irFormService
                      .deleteCustomerCompetitorDistributionChannel(
                        anagraphic.idCompetitorDistributionChannel
                      )
                      .subscribe({
                        complete: () => {
                          this.getForeignCompetitorDistributionAnagafics(
                            competitorIndex,
                            channelIndex
                          ).removeAt(0);
                          if (
                            !this.getForeignCompetitorDistributionAnagafics(
                              competitorIndex,
                              channelIndex
                            )?.length
                          ) {
                            this.getForeignCompetitorDistribution(
                              competitorIndex
                            ).removeAt(channelIndex);
                          }
                        },
                      })
                  );
                } else {
                  this.getForeignCompetitorDistributionAnagafics(
                    competitorIndex,
                    channelIndex
                  ).removeAt(0);
                  if (
                    !this.getForeignCompetitorDistributionAnagafics(
                      competitorIndex,
                      channelIndex
                    )?.length
                  ) {
                    this.getForeignCompetitorDistribution(
                      competitorIndex
                    ).removeAt(channelIndex);
                  }
                }
              });
          } else {
            this.getForeignCompetitorDistribution(competitorIndex).removeAt(
              channelIndex
            );
          }
        }
      }
    });
  }

  removeDistributionAnagrafic(
    competitorType: COMPETITOR_TYPE,
    competitorIndex: number,
    channelIndex: number,
    anagraficIdx: number
  ): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.width = '400px';
    dialogConfig.data = {
      title: this.translate.instant('COMMON.Attention'),
      message: this.translate.instant('COMMON.DeleteConfirmationMessage'),
      buttonTrue: this.translations.Yes,
      buttonFalse: this.translations.No,
    };
    let idCompetitorDistribution: number | string;
    if (competitorType === COMPETITOR_TYPE.National) {
      idCompetitorDistribution =
        this.getNationalCompetitorDistributionAnagafics(
          competitorIndex,
          channelIndex
        )
          .at(anagraficIdx)
          .get('idCompetitorDistributionChannel').value;
      if (idCompetitorDistribution) {
        const dialogRef = this.dialog.open(
          ConfirmationDialogComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService
                .deleteCustomerCompetitorDistributionChannel(
                  idCompetitorDistribution
                )
                .subscribe({
                  complete: () => {
                    this.getNationalCompetitorDistributionAnagafics(
                      competitorIndex,
                      channelIndex
                    ).removeAt(anagraficIdx);
                    // if (
                    //   !this.getNationalCompetitorDistributionAnagafics(
                    //     competitorIndex,
                    //     channelIndex
                    //   )?.length
                    // ) {
                    //   this.getNationalCompetitorDistribution(
                    //     competitorIndex
                    //   ).removeAt(channelIndex);
                    // }
                  },
                })
            );
          }
        });
      } else {
        const dialogRef = this.dialog.open(
          ConfirmationDialogComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.getNationalCompetitorDistributionAnagafics(
              competitorIndex,
              channelIndex
            ).removeAt(anagraficIdx);
            // if (
            //   !this.getNationalCompetitorDistributionAnagafics(
            //     competitorIndex,
            //     channelIndex
            //   )?.length
            // ) {
            //   this.getNationalCompetitorDistribution(competitorIndex).removeAt(
            //     channelIndex
            //   );
            // }
          }
        });
      }
    }
    if (competitorType === COMPETITOR_TYPE.Foreign) {
      idCompetitorDistribution = this.getForeignCompetitorDistributionAnagafics(
        competitorIndex,
        channelIndex
      )
        .at(anagraficIdx)
        .get('idCompetitorDistributionChannel').value;
      if (idCompetitorDistribution) {
        const dialogRef = this.dialog.open(
          ConfirmationDialogComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService
                .deleteCustomerCompetitorDistributionChannel(
                  idCompetitorDistribution
                )
                .subscribe({
                  complete: () => {
                    this.getForeignCompetitorDistributionAnagafics(
                      competitorIndex,
                      channelIndex
                    ).removeAt(anagraficIdx);
                    // if (
                    //   !this.getForeignCompetitorDistributionAnagafics(
                    //     competitorIndex,
                    //     channelIndex
                    //   )?.length
                    // ) {
                    //   this.getForeignCompetitorDistribution(
                    //     competitorIndex
                    //   ).removeAt(channelIndex);
                    // }
                  },
                })
            );
          }
        });
      } else {
        const dialogRef = this.dialog.open(
          ConfirmationDialogComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((result: boolean) => {
          if (result) {
            this.getForeignCompetitorDistributionAnagafics(
              competitorIndex,
              channelIndex
            ).removeAt(anagraficIdx);
            // if (
            //   !this.getForeignCompetitorDistributionAnagafics(
            //     competitorIndex,
            //     channelIndex
            //   )?.length
            // ) {
            //   this.getForeignCompetitorDistribution(competitorIndex).removeAt(
            //     channelIndex
            //   );
            // }
          }
        });
      }
    }
  }

  onSubmit(): void {
    if (
      this.referenceCompetitorsForm.valid &&
      this.referenceCompetitorsForm.dirty
    ) {
      const body: IReferenceCompetitor[] = [
        ...this.nationalCompetitors.getRawValue(),
        ...this.foreignCompetitors.getRawValue(),
      ].map((competitor: ReferenceCompetitorForm): IReferenceCompetitor => {
        competitor = {
          ...competitor,
          competitorDistributionChannels: Object.keys(
            competitor.competitorDistribution
          ).reduce(
            (
              competitorDistributionChannels: DistributionChannel[],
              idChannelType: string
            ) => {
              return [
                ...competitorDistributionChannels,
                ...competitor.competitorDistribution[idChannelType].anagrafics,
              ];
            },
            []
          ),
        };
        delete competitor.competitorDistribution;
        return competitor;
      });
      this.submitFuncEmitter.emit(body);
      this.referenceCompetitorsForm.markAsUntouched();
      this.referenceCompetitorsForm.markAsPristine();
    }
    this.customerDescrSubmit('indirectCompetitionProdDesc');
  }

  // submitNational(): void {
  //   if (this.nationalCompetitorsForm.valid && this.nationalCompetitorsForm.dirty) {
  //     const body: IReferenceCompetitor[] = [
  //       ...this.nationalCompetitors.getRawValue()
  //     ].map((competitor: ReferenceCompetitorForm): IReferenceCompetitor => {
  //       competitor = {
  //         ...competitor,
  //         competitorDistributionChannels: Object.keys(competitor.competitorDistribution)
  //         .reduce((competitorDistributionChannels: DistributionChannel[], distributionName: string) => {
  //           return [ ...competitorDistributionChannels, ...competitor.competitorDistribution[distributionName].anagrafics ];
  //         }, [])
  //       };
  //       delete competitor.competitorDistribution;
  //       return competitor;
  //     });
  //     this.submitFuncEmitter.emit(body);
  //   }
  //   this.customerDescrSubmit();
  // }

  // submitForeign(): void {
  //   if (this.foreignCompetitorsForm.valid && this.foreignCompetitorsForm.dirty) {
  //     const body: IReferenceCompetitor[] = [
  //       ...this.foreignCompetitors.getRawValue()
  //     ].map((competitor: ReferenceCompetitorForm): IReferenceCompetitor => {
  //       competitor = {
  //         ...competitor,
  //         competitorDistributionChannels: Object.keys(competitor.competitorDistribution)
  //         .reduce((competitorDistributionChannels: DistributionChannel[], distributionName: string) => {
  //           return [ ...competitorDistributionChannels, ...competitor.competitorDistribution[distributionName].anagrafics ];
  //         }, [])
  //       };
  //       delete competitor.competitorDistribution;
  //       return competitor;
  //     });
  //     this.submitFuncEmitter.emit(body);
  //   }
  //   this.customerDescrSubmit();
  // }

  submitCompetitors(): void {
    if (this.isIsp) {
      if (
        (this.referenceCompetitorsForm.valid &&
          this.referenceCompetitorsForm.dirty &&
          this.customerDescriptionForm.get('indirectCompetitionProdDesc')
            .valid) ||
        (this.referenceCompetitorsForm.valid &&
          this.customerDescriptionForm.get('indirectCompetitionProdDesc')
            .valid &&
          this.customerDescriptionForm.get('indirectCompetitionProdDesc').dirty)
      ) {
        if (!this.referenceCompetitorsForm.dirty) {
          this.customerDescrSubmit('indirectCompetitionProdDesc');
        } else {
          const body: IReferenceCompetitor[] = [
            ...this.referenceCompetitorsForm.getRawValue().nationalCompetitors,
            ...this.referenceCompetitorsForm.getRawValue().foreignCompetitors,
          ];
          this.submitFuncEmitter.emit(body);
          this.customerDescrSubmit('indirectCompetitionProdDesc');
        }
      }
    } else {
      if (
        this.referenceCompetitorsForm.valid &&
        this.referenceCompetitorsForm.dirty
      ) {
        const body: IReferenceCompetitor[] = [
          ...this.referenceCompetitorsForm.getRawValue().nationalCompetitors,
          ...this.referenceCompetitorsForm.getRawValue().foreignCompetitors,
        ];
        this.submitFuncEmitter.emit(body);
      }
    }
  }

  // checkDirtyForm(): void {
  //   const isEmpty = [
  //     ...this.nationalCompetitors.getRawValue(),
  //     ...this.foreignCompetitors.getRawValue()
  //   ].length === 0;

  //   const result = this.referenceCompetitorsForm?.dirty ||
  //                  this.referenceCompetitorsForm?.touched ||
  //                  !this.referenceCompetitorsForm?.valid ||
  //                  isEmpty;

  //   this.layoutService.triggerDirtyForm({ referenceCompetitors: result });
  // }

  // checkNationalDirtyForm(): void {
  //   const isEmpty = [
  //     ...this.nationalCompetitors.getRawValue()
  //   ].length === 0;

  //   const result = this.nationalCompetitorsForm?.dirty ||
  //                  this.nationalCompetitorsForm?.touched ||
  //                  !this.nationalCompetitorsForm?.valid ||
  //                  isEmpty;

  //   this.layoutService.triggerDirtyForm({ referenceCompetitors: result });
  // }

  // checkForeignDirtyForm(): void {
  //   const isEmpty = [
  //     ...this.foreignCompetitors.getRawValue()
  //   ].length === 0;

  //   const result = this.foreignCompetitorsForm?.dirty ||
  //                  this.foreignCompetitorsForm?.touched ||
  //                  !this.foreignCompetitorsForm?.valid ||
  //                  isEmpty;

  //   this.layoutService.triggerDirtyForm({ referenceCompetitors: result });
  // }

  checkDirtyForm(): boolean {
    const competitorsBody: any = this.referenceCompetitorsForm.getRawValue();
    competitorsBody.nationalCompetitors.forEach((comp: any) => {
      delete comp.idCountries;
    });
    competitorsBody.foreignCompetitors.forEach((comp: any) => {
      delete comp.idCountries;
    });
    const result: boolean =
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify(
        {
          competitors: competitorsBody,
          description: this.customerDescriptionForm.get(
            'indirectCompetitionProdDesc'
          ).value,
        },
        this.irFormService.replacer
      );
    return result;
  }

  checkInvalidForm(): boolean {
    this.referenceCompetitorsForm.markAllAsTouched();
    return this.referenceCompetitorsForm.invalid;
  }

  checkAtLeastOneNationalCompetitor(): boolean {
    return this.nationalCompetitors.getRawValue().some((competitor: IReferenceCompetitor) => {
      return competitor.idCompetitorCompany;
    });
  }

  getRandomColor(): string {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#E6' + ('000000' + color).slice(-6);
  }

  handleProductSelect(
    competitorType,
    option: MatCheckbox,
    i: number,
    idProduct: number
  ): void {
    if (option.checked) {
      this.addProduct(competitorType, i, null, idProduct);
    }
    if (!option.checked) {
      this.removeProduct(competitorType, i, idProduct);
    }
  }

  handleCountrySelect(competitorType, option, i): void {
    if (option._selected) {
      this.addPresenceMarket(competitorType, i, null, option.value);
    }
    if (!option._selected) {
      this.removePresenceMarket(competitorType, i, option.value);
    }
  }

  checkIfProductIsSelected(competitor: FormGroup, idProduct: number): boolean {
    if (competitor.get('idProducts')?.value?.includes(idProduct)) {
      return true;
    } else {
      return false;
    }
  }

  // DEPRECATED: not usefull anymore because of using currency pipe insted of this method
  // setCurrencyMask(value: number): any {
  //   const curr = new Intl.NumberFormat('it-IT', {
  //     style: 'currency',
  //     currency: 'EUR',
  //   }).format(value);
  //   return curr.substr(curr.length - 1) + ' ' + curr.substr(0, curr.length - 1);
  // }

  expandPanelOnScroll(): void {
    this.formPanel.toArray().forEach((panel, index) => {
      setTimeout(() => {
        const invalidControl: HTMLElement =
          panel._body.nativeElement.querySelector('.mat-form-field-invalid');
        if (invalidControl) {
          this.formPanel.toArray()[index].open();
        }
      });
    });
  }

  checkValuesForPositioningChart(
    competitor: FormGroup,
    index: number
  ): boolean {
    if (this.isIsp) {
      if (competitor.get('isGlobal').value === COMPETITOR_TYPE.National) {
        if (
          !(this.nationalCompetitors.at(index) as FormGroup)?.get('product')
            .value ||
          !(this.nationalCompetitors.at(index) as FormGroup)?.get(
            'distribution'
          ).value ||
          !(this.nationalCompetitors.at(index) as FormGroup)?.get(
            'priceSelling'
          ).value ||
          !(this.nationalCompetitors.at(index) as FormGroup)?.get(
            'communicationMarketing'
          ).value ||
          (this.nationalCompetitors.at(index) as FormGroup)?.get('product')
            .touched ||
          (this.nationalCompetitors.at(index) as FormGroup)?.get('distribution')
            .touched ||
          (this.nationalCompetitors.at(index) as FormGroup)?.get('priceSelling')
            .touched ||
          (this.nationalCompetitors.at(index) as FormGroup)?.get(
            'communicationMarketing'
          ).touched
        ) {
          (this.nationalCompetitors.at(index) as FormGroup)
            ?.get('product')
            .markAsTouched();
          (this.nationalCompetitors.at(index) as FormGroup)
            ?.get('distribution')
            .markAsTouched();
          (this.nationalCompetitors.at(index) as FormGroup)
            ?.get('priceSelling')
            .markAsTouched();
          (this.nationalCompetitors.at(index) as FormGroup)
            ?.get('communicationMarketing')
            .markAsTouched();
          return true;
        }
      } else if (competitor.get('isGlobal').value === COMPETITOR_TYPE.Foreign) {
        if (
          !(this.foreignCompetitors.at(index) as FormGroup)?.get('product')
            .value ||
          !(this.foreignCompetitors.at(index) as FormGroup)?.get('distribution')
            .value ||
          !(this.foreignCompetitors.at(index) as FormGroup)?.get('priceSelling')
            .value ||
          !(this.foreignCompetitors.at(index) as FormGroup)?.get(
            'communicationMarketing'
          ).value ||
          (this.foreignCompetitors.at(index) as FormGroup)?.get('product')
            .touched ||
          (this.foreignCompetitors.at(index) as FormGroup)?.get('distribution')
            .touched ||
          (this.foreignCompetitors.at(index) as FormGroup)?.get('priceSelling')
            .touched ||
          (this.foreignCompetitors.at(index) as FormGroup)?.get(
            'communicationMarketing'
          ).touched
        ) {
          (this.foreignCompetitors.at(index) as FormGroup)
            ?.get('product')
            .markAsTouched();
          (this.foreignCompetitors.at(index) as FormGroup)
            ?.get('distribution')
            .markAsTouched();
          (this.foreignCompetitors.at(index) as FormGroup)
            ?.get('priceSelling')
            .markAsTouched();
          (this.foreignCompetitors.at(index) as FormGroup)
            ?.get('communicationMarketing')
            .markAsTouched();
          return true;
        }
      }
    }
  }

  checkMacroArea(
    selectType: string,
    area: string,
    competitorIndex: number,
    competitorType: string
  ): void {
    const countriesContainer: number[] = [];
    if (competitorType === 'national') {
      if (selectType === 'select') {
        this.nationalCountrySelect
          .toArray()
          [competitorIndex].options.forEach((item: MatOption) => {
            if (item.group.label === this.translate.instant('COMMON.' + area)) {
              countriesContainer.push(item.value);
              item.select();
            }
          });
      } else {
        this.nationalCountrySelect
          .toArray()
          [competitorIndex].options.forEach((item: MatOption) => {
            if (item.group.label === this.translate.instant('COMMON.' + area)) {
              item.deselect();
            }
          });
      }
    } else {
      if (selectType === 'select') {
        this.foreignCountrySelect
          .toArray()
          [competitorIndex].options.forEach((item: MatOption) => {
            if (item.group.label === this.translate.instant('COMMON.' + area)) {
              countriesContainer.push(item.value);
              item.select();
            }
          });
      } else {
        this.foreignCountrySelect
          .toArray()
          [competitorIndex].options.forEach((item: MatOption) => {
            if (item.group.label === this.translate.instant('COMMON.' + area)) {
              item.deselect();
            }
          });
      }
    }
  }

  showChannelsManagementDialog(
    event: PointerEvent,
    type: string,
    channel: FormGroup
  ): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    if (type === 'channel') {
      dialogConfig.data = {
        managementType: 'channel',
        channels: this.channelsType,
        idCustomer: this.idCustomer,
      };
    } else {
      dialogConfig.data = {
        managementType: 'channelSpecification',
        channelsSpecifications: this.specificationsChannels.filter(
          (spec: IChannelSpecification) => {
            return spec.idChannelType === channel.get('idChannelType').value;
          }
        ),
        idCustomer: this.idCustomer,
        idChannelType: channel.get('idChannelType').value,
      };
    }
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        if (type === 'channel') {
          this.subscriptions.add(
            this.irFormService
              .getChannelsTypeByIdCustomer(this.idCustomer, false)
              .subscribe({
                next: (channels: IChannelsType[]) => {
                  this.channelsType = channels;
                  if (
                    !channels.some((ch: any) => {
                      return (
                        ch.idChannelType === channel.get('idChannelType').value
                      );
                    })
                  ) {
                    channel.get('idChannelType').setValue(null);
                    this.activateSpecificationsSelect(
                      new MatSelectChange(null, null),
                      channel
                    );
                  }
                },
              })
          );
        } else {
          this.subscriptions.add(
            this.common
              .getSpecificationChannels(this.idCustomer, false)
              .subscribe({
                next: (channelsSpec: IChannelSpecification[]) => {
                  this.specificationsChannels = channelsSpec;
                  if (
                    !channelsSpec.some((ch: any) => {
                      return (
                        ch.idSpecificationChannelType ===
                        channel.get('idSpecificationChannelType').value
                      );
                    })
                  ) {
                    channel.get('idSpecificationChannelType').setValue(null);
                  }
                },
              })
          );
        }
      }
    });
  }

  activateSpecificationsSelect(
    change: MatSelectChange,
    channel: FormGroup
  ): void {
    if (change.value) {
      channel.get('idSpecificationChannelType').reset();
      channel.get('idSpecificationChannelType').enable();
      (
        channel.get('competitorDistributionChannel') as FormArray
      ).controls.forEach((anagraphic: FormGroup) => {
        anagraphic
          .get('idChannelType')
          .setValue(channel.get('idChannelType').value);
      });
    } else {
      channel.get('idSpecificationChannelType').reset();
      channel.get('idSpecificationChannelType').disable();
    }
  }

  setSpecificationToRelativeAnagrafics(
    change: MatSelectChange,
    channel: FormGroup
  ): void {
    if (change.value) {
      (
        channel.get('competitorDistributionChannel') as FormArray
      ).controls.forEach((anagraphic: FormGroup) => {
        anagraphic
          .get('idSpecificationChannelType')
          .setValue(channel.get('idSpecificationChannelType').value);
      });
    }
  }

  getCompetitorWeightedAverageForRadarChartTable(): void {
    const WeightValues = { PRODUCT: 35, PRICE: 35, DISTRIBUTION: 20, COMM: 10 };

    this.radarChartDataTable?.forEach(
      (competitor: IReferenceCompetitorForTable) => {
        competitor.weightedAverage =
          (competitor.product * WeightValues.PRODUCT +
            competitor.distribution * WeightValues.DISTRIBUTION +
            competitor.priceSelling * WeightValues.PRICE +
            competitor.communicationMarketing * WeightValues.COMM) /
          100;
      }
    );
    this.radarChartDataTable
      .sort((a, b) => a.weightedAverage - b.weightedAverage)
      .reverse();
  }

  initBubbleChart(): void {
    this.exportOrientationChartOptions = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          return `
            ${params.marker} <span>${params.data.name}</span>
            <br>
            <span>${this.translations.QuotaExport}: </span>
            <strong>${params.value[1].toFixed(2)} %</strong>
            <br>
            <span>${this.translations.RevenueExport}: </span>
            <strong>${params.value[4].toLocaleString()} €</strong>
            <br>
            <span>${this.translations.NCountryExport}: </span>
            <strong>${params.value[0]}</strong>
          `;
        },
      },
      legend: {
        left: 'center',
        top: 0,
      },
      xAxis: {
        name: 'Nr. Paesi Export',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: 20,
        },
        axisLine: {
          show: true,
          // lineStyle: {
          //   color: '#EAF0F4',
          // },
        },
        minInterval: 1,
        max: this.biggestNrOfCountriesExport + 1,
      },
      yAxis: {
        name: '% Fatturato Export',
        nameLocation: 'middle',
        nameTextStyle: {
          padding: 20,
        },
        axisLine: {
          show: true,
          // lineStyle: {
          //   color: '#EAF0F4',
          // },
        },
      },
      series: [
        {
          type: 'scatter',
          data: this.data1,
          symbolSize: (data) => {
            return data[2];
          },
        },
      ],
    };
  }

  initRadarChart(): void {
    this.positioningAnalysisChartOptions = {
      tooltip: {
        trigger: 'item',
      },
      radar: {
        splitArea: null,
        splitNumber: 1,
        indicator: [
          { name: this.translations.Product, max: 10, color: '#505050' },
          { name: this.translations.distribution, max: 10, color: '#505050' },
          { name: this.translations.PriceSelling, max: 10, color: '#505050' },
          {
            name: this.translations.CommunicationMarketingOnNewLine,
            max: 10,
            color: '#505050',
          },
        ],
      },
      series: [
        {
          color: newChartColors,
          type: 'radar',
          symbol: 'none',
          data: this.data2,
        },
      ],
    };
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
