<!-- <div>
  <form [formGroup]="searchCode" (ngSubmit)="onSubmit()">
    <input
      class="search-bar-input"
      type="text"
      [placeholder]="'COMMON.SearchCustomsCode' | translate"
      name="description"
      formControlName="description"
    >
    <button
      color="primary"
      class="search-bar-button"
      [disabled]="!searchCode.get('description').value"
      mat-icon-button
      type="submit"
    >
      <mat-icon>search</mat-icon>
    </button>
  </form>
</div> -->

<!-- <ng-template #searchCodeDialog>
  <mat-dialog-content>
    <table
      [hidden]="cnProductsDataSource?.data?.length < 1 || loader === true"
      mat-table
      [dataSource]="cnProductsDataSource"
      class="custom-table"
      matSort
    >
      <ng-container matColumnDef="cnCode">
        <th mat-header-cell *matHeaderCellDef>CN8</th>
        <td mat-cell *matCellDef="let product">
          <span class="app-color">{{ product.cnCode }}</span>
        </td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef class="custom-th">{{ 'IR_FORM.Description' | translate }}</th>
        <td mat-cell *matCellDef="let product" class="custom-td">{{ product.description }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>      
    </table>
    <div class="no-matches-container" *ngIf="(cnProductsDataSource?.data?.length < 1 || cnProductsDataSource === null) && loader === false">
      <span>
        {{ 'COMMON.NoMatches' | translate }}
      </span>
    </div>
    
    <div *ngIf="loader" class="loader-container">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div *ngIf="internalLoader" class="internal-loader-container">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end" style="margin-top: 20px;">
    <mat-paginator
      [hidden]="cnProductsService?.totalElements < 10 || loader === true"
      [length]="cnProductsService?.totalElements"
      [pageIndex]="0"
      [pageSize]="itemsPerPage"
      (page)="changePage($event)"
    ></mat-paginator>
    <button mat-button matDialogClose>{{ 'COMMON.Close' | translate }}</button>
  </mat-dialog-actions>
</ng-template> -->

<mat-dialog-content>
  <table
    [hidden]="cnProductsDataSource?.data?.length < 1 || loader === true"
    mat-table
    [dataSource]="cnProductsDataSource"
    class="custom-table"
    matSort
  >
    <ng-container matColumnDef="cnCode">
      <th mat-header-cell *matHeaderCellDef>CN8</th>
      <td mat-cell *matCellDef="let product">
        <span matDialogClose [ngClass]="codeIsClickable ? 'clickable-span' : 'app-color'" (click)="codeSelected(product.cnCode)">
          {{ product.cnCode }}
        </span>
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef class="custom-th">{{ 'IR_FORM.Description' | translate }}</th>
      <td mat-cell *matCellDef="let product" class="custom-td">{{ product.description }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>      
  </table>
  <div class="no-matches-container" *ngIf="(cnProductsDataSource?.data?.length < 1 || cnProductsDataSource === null) && loader === false">
    <span>
      {{ 'COMMON.NoMatches' | translate }}
    </span>
  </div>
  
  <div *ngIf="loader" class="loader-container">
    <mat-spinner diameter="30"></mat-spinner>
  </div>
  <div *ngIf="internalLoader" class="internal-loader-container">
    <mat-spinner diameter="30"></mat-spinner>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" style="margin-top: 20px;">
  <mat-paginator
    [hidden]="cnProductsService?.totalElements < 10 || loader === true"
    [length]="cnProductsService?.totalElements"
    [pageIndex]="0"
    [pageSize]="itemsPerPage"
    (page)="changePage($event)"
  ></mat-paginator>
  <button mat-button matDialogClose>{{ 'COMMON.Close' | translate }}</button>
</mat-dialog-actions>