import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NotesDialogComponent } from 'src/app/components/notes-dialog/notes-dialog.component';
import { DocumentDTO } from 'src/app/models/contract';
import { ICustomerAndProject } from 'src/app/models/customer';
import { Note } from 'src/app/models/note';
import {
  ICustomerPageProjectForTable,
  IProjectForTable,
  ProjectDTO,
} from 'src/app/models/project';
import {
  IColumnsName,
  ITableButton,
  ITableButtons,
  ITableColumn,
  ITableOptions,
  ToastStatus,
} from 'src/app/models/utility';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import {
  fromDocumentDtoToDocumentForTable,
  fromProjectDtoToProjectTable,
} from 'src/app/utility/elaborations';
import { IconDefinition } from '@fortawesome/fontawesome-common-types/index';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import {
  DIRECTION_ROLE,
  IconTypes,
  AGENT_ROLE,
  LAB_MANAGER,
  RI_STATUS,
  ADMINISTRATOR_ROLE,
  FileLimitLogo,
  apiRootUrl,
  AGENT_SUPERVISOR,
  CONTACT_REFERENCE_TYPE,
  LAB_MANAGER_INTERNAL,
  DATE_PIKER_FORMATS,
  Roles,
} from 'src/app/config';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';
import { PublishProjectDialogComponent } from 'src/app/components/publish-project-dialog/publish-project-dialog.component';
import { LayoutService } from 'src/app/services/layout/layout.service';
import * as moment from 'moment';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  dialogSubscriptions: Subscription = new Subscription();
  customer: ICustomerAndProject;
  customerAddress: string;
  businessName: string;
  administrativeReferent: {};
  projectReferent: {};
  translations: any;
  prTranslations: any;
  loaderDocuments: boolean = false;
  loaderProjects: boolean = false;
  documentType: any = IconTypes;
  customerLogoPath: string;

  /* Documents Table */
  documentsDataSource: DocumentDTO[];

  documentsColumnsName: IColumnsName[];
  documentsDisplayedColumns: string[];
  documentsTableButtons: ITableButtons[];
  documentsTableOptions: ITableOptions;

  documentsTableColumns: ITableColumn[];
  documentsTableColumnsButtons: ITableButton[];

  /* Projects Table */
  projectsDataSource: ICustomerPageProjectForTable[];

  projectsColumnsName: IColumnsName[];
  projectsDisplayedColumns: string[];
  projectsTableButtons: ITableButtons[];
  projectsTableMenuButtons: ITableButtons[];
  projectsTableSubMenuButtons: ITableButtons[];
  projectsTableOptions: ITableOptions;

  projectsTableColumns: ITableColumn[];
  projectsTableColumnsButtons: ITableButton[];
  projectsTableColumnsButtonsWithHeading: ITableButton[];

  isAuthorized: boolean = false;
  isEnabled: boolean = false;
  isDirection: boolean = false;
  isAdmin: boolean = false;
  isSupervisorAgent: boolean = false;
  isAgent: boolean = false;
  isTeamLeader: boolean = false;
  isLabManager: boolean = false;
  isLabManagerInternal: boolean = false;

  sizeTypeFileError: boolean = false;
  maxSize = FileLimitLogo.maxSize;
  fileTypes = FileLimitLogo.fileTypes;
  fileToUpload: File;

  searchValueProjects: string;
  searchValueDocuments: string;
  idUser: number;

  constructor(
    private irFormService: IrFormService,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private common: CommonService
  ) {}

  ngOnInit(): void {
    this.idUser = JSON.parse(localStorage.getItem('session_user')).idUser;
    this.loaderDocuments = true;
    this.loaderProjects = true;
    this.subscriptions.add(
      this.translate.get('CUSTOMER').subscribe((translations: any) => {
        this.translations = translations;
        this.initializeDocumentsTable();
        this.initializeProjectsTable();
      })
    );
    this.subscriptions.add(
      this.translate.get('PR_STATUS').subscribe((translations: any) => {
        this.prTranslations = translations;
      })
    );
    this.subscriptions.add(
      this.route.paramMap.subscribe((params: Params) => {
        this.customer = { customer: { idCustomer: params.get('idCustomer') } };
        this.getAllDocuments();
        this.getAllProjects();
      })
    );
  }

  generateBreadCrumb(): void {
    this.subscriptions.add(
      this.layoutService.generateBreadcrumb([
        {
          label: this.translate.instant('PAGES.customers'),
          path: '/customers',
          pageTitle: false,
        },
        {
          label: this.customer?.customer?.businessName,
          path: null,
          pageTitle: true,
        },
      ])
    );
  }

  initializeDocumentsTable(): void {
    this.documentsTableColumns = [
      {
        name: 'name',
        translatedName: this.translations.Name,
      },
      {
        name: 'type',
        translatedName: this.translations.Type,
      },
      {
        name: 'dateInsert',
        translatedName: this.translations.DateInsert,
        type: 'date',
      },
      {
        name: 'userName',
        translatedName: this.translations.User,
      },
      {
        name: 'buttons',
        disableSort: true,
      },
    ];
  }

  initializeProjectsTable(): void {
    this.projectsTableColumns = [
      {
        name: 'idProject',
        translatedName: this.translations.IdProject,
      },
      {
        name: 'code',
        translatedName: this.translations.Code,
      },
      {
        name: 'idProjectMaster',
        translatedName: this.translations.ParentProject,
      },
      {
        name: 'status',
        translatedName: this.translations.Status,
      },
      {
        name: 'startWorking',
        translatedName: this.translations.CreationDate,
        type: 'date',
      },
      {
        name: 'lastModified',
        translatedName: this.translations.LastWorking,
        type: 'date',
      },
      {
        name: 'buttonWithHeading',
        translatedName: this.translations.Documents,
        align: 'center',
      },
      {
        name: 'buttons',
        disableSort: true,
      },
    ];
    // this.projectsTableButtons = [
    //   {
    //     name: this.translations.Note,
    //     disabled: (project: IProjectForTable): boolean => !this.haveNotes(project),
    //     click: (project: IProjectForTable): void => {
    //       this.openNoteListDialog(project);
    //     },
    //     icon: 'comment',
    //     iconClass: 'dark-icon-color',
    //     disabledClass: 'disabled-icon-color'
    //   },
    // ];
  }

  getAllDocuments(): void {
    this.loaderDocuments = true;
    this.subscriptions.add(
      this.irFormService
        .getCustomerDocuments(
          this.loaderDocuments,
          this.customer.customer.idCustomer
        )
        .subscribe((data: DocumentDTO[]) => {
          this.documentsDataSource = data.map(
            fromDocumentDtoToDocumentForTable
          );
          this.documentsTableColumnsButtons = [
            {
              icon: 'download_icon',
              class: 'primary',
              clickFunction: (item: any) =>
                this.visualizeDocument(item.idDocument),
            },
            {
              icon: 'trash_icon',
              class: 'error',
              clickFunction: (item: any) =>
                this.deleteCustomerDocument(item.idDocument),
            },
          ];
          this.loaderDocuments = false;
        })
    );
  }

  getAllProjects(): void {
    this.customer = {
      customer: { idCustomer: this.customer?.customer?.idCustomer },
    };
    this.loaderProjects = true;
    this.subscriptions.add(
      this.irFormService
        .getAllProjectByCustomer(true, this.customer.customer.idCustomer)
        .subscribe({
          next: (data: ICustomerAndProject) => {
            this.loaderProjects = false;
            if (data.customer) {
              this.customer = data;
              this.businessName = data?.customer?.businessName;
              this.customerAddress =
                (data.customer?.address?.address
                  ? data.customer?.address?.address
                  : '') +
                (data.customer?.address?.city
                  ? ', ' + data.customer?.address?.city
                  : '') +
                (data.customer?.address?.province
                  ? ', ' + data.customer?.address?.province
                  : '');
              for (const referent of data.customer?.customerContact) {
                if (
                  referent.customerReferenceType.idCustomerReferenceType ===
                  CONTACT_REFERENCE_TYPE.AdministrativeReferent
                ) {
                  this.administrativeReferent = referent;
                }
                if (
                  referent.customerReferenceType.idCustomerReferenceType ===
                  CONTACT_REFERENCE_TYPE.ProjectReferent
                ) {
                  this.projectReferent = referent;
                }
              }
              if (data.customer.pathImage) {
                this.customerLogoPath = `${apiRootUrl}/customer/public?path=${data.customer.pathImage}`;
              }

              this.generateBreadCrumb();
            }
            this.isEnabled = this.customer?.customer?.status;
            this.authService.userProfile?.roles.map((role) => {
              if (role === DIRECTION_ROLE) {
                this.isAuthorized = true;
              }
              if (role === DIRECTION_ROLE) {
                this.isDirection = true;
              }
              if (role === ADMINISTRATOR_ROLE) {
                this.isAdmin = true;
              }
              if (role === AGENT_SUPERVISOR) {
                this.isSupervisorAgent = true;
              }
              if (role === AGENT_ROLE) {
                this.isAgent = true;
              }
              if (role === LAB_MANAGER) {
                this.isLabManager = true;
              }
              if (role === LAB_MANAGER_INTERNAL) {
                this.isLabManagerInternal = true;
              }
              if (role === Roles.LabManagerSupervisor) {
                this.isTeamLeader = true;
              }
            });
            if (data.project) {
              this.projectsDataSource = data?.project?.map(
                (project: ProjectDTO): ICustomerPageProjectForTable => ({
                  idProject: project?.idProject,
                  code: project?.code,
                  idProjectMaster: project?.idProjectMaster,
                  status: this.prTranslations[project?.projectStatusDesc],
                  idProjectStatus: project?.idProjectStatus,
                  startWorking: project?.startWorkTime,
                  lastModified: project?.lastModified,
                  documents: project?.documents || [],
                  agent: project?.agent,
                  manager: project?.manager,
                  idCustomer: project?.idCustomer,
                  idUser: project?.idUser,
                })
              );
              this.projectsTableColumnsButtonsWithHeading = [
                {
                  icon: 'download_icon',
                  class: 'primary',
                  disabled: (item: ICustomerPageProjectForTable): boolean => {
                    return !item.documents.length;
                  },
                  subMenu: {
                    key: 'documents',
                    clickFunction: (item: DocumentDTO) => {
                      this.visualizeDocument(item.idDocument);
                    },
                  },
                },
              ];
              this.projectsTableColumnsButtons = [
                {
                  icon: 'more_option_2_icon',
                  class: 'primary',
                  subMenu: {
                    buttons: [
                      {
                        text: (project: ICustomerPageProjectForTable) => {
                          if (
                            project.idProjectStatus === RI_STATUS.RiWorking ||
                            project.idProjectStatus === RI_STATUS.IspWorking
                          ) {
                            return this.translations.Edit;
                          }
                          if (
                            project.idProjectStatus >= RI_STATUS.IspWorking2 &&
                            project.idProjectStatus <= RI_STATUS.IspWorking6
                          ) {
                            return this.translations.DeliveryPS;
                          }
                        },
                        conditions: (project: ICustomerPageProjectForTable) => {
                          if (this.isAgent) {
                            return project.agent.idUser === project.idUser;
                          }
                          if (this.isLabManager || this.isLabManagerInternal) {
                            return project.manager.idUser === project.idUser;
                          }
                        },
                        clickFunction: (
                          project: ICustomerPageProjectForTable
                        ) => {
                          if (this.isAgent) {
                            this.router.navigate([
                              `/assessment/${project.idProject}`,
                            ]);
                          }
                          if (this.isLabManager || this.isLabManagerInternal) {
                            if (
                              project.idProjectStatus >= RI_STATUS.IspWorking2
                            ) {
                              this.router.navigate([
                                `/ps-form/${project.idProject}`,
                              ]);
                            } else if (
                              project.idProjectStatus === RI_STATUS.IspWorking
                            ) {
                              this.router.navigate([
                                `/ps/${project.idProject}`,
                              ]);
                            }
                          }
                        },
                      },
                      // {
                      //   text: this.translations.ViewCurrentPS,
                      //   conditions: (
                      //     project: ICustomerPageProjectForTable
                      //   ): boolean => {
                      //     return (
                      //       project.idUser !== this.idUser &&
                      //       project.idProjectStatus >= RI_STATUS.IspWorking &&
                      //       project.idProjectStatus !== RI_STATUS.Closed
                      //     );
                      //   },
                      //   // this.checkViewPS1Conditions(project),
                      //   clickFunction: (
                      //     project: ICustomerPageProjectForTable
                      //   ) => {
                      //     this.router.navigate([
                      //       `/ps-readonly`,
                      //       project.idProject,
                      //     ]);
                      //   },
                      // },
                      {
                        text: this.translate.instant('COMMON.Visualize'),
                        conditions: (project: IProjectForTable) => {
                          return (
                            (this.isLabManager &&
                              project.idProjectStatus >=
                                RI_STATUS.IspWorking2) ||
                            (this.isDirection &&
                              project.idProjectStatus >= RI_STATUS.RiWorking &&
                              project.idProjectStatus !== RI_STATUS.Closed)
                          );
                        },
                        subMenu: {
                          buttons: [
                            {
                              text: this.translate.instant('COMMON.Assessment'),
                              conditions: (project: IProjectForTable) => {
                                return (
                                  (this.isDirection &&
                                    project.idProjectStatus ===
                                      RI_STATUS.RiWorking) ||
                                  project.idProjectStatus ===
                                    RI_STATUS.RiDelivered ||
                                  project.idProjectStatus ===
                                    RI_STATUS.RiApproved
                                );
                              },
                              clickFunction: (project: IProjectForTable) => {
                                this.router.navigate([
                                  `/assessment-readonly`,
                                  project.idProject,
                                ]);
                              },
                            },
                            {
                              text: this.translate.instant(
                                'COMMON.SalesMatching'
                              ),
                              conditions: (project: IProjectForTable) => {
                                return (
                                  this.isDirection &&
                                  project.idProjectStatus ===
                                    RI_STATUS.NoContract
                                );
                              },
                              clickFunction: (project: IProjectForTable) => {
                                this.router.navigate([
                                  `/assessment-readonly`,
                                  project.idProject,
                                ]);
                              },
                            },
                            {
                              text: this.translate.instant('PS_VERSION.PS1'),
                              conditions: (project: IProjectForTable) => {
                                return (
                                  (this.isLabManager &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking2) ||
                                  (this.isDirection &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking)
                                );
                              },
                              clickFunction: (project: IProjectForTable) => {
                                this.router.navigate([
                                  `/ps-readonly`,
                                  project.idProject,
                                ]);
                              },
                            },
                            {
                              text: this.translate.instant('PS_VERSION.PS2'),
                              conditions: (project: IProjectForTable) => {
                                return (
                                  (this.isLabManager &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking3) ||
                                  (this.isDirection &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking2)
                                );
                              },
                              clickFunction: (project: IProjectForTable) => {
                                this.router.navigate([
                                  `/ps-form-readonly`,
                                  project.idProject,
                                  project.idCustomer,
                                  RI_STATUS.IspWorking2,
                                ]);
                              },
                            },
                            {
                              text: this.translate.instant('PS_VERSION.PS3'),
                              conditions: (project: IProjectForTable) => {
                                return (
                                  (this.isLabManager &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking4) ||
                                  (this.isDirection &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking3)
                                );
                              },
                              clickFunction: (project: IProjectForTable) => {
                                this.router.navigate([
                                  `/ps-form-readonly`,
                                  project.idProject,
                                  project.idCustomer,
                                  RI_STATUS.IspWorking3,
                                ]);
                              },
                            },
                            {
                              text: this.translate.instant('PS_VERSION.PS4'),
                              conditions: (project: IProjectForTable) => {
                                return (
                                  (this.isLabManager &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking5) ||
                                  (this.isDirection &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking4)
                                );
                              },
                              clickFunction: (project: IProjectForTable) => {
                                this.router.navigate([
                                  `/ps-form-readonly`,
                                  project.idProject,
                                  project.idCustomer,
                                  RI_STATUS.IspWorking4,
                                ]);
                              },
                            },
                            {
                              text: this.translate.instant('PS_VERSION.PS5'),
                              conditions: (project: IProjectForTable) => {
                                return (
                                  (this.isLabManager &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking6) ||
                                  (this.isDirection &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking5)
                                );
                              },
                              clickFunction: (project: IProjectForTable) => {
                                this.router.navigate([
                                  `/ps-form-readonly`,
                                  project.idProject,
                                  project.idCustomer,
                                  RI_STATUS.IspWorking5,
                                ]);
                              },
                            },
                            {
                              text: this.translate.instant('PS_VERSION.PS6'),
                              conditions: (project: IProjectForTable) => {
                                return (
                                  (this.isLabManager &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspDelivered6) ||
                                  (this.isDirection &&
                                    project.idProjectStatus >=
                                      RI_STATUS.IspWorking6)
                                );
                              },
                              clickFunction: (project: IProjectForTable) => {
                                this.router.navigate([
                                  `/ps-form-readonly`,
                                  project.idProject,
                                  project.idCustomer,
                                  RI_STATUS.IspWorking6,
                                ]);
                              },
                            },
                          ],
                        },
                      },
                      // {
                      //   text: this.translations.ViewPS1,
                      //   conditions: (
                      //     project: ICustomerPageProjectForTable
                      //   ): boolean => {
                      //     return (
                      //       project.idProjectStatus >= RI_STATUS.IspWorking2
                      //     );
                      //   },
                      //   // this.checkViewPS1Conditions(project),
                      //   clickFunction: (project: IProjectForTable) => {
                      //     this.router.navigate([
                      //       `/ps-readonly`,
                      //       project.idProject,
                      //     ]);
                      //   },
                      // },
                      // {
                      //   text: this.translations.ViewASS,
                      //   conditions: (
                      //     project: ICustomerPageProjectForTable
                      //   ): boolean => {
                      //     // TODO: chiedere a Mirko se nella tabella progetti
                      //     // del singolo cliente bisogna mostrare il tasto
                      //     // visualizza ASS per i ruoli ADMIN o Direzione
                      //     return (
                      //       project.idProjectStatus === RI_STATUS.RiDelivered ||
                      //       project.idProjectStatus === RI_STATUS.RiApproved
                      //     );
                      //     // this.checkViewAssConditions(project),
                      //   },
                      //   clickFunction: (
                      //     project: ICustomerPageProjectForTable
                      //   ) => {
                      //     this.router.navigate([
                      //       `/assessment-readonly`,
                      //       project.idProject,
                      //     ]);
                      //   },
                      // },
                      {
                        // text: this.translations.ViewCRM,
                        text: this.translations.GoToCRM,
                        conditions: (
                          project: ICustomerPageProjectForTable
                        ): boolean =>
                          project.idProjectStatus >= RI_STATUS.IspWorking2 &&
                          !this.isDirection &&
                          !this.isAdmin,
                        clickFunction: (
                          project: ICustomerPageProjectForTable
                        ) => {
                          this.router.navigate([
                            `/crm/customer/${project.idCustomer}/project/${project.idProject}`,
                          ]);
                        },
                      },
                      {
                        text: this.translations.AssignInternalManager,
                        conditions: (
                          project: ICustomerPageProjectForTable
                        ): boolean =>
                          this.isDirection &&
                          !(
                            project.idProjectStatus < RI_STATUS.IspWorking2 ||
                            project.idProjectMaster
                          ),
                        clickFunction: (
                          project: ICustomerPageProjectForTable
                        ) => {
                          this.router.navigate([
                            `/assign-project`,
                            project.idProject,
                            { type: 'assignDLManagerInternal' },
                          ]);
                        },
                      },
                      {
                        text: this.translations.ChangeProductStrategy,
                        conditions: (
                          project: ICustomerPageProjectForTable
                        ): boolean =>
                          (this.isDirection || this.isTeamLeader) &&
                          !(project.idProjectStatus < RI_STATUS.IspWorking2),
                        clickFunction: (
                          project: ICustomerPageProjectForTable
                        ) => {
                          this.changeProductStrategy(project);
                        },
                      },
                      {
                        text: this.translations.ChangeProjectDLManager,
                        conditions: (
                          project: ICustomerPageProjectForTable
                        ): boolean =>
                          this.isDirection &&
                          !(project.idProjectStatus < RI_STATUS.IspWorking),
                        clickFunction: (
                          project: ICustomerPageProjectForTable
                        ) => {
                          this.router.navigate([
                            `/assign-project`,
                            project.idProject,
                            { type: 'changeDLManager' },
                          ]);
                        },
                      },
                      {
                        text: this.translations.ChangeProjectAgent,
                        conditions: (
                          project: ICustomerPageProjectForTable
                        ): boolean =>
                          this.isDirection &&
                          (project.idProjectStatus === RI_STATUS.RiWorking ||
                            project.idProjectStatus === RI_STATUS.NoContract),
                        clickFunction: (
                          project: ICustomerPageProjectForTable
                        ) => {
                          this.router.navigate([
                            `/assign-project`,
                            project.idProject,
                            { type: 'changeAgent' },
                          ]);
                        },
                      },
                      {
                        text: this.translations.PublishProject,
                        conditions: (
                          project: ICustomerPageProjectForTable
                        ): boolean =>
                          project?.manager?.idUser ===
                            this.authService?.userProfile?.idUser &&
                          !(project.idProjectStatus < RI_STATUS.IspWorking2),
                        clickFunction: (
                          project: ICustomerPageProjectForTable
                        ) => {
                          this.publishProjectDialog(project);
                        },
                      },
                    ],
                  },
                },
              ];
            }
          },
          complete: () => {
            this.loaderProjects = false;
          },
          error: () => {
            this.loaderProjects = false;
          },
        })
    );
  }

  getDocumentMenu(project: IProjectForTable): ITableButtons[] {
    return project.documents.map(
      (doc: DocumentDTO): ITableButtons => ({
        click: () => {
          this.visualizeDocument(doc.idDocument);
        },
        name: doc.name,
        fontAwesomeIcon: this.documentType[doc.mimeType] || faFile,
      })
    );
  }

  visualizeDocument(idDocument: number | string): void {
    this.subscriptions.add(
      this.irFormService.downloadDocument(idDocument).subscribe()
    );
  }

  haveNotes(project: IProjectForTable): boolean {
    let presentNotes: boolean;
    project?.projectNoteList?.forEach((note: Note) => {
      if (note.checked === false) {
        presentNotes = true;
      }
    });
    return presentNotes;
  }

  openNoteListDialog(project: IProjectForTable): void {
    const notesArray: Note[] = [];
    project.projectNoteList.forEach((note: Note) => {
      if (note.checked === false) {
        notesArray.push(note);
      }
    });
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      translations: this.translate.instant('IR_IN_PROGRESS'),
      message: this.translate.instant('IR_IN_PROGRESS.Notes'),
      notes: notesArray,
    };
    this.dialog.open(NotesDialogComponent, dialogConfig);
  }

  uploadDocument(file: File): void {
    this.loaderDocuments = true;
    this.subscriptions.add(
      this.irFormService
        .uploadCustomerDocument(
          this.loaderDocuments,
          this.customer.customer.idCustomer,
          file
        )
        .subscribe({
          complete: () => {
            this.getAllProjects();
            this.getAllDocuments();
          },
        })
    );
  }

  deleteCustomerDocument(idDocument: string | number): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations.DeleteDocumentQuestion,
      buttonTrue: this.translations.Delete,
      buttonFalse: this.translations.Cancel,
    };
    const dialogRef: MatDialogRef<ConfirmationDialogComponent> =
      this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    this.dialogSubscriptions = dialogRef
      .afterClosed()
      .subscribe((result: any) => {
        if (result) {
          (this.loaderDocuments = true),
            this.subscriptions.add(
              this.irFormService
                .deleteCustomerDocument(this.loaderDocuments, idDocument)
                .subscribe({
                  complete: () => {
                    this.getAllProjects();
                    this.getAllDocuments();
                  },
                })
            );
        }
      });
  }

  disableCustomer(): void {
    this.subscriptions.add(
      this.irFormService
        .enableDisableCustomer(this.customer?.customer?.idCustomer, false)
        .subscribe({
          complete: () => {
            this.getAllProjects();
          },
        })
    );
  }

  enableCustomer(): void {
    this.subscriptions.add(
      this.irFormService
        .enableDisableCustomer(this.customer?.customer?.idCustomer, true)
        .subscribe({
          complete: () => {
            this.getAllProjects();
          },
        })
    );
  }

  changeProductStrategy(project: ICustomerPageProjectForTable): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translations?.Attention,
      message: this.translations?.ChangeProductStrategyConfirmationMessage,
      // centerTextMessage: true,
      buttonTrue: this.translate.instant('Yes'),
      buttonFalse: this.translate.instant('No'),
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.irFormService
          .changeProductStrategy(project.idProject, project.idUser)
          .subscribe({
            complete: () => {
              this.getAllProjects();
            },
          });
      }
    });
  }

  checkViewPS1Conditions(project: IProjectForTable): boolean {
    if (
      (this.isAdmin ||
        this.isDirection ||
        this.authService?.userProfile?.idUser === project?.idSupervisor) &&
      project?.idProjectStatus >= RI_STATUS.IspWorking &&
      project?.idUser !== project?.idSupervisor
    ) {
      return true;
    }
    return false;
  }

  checkViewAssConditions(project: IProjectForTable): boolean {
    if (
      (this.isAdmin ||
        this.isDirection ||
        (this.isSupervisorAgent &&
          project.idUser !== project.previewOwnerId)) &&
      project.idProjectStatus < RI_STATUS.IspWorking
    ) {
      return true;
    }
    return false;
  }

  // TODO: Verificare se questa condizione possa essere ancora utile
  // checkViewCRMConditions(project: ICustomerPageProjectForTable): boolean {
  //   if (
  //     (this.isAdmin ||
  //       this.isDirection ||
  //       this.authService?.userProfile?.idUser === project?.idSupervisor) &&
  //     project?.idProjectStatus >= RI_STATUS.IspWorking2 &&
  //     project?.idUser !== project?.idSupervisor
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }

  handleLogoFileInput(files: FileList): void {
    this.sizeTypeFileError = false;
    if (files && files.item(0)) {
      const typeSplit = files.item(0).type.split('/');
      if (
        files.item(0).size <= FileLimitLogo.maxSize * 1024 * 1024 &&
        FileLimitLogo.fileTypesSplit.includes(typeSplit[1])
      ) {
        this.fileToUpload = files.item(0);
        this.subscriptions.add(
          this.irFormService
            .uploadCustomerLogo(
              this.customer.customer.idCustomer,
              this.fileToUpload
            )
            .subscribe({
              complete: () => {
                this.getAllProjects();
              },
            })
        );
      } else {
        this.sizeTypeFileError = true;
        this.translate
          .get('UPLOAD_FILE.UnableToUploadDocument', {
            size: this.maxSize,
            types: this.fileTypes,
          })
          .subscribe((error: string) => {
            this.common.showToast(error, ToastStatus.error, 5000);
          });
      }
    }
  }

  publishProjectDialog(project: ICustomerPageProjectForTable): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      projectData: project,
    };
    const dialogRef: MatDialogRef<PublishProjectDialogComponent> =
      this.dialog.open(PublishProjectDialogComponent, dialogConfig);
    // this.dialogSubscriptions = dialogRef
    //   .afterClosed()
    //   .subscribe((result: any) => {
    //     if (result) {

    //     }
    //   });
  }

  searchValue(value: string, table: string): void {
    switch (table) {
      case 'projects':
        this.searchValueProjects = value;
        break;
      case 'documents':
        this.searchValueDocuments = value;
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
