<form
  appSrollOnError
  [formGroup]="swotForm"
  class="ir-form"
  (ngSubmit)="onSubmit()"
>
  <div class="section mb-50">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.StrengthPoints" | translate }}</span>
        </div>
        <div class="description">
          <span>
            {{ "IR_FORM.StrengthsDescription" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="content" formArrayName="swItems">
      <ng-container
        *ngFor="let swItem of swItems.controls; let i = index"
        [formGroupName]="i"
      >
        <div
          *ngIf="
            swItem?.controls?.idSwotUspType?.value == 1 ||
            swItem?.controls?.idSwotUspType?.value == 5
          "
          class="section-card my-20"
        >
          <div class="content with-left-element">
            <div class="row">
              <mat-form-field
                class="col-12 col-sm-3 col-md-4"
                [ngClass]="
                  !swItem.get('title')?.value ? 'red-field-for-lab-manager' : ''
                "
              >
                <mat-label for="title">{{
                  "IR_FORM.Title" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="title"
                  type="text"
                  name="title"
                />
                <mat-error *ngIf="!swItem.get('title').valid">
                  {{
                    swItem.get("title").errors
                      | getErrorMessage: translations.Title
                  }}
                </mat-error>
                <button
                  type="button"
                  matSuffix
                  [matMenuTriggerFor]="helpChoiceTitle"
                  class="only-icon-button primary"
                >
                  <svg-icon name="assessment_icon" class="w16"></svg-icon>
                </button>
              </mat-form-field>
              <mat-menu #helpChoiceTitle="matMenu" class="helper-mat-menu">
                <div class="mat-menu-subtitle">
                  <span class="bold-text-color fs-12">{{
                    "IR_FORM.Products" | translate | uppercase
                  }}</span>
                </div>
                <div
                  *ngFor="
                    let helperMenuItem of swHelperMenuItems;
                    let ind = index
                  "
                >
                  <button
                    mat-menu-item
                    (click)="setTitleFromSWHelper(helperMenuItem, i)"
                    [disabled]="isReadOnly"
                  >
                    <span class="fs-12">{{ helperMenuItem }}</span>
                  </button>
                  <div *ngIf="ind === 4" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.distribution" | translate | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 6" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.SalesPricesAndConditions" | translate | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 9" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.CommunicationAndMarketing"
                        | translate
                        | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 12" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.Other" | translate | uppercase
                    }}</span>
                  </div>
                </div>
              </mat-menu>
              <div class="w-100 d-none d-sm-block d-md-none"></div>
              <mat-form-field
                class="col-12 col-sm-4 col-md-4"
                [ngClass]="
                  !swItem.get('description')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="description">{{
                  "IR_FORM.Description" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="description"
                  type="text"
                  name="description"
                />
                <mat-error *ngIf="!swItem.get('description').valid">
                  {{
                    swItem.get("description").errors
                      | getErrorMessage: translations.Description
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                class="col-12 col-sm-3 col-md-2"
                [ngClass]="
                  !swItem.get('levelOfInterest')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label>{{
                  "IR_FORM.ImportanceLevel" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="levelOfInterest"
                  name="level"
                >
                  <mat-option
                    *ngFor="let num of [].constructor(10); let i = index"
                    [value]="i + 1"
                  >
                    {{ i + 1 }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!swItem.get('levelOfInterest').valid">
                  {{
                    swItem.get("levelOfInterest").errors
                      | getErrorMessage: translations.ImportanceLevel
                  }}
                </mat-error>
              </mat-form-field>
              <div
                class="col-1 d-flex justify-content-center align-items-center"
              >
                <mat-checkbox
                  color="primary"
                  class="usp-checkbox"
                  [disabled]="isLabManagerInternal || isReadOnly"
                  (change)="uspModelChange($event, swItem)"
                  [checked]="checkUspValue(swItem)"
                  >{{ "IR_FORM.Usp" | translate }}</mat-checkbox
                >
              </div>
              <div class="remove-element-button col-12 col-sm-1 col-md-1">
                <button
                  class="only-icon-button primary"
                  [disabled]="isLabManagerInternal || isReadOnly"
                  type="button"
                  (click)="removeSwItem(i)"
                >
                  <svg-icon name="trash_icon"></svg-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <button
        *ngIf="!isReadOnly"
        type="button"
        class="rounded-button primary-bg-button ml-auto"
        (click)="addSwItem({ idSwotUspType: 1 })"
      >
        {{ "IR_FORM.AddContent" | translate }}
      </button>
    </div>
  </div>
  <div class="section mb-50">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.WeakPoints" | translate }}</span>
        </div>
        <div class="description">
          <span>
            {{ "IR_FORM.WeaknessesDescription" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="content" formArrayName="swItems">
      <ng-container
        *ngFor="let swItem of swItems.controls; let i = index"
        [formGroupName]="i"
      >
        <div
          *ngIf="swItem?.controls?.idSwotUspType?.value == 2"
          class="section-card my-20"
        >
          <div class="content with-left-element">
            <div class="row">
              <mat-form-field
                class="col-12 col-sm-3 col-md-4"
                [ngClass]="
                  !swItem.get('title')?.value ? 'red-field-for-lab-manager' : ''
                "
              >
                <mat-label for="title">{{
                  "IR_FORM.Title" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="title"
                  type="text"
                  name="title"
                />
                <mat-error *ngIf="!swItem.get('title').valid">
                  {{
                    swItem.get("title").errors
                      | getErrorMessage: translations.Title
                  }}
                </mat-error>
                <button
                  type="button"
                  matSuffix
                  [matMenuTriggerFor]="helpChoiceTitle"
                  class="only-icon-button primary"
                >
                  <svg-icon name="assessment_icon" class="w16"></svg-icon>
                </button>
              </mat-form-field>
              <mat-menu #helpChoiceTitle="matMenu" class="helper-mat-menu">
                <div class="mat-menu-subtitle">
                  <span class="bold-text-color fs-12">{{
                    "IR_FORM.Products" | translate | uppercase
                  }}</span>
                </div>
                <div
                  *ngFor="
                    let helperMenuItem of swHelperMenuItems;
                    let ind = index
                  "
                >
                  <button
                    mat-menu-item
                    (click)="setTitleFromSWHelper(helperMenuItem, i)"
                    [disabled]="isReadOnly"
                  >
                    <span class="fs-12">{{ helperMenuItem }}</span>
                  </button>
                  <div *ngIf="ind === 4" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.distribution" | translate | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 6" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.SalesPricesAndConditions" | translate | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 9" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.CommunicationAndMarketing"
                        | translate
                        | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 12" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.Other" | translate | uppercase
                    }}</span>
                  </div>
                </div>
              </mat-menu>
              <div class="w-100 d-none d-sm-block d-md-none"></div>
              <mat-form-field
                class="col-12 col-sm-4 col-md-4"
                [ngClass]="
                  !swItem.get('description')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="description">{{
                  "IR_FORM.Description" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="description"
                  type="text"
                  name="description"
                />
                <mat-error *ngIf="!swItem.get('description').valid">
                  {{
                    swItem.get("description").errors
                      | getErrorMessage: translations.Description
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                class="col-12 col-sm-3 col-md-2"
                [ngClass]="
                  !swItem.get('levelOfInterest')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label>{{
                  "IR_FORM.ImportanceLevel" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="levelOfInterest"
                  name="level"
                >
                  <mat-option
                    *ngFor="let num of [].constructor(10); let i = index"
                    [value]="i + 1"
                  >
                    {{ i + 1 }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!swItem.get('levelOfInterest').valid">
                  {{
                    swItem.get("levelOfInterest").errors
                      | getErrorMessage: translations.ImportanceLevel
                  }}
                </mat-error>
              </mat-form-field>
              <div
                class="col-1 d-flex justify-content-center align-items-center"
              ></div>
              <div class="remove-element-button col-12 col-sm-1 col-md-1">
                <button
                  class="only-icon-button primary"
                  [disabled]="isLabManagerInternal || isReadOnly"
                  type="button"
                  (click)="removeSwItem(i)"
                >
                  <svg-icon name="trash_icon"></svg-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <button
        *ngIf="!isReadOnly"
        type="button"
        class="rounded-button primary-bg-button ml-auto"
        (click)="addSwItem({ idSwotUspType: 2 })"
      >
        {{ "IR_FORM.AddContent" | translate }}
      </button>
    </div>
  </div>
  <div class="section mb-50">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.Opportunities" | translate }}</span>
        </div>
        <div class="description">
          <span>
            {{ "IR_FORM.OpportunitiesDescription" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="content" formArrayName="otItems">
      <ng-container
        *ngFor="let otItem of otItems.controls; let i = index"
        [formGroupName]="i"
      >
        <div
          *ngIf="otItem?.controls?.idSwotUspType?.value == 3"
          class="section-card my-20"
        >
          <div class="content with-left-element">
            <div class="row">
              <mat-form-field
                class="col-12 col-sm-3 col-md-4"
                [ngClass]="
                  !otItem.get('title')?.value ? 'red-field-for-lab-manager' : ''
                "
              >
                <mat-label for="title">{{
                  "IR_FORM.Title" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="title"
                  type="text"
                  name="title"
                />
                <mat-error *ngIf="!otItem.get('title').valid">
                  {{
                    otItem.get("title").errors
                      | getErrorMessage: translations.Title
                  }}
                </mat-error>
                <button
                  type="button"
                  matSuffix
                  [matMenuTriggerFor]="helpChoiceTitle"
                  class="only-icon-button primary"
                >
                  <svg-icon name="assessment_icon" class="w16"></svg-icon>
                </button>
              </mat-form-field>
              <mat-menu #helpChoiceTitle="matMenu" class="helper-mat-menu">
                <div class="mat-menu-subtitle">
                  <span class="bold-text-color fs-12">{{
                    "IR_FORM.Products" | translate | uppercase
                  }}</span>
                </div>
                <div
                  *ngFor="
                    let helperMenuItem of otHelperMenuItems;
                    let ind = index
                  "
                >
                  <button
                    mat-menu-item
                    (click)="setTitleFromOTHelper(helperMenuItem, i)"
                    [disabled]="isReadOnly"
                  >
                    <span class="fs-12">{{ helperMenuItem }}</span>
                  </button>
                  <div *ngIf="ind === 3" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.Consumers" | translate | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 6" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.Competitors" | translate | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 10" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.Other" | translate | uppercase
                    }}</span>
                  </div>
                </div>
              </mat-menu>
              <div class="w-100 d-none d-sm-block d-md-none"></div>
              <mat-form-field
                class="col-12 col-sm-4 col-md-4"
                [ngClass]="
                  !otItem.get('description')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="description">{{
                  "IR_FORM.Description" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="description"
                  type="text"
                  name="description"
                />
                <mat-error *ngIf="!otItem.get('description').valid">
                  {{
                    otItem.get("description").errors
                      | getErrorMessage: translations.Description
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                class="col-12 col-sm-3 col-md-2"
                [ngClass]="
                  !otItem.get('levelOfInterest')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label>{{
                  "IR_FORM.ImportanceLevel" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="levelOfInterest"
                  name="level"
                >
                  <mat-option
                    *ngFor="let num of [].constructor(10); let i = index"
                    [value]="i + 1"
                  >
                    {{ i + 1 }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!otItem.get('levelOfInterest').valid">
                  {{
                    otItem.get("levelOfInterest").errors
                      | getErrorMessage: translations.ImportanceLevel
                  }}
                </mat-error>
              </mat-form-field>
              <div
                class="col-1 d-flex justify-content-center align-items-center"
              ></div>
              <div class="remove-element-button col-12 col-sm-1 col-md-1">
                <button
                  class="only-icon-button primary"
                  [disabled]="isLabManagerInternal || isReadOnly"
                  type="button"
                  (click)="removeOtItem(i)"
                >
                  <svg-icon name="trash_icon"></svg-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <button
        *ngIf="!isReadOnly"
        type="button"
        class="rounded-button primary-bg-button ml-auto"
        (click)="addOtItem({ idSwotUspType: 3 })"
      >
        {{ "IR_FORM.AddContent" | translate }}
      </button>
    </div>
  </div>
  <div class="section mb-50">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.Threats" | translate }}</span>
        </div>
        <div class="description">
          <span>
            {{ "IR_FORM.ThreatsDescription" | translate }}
          </span>
        </div>
      </div>
    </div>
    <div class="content" formArrayName="otItems">
      <ng-container
        *ngFor="let otItem of otItems.controls; let i = index"
        [formGroupName]="i"
      >
        <div
          *ngIf="otItem?.controls?.idSwotUspType?.value == 4"
          class="section-card my-20"
        >
          <div class="content with-left-element">
            <div class="row">
              <mat-form-field
                class="col-12 col-sm-3 col-md-4"
                [ngClass]="
                  !otItem.get('title')?.value ? 'red-field-for-lab-manager' : ''
                "
              >
                <mat-label for="title">{{
                  "IR_FORM.Title" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="title"
                  type="text"
                  name="title"
                />
                <mat-error *ngIf="!otItem.get('title').valid">
                  {{
                    otItem.get("title").errors
                      | getErrorMessage: translations.Title
                  }}
                </mat-error>
                <button
                  type="button"
                  matSuffix
                  [matMenuTriggerFor]="helpChoiceTitle"
                  class="only-icon-button primary"
                >
                  <svg-icon name="assessment_icon" class="w16"></svg-icon>
                </button>
              </mat-form-field>
              <mat-menu #helpChoiceTitle="matMenu" class="helper-mat-menu">
                <div class="mat-menu-subtitle">
                  <span class="bold-text-color fs-12">{{
                    "IR_FORM.Products" | translate | uppercase
                  }}</span>
                </div>
                <div
                  *ngFor="
                    let helperMenuItem of otHelperMenuItems;
                    let ind = index
                  "
                >
                  <button
                    mat-menu-item
                    (click)="setTitleFromOTHelper(helperMenuItem, i)"
                    [disabled]="isReadOnly"
                  >
                    <span class="fs-12">{{ helperMenuItem }}</span>
                  </button>
                  <div *ngIf="ind === 3" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.Consumers" | translate | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 6" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.Competitors" | translate | uppercase
                    }}</span>
                  </div>
                  <div *ngIf="ind === 10" class="mat-menu-subtitle">
                    <span class="bold-text-color fs-12">{{
                      "IR_FORM.Other" | translate | uppercase
                    }}</span>
                  </div>
                </div>
              </mat-menu>
              <div class="w-100 d-none d-sm-block d-md-none"></div>
              <mat-form-field
                class="col-12 col-sm-4 col-md-4"
                [ngClass]="
                  !otItem.get('description')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="description">{{
                  "IR_FORM.Description" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="description"
                  type="text"
                  name="description"
                />
                <mat-error *ngIf="!otItem.get('description').valid">
                  {{
                    otItem.get("description").errors
                      | getErrorMessage: translations.Description
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                class="col-12 col-sm-3 col-md-2"
                [ngClass]="
                  !otItem.get('levelOfInterest')?.value
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label>{{
                  "IR_FORM.ImportanceLevel" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="levelOfInterest"
                  name="level"
                >
                  <mat-option
                    *ngFor="let num of [].constructor(10); let i = index"
                    [value]="i + 1"
                  >
                    {{ i + 1 }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!otItem.get('levelOfInterest').valid">
                  {{
                    otItem.get("levelOfInterest").errors
                      | getErrorMessage: translations.ImportanceLevel
                  }}
                </mat-error>
              </mat-form-field>
              <div
                class="col-1 d-flex justify-content-center align-items-center"
              ></div>
              <div class="remove-element-button col-12 col-sm-1 col-md-1">
                <button
                  class="only-icon-button primary"
                  [disabled]="isLabManagerInternal || isReadOnly"
                  type="button"
                  (click)="removeOtItem(i)"
                >
                  <svg-icon name="trash_icon"></svg-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
      <button
        *ngIf="!isReadOnly"
        type="button"
        class="rounded-button primary-bg-button ml-auto"
        (click)="addOtItem({ idSwotUspType: 4 })"
      >
        {{ "IR_FORM.AddContent" | translate }}
      </button>
    </div>
  </div>
  <div
    *ngIf="!isLabManagerInternal && !isReadOnly"
    class="center-buttons mb-50"
  >
    <button type="submit" class="rounded-button primary-bg-button">
      {{ "COMMON.SaveChanges" | translate }}
    </button>
  </div>
  <div class="section">
    <div class="content mt-30">
      <div class="row">
        <div class="col-6">
          <div class="section-card graph-section">
            <div class="header h42">
              <div class="title secondary-color">
                <span>{{ "IR_FORM.StrengthPoints" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <div *ngFor="let strength of swot.strengths" class="graph-item">
                <div>
                  <div class="title">
                    <span>{{ strength.title }}</span>
                  </div>
                  <div class="description">
                    <span>
                      {{ strength.description }} ({{
                        strength.levelOfInterest
                      }})
                    </span>
                  </div>
                </div>
                <div *ngIf="strength.idSwotUspType === 5" class="usp-item">
                  <svg-icon
                    name="check_icon"
                    class="secondary-color"
                  ></svg-icon>
                  <span>USP</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="section-card graph-section">
            <div class="header h42 problem-element">
              <div class="title problem-color">
                <span>{{ "IR_FORM.WeakPoints" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <div *ngFor="let weakness of swot.weaknesses" class="graph-item">
                <div>
                  <div class="title">
                    <span>{{ weakness.title }}</span>
                  </div>
                  <div class="description">
                    <span>
                      {{ weakness.description }} ({{
                        weakness.levelOfInterest
                      }})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swot-logo">
        <span class="letter secondary-color">S.</span>
        <span class="letter problem-color">W.</span>
        <span class="letter" style="color: #3294c7">O.</span>
        <span class="letter error-color">T.</span>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="section-card graph-section">
            <div class="header h42 blu-element">
              <div class="title" style="color: #3294c7">
                <span>{{ "IR_FORM.Opportunities" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <div
                *ngFor="let opportunity of swot.opportunities"
                class="graph-item"
              >
                <div>
                  <div class="title">
                    <span>{{ opportunity.title }}</span>
                  </div>
                  <div class="description">
                    <span>
                      {{ opportunity.description }} ({{
                        opportunity.levelOfInterest
                      }})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="section-card graph-section">
            <div class="header h42 error-element">
              <div class="title error-color">
                <span>{{ "IR_FORM.Threats" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <div *ngFor="let threat of swot.threats" class="graph-item">
                <div>
                  <div class="title">
                    <span>{{ threat.title }}</span>
                  </div>
                  <div class="description">
                    <span>
                      {{ threat.description }} ({{ threat.levelOfInterest }})
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-60">
      <mat-form-field
        class="col-10"
        [ngClass]="
          !customerDescriptionForm.get('ext1')?.value
            ? 'red-field-for-lab-manager'
            : ''
        "
      >
        <mat-label for="ext1">
          {{ "IR_FORM.CommentForSWOT" | translate }}
        </mat-label>
        <textarea
          rows="2"
          matInput
          type="text"
          name="ext1"
          [formControl]="customerDescriptionForm.get('ext1')"
        ></textarea>
        <mat-error *ngIf="customerDescriptionForm.get('ext1').invalid">
          {{
            customerDescriptionForm.get("ext1").errors
              | getErrorMessage: translations.CommentForPS
          }}
        </mat-error>
      </mat-form-field>
      <div class="col-2" *ngIf="!isReadOnly">
        <div class="d-flex justify-content-end">
          <button
            type="button"
            class="rounded-button primary-border-button"
            (click)="submitComment()"
          >
            {{ "IR_FORM.SaveComment" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
<div class="mt-40">
  <button
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
