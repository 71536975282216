import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    public router: Router,
  ) { }

  canActivate(): boolean {
    if (this.authService.isAuthenticated()) {
      this.authService.logoutWithoutLogin();
      return true;
    }
    return true;
  }
}
