import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonService } from './services/common/common.service';
import { IconRegistryService } from './services/icon-registry.service';
import { LayoutService } from './services/layout/layout.service';
import { LoadersObj, ToastParams } from './models/utility';
import { CrmService } from './services/crm/crm.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit, OnDestroy {
  loadSubscription: Subscription = new Subscription();
  toastSubscription: Subscription = new Subscription();

  title = 'pps';
  spinner: boolean = false;

  constructor(
    public layoutService: LayoutService,
    public translate: TranslateService,
    private cService: CommonService,
    private chageDet: ChangeDetectorRef,
    private iconRegistry: IconRegistryService
  ) {
    translate.setDefaultLang('it');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it/) ? browserLang : 'it');
  }

  ngOnInit(): void {
    // Load all the custom svg icons
    this.iconRegistry.createRegistry();
    // subscription to the loader BehavioursObj observable to manage the loaders
    this.loadSubscription = this.layoutService.loadersChanged$.subscribe({
      next: (loaders: LoadersObj): void => {
        if (Object.keys(loaders).length > 0) {
          const isLoading: boolean[] = Object.keys(loaders).map((key) => {
            return loaders[key];
          });
          if (isLoading.indexOf(true) >= 0) {
            this.spinner = true;
            this.chageDet.detectChanges();
          } else {
            this.spinner = false;
            this.chageDet.detectChanges();
            this.layoutService.triggerLoadersReset();
          }
        }
      },
    });

    // subscription to the toast BehavioursObj observable to show the when need it
    this.toastSubscription = this.layoutService.Triggertoast$.subscribe({
      next: (toastParam: ToastParams): void => {
        if (toastParam) {
          this.cService.showToast(
            this.translate.instant(toastParam.messageKey),
            toastParam.status,
            toastParam.duration
          );
        }
      },
    });
  }

  ngOnDestroy(): void {
    this.loadSubscription.unsubscribe();
    this.toastSubscription.unsubscribe();
  }
}
