import { Component, OnInit, Input, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ir-reject-dialog',
  templateUrl: './ir-reject-dialog.component.html',
  styleUrls: ['./ir-reject-dialog.component.css']
})
export class IrRejectDialogComponent implements OnInit {

  noteForm: FormGroup;
  riTabs: string[];
  translations: any;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.riTabs = data.tabs;
    this.translations = data.translations;
  }

  ngOnInit(): void {
    this.noteForm = this.fb.group({
      notes: this.fb.array([])
    });
  }

  get notes(): FormArray {
    return this.noteForm.get('notes') as FormArray;
  }

  addNote(): void {
    this.notes.push(this.fb.group({
      riTab: ['', Validators.required],
      description: ['', Validators.required]
    }));
  }

}
