<form [formGroup]="analysisForm" (ngSubmit)="saveAnalysis()">
  <div class="section-card left-element mb-30">
    <div class="content">
      <div class="row">
        <mat-form-field class="col-3" (click)="showWarningResetMessage()">
          <mat-label for="productCN8">
            {{ "IR_FORM.SelectProductToCommercialize" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            #productSelection
            formControlName="productCN8"
            (selectionChange)="selectProduct($event.value)"
          >
            <mat-option *ngFor="let product of products" [value]="product">
              {{ product.description }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <!-- <div class="col-3 d-flex align-items-center">
          <span class="text-color">{{ "IR_FORM.Name" | translate }}: </span>
          <span *ngIf="selectedProduct" class="bold-text-color ml-10">{{
            selectedProduct.name
          }}</span>
        </div>
        <div class="col-3 d-flex align-items-center">
          <span class="text-color"
            >{{ "IR_FORM.Description" | translate }}:
          </span>
          <span *ngIf="selectedProduct" class="bold-text-color ml-10">{{
            selectedProduct.description
          }}</span>
        </div> -->
        <div class="col-3 d-flex align-items-center">
          <span class="text-color"
            >{{ "IR_FORM.ProductCode" | translate }}:
          </span>
          <span *ngIf="selectedProduct" class="bold-text-color ml-10">{{
            selectedProduct.productCode
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="section-card left-element mb-10">
    <div class="content">
      <div class="row p-relative">
        <mat-form-field class="col-3">
          <mat-label for="evaluationTypology">{{
            "IR_FORM.EvaluationTypology" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="evaluationTypology"
            name="evaluationTypology"
          >
            <mat-option [value]="1">{{
              "IR_FORM.Interchange" | translate
            }}</mat-option>
            <!-- <mat-option [value]="2">{{ 'IR_FORM.ApparentConsumption' | translate }}</mat-option> -->
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="col-3"
          *ngIf="analysisForm.get('evaluationTypology').value === 1"
        >
          <mat-label for="interchangeTypology">{{
            "IR_FORM.InterchangeTypology" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="interchangeTypology"
            name="interchangeTypology"
            (selectionChange)="getInterchangeYears($event.value)"
          >
            <mat-option [value]="1">{{
              "IR_FORM.ItalyVSWorld" | translate
            }}</mat-option>
            <mat-option [value]="2">{{
              "IR_FORM.UEVSWorld" | translate
            }}</mat-option>
            <mat-option [value]="3">{{
              "IR_FORM.WorldVSWorld" | translate
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          class="col-3"
          *ngIf="analysisForm.get('interchangeTypology').value"
        >
          <mat-label for="yearEnd">{{
            "IR_FORM.LastYearOf3" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="yearEnd"
            name="yearEnd"
          >
            <mat-option
              *ngFor="let year of interchangeDataYears"
              [value]="year"
              >{{ year }}</mat-option
            >
          </mat-select>
        </mat-form-field>
        <button
          *ngIf="!isReadOnly"
          [disabled]="!analysisForm.get('interchangeTypology').value"
          type="button"
          class="rounded-button primary-bg-button show-interchange-data"
          (click)="showInterchangeData()"
        >
          {{ "IR_FORM.ShowInterchangeData" | translate }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="interchangeTypologyData">
    <div class="section-card pt-20">
      <div class="header h42 multi">
        <div class="title">
          <span>{{ "IR_FORM.SelectionMarketsTable" | translate }}</span>
        </div>
        <div class="d-flex justify-content-center align-items-center">
          <div class="legend">
            <div class="d-flex justify-content-center align-items-center">
              <div class="presence-markets-to-increase"></div>
              <div class="title">
                {{ "IR_FORM.PresenceMarketsToIncrease" | translate }}
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <div class="actual-high-potential-markets"></div>
              <div class="title">
                {{ "IR_FORM.ActualHighPotentialMarkets" | translate }}
              </div>
            </div>
            <div class="d-flex justify-content-center align-items-center">
              <div class="future-high-potential-markets"></div>
              <div class="title">
                {{ "IR_FORM.FutureHighPotentialMarkets" | translate }}
              </div>
            </div>
          </div>
          <button
            type="submit"
            class="rounded-button primary-bg-button ml-50"
            [disabled]="isReadOnly || !selectedMarkets?.length"
          >
            {{ "COMMON.CreateObjectMarketMatrix" | translate }}
          </button>
        </div>
      </div>
      <div class="content">
        <div class="scrollable-table">
          <table class="test-table interchange-data-table">
            <thead>
              <tr>
                <th (click)="sortColumn('check')">
                  <div>
                    <mat-icon>check</mat-icon>
                    <mat-icon
                      *ngIf="
                        columnToSort === 'check' && sortArrowDirection !== null
                      "
                    >
                      {{ sortArrowDirection }}
                    </mat-icon>
                  </div>
                </th>
                <th (click)="sortColumn('countryName')">
                  <div>
                    <div>
                      {{ "IR_FORM.Markets" | translate }}
                    </div>
                    <mat-icon
                      *ngIf="
                        columnToSort === 'countryName' &&
                        sortArrowDirection !== null
                      "
                    >
                      {{ sortArrowDirection }}
                    </mat-icon>
                  </div>
                </th>
                <th (click)="sortColumn('presenceMarkets')">
                  <div>
                    <div>
                      {{ "IR_FORM.PresenceMarkets" | translate }}
                    </div>
                    <mat-icon
                      *ngIf="
                        columnToSort === 'presenceMarkets' &&
                        sortArrowDirection !== null
                      "
                    >
                      {{ sortArrowDirection }}
                    </mat-icon>
                  </div>
                </th>
                <th (click)="sortColumn('potentialMarkets')">
                  <div>
                    <div>
                      {{ "IR_FORM.PotentialMarkets" | translate }}
                    </div>
                    <mat-icon
                      *ngIf="
                        columnToSort === 'potentialMarkets' &&
                        sortArrowDirection !== null
                      "
                    >
                      {{ sortArrowDirection }}
                    </mat-icon>
                  </div>
                </th>
                <th (click)="sortColumn('competition')">
                  <div>
                    <div>
                      {{ "IR_FORM.Competition" | translate }}
                    </div>
                    <mat-icon
                      *ngIf="
                        columnToSort === 'competition' &&
                        sortArrowDirection !== null
                      "
                    >
                      {{ sortArrowDirection }}
                    </mat-icon>
                    <div>
                      <button
                        type="button"
                        [matMenuTriggerFor]="benchmarkLegend"
                        class="only-icon-button primary"
                        (click)="$event.stopPropagation()"
                        style="margin-left: 5px"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                      <mat-menu #benchmarkLegend="matMenu">
                        <div
                          *ngFor="
                            let benchmarkLegend of translations.BenchmarkLegend
                          "
                          style="padding: 0 10px"
                        >
                          {{ benchmarkLegend | translate }}
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </th>
                <th (click)="sortColumn('valueSaldo')">
                  <div>
                    <div>
                      {{ "IR_FORM.Balance" | translate }}
                    </div>
                    <mat-icon
                      *ngIf="
                        columnToSort === 'valueSaldo' &&
                        sortArrowDirection !== null
                      "
                    >
                      {{ sortArrowDirection }}
                    </mat-icon>
                    <div>
                      <button
                        type="button"
                        [matMenuTriggerFor]="balanceLegend"
                        class="only-icon-button primary"
                        (click)="$event.stopPropagation()"
                        style="margin-left: 5px"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                      <mat-menu #balanceLegend="matMenu">
                        <div
                          *ngFor="
                            let balanceLegend of translations.BalanceLegendDescription
                          "
                          style="padding: 0 10px"
                        >
                          {{ balanceLegend | translate }}
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </th>
                <th (click)="sortColumn('valueSaldoProCapite')">
                  <div>
                    <div>
                      {{ "IR_FORM.PerCapita" | translate }}
                    </div>
                    <mat-icon
                      *ngIf="
                        columnToSort === 'valueSaldoProCapite' &&
                        sortArrowDirection !== null
                      "
                    >
                      {{ sortArrowDirection }}
                    </mat-icon>
                    <div>
                      <button
                        type="button"
                        [matMenuTriggerFor]="percapitaLegend"
                        class="only-icon-button primary"
                        (click)="$event.stopPropagation()"
                        style="margin-left: 5px"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                      <mat-menu #percapitaLegend="matMenu">
                        <div
                          *ngFor="
                            let percapitaLegend of translations.PercapitaLegendDescription
                          "
                          style="padding: 0 10px"
                        >
                          {{ percapitaLegend | translate }}
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </th>
                <th (click)="sortColumn('valueTrend')">
                  <div>
                    <div>
                      {{ "IR_FORM.Trend" | translate }}
                    </div>
                    <mat-icon
                      *ngIf="
                        columnToSort === 'valueTrend' &&
                        sortArrowDirection !== null
                      "
                    >
                      {{ sortArrowDirection }}
                    </mat-icon>
                    <div>
                      <button
                        type="button"
                        [matMenuTriggerFor]="trendLegend"
                        class="only-icon-button primary"
                        (click)="$event.stopPropagation()"
                        style="margin-left: 5px"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                      <mat-menu #trendLegend="matMenu">
                        <div
                          *ngFor="
                            let trendLegend of translations.TrendLegendDescription
                          "
                          style="padding: 0 10px"
                        >
                          {{ trendLegend | translate }}
                        </div>
                      </mat-menu>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngFor="let market of interchangeTypologyData"
                [ngClass]="{
                  'blue-row': market?.idMarketType === 1 && market?.toIncrease,
                  'red-row': market?.idMarketType === 3,
                  'green-row': market?.idMarketType === 4
                }"
              >
                <td>
                  <mat-checkbox
                    color="primary"
                    [disabled]="
                      (selectedMarkets?.length >= 10 &&
                        !checkCorrespondance(market)) ||
                      isReadOnly
                    "
                    [checked]="checkMarkets(market)"
                    (change)="selectMarket($event.checked, market)"
                    [matTooltip]="
                      selectedMarkets?.length >= 10 &&
                      !isReadOnly &&
                      !this.checkCorrespondance(market)
                        ? ('IR_FORM.TheLimitOfSelectableMarketsIs10'
                          | translate)
                        : null
                    "
                  ></mat-checkbox>
                </td>
                <td>{{ market.countryName }}</td>
                <td>
                  <div
                    *ngIf="market?.idMarketType === 1 && !market?.toIncrease"
                  >
                    {{ "IR_FORM.OnlyOfPresence" | translate }}
                  </div>
                  <div *ngIf="market?.idMarketType === 1 && market?.toIncrease">
                    {{ "IR_FORM.OfPresenceToIncrease" | translate }}
                  </div>
                </td>
                <td>
                  <div *ngIf="market?.idMarketType === 3">
                    {{ "IR_FORM.OfActualHighPotential" | translate }}
                  </div>
                  <div *ngIf="market?.idMarketType === 4">
                    {{ "IR_FORM.OfFutureHighPotential" | translate }}
                  </div>
                </td>
                <td class="d-flex">
                  {{ market?.benchmark }}
                </td>
                <td>
                  <div class="d-inline-block">
                    {{
                      market?.valueSaldo
                        | currency
                          : this._defaultCurrencyCode
                          : "symbol"
                          : "1.0-2"
                          : undefined
                    }}
                  </div>
                  <div class="d-inline-block ml-10 fw-700">
                    {{ market?.positionSaldo }}
                  </div>
                </td>
                <td>
                  <div class="d-inline-block">
                    {{
                      market?.valueSaldoProCapite
                        | currency
                          : this._defaultCurrencyCode
                          : "symbol"
                          : "1.0-2"
                          : undefined
                    }}
                  </div>
                  <div class="d-inline-block ml-10 fw-700">
                    {{ market?.positionSaldoProCapite }}
                  </div>
                </td>
                <td>
                  <div class="d-inline-block">
                    {{
                      market?.valueTrend
                        | currency
                          : this._defaultCurrencyCode
                          : "symbol"
                          : "1.0-2"
                          : undefined
                    }}
                  </div>
                  <div class="d-inline-block ml-10 fw-700">
                    {{ market?.positionTrend }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</form>
<ng-container *ngIf="matrixMarketAnalysis?.indexMarketObjective?.length">
  <form [formGroup]="customIndexesForm" (ngSubmit)="saveMatrix()">
    <div #matrixTable class="section-card pt-20 mt-30 mb-30">
      <div class="header">
        <div class="title">
          <span>
            {{ "IR_FORM.MatrixForTheChoiceOfTargetMarkets" | translate }}
          </span>
        </div>
      </div>
      <div class="content matrix-table-content">
        <table class="matrix-table">
          <thead>
            <tr>
              <th colspan="2" scope="colgroup"></th>
              <th class="weight-col" scope="col">Peso</th>
              <th
                *ngFor="
                  let country of matrixMarketAnalysis.indexMarketObjective[0]
                    .countryMarketObjective
                "
                scope="col"
              >
                {{ country.countryName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="multirow-th" rowspan="3" scope="rowgroup">
                {{ "IR_FORM.Interchange" | translate }}
              </th>
              <th class="second-th" scope="row">Saldo</th>
              <td class="weight-col">
                <mat-form-field class="td-field">
                  <input
                    class="td-input"
                    matInput
                    type="number"
                    formControlName="balanceWeight"
                    name="balanceWeight"
                    (change)="highlightsRecalculate()"
                    (keyup)="highlightsRecalculate()"
                  />
                  <mat-error
                    *ngIf="customIndexesForm.get('balanceWeight').invalid"
                  >
                    {{ "IR_FORM.Error" | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
              <td
                [ngClass]="country.value >= 9 ? 'green' : ''"
                *ngFor="
                  let country of matrixMarketAnalysis.indexMarketObjective[0]
                    .countryMarketObjective
                "
              >
                {{ country?.value }}
              </td>
            </tr>
            <tr>
              <th class="second-th" scope="row">
                {{ "IR_FORM.PerCapitaBalance" | translate }}
              </th>
              <td class="weight-col">
                <mat-form-field class="td-field">
                  <input
                    class="td-input"
                    matInput
                    type="number"
                    formControlName="perCapitaWeight"
                    name="perCapitaWeight"
                    (change)="highlightsRecalculate()"
                    (keyup)="highlightsRecalculate()"
                  />
                  <mat-error
                    *ngIf="customIndexesForm.get('perCapitaWeight').invalid"
                  >
                    {{ "IR_FORM.Error" | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
              <td
                [ngClass]="country.value >= 9 ? 'green' : ''"
                *ngFor="
                  let country of matrixMarketAnalysis.indexMarketObjective[1]
                    .countryMarketObjective
                "
              >
                {{ country?.value }}
              </td>
            </tr>
            <tr>
              <th class="second-th" scope="row">
                {{ "IR_FORM.Trend" | translate }}
              </th>
              <td class="weight-col">
                <mat-form-field class="td-field">
                  <input
                    class="td-input"
                    matInput
                    type="number"
                    formControlName="trendWeight"
                    name="trendWeight"
                    (change)="highlightsRecalculate()"
                    (keyup)="highlightsRecalculate()"
                  />
                  <mat-error
                    *ngIf="customIndexesForm.get('trendWeight').invalid"
                  >
                    {{ "IR_FORM.Error" | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
              <td
                [ngClass]="country.value >= 9 ? 'green' : ''"
                *ngFor="
                  let country of matrixMarketAnalysis.indexMarketObjective[2]
                    .countryMarketObjective
                "
              >
                {{ country?.value }}
              </td>
            </tr>
            <tr>
              <th class="multirow-th" rowspan="2" scope="rowgroup">
                {{ "IR_FORM.CountryIndexes" | translate }}
              </th>
              <th class="second-th" scope="row">
                {{ "IR_FORM.PPP" | translate }}
              </th>
              <td class="weight-col">
                <mat-form-field class="td-field">
                  <input
                    class="td-input"
                    matInput
                    type="number"
                    formControlName="macroWeight"
                    name="macroWeight"
                    (change)="highlightsRecalculate()"
                    (keyup)="highlightsRecalculate()"
                  />
                  <mat-error
                    *ngIf="customIndexesForm.get('macroWeight').invalid"
                  >
                    {{ "IR_FORM.Error" | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
              <td
                [ngClass]="country.value >= 9 ? 'green' : ''"
                *ngFor="
                  let country of matrixMarketAnalysis.indexMarketObjective[3]
                    .countryMarketObjective
                "
              >
                {{ country?.value }}
              </td>
            </tr>
            <tr>
              <th class="second-th" scope="row">
                {{ "IR_FORM.CountryRisk" | translate }}
              </th>
              <td class="weight-col">
                <mat-form-field class="td-field">
                  <input
                    class="td-input"
                    matInput
                    type="number"
                    formControlName="countryRiskWeight"
                    name="countryRiskWeight"
                    (change)="highlightsRecalculate()"
                    (keyup)="highlightsRecalculate()"
                  />
                  <mat-error
                    *ngIf="customIndexesForm.get('countryRiskWeight').invalid"
                  >
                    {{ "IR_FORM.Error" | translate }}
                  </mat-error>
                </mat-form-field>
              </td>
              <td
                [ngClass]="country.value >= 9 ? 'green' : ''"
                *ngFor="
                  let country of matrixMarketAnalysis.indexMarketObjective[4]
                    .countryMarketObjective
                "
              >
                {{ country?.value }}
              </td>
            </tr>
            <ng-container formArrayName="customIndexes">
              <tr>
                <th
                  class="multirow-th"
                  [attr.rowspan]="customIndexes?.length + 1"
                  scope="rowgroup"
                >
                  {{ "IR_FORM.CustomIndexes" | translate }}
                </th>
                <th class="second-th no-padding" scope="row">
                  <button
                    [disabled]="isReadOnly"
                    type="button"
                    class="rounded-button primary-border-button add-custom-index"
                    (click)="addCustomIndex()"
                  >
                    {{ "IR_FORM.AddCustomIndex" | translate }}
                  </button>
                </th>
                <td></td>
                <ng-container>
                  <td
                    *ngFor="
                      let country of matrixMarketAnalysis
                        .indexMarketObjective[0].countryMarketObjective
                    "
                  ></td>
                </ng-container>
              </tr>
              <tr
                *ngFor="
                  let customIndex of customIndexes.controls;
                  let i = index
                "
              >
                <ng-container [formGroupName]="i">
                  <th class="second-th no-padding" scope="row">
                    <mat-form-field
                      [ngClass]="isReadOnly ? 'ml-readOnly' : ''"
                      class="custom-index-form-field"
                    >
                      <mat-label for="name">{{
                        "IR_FORM.Name" | translate
                      }}</mat-label>
                      <input
                        [readonly]="isReadOnly"
                        formControlName="name"
                        name="name"
                        matInput
                        type="text"
                        (change)="highlightsRecalculate()"
                        (keyup)="highlightsRecalculate()"
                      />
                      <button
                        type="button"
                        matPrefix
                        [matMenuTriggerFor]="customIndexListMenu"
                        class="only-icon-button primary mr-05"
                      >
                        <svg-icon name="assessment_icon" class="w16"></svg-icon>
                      </button>
                      <button
                        matSuffix
                        [disabled]="isReadOnly"
                        type="button"
                        class="only-icon-button error"
                        (click)="removeCustomIndex(customIndex, i)"
                      >
                        <svg-icon name="close_icon" class="w14"></svg-icon>
                      </button>
                      <mat-error
                        class="small-size-error"
                        *ngIf="customIndex.get('name').invalid"
                      >
                        {{
                          customIndex.get("name").errors
                            | getErrorMessage: translations.Name
                        }}
                      </mat-error>
                    </mat-form-field>
                    <mat-menu
                      #customIndexListMenu="matMenu"
                      class="custom-indexes-mat-menu"
                    >
                      <div class="mat-menu-subtitle">
                        <span class="bold-text-color fs-12">{{
                          "IR_FORM.CustomIndexes" | translate | uppercase
                        }}</span>
                      </div>
                      <div *ngFor="let customIndex of customIndexList">
                        <button
                          mat-menu-item
                          (click)="
                            setCustomIndexText($event.target.textContent, i)
                          "
                        >
                          <span class="fs-12">{{
                            "CUSTOM_INDEX_LIST." + customIndex.description
                              | translate
                          }}</span>
                        </button>
                      </div>
                    </mat-menu>
                  </th>
                  <td class="weight-col">
                    <mat-form-field class="td-field">
                      <input
                        [readonly]="isReadOnly"
                        class="td-input"
                        formControlName="weight"
                        name="weight"
                        matInput
                        type="number"
                        (focusout)="cropDecimalValue(customIndex.get('weight'))"
                        (change)="highlightsRecalculate()"
                        (keyup)="highlightsRecalculate()"
                      />
                      <mat-error *ngIf="customIndex.get('weight').invalid">
                        {{ "IR_FORM.Error" | translate }}
                      </mat-error>
                    </mat-form-field>
                  </td>
                  <ng-container formArrayName="countryMarketObjective">
                    <td
                      *ngFor="
                        let data of countryMarketObjective(i).controls;
                        let j = index
                      "
                    >
                      <ng-container [formGroupName]="j">
                        <mat-form-field class="td-field">
                          <input
                            [readonly]="isReadOnly"
                            class="td-input"
                            formControlName="value"
                            matInput
                            min="0"
                            max="10"
                            step="0.1"
                            type="number"
                            name="value"
                            (focusout)="cropDecimalValue(data.get('value'))"
                            (change)="highlightsRecalculate()"
                            (keyup)="highlightsRecalculate()"
                          />
                          <mat-error *ngIf="data.get('value').invalid">
                            {{ "IR_FORM.Error" | translate }}
                          </mat-error>
                        </mat-form-field>
                      </ng-container>
                    </td>
                  </ng-container>
                </ng-container>
              </tr>
            </ng-container>
            <tr>
              <th class="multirow-th" rowspan="3" scope="rowgroup">
                <button
                  [disabled]="
                    isReadOnly ||
                    customIndexes.invalid ||
                    customIndexesForm.invalid
                  "
                  class="rounded-button primary-border-button"
                  type="button"
                  (click)="recalculateTotalsValues()"
                >
                  {{ "IR_FORM.Recalculate" | translate }}
                </button>
              </th>
              <th class="total-points second-th" scope="row">
                {{ "IR_FORM.TotalPoints" | translate }}
              </th>
              <ng-container *ngFor="let value of totalPoints; let ind = index">
                <td
                  *ngIf="ind < 1"
                  class="weight-col"
                  [ngClass]="value < 100 || value > 100 ? 'less-then-100' : ''"
                >
                  {{ value }}
                </td>
              </ng-container>
              <ng-container *ngFor="let value of totalPoints; let inde = index">
                <td *ngIf="inde > 0">{{ value.toFixed(2) }}</td>
              </ng-container>
            </tr>
            <tr>
              <th class="weighted-average second-th" scope="row">
                {{ "IR_FORM.WeightedAverage" | translate }}
              </th>
              <th scope="col"></th>
              <td
                *ngFor="let value of totalWeightedAverage; let indind = index"
              >
                {{ value.toFixed(2) }}
              </td>
            </tr>
            <tr class="objective-markets-row">
              <th class="weighted-average second-th" scope="row">
                {{ "IR_FORM.TargetMarkets" | translate }}
              </th>
              <th scope="col"></th>
              <td *ngFor="let value of totalWeightedAverage; let i = index">
                <div class="custom-progress-bar-container">
                  <div
                    [matTooltip]="'IR_FORM.ShowMarketInfo' | translate"
                    class="custom-progress-bar"
                    [ngClass]="
                      selectedCountry.toArray()[i]?.checked
                        ? 'bg-green'
                        : 'bg-blue'
                    "
                    [style.height.%]="value * 10"
                    (click)="showMarketInfoDialog(i)"
                  ></div>
                </div>
              </td>
            </tr>
            <tr>
              <th colspan="3" scope="colgroup"></th>
              <th
                *ngFor="
                  let country of matrixMarketAnalysis.indexMarketObjective[0]
                    .countryMarketObjective;
                  let i = index
                "
                scope="col"
                #visualizedCountry
              >
                {{ country.countryName }}
                <mat-checkbox
                  [disabled]="isReadOnly"
                  color="primary"
                  class="select-country"
                  [checked]="checkCountries(country)"
                  (change)="selectCountry($event.checked, country, i)"
                  #selectedCountry
                ></mat-checkbox>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="!isReadOnly">
      <div class="center-buttons">
        <button
          [disabled]="
            isReadOnly ||
            highlightsRecalculateChecker ||
            totalPoints[0] > 100 ||
            totalPoints[0] < 100
          "
          class="rounded-button primary-bg-button"
          type="submit"
        >
          {{ "IR_FORM.ConfirmSelectedMarkets" | translate }}
        </button>
      </div>
      <div
        *ngIf="highlightsRecalculateChecker && customIndexes.valid"
        class="ta-center mt-20"
      >
        <span style="color: red">{{
          "IR_FORM.PressRecalculateToContinue" | translate
        }}</span>
      </div>
      <div
        *ngIf="totalPoints[0] > 100 || totalPoints[0] < 100"
        class="ta-center mt-20"
      >
        <span style="color: red">{{
          "IR_FORM.TotalWeightsIsNotEqualTo100" | translate
        }}</span>
      </div>
    </div>
  </form>
</ng-container>
<div class="mt-40">
  <button
    *ngIf="interchangeTypologyData?.length"
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
<ng-template #marketInfoDialog let-close="close" let-data>
  <div class="header">
    <div class="title">
      <span>{{ data.selectedMarket.countryName }}</span>
    </div>
    <button
      class="close-dialog"
      mat-dialog-close
      mat-icon-button
      [disabled]="disableButtons"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <div>
      <div class="d-inline-block">{{ "IR_FORM.MarketType2" | translate }}:</div>
      <div
        *ngIf="data?.selectedMarket?.idMarketType === 1"
        class="d-inline-block ml-10"
      >
        {{
          data?.selectedMarket?.toIncrease
            ? ("IR_FORM.OfPresenceToIncrease" | translate)
            : ("IR_FORM.OfPresence" | translate)
        }}
      </div>
      <div
        *ngIf="data?.selectedMarket?.idMarketType === 3"
        class="d-inline-block ml-10"
      >
        {{ "IR_FORM.OfActualHighPotential" | translate }}
      </div>
      <div
        *ngIf="data?.selectedMarket?.idMarketType === 4"
        class="d-inline-block ml-10"
      >
        {{ "IR_FORM.OfFutureHighPotential" | translate }}
      </div>
      <div
        *ngIf="data?.selectedMarket?.idMarketType === null"
        class="d-inline-block ml-10"
      >
        --
      </div>
    </div>
    <div>
      <div class="d-inline-block">{{ "IR_FORM.Competition" | translate }}:</div>
      <div class="d-inline-block ml-10">
        {{ data?.selectedMarket?.benchmark || "--" }}
      </div>
    </div>
    <div>
      <div class="d-inline-block">{{ "IR_FORM.Balance" | translate }}:</div>
      <div class="d-inline-block ml-10">
        {{
          data?.selectedMarket?.valueSaldo
            | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
        }}
      </div>
    </div>
    <div>
      <div class="d-inline-block">{{ "IR_FORM.PerCapita" | translate }}:</div>
      <div class="d-inline-block ml-10">
        {{
          data?.selectedMarket?.valueSaldoProCapite
            | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
        }}
      </div>
    </div>
    <div>
      <div class="d-inline-block">{{ "IR_FORM.Trend" | translate }}:</div>
      <div class="d-inline-block ml-10">
        {{
          data?.selectedMarket?.valueTrend
            | currency: this._defaultCurrencyCode:"symbol":"1.0-2":undefined
        }}
      </div>
    </div>
  </div>
</ng-template>
