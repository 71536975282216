<div class="section-card">
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "CRM.MandatoryActivities" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!mandatoryActivities?.length"
        (emitSearchValue)="searchValue($event, 'mandatory activities')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content">
    <app-table
      [loader]="mandatoryActivitiesLoader"
      [columns]="mandatoryActivitiesTableColumns"
      [columnsButtons]="mandatoryActivitiesTableButtons"
      [data]="mandatoryActivities"
      [searchValue]="searchValueMandatoryActivities"
    >
    </app-table>
  </div>
</div>
<div class="section-card mt-40">
  <div class="header h70 multi">
    <div class="title">
      <span>{{ "CRM.Customers" | translate }}</span>
    </div>
    <div class="search-bar">
      <app-search-bar
        [disabled]="!followedPMIs?.length"
        (emitSearchValue)="searchValue($event, 'followed pmi')"
      ></app-search-bar>
    </div>
  </div>
  <div class="content managed-customers-table">
    <app-table
      [loader]="followedPMIsLoader"
      [columns]="followedPMIsTableColumns"
      [columnsButtons]="followedPMIsTableButtons"
      [data]="followedPMIs"
      [searchValue]="searchValueFollowedPmi"
    >
    </app-table>
  </div>
</div>

<ng-template #customerDialog let-close="close" let-data>
  <div class="header">
    <div class="title">
      <span class="fw-700">{{ data.customer.businessName }}: </span>
      <span></span>
    </div>
    <button
      class="close-dialog"
      mat-dialog-close
      mat-icon-button
      [disabled]="disableButtons"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="projects-container">
      <div *ngFor="let project of data.projects" class="project">
        <div>
          <span>{{ "CRM.Code" | translate }}:</span>
          <span>{{ project.code }}</span>
        </div>
        <div>
          <span>{{ "CRM.Product" | translate }}:</span>
          <span>{{ project.productName }}</span>
        </div>
        <div>
          <span>{{ "CRM.Version" | translate }}:</span>
          <span>{{ project.version }}</span>
        </div>
        <div>
          <span>{{ "CRM.Typology" | translate }}:</span>
          <span>{{ project.reason }}</span>
        </div>
        <div>
          <button
            type="button"
            class="rounded-button primary-border-button mt-20"
            [mat-dialog-close]="project"
          >
            {{ "CRM.SelectProject" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- <div class="wrap-1200">
  <div class="ta-center">
    <span class="bold-text-color">{{ 'CRM.FollowedPMI' | translate }}</span>
  </div>
  <div *ngIf="followedPMIsLoader" class="mt-80">
    <mat-spinner class="m-0-auto" diameter="50"></mat-spinner>
  </div>
  <div *ngIf="!followedPMIs?.length && !followedPMIsLoader">
    <div class="mt-30 mb-30 ta-center">
      <mat-icon class="fs-100">hide_source</mat-icon>
    </div>
    <div class="mb-30 ta-center">
      <span class="text-color">{{ 'CRM.EmptyProject' | translate }}</span>
    </div>
  </div>
  <app-pmi-carousel *ngIf="!followedPMIsLoader && followedPMIs.length" [followedPMIs]="followedPMIs"></app-pmi-carousel>
  <div *ngIf="showMandatoryActivitiesTable" class="mt-40 pb-60">
    <div class="ta-center">
      <span class="bold-text-color">
        {{ 'CRM.MandatoryActivities' | translate }}
      </span>
    </div>
    <app-table
      [dataSource]="mandatoryActivitiesDataSource"
      [columnsName]="mandatoryActivitiesColumnsName"
      [displayedColumns]="mandatoryActivitiesDisplayedColumns"
      [tableOptions]="mandatoryActivitiesTableOptions"
      [tableButtons]="mandatoryActivitiesTableButtons"
      [loader]="mandatoryActivitiesLoader"
    ></app-table>
  </div>
</div> -->
