import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import html2pdf from 'html2pdf.js';
import { contract, SplitMarketsArray, SplitProductArray } from 'src/app/utility/elaborations';
import { Project, ProjectDTO } from 'src/app/models/project';
import { IrDocument } from 'src/app/models/irdocument';
import { Html2PdfOpt, PageBrakMode } from 'src/app/models/utility';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ICustomerContact } from 'src/app/models/customer';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { APP_CONTEX, NOTE_TYPE, RI_STATUS } from 'src/app/config';
import { IProductsServices } from 'src/app/models/products-services';
import { IMarkets } from 'src/app/models/markets';
import { Note } from 'src/app/models/note';


@Component({
  selector: 'app-ir-to-pdf',
  templateUrl: './ir-to-pdf.component.html',
  styleUrls: ['./ir-to-pdf.component.scss']
})
export class IrToPdfComponent implements OnInit, OnDestroy {

  @ViewChild('saveArea') saveArea: ElementRef;

  subscriptions: Subscription = new Subscription();

  firstContact: ICustomerContact;

  idProjectStatus: number;

  today: Date = new Date();
  dd: number = this.today.getDate();
  mm: number = this.today.getMonth() + 1;
  yyyy: number = this.today.getFullYear();
  date: string = this.dd + '/' + this.mm + '/' + this.yyyy;

  project: IrDocument = {};

  readyTranslations: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private irFormService: IrFormService,
    private layoutService: LayoutService,
    private translations: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.translations.get('IR_DOCUMENT_PRE_TRANSLATIONS').subscribe((readyTranslations) => {
      this.readyTranslations = readyTranslations;
      this.subscriptions.add(this.route.params.subscribe(params => {
        this.project = { idProject: params.idProject };
      }));
      this.subscriptions.add(this.irFormService.getProjectById(this.project.idProject).subscribe({
        next: (project: ProjectDTO) => {
          this.project = project;
          this.idProjectStatus = project.idProjectStatus;

        },
        complete: () => {
          this.subscriptions.add(this.irFormService.getProjectEntities(this.project).subscribe((projectEntities: Project) => {
            this.firstContact = projectEntities.customer?.customerContact[0] || {};
            // Split productsServices in two array, one for sold product and one for bought product
            const [ soldProduct, boughtProduct ]: [IProductsServices[], IProductsServices[]] =
              SplitProductArray(projectEntities.productsServices);
            // Split markets array
            const [
              presenceMarkets,
              desiredMarkets,
              hightPotentialMarkets,
              unexploredMarkets ]: [IMarkets[], IMarkets[], IMarkets[], IMarkets[]] =
              SplitMarketsArray(projectEntities.markets);
            // Split notes array in three string
            let customerNote: string;
            let referentNote: string;
            let productNote: string;
            projectEntities.notes.forEach((note: Note) => {
              if (note.idAgentNoteType === NOTE_TYPE.CustomNote) { customerNote = note.description; }
              if (note.idAgentNoteType === NOTE_TYPE.ReferentNote) { referentNote = note.description; }
              if (note.idAgentNoteType === NOTE_TYPE.ProductNote) { productNote = note.description; }
            });
            const elaboratedProject: IrDocument = {
              contract: contract(projectEntities.contract),
              customer: {
                ...projectEntities.customer,
                customerContact: projectEntities.customer?.customerContact?.slice(1) || []
              },
              customerInformation: projectEntities.customerInformation,
              countryImport: projectEntities.customerInformation?.countriesOfImport?.split(',') || [],
              soldProduct,
              boughtProduct,
              presenceMarkets,
              desiredMarkets,
              channels: projectEntities.channels,
              customerNote,
              referentNote,
              productNote
            };
            this.project = { ...this.project, ...elaboratedProject};
          }));
        }
      }));
    }));
  }

  /**
   * @description Create the pdf from element reference and send to server before save on client filesystem
   */
  createPdf(): void {
    // html to pdf options
    const opt: Html2PdfOpt = {
      filename: `ASS_1°${this.project.idProject}.pdf`,
      image: {
        type: 'jpeg',
        quality: 0.30
      },
      html2canvas: {
        removeContainer: true,
        scrollX: 0,
        scrollY: 0,
        scale: 2
      },
      jsPDF: {
        compress: true,
        unit: 'cm',
        format: 'a4',
        orientation: 'portrait',
        putOnlyUsedFonts: true
      },
      // margin: [ 1, 0 ],
      pagebreak: {
        mode: PageBrakMode.Css,
        after: '.break-page',
        before: ['.page', '.new-page-item'],
        avoid: [
          '.unbreackeable'
        ]
      }
    };
    this.layoutService.triggerLoadersChangedObservable('CREATE_PDF', true);
    const worker = html2pdf().set(opt).from(this.saveArea.nativeElement);
    worker.outputPdf('blob').then((data: Blob) => {
      this.layoutService.triggerLoadersChangedObservable('CREATE_PDF', false);
      if (this.idProjectStatus >= RI_STATUS.IspWorking) {
        const document: File = new File([data], `PS_${this.project.idProject}.pdf`);
        this.subscriptions.add(this.irFormService.uploadIspDocument(document, this.project.idProject).subscribe({
          complete: () => {
            worker.save();
            this.router.navigate([`${APP_CONTEX}/isp-in-progress`]);
          }
        }));
      } else {
        const document: File = new File([data], `ASS_${this.project.idProject}.pdf`);
        this.subscriptions.add(this.irFormService.uploadIrDocument(document, this.project.idProject).subscribe({
          complete: () => {
            worker.save();
            this.router.navigate([`${APP_CONTEX}/ir-in-progress`]);
          }
        }));
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
