import { MatSnackBarConfig } from '@angular/material/snack-bar';
import { IconDefinition } from '@fortawesome/fontawesome-common-types/index';
import { IChannelsType, IChannelSpecification } from './channels-type';
import {
  IBuyerStatus,
  IBuyerStatusReason,
  IContactOrigins,
  IDealState,
  IDealStateReason,
} from './contact';
import { ICountries } from './countries';
import { IReferentRole } from './referent-role';
import { ISectors } from './sectors';
/**
 *  @description Back end response
 */
export interface BackEndResponse {
  /** error code */
  errors?: BackEndErrorsResponse;
  /** error message */
  message?: string;
  /** status error */
  status?: number;
  /** path of servise */
  path?: string;
  /** time of error */
  timestamp?: Date | string;
  /**  details erro */
  details?: string;
  /** payload(deprecated) */
  payload?: any;
}
/**
 *  @description Back end error response
 */
export interface BackEndErrorsResponse {
  /** error message */
  message?: string;
  /** path of servise */
  path?: string;
  /** time of error */
  timestamp?: Date | string;
  /**  details erro */
  details?: string;
}
/**
 * @description The possible statuses of a snack-bar notification a Class must be declared styles.css/scss
 */
export enum ToastStatus {
  /** used for success message ClassName='success-message' */
  success = 'success-message',
  /** used for error message ClassName='error-message' */
  error = 'error-message',
  /** used for default message ClassName='info-message' */
  info = 'info-message',
  /** used for warning messa ClassName='warning-message' */
  warning = 'warning-message',
}
/**
 * @description Snack bar message parameter extend MatSnackBarConfig interface
 */
export interface ToastParams extends MatSnackBarConfig {
  /** the i18n key for message in toast */
  messageKey?: string;
  /** the status of toast */
  status?: ToastStatus;
  /** the action showed in the toast */
  action?: string;
  /** the function to trigger when click on the action */
  afterActionFn?: () => {};
}
/**
 * @description The Nav Bar property
 */
export interface NavBarProps {
  path: string;
  icon?: string;
  label?: string;
  badge?: string;
}
/**
 * @description Interface of breadcrumb element
 */
export interface IBreadcrumb {
  label?: string;
  path?: string;
  pageTitle?: boolean;
}
/**
 * @description Loaders object
 */
export interface LoadersObj {
  [param: string]: boolean;
}
/**
 * @description the select type interface
 */
export interface Select {
  label: string;
  value: string | number;
  description?: string;
}

export interface IColumnsName {
  value?: string;
  name?: string;
}

export interface ITableOptions {
  showFilter?: boolean;
  showSort?: boolean;
  showPaginator?: boolean;
  showButtons?: boolean;
  menuButtonIcon?: string;
  iconClass?: string;
}

export interface IDisplayedColumns {
  value: string;
  name: string;
}

export interface ITableButtons {
  name?: string;
  disabled?: boolean | ((x: any) => boolean);
  tooltip?: string;
  icon?: string;
  iconClass?: string;
  disabledClass?: string;
  fontAwesomeIcon?: IconDefinition | ((element: any) => any);
  click?: (element: any, index?: number) => void;
  conditions?: (element: any) => boolean;
  menuElementEleborator?: (element: any) => ITableButtons[];
  subMenu?: ITableButtons[];
}

/**
 * @description Body created when i file is downloaded
 */
export interface FileBody {
  file: Blob;
  fileName: string;
}
/**
 * @description Props of hidden archor Url download
 */
export interface HiddenArchorProps {
  url: string;
  filename: string;
}
/**
 * @description The option of html2pdf tool
 */
export interface Html2PdfOpt {
  margin?: number | number[];
  filename?: string;
  image?: Html2PdfImageOpt;
  pagebreak?: PageBreakOpt;
  html2canvas?: Html2CanvasOpt;
  jsPDF?: PdfJsOpt;
}

export interface Html2PdfImageOpt {
  type?: 'jpg' | 'png' | 'jpeg' | 'webp';
  quality?: number;
}

export interface Html2CanvasOpt {
  removeContainer?: boolean;
  scrollX?: number;
  scrollY?: number;
  scale: number;
}

export interface PdfJsOpt {
  compress?: boolean;
  unit?: 'pt' | 'mm' | 'cm' | 'm' | 'in' | 'px';
  format?: string;
  orientation?: 'p' | 'portrait' | 'l' | 'landscape';
  putOnlyUsedFonts?: boolean;
}

export interface PageBreakOpt {
  mode?: PageBrakMode | PageBrakMode[];
  before?: string | string[];
  after?: string | string[];
  avoid?: string | string[];
}

export enum PageBrakMode {
  AvoidAll = 'avoid-all',
  Legacy = 'legacy',
  Css = 'css',
}

export interface UnitsOfMeasure {
  idUom?: number;
  name?: string;
  description?: string;
  idCustomer?: number;
}

export interface IFileToHandle {
  idDealDocument?: number;
  name?: string;
}

export interface ITableColumn {
  name?: string;
  translatedName?: string;
  width?: number;
  hide?: boolean;
  class?: string;
  align?: 'center' | 'right';
  position?: string;
  sortType?: string;
  translation?: string;
  addClass?: ((item: any) => string) | string;
  type?: 'currency' | 'number' | 'date' | 'innerHTML' | 'shortDate';
  symbol?: string;
  disableSort?: true | false;
  legend?: string | string[];
  chartColor?: boolean;
}

export interface ITableButton {
  icon?: ((item: any) => string) | string;
  class?: ((item: any) => string) | string;
  disabled?: boolean | ((item: any) => boolean);
  tooltip?: string | ((item: any) => string);
  conditions?: (item: any) => boolean;
  clickFunction?: (item: any) => any;
  subMenu?: {
    buttons?: ITableSubButton[];
    key?: string;
    clickFunction?: (item: any) => any;
  };
  subMenuButtons?: any;
  subMenuElaboratorOptions?: any;
}

export interface ITableSubButton {
  text?: string | ((item: any) => string);
  icon?: string;
  clickFunction?: (item: any) => any;
  conditions?: (item: any) => boolean;
  subMenu?: {
    buttons?: ITableSubSubButton[];
  };
}

export interface ITableSubSubButton {
  text?: string | ((item: any) => string);
  icon?: string;
  clickFunction?: (item: any) => any;
  conditions?: (item: any) => boolean;
}

export interface IDataForCrmSelects {
  commerceSectors?: ISectors[];
  dealStates?: IDealState[];
  dealStatesReasons?: IDealStateReason[];
  unitsOfMeasure?: UnitsOfMeasure[];
  countries?: ICountries[];
  channelTypes?: IChannelsType[];
  specificationsChannels?: IChannelSpecification[];
  buyerStatus?: IBuyerStatus[];
  buyerStatusReasons?: IBuyerStatusReason[];
  referentRoles?: IReferentRole[];
  contactOrigins?: IContactOrigins;
}

export interface ITop3ManagersData {
  topManagers?: ITopManager[];
  totalManagers?: number;
  totalRevenue?: number;
}

export interface ITopManager {
  email?: string;
  idManager?: number;
  name?: string;
  surname?: string;
  totalRevenue?: number;
}

export interface INominatimCoordinates {
  lon?: string;
  lat?: string;
}

export interface IBMOChecker {
  global?: true;
  bm?: boolean;
  lostOffers?: boolean;
  reports?: boolean;
}
