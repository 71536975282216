import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { animate } from '@angular/animations';
import { style } from '@angular/animations';
import { state } from '@angular/animations';
import { trigger } from '@angular/animations';
import { transition } from '@angular/animations';
import { Sort } from '@angular/material/sort';
import { ITableColumn } from 'src/app/models/utility';
import { newChartColors } from 'src/app/config';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class TableComponent implements OnInit, OnChanges {
  value: string;
  oldData: any[];
  oldOldData: any[];
  activeSort: Sort;
  chartColors: string[] = newChartColors;
  pageIndexForChartColor: number = 0;

  @Input() loader: boolean;
  @Input() columns: ITableColumn[];
  @Input() data: any[];
  @Input() columnsButtons: any;
  @Input() columnsButtonsWithHeading: any;
  @Input() columnCheckbox: any;
  @Input() paginator: boolean = true;
  @Input() specialSorting: boolean = false;
  @Input() rowBadgeNewElements: boolean = false;
  @Input() resetPaginator: boolean = false;
  @Input()
  set searchValue(value: string) {
    this.value = value;
  }
  get searchValue(): string {
    return this.value;
  }

  @Output() pageIsChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('paginator') paginatorElement: MatPaginator;

  constructor() {}

  ngOnInit(): void {
    if (this.specialSorting) {
      this.data = this.data.map((item: any): any => ({
        ...item,
        sortByPresenceMarket:
          item?.idMarketType === 1 ? (item?.toIncrease ? 2 : 1) : 0,
        sortByPotential:
          item?.idMarketType > 2 ? (item?.idMarketType === 3 ? 2 : 1) : 0,
        sortByBenchmark: item?.benchmark
          ? this.workItemBenchmark(item?.benchmark)
          : 0,
      }));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.searchValue?.currentValue) {
      const result: any[] = [];
      this.oldOldData.forEach((item) => {
        this.columns.forEach((col) => {
          const value = '' + item[col.name];
          if (
            value
              ?.toLowerCase()
              .includes(changes?.searchValue?.currentValue.toLowerCase()) &&
            col.name !== 'buttons'
          ) {
            if (!result.includes(item)) {
              result.push(item);
            }
          }
        });
      });
      this.oldData = result;
      this.data = result;
      this.pageChanged({
        pageIndex: this.paginatorElement?.pageIndex || 0,
        pageSize: this.paginatorElement?.pageSize || 5,
        length: this.oldData?.length,
      });
    } else {
      this.oldData = this.oldOldData;
      this.data = this.oldOldData;
      this.pageChanged({
        pageIndex: this.paginatorElement?.pageIndex || 0,
        pageSize: this.paginatorElement?.pageSize || 5,
        length: this.oldData?.length,
      });
    }
    if (changes?.data?.currentValue) {
      this.oldOldData = changes?.data?.currentValue;
      this.oldData = changes.data.currentValue;
      this.data = this.oldData;
      this.pageChanged({
        pageIndex: this.paginatorElement?.pageIndex || 0,
        pageSize: this.paginatorElement?.pageSize || 5,
        length: this.oldData?.length,
      });
    }
    if (changes?.resetPaginator?.currentValue === true) {
      this.paginatorElement.pageIndex = 0;
      this.paginatorElement.pageSize = 5;
    }
  }

  pageChanged(event: PageEvent): void {
    if (this.oldData?.length) {
      const data = [...this.oldData];
      if (this.paginatorElement) {
        while (!data[event.pageIndex * event.pageSize]) {
          this.paginatorElement.previousPage();
          event.pageIndex--;
        }
      }
      let sortedData: any[];
      if (this.activeSort?.active) {
        sortedData = data.sort((a: any, b: any) => {
          const isAsc = this.activeSort.direction === 'asc';
          return (
            (a[this.activeSort.active] < b[this.activeSort.active] ? -1 : 1) *
            (isAsc ? 1 : -1)
          );
        });
      } else {
        sortedData = data;
      }
      this.data = sortedData.splice(
        event.pageIndex * event.pageSize,
        event.pageSize
      );
      this.pageIndexForChartColor = event.pageIndex;
    }
  }

  sortData(sort: Sort): any {
    this.activeSort = sort;
    const data = this.oldData.slice();
    if (!sort.active || sort.direction === '') {
      this.activeSort = null;
      this.data = data.splice(
        this.paginatorElement?.pageIndex * this.paginatorElement?.pageSize,
        this.paginatorElement?.pageSize
      );
      return;
    }
    const sortedData: any[] = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      return (a[sort.active] < b[sort.active] ? -1 : 1) * (isAsc ? 1 : -1);
    });
    this.data = sortedData.splice(
      this.paginatorElement?.pageIndex * this.paginatorElement?.pageSize,
      this.paginatorElement?.pageSize
    );
  }

  workItemBenchmark(item: any): number {
    let result: number = 0;
    let fav: number;
    let sfav: number;
    let splittedString: string[];
    if (item?.includes(',')) {
      splittedString = item?.split(',');
      splittedString?.forEach((bench: string) => {
        if (bench.includes('F') && !bench.includes('S')) {
          if (bench.includes('()')) {
            fav = parseInt(
              bench.substring(bench.indexOf('(') + 1, bench.lastIndexOf(')')),
              10
            );
          } else {
            fav = 1;
          }
        } else if (bench.includes('SF')) {
          if (bench.includes('()')) {
            sfav = parseInt(
              bench.substring(bench.indexOf('(') + 1, bench.lastIndexOf(')')),
              10
            );
          } else {
            sfav = 1;
          }
        } else {
          result = 0;
        }
      });
    } else {
      if (item?.includes('F') && !item?.includes('S')) {
        if (item?.includes('()')) {
          fav = parseInt(
            item.substring(item.indexOf('(') + 1, item.lastIndexOf(')')),
            10
          );
        } else {
          fav = 1;
        }
      } else if (item?.includes('SF')) {
        if (item?.includes('()')) {
          sfav = parseInt(
            item.substring(item.indexOf('(') + 1, item.lastIndexOf(')')),
            10
          );
        } else {
          sfav = 1;
        }
      } else {
        result = 0;
      }
    }
    if (fav) {
      result = fav + 1000;
    }
    if (!fav && sfav) {
      result = sfav + 10;
    }
    return result;
  }

  checkIfIcon(row: any, column: any): boolean {
    if (
      typeof row[column.name] === 'string' ||
      row[column.name] instanceof String
    ) {
      if (row[column.name]?.includes('icon')) {
        return true;
      }
    }
  }
}
