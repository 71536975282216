<div class="row">
  <div class="col-9">
    <div class="section-card h100">
      <div class="content">
        <app-table
          [loader]="dealsTableLoader"
          [columns]="dealsTableColumns"
          [columnsButtons]="dealsTableButtons"
          [data]="deals"
        ></app-table>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="section-card h100">
      <div *ngIf="!isReadonly" class="add-content">
        <button
          type="button"
          class="rounded-button primary-bg-button"
          (click)="openDealDialog()"
        >
          <span class="white-color">
            {{ "CRM_DETAIL.AddOfferOrder" | translate }}
          </span>
        </button>
      </div>
      <div class="header h70 multi">
        <div class="title">
          <span>{{ "COMMON.Filters" | translate }}</span>
        </div>
        <div class="text-button">
          <span class="primary-color" (click)="resetDealsTableFilters()">
            {{ "COMMON.Reset" | translate | lowercase }}
          </span>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <mat-form-field appearance="standard" class="col-12">
            <mat-label>{{ "CRM_DETAIL.CommerceSector" | translate }}</mat-label>
            <mat-select
              #dealCommerceSectorFilter
              (selectionChange)="filterDealsTable('commerceSector', $event)"
            >
              <mat-option
                *ngFor="let sector of commerceSectors"
                [value]="sector.name"
              >
                {{ sector.name }}
                <!-- {{ "COMMERCE_SECTORS." + sector.name | translate }} -->
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col-12">
            <mat-label>{{ "CRM_DETAIL.Status2" | translate }}</mat-label>
            <mat-select
              #dealStatusFilter
              (selectionChange)="filterDealsTable('dealStatus', $event)"
            >
              <mat-option *ngFor="let status of dealStatus" [value]="status">
                {{ "DEAL_STATE_NAME." + status | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field appearance="standard" class="col-12">
            <mat-label>{{ "CRM_DETAIL.Date" | translate }}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input
                matStartDate
                placeholder="{{ 'COMMON.StartDate' | translate }}"
                [formControl]="startDateDealFilter"
                readonly
              />
              <input
                matEndDate
                placeholder="{{ 'COMMON.EndDate' | translate }}"
                readonly
                [formControl]="endDateDealFilter"
                (dateChange)="handleRangeDateDealTableFilter($event)"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
