import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-input-dialog',
  templateUrl: './confirmation-input-dialog.component.html',
  styleUrls: ['./confirmation-input-dialog.component.css']
})
export class ConfirmationInputDialogComponent implements OnInit {

  form: FormGroup;
  message: string;
  translations: any;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ConfirmationInputDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.message = data.message;
    this.translations = data.translations;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      reason: ['', [Validators.required]]
    });
  }

  confirm(): void {
    this.dialogRef.close(this.form.get('reason').value);
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
