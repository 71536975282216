import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
  Injector,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import {
  CommonModule,
  DatePipe,
  getLocaleCurrencyCode,
  LOCATION_INITIALIZED,
  registerLocaleData,
} from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import localeIt from '@angular/common/locales/it';
import localeFr from '@angular/common/locales/fr';
// Routing
import { AppRoutingModule } from 'src/app/app-routing.module';
// Pages Component
import { AppComponent } from 'src/app/app.component';
import { MeetingsComponent } from 'src/app/pages/meetings/meetings.component';
import { MeetingsTableComponent } from 'src/app/pages/meetings/meetings-table.component';
import { LoginComponent } from 'src/app/pages/login/login.component';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { IrFormComponent } from 'src/app/pages/ir-form/ir-form.component';
import { AccountSettingsComponent } from 'src/app/pages/account-settings/account-settings.component';
import { IrInProgressComponent } from 'src/app/pages/ir-in-progress/ir-in-progress.component';
import { IrInProgressTableComponent } from 'src/app/pages/ir-in-progress/ir-in-progress-table.component';
import { UsersManagementComponent } from 'src/app/pages/users-management/users-management.component';
import { IrSentComponent } from 'src/app/pages/ir-sent/ir-sent.component';
import { LayoutComponent } from 'src/app/pages/layout/layout.component';
import { SignupComponent } from 'src/app/pages/signup/signup.component';
import { ResetPasswordComponent } from 'src/app/pages/reset-password/reset-password.component';
import { IrToPdfComponent } from 'src/app/pages/ir-document/ir-to-pdf.component';
import { CustomersComponent } from 'src/app/pages/customers/customers.component';
import { CustomerComponent } from 'src/app/pages/customers/customer/customer.component';
import { IrToApproveComponent } from 'src/app/pages/ir-to-approve/ir-to-approve.component';
import { IrToApproveTableComponent } from 'src/app/pages/ir-to-approve/ir-to-approve-table.component';
import { IrApprovedComponent } from 'src/app/pages/ir-approved/ir-approved.component';
import { IrApprovedTableComponent } from 'src/app/pages/ir-approved/ir-approved-table.component';
import { IspAssignedComponent } from 'src/app/pages/isp-assigned/isp-assigned.component';
import { IspAssignedTableComponent } from 'src/app/pages/isp-assigned/isp-assigned-table.component';
import { IrRejectedComponent } from 'src/app/pages/ir-rejected/ir-rejected.component';
import { IrRejectedTableComponent } from 'src/app/pages/ir-rejected/ir-rejected-table.component';
import { IrToAssignComponent } from 'src/app/pages/ir-to-assign/ir-to-assign.component';
import { ProjectDetailComponent } from 'src/app/pages/project-detail/project-detail.component';
import { IrSentTableComponent } from 'src/app/pages/ir-sent/ir-sent-table.component';
import { IrToAssignTableComponent } from 'src/app/pages/ir-to-assign/ir-to-assign-table.component';
import { AllProjectsComponent } from 'src/app/pages/all-projects/all-projects.component';
import { CustomersTableComponent } from 'src/app/pages/customers/customers-table.component';
import { SearchProductComponent } from 'src/app/pages/search-product/search-product.component';
import { SearchProductTableComponent } from 'src/app/pages/search-product/search-product-table.component';
import { ChannelsRegistryComponent } from 'src/app/pages/channels-registry/channels-registry.component';
import { CrmComponent } from './pages/crm/crm.component';
import { CrmDetailComponent } from './pages/crm/crm-detail/crm-detail.component';
import { PsFormComponent } from 'src/app/pages/ps-form/ps-form.component';
import { PsStrategyComponent } from 'src/app/pages/ps-form/ps-strategy/ps-strategy.component';
import { PsSwotComponent } from 'src/app/pages/ps-form/ps-swot/ps-swot.component';
import { PsCompetitorComponent } from 'src/app/pages/ps-form/ps-competitor/ps-competitor.component';
import { AssignProjectComponent } from 'src/app/pages/assign-project/assign-project.component';
// Component
import { HeaderComponent } from 'src/app/components/header/header.component';
import { TableComponent } from 'src/app/components/table/table.component';
import { ConfirmationDialogComponent } from 'src/app/components/confirmation-dialog/confirmation-dialog.component';
import { AccountFormComponent } from 'src/app/components/account-form/account-form.component';
import { CustomerFormComponent } from 'src/app/components/customer-form/customer-form.component';
import { ContractFormComponent } from 'src/app/components/contract-form/contract-form.component';
import { UserManagmentFormComponent } from 'src/app/components/user-managment-form/user-managment-form.component';
import { UploadFileComponent } from 'src/app/components/upload-file/upload-file.component';
import { CustomerInformationFormComponent } from 'src/app/components/customer-information-form/customer-information-form.component';
import { ProductsServicesFormComponent } from 'src/app/components/products-services-form/products-services-form.component';
import { HiddenArchorComponent } from 'src/app/components/hidden-archor/hidden-archor.component';
import { MarketFormComponent } from 'src/app/components/market-form/market-form.component';
import { ChannelsFormComponent } from 'src/app/components/channels-form/channels-form.component';
import { ReferenceCompetitorsComponent } from 'src/app/components/reference-competitors/reference-competitors.component';
import { NoteFormComponent } from 'src/app/components/note-form/note-form.component';
import { SwotFormComponent } from 'src/app/components/swot-form/swot-form.component';
import { ConfirmationInputDialogComponent } from 'src/app/components/confirmation-input-dialog/confirmation-input-dialog.component';
import { NotesDialogComponent } from 'src/app/components/notes-dialog/notes-dialog.component';
import { NoteButtonComponent } from 'src/app/components/note-button/note-button.component';
import { SkeletonLoaderComponent } from 'src/app/components/skeleton-loader/skeleton-loader.component';
import { ChartComponent } from 'src/app/components/chart/chart.component';
import { LeadGenerationFormComponent } from './components/lead-generation-form/lead-generation-form.component';
import { ProductMatchingDialogComponent } from 'src/app/components/product-matching-dialog/product-matching-dialog.component';
import { ContactReportsDialogComponent } from 'src/app/components/contact-reports-dialog/contact-reports-dialog.component';
import { ContactCompetitorsDialogComponent } from 'src/app/components/contact-competitors-dialog/contact-competitors-dialog.component';
import { ContactDealDialogComponent } from './components/contact-deal-dialog/contact-deal-dialog.component';
import { CloneProjectComponent } from './components/clone-project/clone-project.component';
// Service
import { HttpRefreshInterceptorProvider } from 'src/app/interceptors/refresh-token.interceptor/refresh-token.interceptor';
import { HttpRequestInterceptorProvider } from 'src/app/interceptors/http.interceptor/http.interceptor';
import { ErrorHandlerProvider } from 'src/app/services/guard/error-handler.service';
// Third-part
import { MatRippleModule } from '@angular/material/core';
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatStepperModule } from '@angular/material/stepper';
import {
  MAT_RADIO_DEFAULT_OPTIONS,
  MatRadioModule,
} from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faAppleAlt,
  faBuilding,
  faFilePdf,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
// import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxCurrencyModule } from 'ngx-currency';
import { allowedDomains, headerName } from 'src/app/config';
import {
  authScheme,
  disallowedRoutes,
  tokenGetter,
} from 'src/app/utility/token';
import { LangTranslationLoader } from 'src/app/utility/lenguage.loader';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ChartsModule } from 'ng2-charts';
import { IvyCarouselModule } from 'angular-responsive-carousel';
// Pipes
import { SuperVisorFilterPipe } from 'src/app/pipes/filters.pipe';
import { GetErrorMessagePipe } from 'src/app/pipes/get-error-message.pipe';
import { TableMenuSkimPipe } from 'src/app/pipes/table-menu-skim.pipe';
import { ChunkPipe } from 'src/app/components/custom-calendar/chunk.pipe';
// Directives
import { SrollOnErrorDirective } from 'src/app/directives/sroll-on-error.directive';
import { CustomerDescrDirective } from './directives/customer-descr.directive';
import { IrRejectDialogComponent } from './components/ir-reject-dialog/ir-reject-dialog.component';
import { RadarChartComponent } from './components/radar-chart/radar-chart.component';
import { InsertNotesPanelComponent } from './components/insert-notes-panel/insert-notes-panel.component';
import { MarketsStrategyAnalysisComponent } from './components/markets-strategy-analysis/markets-strategy-analysis.component';
import { SearchCustomsCodeComponent } from './components/search-customs-code/search-customs-code.component';
import { HelpDialogComponent } from './components/help-dialog/help-dialog.component';
import { MatBadgeIconDirective } from './directives/mat-badge-icon.directive';
import { CrmContactDetailComponent } from './components/crm-contact-detail/crm-contact-detail.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { NguCarouselModule } from '@ngu/carousel';
import { ContactDealsComponent } from './components/contact-deals/contact-deals.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';
import { CrmBusinessMatchingComponent } from './pages/crm/crm-business-matching/crm-business-matching.component';
import { PmiCarouselComponent } from './shared/pmi-carousel/pmi-carousel.component';
import { CustomerInfoPanelComponent } from './components/customer-info-panel/customer-info-panel.component';
import { TableRowDetailsComponent } from './components/table/table-row-details/table-row-details.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FlatpickrModule } from 'angularx-flatpickr';
import { PublishProjectDialogComponent } from './components/publish-project-dialog/publish-project-dialog.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CustomCalendarComponent } from './components/custom-calendar/custom-calendar.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { FilterTablePipe } from './pipes/filter-table.pipe';
import { SvgIconComponent } from './components/svg-icon/svg-icon.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { StrategyFormComponent } from './components/strategy-form/strategy-form.component';
import { ScrollToTopDirective } from './directives/scroll-to-top.directive';
import { LayoutService } from './services/layout/layout.service';
import { PsCurrentStrategyComponent } from './pages/ps-form/ps-current-strategy/ps-current-strategy.component';
import { ChannelsManagementDialogComponent } from './components/channels-management-dialog/channels-management-dialog.component';
import { FilterNotDynamicTablePipe } from './pipes/filter-not-dynamic-table.pipe';
import { BusinessOpportunitiesComponent } from './pages/business-opportunities/business-opportunities.component';
import { PositionDialogComponent } from './components/position-dialog/position-dialog.component';
import { NoticesBoardComponent } from './pages/notices-board/notices-board.component';
import { FilterSelectPipe } from './pipes/filter-select.pipe';
import { FilterSelectByConditionPipe } from './pipes/filter-select-by-condition.pipe';
import {ScrollingModule} from "@angular/cdk/scrolling";
import { ConfigurationService } from './services/configuration/configuration.service';

// I18n - Translations
export function HttpLoaderFactory(
  httpClient: HttpClient
): LangTranslationLoader {
  return new LangTranslationLoader(httpClient);
}

// Configuration Initializer
const appInitializerFn = (appConfig: ConfigurationService) => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

registerLocaleData(localeIt);
registerLocaleData(localeFr);

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

// export function appInitializerFactory(translate: TranslateService, injector: Injector) {
//   return () => new Promise<any>((resolve: any) => {
//     const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
//     locationInitialized.then(() => {
//       const langToSet = 'en-GB';
//       translate.setDefaultLang('en-US');
//       translate.use(langToSet).subscribe(() => {
//         console.log(`Successfully initialized '${langToSet}' language.'`);
//       }, err => {
//         console.error(`Problem with '${langToSet}' language initialization.'`);
//       }, () => {
//         resolve(null);
//       });
//     });
//   });
// }

@NgModule({
  declarations: [
    AppComponent,
    IrFormComponent,
    HeaderComponent,
    MeetingsComponent,
    MeetingsTableComponent,
    LoginComponent,
    HomeComponent,
    AccountSettingsComponent,
    IrInProgressComponent,
    IrInProgressTableComponent,
    IrSentComponent,
    TableComponent,
    LayoutComponent,
    ConfirmationDialogComponent,
    UsersManagementComponent,
    SignupComponent,
    AccountFormComponent,
    SuperVisorFilterPipe,
    ResetPasswordComponent,
    GetErrorMessagePipe,
    ContractFormComponent,
    CustomerFormComponent,
    UserManagmentFormComponent,
    UploadFileComponent,
    CustomerInformationFormComponent,
    ProductsServicesFormComponent,
    HiddenArchorComponent,
    MarketFormComponent,
    ChannelsFormComponent,
    ReferenceCompetitorsComponent,
    NoteFormComponent,
    SwotFormComponent,
    IrToPdfComponent,
    CustomersComponent,
    CustomerComponent,
    IrToApproveComponent,
    IrToApproveTableComponent,
    IrApprovedComponent,
    IrApprovedTableComponent,
    IrRejectedComponent,
    IrRejectedTableComponent,
    IspAssignedComponent,
    IspAssignedTableComponent,
    ConfirmationInputDialogComponent,
    NotesDialogComponent,
    IrToAssignComponent,
    ProjectDetailComponent,
    NoteButtonComponent,
    SrollOnErrorDirective,
    SkeletonLoaderComponent,
    TableMenuSkimPipe,
    IrSentTableComponent,
    IrToAssignTableComponent,
    AllProjectsComponent,
    CustomerDescrDirective,
    MatBadgeIconDirective,
    CustomersTableComponent,
    IrRejectDialogComponent,
    ChartComponent,
    LeadGenerationFormComponent,
    RadarChartComponent,
    InsertNotesPanelComponent,
    ProductMatchingDialogComponent,
    MarketsStrategyAnalysisComponent,
    SearchCustomsCodeComponent,
    SearchProductComponent,
    SearchProductTableComponent,
    HelpDialogComponent,
    ChannelsRegistryComponent,
    CrmComponent,
    CrmDetailComponent,
    ContactReportsDialogComponent,
    ContactCompetitorsDialogComponent,
    ContactDealDialogComponent,
    PsFormComponent,
    PsStrategyComponent,
    PsSwotComponent,
    PsCompetitorComponent,
    CrmContactDetailComponent,
    ContactFormComponent,
    AssignProjectComponent,
    ContactDealsComponent,
    CloneProjectComponent,
    CrmBusinessMatchingComponent,
    PmiCarouselComponent,
    CustomerInfoPanelComponent,
    TableRowDetailsComponent,
    CalendarComponent,
    PublishProjectDialogComponent,
    SidebarComponent,
    CustomCalendarComponent,
    ChunkPipe,
    SearchBarComponent,
    FilterTablePipe,
    SvgIconComponent,
    StrategyFormComponent,
    ScrollToTopDirective,
    PsCurrentStrategyComponent,
    ChannelsManagementDialogComponent,
    FilterNotDynamicTablePipe,
    BusinessOpportunitiesComponent,
    PositionDialogComponent,
    NoticesBoardComponent,
    FilterSelectPipe,
    FilterSelectByConditionPipe,
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter,
                headerName,
                authScheme,
                allowedDomains,
                disallowedRoutes: disallowedRoutes(),
            },
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
            defaultLanguage: 'it',
        }),
        MatRippleModule,
        MatButtonModule,
        MatInputModule,
        MatFormFieldModule,
        MatCardModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatTabsModule,
        MatStepperModule,
        MatRadioModule,
        MatSelectModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatTooltipModule,
        MatCheckboxModule,
        MatMenuModule,
        MatDialogModule,
        MatSnackBarModule,
        MatBadgeModule,
        MatListModule,
        MatDividerModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MatProgressBarModule,
        FontAwesomeModule,
        ReactiveFormsModule,
        // CurrencyMaskModule,
        NgxCurrencyModule,
        MatSlideToggleModule,
        ChartsModule,
        NgxMatSelectSearchModule,
        NgbModule,
        IvyCarouselModule,
        NguCarouselModule,
        DragDropModule,
        FlexLayoutModule,
        PerfectScrollbarModule,
        FlatpickrModule.forRoot(),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts'),
        }),
        AngularSvgIconModule.forRoot(),
        ScrollingModule,
    ],
  providers: [
    DatePipe,
    JwtInterceptor,
    HttpRefreshInterceptorProvider,
    HttpRequestInterceptorProvider,
    ErrorHandlerProvider,
    {
      provide: LOCALE_ID,
      useValue: navigator.language,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    // {
    //   provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    //   useValue: { useUtc: true, strict: true },
    // },
    // { provide: DateAdapter, useClass: MomentDateAdapter },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    // { provide: MAT_DATE_LOCALE, useValue: DATE_PIKER_FORMATS },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: '€',
    },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [ConfigurationService]
    }
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializerFactory,
    //   deps: [TranslateService, Injector],
    //   multi: true,
    // },
  ],
  bootstrap: [AppComponent],
  exports: [DragDropModule],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faBuilding, faAppleAlt, faUser, faFilePdf);
  }
}
