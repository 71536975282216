import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ICustomers, ICustomer } from 'src/app/models/customer';
import { AuthService } from 'src/app/services/auth/auth.service';
import { storageKeys, Roles } from 'src/app/config';
import { ITableButton, ITableColumn } from 'src/app/models/utility';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/services/layout/layout.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  translations: any;
  userRole: string;

  customersLoader: boolean = false;

  activeCustomers: ICustomer[];
  activeCustomersTableColumns: ITableColumn[];
  activeCustomersTableColumnsButtons: ITableButton[];
  searchValueActiveCustomers: string;

  bMCustomers: ICustomer[];
  bMCustomersTableColumns: ITableColumn[];
  bMCustomersTableColumnsButtons: ITableButton[];
  searchValueBMCustomers: string;

  customersHistory: any[];
  customersHistoryTableColumns: ITableColumn[];
  customersHistoryTableColumnsButtons: ITableButton[];
  searchValueCustomersHistory: string;
  historyCustomersLoader: boolean = false;

  noActiveCustomers: ICustomer[];
  noactiveCustomersTableColumns: ITableColumn[];
  noAactiveCustomersTableColumnsButtons: ITableButton[];
  searchValueNoActiveCustomers: string;

  isAuthorized: boolean = false;
  isAgent: boolean = false;
  isAgentSupervisor: boolean = false;
  isLabManagerSupervisor: boolean = false;
  haveBM: boolean = false;
  isDirection: boolean = false;

  constructor(
    private tranlsate: TranslateService,
    private layoutService: LayoutService,
    private irFormService: IrFormService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.userRole = JSON.parse(
      localStorage.getItem(storageKeys.user)
    ).activeRole;
    this.getAllCustomers();
    if (
      this.userRole === Roles.Agent ||
      this.userRole === Roles.LabManager ||
      this.userRole === Roles.Direction
    ) {
      this.isAuthorized = true;
    }
    if (
      this.userRole === Roles.Agent ||
      this.userRole === Roles.AgentSupervisor ||
      this.userRole === Roles.Direction
    ) {
      this.haveBM = true;
    }
    if (this.userRole === Roles.Agent) {
      this.isAgent = true;
      this.getCustomersHistory();
    }
    if (this.userRole === Roles.AgentSupervisor) {
      this.isAgentSupervisor = true;
    }
    if (this.userRole === Roles.LabManagerSupervisor) {
      this.isLabManagerSupervisor = true;
    }
    if (this.userRole === Roles.Direction) {
      this.isDirection = true;
    }
    this.subscriptions.add(
      this.tranlsate.get('CUSTOMERS').subscribe((data: any) => {
        this.translations = data;
        this.activeCustomersTableColumns = [
          {
            name: 'dateInsert',
            translatedName: data.DateInsert,
            type: 'date',
          },
          {
            name: 'businessName',
            translatedName: data.BusinessName,
          },
          {
            name: 'address',
            translatedName: data.Address,
          },
          {
            name: 'email',
            translatedName: data.Email,
          },
          {
            name: 'telephoneNumber',
            translatedName: data.TelephoneNumber,
          },
        ];
        if (this.isAgentSupervisor || this.isDirection) {
          this.activeCustomersTableColumns.push({
            name: 'agent',
            translatedName: data.Agent,
          });
        }
        if (this.isLabManagerSupervisor || this.isDirection) {
          this.activeCustomersTableColumns.push({
            name: 'manager',
            translatedName: data.DLManager,
          });
        }
        this.activeCustomersTableColumns.push({
          name: 'buttons',
          disableSort: true,
        });
        this.bMCustomersTableColumns = [
          {
            name: 'dateInsert',
            translatedName: data.DateInsert,
            type: 'date',
          },
          {
            name: 'businessName',
            translatedName: data.BusinessName,
          },
          {
            name: 'address',
            translatedName: data.Address,
          },
          {
            name: 'email',
            translatedName: data.Email,
          },
          {
            name: 'telephoneNumber',
            translatedName: data.TelephoneNumber,
          },
        ];
        if (this.isAgentSupervisor || this.isDirection) {
          this.bMCustomersTableColumns.push({
            name: 'agent',
            translatedName: data.Agent,
          });
        }
        this.bMCustomersTableColumns.push({
          name: 'buttons',
          disableSort: true,
        });
        this.customersHistoryTableColumns = [
          {
            name: 'dateInsert',
            translatedName: data.DateInsert,
            type: 'date',
          },
          {
            name: 'businessName',
            translatedName: data.BusinessName,
          },
          {
            name: 'address',
            translatedName: data.Address,
          },
          {
            name: 'email',
            translatedName: data.Email,
          },
          {
            name: 'telephoneNumber',
            translatedName: data.TelephoneNumber,
          },
        ];
        this.customersHistoryTableColumns.push({
          name: 'manager',
          translatedName: data.DLManager,
        });
        this.noactiveCustomersTableColumns = [
          {
            name: 'dateInsert',
            translatedName: data.DateInsert,
            type: 'date',
          },
          {
            name: 'businessName',
            translatedName: data.BusinessName,
          },
          {
            name: 'address',
            translatedName: data.Address,
          },
          {
            name: 'email',
            translatedName: data.Email,
          },
          {
            name: 'telephoneNumber',
            translatedName: data.TelephoneNumber,
          },
        ];
        if (this.isAgentSupervisor || this.isDirection) {
          this.noactiveCustomersTableColumns.push({
            name: 'agent',
            translatedName: data.Agent,
          });
        }
        this.noactiveCustomersTableColumns.push({
          name: 'manager',
          translatedName: data.DLManager,
        });
        this.noactiveCustomersTableColumns.push({
          name: 'buttons',
          disableSort: true,
        });
        this.generateBreadCrumb();
      })
    );
  }

  generateBreadCrumb(): void {
    this.subscriptions.add(
      this.layoutService.generateBreadcrumb([
        {
          label: this.tranlsate.instant('PAGES.customers'),
          path: null,
          pageTitle: true,
        },
      ])
    );
  }

  getAllCustomers(): void {
    this.customersLoader = true;
    this.subscriptions.add(
      this.irFormService
        .getAllCustomerByUser(null, true)
        .subscribe((customers: ICustomers) => {
          this.activeCustomers = this.processCustomers(
            customers.customerActive
          );
          this.bMCustomers = this.processCustomers(customers.customerBM);
          this.noActiveCustomers = this.processCustomers(
            customers.customerNoActive
          );
          this.activeCustomersTableColumnsButtons = [
            {
              icon: 'arrow_green_3_icon',
              class: 'primary',
              clickFunction: (item: ICustomer) => this.visualizeCustomer(item),
            },
          ];
          this.bMCustomersTableColumnsButtons = [
            {
              icon: 'arrow_green_3_icon',
              class: 'primary',
              clickFunction: (item: ICustomer) => this.visualizeCustomer(item),
            },
          ];
          this.noAactiveCustomersTableColumnsButtons = [
            {
              icon: 'arrow_green_3_icon',
              class: 'primary',
              clickFunction: (item: ICustomer) => this.visualizeCustomer(item),
            },
          ];
          this.customersLoader = false;
        })
    );
  }

  getCustomersHistory(): void {
    this.historyCustomersLoader = true;
    this.subscriptions.add(
      this.irFormService.getCustomersHistory().subscribe((customers: any) => {
        this.customersHistory = this.processCustomers(customers);
        // this.customersHistoryTableColumnsButtons = [
        //   {
        //     icon: 'arrow_green_3_icon',
        //     class: 'primary',
        //     clickFunction: (item: ICustomer) => this.visualizeCustomer(item),
        //   },
        // ];
        this.historyCustomersLoader = false;
      })
    );
  }

  searchValue(value: string, table: string): void {
    switch (table) {
      case 'active':
        this.searchValueActiveCustomers = value;
        break;
      case 'bMCustomers':
        this.searchValueBMCustomers = value;
        break;
      case 'customersHistory':
        this.searchValueCustomersHistory = value;
        break;
      case 'noActive':
        this.searchValueNoActiveCustomers = value;
        break;
    }
  }

  visualizeCustomer(customer: ICustomer): void {
    this.router.navigate([`/customers`, customer.idCustomer]);
  }

  processCustomers(customers: ICustomer[]): ICustomer[] {
    return customers.map((customer: ICustomer): any => ({
      ...customer,
      businessName: customer?.businessName,
      address: `${customer?.address?.address || ''} ${
        customer?.address?.city || ''
      }`,
      email: customer?.email,
      telephoneNumber: customer?.telephoneNumber,
      dateInsert: customer?.dateInsert,
      isMyCustomer: customer?.isMyCustomer
        ? this.translations.Yes
        : this.translations.No,
      agent: `${customer?.agent?.name} ${customer?.agent?.surname}`,
      manager: `${customer?.manager?.name || '-'} ${
        customer?.manager?.surname || '-'
      }`,
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
