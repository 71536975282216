import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CalendarDay } from 'src/app/components/custom-calendar/calendar-day';
import { APPOINTMENT_TYPE, Pages } from 'src/app/config';
import { IAppointment } from 'src/app/models/appointment';
import { Router } from '@angular/router';

const DAY_MS: number = 60 * 60 * 24 * 1000;

@Component({
  selector: 'app-custom-calendar',
  templateUrl: './custom-calendar.component.html',
  styleUrls: ['./custom-calendar.component.scss'],
})
export class CustomCalendarComponent implements OnInit, OnDestroy {
  calendar: CalendarDay[] = [];
  monthIndex: number = 0;
  selectedMonth: Date;
  loader: boolean = false;
  subscriptions: Subscription = new Subscription();
  days: string[];
  pages: any = Pages;

  @Input() appointments: IAppointment[];

  constructor(private translate: TranslateService, private router: Router) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('CUSTOMCALENDAR').subscribe({
        next: (data: any) => {
          this.days = [
            data.Mo,
            data.Tu,
            data.We,
            data.Th,
            data.Fr,
            data.Sa,
            data.Su,
          ];
          this.generateCalendarDays(this.monthIndex);
          this.initializeCalendar();
        },
      })
    );
  }

  private generateCalendarDays(monthIndex: number): void {
    // we reset our calendar every time
    this.calendar = [];

    // we set the date
    const day: Date = new Date(
      new Date().setMonth(new Date().getMonth() + monthIndex)
    );

    // here we find the first day that our calendar will start from
    // it would be the last Monday of the previous month
    const startingDateOfCalendar = this.getStartDateForCalendar(day);

    // dateToAdd is an intermediate variable that will get increased
    // in the following for loop
    let dateToAdd = startingDateOfCalendar;

    // ok since we have our starting date then we get the next 41 days
    // that we need to add in our calendar array
    // 41 cause our calendar will show 6 weeks and MATH say that
    // 6 weeks * 7 days = 42!!
    for (let i = 0; i < 42; i++) {
      this.calendar.push(new CalendarDay(new Date(dateToAdd)));
      dateToAdd = new Date(dateToAdd.setDate(dateToAdd.getDate() + 1));
    }
  }

  private getStartDateForCalendar(selectedDate: Date): any {
    // for the day we selected let's get the previous month last day
    const lastDayOfPreviousMonth = new Date(selectedDate.setDate(0));

    // start by setting the starting date of the calendar same as the last day of previous month
    let startingDateOfCalendar: Date = lastDayOfPreviousMonth;

    // but since we actually want to find the last Monday of previous month
    // we will start going back in days intil we encounter our last Monday of previous month
    if (startingDateOfCalendar.getDay() !== 1) {
      do {
        startingDateOfCalendar = new Date(
          startingDateOfCalendar.setDate(startingDateOfCalendar.getDate() - 1)
        );
      } while (startingDateOfCalendar.getDay() !== 1);
    }

    return startingDateOfCalendar;
  }

  public setMonth(value: string): void {
    if (value === 'decrease') {
      this.monthIndex--;
    }
    if (value === 'increase') {
      this.monthIndex++;
    }
    this.initializeCalendar();
  }

  private initializeCalendar(): void {
    const idUser: number = JSON.parse(
      localStorage.getItem('session_user')
    ).idUser;
    this.selectedMonth = new Date(
      new Date().setMonth(new Date().getMonth() + this.monthIndex)
    );
    const firstDayOfMonth: Date = new Date(
      this.selectedMonth.getFullYear(),
      this.selectedMonth.getMonth(),
      1
    );
    const lastDayOfMonth: Date = new Date(
      this.selectedMonth.getFullYear(),
      this.selectedMonth.getMonth() + 1,
      1
    );
    this.generateCalendarDays(this.monthIndex);
    // this.subscriptions.add(
    //   this.irFormService
    //     .getAgentAppointment(
    //       firstDayOfMonth.toISOString(),
    //       lastDayOfMonth.toISOString(),
    //       idUser,
    //       APPOINTMENT_STATE.Created
    //     )
    //     .subscribe({
    //       next: (appointments: IAppointment[]) => {
    //         this.appointments = appointments;
    //         this.generateCalendarDays(this.monthIndex);
    //         this.loader = false;
    //       },
    //     })
    // );
  }

  public setCurrentMonth(): void {
    this.monthIndex = 0;
    this.generateCalendarDays(this.monthIndex);
  }

  differentMonth(date: Date): boolean {
    return date.getMonth() === this.selectedMonth.getMonth();
  }

  checkAppointment(date: Date): string {
    let ret: string = '';
    if (this.appointments) {
      this.appointments.forEach((appointment: IAppointment) => {
        if (
          date.getMonth() === new Date(appointment.startDate).getMonth() &&
          date.getDate() === new Date(appointment.startDate).getDate()
        ) {
          if (appointment.appointmentType === APPOINTMENT_TYPE.Telephonic) {
            ret = 'telephonic';
          }
          if (appointment.appointmentType === APPOINTMENT_TYPE.Personal) {
            ret = 'personal';
          }
        }
      });
    }
    return ret;
  }

  goToSpecificMeet(meetDate: Date): void {
    this.router.navigate(['/meetings'], {
      queryParams: { showDay: true, date: meetDate },
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
