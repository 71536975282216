import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { PRODUCT_TYPE, MARKET_TYPE, CUSTOM_INDEXES } from 'src/app/config';
import {
  ICountryMarketObjective,
  ICustomIndex,
  IIndexMarketObjective,
  IMatrixMarketsAnalysis,
  InterchangeTrendBalance,
  MarketsAnalysisResponse,
} from 'src/app/models/markets';
import { Observable, Subscription } from 'rxjs';
import {
  IInterchangeData,
  IProductsServices,
  IInterchangeDataForTable,
} from 'src/app/models/products-services';
import { Project } from 'src/app/models/project';
import { fromInterchangeDataToDataForTable } from 'src/app/utility/elaborations';
import { ICountries } from 'src/app/models/countries';
import { CommonService } from 'src/app/services/common/common.service';
import { ITableColumn, ToastStatus } from 'src/app/models/utility';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { MatSelect } from '@angular/material/select';
import { LayoutService } from 'src/app/services/layout/layout.service';

@Component({
  selector: 'app-markets-strategy-analysis',
  templateUrl: './markets-strategy-analysis.component.html',
  styleUrls: ['./markets-strategy-analysis.component.scss'],
})
export class MarketsStrategyAnalysisComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  interchangeDataLoader: boolean;
  marketsAnalysis: MarketsAnalysisResponse;

  analysisForm: FormGroup;
  customIndexesForm: FormGroup;
  project: Project;
  products: IProductsServices[] = [];
  selectedProduct: IProductsServices;
  interchangeTypologyData: IInterchangeDataForTable[];
  oldInterchangeTypologyData: IInterchangeDataForTable[];
  countryMarketObjectives: ICountryMarketObjective[] = [];
  interchangeDataStartYear: number = 2014;
  interchangeDataYears: number[] = [];
  private subscriptions: Subscription = new Subscription();

  setPresenceMarkets: boolean = true;
  setActualMarkets: boolean = true;
  setFutureMarkets: boolean = true;
  selectedMarkets: IInterchangeData[] = [];
  selectedCountries: number[] = [];
  countries: ICountries[];
  totalPoints: number[] = [];
  totalWeightedAverage: number[] = [];
  matrixMarketAnalysis: IMatrixMarketsAnalysis;
  highlightsRecalculateChecker: boolean = false;

  startDataOnInit: any;
  sortArrowDirection: string = null;
  columnToSort: string = '';
  customIndexList: ICustomIndex[] = [];

  @ViewChild('productSelection') productSelection: MatSelect;
  @ViewChild('matrixTable') matrixTable: ElementRef;
  @ViewChildren('selectedCountry') selectedCountry: QueryList<any>;
  @ViewChildren('visualizedCountry') visualizedCountry: QueryList<any>;

  @Input() translations: any;
  @Input() initialValues: Observable<Project>;
  @Input() index: any;
  @Input() tabIndex: any;
  @Input() isSupervisor: boolean;
  @Input() isLabManager: boolean;
  @Input() isIspDelivered: boolean;
  @Input() isLabManagerInternal: boolean;
  @Input() isReadOnly: boolean;
  @Input() canModifyStrategy: boolean;
  @Input() isManagerAndSupervisor: boolean;

  @Output() saveMarketsAnalysisEmitter: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() saveMatrixEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() deleteStrategyEmitter: EventEmitter<any> = new EventEmitter();
  @Output() sendIspEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() rejectIspEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() approveIspEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private dialog: MatDialog,
    private common: CommonService,
    private cdref: ChangeDetectorRef,
    private fb: FormBuilder,
    private translate: TranslateService,
    private irFormService: IrFormService,
    private layoutService: LayoutService
  ) {
    this.analysisForm = this.fb.group({
      idMarketAnalysis: [null],
      productCN8: [null],
      evaluationTypology: [{ value: null, disabled: true }],
      interchangeTypology: [null],
      apparentConsumptionTypology: [null],
      yearEnd: [{ value: null }],
    });
    this.customIndexesForm = this.fb.group({
      balanceWeight: [null, [Validators.required]],
      perCapitaWeight: [null, [Validators.required]],
      trendWeight: [null, [Validators.required]],
      macroWeight: [null, [Validators.required]],
      countryRiskWeight: [null, [Validators.required]],
      customIndexes: fb.array([]),
    });
  }

  @ViewChild('marketInfoDialog') marketInfoDialog: any;

  get customIndexes(): FormArray {
    return this.customIndexesForm.get('customIndexes') as FormArray;
  }

  countryMarketObjective(index: number): FormArray {
    return (this.customIndexes.at(index) as FormGroup).get(
      'countryMarketObjective'
    ) as FormArray;
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initialValues.subscribe({
        next: (project: Project) => {
          this.project = project;
          this.analysisForm.reset();
          this.customIndexesForm.reset();
          this.products = project.productsServices.filter(
            (product: IProductsServices) =>
              product.idProductType === PRODUCT_TYPE.sold
          );
          if (this.isReadOnly) {
            this.analysisForm.disable();
            this.customIndexesForm.disable();
          }
          this.marketsAnalysis = project?.marketAnalysis;
          if (project.marketAnalysis) {
            const productSelected: IProductsServices = this.products.find(
              (product: IProductsServices) => {
                return (
                  product.idProduct ===
                  project.marketAnalysis.marketAnalysis.idProduct
                );
              }
            );
            this.analysisForm.get('productCN8').setValue(productSelected);
            this.selectProduct(productSelected);
            this.analysisForm
              .get('idMarketAnalysis')
              .setValue(project.marketAnalysis.marketAnalysis.idMarketAnalysis);
            this.analysisForm
              .get('evaluationTypology')
              .setValue(
                project?.marketAnalysis?.marketAnalysis?.evaluationTypology
              );
            this.analysisForm
              .get('interchangeTypology')
              .setValue(
                project?.marketAnalysis?.marketAnalysis?.interchangeTypology
              );
            this.getInterchangeYears(project?.marketAnalysis?.marketAnalysis?.interchangeTypology);
            this.analysisForm
              .get('yearEnd')
              .setValue(project?.marketAnalysis?.marketAnalysis?.yearEnd);
            this.interchangeTypologyData =
              project?.interchangeTypologyData?.map(
                fromInterchangeDataToDataForTable
              );
            this.oldInterchangeTypologyData =
              project?.interchangeTypologyData.map(
                fromInterchangeDataToDataForTable
              );
            this.selectedMarkets =
              project?.marketAnalysis?.interchangeTrendBalance?.map(
                fromInterchangeDataToDataForTable
              );
          } else {
            this.interchangeTypologyData = null;
            this.oldInterchangeTypologyData = null;
            this.selectedProduct = null;
            this.selectedMarkets = null;
          }
          // Initialize total points array to 0
          this.initializeMatrixMarketsTable(project?.matrixMarketAnalysis);
          if (this.isReadOnly) {
            this.analysisForm.disable();
          }
          this.startDataOnInit = {
            analysis: this.analysisForm.getRawValue(),
            index: this.customIndexesForm.getRawValue(),
          };
          // Sort selected markets for first
          this.sortSelectedMarkets('descending');
        },
      })
    );
    this.subscriptions.add(
      this.irFormService.getCustomIndexList().subscribe({
        next: (customIndexList: ICustomIndex[]) => {
          this.customIndexList = customIndexList.map((customIndex) => ({
            idCustomIndex: customIndex.idDefaultIndexMarketObjective,
            description: `${CUSTOM_INDEXES[customIndex.description]}`,
          }));
        },
      })
    );
  }

  sortSelectedMarkets(sortType: string): void {
    let index: number;
    this.selectedMarkets?.forEach((market: IInterchangeData) => {
      index = this.interchangeTypologyData
        .map((item) => item.idCountry)
        .indexOf(market.idCountry);
      if (index > -1) {
        this.interchangeTypologyData.splice(index, 1);
      }
    });
    this.selectedMarkets?.sort((a, b) => {
      return a.saldo.value - b.saldo.value;
    });
    if (sortType === 'ascending') {
      this.selectedMarkets?.forEach((market: IInterchangeData) => {
        this.interchangeTypologyData.push(market);
      });
    } else if (sortType === 'descending') {
      this.selectedMarkets?.forEach((market: IInterchangeData) => {
        this.interchangeTypologyData.unshift(market);
      });
    }
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  selectProduct(product: IProductsServices): void {
    this.selectedProduct = product;
    this.analysisForm.get('evaluationTypology').enable();
    this.analysisForm.get('evaluationTypology').setValue(null);
    this.analysisForm.get('interchangeTypology').setValue(null);
    this.analysisForm.get('yearEnd').setValue(null);
  }

  initializeMatrixMarketsTable(
    matrixMarketAnalysis: IMatrixMarketsAnalysis
  ): void {
    this.selectedCountries = matrixMarketAnalysis.countries || [];
    this.matrixMarketAnalysis = matrixMarketAnalysis;
    if (this.matrixMarketAnalysis.indexMarketObjective.length) {
      this.customIndexesForm.reset();
      const standardWeightControls: AbstractControl[] = [
        this.customIndexesForm.get('balanceWeight'),
        this.customIndexesForm.get('perCapitaWeight'),
        this.customIndexesForm.get('trendWeight'),
        this.customIndexesForm.get('macroWeight'),
        this.customIndexesForm.get('countryRiskWeight'),
      ];
      for (let i = 0; i < 5; i++) {
        if (this.matrixMarketAnalysis.indexMarketObjective[i].weight) {
          standardWeightControls[i].setValue(
            this.matrixMarketAnalysis.indexMarketObjective[i].weight
          );
        }
      }
      this.totalPoints = [];
      this.totalWeightedAverage = [];
      this.totalPoints.push(0);
      this.matrixMarketAnalysis.indexMarketObjective[0].countryMarketObjective.forEach(
        () => {
          this.totalPoints.push(0);
          this.totalWeightedAverage.push(0);
        }
      );
      this.customIndexes.clear();
      this.matrixMarketAnalysis.indexMarketObjective.forEach(
        (indexMarketObjective: IIndexMarketObjective) => {
          if (indexMarketObjective.idMacroIndexType === 2) {
            this.addCustomIndex(indexMarketObjective);
          }
        }
      );
      this.recalculateTotalsValues();
    }
  }

  /**
   * Show a warning message when the product want to be changed.
   * If will be clicked "confirm", every table will be hidden and all forms reset
   */
  showWarningResetMessage(): void {
    if (!this.isReadOnly) {
      if (this.marketsAnalysis?.interchangeTrendBalance?.length) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = true;
        dialogConfig.width = '400px';
        dialogConfig.panelClass = 'custom-dialog-container';
        dialogConfig.data = {
          title: this.translate.instant('COMMON.Attention'),
          message: this.translations.WarningResetMarketAnalysisMessage,
          buttonTrue: this.translations.Yes,
          buttonFalse: this.translations.No,
        };
        const dialogRef = this.dialog.open(
          ConfirmationDialogComponent,
          dialogConfig
        );
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.deleteStrategyEmitter.emit();
          } else {
            this.productSelection.close();
          }
        });
      }
    }
  }

  getInterchangeYears(interchangeType: number): void {
    this.interchangeDataYears = [];
    this.subscriptions.add(
      this.irFormService
        .getInterchangeYears(interchangeType, false).subscribe({
          next: (years) => {
            this.interchangeDataYears = years;
            this.interchangeDataYears.reverse();
          }
        })
    );
  }

  showInterchangeData(): void {
    const options: any = {
      cnCode: this.selectedProduct.productCode,
      interchangeTypology: this.analysisForm.get('interchangeTypology').value,
      yearEnd: this.analysisForm.get('yearEnd').value,
    };
    this.subscriptions.add(
      this.irFormService
        .getInterchangeTypologyData(
          options.cnCode,
          options.interchangeTypology,
          this.project.idCustomer,
          options.yearEnd
        )
        .subscribe({
          next: (interchangeTypologyData: IInterchangeData[]) => {
            this.interchangeTypologyData = interchangeTypologyData?.map(
              fromInterchangeDataToDataForTable
            );
            this.oldInterchangeTypologyData = interchangeTypologyData?.map(
              fromInterchangeDataToDataForTable
            );
          },
          complete: () => {
            this.selectedMarkets = [];
          },
        })
    );
  }

  selectMarket(checked: boolean, market: IInterchangeData): void {
    if (checked) {
      this.selectedMarkets.push(market);
    } else {
      this.selectedMarkets = this.selectedMarkets.filter(
        (item: IInterchangeDataForTable): boolean =>
          item.idCountry !== market.idCountry
      );
      // this.selectedMarkets.splice(this.selectedMarkets.indexOf(market), 1);
    }
  }

  checkMarkets(markets: IInterchangeDataForTable): boolean {
    let ret: boolean = false;
    for (const selected of this.selectedMarkets) {
      if (selected.idCountry === markets.idCountry) {
        ret = true;
        break;
      }
    }
    return ret;
  }

  checkCorrespondance(rowCountry: IInterchangeData): boolean {
    let ret: boolean = false;
    this.selectedMarkets.forEach((country: IInterchangeData) => {
      if (country.idCountry === rowCountry.idCountry) {
        ret = true;
      }
    });
    return ret;
  }

  saveAnalysis(): void {
    if (this.project?.marketAnalysis?.interchangeTrendBalance?.length) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.disableClose = true;
      dialogConfig.width = '400px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translate.instant('COMMON.Attention'),
        message: this.translations.CreateNewMatrixWarning,
        buttonTrue: this.translations.Yes,
        buttonFalse: this.translations.No,
      };
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.irFormService.deleteStrategy(this.project.idProject).subscribe({
            complete: () => {
              this.selectedCountries = [];
              const analysisForm: any = this.analysisForm.getRawValue();
              const analysis: MarketsAnalysisResponse = {
                marketAnalysis: {
                  idMarketAnalysis: null,
                  idCustomer: this.project.customer.idCustomer,
                  idProduct: analysisForm.productCN8.idProduct,
                  descriptionProduct: analysisForm.productCN8.description,
                  productCN8: analysisForm.productCN8.productCode,
                  yearEnd: analysisForm.yearEnd,
                  productName: analysisForm.productCN8.name,
                  evaluationTypology: analysisForm.evaluationTypology,
                  interchangeTypology: analysisForm.interchangeTypology,
                  idProject: this.project.idProject,
                  idUser: this.project.idUser,
                },
                interchangeTrendBalance: this.selectedMarkets.map(
                  (market: IInterchangeData): InterchangeTrendBalance => ({
                    idCountry: market.idCountry,
                    countryName: market.countryName,
                    isToIncrease: market.toIncrease,
                    idMarketType: market.idMarketType,
                    marketTypeName: market.marketTypeName,
                    benchmark: market.benchmark,
                    saldo: market.saldo,
                    saldoProCapite: market.saldoProCapite,
                    trend: market.trend,
                    toIncrease: market.toIncrease,
                  })
                ),
              };
              this.saveMarketsAnalysisEmitter.emit(analysis);
              this.matrixTable?.nativeElement?.scrollIntoView({
                behavior: 'smooth',
              });
              let index: number;
              this.selectedMarkets.forEach((market: IInterchangeData) => {
                index = this.interchangeTypologyData
                  .map((item) => item.idCountry)
                  .indexOf(market.idCountry);
                if (index > -1) {
                  this.interchangeTypologyData.splice(index, 1);
                }
              });
              this.selectedMarkets.sort((a, b) => {
                return b.saldo.value - a.saldo.value;
              });
              this.selectedMarkets.forEach((market: IInterchangeData) => {
                this.interchangeTypologyData.unshift(market);
              });
            },
          });
        }
      });
    } else {
      this.selectedCountries = [];
      const analysisForm: any = this.analysisForm.getRawValue();
      const analysis: MarketsAnalysisResponse = {
        marketAnalysis: {
          idMarketAnalysis: analysisForm.idMarketAnalysis,
          idCustomer: this.project.customer.idCustomer,
          idProduct: analysisForm.productCN8.idProduct,
          descriptionProduct: analysisForm.productCN8.description,
          productCN8: analysisForm.productCN8.productCode,
          yearEnd: analysisForm.yearEnd,
          productName: analysisForm.productCN8.name,
          evaluationTypology: analysisForm.evaluationTypology,
          interchangeTypology: analysisForm.interchangeTypology,
        },
        interchangeTrendBalance: this.selectedMarkets.map(
          (market: IInterchangeData): InterchangeTrendBalance => ({
            idCountry: market.idCountry,
            countryName: market.countryName,
            isToIncrease: market.toIncrease,
            idMarketType: market.idMarketType,
            marketTypeName: market.marketTypeName,
            benchmark: market.benchmark,
            saldo: market.saldo,
            saldoProCapite: market.saldoProCapite,
            trend: market.trend,
            toIncrease: market.toIncrease,
          })
        ),
      };
      this.saveMarketsAnalysisEmitter.emit(analysis);
      this.matrixTable?.nativeElement?.scrollIntoView({ behavior: 'smooth' });
      let index: number;
      this.selectedMarkets.forEach((market: IInterchangeData) => {
        index = this.interchangeTypologyData
          .map((item) => item.idCountry)
          .indexOf(market.idCountry);
        if (index > -1) {
          this.interchangeTypologyData.splice(index, 1);
        }
      });
      this.selectedMarkets.sort((a, b) => {
        return b.saldo.value - a.saldo.value;
      });
      this.selectedMarkets.forEach((market: IInterchangeData) => {
        this.interchangeTypologyData.unshift(market);
      });
    }
  }

  setCurrencyMask(value: number): any {
    const curr = value.toLocaleString('it-IT');
    return curr;
  }

  addCustomIndex(customIndex?: IIndexMarketObjective): void {
    this.customIndexes.push(
      this.fb.group({
        countryMarketObjective: this.fb.array([]),
        idCustomer: this.project.idCustomer,
        idIndexMarketObjective: [customIndex?.idIndexMarketObjective || null],
        idIndexType: [5 + this.customIndexes.length + 1],
        idMacroIndexType: [2],
        name: [customIndex?.name || null, [Validators.required]],
        weight: [
          customIndex?.weight || null,
          [Validators.required, Validators.min(0), Validators.max(100)],
        ],
      })
    );
    if (customIndex?.countryMarketObjective) {
      customIndex.countryMarketObjective.forEach(
        (countryMarketObjective: ICountryMarketObjective) => {
          this.addCustomCountryMarketObjective(
            countryMarketObjective,
            this.customIndexes.length - 1
          );
        }
      );
    } else {
      this.matrixMarketAnalysis.indexMarketObjective[0].countryMarketObjective.forEach(
        (countryMarketObjective: ICountryMarketObjective) => {
          this.addCustomCountryMarketObjective(
            countryMarketObjective,
            this.customIndexes.length - 1
          );
        }
      );
    }
  }

  setCustomIndexText(item: string, index: number): void {
    this.customIndexes.at(index).get('name').setValue(item);
    this.customIndexes.markAsDirty();
    this.customIndexes.markAsTouched();
  }

  addCustomCountryMarketObjective(
    countryMarketObjective: ICountryMarketObjective,
    index?: number
  ): void {
    this.countryMarketObjective(index).push(
      this.fb.group({
        idCustomer: [this.project.idCustomer],
        countryName: [countryMarketObjective.countryName],
        idCountry: [countryMarketObjective.idCountry],
        idCountryMarketObjective: [
          countryMarketObjective.idIndexType > 5
            ? countryMarketObjective.idCountryMarketObjective
            : null,
        ],
        value: [
          countryMarketObjective.idIndexType > 5
            ? countryMarketObjective.value
            : null,
          [Validators.required, Validators.min(0), Validators.max(10)],
        ],
      })
    );
  }

  removeCustomIndex(customIndex: AbstractControl, i: number): void {
    if (customIndex.get('idIndexMarketObjective').value) {
      const idIndexType: number = customIndex.get(
        'idIndexMarketObjective'
      ).value;
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = false;
      dialogConfig.width = '400px';
      dialogConfig.panelClass = 'custom-dialog-container';
      dialogConfig.data = {
        title: this.translations?.Attention,
        message: this.translations.DeleteCustomIndexMessageConfirmation,
        buttonTrue: this.translations.Yes,
        buttonFalse: this.translations.No,
      };
      const dialogRef = this.dialog.open(
        ConfirmationDialogComponent,
        dialogConfig
      );
      this.subscriptions.add(
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.subscriptions.add(
              this.irFormService
                .deleteIndexMarketObjective(this.project.idProject, idIndexType)
                .subscribe({
                  complete: () => {
                    this.customIndexes.removeAt(i);
                  },
                })
            );
          }
        })
      );
    } else {
      this.customIndexes.removeAt(i);
    }
  }

  recalculateTotalsValues(): void {
    this.highlightsRecalculateChecker = false;
    if (this.customIndexesForm.valid) {
      /* TOTAL POINTS */
      // standard weights
      const standardWeightValues: number[] = [
        this.customIndexesForm.get('balanceWeight').value,
        this.customIndexesForm.get('perCapitaWeight').value,
        this.customIndexesForm.get('trendWeight').value,
        this.customIndexesForm.get('macroWeight').value,
        this.customIndexesForm.get('countryRiskWeight').value,
      ];
      // standard values
      const standardValues: number[] = [];
      standardValues.push(0);
      this.matrixMarketAnalysis.indexMarketObjective[0].countryMarketObjective.forEach(
        () => {
          standardValues.push(0);
        }
      );
      for (let i = 0; i < 5; i++) {
        standardValues[0] = standardValues[0] + standardWeightValues[i];
        let index = 1;
        this.matrixMarketAnalysis.indexMarketObjective[
          i
        ].countryMarketObjective.forEach(
          (countryMarket: ICountryMarketObjective) => {
            standardValues[index] = standardValues[index] + countryMarket.value;
            index = index + 1;
          }
        );
      }
      // custom values
      const customIndexesValues: number[] = [];
      if (this.customIndexes.length) {
        let customIndexesArray: any[];
        customIndexesArray = this.customIndexes.getRawValue();
        customIndexesValues.push(0);
        customIndexesArray[0].countryMarketObjective.forEach(() => {
          customIndexesValues.push(0);
        });
        customIndexesArray.forEach((customIndex: any) => {
          let index = 0;
          customIndexesValues[index] =
            customIndexesValues[index] + customIndex.weight;
          index++;
          customIndex.countryMarketObjective.forEach((data: any) => {
            customIndexesValues[index] =
              customIndexesValues[index] + data.value;
            index = index + 1;
          });
        });
      }
      // total result
      if (customIndexesValues.length) {
        this.totalPoints = customIndexesValues.map(
          (value: number, index: number) => {
            return value + standardValues[index];
          }
        );
      } else {
        this.totalPoints = standardValues;
      }

      /* WEIGHTED AVERAGE */
      // standard average
      const standardWeightedAverage: number[] = [];
      this.matrixMarketAnalysis.indexMarketObjective[0].countryMarketObjective.forEach(
        () => {
          standardWeightedAverage.push(0);
        }
      );
      for (let i = 0; i < 5; i++) {
        let index = 0;
        this.matrixMarketAnalysis.indexMarketObjective[
          i
        ].countryMarketObjective.forEach(
          (countryMarket: ICountryMarketObjective) => {
            standardWeightedAverage[index] =
              standardWeightedAverage[index] +
              countryMarket.value * standardWeightValues[i];
            index++;
          }
        );
      }
      // custom average
      const customWeightedAverage: number[] = [];
      if (this.customIndexes.length) {
        let customIndexesArray: any[];
        customIndexesArray = this.customIndexes.getRawValue();
        customIndexesArray[0].countryMarketObjective.forEach(() => {
          customWeightedAverage.push(0);
        });
        customIndexesArray.forEach((customIndex: any) => {
          let index = 0;
          customIndex.countryMarketObjective.forEach((data: any) => {
            customWeightedAverage[index] =
              customWeightedAverage[index] + data.value * customIndex.weight;
            index++;
          });
        });
      }
      // total average
      if (customWeightedAverage.length) {
        this.totalWeightedAverage = customWeightedAverage.map(
          (value: number, index: number) => {
            return (value + standardWeightedAverage[index]) / 100;
          }
        );
      } else {
        this.totalWeightedAverage = [];
        standardWeightedAverage.forEach((value: number) => {
          if (value > 0) {
            this.totalWeightedAverage.push(value / 100);
          } else {
            this.totalWeightedAverage.push(0);
          }
        });
      }
    } else {
      this.customIndexes.controls.forEach((customIndex: any) => {
        customIndex.get('name').markAsTouched();
        customIndex.get('weight').markAsTouched();
        customIndex
          .get('countryMarketObjective')
          .controls.forEach((countryMarketObjective: any) => {
            countryMarketObjective.get('value').markAsTouched();
          });
      });
    }
  }

  highlightsRecalculate(): void {
    this.highlightsRecalculateChecker = true;
  }

  selectCountry(
    checked: boolean,
    country: ICountryMarketObjective,
    index: number
  ): void {
    if (checked) {
      this.selectedCountries.push(country.idCountry);
    } else {
      this.selectedCountries = this.selectedCountries.filter(
        (item: number): boolean => item !== country.idCountry
      );
    }
  }

  checkCountries(country: ICountryMarketObjective): boolean {
    let ret: boolean = false;
    for (const selected of this.selectedCountries) {
      if (selected === country.idCountry) {
        ret = true;
        break;
      }
    }
    return ret;
  }

  // setMatToolTip(index: number): string {
  //   if (this.interchangeTypologyData) {
  //     const idCountry: number =
  //       this.matrixMarketAnalysis?.indexMarketObjective[0]
  //         ?.countryMarketObjective[index]?.idCountry;
  //     for (const country of this.interchangeTypologyData) {
  //       if (country.idCountry === idCountry) {
  //         return `${this.translations.Benchmark}: ${
  //           country.benchmark || '---'
  //         },
  //           ${this.translations.MarketTypology}: ${
  //           MARKET_TYPE[country.idMarketType] || '---'
  //         },
  //           ${this.translations.Balance}: € ${new Intl.NumberFormat(
  //           'it-IT'
  //         ).format(
  //           Math.round((country.saldo.value + Number.EPSILON) * 100) / 100
  //         )},
  //           ${this.translations.PerCapita}: € ${new Intl.NumberFormat(
  //           'it-IT'
  //         ).format(
  //           Math.round((country.saldoProCapite.value + Number.EPSILON) * 100) /
  //             100
  //         )},
  //           ${this.translations.Trend}: € ${new Intl.NumberFormat(
  //           'it-IT'
  //         ).format(
  //           Math.round((country.trend.value + Number.EPSILON) * 100) / 100
  //         )}
  //           `;
  //       }
  //     }
  //   }
  // }

  showMarketInfoDialog(marketIndex: number): void {
    let selectedMarket: IInterchangeDataForTable;
    const idCountry: number =
      this.matrixMarketAnalysis?.indexMarketObjective[0]
        ?.countryMarketObjective[marketIndex]?.idCountry;
    this.interchangeTypologyData.forEach((market: IInterchangeDataForTable) => {
      if (market.idCountry === idCountry) {
        selectedMarket = market;
      }
    });
    console.log(selectedMarket);
    const dialogConfig: MatDialogConfig<any> = new MatDialogConfig<any>();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = 'auto';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      selectedMarket,
    };
    this.dialog.open(this.marketInfoDialog, dialogConfig);
  }

  saveMatrix(): void {
    if (
      this.customIndexesForm.valid &&
      this.selectedCountries.length &&
      this.totalPoints[0] === 100
    ) {
      const body: IMatrixMarketsAnalysis = {
        countries: [...this.selectedCountries],
        indexMarketObjective: [],
      };
      const weightValues: number[] = [
        this.customIndexesForm.get('balanceWeight').value,
        this.customIndexesForm.get('perCapitaWeight').value,
        this.customIndexesForm.get('trendWeight').value,
        this.customIndexesForm.get('macroWeight').value,
        this.customIndexesForm.get('countryRiskWeight').value,
      ];
      for (let i = 0; i < 5; i++) {
        this.matrixMarketAnalysis.indexMarketObjective[i].weight =
          weightValues[i];
        body.indexMarketObjective.push(
          this.matrixMarketAnalysis.indexMarketObjective[i]
        );
      }

      body.indexMarketObjective = [
        ...body.indexMarketObjective,
        ...this.customIndexes.getRawValue(),
      ];
      this.saveMatrixEmitter.emit(body);
    }
    if (this.totalPoints[0] !== 100) {
      this.common.showToast(
        this.translations.TotalWeightMustBe100,
        ToastStatus.warning,
        3000,
        null
      );
    }
    if (this.customIndexesForm.invalid) {
      this.common.showToast(
        this.translations.ThereAreRequiredFieldsToFillIn,
        ToastStatus.warning,
        3000,
        null
      );
    }
    if (!this.selectedCountries.length) {
      this.common.showToast(
        this.translations.SelectAtLeastOneCountry,
        ToastStatus.warning,
        3000,
        null
      );
    }
  }

  cropDecimalValue(customIndex: FormControl): void {
    if (customIndex.value) {
      customIndex.setValue(parseFloat(customIndex.value.toFixed(2)));
      customIndex.updateValueAndValidity();
    }
  }

  checkDirtyForm(): boolean {
    return (
      JSON.stringify(this.startDataOnInit, this.irFormService.replacer) !==
      JSON.stringify({
        analysis: this.analysisForm.getRawValue(),
        index: this.customIndexesForm.getRawValue(),
      })
    );
  }

  checkInvalidForm(): boolean {
    this.customIndexesForm.markAllAsTouched();
    if (
      this.matrixMarketAnalysis?.countries?.length ||
      this.customIndexesForm.invalid
    ) {
      return false;
    } else {
      return true;
    }
  }

  sortColumn(column: string): void {
    if (!this.oldInterchangeTypologyData) {
      this.oldInterchangeTypologyData = this.interchangeTypologyData.slice(0);
    }
    this.columnToSort = column;
    if (this.sortArrowDirection === null) {
      this.sortArrowDirection = 'expand_less';
      if (column === 'presenceMarkets') {
        this.interchangeTypologyData.sort((a, b) => {
          return (
            a.sortByPresenceMarket - b.sortByPresenceMarket ||
            a.levelOfInterest - b.levelOfInterest
          );
        });
      } else if (column === 'potentialMarkets') {
        this.interchangeTypologyData.sort((a, b) => {
          return (
            a.sortByPotential - b.sortByPotential ||
            a.levelOfInterest - b.levelOfInterest
          );
        });
      } else if (column === 'competition') {
        this.interchangeTypologyData.sort((a, b) => {
          return a.sortByBenchmark - b.sortByBenchmark;
        });
      } else if (column === 'countryName') {
        this.interchangeTypologyData.sort((a, b) => {
          if (a.countryName > b.countryName) {
            return 1;
          }
          if (a.countryName < b.countryName) {
            return -1;
          }
          return 0;
        });
      } else if (column === 'check') {
        this.sortSelectedMarkets('ascending');
      } else {
        this.interchangeTypologyData.sort((a, b) => {
          return a[column] - b[column];
        });
      }
      return;
    }
    if (this.sortArrowDirection === 'expand_less') {
      this.sortArrowDirection = 'expand_more';
      if (column === 'presenceMarkets') {
        this.interchangeTypologyData.sort((a, b) => {
          return (
            b.sortByPresenceMarket - a.sortByPresenceMarket ||
            b.levelOfInterest - a.levelOfInterest
          );
        });
        return;
      } else if (column === 'potentialMarkets') {
        this.interchangeTypologyData.sort((a, b) => {
          return (
            b.sortByPotential - a.sortByPotential ||
            b.levelOfInterest - a.levelOfInterest
          );
        });
        return;
      } else if (column === 'competition') {
        this.interchangeTypologyData.sort((a, b) => {
          return b.sortByBenchmark - a.sortByBenchmark;
        });
        return;
      } else if (column === 'countryName') {
        this.interchangeTypologyData.sort((a, b) => {
          if (b.countryName > a.countryName) {
            return 1;
          }
          if (b.countryName < a.countryName) {
            return -1;
          }
          return 0;
        });
      } else if (column === 'check') {
        this.sortSelectedMarkets('descending');
      } else {
        this.interchangeTypologyData.sort((a, b) => {
          return b[column] - a[column];
        });
      }
      return;
    }
    if (this.sortArrowDirection === 'expand_more') {
      this.sortArrowDirection = null;
      this.interchangeTypologyData = this.oldInterchangeTypologyData.slice(0);
      return;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
