import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, interval } from 'rxjs';
import { IClonable } from 'src/app/models/clonable';
import { IContact, IOtherContactForTable } from 'src/app/models/contact';
import { IProjectCustomer } from 'src/app/models/project';
import { IColumnsName, ITableButtons, ITableOptions, ToastStatus } from 'src/app/models/utility';
import { CommonService } from 'src/app/services/common/common.service';
import { CrmService } from 'src/app/services/crm/crm.service';
import { fromOtherContactToDataForTable, fromProjectCustomerToDataForTable } from 'src/app/utility/elaborations';

@Component({
  selector: 'app-clone-project',
  templateUrl: './clone-project.component.html',
  styleUrls: ['./clone-project.component.scss']
})
export class CloneProjectComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription = new Subscription();
  idProject: number;
  cloneProjectForm: FormGroup;
  contactsForm: FormGroup;
  translations: any;
  projectTableLoader: boolean = false;
  tableLoader: boolean = false;
  parentTableLoader: boolean = false;
  importTableLoader: boolean = false;
  isStepCompleted: boolean = false;
  enableProgresBar: boolean = false;

  projectTableDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  projectTableDisplayedColumns: string[] = [
    'code',
    'projectStatusCode',
    'businessName',
    'email',
    'buttons'
  ];
  projectTableColumnsName: IColumnsName[];
  projectTabTableButtons: ITableButtons[] = [
    {
      click: (project: IProjectCustomer) => { this.getParentClonableByProject(project.idProject); },
      icon: 'forward'
    }
  ];
  projectTableOptions: ITableOptions = {
    showButtons: true,
    showFilter: true,
    showPaginator: true,
    showSort: true
  };

  contactTableDataSource: MatTableDataSource<IOtherContactForTable> = new MatTableDataSource<IOtherContactForTable>();
  contactTableDisplayedColumns: string[] = [
    'select',
    'businessName',
    'country',
    'telephoneNumber'
  ];
  contactTableColumnsName: IColumnsName[];
  contactCheckbox: any = {
    isSelected: (contact: IOtherContactForTable): boolean => { return this.checkSelected(contact); },
    disabled: (contact: IOtherContactForTable): boolean => { return this.checkDisabled(contact); },
    click: (event: any, contact: IOtherContactForTable) => { this.addContactToClone(event, contact); },
  };
  contactTableOptions: ITableOptions = {
    showButtons: false,
    showFilter: true,
    showPaginator: true,
    showSort: true
  };

  contactParentTableDataSource: MatTableDataSource<IOtherContactForTable> = new MatTableDataSource<IOtherContactForTable>();
  contactParentTableDisplayedColumns: string[] = [
    'businessName',
    'country',
    'telephoneNumber'
  ];
  contactParentTableColumnsName: IColumnsName[];
  contactParentTableOptions: ITableOptions = {
    showButtons: false,
    showFilter: true,
    showPaginator: true,
    showSort: true
  };

  importTableDataSource: MatTableDataSource<IOtherContactForTable> = new MatTableDataSource<IOtherContactForTable>();
  importParentTableDisplayedColumns: string[] = [
    'businessName',
    'country',
    'telephoneNumber'
  ];
  importParentTableColumnsName: IColumnsName[] = [
    { value: 'businessName', name: null },
    { value: 'email', name: null },
    { value: 'country', name: null },
    { value: 'telephoneNumber', name: null }
  ];
  importParentTableOptions: ITableOptions = {
    showButtons: false,
    showFilter: true,
    showPaginator: true,
    showSort: true
  };

  @ViewChild(MatStepper) stepper: MatStepper;

  constructor(
    private common: CommonService,
    private fb: FormBuilder,
    private crmService: CrmService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.cloneProjectForm = this.fb.group({
      idProject: [null, [Validators.required]],
    });
    this.contactsForm = this.fb.group({
      competitors: [null],
      completed: [null, [Validators.required]],
      contacts: this.fb.array([]),
      deals: [null],
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params: Params) => {
        this.idProject = parseInt(params.get('idProject'));
        this.getFollowedCustomers();
        this.getClonableByProject();
      })
    );
    this.subscriptions.add(
      this.translate.get('CRM_DETAIL').subscribe((translations: any) => {
        this.translations = translations;
        this.projectTableColumnsName = [
          { value: 'businessName', name: this.translations?.BusinessName },
          { value: 'email', name: this.translations?.Email },
          { value: 'code', name: this.translations?.Code },
          { value: 'projectStatusCode', name: this.translations?.Version }
        ];
        this.contactTableColumnsName = [
          { value: 'businessName', name: this.translations?.BusinessName },
          { value: 'email', name: this.translations?.Email },
          { value: 'country', name: this.translations?.Country },
          { value: 'telephoneNumber', name: this.translations?.Telephone }
        ];
        this.contactParentTableColumnsName = [
          { value: 'businessName', name: this.translations?.BusinessName },
          { value: 'email', name: this.translations?.Email },
          { value: 'country', name: this.translations?.Country },
          { value: 'telephoneNumber', name: this.translations?.Telephone }
        ];
        this.importParentTableColumnsName[0].name = this.translations?.BusinessName;
        this.importParentTableColumnsName[1].name = this.translations?.Email;
        this.importParentTableColumnsName[2].name = this.translations?.Country;
        this.importParentTableColumnsName[3].name = this.translations?.Telephone;
      })
    );
  }

  getFollowedCustomers(): void {
    this.projectTableLoader = true;
    this.subscriptions.add(
      this.crmService.getFollowedCustomers(true).subscribe({
        next: (projects: IProjectCustomer[]) => {
          this.projectTableDataSource.data = projects.map(fromProjectCustomerToDataForTable).filter((element) => {
            return element.idProject !== this.idProject; 
          });
          this.projectTableLoader = false;
        },
        error: () => {
          this.projectTableLoader = false;
        }
      })
    );
  }

  getClonableByProject(): void {
    this.tableLoader = true;
    this.subscriptions.add(
      this.crmService.getClonableByProject(this.idProject, true).subscribe({
        next: (clonable: IClonable) => {
          if (clonable?.contacts && clonable?.contacts.length > 0) {
            this.contactTableDataSource.data = clonable.contacts.map(fromOtherContactToDataForTable);
            this.tableLoader = false;
          } else {
            this.common.showToast(
              this.translations.NoContactError,
              ToastStatus.error,
              3000
            );
          }
        },
        error: () => {
          this.tableLoader = false;
          this.common.showToast(
            this.translations.ShowGeneralErrorToast,
            ToastStatus.error,
            3000
          );
        }
      })
    );
  }

  getParentClonableByProject(idProject: number): void {
    this.contactParentTableDataSource.data = [];
    this.parentTableLoader = true;
    this.subscriptions.add(
      this.crmService.getClonableByProject(idProject, true).subscribe({
        next: (clonable: IClonable) => {
          if (clonable?.contacts && clonable?.contacts.length > 0) {
            this.contactParentTableDataSource.data = clonable.contacts.map(fromOtherContactToDataForTable);
            this.parentTableLoader = false;
          }
        },
        complete: () => {
          this.cloneProjectForm.get('idProject').setValue(idProject);
          this.cloneProjectForm.get('idProject').updateValueAndValidity();
          this.stepper.next();
        },
        error: () => {
          this.parentTableLoader = false;
          this.common.showToast(
            this.translations.ShowGeneralErrorToast,
            ToastStatus.error,
            3000
          );
        }
      })
    );
  }

  get contacts(): FormArray {
    return this.contactsForm.get('contacts') as FormArray;
  }

  addContactToClone(event: any, contact: IOtherContactForTable): void {
    if (event.checked) {
      this.contacts.push(this.fb.group({
        idContact: [contact?.idContact || null, Validators.required],
        businessName: [contact?.businessName || null],
        contactMainReference: this.fb.group({
          tel: [contact?.telephoneNumber || null]
        }),
        email: [contact?.email || null],
        country: this.fb.group({
          idCountry: [contact?.idCountry || null],
          country: [contact?.country || null]
        })
      }));
    } else {
      this.contacts.getRawValue().forEach((element, i) => {
        if (contact.idContact === element.idContact) {
          this.contacts.removeAt(i);
        }
      });
    }
    if (this.contacts.getRawValue().length > 0) {
      this.contactsForm.get('completed').setValue(true);
      this.contactsForm.get('completed').updateValueAndValidity();
    } else {
      this.contactsForm.get('completed').setValue(null);
      this.contactsForm.get('completed').updateValueAndValidity();
    }
    this.importTableDataSource.data = this.contacts.getRawValue().map(fromOtherContactToDataForTable)
  }

  checkContacts(): void {
    if (this.contacts.getRawValue().length > 0) {
      this.stepper.next();
    } else {
      this.common.showToast(
        this.translations.ContactsError,
        ToastStatus.error,
        3000
      );
    }
  }

  selectionChange(event: any): void {
    if (event.selectedIndex === 0) {
      this.cloneProjectForm.get('idProject').setValue(null);
      this.cloneProjectForm.get('idProject').updateValueAndValidity();
      this.contactsForm.get('completed').setValue(null);
      this.contactsForm.get('completed').updateValueAndValidity();
      this.contacts.clear();
      this.isStepCompleted = false;
      this.getClonableByProject();
    }
    if (event.selectedIndex === 1) {
      this.isStepCompleted = false;
    }
  }

  checkDisabled(contact): boolean {
    let disabled: boolean = false;
    this.contactParentTableDataSource.data.forEach((element) => {
      if (contact.businessName === element.businessName && contact.country === element.country) {
        disabled = true;
      }
    });
    return disabled;
  }

  checkSelected(contact): boolean {
    let selected: boolean = false;
    this.contacts.getRawValue().forEach((element) => {
      if (contact.businessName === element.businessName) {
        selected = true;
      }
    });
    return selected;
  }

  onSubmit(): void {
    const contacts = this.contactsForm.getRawValue();
    this.enableProgresBar = true;
    setTimeout(() => {
      this.subscriptions.add(
        this.crmService.cloneToProject(this.cloneProjectForm.get('idProject').value, contacts, true).subscribe({
          complete: () => {
            this.enableProgresBar = false;
            this.router.navigate([ `/crm` ]);
          },
          error: () => {
            this.enableProgresBar = false;
            this.common.showToast(
              this.translations.ShowGeneralErrorToast,
              ToastStatus.error,
              3000
            );
          }
        })
      );
    }, 2000);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
