import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { APP_CONTEX } from 'src/app/config';
import { ToastStatus } from 'src/app/models/utility';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CommonService } from 'src/app/services/common/common.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  subscriptions: Subscription = new Subscription();
  loginForm: FormGroup;
  spinner: boolean = false;
  hide: boolean = true;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private common: CommonService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.loginForm = this.fb.group({
      username: [null, [/*Validators.email,*/ Validators.required]],
      password: [null, [Validators.minLength(8), Validators.required]]
    });

  }

  onSubmit(): void {
    const body = {
      username: this.loginForm.get('username')?.value?.trim(),
      password: this.loginForm.get('password')?.value
    };

    this.subscriptions.add(this.authService.login(body).subscribe({
      complete: () => this.router.navigate([`${APP_CONTEX}/dashboard`])
    }));
  }

  getErrorMessageEmail(): string {
    if (this.loginForm.controls.username.hasError('required')) {
      return 'Inserisci l\'email';
    }
    return this.loginForm.controls.username.hasError('email') ? 'Email non valida' : '';
  }

  getErrorMessagePassword(): string {
    if (this.loginForm.controls.password.hasError('required')) {
      return 'Inserisci la password';
    }
    if (this.loginForm.controls.password.hasError('minlength')) {
      return 'Password troppo corta';
    }
    return '';
  }

  resetPassword(): void {
    if (this.loginForm.get('username').value) {
      if (!this.loginForm.get('username').value.includes('@')) {
        this.common.showToast(
          this.translate.instant('COMMON.InsertValidEmail'),
          ToastStatus.warning,
          3000
        );
      } else {
        this.common.showToast(
          this.translate.instant('COMMON.EmailSentForPasswordReset'),
          ToastStatus.success,
          3000
        );
      }
    } else {
      this.common.showToast(
        this.translate.instant('COMMON.InsertEmail'),
        ToastStatus.warning,
        3000
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
