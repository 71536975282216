<div class="center-header">
  <div class="title">
    <span>{{ title }}</span>
  </div>
</div>
<div class="content">
  <p [ngClass]="messageClass">{{ message }}</p>
  <p *ngIf="secondMessage" [ngClass]="secondMessageClass">{{ secondMessage }}</p>
</div>
<div class="buttons center">
  <button
    *ngIf="buttonFalse"
    type="button"
    class="rounded-button error-bg-button mr-20"
    mat-dialog-close
  >
    {{ buttonFalse || 'COMMON.No' | translate }}
  </button>
  <button
    *ngIf="buttonTrue"
    type="button"
    class="rounded-button primary-bg-button"
    [mat-dialog-close]="true"
  >
    {{ buttonTrue || 'COMMON.Yes' | translate }}
  </button>
  <button
    *ngIf="!buttonTrue && !buttonFalse"
    type="button"
    class="rounded-button primary-bg-button"
    mat-dialog-close
  >
    Ok
  </button>
</div>