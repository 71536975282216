import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BackEndResponse, ToastStatus } from 'src/app/models/utility';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { timeInterval, timeout } from 'rxjs/operators';
import { Router } from '@angular/router';
import Config, { apiContext } from 'src/app/config';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService implements ErrorHandler {

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private router: Router
  ) { }

  handleError(catchedErr: any): void {
    try {
      if (catchedErr instanceof HttpErrorResponse) {
        const error: BackEndResponse = catchedErr.error?.errors || null;
        let messageKey = !error ? 'API_RESPONSE.NoMessage' : error.message ? `API_RESPONSE.${error.message}` : `API_RESPONSE.${error.details}`;
        // if (catchedErr.error.path === `${environment.API_CONTEXT}/account/access_token`) { messageKey = 'API_RESPONSE.US000'; }
        if (catchedErr.error.path === `${apiContext}/account/access_token`) { messageKey = 'API_RESPONSE.US000'; }
        if (catchedErr?.url?.includes('/productmatching') && catchedErr.status === 409) { messageKey = error?.details; }
        // if (catchedErr?.url?.includes(`${environment.API_CONTEXT}/account/refresh_token`)) {
        if (catchedErr?.url?.includes(`${apiContext}/account/refresh_token`)) {
          messageKey = 'API_RESPONSE.US009';
          this.authService.logout();
        }

        if (catchedErr?.url?.includes('/project') && catchedErr.status === 403) {
          messageKey = 'API_RESPONSE.NotAuthorized';
          this.router.navigate(['/']);
        }
        if (catchedErr.status === 0) {
          messageKey = 'API_RESPONSE.BEDown';
          this.authService.logout();
        }
        this.layoutService.triggerToastChangedObservable({
          messageKey,
          status: ToastStatus.error,
          duration: 5000,
        });
        console.error('This is an HTTP error instance');
        console.error(catchedErr);
      }
      if (catchedErr instanceof ErrorEvent) {
        /**
         * @TODO :Need to implement a client side error handler
         */
        console.error('This is an error event instance');
        console.error(catchedErr);
      }
      if (catchedErr instanceof Object) {
        console.error('This is an error event instance');
        console.error(catchedErr);
      }
    } catch (err) {
      console.error(err);
    }
  }
}

export const ErrorHandlerProvider = [{
  provide: ErrorHandler,
  useClass: ErrorHandlerService
}];
