<div class="row" style="min-height: 800px">
  <div class="col-9">
    <div class="section-card" style="height: 100%">
      <div class="content">
        <div class="search-text" *ngIf="!enableSearchText && !loading">
          <span>Effettua una ricerca per visualizzare i risultati.</span>
          <br />
        </div>
        <div class="search-text" *ngIf="!enableSearchText && loading">
          <span>Ricerca...</span>
          <br />
        </div>
        <div class="search-text" *ngIf="enableSearchText">
          <span>
            Per il prodotto
            <span class="font-weight-bold">{{ productInput }}</span> sono stati
            trovati
            <span class="font-weight-bold">{{
              productInformation.nCustomers
            }}</span>
            potenziali
            <span>{{
              searchProductForm.get("idProductType").value === 2
                ? ("SEARCH_PRODUCT.Customers" | translate)
                : ("SEARCH_PRODUCT.Suppliers" | translate)
            }}</span
            >, con una quantità annuale di
            <span class="font-weight-bold">{{ totQuantityYear || 0 }}</span> per
            un valore annuo di
            <span class="font-weight-bold">€ {{ totValueYear || 0 }}</span
            >.
          </span>
          <br />
        </div>
        <table
          *ngIf="!loading && productDetailsData?.length"
          class="test-table"
        >
          <thead>
            <tr>
              <th>
                <span>{{ "COMMON.Product" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.BusinessName" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.BuyerReferent" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.Type" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.DLManager" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.QuantityValueYear" | translate }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let customer of productDetailsData">
              <td>
                <div>
                  <span class="fw-700">
                    {{ customer?.name }}
                  </span>
                </div>
                <div>
                  <span>
                    {{ customer?.description }}
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ customer?.businessName }}</span>
                </div>
                <div>
                  <span>{{ customer?.country }}</span>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ customer?.buyerReferenceTitle }}</span>
                </div>
                <div class="mb-10">
                  <span>{{ customer?.buyerReference }}</span>
                </div>
                <!-- @TODO - check if buyer fields are ok -->
                <div>
                  <span>{{ customer?.telephone }}</span>
                </div>
                <div>
                  <span>{{ customer?.email }}</span>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ customer?.type }}</span>
                </div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div>
                  <span>{{ customer?.quantityYear }}</span> /
                  <span>{{ customer?.valueYear }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="loading" class="skeleton-loader">
          <div class="header"></div>
          <div class="body-row"></div>
          <div class="body-row"></div>
          <div class="body-row"></div>
        </div>
        <div *ngIf="!productDetailsData?.length && !loading" class="no-data">
          <span>{{
            noDataMessage || ("COMMON.NoDataAvailable" | translate)
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="section-card" style="height: 100%">
      <div class="header multi">
        <div class="title">
          <span>{{ "COMMON.Filters" | translate }}</span>
        </div>
        <div class="text-button">
          <span class="primary-color" (click)="resetFilters()">
            {{ "COMMON.Reset" | translate | lowercase }}
          </span>
        </div>
      </div>
      <div class="content">
        <form [formGroup]="searchProductForm" (ngSubmit)="searchProduct()">
          <div class="row" style="flex-direction: column">
            <mat-form-field appearance="standard" class="col">
              <mat-label for="product">{{
                "COMMON.SearchProduct" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                autocomplete="off"
                formControlName="product"
                name="product"
                [attr.disabled]="loading ? 'disabled' : null"
                (keyup)="__checkString()"
              />
              <button
                class="only-icon-button search-code primary"
                type="submit"
                [disabled]="!searchProductForm.get('product').value || loading"
              >
                <svg-icon name="search_icon" class="w20"></svg-icon>
              </button>
            </mat-form-field>
            <mat-error
              class="name-too-short-error"
              *ngIf="searchProductForm.get('product').invalid || !productValid"
            >
              {{ productError | getErrorMessage: translations.Field }}
            </mat-error>
          </div>
          <div class="row">
            <mat-form-field appearance="standard" class="col">
              <mat-label for="idProductType">
                {{ "IR_FORM.Typology" | translate }}
              </mat-label>
              <mat-select
                #typologyFilter
                (selectionChange)="searchProduct()"
                formControlName="idProductType"
                name="idProductType"
              >
                <mat-option value="all">
                  {{ "COMMON.All" | translate }}
                </mat-option>
                <mat-option [value]="2">
                  {{ "SEARCH_PRODUCT.Bought" | translate }}
                </mat-option>
                <mat-option [value]="1">
                  {{ "SEARCH_PRODUCT.Sold" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="row">
            <mat-form-field appearance="standard" class="col-12">
              <mat-label for="idCommerceSector">
                {{ "IR_FORM.CommerceSector" | translate }}
              </mat-label>
              <mat-select
                formControlName="idCommerceSector"
                name="idCommerceSector"
              >
                <mat-option value="all">
                  {{ "COMMON.All" | translate }}
                </mat-option>
                <mat-option
                  *ngFor="let sector of commerceSectors"
                  [value]="sector.idCommerceSector"
                >
                  {{ sector.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- OLD CODE -->

<div class="row" style="min-height: 800px">
  <div class="col-9">
    <div class="section-card" style="height: 100%">
      <div class="content">
        <div class="search-text" *ngIf="!enableSearchText && !loading">
          <span>Effettua una ricerca per visualizzare i risultati.</span>
          <br />
        </div>
        <div class="search-text" *ngIf="!enableSearchText && loading">
          <span>Ricerca...</span>
          <br />
        </div>
        <div class="search-text" *ngIf="enableSearchText">
          <span>
            Per il prodotto
            <span class="font-weight-bold">{{ productInput }}</span> sono stati
            trovati
            <span class="font-weight-bold">{{
              productInformation.nCustomers
            }}</span>
            potenziali
            <span>{{
              searchProductForm.get("idProductType").value === 2
                ? ("SEARCH_PRODUCT.Customers" | translate)
                : ("SEARCH_PRODUCT.Suppliers" | translate)
            }}</span
            >, con una quantità annuale di
            <span class="font-weight-bold">{{ totQuantityYear || 0 }}</span> per
            un valore annuo di
            <span class="font-weight-bold">€ {{ totValueYear || 0 }}</span
            >.
          </span>
          <br />
        </div>
        <table
          *ngIf="!loading && productDetailsData?.length"
          class="test-table"
        >
          <thead>
            <tr>
              <th>
                <span>{{ "COMMON.Product" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.BusinessName" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.BuyerReferent" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.Type" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.DLManager" | translate }}</span>
              </th>
              <th>
                <span>{{ "COMMON.QuantityValueYear" | translate }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let customer of productDetailsData">
              <td>
                <div>
                  <span class="fw-700">
                    {{ customer?.name }}
                  </span>
                </div>
                <div>
                  <span>
                    {{ customer?.description }}
                  </span>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ customer?.businessName }}</span>
                </div>
                <div>
                  <span>{{ customer?.country }}</span>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ customer?.buyerReferenceTitle }}</span>
                </div>
                <div class="mb-10">
                  <span>{{ customer?.buyerReference }}</span>
                </div>
                <!-- @TODO - check if buyer fields are ok -->
                <div>
                  <span>{{ customer?.telephone }}</span>
                </div>
                <div>
                  <span>{{ customer?.email }}</span>
                </div>
              </td>
              <td>
                <div>
                  <span>{{ customer?.type }}</span>
                </div>
              </td>
              <td>
                <div></div>
              </td>
              <td>
                <div>
                  <span>{{ customer?.quantityYear }}</span> /
                  <span>{{ customer?.valueYear }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="loading" class="skeleton-loader">
          <div class="header"></div>
          <div class="body-row"></div>
          <div class="body-row"></div>
          <div class="body-row"></div>
        </div>
        <div *ngIf="!productDetailsData?.length && !loading" class="no-data">
          <span>{{
            noDataMessage || ("COMMON.NoDataAvailable" | translate)
          }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-3">
    <div class="section-card" style="height: 100%">
      <div class="header multi">
        <div class="title">
          <span>{{ "COMMON.Filters" | translate }}</span>
        </div>
        <div class="text-button">
          <span class="primary-color" (click)="resetFilters()">
            {{ "COMMON.Reset" | translate | lowercase }}
          </span>
        </div>
      </div>
      <div class="content">
        <form [formGroup]="searchProductForm" (ngSubmit)="searchProduct()">
          <div class="row" style="flex-direction: column">
            <mat-form-field appearance="standard" class="col">
              <mat-label for="product">{{
                "COMMON.SearchProduct" | translate
              }}</mat-label>
              <input
                matInput
                type="text"
                autocomplete="off"
                formControlName="product"
                name="product"
                [attr.disabled]="loading ? 'disabled' : null"
                (keyup)="__checkString()"
              />
              <button
                class="only-icon-button search-code primary"
                type="submit"
                [disabled]="!searchProductForm.get('product').value || loading"
              >
                <svg-icon name="search_icon" class="w20"></svg-icon>
                <!-- <img src="/assets/icons/search_icon.svg" /> -->
              </button>
            </mat-form-field>
            <mat-error
              class="name-too-short-error"
              *ngIf="searchProductForm.get('product').invalid || !productValid"
            >
              {{ productError | getErrorMessage: translations.Field }}
            </mat-error>
          </div>
          <div class="row">
            <mat-form-field appearance="standard" class="col">
              <mat-label for="idProductType">{{
                "IR_FORM.Typology" | translate
              }}</mat-label>
              <mat-select
                #typologyFilter
                (selectionChange)="searchProduct()"
                formControlName="idProductType"
                name="idProductType"
              >
                <mat-option value="all">
                  {{ "COMMON.All" | translate }}
                </mat-option>
                <mat-option [value]="2">
                  {{ "SEARCH_PRODUCT.Bought" | translate }}
                </mat-option>
                <mat-option [value]="1">
                  {{ "SEARCH_PRODUCT.Sold" | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- <div class="wrap-1200">
  <div class="search-title text-color font-weight-bold">
    <span>{{ 'COMMON.SearchProductTitle' | translate }}</span>
  </div>
  <div class="search-bar">
    <div>
      <form [formGroup]="searchProductForm" (ngSubmit)="searchProduct()">
        <input
          class="search-bar-input"
          type="text"
          [placeholder]="'COMMON.SearchProduct' | translate"
          name="product"
          formControlName="product"
          [attr.disabled]="loading ? 'disabled' : null"
          (keyup)="__checkString()"
        >
        <button
          color="primary"
          class="search-bar-button"
          mat-icon-button
          [disabled]="!searchProductForm.get('product').value || loading"
          type="submit"
        >
          <mat-icon *ngIf="!loading">search</mat-icon>
          <mat-spinner class="spinner-app loading-spinner" diameter="20" *ngIf="loading"></mat-spinner>
        </button>
        <mat-error class="name-too-short-error" *ngIf="searchProductForm.get('product').invalid || !productValid">
          {{ productError | getErrorMessage:translations.Field }}
        </mat-error>
        <mat-radio-group class="product-type-container" formControlName="idProductType" name="idProductType" (change)="resetPage($event)">
          <mat-radio-button class="product-type-radio" [value]="2" labelPosition="before">
            {{ 'SEARCH_PRODUCT.Bought' | translate }}
          </mat-radio-button>
          <mat-radio-button class="product-type-radio" [value]="1" labelPosition="before">
            {{ 'SEARCH_PRODUCT.Sold' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </div>
  </div>
  <div class="search-title text-color font-weight-bold" *ngIf="enableSearchText">
    <span>{{ 'COMMON.SearchProductText' | translate }}</span>
  </div>
  <div class="search-text" *ngIf="enableSearchText">
    <span>
      Per il prodotto <span class="font-weight-bold">{{productInput}}</span>
      sono stati trovati <span class="font-weight-bold">{{productInformation.nCustomers}}</span>
      potenziali <span>{{ searchProductForm.get('idProductType').value === 2 ? ('SEARCH_PRODUCT.Customers' | translate) : ('SEARCH_PRODUCT.Suppliers' | translate) }}</span>,
      con una quantità annuale di <span class="font-weight-bold">{{productInformation.totQuantityYear || 0 }}</span>
      per un valore annuo di <span class="font-weight-bold">€ {{totValueYear}}</span>.
    </span>
    <br>
    <span *ngIf="thereIsCustomers">
      Per avere il dettaglio dei
      <span>{{ searchProductForm.get('idProductType').value === 2 ? ('SEARCH_PRODUCT.Customers' | translate) : ('SEARCH_PRODUCT.Suppliers' | translate) }}</span>
      clicca
    </span>
    <span *ngIf="thereIsCustomers" class="clickable-span" (click)="searchProductsDetails()">
      {{ 'COMMON.Here' | translate }}
    </span>
  </div>
  <div class="search-title text-color font-weight-bold" *ngIf="enableSearchTable">
    <span>
      {{ searchProductForm.get('idProductType').value === 2 ? ('SEARCH_PRODUCT.CustomersDetail' | translate) : ('SEARCH_PRODUCT.SuppliersDetail' | translate) }}
    </span>
  </div>
  <div class="table-container" *ngIf="enableSearchTable">
    <app-search-product-table
      [translations]="translations"
      [productDetails]="productDetails"
      [loader]="loader"
    ></app-search-product-table>
  </div>
</div> -->
