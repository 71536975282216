import { Directive, ElementRef, Input, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[matBadgeIcon]'
})
export class MatBadgeIconDirective {

  @Input() matBadgeIcon: string;
  @Input() matBadgeHiddenDirective: string;

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    const badge = this.el.nativeElement.querySelector('.mat-badge-content');
    badge.style.display = this.matBadgeHiddenDirective ? 'none' : 'flex';
    badge.style.alignItems = 'center';
    badge.style.justifyContent = 'center';
    badge.innerHTML = `<i class="material-icons" style="font-size: 15px">${changes.matBadgeIcon.currentValue}</i>`;
  }
}