import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { DisableDependentControl, MustMatch } from 'src/app/utility/validators';
import { IProvince, LoggedUser } from 'src/app/models/user';
import { IrFormService } from '../../services/ir-form/ir-form.service';
import { ICustomer } from '../../models/customer';
import { Roles } from '../../config';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { UsersService } from 'src/app/services/users/users.service';
import { CommonService } from 'src/app/services/common/common.service';
import { TranslateService } from '@ngx-translate/core';
import { INominatimCoordinates, ToastStatus } from 'src/app/models/utility';
import { PositionDialogComponent } from '../position-dialog/position-dialog.component';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';

@Component({
  selector: 'app-user-managment-form',
  templateUrl: './user-managment-form.component.html',
  styleUrls: ['./user-managment-form.component.scss'],
})
export class UserManagmentFormComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  translations: any;

  user: LoggedUser;
  loading: boolean = false;

  usersFormGroup: FormGroup;
  roles: any[];
  provinceList: IProvince[];
  mappedProvinceList: number[] = [];

  customerList: ICustomer[] = [];
  showDemonstratorCheckbox: boolean = false;

  previousRolesUser: string[] = [];

  @Output() refreshUsers: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('competenceAreaSelect') competenceAreaSelect: MatSelect;

  constructor(
    private fb: FormBuilder,
    private irFormService: IrFormService,
    private usersService: UsersService,
    private dialogRef: MatDialogRef<UserManagmentFormComponent>,
    private common: CommonService,
    private translate: TranslateService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.user = data.user;
    this.roles = data.roles;
    this.provinceList = data.provinces;
    this.usersFormGroup = this.fb.group(
      {
        idUser: [null],
        name: [null, Validators.required],
        surname: [null, Validators.required],
        address: [
          { value: null, disabled: true },
          [Validators.required, Validators.maxLength(255)],
        ],
        cap: [
          null,
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
            Validators.pattern('[0-9]+'),
          ],
        ],
        city: [null, Validators.required],
        province: [null, Validators.required],
        residencePosition: [[], Validators.required],
        telephone: [null],
        email: [null, [Validators.required, Validators.email]],
        confirmEmail: [
          { value: null, disabled: true },
          [Validators.required, MustMatch('email')],
        ],
        roles: [null, Validators.required],
        expertiseArea: [null, Validators.required],
        idCustomer: [null],
        demonstrator: [false],
      },
      { validator: DisableDependentControl('email', 'confirmEmail') }
    );
  }

  get rolesFromForm(): string[] {
    return this.usersFormGroup.get('roles')?.value || [];
  }

  ngOnInit(): void {
    this.mappedProvinceList = this.provinceList.map(
      (province: IProvince): number => province.idProvince
    );
    this.subscriptions.add(
      this.translate.get('USERS_MANAGEMENT').subscribe({
        next: (data: any) => {
          this.translations = data;
        },
      })
    );
    if (this.user) {
      this.usersFormGroup.patchValue(this.user);
      /** Enable address field if city has value */
      if (
        this.usersFormGroup.get('city').value !== '' &&
        this.usersFormGroup.get('city').value !== null
      ) {
        this.usersFormGroup.get('address').enable();
      }
      this.usersFormGroup.get('confirmEmail').setValue(this.user.email);
      this.usersFormGroup.get('confirmEmail').updateValueAndValidity();
      if (this.usersFormGroup.get('roles').value.includes(Roles.LabManager)) {
        this.showDemonstratorCheckbox = true;
      }
    }
  }

  get controls(): { [key: string]: AbstractControl } {
    return this.usersFormGroup.controls;
  }

  saveUser(): void {
    if (this.usersFormGroup.valid && this.usersFormGroup.dirty) {
      this.usersFormGroup.disable();
      this.loading = true;
      const user: any = this.usersFormGroup.getRawValue();
      if (!user.idUser) {
        user.roles = user.roles.join(',');
        this.usersService.signUpUser(user).subscribe({
          complete: () => {
            this.dialogRef.close('refreshUsers');
            this.loading = false;
            this.usersFormGroup.enable();
          },
          error: () => {
            this.loading = false;
            this.usersFormGroup.enable();
          },
        });
      } else {
        this.usersService.updateUser(user).subscribe({
          complete: () => {
            this.dialogRef.close('refreshUsers');
            this.loading = false;
            this.usersFormGroup.enable();
          },
          error: () => {
            this.loading = false;
            this.usersFormGroup.enable();
          },
        });
      }
    }
    if (this.usersFormGroup.valid && !this.usersFormGroup.dirty) {
      this.dialogRef.close();
    }
  }

  setPreviousRoles(): void {
    this.previousRolesUser = this.usersFormGroup.get('roles').value;
  }

  onRoleChange(event): void {
    if (event.value?.includes(Roles.Customer)) {
      if (!this.customerList.length) {
        this.loading = true;
        this.usersFormGroup.disable();
        this.subscriptions.add(
          this.irFormService.getAllCustomer().subscribe({
            next: (customers: ICustomer[]): void => {
              this.customerList = customers;
              if (!this.customerList?.length) {
                this.common.showToast(
                  this.translate.instant(
                    'USERS_MANAGEMENT.NoCustomersAvailable'
                  ),
                  ToastStatus.warning,
                  3000
                );
              }
            },
            complete: () => {
              this.usersFormGroup.enable();
              this.loading = false;
            },
            error: () => {
              this.usersFormGroup.enable();
              this.loading = false;
            },
          })
        );
      }
      this.usersFormGroup.get('idCustomer').setValidators(Validators.required);
      this.usersFormGroup.get('expertiseArea').setValue(null);
      this.usersFormGroup.get('expertiseArea').setValidators(null);
      this.usersFormGroup.get('expertiseArea').updateValueAndValidity();
    } else {
      if (this.usersFormGroup.get('idUser').value) {
        let roleToRemove: string = '';
        if (
          this.previousRolesUser.includes(Roles.Agent) &&
          !this.usersFormGroup.get('roles').value.includes(Roles.Agent)
        ) {
          roleToRemove = Roles.Agent;
        }
        if (
          this.previousRolesUser.includes(Roles.AgentSupervisor) &&
          !this.usersFormGroup
            .get('roles')
            .value.includes(Roles.AgentSupervisor)
        ) {
          roleToRemove = Roles.AgentSupervisor;
        }
        if (
          this.previousRolesUser.includes(Roles.LabManagerInternal) &&
          !this.usersFormGroup
            .get('roles')
            .value.includes(Roles.LabManagerInternal)
        ) {
          roleToRemove = Roles.LabManagerInternal;
        }
        if (
          this.previousRolesUser.includes(Roles.LabManager) &&
          !this.usersFormGroup.get('roles').value.includes(Roles.LabManager)
        ) {
          roleToRemove = Roles.LabManager;
        }
        if (
          this.previousRolesUser.includes(Roles.LabManagerSupervisor) &&
          !this.usersFormGroup
            .get('roles')
            .value.includes(Roles.LabManagerSupervisor)
        ) {
          roleToRemove = Roles.LabManagerSupervisor;
        }
        if (roleToRemove) {
          this.usersService
            .checkRoleToRemove(
              this.usersFormGroup.get('idUser').value,
              roleToRemove
            )
            .subscribe({
              next: (response: boolean) => {
                if (response === false) {
                  this.common.showToast(
                    this.translate.instant('COMMON.RoleCheckError'),
                    ToastStatus.error,
                    8000
                  );
                  this.usersFormGroup
                    .get('roles')
                    .setValue([
                      ...this.usersFormGroup.get('roles').value,
                      roleToRemove,
                    ]);
                }
              },
            });
        }
      }
      // this.customerList = [];
      this.usersFormGroup.get('idCustomer').setValue(null);
      this.usersFormGroup.get('idCustomer').setValidators(null);
      this.usersFormGroup.get('idCustomer').updateValueAndValidity();
      this.usersFormGroup
        .get('expertiseArea')
        .setValidators(Validators.required);
      this.usersFormGroup.get('expertiseArea').updateValueAndValidity();
      const selectedRoles: string[] = this.usersFormGroup.get('roles').value;
      if (selectedRoles && !selectedRoles.includes(Roles.Customer)) {
        // this.customerList = [];
        this.usersFormGroup.get('idCustomer').setValue(null);
        this.usersFormGroup.get('idCustomer').setValidators(null);
        this.usersFormGroup.get('idCustomer').updateValueAndValidity();
      }
      if (selectedRoles.includes(Roles.LabManager)) {
        this.showDemonstratorCheckbox = true;
      } else {
        this.showDemonstratorCheckbox = false;
      }
    }
  }

  checkIfDisableRolesSelection(value: string): boolean {
    if (this.rolesFromForm.includes(Roles.Admin)) {
      return !(value === Roles.Admin);
    }
    if (this.rolesFromForm.includes(Roles.Direction)) {
      return !(value === Roles.Direction);
    }
    if (this.rolesFromForm.includes(Roles.Customer)) {
      return !(value === Roles.Customer);
    }
    if (this.rolesFromForm.includes(Roles.CallCenter)) {
      return !(value === Roles.CallCenter);
    }
    if (
      this.rolesFromForm.includes(Roles.Agent) ||
      this.rolesFromForm.includes(Roles.AgentSupervisor)
    ) {
      return !(value === Roles.Agent || value === Roles.AgentSupervisor);
    }
    if (
      this.rolesFromForm.includes(Roles.LabManager) ||
      this.rolesFromForm.includes(Roles.LabManagerSupervisor)
    ) {
      return !(
        value === Roles.LabManager || value === Roles.LabManagerSupervisor
      );
    }
    if (this.rolesFromForm.includes(Roles.LabManagerInternal)) {
      return !(value === Roles.LabManagerInternal);
    }
  }

  checkCity(): void {
    if (this.usersFormGroup.get('city').value !== '') {
      this.usersFormGroup.get('address').enable();
    } else {
      this.usersFormGroup.get('address').disable();
      this.usersFormGroup.get('address').setValue(null);
      this.usersFormGroup.get('residencePosition').setValue([]);
    }
  }

  checkAndSetCoordinatesAutomatically(): void {
    if (this.usersFormGroup.get('address').value !== '') {
      if (this.usersFormGroup.get('address').dirty) {
        this.usersFormGroup.get('residencePosition').setValue([]);
        this.common
          .getAddressFromCoordinates(
            `${this.usersFormGroup.get('address').value} ${
              this.usersFormGroup.get('city').value
            } ${this.usersFormGroup.get('cap').value}`
          )
          .subscribe({
            next: (resp: INominatimCoordinates[]) => {
              this.usersFormGroup.get('address').markAsPristine();
              if (resp.length) {
                this.usersFormGroup
                  .get('residencePosition')
                  .setValue([resp[0].lon, resp[0].lat]);
              }
            },
            complete: () => {
              this.usersFormGroup.get('residencePosition').markAsDirty();
            },
          });
      }
    }
  }

  openPositionDialog(): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      address: this.usersFormGroup.get('address').value,
      position: this.usersFormGroup.get('residencePosition').value,
      // alternativePositionCity: this.alternativePositionCity,
    };
    const dialogRef = this.dialog.open(PositionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((position: number[]) => {
      if (position) {
        if (
          JSON.stringify(position) !==
          JSON.stringify(this.usersFormGroup.get('residencePosition').value)
        ) {
          this.usersFormGroup.get('residencePosition').setValue(position);
          this.usersFormGroup.markAsDirty();
        }
      }
    });
  }

  selectDeselectAllCompetenceArea(): void {
    if (
      JSON.stringify(this.usersFormGroup.get('expertiseArea').value) ===
      JSON.stringify(this.mappedProvinceList)
    ) {
      this.usersFormGroup.get('expertiseArea').setValue([]);
      this.competenceAreaSelect.options.forEach((option: MatOption) => {
        option.deselect();
      });
    } else {
      this.usersFormGroup
        .get('expertiseArea')
        .setValue(this.mappedProvinceList);
      this.competenceAreaSelect.options.forEach((option: MatOption) => {
        option.select();
      });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
