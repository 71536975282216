import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxCurrencyModule } from 'ngx-currency';
import { forkJoin, Subject, Subscription } from 'rxjs';
import {
  CHANNELS_TYPE,
  // COMMERCE_SECTORS,
  currencyDefaultConfig,
  DATE_PIKER_FORMATS,
  DEAL_STATE_NAME,
  DEAL_STATE_ID,
  DEAL_STATE_REASON,
  currencyQuantityConfig,
  TITLE_TYPE,
  CONTACT_ORIGIN,
  BUYER_STATUS,
} from 'src/app/config';
import {
  IBuyerStatus,
  IBuyerStatusReason,
  IContact,
  IContactActivity,
  IContactBuyer,
  IContactCompetitor,
  IContactCompetitorForTable,
  IContactOrigin,
  IContactOrigins,
  IDeal,
  IDealForTable,
  IDealState,
  IDealStateReason,
} from 'src/app/models/contact';
import {
  IColumnsName,
  IDataForCrmSelects,
  IFileToHandle,
  INominatimCoordinates,
  ITableButton,
  ITableButtons,
  ITableColumn,
  ITableOptions,
  ToastStatus,
  UnitsOfMeasure,
} from 'src/app/models/utility';
import { CommonService } from 'src/app/services/common/common.service';
import { CrmService } from 'src/app/services/crm/crm.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import { ContactCompetitorsDialogComponent } from 'src/app/components/contact-competitors-dialog/contact-competitors-dialog.component';
import { ContactDealDialogComponent } from 'src/app/components/contact-deal-dialog/contact-deal-dialog.component';
import {
  fromContactCompetitorsToDataForTable,
  fromContactDealToDataForTable,
  fromContactReportsToDataForTable,
} from 'src/app/utility/elaborations';
import {
  IContactReference,
  IReport,
  IReportForTable,
  IReportMatching,
} from 'src/app/models/report';
import { ContactReportsDialogComponent } from 'src/app/components/contact-reports-dialog/contact-reports-dialog.component';
import { LayoutService } from 'src/app/services/layout/layout.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ICustomer } from 'src/app/models/customer';
import { TranslateService } from '@ngx-translate/core';
import { IProjectCustomer } from 'src/app/models/project';
import { ICountries } from 'src/app/models/countries';
import {
  IChannelsType,
  IChannelSpecification,
} from 'src/app/models/channels-type';
import { IReferentRole } from 'src/app/models/referent-role';
import { reduce, takeUntil, timestamp } from 'rxjs/operators';
import {
  MatSlideToggle,
  MatSlideToggleChange,
} from '@angular/material/slide-toggle';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { ISectors } from 'src/app/models/sectors';
import { MatTabGroup } from '@angular/material/tabs';
import { ChannelsManagementDialogComponent } from 'src/app/components/channels-management-dialog/channels-management-dialog.component';
import { PositionDialogComponent } from '../position-dialog/position-dialog.component';
import { CdkAutofill } from '@angular/cdk/text-field';
import { any } from 'codelyzer/util/function';

@Component({
  selector: 'app-crm-contact-detail',
  templateUrl: './crm-contact-detail.component.html',
  styleUrls: ['./crm-contact-detail.component.scss'],
})
export class CrmContactDetailComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  contact: IContact;
  customer: ICustomer;
  followedCustomers: IProjectCustomer[];
  options: any[];
  contactForm: FormGroup;

  @Input() isReadonly: boolean = false;
  @Input() selectedIndex: number = 0;
  @Input() idProject: number;
  translations: any;

  @Output() reloadContactsEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() reloadActivitiesEmitter: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() activitySubmitEmitter: EventEmitter<IContactActivity> =
    new EventEmitter<IContactActivity>();
  @Output() reloadContactDealsEmitter: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() reloadContactCompetitorsEmitter: EventEmitter<any> =
    new EventEmitter<any>();
  @Output() reloadContactReportsEmitter: EventEmitter<any> =
    new EventEmitter<any>();

  editMode: boolean = false;

  activities: IContactActivity[];
  deals: IDealForTable[];
  oldDeals: IDealForTable[];
  reports: IReportForTable[];
  oldReports: IReportForTable[];
  buyerStatus: IBuyerStatus[];
  competitors: IContactCompetitorForTable[];
  dataForSelectReady: boolean = false;
  activitiesLoader: boolean = false;
  savingActivity: boolean = false;

  activityForm: FormGroup;
  editActivityForm: FormGroup;

  countries: ICountries[];
  channels: IChannelsType[];
  specificationsChannels: IChannelSpecification[];
  buyerStatusReason: IBuyerStatusReason[];
  referentRoles: IReferentRole[];
  mainReferentIndex: number = null;
  contactOperationInProgress: boolean = null;
  activityOperationInProgress: boolean = null;
  competitorForm: FormGroup;
  editCompetitorForm: FormGroup;
  dealForm: FormGroup;
  competitorOperationInProgress: boolean = false;
  competitorsTableColumns: ITableColumn[];
  competitorsTableButtons: ITableButton[];
  competitorsLoader: boolean = false;
  dealsOperationInProgress: boolean = false;
  dealsTableColumns: ITableColumn[];
  dealsTableButtons: ITableButton[];
  dealsTableLoader: boolean = false;

  savingReportLoader: boolean = false;
  reportForm: FormGroup;
  reportOperationInProgress: boolean = false;
  reportsTableColumns: ITableColumn[];
  reportsTableButtons: ITableButton[];
  reportsTableLoader: boolean = false;
  unitsOfMeasure: UnitsOfMeasure[] = [];

  reportsFilterSelects: any = {
    commerceSectors: null,
    reportRangeDate: null,
  };

  startDateDealFilter: FormControl = new FormControl();
  endDateDealFilter: FormControl = new FormControl();

  startDateReportFilter: FormControl = new FormControl();
  endDateReportFilter: FormControl = new FormControl();

  commerceSectors: ISectors[] = [];
  dealStatus: string[] = [];
  dealFilterSelects: any = {
    commerceSectors: null,
    dealStatus: null,
    dealRangeDate: null,
  };

  savingDealLoader: boolean = false;
  uploadError: boolean = false;
  uploadedDocument: IFileToHandle;
  uploadingDealDocument: boolean = false;
  disableUploadButton: boolean = true;
  orderPlacedFile: File;

  dealStateReasonEnum: typeof DEAL_STATE_REASON = DEAL_STATE_REASON;
  dealStateNameEnum: typeof DEAL_STATE_NAME = DEAL_STATE_NAME;
  dealStateSelected: number;
  dealStateBefore: number;
  dealStates: any[] = [];
  dealStateReasons: IDealStateReason[];
  dealDocumentUpdated: boolean = false;

  showOpportunityFlag: boolean = false;
  showOpportunityFields: boolean = false;
  mustUploadDocument: boolean = false;
  showDealStateReason: boolean = false;
  firstStepAddDeal: boolean = true;

  showNotDirtyMessage: boolean = false;

  // matTabIndex: number = 0;
  titleType: string[] = [];

  addFromBM: boolean;
  idBM: number;

  alternativePositionCity: number[];

  leadGenContactOrigin: IContactOrigins = {
    tool: [],
    offLineAction: [],
    onLineAction: [],
  };
  // isContactOriginFieldSelect: boolean = true;

  @ViewChild('mandatoryActivityToggle') mandatoryActivityToggle: MatSlideToggle;
  @ViewChild('fgDirective') fgDirective: FormGroupDirective;
  @ViewChild('competitorFormDirective')
  competitorFormDirective: FormGroupDirective;
  @ViewChild('competitorDialog') competitorDialog: TemplateRef<any>;
  @ViewChild('contactDealDialog')
  contactDealDialog: TemplateRef<ContactDealDialogComponent>;
  @ViewChild('contactReportDialog') contactReportDialog: TemplateRef<any>;
  @ViewChild('dealCommerceSectorFilter') dealCommerceSectorFilter: MatSelect;
  @ViewChild('reportCommerceSectorFilter')
  reportCommerceSectorFilter: MatSelect;
  @ViewChild('dealStatusFilter') dealStatusFilter: MatSelect;
  @ViewChild('crmContactDetailTabGroup') crmContactDetailTabGroup: MatTabGroup;
  // @ViewChild('contactOriginInput') contactOriginInput: ElementRef;
  // @ViewChild('contactOriginSelect') contactOriginSelect: MatSelect;
  @ViewChild('activityDialog') activityDialog: TemplateRef<any>;

  currencyDefaultConfig: NgxCurrencyModule = currencyDefaultConfig;
  currencyQuantityConfig: NgxCurrencyModule = currencyQuantityConfig;

  /** filtered variables */
  filteredCountries: ICountries[];

  /** control for the MatSelect filter keyword */
  public entityFilterCtrl: FormControl = new FormControl();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();
  filteredOptions: any[];

  constructor(
    private common: CommonService,
    private router: Router,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private crmService: CrmService,
    private irFormService: IrFormService,
    private layoutService: LayoutService,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.contactForm = this.fb.group({
      idCustomer: [],
      idContact: [],
      idUser: [],
      businessName: [null, [Validators.required, Validators.maxLength(45)]],
      channelType: this.fb.group({
        idChannelType: [null, Validators.required],
      }),
      // channelTypeDesc: [null, Validators.maxLength(255)],
      // channelTypeSpec: [null, [Validators.required, Validators.maxLength(255)]],
      specificationChannelTypeDTO: this.fb.group({
        idSpecificationChannelType: [
          { value: null, disabled: true },
          [Validators.required, Validators.maxLength(255)],
        ],
      }),
      address: [null, Validators.maxLength(255)],
      telephoneNumber: [null, [Validators.required, Validators.maxLength(20)]],
      email: [null, [Validators.required, Validators.maxLength(45)]],
      webSiteLink: [null, [Validators.maxLength(255)]],
      note: [],
      contactOrigin: [null, Validators.required],
      country: this.fb.group({
        idCountry: [null, Validators.required],
      }),
      buyerStatus: this.fb.group({
        idBuyerStatus: [null, Validators.required],
      }),
      buyerStatusReason: this.fb.group({
        idBuyerStatusReason: [null],
      }),
      statusDetails: [null, Validators.maxLength(1024)],
      vip: [false],
      city: [null, Validators.required],
      province: [null, [Validators.maxLength(45)]],
      // ↓ TODO: inserire un regex che permetta i punti per le migliaia ↓
      revenue: [null /*Validators.pattern('^(?!$)\d{0,10}(?:\.\d{1,2})?$')*/],
      contactReference: this.fb.array([]),
      // position: [[]],
    });
    this.activityForm = this.fb.group({
      customerSummaryDTO: this.fb.group({
        idCustomer: [null],
      }),
      dateActivity: [
        null,
        [Validators.required, this.checkDateBeforeToday('new')],
      ],
      timeActivity: [null, [this.checkTimeBeforeNow('new')]],
      feedbackDescription: [null, Validators.required],
      mandatory: [false],
      idContactBuyerStatusChange: [null],
    });
    this.editActivityForm = this.fb.group({
      customerSummaryDTO: this.fb.group({
        idCustomer: [null],
      }),
      dateActivity: [
        null,
        [Validators.required, this.checkDateBeforeToday('edit')],
      ],
      timeActivity: [null, this.checkTimeBeforeNow('edit')],
      feedbackDescription: [null, Validators.required],
      mandatory: [false],
      idContactBuyerStatusChange: [null],
    });
    this.competitorForm = fb.group({
      idCompetitorCompanyCrm: [null],
      companyName: [null, [Validators.required]],
      // valueRevenueYear: [null, Validators.required],
      buyerRevenueYear: [0, Validators.required],
    });
    this.editCompetitorForm = fb.group({
      idCompetitorCompanyCrm: [null],
      companyName: [null, [Validators.required]],
      valueRevenueYear: [{ value: null, disabled: true }, Validators.required],
      buyerRevenueYear: [null, Validators.required],
    });
    this.dealForm = this.fb.group({
      idDeal: [null],
      commerceSector: this.fb.group({
        idCommerceSector: [null, Validators.required],
      }),
      dealState: this.fb.group({
        idDealState: [null, Validators.required],
      }),
      dealStateReason: this.fb.group({
        idDealStateReason: [null],
      }),
      dateDeal: [null, Validators.required],
      quantity: [null, Validators.required],
      revenue: [null, Validators.required],
      note: [null],
      isOpportunity: [null],
      productName: [null],
      italianDescription: [null],
      englishDescription: [null],
    });
    this.reportForm = this.fb.group({
      idOpportunity: [null],
      idDeal: [null],
      contactReference: this.fb.group({
        idContactReference: [null, Validators.required],
      }),
      dateInsert: [null],
      dateModify: [null],
      userInsert: [null],
      userModify: [null],
      productName: [null, Validators.required],
      commerceSector: this.fb.group({
        idCommerceSector: [null, Validators.required],
      }),
      quantity: [null],
      quantityYear: [null],
      unitOfMeasure: this.fb.group({
        idUom: [null, Validators.required],
      }),
      valueYear: [null],
      valueOfUnit: [null],
      productDescription: [null, Validators.required],
      productEnglishDescription: [null, Validators.required],
      reportMatching: fb.array([]),
    });
  }

  get contactBuyers(): FormArray {
    return this.contactForm.get('contactReference') as FormArray;
  }

  get reportMatching(): FormArray {
    return this.reportForm.get('reportMatching') as FormArray;
  }

  ngOnInit(): void {
    // Object.keys(COMMERCE_SECTORS).forEach((sector: any, index: number) => {
    //   this.commerceSectors.push({
    //     name: sector,
    //     idCommerceSector: index + 1
    //   });
    // });

    // this.subscriptions.add(
    //   this.activatedRoute?.queryParams?.subscribe({
    //     next: (queryParams) => {
    //       if (queryParams?.activeTabIndex) {
    //         this.matTabIndex = queryParams?.activeTabIndex;
    //       }
    //     },
    //   })
    // );
    this.subscriptions.add(
      this.activatedRoute?.params?.subscribe({
        next: (params: Params) => {
          this.idProject = parseInt(params?.idProject, 10);
          this.customer = { idCustomer: parseInt(params?.idCustomer, 10) };
          if (
            params?.idContact !== 'add' &&
            !params?.idContact?.includes('addFromBM')
          ) {
            this.editMode = true;
            this.contact = { idContact: parseInt(params?.idContact, 10) };
          }
          if (params?.idContact.includes('addFromBM')) {
            this.addFromBM = true;
            const splitArr: string[] = params?.idContact?.split(':');
            this.idBM = parseInt(splitArr[1], 10);
            this.crmService.getContactStartDataFromBM(this.idBM).subscribe({
              next: (data: IContact) => {
                this.contact = data;
                this.patchContactValue(data);
              },
            });
          }
          this.getCustomer();
        },
      })
    );
    this.subscriptions.add(
      this.translate.get('CRM_DETAIL').subscribe({
        next: (data: any) => {
          this.translations = { ...this.translations, ...data };
        },
      })
    );
    Object.values(DEAL_STATE_NAME).forEach((status: any) => {
      this.dealStatus.push(status);
    });
    Object.keys(TITLE_TYPE).forEach((title: string) => {
      this.titleType.push(title);
    });
    // Initialize all data for selects
    this.subscriptions.add(
      this.crmService.getDataForSelects(this.customer.idCustomer).subscribe({
        next: (data: IDataForCrmSelects) => {
          data?.commerceSectors?.forEach((sector: ISectors) => {
            this.commerceSectors.push({
              name: sector?.name,
              idCommerceSector: sector?.idCommerceSector,
            });
          });
          this.dealStates = data?.dealStates;
          this.dealStateReasons = data?.dealStatesReasons;
          this.unitsOfMeasure = data?.unitsOfMeasure;
          this.countries = data?.countries;
          for (const key in data?.contactOrigins) {
            data?.contactOrigins[key].forEach((origin: IContactOrigin) => {
              this.leadGenContactOrigin[key].push({
                idContactOrigin: origin.idContactOrigin,
                description: `${CONTACT_ORIGIN[origin.description]}`,
              });
            });
          }
          /** Init variables and check on change */
          this.filteredCountries = this.countries;
          this.filterEntities();
          this.entityFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterEntities();
            });
          this.channels = data?.channelTypes;
          this.specificationsChannels = data?.specificationsChannels;
          this.buyerStatus = data?.buyerStatus.map(
            (status: IBuyerStatus): any => ({
              idBuyerStatus: status.idBuyerStatus,
              description: `${CHANNELS_TYPE[status.name]}`,
            })
          );
          this.buyerStatusReason = data?.buyerStatusReasons;
          this.referentRoles = data?.referentRoles;
          this.dataForSelectReady = true;
        },
      })
    );

    // Initialize all data if edit mode
    if (this.editMode) {
      this.getContactActivities();
      this.getContactCompetitors();
      this.getContactDeals();
      this.getContactReports();
      this.initAutocompleteData();
      this.getAllCompetitors();
    }
  }

  getContactDetails(): void {
    this.contactOperationInProgress = true;
    this.contactForm.disable();
    this.subscriptions.add(
      this.crmService
        .getContactDetails(this.contact.idContact, true)
        .subscribe((contact: IContact) => {
          this.contact = contact;
          // if (contact.channelType.idChannelType !== 1) {
          //   this.contactForm.get('channelTypeDesc').disable();
          // }
          this.contactForm.patchValue({
            idCustomer: contact.idCustomer,
            idContact: contact.idContact,
            idUser: contact.idUser,
            businessName: contact.businessName,
            channelType: contact.channelType,
            specificationChannelTypeDTO: contact.specificationChannelTypeDTO,
            address: contact.address,
            telephoneNumber: contact.telephoneNumber,
            email: contact?.email,
            webSiteLink: contact?.webSiteLink,
            note: contact?.note,
            country: contact?.country,
            buyerStatus: contact?.buyerStatus,
            buyerStatusReason: contact?.buyerStatusReason || {
              idBuyerStatusReason: null,
            },
            statusDetails: contact?.statusDetails,
            contactOrigin: contact?.contactOrigin,
            vip: contact?.vip,
            city: contact?.city,
            province: contact?.province,
            position: contact?.position,
            revenue: contact?.revenue,
            contactReference: contact?.contactReference,
          });
          // This 'if' will be removed, because contactOrigin is mandatory so it will be useless.
          // I use it now for backwards compatibility with contacts created before adding contactOrigin field
          // if (this.contactForm.get('contactOrigin').value) {
          //   // Set contact origin field type
          //   for (let key in this.leadGenContactOrigin) {
          //     this.isContactOriginFieldSelect = this.leadGenContactOrigin[
          //       key
          //     ].some(
          //       (origin: IContactOrigin) =>
          //         origin.description ===
          //         this.contactForm.get('contactOrigin').value
          //     );
          //     if (this.isContactOriginFieldSelect) {
          //       break;
          //     }
          //   }
          // }
          // Enable specification if channelType already exist
          if (this.contactForm.get('channelType.idChannelType').value) {
            this.contactForm
              .get('specificationChannelTypeDTO.idSpecificationChannelType')
              .enable();
          }
          this.contactBuyers.clear();
          contact.contactReference.forEach(
            (buyer: IContactBuyer, i: number) => {
              if (buyer.main) {
                this.mainReferentIndex = i;
              }
              this.addContactBuyer(buyer);
            }
          );
          this.enableDisableValidatorOnBuyerStatusReason(
            new MatSelectChange(null, contact.buyerStatus.idBuyerStatus)
          );
          this.contactForm.markAsPristine();
          this.contactForm.markAsUntouched();
          this.contactForm.enable();
          if (
            this.contactForm.get('city').value === '' ||
            this.contactForm.get('city').value === null
          ) {
            this.contactForm.get('address').disable();
          }
          this.contactOperationInProgress = false;
          this.generateBreadCrumb();
          this.activityForm
            .get('idContactBuyerStatusChange')
            .setValue(contact.buyerStatus.idBuyerStatus);
          if (
            contact.buyerStatus.idBuyerStatus !== BUYER_STATUS.Active &&
            contact.buyerStatus.idBuyerStatus !== BUYER_STATUS.Suspect &&
            contact.buyerStatus.idBuyerStatus !== BUYER_STATUS.Prospect
          ) {
            this.activityForm.get('dateActivity').clearValidators();
            this.activityForm.get('dateActivity').updateValueAndValidity();
            this.activityForm
              .get('dateActivity')
              .setValidators([this.checkDateBeforeToday('new')]);
            this.activityForm.get('dateActivity').updateValueAndValidity();
          }
        })
    );
  }

  patchContactValue(contact?: IContact): void {
    this.contactForm?.patchValue({
      idCustomer: contact?.idCustomer || null,
      idContact: contact?.idContact || null,
      idUser: contact?.idUser || null,
      businessName: contact?.businessName || null,
      channelType: contact?.channelType || {
        idChannelType: null,
      },
      specificationChannelTypeDTO: contact?.specificationChannelTypeDTO || {
        idSpecificationChannelType: null,
      },
      address: contact?.address || null,
      telephoneNumber: contact?.telephoneNumber || null,
      email: contact?.email || null,
      webSiteLink: contact?.webSiteLink || null,
      note: contact?.note || null,
      country: contact?.country || {
        idCountry: null,
      },
      buyerStatus: contact?.buyerStatus || {
        idBuyerStatus: null,
      },
      buyerStatusReason: contact?.buyerStatusReason || {
        idBuyerStatusReason: null,
      },
      statusDetails: contact?.statusDetails || null,
      vip: contact?.vip || null,
      city: contact?.city || null,
      province: contact?.province || null,
      // position: contact?.position || null,
      revenue: contact?.revenue || null,
      contactReference: contact?.contactReference || [],
    });
    this.contactBuyers.clear();
    contact?.contactReference?.forEach((buyer: IContactBuyer, i: number) => {
      if (buyer.main) {
        this.mainReferentIndex = i;
      }
      this.addContactBuyer(buyer);
    });
  }

  generateBreadCrumb(): void {
    this.layoutService.generateBreadcrumb([
      {
        label: 'CRM',
        path: '/crm',
      },
      {
        label:
          this.customer?.businessName ||
          this.translate.instant('COMMON.Customer'),
        path: `/crm/customer/${this.customer?.idCustomer}/project/${this.idProject}`,
      },
      {
        label:
          this.contact?.businessName ||
          this.translate.instant('CRM.AddContact') ||
          this.translate.instant('COMMON.Customer'),
        path: null,
        pageTitle: true,
      },
    ]);
  }

  getCustomer(): void {
    this.contactOperationInProgress = true;
    this.irFormService.getCustomerById(this.customer.idCustomer).subscribe({
      next: (customer: ICustomer) => {
        this.customer = customer;
        if (this.editMode) {
          this.getContactDetails();
        } else {
          this.generateBreadCrumb();
          this.contactOperationInProgress = false;
        }
      },
    });
  }

  getFollowedCustomers(): void {
    this.subscriptions.add(
      this.crmService.getFollowedCustomers(true).subscribe({
        next: (customers: IProjectCustomer[]) => {
          this.followedCustomers = customers;
        },
      })
    );
  }

  getContactActivities(): void {
    this.activityOperationInProgress = true;
    this.subscriptions.add(
      this.crmService
        .getContactActivity(
          this.customer.idCustomer,
          this.contact.idContact,
          true
        )
        .subscribe((contactActivities: IContactActivity[]) => {
          this.activities = contactActivities;
          this.activityOperationInProgress = false;
        })
    );
  }

  getContactCompetitors(): void {
    this.competitorOperationInProgress = true;
    this.competitorsLoader = true;
    this.subscriptions.add(
      this.crmService
        .getContactCompetitors(this.contact.idContact, true)
        .subscribe((competitors: IContactCompetitor[]) => {
          this.competitors = competitors.map(
            (competitor: IContactCompetitor): IContactCompetitorForTable => ({
              ...competitor,
              country: competitor.country.country,
            })
          );
          this.competitorsTableColumns = [
            {
              name: 'companyName',
              translatedName: this.translations.CompetitorBusinessName,
            },
            // {
            //   name: 'country',
            //   translatedName: this.translations.ReferenceMarket,
            // },
            {
              name: 'buyerRevenueYear',
              translatedName: this.translations.AnnualPurchaseValue,
              type: 'currency',
              align: 'right',
            },
            // {
            //   name: 'valueRevenueYear',
            //   translatedName: this.translations.MarketShare,
            //   type: 'currency',
            //   align: 'right',
            // },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.competitorsTableButtons = [
            {
              icon: 'edit_icon',
              class: 'primary',
              disabled: () => this.competitorOperationInProgress,
              conditions: () => !this.isReadonly,
              clickFunction: (competitor: IContactCompetitorForTable) => {
                this.openUpdateContactCompetitorDialog(competitor);
              },
            },
          ];
          this.competitorOperationInProgress = false;
          this.competitorsLoader = false;
        })
    );
  }

  getContactDeals(): void {
    this.dealsOperationInProgress = true;
    this.dealsTableLoader = true;
    this.subscriptions.add(
      this.crmService
        .getDealsByIdContact(this.contact.idContact, true)
        .subscribe((deals: IDeal[]) => {
          this.deals = deals.map(
            (deal: IDeal, ind: number): IDealForTable => ({
              ...deal,
              dateDeal: moment(deal?.dateDeal).format(
                DATE_PIKER_FORMATS.display.dateInput
              ),
              dateDealMs: new Date(deal?.dateDeal).getTime(),
              commerceSectorForTable: deal?.commerceSector?.name,
              quantity: deal?.quantity,
              revenue: deal?.revenue,
              dealStateDesc: this.translate.instant(
                `DEAL_STATE_NAME.${DEAL_STATE_NAME[deal?.dealState?.name]}`
              ),
              index: ind,
            })
          );
          this.oldDeals = deals.map(
            (deal: IDeal, ind: number): IDealForTable => ({
              ...deal,
              dateDeal: moment(deal?.dateDeal).format(
                DATE_PIKER_FORMATS.display.dateInput
              ),
              dateDealMs: new Date(deal?.dateDeal).getTime(),
              commerceSectorForTable: deal?.commerceSector?.name,
              quantity: deal?.quantity,
              revenue: deal?.revenue,
              dealStateDesc: this.translate.instant(
                `DEAL_STATE_NAME.${DEAL_STATE_NAME[deal?.dealState?.name]}`
              ),
              index: ind,
            })
          );
          this.dealsTableColumns = [
            {
              name: 'dateDeal',
              translatedName: this.translations.DateDeal,
              type: 'shortDate',
            },
            {
              name: 'commerceSectorForTable',
              translatedName: this.translations.CommerceSector,
            },
            {
              name: 'quantity',
              translatedName: this.translations.Quantity,
            },
            {
              name: 'revenue',
              translatedName: this.translations.Revenue,
            },
            {
              name: 'dealStateDesc',
              translatedName: this.translations.DealStatus,
            },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.dealsTableButtons = [
            {
              icon: 'edit_icon',
              class: 'primary',
              disabled: () => this.dealsOperationInProgress,
              conditions: () => !this.isReadonly,
              clickFunction: (deal: IDealForTable) => {
                this.openContactDealDialog(deal);
              },
            },
          ];
          this.dealsOperationInProgress = false;
          this.dealsTableLoader = false;
        })
    );
  }

  getContactReports(): void {
    this.reportOperationInProgress = true;
    this.reportsTableLoader = true;
    this.subscriptions.add(
      this.crmService
        .getContactReports(this.contact.idContact, true)
        .subscribe((reports: IReport[]) => {
          // return;
          this.reports = reports.map(fromContactReportsToDataForTable);
          this.oldReports = reports.map(fromContactReportsToDataForTable);

          this.reportsTableColumns = [
            {
              name: 'status',
              translatedName: this.translations.Status2,
              translation: 'CRM_DETAIL',
            },
            {
              name: 'dateInsertForTable',
              translatedName: this.translations.DateInsert,
            },
            {
              name: 'commerceSectorForTable',
              translatedName: this.translations.CommerceSector,
            },
            {
              name: 'unitOfMeasureForTable',
              translatedName: this.translations.UnitOfMeasure,
            },
            {
              name: 'quantityYearForTable',
              translatedName: this.translations.QuantityYear,
            },
            {
              name: 'valueOfUnitForTable',
              translatedName: this.translations.ValueOfUnit,
              align: 'right',
            },
            {
              name: 'valueYearForTable',
              translatedName: this.translations.ValueYear,
              align: 'right',
            },
            {
              name: 'buttons',
              disableSort: true,
            },
          ];
          this.reportsTableButtons = [
            {
              icon: 'edit_icon',
              class: 'primary',
              disabled: () => this.reportOperationInProgress,
              conditions: () => !this.isReadonly,
              clickFunction: (report: IReportForTable) => {
                this.openContactReportDialog(report);
              },
            },
          ];
          this.reportOperationInProgress = false;
          this.reportsTableLoader = false;
        })
    );
  }

  // getContactReports(): void {
  //   this.reportsTableLoader = true;
  //   this.subscriptions.add(
  //     this.crmService
  //       .getContactReports(this.contact.idContact, true)
  //       .subscribe((reports: IReport[]) => {
  //         this.reports = reports;
  //         this.reportsTableDataSource.data = reports.map(
  //           fromContactReportsToDataForTable
  //         );
  //         this.reportsTableLoader = false;
  //       })
  //   );
  // }

  // changeToInputField() {
  //   if (this.contactForm.get('contactOrigin').value === CONTACT_ORIGIN.Other) {
  //     this.isContactOriginFieldSelect = false;
  //     setTimeout(() => {
  //       this.contactForm.get('contactOrigin').reset();
  //       this.contactOriginInput.nativeElement.focus();
  //     });
  //   }
  // }

  // changeToSelectField() {
  //   this.isContactOriginFieldSelect = true;
  //   setTimeout(() => {
  //     this.contactForm.get('contactOrigin').reset();
  //     this.contactOriginSelect.open();
  //   });
  // }

  addContactBuyer(contactReference?: IContactBuyer): void {
    this.contactBuyers.push(
      this.fb.group({
        idContact: [
          contactReference?.idContact || this.contact?.idContact || null,
        ],
        idContactReference: [contactReference?.idContactReference || null],
        role: this.fb.group({
          idCompanyRoleType: [
            contactReference?.role?.idCompanyRoleType || null,
            [Validators.required],
          ],
        }),
        name: [
          contactReference?.name || null,
          [Validators.required, Validators.maxLength(45)],
        ],
        surname: [
          contactReference?.surname || null,
          [Validators.required, Validators.maxLength(45)],
        ],
        personalTel: [
          contactReference?.personalTel || null,
          Validators.maxLength(20),
        ],
        officeTel: [
          contactReference?.officeTel || null,
          Validators.maxLength(20),
        ],
        email: [
          contactReference?.email || null,
          [Validators.required, Validators.maxLength(45)],
        ],
        title: [
          contactReference?.title || null,
          [Validators.required, Validators.maxLength(15)],
        ],
        main: [contactReference?.main || null],
        deactivated: [contactReference?.deactivated || false],
      })
    );
  }

  removeContactBuyer(buyer: FormGroup, index: number): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translate.instant('COMMON.Attention'),
      message: this.translate.instant('COMMON.DeleteConfirmationMessage'),
      buttonTrue: this.translate.instant('COMMON.Yes'),
      buttonFalse: this.translate.instant('COMMON.No'),
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        const isSuspect: boolean =
          this.contactForm.get('buyerStatus').value.idBuyerStatus === 2;
        if (this.contactForm.get('idContact').value) {
          if (this.contactForm.get('buyerStatus.idBuyerStatus').dirty) {
            this.common.showToast(
              this.translations.StatusChangedSaveFirst,
              ToastStatus.warning,
              3000
            );
          } else {
            if (buyer.get('main').value && !isSuspect) {
              this.common.showToast(
                this.translations.CantDeleteMainBuyer,
                ToastStatus.error,
                3000
              );
            } else if (
              !buyer.get('main').value &&
              !isSuspect &&
              this.contactForm.get('contactReference').value.length < 2
            ) {
              this.common.showToast(
                this.translations.AddAtLeastOneMainBuyer,
                ToastStatus.error,
                3000
              );
            } else {
              if (buyer.get('idContactReference').value) {
                this.contactBuyers.controls[index].disable();
                this.contactOperationInProgress = true;
                this.subscriptions.add(
                  this.crmService
                    .deleteContactBuyerBydId(
                      buyer.get('idContactReference').value,
                      true
                    )
                    .subscribe({
                      complete: () => {
                        if (buyer.get('main').value) {
                          this.mainReferentIndex = null;
                        }
                        this.contactBuyers.removeAt(index);
                        this.contactOperationInProgress = false;
                        // this.getContactDetails();
                      },
                      error: () => {
                        this.contactBuyers.controls[index].enable();
                        this.contactOperationInProgress = false;
                      },
                    })
                );
              } else {
                if (buyer.get('main').value) {
                  this.mainReferentIndex = null;
                }
                this.contactBuyers.removeAt(index);
              }
            }
          }
        }
      }
    });
  }

  checkMainBuyer(event: MatSlideToggleChange, index: number): void {
    this.contactForm.markAsDirty();
    if (event.checked === true) {
      this.mainReferentIndex = index;
      this.contactBuyers.controls[index].get('main').setValue(true);
    } else if (event.checked === false) {
      this.mainReferentIndex = null;
      this.contactBuyers.controls[index].get('main').setValue(false);
    }
  }

  checkIfChannelTypeOther(selection: MatSelectChange): void {
    if (selection.value === 1) {
      this.contactForm.get('channelTypeDesc').enable();
      this.contactForm
        .get('channelTypeDesc')
        .setValidators([Validators.required, Validators.maxLength(255)]);
    } else {
      this.contactForm.get('channelTypeDesc').disable();
      this.contactForm.get('channelTypeDesc').clearValidators();
    }
  }

  saveContact(): void {
    // if (this.contactForm.get('position').value.length) {
    let isSuspect: boolean = false;
    let atLeastOneMain: boolean = false;
    if (this.contactForm.get('buyerStatus').value.idBuyerStatus === 2) {
      isSuspect = true;
    } else {
      for (const buyer of this.contactBuyers.getRawValue()) {
        if (buyer.main === true) {
          atLeastOneMain = true;
          break;
        }
      }
    }
    if (
      this.contactForm.valid &&
      this.contactForm.dirty &&
      (isSuspect || (atLeastOneMain && this.contactBuyers.length))
    ) {
      this.contactForm.disable();
      this.contactOperationInProgress = true;
      this.subscriptions.add(
        this.crmService
          .createUpdateContact(
            this.idProject,
            this.contactForm.getRawValue(),
            true
          )
          .subscribe({
            next: (contact: IContact) => {
              this.contact = contact;
              this.router.navigate([
                `/crm/customer/${this.customer?.idCustomer}/project/${this.idProject}/contact/${contact?.idContact}`,
              ]);
            },
            complete: () => {
              this.contactForm.enable();
              this.getContactDetails();
              this.contactForm.markAsPristine();
              this.contactForm.markAsUntouched();
              if (this.addFromBM) {
                this.crmService.checktExportedBMAsWorked(this.idBM).subscribe({
                  complete: () => {},
                });
              }
            },
            error: () => {
              this.contactForm.enable();
              this.contactOperationInProgress = false;
              this.common.showToast(
                this.translations.ShowGeneralErrorToast,
                ToastStatus.error,
                3000
              );
            },
          })
      );
    }
    if (
      this.contactForm.valid &&
      this.contactForm.dirty &&
      this.contactBuyers.length &&
      !atLeastOneMain
    ) {
      this.common.showToast(
        this.translations.SetABuyerAsMain,
        ToastStatus.warning,
        3000
      );
    }
    if (this.contactForm.valid && !this.contactBuyers.length && !isSuspect) {
      this.common.showToast(
        this.translations.AddAtLeastOneBuyer,
        ToastStatus.error,
        3000
      );
    }
    // } else {
    //   this.common.showToast(
    //    this.translations.SetAddressCoordinatesForContact,
    //    ToastStatus.error,
    //    3000
    //  );
    // }
  }

  checkMandatoryActivity(event: MatSlideToggleChange): void {
    if (event.source?.name === 'mandatoryActivityEditToggle') {
      this.editActivityForm.get('mandatory').setValue(event.checked);
      this.editActivityForm.get('mandatory').markAsDirty();
    } else {
      this.activityForm.get('mandatory').setValue(event.checked);
    }
    this.editActivityForm.get('mandatory').updateValueAndValidity();
    this.activityForm.get('mandatory').updateValueAndValidity();
  }

  openUpdateContactActivityDialog(activity: IContactActivity): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = { activity };
    this.editActivityForm.patchValue({
      ...activity,
      dateActivity: moment(activity.dateActivity)
        .utc()
        .local()
        .format('YYYY-MM-DD'),
    });
    this.dialog.open(this.activityDialog, dialogConfig);
  }

  clearAndCloseContactActivityDialog(): void {
    this.editActivityForm.reset();
    this.dialog.closeAll();
  }

  removeActivity(activity: IContactActivity): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '400px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      title: this.translate.instant('COMMON.Attention'),
      message: this.translate.instant('COMMON.DeleteConfirmationMessage'),
      buttonTrue: this.translate.instant('COMMON.Yes'),
      buttonFalse: this.translate.instant('COMMON.No'),
    };
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.activityOperationInProgress = true;
        this.subscriptions.add(
          this.crmService
            .deleteContactActivity(activity.idContactActivity)
            .subscribe({
              complete: () => {
                this.activities = this.activities.filter(
                  (elem) =>
                    elem.idContactActivity !== activity.idContactActivity
                );
                this.activityOperationInProgress = false;
                this.getContactDetails();
              },
              error: () => {
                this.activityOperationInProgress = false;
              },
            })
        );
      }
    });
  }

  saveActivity(editedActivity?: IContactActivity): void {
    if (editedActivity) {
      if (this.editActivityForm.valid && this.editActivityForm.dirty) {
        this.editActivityForm.disable();
        this.activityOperationInProgress = true;
        const updatedActivity: any = this.editActivityForm.getRawValue();
        if (updatedActivity.timeActivity) {
          if (updatedActivity.dateActivity instanceof moment) {
            updatedActivity.dateActivity = updatedActivity.dateActivity
              .format('YYYY-MM-DD')
              .concat(`T${updatedActivity.timeActivity}`);
          } else {
            updatedActivity.dateActivity = updatedActivity.dateActivity.concat(
              `T${updatedActivity.timeActivity}`
            );
          }
        }
        updatedActivity.dateActivity = moment(updatedActivity.dateActivity)
          .utc()
          .toISOString();
        updatedActivity.idContactActivity = editedActivity.idContactActivity;
        this.subscriptions.add(
          this.crmService
            .addContactActivity(this.contact.idContact, updatedActivity)
            .subscribe({
              complete: () => {
                this.fgDirective.resetForm();
                this.editActivityForm.enable();
                this.clearAndCloseContactActivityDialog();
                this.getContactActivities();
                this.getContactDetails();
              },
              error: () => {
                this.editActivityForm.enable();
              },
            })
        );
      }
    } else {
      if (this.activityForm.valid && this.activityForm.dirty) {
        this.activityForm.disable();
        this.activityForm
          .get('customerSummaryDTO.idCustomer')
          .setValue(this.customer.idCustomer);
        this.activityForm
          .get('customerSummaryDTO.idCustomer')
          .updateValueAndValidity();
        this.activityOperationInProgress = true;
        const newActivity: any = this.activityForm.getRawValue();
        if (newActivity.timeActivity) {
          newActivity.dateActivity = newActivity.dateActivity
            .format('YYYY-MM-DD')
            .concat(`T${newActivity.timeActivity}`);
        }
        newActivity.dateActivity = moment(newActivity.dateActivity)
          .utc()
          .toISOString();
        this.subscriptions.add(
          this.crmService
            .addContactActivity(this.contact.idContact, newActivity)
            .subscribe({
              complete: () => {
                // if (idStatus) {
                //   this.subscriptions.add(
                //     this.crmService
                //       .updateContactStatus(this.contact.idContact, idStatus)
                //       .subscribe({
                //         complete: () => {
                //           this.mandatoryActivityToggle.checked = false;
                //           // this.activityForm.reset();
                //           this.fgDirective.resetForm();
                //           this.activityForm.get('mandatory').setValue(false);
                //           this.activityForm.markAsPristine();
                //           this.activityForm.enable();
                //           this.getContactActivities();
                //           this.getContactDetails();
                //         },
                //         error: () => {
                //           this.activityForm.enable();
                //           this.getContactActivities();
                //           this.common.showToast(
                //             this.translations.UnableToChangeStatusMessage,
                //             ToastStatus.error,
                //             3000
                //           );
                //         },
                //       })
                //   );
                // } else {}
                this.mandatoryActivityToggle.checked = false;
                // this.activityForm.reset();
                this.fgDirective.resetForm();
                this.activityForm.get('mandatory').setValue(false);
                this.activityForm.markAsPristine();
                this.activityForm.enable();
                this.getContactActivities();
                this.getContactDetails();
              },
              error: () => {
                this.activityForm.enable();
              },
            })
        );
      }
    }
  }

  saveCompetitor(competitor?: IContactCompetitor): void {
    if (competitor) {
      if (this.editCompetitorForm.valid && this.editCompetitorForm.dirty) {
        this.competitorOperationInProgress = true;
        this.editCompetitorForm.disable();
        this.subscriptions.add(
          this.crmService
            .addContactCompetitor(
              this.contact.idContact,
              this.editCompetitorForm.getRawValue(),
              true
            )
            .subscribe({
              complete: () => {
                this.editCompetitorForm.enable();
                this.clearAndCloseContactCompetitorDialog();
                this.getContactCompetitors();
              },
              error: () => {
                this.competitorForm.enable();
                this.competitorOperationInProgress = true;
              },
            })
        );
      }
    } else {
      if (this.competitorForm.valid && this.competitorForm.dirty) {
        this.competitorOperationInProgress = true;
        this.competitorForm.disable();
        this.subscriptions.add(
          this.crmService
            .addContactCompetitor(
              this.contact.idContact,
              this.competitorForm.getRawValue(),
              true
            )
            .subscribe({
              complete: () => {
                this.competitorForm.enable();
                this.competitorFormDirective.resetForm();
                this.getContactCompetitors();
              },
              error: () => {
                this.common.showToast(
                  this.translations.ShowGeneralErrorToast,
                  ToastStatus.error,
                  5000
                );
                this.competitorForm.enable();
                this.competitorOperationInProgress = false;
              },
            })
        );
      }
    }
  }

  openUpdateContactCompetitorDialog(
    competitor: IContactCompetitorForTable
  ): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      competitor,
      title: this.translate.instant('COMMON.Attention'),
      message: this.translate.instant('COMMON.DeleteConfirmationMessage'),
      buttonTrue: this.translate.instant('COMMON.Yes'),
      buttonFalse: this.translate.instant('COMMON.No'),
    };
    this.editCompetitorForm.patchValue(competitor);
    const dialogRef = this.dialog.open(this.competitorDialog, dialogConfig);
  }

  clearAndCloseContactCompetitorDialog(): void {
    this.editCompetitorForm.reset();
    this.dialog.closeAll();
  }

  openContactDealDialog(deal?: IDealForTable): void {
    this.showDealStateReason = false;
    this.showOpportunityFlag = false;
    this.showOpportunityFields = false;
    this.mustUploadDocument = false;

    if (deal) {
      if (!deal.dealStateReason) {
        deal.dealStateReason = {};
      }
      if (deal.dealState.idDealState === DEAL_STATE_ID.Losed) {
        this.showOpportunityFlag = true;
        this.showDealStateReason = true;
        if (deal.isOpportunity) {
          this.showOpportunityFields = true;
        }
      }
      // if (deal.dealState.idDealState === DEAL_STATE_ID.Created) {
      //   this.showOpportunityFields = false;
      // }
      if (deal.dealState.idDealState === DEAL_STATE_ID.OrderPlaced) {
        this.showDealStateReason = true;
        this.mustUploadDocument = true;
      }
      // if(this.dealStateBefore === DEAL_STATE_ID.Losed || this.dealStateBefore === DEAL_STATE_ID.OrderPlaced){
      //   this.disabledForm = true;
      // }
      // this.fetchForm(this.deal);
      // this.dealForm.patchValue(this.deal);
      this.firstStepAddDeal = false;
      this.disableUploadButton = false;
      if (deal.dealDocuments?.length) {
        this.uploadedDocument =
          deal.dealDocuments[deal.dealDocuments.length - 1];
        if (this.uploadedDocument) {
          this.mustUploadDocument = false;
        }
      }
    }

    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = deal;
    this.dealForm.patchValue({
      idDeal: deal?.idDeal,
      commerceSector: {
        idCommerceSector: deal?.commerceSector?.idCommerceSector,
      },
      dealState: {
        idDealState: deal?.dealState?.idDealState,
      },
      dealStateReason: {
        idDealStateReason: deal?.dealStateReason?.idDealStateReason,
      },
      dateDeal: deal?.dateDeal
        ? new Date(moment(deal?.dateDeal, 'DD/MM/YYYY').format('YYYY/MM/DD'))
        : null,
      quantity: deal?.quantity,
      revenue: deal?.revenue,
      note: deal?.note,
      isOpportunity: deal?.isOpportunity,
      productName: deal?.productName,
      italianDescription: deal?.italianDescription,
      englishDescription: deal?.englishDescription,
    });
    if (this.isReadonly) {
      this.dealForm.disable();
    }
    this.dialog.open(this.contactDealDialog, dialogConfig);
  }

  openContactReportDialog(report?: IReportForTable): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.maxWidth = '95vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = report;
    this.reportForm.patchValue({
      idDeal: report?.idDeal,
      contactReference: {
        idContactReference: report?.idContactReference || null,
      },
      idOpportunity: report?.idOpportunity,
      commerceSector: {
        idCommerceSector: report?.commerceSector?.idCommerceSector ?? null,
      },
      unitOfMeasure: {
        idUom: report?.unitOfMeasure?.idUom ?? null,
      },
      dateInsert: report?.dateInsert,
      dateInserForTable:
        new Date(
          moment(report?.dateInsert, 'DD/MM/YYYY').format('YYYY/MM/DD')
        ) ?? null,
      dateModify:
        new Date(
          moment(report?.dateModify, 'DD/MM/YYYY').format('YYYY/MM/DD')
        ) ?? null,
      userInsert: report?.userInsert,
      userModify: report?.userModify,
      quantity: report?.quantity,
      quantityYear: report?.quantityYear,
      valueOfUnit: report?.valueOfUnit,
      valueYear: report?.valueYear,
      productName: report?.productName,
      productDescription: report?.productDescription,
      productEnglishDescription: report?.productEnglishDescription,
      reportMatching: report?.reportMatching ?? [],
    });
    if (!report?.idOpportunity) {
      if (this.contact.contactReference.length === 1) {
        this.reportForm
          .get('contactReference.idContactReference')
          .setValue(this.contact.contactReference[0].idContactReference);
        this.reportForm.get('contactReference.idContactReference').disable();
      }
      if (this.contact.contactReference.length > 1) {
        this.reportForm
          .get('contactReference.idContactReference')
          .setValue(this.contact.contactReference[0].idContactReference);
        this.reportForm.get('contactReference.idContactReference').enable();
      }
    }
    if (this.isReadonly) {
      this.reportForm.disable();
    }
    this.dialog.open(this.contactReportDialog, dialogConfig);
  }

  addReportMatching(reportMatching?: IReportMatching): void {
    this.reportMatching.push(
      this.fb.group({
        idReportMatching: [reportMatching?.idReportMatching || null],
        wordKey: [reportMatching?.wordKey || null, [Validators.maxLength(250)]],
      })
    );
  }

  removeReportMatching(i: number): void {
    this.reportMatching.removeAt(i);
  }

  // activitySubmit(form: FormGroupDirective): void {
  //   if (this.contactActivityForm.valid && this.contactActivityForm.dirty) {
  //     const activity = this.contactActivityForm.getRawValue();
  //     activity.dateActivity = new Date(activity.dateActivity).toISOString();
  //     this.savingActivity = true;
  //     this.contactActivityForm.disable();
  //     this.subscriptions.add(
  //       this.crmService
  //         .addContactActivity(this.contact.idContact, activity, true)
  //         .subscribe({
  //           complete: () => {
  //             this.contactActivityForm.get('dateActivity').clearValidators();
  //             this.contactActivityForm
  //               .get('dateActivity')
  //               .updateValueAndValidity();
  //             this.contactActivityForm.reset();
  //             form.resetForm();
  //             this.contactActivityForm
  //               .get('customerSummaryDTO.idCustomer')
  //               .setValue(this.customer.idCustomer);
  //             this.getContactActivities();
  //             this.reloadActivitiesTable();
  //             this.savingActivity = false;
  //             this.contactActivityForm.enable();
  //           },
  //           error: () => {
  //             this.contactActivityForm.enable();
  //             this.savingActivity = false;
  //           },
  //         })
  //     );
  //   }
  // }

  // showCreateUpdateDealDialog(selectedDeal?: number): void {
  //   let deal = null;
  //   let dealStateBefore = null;

  //   if (!(typeof selectedDeal === 'undefined') && selectedDeal >= 0) {
  //     deal = this.deals[selectedDeal];
  //     dealStateBefore = this.deals[selectedDeal].dealState.idDealState;
  //   }
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.disableClose = true;
  //   dialogConfig.width = '800px';
  //   dialogConfig.data = {
  //     translations: this.translations,
  //     idContact: this.contact.idContact,
  //     dealStateBefore,
  //     deal,
  //     isReadonly: this.isReadonly,
  //   };
  //   const dialogRef = this.dialog.open(
  //     ContactDealDialogComponent,
  //     dialogConfig
  //   );
  //   dialogRef.afterClosed().subscribe((result: boolean) => {
  //     if (result) {
  //       this.getContactDeals();
  //     }
  //   });
  // }

  showCreateUpdateCompetitorDialog(selectedCompetitor?: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.autoFocus = false;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      translations: this.translations,
      idContact: this.contact.idContact,
      contactCompetitor: null,
      isReadonly: this.isReadonly,
    };
    if (typeof selectedCompetitor === 'number') {
      dialogConfig.data.contactCompetitor =
        this.competitors[selectedCompetitor];
    }
    const dialogRef = this.dialog.open(
      ContactCompetitorsDialogComponent,
      dialogConfig
    );
    dialogRef.componentInstance.addContactCompetitorEmitter.subscribe(() => {
      dialogRef.close();
      this.getContactCompetitors();
    });
  }

  // showCreateUpdateReportDialog(index?: number): void {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.width = '800px';
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.disableClose = true;
  //   dialogConfig.data = {
  //     translations: this.translations,
  //     idContact: this.contact.idContact,
  //     contactReport: null,
  //     isReadonly: this.isReadonly,
  //   };
  //   if (typeof index === 'number') {
  //     dialogConfig.data.contactReport = this.reports[index];
  //   }
  //   const dialogRef = this.dialog.open(
  //     ContactReportsDialogComponent,
  //     dialogConfig
  //   );
  //   dialogRef.componentInstance.addContactReportEmitter.subscribe(() => {
  //     dialogRef.close();
  //     this.getContactReports();
  //   });
  // }

  // handleActivityMandatory(event: any): void {
  //   if (event) {
  //     this.contactActivityForm
  //       .get('dateActivity')
  //       .setValidators(Validators.required);
  //     this.contactActivityForm.get('dateActivity').updateValueAndValidity();
  //   } else {
  //     this.contactActivityForm.get('dateActivity').clearValidators();
  //     this.contactActivityForm.get('dateActivity').updateValueAndValidity();
  //   }
  // }

  reloadContactsTable(): void {
    this.reloadContactsEmitter.emit();
  }

  reloadActivitiesTable(): void {
    this.reloadActivitiesEmitter.emit();
  }

  // deleteContactReport(idOpportunity: number | string): void {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations?.DeleteContactReport,
  //     centerTextMessage: true
  //   };
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.subscriptions.add(
  //         this.crmService.deleteContactReport(idOpportunity, false).subscribe(() => {
  //           this.getContactReports();
  //         })
  //       );
  //     }
  //   });
  // }

  // deleteContactCompetitor(idContactCompetitorCompany: number | string): void {
  //   const dialogConfig = new MatDialogConfig();
  //   dialogConfig.disableClose = true;
  //   dialogConfig.autoFocus = false;
  //   dialogConfig.data = {
  //     title: this.translations?.Attention,
  //     message: this.translations?.DeleteContactCompetitor,
  //     centerTextMessage: true
  //   };
  //   const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.subscriptions.add(
  //         this.crmService.deleteContactCompetitor(idContactCompetitorCompany, false).subscribe(() => {
  //           this.getContactCompetitors();
  //         })
  //       );
  //     }
  //   });
  // }

  handleRangeDateDealTableFilter(
    event: MatDatepickerInputEvent<any, any>
  ): void {
    if (event.value) {
      const rangeDate: {} = {
        startDate: new Date(this.startDateDealFilter.value).getTime(),
        endDate: new Date(event.value).getTime(),
      };
      this.filterDealsTable('dealRangeDate', null, rangeDate);
    }
  }

  handleRangeDateReportTableFilter(
    event: MatDatepickerInputEvent<any, any>
  ): void {
    if (event.value) {
      const rangeDate: {} = {
        startDate: new Date(this.startDateReportFilter.value).getTime(),
        endDate: new Date(event.value).getTime(),
      };
      this.filterReportsTable('reportRangeDate', null, rangeDate);
    }
  }

  filterDealsTable(
    filter: string,
    select: MatSelectChange,
    rangeDate?: {}
  ): void {
    if (rangeDate) {
      this.dealFilterSelects[filter] = rangeDate;
    } else {
      this.dealFilterSelects[filter] = select.value;
    }
    this.deals = [];
    this.deals = this.oldDeals.filter((deal: IDealForTable) => {
      if (this.dealFilterSelects.commerceSector) {
        return (
          deal.commerceSector?.name === this.dealFilterSelects.commerceSector
          // this.translate.instant(
          //   'COMMERCE_SECTORS.' + this.filterSelects.commerceSector
          // )
        );
      }
      return deal;
    });
    this.deals = this.deals.filter((deal: IDealForTable) => {
      if (this.dealFilterSelects.dealStatus) {
        return (
          deal.dealStateDesc ===
          this.translate.instant(
            'DEAL_STATE_NAME.' + this.dealFilterSelects.dealStatus
          )
        );
      }
      return deal;
    });
    this.deals = this.deals.filter((deal: IDealForTable) => {
      if (this.dealFilterSelects.dealRangeDate) {
        return (
          deal.dateDealMs >= this.dealFilterSelects.dealRangeDate.startDate &&
          deal.dateDealMs <= this.dealFilterSelects.dealRangeDate.endDate
        );
      }
      return deal;
    });
    // this.deals = this.deals.filter((content: any) => {
    //   if (this.filterSelects.importanceLevel) {
    //     return (
    //       content.importanceLevelForTable === this.filterSelects.importanceLevel
    //     );
    //   }
    //   return content;
    // });
  }

  filterReportsTable(
    filter: string,
    select: MatSelectChange,
    rangeDate?: {}
  ): void {
    if (rangeDate) {
      this.reportsFilterSelects[filter] = rangeDate;
    } else {
      this.reportsFilterSelects[filter] = select.value;
    }
    this.reports = [];
    this.reports = this.oldReports.filter((report: IReportForTable) => {
      if (this.reportsFilterSelects.commerceSector) {
        return (
          report.commerceSector?.name ===
          this.reportsFilterSelects.commerceSector
          // this.translate.instant(
          //   'COMMERCE_SECTORS.' + this.filterSelects.commerceSector
          // )
        );
      }
      return report;
    });
    this.reports = this.reports.filter((report: IReportForTable) => {
      if (this.reportsFilterSelects.reportRangeDate) {
        return (
          new Date(report.dateInsert)?.getTime() >=
            this.reportsFilterSelects.reportRangeDate.startDate &&
          new Date(report.dateInsert)?.getTime() <=
            this.reportsFilterSelects.reportRangeDate.endDate
        );
      }
      return report;
    });
  }

  resetDynamicFields(): void {
    this.dealForm.get('isOpportunity').setValue(false);
    this.dealForm.get('italianDescription').setValue(null);
    this.dealForm.get('englishDescription').setValue(null);
    this.dealForm.get('productName').setValue(null);
    this.dealForm.get('dealStateReason.idDealStateReason').setValue(null);
  }

  checkSelectedDealState(select?: MatSelectChange): void {
    this.resetDynamicFields();
    this.dealStateSelected = select?.value;
    if (select?.value === DEAL_STATE_ID.Losed) {
      this.dealForm
        .get('dealStateReason.idDealStateReason')
        .setValidators(Validators.required);
      this.dealForm
        .get('dealStateReason.idDealStateReason')
        .updateValueAndValidity();
      this.dealForm.markAllAsTouched();
      this.showOpportunityFlag = true;
      this.mustUploadDocument = true;
      this.showDealStateReason = true;
      this.disableUploadButton = false;
      this.uploadingDealDocument = false;
      this.firstStepAddDeal = false;
    }
    if (select?.value !== DEAL_STATE_ID.Losed) {
      this.dealForm.get('dealStateReason.idDealStateReason').clearValidators();
      this.dealForm
        .get('dealStateReason.idDealStateReason')
        .updateValueAndValidity();
      this.dealForm.get('productName').clearValidators();
      this.dealForm.get('productName').updateValueAndValidity();
      this.dealForm.get('italianDescription').clearValidators();
      this.dealForm.get('italianDescription').updateValueAndValidity();
      this.dealForm.get('englishDescription').clearValidators();
      this.dealForm.get('englishDescription').updateValueAndValidity();
      this.showDealStateReason = false;
      this.showOpportunityFlag = false;
    }
    if (select?.value === DEAL_STATE_ID.OrderPlaced) {
      this.dealForm.markAllAsTouched();
      this.mustUploadDocument = true;
      this.showOpportunityFields = false;
      this.showDealStateReason = true;
      this.disableUploadButton = false;
      this.uploadingDealDocument = false;
      this.firstStepAddDeal = false;
    }
    if (select?.value === DEAL_STATE_ID.Created) {
      this.dealForm.get('dealStateReason.idDealStateReason').clearValidators();
      this.dealForm
        .get('dealStateReason.idDealStateReason')
        .updateValueAndValidity();
      this.dealForm.get('productName').clearValidators();
      this.dealForm.get('productName').updateValueAndValidity();
      this.dealForm.get('italianDescription').clearValidators();
      this.dealForm.get('italianDescription').updateValueAndValidity();
      this.dealForm.get('englishDescription').clearValidators();
      this.dealForm.get('englishDescription').updateValueAndValidity();

      this.showDealStateReason = false;
      this.mustUploadDocument = false;
      this.showOpportunityFields = false;
    }
  }

  resetDealsTableFilters(): void {
    this.deals = this.oldDeals;
    this.dealFilterSelects = {
      commerceSector: null,
      dealStatus: null,
      dealRangeDate: null,
    };
    this.dealCommerceSectorFilter.value = [];
    this.dealStatusFilter.value = [];
    this.startDateDealFilter.reset();
    this.endDateDealFilter.reset();
  }

  resetReportsTableFilters(): void {
    this.reports = this.oldReports;
    this.reportsFilterSelects = {
      commerceSector: null,
      dealStatus: null,
      reportRangeDate: null,
    };
    this.reportCommerceSectorFilter.value = [];
    this.startDateReportFilter.reset();
    this.endDateReportFilter.reset();
  }

  /** filter the entities */
  private filterEntities(): void {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.entityFilterCtrl.value;
    if (!search) {
      this.filteredCountries = this.countries;
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the data
    this.filteredCountries = this.countries
      .filter((entity) => entity.country.toLowerCase().indexOf(search) > -1)
      .sort(
        (a, b) =>
          a.country.toLowerCase().indexOf(search) -
          b.country.toLowerCase().indexOf(search)
      );
  }

  uploadDealDocument(document: File): void {
    this.uploadingDealDocument = true;
    if (this.dealForm.get('idDeal').value) {
      const idDeal: number = this.dealForm.get('idDeal').value;
      this.disableUploadButton = true;
      if (this.uploadedDocument?.idDealDocument) {
        this.subscriptions.add(
          this.crmService
            .saveUpdateDealDocument(
              idDeal,
              document,
              true,
              this.uploadedDocument.idDealDocument
            )
            .subscribe({
              next: (documentUploaded: any) => {
                this.uploadedDocument = documentUploaded;
              },
              complete: async () => {
                await this.completeAfterUploadFile();
              },
              error: (err) => {
                this.uploadError = true;
                this.showToastErrorUploadFile(true);
              },
            })
        );
      } else {
        this.subscriptions.add(
          this.crmService
            .saveUpdateDealDocument(idDeal, document, true)
            .subscribe({
              next: (documentUploaded: any) => {
                this.uploadedDocument = documentUploaded;
              },
              complete: () => {
                this.completeAfterUploadFile();
              },
              error: (err) => {
                this.uploadError = true;
                this.showToastErrorUploadFile(true);
              },
            })
        );
      }
    }
    if (!this.dealForm.get('idDeal').value) {
      this.orderPlacedFile = document;
      this.uploadingDealDocument = false;
      this.savingDealLoader = false;
      this.mustUploadDocument = false;
      this.firstStepAddDeal = false;
    }
  }

  viewDeal(): void {
    this.subscriptions.add(
      this.crmService
        .downloadDealDocument(this.uploadedDocument.idDealDocument, true)
        .subscribe({
          complete: () => {
            this.uploadingDealDocument = false;
          },
          error: () => {
            this.common.showToast(
              this.translations.ShowGeneralErrorToast,
              ToastStatus.error,
              5000
            );
          },
        })
    );
  }

  completeAfterUploadFile(): void {
    this.dealForm.markAsDirty();
    this.dealForm.markAsTouched();
    this.disableUploadButton = false;
    this.uploadingDealDocument = false;
    this.dealDocumentUpdated = true;
    this.mustUploadDocument = false;
  }

  showToastErrorUploadFile(closeDialog: boolean): void {
    this.common.showToast(
      this.translations.UnableToUploadDocument,
      ToastStatus.error,
      5000
    );
    this.uploadingDealDocument = false;
  }

  fileRequired(): boolean {
    return (
      !this.uploadedDocument &&
      !this.orderPlacedFile &&
      (this.dealStateSelected === DEAL_STATE_ID.OrderPlaced ||
        this.dealStateSelected === DEAL_STATE_ID.Losed)
    );
  }

  checkOpportunityFlag(check: boolean): void {
    if (check) {
      this.dealForm.get('productName').setValidators(Validators.required);
      this.dealForm
        .get('italianDescription')
        .setValidators(Validators.required);
      this.dealForm
        .get('englishDescription')
        .setValidators(Validators.required);
      this.showOpportunityFields = true;
    } else {
      this.dealForm.get('productName').clearValidators();
      this.dealForm.get('italianDescription').clearValidators();
      this.dealForm.get('englishDescription').clearValidators();
      this.showOpportunityFields = false;
    }
    this.dealForm.get('productName').updateValueAndValidity();
    this.dealForm.get('italianDescription').updateValueAndValidity();
    this.dealForm.get('englishDescription').updateValueAndValidity();
  }

  saveDeal(): void {
    if (this.dealForm.dirty && this.dealForm.valid) {
      // if (this.dealStateSelected === DEAL_STATE_ID.Created || this.dealStateSelected === DEAL_STATE_ID.Losed) {
      //   this.dealForm.disable();
      //   this.subscriptions.add(
      //     this.crmService.saveDeal(this.contact.idContact, this.dealForm.getRawValue()).subscribe({
      //       complete: () => {
      //         this.dealForm.enable();
      //         this.dialog.closeAll();
      //         this.getContactDeals();
      //       },
      //       error: () => {
      //         this.dealForm.enable();
      //       }
      //     })
      //   );
      // }
      // if (this.dealStateSelected === DEAL_STATE_ID.OrderPlaced) {
      //   if (this.orderPlacedFile) {

      //   } else {
      //     this.showUploadFileNeededError = true;
      //   }
      // }

      if (this.dealForm.valid && !this.fileRequired()) {
        if (this.orderPlacedFile) {
          this.savingDealLoader = true;
          this.dealForm.disable();
          this.subscriptions.add(
            this.crmService
              .saveDeal(
                this.contact.idContact,
                this.dealForm.getRawValue(),
                true
              )
              .subscribe(
                (deal) => {
                  this.subscriptions.add(
                    this.crmService
                      .saveUpdateDealDocument(
                        deal.idDeal,
                        this.orderPlacedFile,
                        true
                      )
                      .subscribe({
                        next: (documentUploaded: any) => {
                          this.uploadedDocument = documentUploaded;
                        },
                        complete: () => {
                          this.completeAfterUploadFile();
                          this.savingDealLoader = false;
                          this.dealForm.enable();
                          this.dialog.closeAll();
                          this.getContactDeals();
                        },
                        error: () => {
                          this.showToastErrorUploadFile(false);
                          this.savingDealLoader = false;
                          this.dealForm.enable();
                        },
                      })
                  );
                },
                (error) => {
                  this.common.showToast(
                    this.translations.ShowGeneralErrorToast,
                    ToastStatus.error,
                    5000
                  );
                  this.savingDealLoader = false;
                  this.dealForm.enable();
                }
              )
          );
        } else {
          this.savingDealLoader = true;
          this.dealForm.disable();
          this.subscriptions.add(
            this.crmService
              .saveDeal(
                this.contact.idContact,
                this.dealForm.getRawValue(),
                true
              )
              .subscribe({
                complete: () => {
                  this.savingDealLoader = false;
                  this.dealForm.enable();
                  this.dialog.closeAll();
                  this.getContactDeals();
                },
                error: () => {
                  this.common.showToast(
                    this.translations.ShowGeneralErrorToast,
                    ToastStatus.error,
                    5000
                  );
                },
              })
          );
        }
      }
    }
  }

  updateValueYear(report: FormGroup): void {
    report
      .get('valueYear')
      .setValue(
        (report.get('valueOfUnit').value || 0) *
          (report.get('quantityYear').value || 0)
      );
  }

  saveReport(): void {
    if (this.reportForm.dirty) {
      if (this.reportForm.valid) {
        this.reportForm.disable();
        this.subscriptions.add(
          this.crmService
            .addContactReport(
              this.contact.idContact,
              this.reportForm.getRawValue(),
              true
            )
            .subscribe({
              complete: () => {
                this.savingDealLoader = false;
                this.reportForm.enable();
                this.dialog.closeAll();
                this.getContactReports();
                this.reportForm.reset();
              },
              error: () => {
                this.reportForm.enable();
                this.common.showToast(
                  this.translations.ShowGeneralErrorToast,
                  ToastStatus.error,
                  5000
                );
              },
            })
        );
      }
    } else {
      this.showNotDirtyMessage = true;
    }
  }

  resetDealForm(): void {
    this.dealForm.reset();
  }

  resetReportForm(): void {
    this.reportForm.reset();
  }

  goBackToDatabase(): void {
    this.router.navigate(
      [`/crm/customer/${this.customer.idCustomer}/project/${this.idProject}`],
      {
        queryParams: { tab: 0 },
      }
    );
  }

  enableDisableValidatorOnBuyerStatusReason(value: MatSelectChange): void {
    if (value.value >= 4 && value.value <= 7) {
      this.contactForm
        .get('buyerStatusReason.idBuyerStatusReason')
        ?.setValidators(Validators.required);
      this.contactForm
        .get('buyerStatusReason.idBuyerStatusReason')
        ?.updateValueAndValidity();
    } else {
      this.contactForm
        .get('buyerStatusReason.idBuyerStatusReason')
        ?.clearValidators();
      this.contactForm
        .get('buyerStatusReason.idBuyerStatusReason')
        .setValue(null);
      this.contactForm
        .get('buyerStatusReason.idBuyerStatusReason')
        ?.updateValueAndValidity();
    }
  }

  showChannelsManagementDialog(
    event: PointerEvent,
    type: string,
    idChannelType?: number
  ): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    if (type === 'channel') {
      dialogConfig.data = {
        managementType: 'channel',
        channels: this.channels,
        idCustomer: this.customer.idCustomer,
      };
    } else {
      dialogConfig.data = {
        managementType: 'channelSpecification',
        channelsSpecifications: this.specificationsChannels.filter(
          (spec: IChannelSpecification) => {
            return spec.idChannelType === idChannelType;
          }
        ),
        idCustomer: this.customer.idCustomer,
        idChannelType,
      };
    }
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        if (type === 'channel') {
          this.subscriptions.add(
            this.irFormService
              .getChannelsTypeByIdCustomer(this.customer.idCustomer, false)
              .subscribe({
                next: (channels: IChannelsType[]) => {
                  this.channels = channels;
                  if (
                    !channels.some((ch: any) => {
                      return (
                        ch.idChannelType ===
                        this.contactForm.get('channelType.idChannelType').value
                      );
                    })
                  ) {
                    this.contactForm
                      .get('channelType.idChannelType')
                      .setValue(null);
                    this.activateSpecificationsSelect(
                      new MatSelectChange(null, null)
                    );
                  }
                },
              })
          );
        } else {
          this.subscriptions.add(
            this.common
              .getSpecificationChannels(this.customer.idCustomer, false)
              .subscribe({
                next: (channelsSpec: IChannelSpecification[]) => {
                  this.specificationsChannels = channelsSpec;
                  if (
                    !channelsSpec.some((ch: any) => {
                      return (
                        ch.idSpecificationChannelType ===
                        this.contactForm.get(
                          'specificationChannelTypeDTO.idSpecificationChannelType'
                        ).value
                      );
                    })
                  ) {
                    this.contactForm
                      .get(
                        'specificationChannelTypeDTO.idSpecificationChannelType'
                      )
                      .setValue(null);
                  }
                },
              })
          );
        }
      }
    });
  }

  showUomManagementDialog(event: PointerEvent): void {
    event.stopPropagation();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      managementType: 'uom',
      uom: this.unitsOfMeasure,
      idCustomer: this.contact.idCustomer,
    };
    const dialogRef = this.dialog.open(
      ChannelsManagementDialogComponent,
      dialogConfig
    );
    dialogRef.afterClosed().subscribe((result: string) => {
      if (result === 'refresh') {
        this.getUnitsOfMeasure();
      }
    });
  }

  getUnitsOfMeasure(): void {
    this.subscriptions.add(
      this.irFormService
        .getUnitsOfMeasure(false, false, this.contact.idCustomer)
        .subscribe({
          next: (uom: UnitsOfMeasure[]) => {
            this.unitsOfMeasure = uom;
          },
        })
    );
  }

  checkWichContactReferencesToShow(report: IReportForTable): IContactBuyer[] {
    const references: IContactBuyer[] = [];
    if (!report?.idOpportunity) {
      this.contact?.contactReference?.forEach((ref: IContactBuyer) => {
        if (!ref.deactivated) {
          references.push(ref);
        }
      });
    }
    if (report?.idOpportunity) {
      this.contact?.contactReference?.forEach((ref: IContactBuyer) => {
        if (!ref.deactivated) {
          references.push(ref);
        }
        if (ref.deactivated) {
          if (ref.idContactReference === report.idContactReference) {
            references.push(ref);
          }
        }
      });
    }
    return references;
  }

  activateSpecificationsSelect(change: MatSelectChange): void {
    if (change.value) {
      this.contactForm
        .get('specificationChannelTypeDTO.idSpecificationChannelType')
        .enable();
    } else {
      this.contactForm
        .get('specificationChannelTypeDTO.idSpecificationChannelType')
        .disable();
    }
  }

  openPositionDialog(): void {
    const dialogConfig: MatDialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '800px';
    dialogConfig.panelClass = 'custom-dialog-container';
    dialogConfig.data = {
      address: this.contactForm.get('address').value,
      position: this.contactForm.get('position').value,
      alternativePositionCity: this.alternativePositionCity,
    };
    const dialogRef = this.dialog.open(PositionDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((position: number[]) => {
      if (position) {
        this.contactForm.get('position').setValue(position);
        if (
          JSON.stringify(position.sort()) !==
          JSON.stringify(dialogConfig.data.position.sort())
        ) {
          this.contactForm.markAsDirty();
        }
      }
    });
  }

  checkAndSetCoordinatesAutomatically(): void {
    if (this.contactForm.get('address').value !== '') {
      if (this.contactForm.get('address').dirty) {
        this.contactForm.get('position').setValue([]);
        this.common
          .getAddressFromCoordinates(
            `${this.contactForm.get('address').value} ${
              this.contactForm.get('city').value
            }`
          )
          .subscribe({
            next: (resp: INominatimCoordinates[]) => {
              this.contactForm.get('address').markAsPristine();
              if (resp.length) {
                this.contactForm
                  .get('position')
                  .setValue([resp[0].lon, resp[0].lat]);
              }
            },
            complete: () => {
              this.contactForm.get('position').markAsDirty();
            },
          });
      }
    }
  }

  checkCity(): void {
    if (this.contactForm.get('city').value !== '') {
      this.contactForm.get('address').enable();
    } else {
      this.contactForm.get('address').disable();
      this.contactForm.get('address').setValue(null);
      this.contactForm.get('position').setValue([]);
    }
  }

  checkCityCoordinates(): void {
    this.common
      .getCityFromCoordinates(this.contactForm.get('city').value)
      .subscribe({
        next: (resp: INominatimCoordinates[]) => {
          this.alternativePositionCity = [
            parseFloat(resp[0]?.lon),
            parseFloat(resp[0]?.lat),
          ];
        },
      });
  }

  initAutocompleteData(): void {
    this.competitorForm
      .get('companyName')
      .valueChanges.subscribe((response) => {
        if (response) {
          if (response.length < 3) {
            this.filteredOptions = [];
          } else {
            this.filteredOptions = this.options.filter((item) => {
              return (
                item.companyName.toLowerCase().indexOf(response.toLowerCase()) >
                -1
              );
            });
          }
        }
      });
  }

  getAllCompetitors(): void {
    this.crmService
      .getAllContactCompetitors(this.idProject, false)
      .subscribe((competitors) => {
        this.options = competitors;
      });
  }

  checkDateBeforeToday(formType: string): ValidatorFn {
    return () => {
      if (formType === 'edit') {
        if (
          moment(this.editActivityForm?.get('dateActivity')?.value)
            ?.utc()
            ?.isBefore(moment().startOf('day').utc())
        ) {
          return { beforeNow: true };
        } else {
          return null;
        }
      } else {
        if (
          moment(this.activityForm?.get('dateActivity')?.value)
            ?.utc()
            ?.isBefore(moment().startOf('day').utc())
        ) {
          return { beforeNow: true };
        } else {
          return null;
        }
      }
    };
  }

  checkTimeBeforeNow(formType: string): ValidatorFn {
    return () => {
      if (formType === 'edit') {
        if (
          moment(this.editActivityForm?.get('dateActivity')?.value)
            ?.set({
              hour: this.editActivityForm
                ?.get('timeActivity')
                ?.value?.split(':')[0],
              minute: this.editActivityForm
                ?.get('timeActivity')
                ?.value?.split(':')[1],
            })
            ?.utc()
            ?.isBefore(moment().utc())
        ) {
          return { beforeNow: true };
        } else {
          return null;
        }
      } else {
        if (
          moment(this.activityForm?.get('dateActivity')?.value)
            ?.set({
              hour: this.activityForm
                ?.get('timeActivity')
                ?.value?.split(':')[0],
              minute: this.activityForm
                ?.get('timeActivity')
                ?.value?.split(':')[1],
            })
            ?.utc()
            ?.isBefore(moment().utc())
        ) {
          return { beforeNow: true };
        } else {
          return null;
        }
      }
    };
  }

  checkActivityFieldsValidatorsRelatedToStatus(
    change: MatSelectChange,
    formType: string
  ): void {
    if (
      change.value === BUYER_STATUS.Active ||
      change.value === BUYER_STATUS.Suspect ||
      change.value === BUYER_STATUS.Prospect
    ) {
      if (formType === 'edit') {
        this.editActivityForm
          .get('dateActivity')
          .setValidators([
            Validators.required,
            this.checkDateBeforeToday(formType),
          ]);
        this.editActivityForm.get('dateActivity').updateValueAndValidity();
      } else {
        this.activityForm
          .get('dateActivity')
          .setValidators([
            Validators.required,
            this.checkDateBeforeToday(formType),
          ]);
        this.activityForm.get('dateActivity').updateValueAndValidity();
      }
    } else {
      if (formType === 'edit') {
        this.editActivityForm.get('dateActivity').clearValidators();
        this.editActivityForm
          .get('dateActivity')
          .setValidators([this.checkDateBeforeToday(formType)]);
        this.editActivityForm.get('dateActivity').updateValueAndValidity();
      } else {
        this.activityForm.get('dateActivity').clearValidators();
        this.activityForm
          .get('dateActivity')
          .setValidators([this.checkDateBeforeToday(formType)]);
        this.activityForm.get('dateActivity').updateValueAndValidity();
      }
    }
  }

  checkIfDateExist(): void {
    if (
      !this.editActivityForm.get('dateActivity').value &&
      (this.editActivityForm.get('idContactBuyerStatusChange').value ===
        BUYER_STATUS.Active ||
        this.editActivityForm.get('idContactBuyerStatusChange').value ===
          BUYER_STATUS.Suspect ||
        this.editActivityForm.get('idContactBuyerStatusChange').value ===
          BUYER_STATUS.Prospect)
    ) {
      this.editActivityForm.get('timeActivity').clearValidators();
      this.editActivityForm.get('timeActivity').updateValueAndValidity();
    } else {
      this.editActivityForm
        .get('timeActivity')
        .setValidators(this.checkTimeBeforeNow('edit'));
      this.editActivityForm.get('timeActivity').updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
