<form appSrollOnError [formGroup]="customerForm" (ngSubmit)="onSubmit()">
  <div class="section-card">
    <div class="content">
      <div class="row">
        <mat-form-field
          appearance="standard"
          class="col-3"
          [ngClass]="
            !customerForm.get('businessName')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="businessName">
            {{ "COMMON.BusinessName" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            formControlName="businessName"
            name="businessName"
          />
          <mat-error *ngIf="!customerForm.get('businessName').valid">
            {{
              customerForm.get("businessName").errors
                | getErrorMessage: translations.BusinessName
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-3"
          [ngClass]="
            !customerForm.get('yearFoundation')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="yearFoundation">
            {{ "IR_FORM.YearFoundation" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="yearFoundation"
            type="number"
            min="1900"
            name="yearFoundation"
            onKeyPress="this.value.length === 4;"
          />
          <mat-error *ngIf="!customerForm.get('yearFoundation').valid">
            {{
              customerForm.get("yearFoundation").errors
                | getErrorMessage: translations.YearFoundation
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-3"
          [ngClass]="
            !customerForm.get('vatNumber')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="vatNumber">{{
            "COMMON.VatNumber" | translate
          }}</mat-label>
          <input
            matInput
            formControlName="vatNumber"
            name="vatNumber"
            type="url"
          />
          <mat-error *ngIf="customerForm.get('vatNumber').invalid">
            {{
              customerForm.get("vatNumber").errors
                | getErrorMessage: translations.VatNumber
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-3"
        >
          <mat-label for="fiscalCode">{{
            "COMMON.FiscalCode" | translate
          }}</mat-label>
          <input
            #fiscalCodeInput
            matInput
            formControlName="fiscalCode"
            type="url"
            name="fiscalCode"
            (keyup)="fiscalCodeInput.value = $event.target.value.toUpperCase()"
          />
          <mat-error *ngIf="customerForm.get('fiscalCode').invalid">
            {{
              customerForm.get("fiscalCode").errors
                | getErrorMessage: translations.FiscalCode
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row" formGroupName="address">
        <mat-form-field
          *ngIf="countries"
          appearance="standard"
          class="col-3"
          formGroupName="country"
          [ngClass]="
            !customerForm.get('address.country.idCountry')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="idCountry">{{
            "IR_FORM.Country" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idCountry"
            name="idCountry"
            (selectionChange)="setPrefixPhoneSelect($event)"
            (closed)="filter.value = ''"
          >
            <div class="select-global-filter">
              <input
                #filter
                matInput
                type="text"
                [placeholder]="'COMMON.Search...' | translate"
              />
              <button
                matSuffix
                *ngIf="filter.value !== ''"
                class="only-icon-button"
                type="button"
                (click)="filter.value = null"
              >
                <mat-icon>clear</mat-icon>
              </button>
            </div>
            <mat-option
              [ngStyle]="{
                display:
                  (countries | filterSelect: filter.value:'country').includes(
                    country
                  ) || filter.value === ''
                    ? 'flex'
                    : 'none'
              }"
              *ngFor="let country of countries"
              [value]="country.idCountry"
            >
              {{ country.country }}
            </mat-option>
            <div
              *ngIf="
                !(countries | filterSelect: filter.value:'country')?.length
              "
              class="select-global-filter-no-result"
            >
              {{ "COMMON.NoResult" | translate }}
            </div>
          </mat-select>
          <mat-error
            *ngIf="
              customerForm.controls.address.get('country.idCountry').invalid
            "
          >
            {{
              customerForm.controls.address.get("country.idCountry").errors
                | getErrorMessage: translations?.Country
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-3"
          [ngClass]="
            !customerForm.get('address.province')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="province">{{
            "COMMON.StateProvince" | translate
          }}</mat-label>
          <input
            #provinceInput
            matInput
            formControlName="province"
            type="text"
            name="province"
            (keyup)="provinceInput.value = $event.target.value.toUpperCase()"
          />
          <mat-error *ngIf="customerForm.get('address.province').invalid">
            {{ customerForm.get("address.province").errors
              | getErrorMessage: translations?.StateProvince
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-3"
          [ngClass]="
            !customerForm.get('address.city')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="city">{{ "COMMON.City" | translate }}</mat-label>
          <input
            matInput
            formControlName="city"
            type="text"
            name="city"
            (focusout)="checkCity()"
          />
          <mat-error *ngIf="customerForm.get('address.city').invalid">
            {{ customerForm.get("address.city").errors
              | getErrorMessage: translations?.City
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-3"
          [ngClass]="
            !customerForm.get('address.address')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="address">{{
            "COMMON.Address" | translate
          }}</mat-label>
          <input
            matInput
            formControlName="address"
            type="text"
            name="address"
            (focusout)="checkAndSetCoordinatesAutomatically()"
          />
          <button
            matSuffix
            type="button"
            class="only-icon-button"
            [ngClass]="
              customerForm.get('address.position')?.value?.length > 0
                ? 'primary'
                : 'error'
            "
            (click)="openPositionDialog()"
            [disabled]="isReadOnly || isLabManagerInternal"
          >
            <svg-icon
              *ngIf="customerForm?.get('address.position')?.value?.length > 0"
              name="pin_check"
              class="w20"
              matTooltip="{{ 'COMMON.Coordinates' | translate }}"
            ></svg-icon>
            <svg-icon
              *ngIf="customerForm?.get('address.position')?.value?.length < 1"
              name="pin_error"
              class="w20"
              matTooltip="{{ 'COMMON.Coordinates' | translate }}"
            ></svg-icon>
          </button>
          <mat-error *ngIf="customerForm.get('address.address').invalid">
            {{ customerForm.get("address.address").errors
              | getErrorMessage: translations?.Address
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field
          appearance="standard"
          class="col-3"
          formGroupName="address"
          [ngClass]="
            !customerForm.get('address.cap')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="cap">{{ "COMMON.Cap" | translate }}</mat-label>
          <input
            matInput
            formControlName="cap"
            type="text"
            name="cap"
            onKeyPress="this.value.length === 5;"
          />
          <mat-error *ngIf="!customerForm.get('address.cap').valid">
            {{
              customerForm.get("address.cap").errors | getErrorMessage: "CAP"
            }}
          </mat-error>
        </mat-form-field>
        <div class="col-3 d-flex">
          <div class="row">
            <mat-form-field
              appearance="standard"
              class="col-4"
              [ngClass]="
                !prefixNumber && isIsp ? 'red-field-for-lab-manager' : ''
              "
            >
              <mat-label>
                {{ "IR_FORM.Prefix" | translate }}
              </mat-label>
              <mat-select
                [(value)]="prefixNumber"
                [disabled]="isReadOnly || isLabManagerInternal"
              >
                <mat-option
                  *ngFor="let country of countries"
                  [value]="country.prefixNumber"
                >
                  {{ country?.prefixNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field
              appearance="standard"
              class="col-8"
              [ngClass]="
                !customerForm.get('telephoneNumber')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="telephoneNumber">
                {{ "COMMON.Telephone" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="telephoneNumber"
                type="tel"
                name="telephoneNumber"
              />
              <mat-error *ngIf="!customerForm.get('telephoneNumber').valid">
                {{
                  customerForm.get("telephoneNumber").errors
                    | getErrorMessage: "Telefono"
                }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-form-field
          appearance="standard"
          class="col-3"
          [ngClass]="
            !customerForm.get('email')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="email">{{ "COMMON.Email" | translate }}</mat-label>
          <input matInput formControlName="email" type="email" name="email" />
          <mat-error *ngIf="!customerForm.get('email').valid">
            {{ customerForm.get("email").errors | getErrorMessage: "Email" }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-3"
          [ngClass]="
            !customerForm.get('webSiteLink')?.value && isIsp
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="webSiteLink">{{
            "COMMON.Website" | translate
          }}</mat-label>
          <input
            matInput
            formControlName="webSiteLink"
            type="url"
            name="webSiteLink"
          />
          <mat-error *ngIf="!customerForm.get('webSiteLink').valid">
            {{ customerForm.get("webSiteLink").errors | getErrorMessage: translations?.Website }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <ng-container formArrayName="customerContact">
    <div class="row mt-40">
      <div
        class="col-6 mb-30"
        *ngFor="let contact of customerContact.controls; let i = index"
      >
        <div class="section-card">
          <div
            class="header h70 multi"
            [ngClass]="
              contact.get('customerReferenceType.idCustomerReferenceType')
                .value > 4
                ? 'multi'
                : ''
            "
          >
            <div class="title">
              <span>{{
                "IR_FORM." +
                  contactReferenceType[
                    contact.get("customerReferenceType.idCustomerReferenceType")
                      .value
                  ] | translate
              }}</span>
            </div>
            <div
              *ngIf="
                contact.get('customerReferenceType.idCustomerReferenceType')
                  .value < 5
              "
              class="clone-referent"
            >
              <button
                *ngIf="!isReadOnly && !isLabManagerInternal"
                type="button"
                class="only-icon-button primary d-inline-block"
                (click)="copyReferent(contact.getRawValue())"
              >
                <svg-icon
                  name="icon_copy"
                  class="w20"
                  matTooltip="{{ 'COMMON.CopyReferent' | translate }}"
                ></svg-icon>
              </button>
              <button
                *ngIf="!isReadOnly && !isLabManagerInternal"
                type="button"
                class="only-icon-button primary d-inline-block ml-10"
                (click)="pasteReferent(i)"
              >
                <svg-icon
                  name="icon_paste"
                  class="w20"
                  matTooltip="{{ 'COMMON.PasteReferent' | translate }}"
                ></svg-icon>
              </button>
            </div>
            <div
              class="delete-card"
              *ngIf="
                contact.get('customerReferenceType.idCustomerReferenceType')
                  .value > 4
                  ? 'multi'
                  : ''
              "
            >
              <button
                *ngIf="!isReadOnly && !isLabManagerInternal"
                type="button"
                class="only-icon-button error"
                (click)="removeCompanyContact(contact, i)"
              >
                <svg-icon name="trash_icon" class="w20"></svg-icon>
              </button>
            </div>
          </div>
          <div class="content" [formGroupName]="i">
            <div class="row">
              <mat-form-field
                appearance="standard"
                class="col-6"
                [ngClass]="
                  !contact.get('title')?.value && isIsp
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="title">
                  {{ "IR_FORM.Title" | translate }}
                </mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="title"
                  name="title"
                >
                  <mat-option *ngFor="let title of titleType" [value]="title">
                    {{ "TITLE_TYPE." + title | translate }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="!contact.get('title').valid">
                  {{
                    contact.get("title").errors
                      | getErrorMessage: translations.Title
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                appearance="standard"
                class="col-6"
                [ngClass]="
                  !contact.get('name')?.value && isIsp
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="name">{{
                  "COMMON.Name" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="name"
                  type="text"
                  name="name"
                />
                <mat-error *ngIf="!contact.get('name').valid">
                  {{
                    contact.get("name").errors
                      | getErrorMessage: translations.Name
                  }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <mat-form-field
                appearance="standard"
                class="col-6"
                [ngClass]="
                  !contact.get('surname')?.value && isIsp
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="surname">{{
                  "COMMON.Surname" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="surname"
                  type="text"
                  name="surname"
                />
                <mat-error *ngIf="!contact.get('surname').valid">
                  {{
                    contact.get("surname").errors
                      | getErrorMessage: translations.Surname
                  }}
                </mat-error>
              </mat-form-field>
              <!-- <mat-form-field
                appearance="standard"
                class="col-6"
                formGroupName="role"
                [ngClass]="
                  !contact.get('role.idCompanyRoleType')?.value && isIsp
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="role">
                  {{ "IR_FORM.CompanyEmployment" | translate }}
                </mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="idCompanyRoleType"
                >
                  <mat-option
                    *ngFor="let role of referentRoles"
                    [value]="role.idCompanyRoleType"
                  >
                    {{ role.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
              <mat-form-field
                appearance="standard"
                class="col-6"
                [ngClass]="
                  !contact.get('email')?.value && isIsp
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="email">
                  {{ "COMMON.Email" | translate }}
                </mat-label>
                <input
                  matInput
                  formControlName="email"
                  type="email"
                  name="email"
                />
                <mat-error *ngIf="!contact.get('email').valid">
                  {{
                    contact.get("email").errors
                      | getErrorMessage: translations.Email
                  }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-6" style="padding: 0;">
                <mat-form-field
                  appearance="standard"
                  class="col-4"
                  [ngClass]="
                    !prefixNumber && isIsp ? 'red-field-for-lab-manager' : ''
                  "
                >
                  <mat-label>
                    {{ "IR_FORM.Prefix" | translate }}
                  </mat-label>
                  <mat-select
                    [(value)]="prefixNumber"
                    [disabled]="isReadOnly || isLabManagerInternal"
                  >
                    <mat-option
                      *ngFor="let country of countries"
                      [value]="country.prefixNumber"
                    >
                      {{ country?.prefixNumber }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field
                  appearance="standard"
                  class="col-8"
                >
                  <mat-label for="telephoneNumber">
                    {{ "COMMON.Telephone" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="telephoneNumber"
                    type="tel"
                    name="telephoneNumber"
                  />
                  <mat-error *ngIf="!contact.get('telephoneNumber').valid">
                    {{
                      contact.get("telephoneNumber").errors
                        | getErrorMessage: translations.Telephone
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- <mat-form-field
                appearance="standard"
                class="col-6"
                [ngClass]="
                  !contact.get('telephoneNumber')?.value && isIsp
                    ? 'red-field-for-lab-manager'
                    : ''
                "
              >
                <mat-label for="telephoneNumber">
                  {{ "COMMON.Telephone" | translate }}
                </mat-label>
                <input
                  matInput
                  formControlName="telephoneNumber"
                  type="string"
                  name="telephoneNumber"
                />
                <mat-error *ngIf="!contact.get('telephoneNumber').valid">
                  {{
                    contact.get("telephoneNumber").errors
                      | getErrorMessage: translations.Telephone
                  }}
                </mat-error>
              </mat-form-field> -->
              <mat-form-field appearance="standard" class="col-6">
                <mat-label for="notes">{{
                  "COMMON.Notes" | translate
                }}</mat-label>
                <input
                  matInput
                  formControlName="notes"
                  type="text"
                  name="notes"
                />
                <mat-error *ngIf="!contact.get('notes').valid">
                  {{
                    contact.get("notes").errors
                      | getErrorMessage: translations.Notes
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="center-buttons">
    <button
      *ngIf="!isLabManagerInternal && !isReadOnly && !isNoContract"
      class="rounded-button primary-border-button mr-20"
      type="button"
      (click)="addCompanyContact()"
    >
      {{ "IR_FORM.AddReferent" | translate }}
    </button>
    <button
      *ngIf="!isLabManagerInternal && !isReadOnly"
      class="rounded-button primary-bg-button"
      type="submit"
    >
      {{ "COMMON.SaveChanges" | translate }}
    </button>
  </div>
</form>
<div class="mt-40">
  <button
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
