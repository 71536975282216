import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  title: string;
  message: string;
  secondMessage: string;
  messageClass: string;
  secondMessageClass: string;
  buttonFalse: string;
  buttonTrue: string;
  // centerTextTitle: boolean;
  // centerTextMessage: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.message = data.message;
    this.secondMessage = data.secondMessage;
    this.messageClass = data.messageClass;
    this.secondMessageClass = data.secondMessageClass;
    this.buttonFalse = data.buttonFalse;
    this.buttonTrue = data.buttonTrue;
    // this.centerTextMessage = data?.centerTextMessage;
  }

  ngOnInit(): void { }

}
