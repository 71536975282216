import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { PR_STATUS_PS_VERSION } from 'src/app/config';
import {
  IProjectForTable,
  IProjectFromHistoryList,
  ProjectDTO,
} from 'src/app/models/project';
import { PsFormService } from 'src/app/services/ps-form/ps-form.service';

@Component({
  selector: 'app-publish-project-dialog',
  templateUrl: './publish-project-dialog.component.html',
  styleUrls: ['./publish-project-dialog.component.scss'],
})
export class PublishProjectDialogComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  project: IProjectForTable;
  projectHistoryList: ProjectDTO;
  showPublishConfirmationButtons: boolean[] = [];
  prStatusPsVersion: any = PR_STATUS_PS_VERSION;
  projectLoader: boolean;
  publishLoaderList: boolean[] = [];

  constructor(private psService: PsFormService, @Inject(MAT_DIALOG_DATA) data) {
    this.project = data.projectData;
  }

  ngOnInit(): void {
    this.getProjectHistory();
  }

  getProjectHistory(): void {
    this.projectLoader = true;
    this.subscriptions.add(
      this.psService
        .projectsHistory(
          this.project.idProject,
          this.project.idCustomer || this.project.customer.idCustomer,
          true
        )
        .subscribe({
          next: (project: ProjectDTO) => {
            this.projectLoader = false;
            this.projectHistoryList = project;
            this.projectHistoryList.projectHistoryList.forEach(
              (projectVersion: IProjectFromHistoryList) => {
                this.showPublishConfirmationButtons = [];
                this.publishLoaderList = [];
                this.showPublishConfirmationButtons.push(false);
                this.publishLoaderList.push(false);
              }
            );
          },
          error: () => {
            this.projectLoader = false;
          },
        })
    );
  }

  checkIfOthersShowPublicConfirmationButtonsAreOpen(): boolean {
    let ret: boolean = false;
    this.showPublishConfirmationButtons.forEach((item: boolean) => {
      if (item) {
        ret = true;
      }
    });
    return ret;
  }

  checkIfAtLeastOnePublishLoaderIsTrue(): boolean {
    let ret: boolean = false;
    this.publishLoaderList.forEach((item: boolean) => {
      if (item) {
        ret = true;
      }
    });
    return ret;
  }

  publishProjectVersion(project: IProjectFromHistoryList, index: number): void {
    this.showPublishConfirmationButtons[index] = false;
    this.publishLoaderList[index] = true;
    this.subscriptions.add(
      this.psService
        .publishProject(
          project.idProject,
          this.project.idCustomer || this.project.customer.idCustomer,
          project.idProjectStatus,
          true
        )
        .subscribe({
          complete: () => {
            this.publishLoaderList[index] = false;
            this.getProjectHistory();
          },
          error: () => {
            this.publishLoaderList[index] = false;
          },
        })
    );
  }
}
