<form [formGroup]="usersFormGroup" (ngSubmit)="saveUser()" class="ir-form">
  <div class="header">
    <div class="title">
      <span *ngIf="user?.name"> {{ user?.name }} {{ user?.surname }} </span>
      <span *ngIf="!user?.name">
        {{ "COMMON.NewUser" | translate }}
      </span>
    </div>
    <button
      class="close-dialog"
      mat-dialog-close
      mat-icon-button
      [disabled]="loading"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <div class="row">
      <mat-form-field class="col-4">
        <mat-label for="name">{{
          "USERS_MANAGEMENT.Name" | translate
        }}</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-error
          *ngIf="
            !usersFormGroup.get('name').valid &&
            usersFormGroup.get('name').touched
          "
        >
          {{ "USERS_MANAGEMENT.NameRequired" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-4">
        <mat-label for="surname">Cognome</mat-label>
        <input matInput type="text" formControlName="surname" />
        <mat-error
          *ngIf="
            !usersFormGroup.get('surname').valid &&
            usersFormGroup.get('surname').touched
          "
        >
          {{ "USERS_MANAGEMENT.SurnameRequired" | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-4">
        <mat-label for="telephone">{{
          "USERS_MANAGEMENT.Phone" | translate
        }}</mat-label>
        <input matInput type="number" formControlName="telephone" />
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-2">
        <mat-label for="province">{{
          "USERS_MANAGEMENT.StateProvince" | translate
        }}</mat-label>
        <input matInput type="text" formControlName="province" />
        <mat-error>
          {{
            usersFormGroup.get("province").errors
              | getErrorMessage: translations.StateProvince
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label for="city">{{
          "USERS_MANAGEMENT.City" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="city"
          type="text"
          name="city"
          (focusout)="checkCity()"
        />
        <mat-error>
          {{
            usersFormGroup.get("city").errors
              | getErrorMessage: translations.City
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-2">
        <mat-label for="cap">{{
          "USERS_MANAGEMENT.CAP" | translate
        }}</mat-label>
        <input matInput type="text" formControlName="cap" />
        <mat-error>
          {{
            usersFormGroup.get("cap").errors | getErrorMessage: translations.CAP
          }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-5">
        <mat-label for="address">{{
          "USERS_MANAGEMENT.Address" | translate
        }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="address"
          name="address"
          type="text"
          (focusout)="checkAndSetCoordinatesAutomatically()"
        />
        <button
          matSuffix
          type="button"
          class="only-icon-button"
          [ngClass]="
            usersFormGroup.get('residencePosition')?.value?.length > 0
              ? 'primary'
              : 'error'
          "
          (click)="openPositionDialog()"
        >
          <svg-icon
            *ngIf="usersFormGroup?.get('residencePosition')?.value?.length > 0"
            name="pin_check"
            class="w20"
            matTooltip="{{ 'COMMON.Coordinates' | translate }}"
          ></svg-icon>
          <svg-icon
            *ngIf="usersFormGroup?.get('residencePosition')?.value?.length < 1"
            name="pin_error"
            class="w20"
            matTooltip="{{ 'COMMON.Coordinates' | translate }}"
          ></svg-icon>
        </button>
        <mat-error>
          {{
            usersFormGroup.get("email").errors
              | getErrorMessage: translations.Address
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-6">
        <mat-label for="email">{{
          "USERS_MANAGEMENT.Email" | translate
        }}</mat-label>
        <input matInput type="email" formControlName="email" name="email" />
        <mat-error *ngIf="!usersFormGroup.get('email').valid">
          {{ usersFormGroup.get("email").errors | getErrorMessage: "E-mail" }}
        </mat-error>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label for="confirmEmail">{{
          "USERS_MANAGEMENT.ConfirmEmail" | translate
        }}</mat-label>
        <input
          matInput
          type="email"
          formControlName="confirmEmail"
          name="confirmEmail"
        />
        <mat-error *ngIf="!usersFormGroup.get('confirmEmail').valid">
          {{
            usersFormGroup.get("confirmEmail").errors
              | getErrorMessage: "ConferMa E-mail"
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field
        [ngClass]="showDemonstratorCheckbox ? 'col-6' : 'col-12'"
        (click)="setPreviousRoles()"
      >
        <mat-label for="roles">{{
          "USERS_MANAGEMENT.Roles" | translate
        }}</mat-label>
        <mat-select
          disableOptionCentering
          panelClass="custom-panel-class"
          formControlName="roles"
          (selectionChange)="onRoleChange($event)"
          multiple
        >
          <mat-option
            *ngFor="let roleItem of roles"
            [value]="roleItem"
            [disabled]="checkIfDisableRolesSelection(roleItem)"
          >
            {{ "USERS_MANAGEMENT." + roleItem | translate }}</mat-option
          >
        </mat-select>
        <mat-error
          *ngIf="
            !usersFormGroup.get('roles').valid &&
            usersFormGroup.get('roles').touched
          "
        >
          {{ "USERS_MANAGEMENT.RolesRequired" | translate }}
        </mat-error>
      </mat-form-field>
      <div class="col-6 demonstrator-checkbox">
        <mat-checkbox
          *ngIf="showDemonstratorCheckbox"
          color="primary"
          formControlName="demonstrator"
        >
          {{ "USERS_MANAGEMENT.CanVisualizeMasterAndDemoProjects" | translate }}
        </mat-checkbox>
      </div>
    </div>
    <div
      class="row"
      *ngIf="
        customerList.length > 0 &&
        usersFormGroup.get('roles').value.includes('CUSTOMER')
      "
    >
      <mat-form-field class="col-6">
        <mat-label for="idCustomer">{{
          "USERS_MANAGEMENT.CUSTOMER" | translate
        }}</mat-label>
        <mat-select
          disableOptionCentering
          panelClass="custom-panel-class"
          formControlName="idCustomer"
        >
          <mat-option
            *ngFor="let customerItem of customerList"
            [value]="customerItem.idCustomer"
            >{{ customerItem.businessName }}</mat-option
          >
        </mat-select>
        <mat-error>
          {{ "USERS_MANAGEMENT.CustomerRequired" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <div
      *ngIf="!usersFormGroup?.get('roles')?.value?.includes('CUSTOMER')"
      class="row"
    >
      <mat-form-field class="col-12">
        <mat-label for="expertiseArea">{{
          "USERS_MANAGEMENT.CompetenceArea" | translate
        }}</mat-label>
        <mat-select
          #competenceAreaSelect
          disableOptionCentering
          panelClass="custom-panel-class"
          formControlName="expertiseArea"
          multiple
        >
          <div class="mat-option">
            <button
              type="button"
              class="rounded-button primary-bg-button select-deselect-all"
              (click)="selectDeselectAllCompetenceArea()"
            >
              {{ "COMMON.SelectDeselectAll" | translate }}
            </button>
          </div>
          <mat-option
            *ngFor="let province of provinceList"
            [value]="province.idProvince"
          >
            {{ province.name }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ "USERS_MANAGEMENT.CompetenceAreaRequired" | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="buttons center">
    <!-- <button
      *ngIf="contentDialogType === 'Edit'"
      type="button"
      class="rounded-button error-transparent-bg-button mr-20"
      (click)="deleteContentFromDialog()"
      [disabled]="loading"
    >
      {{ "COMMON.Delete" | translate }}
    </button> -->
    <button
      type="submit"
      class="rounded-button primary-bg-button"
      [disabled]="loading"
    >
      {{ "COMMON.Save" | translate }}
    </button>
  </div>
</form>

<!-- CODICE VECCHIO -->
<!-- CODICE VECCHIO -->
<!-- CODICE VECCHIO -->
<!-- CODICE VECCHIO -->
<!-- CODICE VECCHIO -->

<!-- <div class="user-container">
  <div class="user-title">
    <span>{{ formTitle | translate }}</span>
    <button
      mat-mini-fab
      [color]="openForm ? 'warn' : 'primary'"
      type="button"
      [ngClass]="openForm == false ? 'app-bg-color' : ''"
      class="add-user-button custom-button"
      (click)="openForm = !openForm"
      [matTooltip]="formTitle | translate"
    >
      <mat-icon *ngIf="openForm == false">add</mat-icon>
      <mat-icon *ngIf="openForm == true">remove</mat-icon>
    </button>
  </div>
  <div [hidden]="!openForm" class="user-form-container">
    <form [formGroup]="usersFormGroup" class="user-form">
      <div class="row">
        <mat-form-field class="col-12 col-sm-6">
          <mat-label for="name">{{
            "USERS_MANAGEMENT.Name" | translate
          }}</mat-label>
          <input matInput type="text" formControlName="name" />
          <mat-error
            *ngIf="
              !usersFormGroup.get('name').valid &&
              usersFormGroup.get('name').touched
            "
          >
            {{ "USERS_MANAGEMENT.NameRequired" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-12 col-sm-6">
          <mat-label for="surname">Cognome</mat-label>
          <input matInput type="text" formControlName="surname" />
          <mat-error
            *ngIf="
              !usersFormGroup.get('surname').valid &&
              usersFormGroup.get('surname').touched
            "
          >
            {{ "USERS_MANAGEMENT.SurnameRequired" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12 col-sm-8">
          <mat-label for="address">{{
            "USERS_MANAGEMENT.Address" | translate
          }}</mat-label>
          <input matInput type="text" formControlName="address" />
        </mat-form-field>
        <mat-form-field class="col-12 col-sm-4">
          <mat-label for="cap">{{
            "USERS_MANAGEMENT.CAP" | translate
          }}</mat-label>
          <input matInput type="text" formControlName="cap" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12 col-sm-8">
          <mat-label for="city">{{
            "USERS_MANAGEMENT.City" | translate
          }}</mat-label>
          <input matInput type="text" formControlName="city" />
        </mat-form-field>
        <mat-form-field class="col-12 col-sm-4">
          <mat-label for="province">{{
            "USERS_MANAGEMENT.StateProvince" | translate
          }}</mat-label>
          <input matInput type="text" formControlName="province" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12 col-sm-7">
          <mat-label for="email">{{
            "USERS_MANAGEMENT.Email" | translate
          }}</mat-label>
          <input matInput type="email" formControlName="email" name="email" />
          <mat-error *ngIf="!usersFormGroup.get('email').valid">
            {{ usersFormGroup.get("email").errors | getErrorMessage: "E-mail" }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-12 col-sm-5">
          <mat-label for="telephone">{{
            "USERS_MANAGEMENT.Phone" | translate
          }}</mat-label>
          <input matInput type="number" formControlName="telephone" />
        </mat-form-field>
        <mat-form-field class="col-12 col-sm-7">
          <mat-label for="confirmEmail">{{
            "USERS_MANAGEMENT.ConfirmEmail" | translate
          }}</mat-label>
          <input
            matInput
            type="email"
            formControlName="confirmEmail"
            name="confirmEmail"
          />
          <mat-error *ngIf="!usersFormGroup.get('confirmEmail').valid">
            {{
              usersFormGroup.get("confirmEmail").errors
                | getErrorMessage: "ConferMa E-mail"
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12 col-sm-5">
          <mat-label for="competenceArea">{{
            "USERS_MANAGEMENT.CompetenceArea" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="competenceArea"
          >
            <mat-option *ngFor="let area of areaList" [value]="area">{{
              area
            }}</mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              !usersFormGroup.get('competenceArea').valid &&
              usersFormGroup.get('competenceArea').touched
            "
          >
            {{ "USERS_MANAGEMENT.CompetenceAreaRequired" | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-12 col-sm-7">
          <mat-label for="roles">{{
            "USERS_MANAGEMENT.Roles" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="roles"
            multiple
          >
            <mat-option
              *ngFor="let roleItem of roles"
              [value]="roleItem"
              (onSelectionChange)="onRoleChange($event)"
            >
              {{ "USERS_MANAGEMENT." + roleItem | translate }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              !usersFormGroup.get('roles').valid &&
              usersFormGroup.get('roles').touched
            "
          >
            {{ "USERS_MANAGEMENT.RolesRequired" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row" *ngIf="customerList && customerList.length > 0">
        <mat-form-field class="col-12 col-sm-7">
          <mat-label for="roles">{{
            "USERS_MANAGEMENT.CUSTOMER" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idCustomer"
          >
            <mat-option
              *ngFor="let customerItem of customerList"
              [value]="customerItem.idCustomer"
              >{{ customerItem.businessName }}</mat-option
            >
          </mat-select>
          <mat-error
            *ngIf="
              !usersFormGroup.get('idCustomer').valid &&
              usersFormGroup.get('idCustomer').touched
            "
          >
            {{ "USERS_MANAGEMENT.RolesRequired" | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
    <div class="user-form-actions mt-3 mb-1">
      <button
        [disabled]="!usersFormGroup.valid"
        mat-mini-fab
        color="primary"
        [ngClass]="!usersFormGroup.valid ? '' : 'app-bg-color'"
        type="button"
        class="save-user"
        (click)="submit()"
        matTooltip="Salva utente"
      >
        <mat-icon>save</mat-icon>
      </button>
      <button
        mat-mini-fab
        color="warn"
        type="button"
        class="clear-fields ml-5"
        (click)="resetForm()"
        matTooltip="Annulla"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
  </div>
</div> -->
