import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { de } from 'date-fns/locale';
import { Subscription } from 'rxjs';
import {
  IChannelSpecification,
  IChannelsType,
} from 'src/app/models/channels-type';
import { CommonService } from 'src/app/services/common/common.service';
import { IrFormService } from 'src/app/services/ir-form/ir-form.service';
import {
  ItemFieldValueInArrayCannotBeSame,
  ItemFieldValueInArrayCannotBeSameOnlyString,
} from 'src/app/utility/validators';

@Component({
  selector: 'app-channels-management-dialog',
  templateUrl: './channels-management-dialog.component.html',
  styleUrls: ['./channels-management-dialog.component.scss'],
})
export class ChannelsManagementDialogComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();
  channelsData: IChannelsType[];
  channelsSpecifications: IChannelSpecification[];
  unitsOfMeasure: any;
  idCustomer: number;
  translations: any;
  loading: boolean;
  idChannelType: number;
  refresh: boolean;

  managementType: string;

  channelsForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ChannelsManagementDialogComponent>,
    private irFormService: IrFormService,
    private common: CommonService,
    private translate: TranslateService
  ) {
    this.managementType = data.managementType;
    this.channelsData = data?.channels;
    this.channelsSpecifications = data?.channelsSpecifications;
    this.unitsOfMeasure = data?.uom;
    this.idCustomer = data.idCustomer;
    this.idChannelType = data.idChannelType;
    this.channelsForm = this.fb.group({
      channels: this.fb.array([]),
      channelsSpecifications: this.fb.array([]),
      unitsOfMeasure: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.translate.get('CHANNELS_MANAGEMENT').subscribe({
        next: (data: any) => {
          this.translations = data;
          if (this.managementType === 'channel') {
            this.channelsData?.forEach((channel: IChannelsType) => {
              if (channel.idCustomer) {
                this.addChannel(channel, 'custom');
              } else {
                this.addChannel(channel, 'default');
              }
            });
            this.channels.setValidators(
              ItemFieldValueInArrayCannotBeSameOnlyString('description')
            );
          }
          if (this.managementType === 'channelSpecification') {
            this.channelsSpecifications?.forEach(
              (channelSpec: IChannelSpecification) => {
                if (channelSpec.idCustomer) {
                  this.addChannelSpec(channelSpec, 'custom');
                } else {
                  this.addChannelSpec(channelSpec, 'default');
                }
              }
            );
            this.channelsSpec.setValidators(
              ItemFieldValueInArrayCannotBeSameOnlyString('description')
            );
          }
          if (this.managementType === 'uom') {
            this.unitsOfMeasure?.forEach((uom: any) => {
              if (uom.idCustomer) {
                this.addUom(uom, 'custom');
              } else {
                this.addUom(uom, 'default');
              }
            });
          }
        },
      })
    );
  }

  get channels(): FormArray {
    return this.channelsForm.get('channels') as FormArray;
  }

  get channelsSpec(): FormArray {
    return this.channelsForm.get('channelsSpecifications') as FormArray;
  }

  get uoms(): FormArray {
    return this.channelsForm.get('unitsOfMeasure') as FormArray;
  }

  addChannel(channel?: IChannelsType, type?: string): void {
    this.channels.push(
      this.fb.group({
        idChannelType: [channel?.idChannelType || null],
        description: [
          {
            value: channel?.description || null,
            disabled: type === 'default' ? true : null,
          },
          [Validators.required, Validators.maxLength(255)],
        ],
        idCustomer: [type === 'default' ? null : this.idCustomer],
      })
    );
  }

  removeChannel(i: number): void {
    this.channels.removeAt(i);
  }

  deleteChannel(channel: IChannelsType, i: number): void {
    this.irFormService.deleteChannelType(channel.idChannelType).subscribe({
      complete: () => {
        this.removeChannel(i);
        this.refresh = true;
      },
    });
  }

  addChannelSpec(channelSpec?: IChannelSpecification, type?: string): void {
    this.channelsSpec.push(
      this.fb.group({
        idSpecificationChannelType: [
          channelSpec?.idSpecificationChannelType || null,
        ],
        idChannelType: [channelSpec?.idChannelType || this.idChannelType],
        description: [
          {
            value: channelSpec?.description || null,
            disabled: type === 'default' ? true : null,
          },
          [Validators.required, Validators.maxLength(255)],
        ],
        idCustomer: [type === 'default' ? null : this.idCustomer],
      })
    );
  }

  removeChannelSpec(i: number): void {
    this.channelsSpec.removeAt(i);
  }

  deleteChannelSpecification(spec: IChannelSpecification, i: number): void {
    this.common
      .deleteChannelSpecification(spec.idSpecificationChannelType)
      .subscribe({
        complete: () => {
          this.channelsSpec.removeAt(i);
          this.refresh = true;
        },
      });
  }

  addUom(uom: any, type?: string): void {
    this.uoms.push(
      this.fb.group({
        idUom: [uom?.idUom || null],
        description: [
          {
            value: uom?.description || null,
            disabled: type === 'default' ? true : null,
          },
          [Validators.required, Validators.maxLength(255)],
        ],
        name: [
          {
            value: uom?.name || null,
            disabled: type === 'default' ? true : null,
          },
          [Validators.required, Validators.maxLength(255)],
        ],
        idCustomer: [type === 'default' ? null : this.idCustomer],
      })
    );
  }

  removeUom(i: number): void {
    this.uoms.removeAt(i);
  }

  submit(): void {
    if (this.managementType === 'channel') {
      this.saveChannels();
    }
    if (this.managementType === 'channelSpecification') {
      this.saveChannelsSpecifications();
    }
    if (this.managementType === 'uom') {
      this.saveUoms();
    }
  }

  saveChannels(): void {
    if (this.channelsForm.valid && this.channelsForm.dirty) {
      const onlyCustomChannels: any[] = [];
      this.channelsForm
        .getRawValue()
        .channels.forEach((channel: any, index: number) => {
          if (channel.idCustomer) {
            if (
              channel.description !== this.channelsData[index]?.description ||
              !channel.idChannelType
            ) {
              onlyCustomChannels.push(channel);
            }
          }
        });
      if (onlyCustomChannels.length) {
        this.loading = true;
        this.channelsForm.disable();
        this.subscriptions.add(
          this.irFormService
            .createUpdateChannelType(onlyCustomChannels)
            .subscribe({
              complete: () => {
                this.loading = false;
                this.channelsForm.enable();
                this.dialogRef.close('refresh');
              },
              error: () => {
                this.loading = false;
                this.channelsForm.enable();
              },
            })
        );
      } else {
        this.dialogRef.close(this.refresh ? 'refresh' : null);
      }
    }
    if (!this.channelsForm.dirty && this.channelsForm.valid) {
      this.dialogRef.close(this.refresh ? 'refresh' : null);
    }
  }

  saveChannelsSpecifications(): void {
    if (this.channelsForm.valid && this.channelsForm.dirty) {
      const newSpecifications: IChannelSpecification[] = [];
      this.channelsSpec
        .getRawValue()
        .forEach((spec: IChannelSpecification, index: number) => {
          if (
            spec?.description !==
              this.channelsSpecifications[index]?.description ||
            !spec.idSpecificationChannelType
          ) {
            newSpecifications.push(spec);
          }
        });
      if (newSpecifications.length) {
        this.loading = true;
        this.channelsForm.disable();
        this.subscriptions.add(
          this.common.saveChannelsSpecifications(newSpecifications).subscribe({
            complete: () => {
              this.loading = false;
              this.channelsForm.enable();
              this.dialogRef.close('refresh');
            },
            error: () => {
              this.loading = false;
              this.channelsForm.enable();
            },
          })
        );
      } else {
        this.dialogRef.close(this.refresh ? 'refresh' : null);
      }
    }
    if (!this.channelsForm.dirty && this.channelsForm.valid) {
      this.dialogRef.close(this.refresh ? 'refresh' : null);
    }
  }

  saveUoms(): void {
    if (this.channelsForm.valid && this.channelsForm.dirty) {
      const onlyCustomUoms: any[] = [];
      this.channelsForm
        .getRawValue()
        .unitsOfMeasure.forEach((uom: any, index: number) => {
          if (uom.idCustomer) {
            if (uom.name !== this.uoms[index]?.name || !uom.idUom) {
              onlyCustomUoms.push(uom);
            }
          }
        });
      if (onlyCustomUoms.length) {
        this.loading = true;
        this.channelsForm.disable();
        this.subscriptions.add(
          this.irFormService.updateUnitsOfMeasure(onlyCustomUoms).subscribe({
            complete: () => {
              this.loading = false;
              this.channelsForm.enable();
              this.dialogRef.close('refresh');
            },
            error: () => {
              this.loading = false;
              this.channelsForm.enable();
            },
          })
        );
      } else {
        this.dialogRef.close();
      }
    }
    if (!this.channelsForm.dirty && this.channelsForm.valid) {
      this.dialogRef.close();
    }
  }
}
