<form
  appSrollOnError
  (scrollOnErrorEvent)="expandPanelOnScroll()"
  [formGroup]="leadGenerationForm"
  (ngSubmit)="onSubmit()"
  class="ir-form"
>
  <ng-container formGroupName="leadGenerationRoot">
    <div class="section-card">
      <div class="content">
        <div class="d-flex">
          <label>
            {{ "IR_FORM.LeadActivity" | translate }}
          </label>
          <mat-radio-group 
            formControlName="useLead" 
            (change)="toggleNoLeadGenReasonRequired($event.value)"
          >
            <mat-radio-button [value]="true">
              {{ "IR_FORM.Yes" | translate }}
            </mat-radio-button>
            <mat-radio-button [value]="false">
              {{ "IR_FORM.No" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <mat-form-field
          *ngIf="
            leadGenerationForm.get('leadGenerationRoot.useLead').value ===
              false ||
            leadGenerationForm.get('leadGenerationRoot.useLead').value === null
          "
          class="col-12 mt-10"
          [ngClass]="
            !leadGenerationForm.get('leadGenerationRoot.reasonDescription')
              ?.value
              ? 'red-field-for-lab-manager'
              : ''
          "
        >
          <mat-label for="reasonDescription">
            {{ "IR_FORM.ReasonDetailed" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="reasonDescription"
            name="reasonDescription"
          />
          <mat-error
            *ngIf="
              leadGenerationForm.get('leadGenerationRoot.reasonDescription')
                .invalid
            "
          >
            {{
              leadGenerationForm.get("leadGenerationRoot.reasonDescription")
                .errors | getErrorMessage: translations.Reason
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div
      class="section-card mt-20"
      *ngIf="
        leadGenerationForm.get('leadGenerationRoot.useLead').value === true
      "
    >
      <div class="content">
        <div class="d-flex">
          <label>
            {{ "IR_FORM.DedicatedResource" | translate }}
          </label>
          <mat-radio-group 
            formControlName="dedicatedResource"
            (change)="toggleLeadGenResourcesRequired($event.value)"
          >
            <mat-radio-button [value]="true">
              {{ "IR_FORM.Yes" | translate }}
            </mat-radio-button>
            <mat-radio-button [value]="false">
              {{ "IR_FORM.No" | translate }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          *ngIf="
            leadGenerationForm.get('leadGenerationRoot.dedicatedResource')
              .value === true &&
            leadGenerationForm.get('leadGenerationRoot.useLead').value === true
          "
          class="row mt-10"
        >
          <mat-form-field
            class="col-12"
            [ngClass]="
              !leadGenerationForm.get(
                'leadGenerationRoot.descriptionBackground'
              )?.value
                ? 'red-field-for-lab-manager'
                : ''
            "
          >
            <mat-label for="descriptionBackground">
              {{ "IR_FORM.DescriptionBackground" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="descriptionBackground"
              name="descriptionBackground"
            ></textarea>
            <mat-error
              *ngIf="
                leadGenerationForm.get('leadGenerationRoot.descriptionBackground')
                  .invalid
              "
            >{{
              leadGenerationForm.get("leadGenerationRoot.descriptionBackground")
                .errors | getErrorMessage: translations.DescriptionBackground
            }}</mat-error>
          </mat-form-field>
        </div>
        <div
          *ngIf="
            leadGenerationForm.get('leadGenerationRoot.dedicatedResource')
              .value === true &&
            leadGenerationForm.get('leadGenerationRoot.useLead').value === true
          "
          class="row"
        >
          <!-- <mat-form-field
            class="col-12"
            [ngClass]="
              !leadGenerationForm.get('leadGenerationRoot.descriptionActivity')
                ?.value
                ? 'red-field-for-lab-manager'
                : ''
            "
          >
            <mat-label for="descriptionActivity">
              {{ "IR_FORM.ActivitiesDetailDescription" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="descriptionActivity"
              name="descriptionActivity"
            ></textarea>
          </mat-form-field> -->
        </div>
        <div
          *ngIf="
            leadGenerationForm.get('leadGenerationRoot.dedicatedResource')
              .value === true &&
            leadGenerationForm.get('leadGenerationRoot.useLead').value === true
          "
          class="row"
        >
          <mat-form-field
            class="col-12"
            [ngClass]="
              !leadGenerationForm.get(
                'leadGenerationRoot.descriptionPerformance'
              )?.value
                ? 'red-field-for-lab-manager'
                : ''
            "
          >
            <mat-label for="descriptionPerformance">
              {{ "IR_FORM.DescriptionPerformance" | translate }}
            </mat-label>
            <textarea
              matInput
              formControlName="descriptionPerformance"
              name="descriptionPerformance"
            ></textarea>
            <mat-error
              *ngIf="
                leadGenerationForm.get('leadGenerationRoot.descriptionPerformance')
                  .invalid
              "
            >{{
              leadGenerationForm.get("leadGenerationRoot.descriptionPerformance")
                .errors | getErrorMessage: translations.Field
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container
    *ngIf="leadGenerationForm.get('leadGenerationRoot.useLead').value === true"
  >
    <mat-tab-group
      mat-align-tabs="center"
      dynamicHeight
      class="custom-mat-tab mt-50"
      *ngIf="index === tabIndex"
      [selectedIndex]="0"
    >
      <mat-tab>
        <ng-template matTabLabel>
          <span>{{ "IR_FORM.Actual" | translate }}</span>
        </ng-template>
        <div class="section mb-50">
          <div class="header">
            <div>
              <div class="title">
                <span>{{ "IR_FORM.OfflineActivities" | translate }}</span>
              </div>
              <div class="description">
                <span>
                  {{ "IR_FORM.OfflineActiviesDescription" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="content" formArrayName="actualOfflineActivities">
            <div
              *ngFor="
                let actualOfflineActivity of actualOfflineActivities.controls;
                let i = index
              "
              [formGroupName]="i"
              class="section-card left-element my-20"
            >
              <div class="content">
                <div class="row">
                  <mat-form-field
                    class="col-8"
                    [ngClass]="
                      !actualOfflineActivity.get('description')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="description">
                      {{ "IR_FORM.Activity" | translate }}
                    </mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="description"
                      name="description"
                    />
                    <mat-error
                      *ngIf="actualOfflineActivity.get('description').invalid"
                    >
                      {{
                        actualOfflineActivity.get("description").errors
                          | getErrorMessage: translations.Activity
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-2"
                  >
                    <mat-label for="revenueShare">{{
                      "IR_FORM.TurnoverQuote" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="revenueShare"
                      type="number"
                      min="0"
                      max="100"
                      name="revenueShare"
                    />
                    <span class="custom-suffix" matSuffix>%</span>
                    <mat-error
                      *ngIf="actualOfflineActivity.get('revenueShare').invalid"
                    >
                      {{
                        actualOfflineActivity.get("revenueShare").errors
                          | getErrorMessage: translations.TurnoverQuote
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-2"
                    [ngClass]="
                      !actualOfflineActivity.get('importanceLevel')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="importanceLevel">{{
                      "IR_FORM.ImportanceLevel" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="importanceLevel"
                      name="importanceLevel"
                    >
                      <mat-option [value]="1">1</mat-option>
                      <mat-option [value]="2">2</mat-option>
                      <mat-option [value]="3">3</mat-option>
                      <mat-option [value]="4">4</mat-option>
                      <mat-option [value]="5">5</mat-option>
                      <mat-option [value]="6">6</mat-option>
                      <mat-option [value]="7">7</mat-option>
                      <mat-option [value]="8">8</mat-option>
                      <mat-option [value]="9">9</mat-option>
                      <mat-option [value]="10">10</mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        actualOfflineActivity.get('importanceLevel').invalid
                      "
                    >
                      {{
                        actualOfflineActivity.get("importanceLevel").errors
                          | getErrorMessage: translations.ImportanceLevel
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field
                    class="col"
                    [ngClass]="
                      !actualOfflineActivity.get('reason')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="reason">{{
                      "IR_FORM.Reason" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="reason"
                      type="text"
                      name="reason"
                    />
                    <mat-error
                      *ngIf="actualOfflineActivity.get('reason').invalid"
                    >
                      {{
                        actualOfflineActivity.get("reason").errors
                          | getErrorMessage: translations.Reason
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="center-buttons">
                  <button
                    [disabled]="isReadOnly"
                    type="button"
                    class="rounded-button primary-border-button"
                    (click)="removeActivity(actualOfflineActivity, i)"
                  >
                    {{ "COMMON.Delete" | translate }}
                  </button>
                </div>
              </div>
            </div>
            <button
              *ngIf="!isReadOnly"
              type="button"
              class="rounded-button primary-bg-button ml-auto"
              (click)="addActualOfflineActivity()"
            >
              {{ "IR_FORM.AddContent" | translate }}
            </button>
          </div>
        </div>
        <div class="section mb-50">
          <div class="header">
            <div>
              <div class="title">
                <span>{{ "IR_FORM.OnlineActivities" | translate }}</span>
              </div>
              <div class="description">
                <span>
                  {{ "IR_FORM.OnlineActiviesDescription" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="content" formArrayName="actualOnlineActivities">
            <div
              *ngFor="
                let actualOnlineActivity of actualOnlineActivities.controls;
                let i = index
              "
              [formGroupName]="i"
              class="section-card left-element my-20"
            >
              <div class="content">
                <div class="row">
                  <mat-form-field
                    class="col-8"
                    [ngClass]="
                      !actualOnlineActivity.get('description')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="description">
                      {{ "IR_FORM.Activity" | translate }}
                    </mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="description"
                      name="description"
                    />
                    <mat-error
                      *ngIf="actualOnlineActivity.get('description').invalid"
                    >
                      {{
                        actualOnlineActivity.get("description").errors
                          | getErrorMessage: translations.Activity
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-2"
                  >
                    <mat-label for="revenueShare">{{
                      "IR_FORM.TurnoverQuote" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="revenueShare"
                      type="number"
                      min="0"
                      max="100"
                      name="revenueShare"
                    />
                    <span class="custom-suffix" matSuffix>%</span>
                    <mat-error
                      *ngIf="actualOnlineActivity.get('revenueShare').invalid"
                    >
                      {{
                        actualOnlineActivity.get("revenueShare").errors
                          | getErrorMessage: translations.TurnoverQuote
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-2"
                    [ngClass]="
                      !actualOnlineActivity.get('importanceLevel')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="importanceLevel">{{
                      "IR_FORM.ImportanceLevel" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="importanceLevel"
                      name="importanceLevel"
                    >
                      <mat-option [value]="1">1</mat-option>
                      <mat-option [value]="2">2</mat-option>
                      <mat-option [value]="3">3</mat-option>
                      <mat-option [value]="4">4</mat-option>
                      <mat-option [value]="5">5</mat-option>
                      <mat-option [value]="6">6</mat-option>
                      <mat-option [value]="7">7</mat-option>
                      <mat-option [value]="8">8</mat-option>
                      <mat-option [value]="9">9</mat-option>
                      <mat-option [value]="10">10</mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        actualOnlineActivity.get('importanceLevel').invalid
                      "
                    >
                      {{
                        actualOnlineActivity.get("importanceLevel").errors
                          | getErrorMessage: translations.ImportanceLevel
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field
                    class="col"
                    [ngClass]="
                      !actualOnlineActivity.get('reason')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="reason">{{
                      "IR_FORM.Reason" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="reason"
                      type="text"
                      name="reason"
                    />
                    <mat-error
                      *ngIf="actualOnlineActivity.get('reason').invalid"
                    >
                      {{
                        actualOnlineActivity.get("reason").errors
                          | getErrorMessage: translations.Reason
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="center-buttons">
                  <button
                    [disabled]="isReadOnly"
                    type="button"
                    class="rounded-button primary-border-button"
                    (click)="removeActivity(actualOnlineActivity, i)"
                  >
                    {{ "COMMON.Delete" | translate }}
                  </button>
                </div>
              </div>
            </div>
            <button
              *ngIf="!isReadOnly"
              type="button"
              class="rounded-button primary-bg-button ml-auto"
              (click)="addActualOnlineActivity()"
            >
              {{ "IR_FORM.AddContent" | translate }}
            </button>
          </div>
        </div>
        <div
          *ngIf="!isLabManagerInternal && !isReadOnly"
          class="center-buttons"
        >
          <button type="submit" class="rounded-button primary-bg-button">
            {{ "COMMON.SaveChanges" | translate }}
          </button>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template matTabLabel>
          <span>{{ "IR_FORM.Desired2" | translate }}</span>
        </ng-template>
        <div class="section mb-50">
          <div class="header">
            <div>
              <div class="title">
                <span>
                  {{ "IR_FORM.OfflineActivities" | translate }}
                </span>
              </div>
              <div class="description">
                {{ "IR_FORM.DesiredLeadGenerationDesc" | translate }}
              </div>
            </div>
          </div>
          <div class="content" formArrayName="desiredOfflineActivities">
            <div
              *ngFor="
                let desiredOfflineActivity of desiredOfflineActivities.controls;
                let i = index
              "
              [formGroupName]="i"
              class="section-card left-element my-20"
            >
              <div class="content">
                <div class="row">
                  <mat-form-field
                    class="col-4"
                    [ngClass]="
                      !desiredOfflineActivity.get('description')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="description">{{
                      "IR_FORM.Activity" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="description"
                      name="description"
                    />
                    <mat-error
                      *ngIf="desiredOfflineActivity.get('description').invalid"
                    >
                      {{
                        desiredOfflineActivity.get("description").errors
                          | getErrorMessage: translations.Activity
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-2"
                    [ngClass]="
                      !desiredOfflineActivity.get('importanceLevel')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="importanceLevel">{{
                      "IR_FORM.ImportanceLevel" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="importanceLevel"
                      name="importanceLevel"
                    >
                      <mat-option [value]="1">1</mat-option>
                      <mat-option [value]="2">2</mat-option>
                      <mat-option [value]="3">3</mat-option>
                      <mat-option [value]="4">4</mat-option>
                      <mat-option [value]="5">5</mat-option>
                      <mat-option [value]="6">6</mat-option>
                      <mat-option [value]="7">7</mat-option>
                      <mat-option [value]="8">8</mat-option>
                      <mat-option [value]="9">9</mat-option>
                      <mat-option [value]="10">10</mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        desiredOfflineActivity.get('importanceLevel').invalid
                      "
                    >
                      {{
                        desiredOfflineActivity.get("importanceLevel").errors
                          | getErrorMessage: translations.ImportanceLevel
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-5"
                    [ngClass]="
                      !desiredOfflineActivity.get('reason')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="reason">{{
                      "IR_FORM.Reason" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="reason"
                      type="text"
                      name="reason"
                    />
                    <mat-error
                      *ngIf="desiredOfflineActivity.get('reason').invalid"
                    >
                      {{
                        desiredOfflineActivity.get("reason").errors
                          | getErrorMessage: translations.Reason
                      }}
                    </mat-error>
                  </mat-form-field>
                  <div class="col-1 d-flex justify-content-center">
                    <button
                      [disabled]="isReadOnly"
                      type="button"
                      class="only-icon-button primary"
                      (click)="removeActivity(desiredOfflineActivity, i)"
                    >
                      <svg-icon name="trash_icon"></svg-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              *ngIf="!isReadOnly"
              type="button"
              class="rounded-button primary-bg-button ml-auto"
              (click)="addDesiredOfflineActivity()"
            >
              {{ "IR_FORM.AddContent" | translate }}
            </button>
          </div>
        </div>
        <div class="section mb-50">
          <div class="header">
            <div>
              <div class="title">
                <span>{{ "IR_FORM.OnlineActivities" | translate }}</span>
              </div>
              <div class="description">
                <span>
                  {{ "IR_FORM.DesiredLeadGenerationDesc" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="content" formArrayName="desiredOnlineActivities">
            <div
              *ngFor="
                let desiredOnlineActivity of desiredOnlineActivities.controls;
                let i = index
              "
              [formGroupName]="i"
              class="section-card left-element my-20"
            >
              <div class="content">
                <div class="row">
                  <mat-form-field
                    class="col-4"
                    [ngClass]="
                      !desiredOnlineActivity.get('description')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="description">{{
                      "IR_FORM.Activity" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="description"
                      name="description"
                    />
                    <mat-error
                      *ngIf="desiredOnlineActivity.get('description').invalid"
                    >
                      {{
                        desiredOnlineActivity.get("description").errors
                          | getErrorMessage: translations.Activity
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-2"
                    [ngClass]="
                      !desiredOnlineActivity.get('importanceLevel')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="importanceLevel">{{
                      "IR_FORM.ImportanceLevel" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="importanceLevel"
                      name="importanceLevel"
                    >
                      <mat-option [value]="1">1</mat-option>
                      <mat-option [value]="2">2</mat-option>
                      <mat-option [value]="3">3</mat-option>
                      <mat-option [value]="4">4</mat-option>
                      <mat-option [value]="5">5</mat-option>
                      <mat-option [value]="6">6</mat-option>
                      <mat-option [value]="7">7</mat-option>
                      <mat-option [value]="8">8</mat-option>
                      <mat-option [value]="9">9</mat-option>
                      <mat-option [value]="10">10</mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        desiredOnlineActivity.get('importanceLevel').invalid
                      "
                    >
                      {{
                        desiredOnlineActivity.get("importanceLevel").errors
                          | getErrorMessage: translations.ImportanceLevel
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-5"
                    [ngClass]="
                      !desiredOnlineActivity.get('reason')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="reason">{{
                      "IR_FORM.Reason" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="reason"
                      type="text"
                      name="reason"
                    />
                    <mat-error
                      *ngIf="desiredOnlineActivity.get('reason').invalid"
                    >
                      {{
                        desiredOnlineActivity.get("reason").errors
                          | getErrorMessage: translations.Reason
                      }}
                    </mat-error>
                  </mat-form-field>
                  <div class="col-1 d-flex justify-content-center">
                    <button
                      [disabled]="isReadOnly"
                      type="button"
                      class="only-icon-button primary"
                      (click)="removeActivity(desiredOnlineActivity, i)"
                    >
                      <svg-icon name="trash_icon"></svg-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              *ngIf="!isReadOnly"
              type="button"
              class="rounded-button primary-bg-button ml-auto"
              (click)="addDesiredOnlineActivity()"
            >
              {{ "IR_FORM.AddContent" | translate }}
            </button>
          </div>
        </div>
        <div
          *ngIf="!isLabManagerInternal && !isReadOnly"
          class="center-buttons"
        >
          <button type="submit" class="rounded-button primary-bg-button">
            {{ "COMMON.SaveChanges" | translate }}
          </button>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template matTabLabel>
          <span>{{ "IR_FORM.IdealLeadProfiles" | translate }}</span>
        </ng-template>
        <div class="section mb-50">
          <div class="header">
            <div>
              <div class="title">
                <span>
                  {{ "IR_FORM.IdealLeadProfiles" | translate }}
                </span>
              </div>
              <div class="description">
                <span>
                  {{ "IR_FORM.IdealLeadProfilesDesc" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="content" formArrayName="leadProfiles">
            <div
              *ngFor="let leadProfile of leadProfiles.controls; let i = index"
              [formGroupName]="i"
              class="section-card left-element my-20"
            >
              <div class="content">
                <div class="row">
                  <mat-form-field
                    appearance="standard"
                    class="col-4"
                    [ngClass]="
                      !leadProfile.get('idChannelType')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="idChannelType">
                      {{ "IR_FORM.Channel" | translate }}
                    </mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idChannelType"
                      name="idChannelType"
                      (selectionChange)="
                        activateSpecificationsSelect($event, leadProfile)
                      "
                    >
                      <mat-option
                        *ngFor="let channelType of channelsType"
                        [value]="channelType.idChannelType"
                      >
                        {{ channelType.description }}
                      </mat-option>
                    </mat-select>
                    <button
                      type="button"
                      class="only-icon-button primary"
                      matSuffix
                      [disabled]="isReadonly || contactOperationInProgress"
                      (click)="
                        showChannelsManagementDialog(
                          $event,
                          'channel',
                          leadProfile
                        )
                      "
                    >
                      <svg-icon name="list_icon_2" class="w20"></svg-icon>
                    </button>
                    <mat-error>{{
                      leadProfile.controls.idChannelType.errors
                        | getErrorMessage: translations.Channel
                    }}</mat-error>
                  </mat-form-field>
                  <mat-form-field
                    appearance="standard"
                    class="col-4"
                    [ngClass]="
                      !leadProfile.get('idSpecificationChannelType')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="idSpecificationChannelType">{{
                      "IR_FORM.ChannelSpec" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idSpecificationChannelType"
                      name="idSpecificationChannelType"
                    >
                      <mat-option
                        *ngFor="
                          let channelSpec of specificationsChannelsTypes
                            | filterSelectByCondition
                              : leadProfile.get('idChannelType').value
                              : 'idChannelType'
                        "
                        [value]="channelSpec.idSpecificationChannelType"
                      >
                        {{ channelSpec.description }}
                      </mat-option>
                    </mat-select>
                    <div class="d-flex" matSuffix>
                      <button
                        type="button"
                        class="only-icon-button primary"
                        [disabled]="
                          isReadonly || !leadProfile.get('idChannelType').value
                        "
                        (click)="
                          showChannelsManagementDialog(
                            $event,
                            'channelSpec',
                            leadProfile
                          )
                        "
                      >
                        <svg-icon name="list_icon_2" class="w20"></svg-icon>
                      </button>
                      <button
                        type="button"
                        [matMenuTriggerFor]="channelSpecDescription"
                        class="only-icon-button primary ml-10"
                        (click)="$event.stopPropagation()"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                    </div>
                    <mat-menu #channelSpecDescription="matMenu">
                      <div style="padding: 0 10px">
                        {{
                          "IR_FORM.ChannelSpecDescription" | translate
                        }}
                      </div>
                    </mat-menu>
                    <mat-error>
                      {{
                        leadProfile.get("idSpecificationChannelType").errors
                          | getErrorMessage: translations?.ChannelSpecification
                      }}
                    </mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field
                    appearance="standard"
                    class="col-3"
                    [ngClass]="
                      !leadProfile.get('revenue')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="revenue">
                      {{ "IR_FORM.MinRevenueOrder" | translate }}
                    </mat-label>
                    <input
                      matInput
                      currencyMask
                      autocomplete="off"
                      [options]="currencyDefaultConfig"
                      formControlName="revenue"
                      name="revenue"
                    />
                  </mat-form-field> -->
                  <mat-form-field
                    appearance="standard"
                    class="col-4"
                    [ngClass]="
                      !leadProfile.get('isGlobal')?.value &&
                      leadProfile.get('isGlobal')?.value !== 0
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="isGlobal">
                      {{ "IR_FORM.NationalOrForeign" | translate }}
                    </mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="isGlobal"
                      name="isGlobal"
                    >
                      <mat-option [value]="0">{{
                        "IR_FORM.National" | translate
                      }}</mat-option>
                      <mat-option [value]="1">{{
                        "IR_FORM.Abroad" | translate
                      }}</mat-option>
                      <mat-option [value]="2">{{
                        "IR_FORM.NationalAbroad" | translate
                      }}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <!-- <mat-form-field
                    class="col-6"
                    [ngClass]="
                      !leadProfile.get('channelSpecification')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="channelSpecification">
                      {{ "IR_FORM.ChannelSpecification" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="channelSpecification"
                      type="text"
                      name="channelSpecification"
                    />
                    <mat-error
                      *ngIf="!leadProfile.controls.channelSpecification.valid"
                      >{{
                        leadProfile.controls.channelSpecification.errors
                          | getErrorMessage: translations.ChannelSpecification
                      }}</mat-error
                    >
                  </mat-form-field> -->
                <div
                  *ngIf="leadProfile.get('idChannelType').value === 1"
                  class="row"
                >
                  <mat-form-field
                    class="col-12"
                    *ngIf="leadProfile.get('idChannelType').value === 1"
                    [ngClass]="
                      !leadProfile.get('channel')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="channel">
                      {{ "IR_FORM.ChannelDescription" | translate }}
                    </mat-label>
                    <input
                      #channelDescription
                      matInput
                      formControlName="channel"
                      type="text"
                      name="channel"
                      (keyup)="
                        handleChannelDescription(
                          leadProfile,
                          channelDescription
                        )
                      "
                    />
                    <mat-error *ngIf="!leadProfile.controls.channel.valid">{{
                      leadProfile.controls.channel.errors
                        | getErrorMessage: translations.ChannelDescription
                    }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div *ngIf="actualProfileActivities.length" class="header h42">
                <div class="title small">
                  <span>{{ "IR_FORM.ActualActivities" | translate }}</span>
                </div>
              </div>
              <div *ngIf="actualProfileActivities.length" class="content">
                <div class="activities-list">
                  <mat-checkbox
                    #actualProfileOption
                    *ngFor="let activity of actualProfileActivities"
                    color="primary"
                    class="mr-20"
                    [disabled]="isReadOnly"
                    [checked]="
                      leadProfile
                        .get('idActualLeadProfileActivities')
                        ?.value?.includes(activity.idLeadGeneration)
                    "
                    (change)="
                      handleActualActivitySelect(
                        activity,
                        actualProfileOption,
                        i
                      )
                    "
                  >
                    {{ activity.description }}
                  </mat-checkbox>
                </div>
              </div>
              <div *ngIf="desiredProfileActivities.length" class="header h42">
                <div class="title small">
                  <span>{{ "IR_FORM.DesiredActivities" | translate }}</span>
                </div>
              </div>
              <div *ngIf="desiredProfileActivities.length" class="content">
                <div class="activities-list">
                  <mat-checkbox
                    #desiredProfileOption
                    *ngFor="let activity of desiredProfileActivities"
                    color="primary"
                    class="mr-20"
                    [disabled]="isReadOnly"
                    [checked]="
                      leadProfile
                        .get('idDesiredLeadProfileActivities')
                        ?.value?.includes(activity.idLeadGeneration)
                    "
                    (change)="
                      handleDesiredActivitySelect(
                        activity,
                        desiredProfileOption,
                        i
                      )
                    "
                  >
                    {{ activity.description }}
                  </mat-checkbox>
                </div>
              </div> -->
              <div class="center-buttons mb-30">
                <button
                  [disabled]="isReadOnly"
                  type="button"
                  class="rounded-button primary-border-button"
                  (click)="removeLeadProfile(i)"
                >
                  {{ "COMMON.Delete" | translate }}
                </button>
              </div>
            </div>
            <button
              *ngIf="!isReadOnly"
              type="button"
              class="rounded-button primary-bg-button ml-auto"
              (click)="addLeadProfile()"
            >
              {{ "IR_FORM.AddContent" | translate }}
            </button>
          </div>
        </div>
        <div
          *ngIf="!isLabManagerInternal && !isReadOnly"
          class="center-buttons"
        >
          <button type="submit" class="rounded-button primary-bg-button">
            {{ "COMMON.SaveChanges" | translate }}
          </button>
        </div>
      </mat-tab>
      <mat-tab
        *ngIf="
          leadGenerationForm.get('leadGenerationRoot.dedicatedResource')
            .value === false &&
          leadGenerationForm.get('leadGenerationRoot.useLead').value === true
        "
      >
        <ng-template matTabLabel>
          <span>{{ "IR_FORM.EntrustedCompanies" | translate }}</span>
        </ng-template>
        <div class="section mb-50">
          <div class="header">
            <div>
              <div class="title">
                <span>{{ "IR_FORM.EntrustedCompanies" | translate }}</span>
              </div>
            </div>
          </div>
          <div class="content" formArrayName="companiesLead">
            <div
              *ngFor="let companyLead of companiesLead.controls; let i = index"
              [formGroupName]="i"
              class="section-card left-element my-20"
            >
              <div class="content">
                <div class="row">
                  <mat-form-field
                    class="col"
                    [ngClass]="
                      !companyLead.get('businessName')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="businessName">
                      {{ "IR_FORM.BusinessName" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="businessName"
                      name="businessName"
                      type="text"
                    />
                    <mat-error *ngIf="!companyLead.controls.businessName.valid">{{
                      companyLead.controls.businessName.errors
                        | getErrorMessage: translations.BusinessName
                    }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field
                    class="col-12"
                    [ngClass]="
                      !companyLead.get('description')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="description">
                      {{ "IR_FORM.ActivitiesDescription" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="description"
                      name="description"
                    />
                    <mat-error *ngIf="!companyLead.controls.description.valid">{{
                      companyLead.controls.description.errors
                        | getErrorMessage: translations.ActivitiesDescription
                    }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="center-buttons">
                  <button
                    [disabled]="isReadOnly"
                    type="button"
                    class="rounded-button primary-border-button"
                    (click)="removeCompanyLead(i)"
                  >
                    {{ "COMMON.Delete" | translate }}
                  </button>
                </div>
              </div>
            </div>
            <button
              *ngIf="!isReadOnly"
              type="button"
              class="rounded-button primary-bg-button ml-auto"
              (click)="addCompanyLead()"
            >
              {{ "IR_FORM.AddContent" | translate }}
            </button>
          </div>
        </div>
        <div
          *ngIf="!isLabManagerInternal && !isReadOnly"
          class="center-buttons"
        >
          <button type="submit" class="rounded-button primary-bg-button">
            {{ "COMMON.SaveChanges" | translate }}
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
  <ng-container
    *ngIf="
      leadGenerationForm.get('leadGenerationRoot.useLead').value === false ||
      leadGenerationForm.get('leadGenerationRoot.useLead').value === null
    "
  >
    <mat-tab-group
      mat-align-tabs="center"
      dynamicHeight
      class="custom-mat-tab mt-50"
      *ngIf="index === tabIndex"
      [selectedIndex]="0"
    >
      <mat-tab>
        <ng-template matTabLabel>
          <span>{{ "IR_FORM.Desired2" | translate }}</span>
        </ng-template>
        <div class="section mb-50">
          <div class="header">
            <div>
              <div class="title">
                <span>
                  {{ "IR_FORM.OfflineActivities" | translate }}
                </span>
              </div>
              <div class="description">
                {{ "IR_FORM.DesiredLeadGenerationDesc" | translate }}
              </div>
            </div>
          </div>
          <div class="content" formArrayName="desiredOfflineActivities">
            <div
              *ngFor="
                let desiredOfflineActivity of desiredOfflineActivities.controls;
                let i = index
              "
              [formGroupName]="i"
              class="section-card left-element my-20"
            >
              <div class="content">
                <div class="row">
                  <mat-form-field
                    class="col-4"
                    [ngClass]="
                      !desiredOfflineActivity.get('description')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="description">{{
                      "IR_FORM.Activity" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="description"
                      name="description"
                    />
                    <mat-error
                      *ngIf="desiredOfflineActivity.get('description').invalid"
                    >
                      {{
                        desiredOfflineActivity.get("description").errors
                          | getErrorMessage: translations.Activity
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-2"
                    [ngClass]="
                      !desiredOfflineActivity.get('importanceLevel')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="importanceLevel">{{
                      "IR_FORM.ImportanceLevel" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="importanceLevel"
                      name="importanceLevel"
                    >
                      <mat-option [value]="1">1</mat-option>
                      <mat-option [value]="2">2</mat-option>
                      <mat-option [value]="3">3</mat-option>
                      <mat-option [value]="4">4</mat-option>
                      <mat-option [value]="5">5</mat-option>
                      <mat-option [value]="6">6</mat-option>
                      <mat-option [value]="7">7</mat-option>
                      <mat-option [value]="8">8</mat-option>
                      <mat-option [value]="9">9</mat-option>
                      <mat-option [value]="10">10</mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        desiredOfflineActivity.get('importanceLevel').invalid
                      "
                    >
                      {{
                        desiredOfflineActivity.get("importanceLevel").errors
                          | getErrorMessage: translations.ImportanceLevel
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-5"
                    [ngClass]="
                      !desiredOfflineActivity.get('reason')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="reason">{{
                      "IR_FORM.Reason" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="reason"
                      type="text"
                      name="reason"
                    />
                    <mat-error
                      *ngIf="desiredOfflineActivity.get('reason').invalid"
                    >
                      {{
                        desiredOfflineActivity.get("reason").errors
                          | getErrorMessage: translations.Reason
                      }}
                    </mat-error>
                  </mat-form-field>
                  <div class="col-1 d-flex justify-content-center">
                    <button
                      [disabled]="isReadOnly"
                      type="button"
                      class="only-icon-button primary"
                      (click)="removeActivity(desiredOfflineActivity, i)"
                    >
                      <svg-icon name="trash_icon"></svg-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              *ngIf="!isReadOnly"
              type="button"
              class="rounded-button primary-bg-button ml-auto"
              (click)="addDesiredOfflineActivity()"
            >
              {{ "IR_FORM.AddContent" | translate }}
            </button>
          </div>
        </div>
        <div class="section mb-50">
          <div class="header">
            <div>
              <div class="title">
                <span>{{ "IR_FORM.OnlineActivities" | translate }}</span>
              </div>
              <div class="description">
                <span>
                  {{ "IR_FORM.DesiredLeadGenerationDesc" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="content" formArrayName="desiredOnlineActivities">
            <div
              *ngFor="
                let desiredOnlineActivity of desiredOnlineActivities.controls;
                let i = index
              "
              [formGroupName]="i"
              class="section-card left-element my-20"
            >
              <div class="content">
                <div class="row">
                  <mat-form-field
                    class="col-4"
                    [ngClass]="
                      !desiredOnlineActivity.get('description')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="description">{{
                      "IR_FORM.Activity" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="description"
                      name="description"
                    />
                    <mat-error
                      *ngIf="desiredOnlineActivity.get('description').invalid"
                    >
                      {{
                        desiredOnlineActivity.get("description").errors
                          | getErrorMessage: translations.Activity
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-2"
                    [ngClass]="
                      !desiredOnlineActivity.get('importanceLevel')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="importanceLevel">{{
                      "IR_FORM.ImportanceLevel" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="importanceLevel"
                      name="importanceLevel"
                    >
                      <mat-option [value]="1">1</mat-option>
                      <mat-option [value]="2">2</mat-option>
                      <mat-option [value]="3">3</mat-option>
                      <mat-option [value]="4">4</mat-option>
                      <mat-option [value]="5">5</mat-option>
                      <mat-option [value]="6">6</mat-option>
                      <mat-option [value]="7">7</mat-option>
                      <mat-option [value]="8">8</mat-option>
                      <mat-option [value]="9">9</mat-option>
                      <mat-option [value]="10">10</mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="
                        desiredOnlineActivity.get('importanceLevel').invalid
                      "
                    >
                      {{
                        desiredOnlineActivity.get("importanceLevel").errors
                          | getErrorMessage: translations.ImportanceLevel
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    class="col-5"
                    [ngClass]="
                      !desiredOnlineActivity.get('reason')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="reason">{{
                      "IR_FORM.Reason" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="reason"
                      type="text"
                      name="reason"
                    />
                    <mat-error
                      *ngIf="desiredOnlineActivity.get('reason').invalid"
                    >
                      {{
                        desiredOnlineActivity.get("reason").errors
                          | getErrorMessage: translations.Reason
                      }}
                    </mat-error>
                  </mat-form-field>
                  <div class="col-1 d-flex justify-content-center">
                    <button
                      [disabled]="isReadOnly"
                      type="button"
                      class="only-icon-button primary"
                      (click)="removeActivity(desiredOnlineActivity, i)"
                    >
                      <svg-icon name="trash_icon"></svg-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <button
              *ngIf="!isReadOnly"
              type="button"
              class="rounded-button primary-bg-button ml-auto"
              (click)="addDesiredOnlineActivity()"
            >
              {{ "IR_FORM.AddContent" | translate }}
            </button>
          </div>
        </div>
        <div
          *ngIf="!isLabManagerInternal && !isReadOnly"
          class="center-buttons"
        >
          <button type="submit" class="rounded-button primary-bg-button">
            {{ "COMMON.SaveChanges" | translate }}
          </button>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template matTabLabel>
          <span>{{ "IR_FORM.IdealLeadProfiles" | translate }}</span>
        </ng-template>
        <div class="section mb-50">
          <div class="header">
            <div>
              <div class="title">
                <span>
                  {{ "IR_FORM.IdealLeadProfiles" | translate }}
                </span>
              </div>
              <div class="description">
                <span>
                  {{ "IR_FORM.IdealLeadProfilesDesc" | translate }}
                </span>
              </div>
            </div>
          </div>
          <div class="content" formArrayName="leadProfiles">
            <div
              *ngFor="let leadProfile of leadProfiles.controls; let i = index"
              [formGroupName]="i"
              class="section-card left-element my-20"
            >
              <div class="content">
                <div class="row">
                  <mat-form-field
                    appearance="standard"
                    class="col-4"
                    [ngClass]="
                      !leadProfile.get('idChannelType')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="idChannelType">
                      {{ "IR_FORM.Channel" | translate }}
                    </mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idChannelType"
                      name="idChannelType"
                      (selectionChange)="
                        activateSpecificationsSelect($event, leadProfile)
                      "
                    >
                      <mat-option
                        *ngFor="let channelType of channelsType"
                        [value]="channelType.idChannelType"
                      >
                        {{ channelType.description }}
                      </mat-option>
                    </mat-select>
                    <button
                      type="button"
                      class="only-icon-button primary"
                      matSuffix
                      [disabled]="isReadonly || contactOperationInProgress"
                      (click)="
                        showChannelsManagementDialog(
                          $event,
                          'channel',
                          leadProfile
                        )
                      "
                    >
                      <svg-icon name="list_icon_2" class="w20"></svg-icon>
                    </button>
                    <mat-error>{{
                      leadProfile.controls.idChannelType.errors
                        | getErrorMessage: translations.Channel
                    }}</mat-error>
                  </mat-form-field>
                  <mat-form-field
                    appearance="standard"
                    class="col-4"
                    [ngClass]="
                      !leadProfile.get('idSpecificationChannelType')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="idSpecificationChannelType">{{
                      "IR_FORM.ChannelSpec" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idSpecificationChannelType"
                      name="idSpecificationChannelType"
                    >
                      <mat-option
                        *ngFor="
                          let channelSpec of specificationsChannelsTypes
                            | filterSelectByCondition
                              : leadProfile?.get('idChannelType')?.value
                              : 'idChannelType'
                        "
                        [value]="channelSpec.idSpecificationChannelType"
                      >
                        {{ channelSpec.description }}
                      </mat-option>
                    </mat-select>
                    <div class="d-flex" matSuffix>
                      <button
                        type="button"
                        class="only-icon-button primary"
                        [disabled]="
                          isReadonly || !leadProfile?.get('idChannelType')?.value
                        "
                        (click)="
                          showChannelsManagementDialog(
                            $event,
                            'channelSpec',
                            leadProfile
                          )
                        "
                      >
                        <svg-icon name="list_icon_2" class="w20"></svg-icon>
                      </button>
                      <button
                        type="button"
                        [matMenuTriggerFor]="channelSpecDescription"
                        class="only-icon-button primary ml-10"
                        (click)="$event.stopPropagation()"
                      >
                        <mat-icon>info</mat-icon>
                      </button>
                    </div>
                    <mat-menu #channelSpecDescription="matMenu">
                      <div style="padding: 0 10px">
                        {{
                          "IR_FORM.ChannelSpecDescription" | translate
                        }}
                      </div>
                    </mat-menu>
                    <mat-error>
                      {{
                        leadProfile?.get("idSpecificationChannelType")?.errors
                          | getErrorMessage: translations?.ChannelSpecification
                      }}
                    </mat-error>
                  </mat-form-field>
                  <!-- <mat-form-field
                    appearance="standard"
                    class="col-3"
                    [ngClass]="
                      !leadProfile.get('revenue')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="revenue">
                      {{ "IR_FORM.MinRevenueOrder" | translate }}
                    </mat-label>
                    <input
                      matInput
                      currencyMask
                      autocomplete="off"
                      [options]="currencyDefaultConfig"
                      formControlName="revenue"
                      name="revenue"
                    />
                  </mat-form-field> -->
                  <mat-form-field
                    appearance="standard"
                    class="col-4"
                    [ngClass]="
                      !leadProfile.get('isGlobal')?.value &&
                      leadProfile.get('isGlobal')?.value !== 0
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="isGlobal">
                      {{ "IR_FORM.NationalOrForeign" | translate }}
                    </mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="isGlobal"
                      name="isGlobal"
                    >
                      <mat-option [value]="0">{{
                        "IR_FORM.National" | translate
                      }}</mat-option>
                      <mat-option [value]="1">{{
                        "IR_FORM.Abroad" | translate
                      }}</mat-option>
                      <mat-option [value]="2">{{
                        "IR_FORM.NationalAbroad" | translate
                      }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="!leadProfile.controls.isGlobal.valid">{{
                      leadProfile.controls.isGlobal.errors
                        | getErrorMessage: translations.NationalOrForeign
                    }}</mat-error>
                  </mat-form-field>
                </div>
                <!-- <mat-form-field
                    class="col-6"
                    [ngClass]="
                      !leadProfile.get('channelSpecification')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="channelSpecification">
                      {{ "IR_FORM.ChannelSpecification" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="channelSpecification"
                      type="text"
                      name="channelSpecification"
                    />
                    <mat-error
                      *ngIf="!leadProfile.controls.channelSpecification.valid"
                      >{{
                        leadProfile.controls.channelSpecification.errors
                          | getErrorMessage: translations.ChannelSpecification
                      }}</mat-error
                    >
                  </mat-form-field> -->
                <div
                  *ngIf="leadProfile.get('idChannelType').value === 1"
                  class="row"
                >
                  <mat-form-field
                    class="col-12"
                    *ngIf="leadProfile.get('idChannelType').value === 1"
                    [ngClass]="
                      !leadProfile.get('channel')?.value
                        ? 'red-field-for-lab-manager'
                        : ''
                    "
                  >
                    <mat-label for="channel">
                      {{ "IR_FORM.ChannelDescription" | translate }}
                    </mat-label>
                    <input
                      #channelDescription
                      matInput
                      formControlName="channel"
                      type="text"
                      name="channel"
                      (keyup)="
                        handleChannelDescription(
                          leadProfile,
                          channelDescription
                        )
                      "
                    />
                    <mat-error *ngIf="!leadProfile.controls.channel.valid">{{
                      leadProfile.controls.channel.errors
                        | getErrorMessage: translations.ChannelDescription
                    }}</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div *ngIf="actualProfileActivities.length" class="header h42">
                <div class="title small">
                  <span>{{ "IR_FORM.ActualActivities" | translate }}</span>
                </div>
              </div>
              <div *ngIf="actualProfileActivities.length" class="content">
                <div class="activities-list">
                  <mat-checkbox
                    #actualProfileOption
                    *ngFor="let activity of actualProfileActivities"
                    color="primary"
                    class="mr-20"
                    [disabled]="isReadOnly"
                    [checked]="
                      leadProfile
                        .get('idActualLeadProfileActivities')
                        ?.value?.includes(activity.idLeadGeneration)
                    "
                    (change)="
                      handleActualActivitySelect(
                        activity,
                        actualProfileOption,
                        i
                      )
                    "
                  >
                    {{ activity.description }}
                  </mat-checkbox>
                </div>
              </div>
              <div *ngIf="desiredProfileActivities.length" class="header h42">
                <div class="title small">
                  <span>{{ "IR_FORM.DesiredActivities" | translate }}</span>
                </div>
              </div>
              <div *ngIf="desiredProfileActivities.length" class="content">
                <div class="activities-list">
                  <mat-checkbox
                    #desiredProfileOption
                    *ngFor="let activity of desiredProfileActivities"
                    color="primary"
                    class="mr-20"
                    [disabled]="isReadOnly"
                    [checked]="
                      leadProfile
                        .get('idDesiredLeadProfileActivities')
                        ?.value?.includes(activity.idLeadGeneration)
                    "
                    (change)="
                      handleDesiredActivitySelect(
                        activity,
                        desiredProfileOption,
                        i
                      )
                    "
                  >
                    {{ activity.description }}
                  </mat-checkbox>
                </div>
              </div> -->
              <div class="center-buttons mb-30">
                <button
                  [disabled]="isReadOnly"
                  type="button"
                  class="rounded-button primary-border-button"
                  (click)="removeLeadProfile(i)"
                >
                  {{ "COMMON.Delete" | translate }}
                </button>
              </div>
            </div>
            <button
              *ngIf="!isReadOnly"
              type="button"
              class="rounded-button primary-bg-button ml-auto"
              (click)="addLeadProfile()"
            >
              {{ "IR_FORM.AddContent" | translate }}
            </button>
          </div>
        </div>
        <div
          *ngIf="!isLabManagerInternal && !isReadOnly"
          class="center-buttons"
        >
          <button type="submit" class="rounded-button primary-bg-button">
            {{ "COMMON.SaveChanges" | translate }}
          </button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</form>
<div class="mt-40">
  <button
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
