<div class="section-card">
  <div class="header">
    <div class="title">
      <span>{{ "COMMON.Project" | translate }}: {{ project?.idProject }}</span>
    </div>
  </div>
  <div class="content" [formGroup]="form">
    <div class="row">
      <mat-form-field class="col-6">
        <mat-label *ngIf="type === 'assignDLManagerInternal'">{{
          "COMMON.SelectDLManagerInternal" | translate
        }}</mat-label>
        <mat-label *ngIf="type === 'changeDLManager'">{{
          "COMMON.SelectDLManager" | translate
        }}</mat-label>
        <mat-label *ngIf="type === 'changeAgent'">{{
          "COMMON.SelectAgent" | translate
        }}</mat-label>
        <mat-select
          disableOptionCentering
          panelClass="custom-panel-class"
          name="idUser"
          formControlName="idUser"
          (closed)="filter.value = ''"
        >
          <div class="select-global-filter">
            <input
              #filter
              matInput
              type="text"
              [placeholder]="'COMMON.Search...' | translate"
            />
            <button
              matSuffix
              *ngIf="filter.value !== ''"
              class="only-icon-button"
              type="button"
              (click)="filter.value = null"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
          <mat-option
            [ngStyle]="{
              display:
                (users | filterSelect: filter.value:'name').includes(name) ||
                filter.value === ''
                  ? 'flex'
                  : 'none'
            }"
            *ngFor="let user of users"
            [value]="user.idUser"
          >
            {{ user?.name }} {{ user?.surname }} - {{ user?.email }}
            {{ user?.role }}
          </mat-option>
          <!-- <mat-option
            *ngFor="let labManager of filteredLabManagers"
            [value]="labManager.idUser"
          >
            {{ labManager?.name }} {{ labManager?.surname }} -
            {{ labManager?.email }} {{ labManager?.role }}
          </mat-option> -->
          <div
            *ngIf="!(users | filterSelect: filter.value:'name')?.length"
            class="select-global-filter-no-result"
          >
            {{ "COMMON.NoResult" | translate }}
          </div>
        </mat-select>
        <mat-error *ngIf="form.invalid && type === 'assignDLManagerInternal'">
          {{
            form.get("idUser").errors
              | getErrorMessage: translations?.LabManagerInternal
          }}
        </mat-error>
        <mat-error *ngIf="form.invalid && type === 'changeDLManager'">
          {{ form.get("idUser").errors | getErrorMessage: translations?.Owner }}
        </mat-error>
        <mat-error *ngIf="form.invalid && type === 'changeAgent'">
          {{ form.get("idUser").errors | getErrorMessage: translations?.Agent }}
        </mat-error>
      </mat-form-field>
    </div>
    <div class="row">
      <mat-form-field class="col-4">
        <mat-label>{{ "PROJECT_DETAIL.DrawnUpBy" | translate }}</mat-label>
        <input matInput [value]="drawnUpBy" readonly />
      </mat-form-field>
      <mat-form-field class="col-4">
        <mat-label>{{ "PROJECT_DETAIL.LastUpdateDate" | translate }}</mat-label>
        <input
          matInput
          [value]="(project?.lastModified | date: 'dd/MM/yyyy - HH:mm') || null"
          readonly
        />
      </mat-form-field>
      <mat-form-field class="col-4">
        <mat-label>{{ "PROJECT_DETAIL.ApprovedBy" | translate }}</mat-label>
        <input matInput [value]="approvedBy" readonly />
      </mat-form-field>
    </div>
  </div>
</div>
<div class="section-card mt-30">
  <div class="header">
    <div class="title">
      <span>{{ "PROJECT_DETAIL.CustomerInformation" | translate }}</span>
    </div>
  </div>
  <div class="content">
    <div class="row">
      <div class="customer-image col-1">
        <img src="{{ customerImagePath }}" />
      </div>
      <mat-form-field class="col-3">
        <mat-label>{{ "IR_FORM.BusinessName" | translate }}</mat-label>
        <input matInput [value]="customer?.businessName || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "COMMON.Address" | translate }}</mat-label>
        <input matInput [value]="customerAddress || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-3">
        <mat-label>{{ "PROJECT_DETAIL.Email" | translate }}</mat-label>
        <input matInput [value]="customer?.email || ''" readonly />
      </mat-form-field>
      <mat-form-field class="col-2">
        <mat-label>{{ "PROJECT_DETAIL.Phone" | translate }}</mat-label>
        <input matInput [value]="customer?.telephoneNumber || ''" readonly />
      </mat-form-field>
    </div>
  </div>
</div>
<div class="d-flex justify-content-center mt-30">
  <button
    type="button"
    class="rounded-button primary-bg-button"
    [disabled]="form.invalid"
    (click)="save()"
  >
    {{ "PROJECT_DETAIL.Save" | translate }}
  </button>
</div>
