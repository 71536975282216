<form
  appSrollOnError
  (scrollOnErrorEvent)="expandPanelOnScroll()"
  [formGroup]="marketForm"
  (ngSubmit)="onSubmit()"
  class="ir-form"
>
  <div class="section mb-50">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.PresenceMarkets" | translate }}</span>
        </div>
        <div class="description">
          <span>{{ "IR_FORM.OfPresenceMarketsDescription" | translate }}</span>
        </div>
      </div>
    </div>
    <div class="content" formArrayName="presenceMarkets">
      <div
        *ngFor="let presenceMarket of presenceMarkets.controls; let i = index"
        [formGroupName]="i"
        class="section-card my-20"
      >
        <div class="content">
          <div class="row">
            <div class="col-6 right-border">
              <div class="row">
                <mat-form-field
                  class="col-6"
                  formGroupName="country"
                  [ngClass]="
                    !presenceMarket.get('country.idCountry')?.value && isIsp
                      ? 'red-field-for-lab-manager'
                      : ''
                  "
                >
                  <mat-label for="idCountry">{{
                    "IR_FORM.Market" | translate
                  }}</mat-label>
                  <mat-select
                    disableOptionCentering
                    panelClass="custom-panel-class"
                    formControlName="idCountry"
                    name="idCountry"
                    (closed)="filter.value = ''"
                  >
                    <div class="select-global-filter">
                      <input
                        #filter
                        matInput
                        type="text"
                        [placeholder]="'COMMON.Search...' | translate"
                      />
                      <button
                        matSuffix
                        *ngIf="filter.value !== ''"
                        class="only-icon-button"
                        type="button"
                        (click)="filter.value = null"
                      >
                        <mat-icon>clear</mat-icon>
                      </button>
                    </div>
                    <mat-option
                      [ngStyle]="{
                        display:
                          (
                            countries | filterSelect: filter.value:'country'
                          ).includes(country) || filter.value === ''
                            ? 'flex'
                            : 'none'
                      }"
                      *ngFor="let country of countries"
                      [value]="country.idCountry"
                    >
                      {{ country.country }}
                    </mat-option>
                    <div
                      *ngIf="
                        !(countries | filterSelect: filter.value:'country')
                          ?.length
                      "
                      class="select-global-filter-no-result"
                    >
                      {{ "COMMON.NoResult" | translate }}
                    </div>
                  </mat-select>
                  <mat-error
                    *ngIf="
                      presenceMarket.controls.country.get('idCountry').invalid
                    "
                  >
                    {{
                      presenceMarket.controls.country.get("idCountry").errors
                        | getErrorMessage: translations.IdCountry
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field 
                  class="col-3"
                  [ngClass]="
                    !presenceMarket.get('revenueShare')?.value && isIsp
                      ? 'red-field-for-lab-manager'
                      : ''
                  "
                >
                  <mat-label for="revenueShare">
                    {{ "IR_FORM.TurnoverQuote" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="revenueShare"
                    type="number"
                    min="0"
                    max="100"
                    name="revenueShare"
                  />
                  <button
                    type="button"
                    matSuffix
                    [matMenuTriggerFor]="revenueShareDescription"
                    class="only-icon-button primary"
                    (click)="$event.stopPropagation()"
                  >
                    <span class="custom-suffix">%</span>
                    <mat-icon>info</mat-icon>
                  </button>
                  <mat-menu #revenueShareDescription="matMenu">
                    <div style="padding: 0 10px">
                      {{
                        "IR_FORM.RevenueShareSumDescription" | translate
                      }}
                    </div>
                  </mat-menu>
                  <mat-error *ngIf="presenceMarket.get('revenueShare').invalid">
                    {{
                      presenceMarket.get("revenueShare").errors
                        | getErrorMessage: translations.TurnoverQuote
                    }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field 
                  class="col-3"
                  [ngClass]="
                    !presenceMarket.get('levelOfInterest')?.value && isIsp
                      ? 'red-field-for-lab-manager'
                      : ''
                  "
                >
                  <mat-label for="levelOfInterest">
                    {{ "IR_FORM.ImportanceLevel" | translate }}
                  </mat-label>
                  <mat-select
                    disableOptionCentering
                    panelClass="custom-panel-class"
                    formControlName="levelOfInterest"
                    name="levelOfInterest"
                  >
                    <mat-option [value]="1">1</mat-option>
                    <mat-option [value]="2">2</mat-option>
                    <mat-option [value]="3">3</mat-option>
                    <mat-option [value]="4">4</mat-option>
                    <mat-option [value]="5">5</mat-option>
                    <mat-option [value]="6">6</mat-option>
                    <mat-option [value]="7">7</mat-option>
                    <mat-option [value]="8">8</mat-option>
                    <mat-option [value]="9">9</mat-option>
                    <mat-option [value]="10">10</mat-option>
                  </mat-select>
                  <mat-error
                    *ngIf="presenceMarket.get('levelOfInterest').invalid"
                  >
                    {{
                      presenceMarket.get("levelOfInterest").errors
                        | getErrorMessage: translations.ImportanceLevel
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field 
                  class="col-12"
                  [ngClass]="
                    !presenceMarket.get('reason')?.value && isIsp
                      ? 'red-field-for-lab-manager'
                      : ''
                  "
                >
                  <mat-label for="reason">
                    {{ "IR_FORM.Reason" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="reason"
                    type="text"
                    name="reason"
                  />
                  <mat-error *ngIf="presenceMarket.get('reason').invalid">
                    {{
                      presenceMarket.get("reason").errors
                        | getErrorMessage: translations.Reason
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="col-6">
              <div class="row">
                <div class="col-12">
                  <mat-slide-toggle
                    color="primary"
                    name="toIncrease"
                    formControlName="toIncrease"
                    (change)="toggleToIncreaseReasonRequired($event.checked, i)"
                    style="height: 65.6px"
                    >{{ "IR_FORM.ToIncrease" | translate }}</mat-slide-toggle
                  >
                </div>
              </div>
              <div class="row" *ngIf="presenceMarket.get('toIncrease').value">
                <mat-form-field 
                  class="col-12"
                  [ngClass]="
                    !presenceMarket.get('potentialReason')?.value && isIsp
                      ? 'red-field-for-lab-manager'
                      : ''
                  "
                >
                  <mat-label for="potentialReason">
                    {{ "IR_FORM.PotentialReason" | translate }}
                  </mat-label>
                  <input
                    matInput
                    formControlName="potentialReason"
                    type="text"
                    name="potentialReason"
                  />
                  <mat-error
                    *ngIf="presenceMarket.get('potentialReason').invalid"
                  >
                    {{
                      presenceMarket.get("potentialReason").errors
                        | getErrorMessage: translations.PotentialReason
                    }}
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div
            *ngIf="!isReadOnly && !isLabManagerInternal"
            class="center-buttons"
          >
            <button
              type="button"
              class="rounded-button primary-border-button"
              (click)="removeMarket(presenceMarket, i)"
            >
              {{ "COMMON.Delete" | translate }}
            </button>
          </div>
        </div>
      </div>
      <!-- <div
          *ngIf="otherRevenues.presenceMarkets && presenceMarkets.controls.length"
          class="section-card"
        >
          <div class="content">
            <div class="row">
              <mat-form-field class="col-6">
                <mat-label for="otherPresence">
                  {{ "IR_FORM.Market" | translate }}
                </mat-label>
                <input
                  matInput
                  readonly
                  type="text"
                  name="otherPresence"
                  [value]="'IR_FORM.Other' | translate"
                />
              </mat-form-field>
              <mat-form-field class="col-2">
                <mat-label for="presenceRevenueShare">
                  {{ "IR_FORM.TurnoverQuote" | translate }}
                </mat-label>
                <input
                  matInput
                  readonly
                  type="number"
                  min="0"
                  max="100"
                  name="presenceRevenueShare"
                  [value]="otherRevenues.presenceMarkets"
                />
                <span class="custom-suffix" matSuffix>%</span>
              </mat-form-field>
            </div>
          </div>
        </div> -->
      <button
        *ngIf="!isReadOnly && !isLabManagerInternal"
        type="button"
        class="rounded-button primary-bg-button ml-auto"
        (click)="addPresenceMarket()"
      >
        {{ "IR_FORM.AddContent" | translate }}
      </button>
    </div>
  </div>
  <div class="section mb-50">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.HighPotentialDesiredMarkets" | translate }}</span>
        </div>
        <div class="description">
          <span>{{ "IR_FORM.PotentialsMarketsDescritpion" | translate }}</span>
        </div>
      </div>
    </div>
    <div class="content" formArrayName="hightPotentialMarkets">
      <div
        *ngFor="
          let hightPotentialMarket of hightPotentialMarkets.controls;
          let i = index
        "
        [formGroupName]="i"
        class="section-card my-20"
      >
        <div class="content">
          <div class="row">
            <mat-form-field
              class="col-3"
              formGroupName="country"
              [ngClass]="
                !hightPotentialMarket.get('country.idCountry')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="idCountry">{{
                "IR_FORM.Market" | translate
              }}</mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="idCountry"
                name="idCountry"
                (closed)="filter.value = ''"
              >
                <div class="select-global-filter">
                  <input
                    #filter
                    matInput
                    type="text"
                    [placeholder]="'COMMON.Search...' | translate"
                  />
                  <button
                    matSuffix
                    *ngIf="filter.value !== ''"
                    class="only-icon-button"
                    type="button"
                    (click)="filter.value = null"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
                <mat-option
                  [ngStyle]="{
                    display:
                      (
                        countries | filterSelect: filter.value:'country'
                      ).includes(country) || filter.value === ''
                        ? 'flex'
                        : 'none'
                  }"
                  *ngFor="let country of countries"
                  [value]="country.idCountry"
                >
                  {{ country.country }}
                </mat-option>
                <div
                  *ngIf="
                    !(countries | filterSelect: filter.value:'country')?.length
                  "
                  class="select-global-filter-no-result"
                >
                  {{ "COMMON.NoResult" | translate }}
                </div>
              </mat-select>
              <mat-error
                *ngIf="
                  hightPotentialMarket.controls.country.get('idCountry').invalid
                "
              >
                {{
                  hightPotentialMarket.controls.country.get("idCountry").errors
                    | getErrorMessage: translations.IdCountry
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field 
              class="col-2"
              [ngClass]="
                !hightPotentialMarket.get('levelOfInterest')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="levelOfInterest">
                {{ "IR_FORM.ImportanceLevel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="levelOfInterest"
                name="levelOfInterest"
              >
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>
              </mat-select>
              <mat-error
                *ngIf="hightPotentialMarket.get('levelOfInterest').invalid"
              >
                {{
                  hightPotentialMarket.get("levelOfInterest").errors
                    | getErrorMessage: translations.ImportanceLevel
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field 
              class="col-6"
              [ngClass]="
                !hightPotentialMarket.get('reason')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="reason">
                {{ "IR_FORM.Reason" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="reason"
                type="text"
                name="reason"
              />
              <mat-error *ngIf="hightPotentialMarket.get('reason').invalid">
                {{
                  hightPotentialMarket.get("reason").errors
                    | getErrorMessage: translations.Reason
                }}
              </mat-error>
            </mat-form-field>
            <div
              *ngIf="!isReadOnly && !isLabManagerInternal"
              class="col-1 d-flex justify-content-center"
            >
              <button
                type="button"
                class="only-icon-button primary"
                (click)="removeMarket(hightPotentialMarket, i)"
              >
                <svg-icon name="trash_icon"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        *ngIf="!isReadOnly && !isLabManagerInternal"
        type="button"
        class="rounded-button primary-bg-button ml-auto"
        (click)="addHightPotentialMarket()"
      >
        {{ "IR_FORM.AddContent" | translate }}
      </button>
    </div>
  </div>
  <div class="section mb-50">
    <div class="header">
      <div>
        <div class="title">
          <span>{{ "IR_FORM.UnexploredDesiredMarkets" | translate }}</span>
        </div>
        <div class="description">
          <span>{{
            "IR_FORM.PotentialsFutureMarketsDescritpion" | translate
          }}</span>
        </div>
      </div>
    </div>
    <div class="content" formArrayName="hightPotentialFutureMarkets">
      <div
        *ngFor="
          let hightPotentialFutureMarket of hightPotentialFutureMarkets.controls;
          let i = index
        "
        [formGroupName]="i"
        class="section-card my-20"
      >
        <div class="content">
          <div class="row">
            <mat-form-field
              class="col-3"
              formGroupName="country"
              [ngClass]="
                !hightPotentialFutureMarket.get('country.idCountry')?.value &&
                isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="idCountry">{{
                "IR_FORM.Market" | translate
              }}</mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="idCountry"
                name="idCountry"
                (closed)="filter.value = ''"
              >
                <div class="select-global-filter">
                  <input
                    #filter
                    matInput
                    type="text"
                    [placeholder]="'COMMON.Search...' | translate"
                  />
                  <button
                    matSuffix
                    *ngIf="filter.value !== ''"
                    class="only-icon-button"
                    type="button"
                    (click)="filter.value = null"
                  >
                    <mat-icon>clear</mat-icon>
                  </button>
                </div>
                <mat-option
                  [ngStyle]="{
                    display:
                      (
                        countries | filterSelect: filter.value:'country'
                      ).includes(country) || filter.value === ''
                        ? 'flex'
                        : 'none'
                  }"
                  *ngFor="let country of countries"
                  [value]="country.idCountry"
                >
                  {{ country.country }}
                </mat-option>
                <div
                  *ngIf="
                    !(countries | filterSelect: filter.value:'country')?.length
                  "
                  class="select-global-filter-no-result"
                >
                  {{ "COMMON.NoResult" | translate }}
                </div>
              </mat-select>
              <mat-error
                *ngIf="
                  hightPotentialFutureMarket.controls.country.get('idCountry')
                    .invalid
                "
              >
                {{
                  hightPotentialFutureMarket.controls.country.get("idCountry")
                    .errors | getErrorMessage: translations.IdCountry
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field 
              class="col-2"
              [ngClass]="
                !hightPotentialFutureMarket.get('levelOfInterest')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="levelOfInterest">
                {{ "IR_FORM.ImportanceLevel" | translate }}
              </mat-label>
              <mat-select
                disableOptionCentering
                panelClass="custom-panel-class"
                formControlName="levelOfInterest"
                name="levelOfInterest"
              >
                <mat-option [value]="1">1</mat-option>
                <mat-option [value]="2">2</mat-option>
                <mat-option [value]="3">3</mat-option>
                <mat-option [value]="4">4</mat-option>
                <mat-option [value]="5">5</mat-option>
                <mat-option [value]="6">6</mat-option>
                <mat-option [value]="7">7</mat-option>
                <mat-option [value]="8">8</mat-option>
                <mat-option [value]="9">9</mat-option>
                <mat-option [value]="10">10</mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  hightPotentialFutureMarket.get('levelOfInterest').invalid
                "
              >
                {{
                  hightPotentialFutureMarket.get("levelOfInterest").errors
                    | getErrorMessage: translations.ImportanceLevel
                }}
              </mat-error>
            </mat-form-field>
            <mat-form-field 
              class="col-6"
              [ngClass]="
                !hightPotentialFutureMarket.get('reason')?.value && isIsp
                  ? 'red-field-for-lab-manager'
                  : ''
              "
            >
              <mat-label for="reason">
                {{ "IR_FORM.Reason" | translate }}
              </mat-label>
              <input
                matInput
                formControlName="reason"
                type="text"
                name="reason"
              />
              <mat-error
                *ngIf="hightPotentialFutureMarket.get('reason').invalid"
              >
                {{
                  hightPotentialFutureMarket.get("reason").errors
                    | getErrorMessage: translations.Reason
                }}
              </mat-error>
            </mat-form-field>
            <div
              *ngIf="!isReadOnly && !isLabManagerInternal"
              class="col-1 d-flex justify-content-center"
            >
              <button
                type="button"
                class="only-icon-button primary"
                (click)="removeMarket(hightPotentialFutureMarket, i)"
              >
                <svg-icon name="trash_icon"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        *ngIf="!isReadOnly && !isLabManagerInternal"
        type="button"
        class="rounded-button primary-bg-button ml-auto"
        (click)="addHightPotentialFutureMarket()"
      >
        {{ "IR_FORM.AddContent" | translate }}
      </button>
    </div>
  </div>
  <div *ngIf="isIsp" class="row">
    <mat-form-field
      class="col"
      [ngClass]="
        !customerDescriptionForm.get('marketDesc')?.value && isIsp
          ? 'red-field-for-lab-manager'
          : ''
      "
    >
      <mat-label for="marketDesc">
        {{ "IR_FORM.CommentForPS" | translate }}
      </mat-label>
      <textarea
        rows="2"
        matInput
        type="text"
        name="marketDesc"
        [formControl]="customerDescriptionForm.get('marketDesc')"
      ></textarea>
      <mat-error *ngIf="customerDescriptionForm.get('marketDesc').invalid">
        {{
          customerDescriptionForm.get("marketDesc").errors
            | getErrorMessage: translations.CommentForPS
        }}
      </mat-error>
    </mat-form-field>
  </div>
  <div *ngIf="!isLabManagerInternal && !isReadOnly" class="center-buttons">
    <button type="submit" class="rounded-button primary-bg-button">
      {{ "COMMON.SaveChanges" | translate }}
    </button>
  </div>
</form>
<div class="mt-40">
  <button
    *ngIf="allMarkets.length > 3"
    type="button"
    class="rounded-button primary-border-button m-0-auto"
    appScrollToTop
  >
    {{ "COMMON.ComeUp" | translate }}
  </button>
</div>
