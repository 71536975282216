import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { IProjectForTable, ProjectDTO } from 'src/app/models/project';
import { IColumnsName, ITableButtons, ITableOptions } from 'src/app/models/utility';
import { Router } from '@angular/router';
import { fromProjectDtoToProjectTable } from 'src/app/utility/elaborations';

@Component({
  selector: 'app-ir-to-assign-table',
  templateUrl: './ir-to-assign-table.component.html',
  styleUrls: ['./ir-to-assign.component.scss']
})
export class IrToAssignTableComponent implements OnInit, OnChanges {

  projectDataTable: MatTableDataSource<IProjectForTable> = new MatTableDataSource<IProjectForTable>();

  @Input() loader: boolean;
  @Input() projectData: ProjectDTO[];
  @Input() columnsName: IColumnsName[] = [
    {value: 'businessName', name: 'Ragione Sociale'},
    {value: 'telephoneNumber', name: 'Telefono'},
    {value: 'email', name: 'Email'},
    {value: 'address', name: 'Indirizzo'},
    {value: 'lastModified', name: 'Ultima lavorazione'},
  ];
  @Input() displayedColumns: string[] = [
    'lastModified',
    'businessName',
    'telephoneNumber',
    'email',
    'address',
    'menuButtons'
  ];
  @Input() tableMenuButtons: ITableButtons[] = [
    {
      name: 'Assegna',
      click: (original: IProjectForTable) => { this.visualizeCustomer(original); },
      icon: 'forward'
    }
  ];
  @Input() tableOptions: ITableOptions = {
    showFilter: true,
    showSort: true,
    showPaginator: true,
    showButtons: true
  };

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    const projectData: ProjectDTO[]  = changes.projectData?.currentValue || [];
    if (projectData?.length || projectData.length === 0) {
      this.projectDataTable.data = projectData.map(fromProjectDtoToProjectTable);
    }
  }

  visualizeCustomer(project: IProjectForTable): void {
    this.router.navigate([`/project-detail`, project.idProject, project.customer.idCustomer]);
  }

}
