<div class="mb-30" *ngIf="idProject">
  <app-customer-info-panel
    [idProject]="idProject"
    [isReadonly]="isReadonly"
    [path]="'crm'"
  ></app-customer-info-panel>
</div>
<div *ngIf="contact?.businessName" class="contact-name-and-market mb-20">
  {{
    contact?.businessName +
      " - " +
      ("CRM_DETAIL.Market" | translate) +
      ": " +
      contact?.country?.country
  }}
</div>
<div class="p-relative">
  <div class="go-back-to-database">
    <button
      type="button"
      class="rounded-button primary-border-button"
      [disabled]="contactOperationInProgress"
      (click)="goBackToDatabase()"
    >
      {{ "CRM.GoBackToDatabase" | translate }}
    </button>
  </div>
  <!-- <mat-tab-group
    [(selectedIndex)]="matTabIndex"
    dynamicHeight
    class="custom-mat-tab no-full-width"
  > -->
  <mat-tab-group
    dynamicHeight
    class="custom-mat-tab no-full-width"
  >
    <mat-tab>
      <ng-template matTabLabel>
        <span>{{ "CRM_DETAIL.Contact" | translate }}</span>
      </ng-template>
      <form [formGroup]="contactForm" (ngSubmit)="saveContact()">
        <div class="section-card">
          <div class="content">
            <div class="row">
              <mat-form-field appearance="standard" class="col-12">
                <mat-label for="businessName">{{
                  "CRM_DETAIL.BusinessName" | translate
                }}</mat-label>
                <input
                  matInput
                  type="text"
                  name="businessName"
                  formControlName="businessName"
                />
                <mat-error>
                  {{
                    contactForm.get("businessName").errors
                      | getErrorMessage: translations?.BusinessName
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                appearance="standard"
                class="col-3"
                formGroupName="country"
              >
                <mat-label for="idCountry">{{
                  "CRM_DETAIL.Country" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="idCountry"
                  name="idCountry"
                >
                  <mat-option>
                    <ngx-mat-select-search
                      [formControl]="entityFilterCtrl"
                      [placeholderLabel]="'COMMON.Search...' | translate"
                      [noEntriesFoundLabel]="'Nessun risultato'"
                    >
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option
                    [ngStyle]="{
                      display: filteredCountries.includes(country)
                        ? 'flex'
                        : 'none'
                    }"
                    *ngFor="let country of countries"
                    [value]="country.idCountry"
                  >
                    {{ country.country }}
                  </mat-option>
                  <mat-option
                    *ngIf="!filteredCountries?.length"
                    style="pointer-events: none"
                  >
                    {{ "COMMON.NoResult" | translate }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{
                    contactForm.controls.country.get("idCountry").errors
                      | getErrorMessage: translations?.Country
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-2">
                <mat-label for="province">{{
                  "CRM_DETAIL.StateProvince" | translate
                }}</mat-label>
                <input
                  #provinceInput
                  matInput
                  type="text"
                  name="province"
                  formControlName="province"
                  (keyup)="
                    provinceInput.value = $event.target.value.toUpperCase()
                  "
                />
                <mat-error>
                  {{
                    contactForm.get("province").errors
                      | getErrorMessage: translations?.Province
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-2">
                <mat-label for="city">{{
                  "CRM_DETAIL.City" | translate
                }}</mat-label>
                <input
                  matInput
                  type="text"
                  name="city"
                  formControlName="city"
                  (focusout)="checkCity()"
                />
                <mat-error>
                  {{
                    contactForm.get("city").errors
                      | getErrorMessage: translations?.City
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-3">
                <mat-label for="address">{{
                  "CRM_DETAIL.Address" | translate
                }}</mat-label>
                <input
                  matInput
                  type="text"
                  name="address"
                  formControlName="address"
                />
                <button
                  *ngIf="false"
                  matSuffix
                  type="button"
                  class="only-icon-button"
                  [ngClass]="
                    contactForm.get('position')?.value?.length > 0
                      ? 'primary'
                      : 'error'
                  "
                  [disabled]="contactOperationInProgress"
                  (click)="openPositionDialog()"
                >
                  <svg-icon
                    *ngIf="contactForm?.get('position')?.value?.length > 0"
                    name="pin_check"
                    class="w20"
                    matTooltip="{{ 'COMMON.Coordinates' | translate }}"
                  ></svg-icon>
                  <svg-icon
                    *ngIf="contactForm?.get('position')?.value?.length < 1"
                    name="pin_error"
                    class="w20"
                    matTooltip="{{ 'COMMON.Coordinates' | translate }}"
                  ></svg-icon>
                </button>
                <mat-error>
                  {{
                    contactForm.get("address").errors
                      | getErrorMessage: translations?.Address
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-2">
                <mat-label for="telephoneNumber">{{
                  "CRM_DETAIL.Phone" | translate
                }}</mat-label>
                <input
                  matInput
                  type="tel"
                  name="telephoneNumber"
                  formControlName="telephoneNumber"
                />
                <mat-error>
                  {{
                    contactForm.get("telephoneNumber").errors
                      | getErrorMessage: translations?.Phone
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-4">
                <mat-label for="email">{{
                  "CRM_DETAIL.Email" | translate
                }}</mat-label>
                <input
                  matInput
                  type="email"
                  name="email"
                  formControlName="email"
                />
                <mat-error>
                  {{
                    contactForm.get("email").errors
                      | getErrorMessage: translations?.Email
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-4">
                <mat-label for="webSiteLink">{{
                  "CRM_DETAIL.WebSite" | translate
                }}</mat-label>
                <input
                  matInput
                  type="url"
                  name="webSiteLink"
                  formControlName="webSiteLink"
                />
                <mat-error>
                  {{
                    contactForm.get("webSiteLink").errors
                      | getErrorMessage: translations?.WebSite
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                appearance="standard"
                class="col-4"
                formGroupName="channelType"
              >
                <mat-label for="idChannelType">{{
                  "CRM_DETAIL.Channel" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="idChannelType"
                  name="idChannelType"
                  (selectionChange)="activateSpecificationsSelect($event)"
                >
                  <mat-option
                    *ngFor="let channel of channels"
                    [value]="channel.idChannelType"
                  >
                    {{ channel.description }}
                  </mat-option>
                </mat-select>
                <button
                  type="button"
                  class="only-icon-button primary"
                  matSuffix
                  [disabled]="isReadonly || contactOperationInProgress"
                  (click)="showChannelsManagementDialog($event, 'channel')"
                >
                  <svg-icon name="list_icon_2" class="w20"></svg-icon>
                </button>
                <mat-error>
                  {{
                    contactForm.controls.channelType.get("idChannelType").errors
                      | getErrorMessage: translations?.Channel
                  }}
                </mat-error>
              </mat-form-field>
              <!-- LA DESCRIZIONE DEL CANALE NON SERVE PIU' -->
              <!-- <mat-form-field appearance="standard" class="col-3">
                <mat-label for="channelTypeDesc">
                  {{ "CRM_DETAIL.ChannelDescription" | translate }}
                </mat-label>
                <input
                  matInput
                  formControlName="channelTypeDesc"
                  type="text"
                  name="channelTypeDesc"
                />
                <mat-error>{{
                  contactForm.get("channelTypeDesc").errors
                    | getErrorMessage: translations?.ChannelDescription
                }}</mat-error>
              </mat-form-field> -->
              <mat-form-field
                appearance="standard"
                class="col-4"
                formGroupName="specificationChannelTypeDTO"
              >
                <mat-label for="idSpecificationChannelType">{{
                  "IR_FORM.ChannelSpec" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="idSpecificationChannelType"
                  name="idSpecificationChannelType"
                >
                  <mat-option
                    *ngFor="
                      let channelSpec of specificationsChannels
                        | filterSelectByCondition
                          : contactForm.get('channelType.idChannelType').value
                          : 'idChannelType'
                    "
                    [value]="channelSpec.idSpecificationChannelType"
                  >
                    {{ channelSpec.description }}
                  </mat-option>
                </mat-select>
                <div class="d-flex" matSuffix>
                  <button
                    type="button"
                    class="only-icon-button primary"
                    [disabled]="
                      isReadonly ||
                      !contactForm.get('channelType.idChannelType').value ||
                      contactOperationInProgress
                    "
                    (click)="
                      showChannelsManagementDialog(
                        $event,
                        'channelSpec',
                        contactForm.get('channelType.idChannelType').value
                      )
                    "
                  >
                    <svg-icon name="list_icon_2" class="w20"></svg-icon>
                  </button>
                  <button
                    type="button"
                    [matMenuTriggerFor]="channelSpecDescription"
                    class="only-icon-button primary ml-10"
                    (click)="$event.stopPropagation()"
                  >
                    <mat-icon>info</mat-icon>
                  </button>
                </div>
                <mat-menu #channelSpecDescription="matMenu">
                  <div style="padding: 0 10px">
                    {{ "IR_FORM.ChannelSpecDescription" | translate }}
                  </div>
                </mat-menu>
                <mat-error>
                  {{
                    contactForm.get(
                      "specificationChannelTypeDTO.idSpecificationChannelType"
                    ).errors | getErrorMessage: translations?.Specific
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-4">
                <mat-label for="revenue">{{
                  "CRM_DETAIL.ContactRevenueYear" | translate
                }}</mat-label>
                <input
                  matInput
                  type="text"
                  name="revenue"
                  formControlName="revenue"
                  currencyMask
                  autocomplete="off"
                  [options]="currencyDefaultConfig"
                />
                <mat-error>
                  {{
                    contactForm.get("revenue").errors
                      | getErrorMessage: translations?.Revenue
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                appearance="standard"
                class="col-4"
                formGroupName="buyerStatus"
              >
                <mat-label for="idBuyerStatus">{{
                  "CRM_DETAIL.Status2" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="idBuyerStatus"
                  name="idBuyerStatus"
                  (selectionChange)="
                    enableDisableValidatorOnBuyerStatusReason($event)
                  "
                >
                  <mat-option
                    *ngFor="let status of buyerStatus"
                    [value]="status.idBuyerStatus"
                  >
                    {{ "CRM_DETAIL." + status.description | translate }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{
                    contactForm.controls.buyerStatus.get("idBuyerStatus").errors
                      | getErrorMessage: translations?.Status2
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                *ngIf="
                  contactForm.get('buyerStatus.idBuyerStatus').value >= 4 &&
                  contactForm.get('buyerStatus.idBuyerStatus').value <= 7
                "
                appearance="standard"
                class="col-4"
                formGroupName="buyerStatusReason"
              >
                <mat-label for="idBuyerStatusReason">{{
                  "CRM_DETAIL.StatusReason" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="idBuyerStatusReason"
                  name="idBuyerStatusReason"
                >
                  <mat-option
                    *ngFor="let statusReason of buyerStatusReason"
                    [value]="statusReason.idBuyerStatusReason"
                  >
                    {{ "BUYER_STATUS_REASON." + statusReason.name | translate }}
                  </mat-option>
                </mat-select>
                <mat-error>
                  {{
                    contactForm.controls.buyerStatusReason.get(
                      "idBuyerStatusReason"
                    ).errors | getErrorMessage: translations?.StatusReason
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field
                *ngIf="
                  contactForm.get('buyerStatus.idBuyerStatus').value >= 4 &&
                  contactForm.get('buyerStatus.idBuyerStatus').value <= 7
                "
                appearance="standard"
                class="col-8"
              >
                <mat-label for="statusDetails">{{
                  "CRM_DETAIL.ReasonDetail" | translate
                }}</mat-label>
                <input
                  matInput
                  type="text"
                  name="statusDetails"
                  formControlName="statusDetails"
                />
                <mat-error>
                  {{
                    contactForm.get("statusDetails").errors
                      | getErrorMessage: translations?.ReasonDetail
                  }}
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="standard" class="col-4">
                <mat-label for="contactOrigin">{{
                  "CRM_DETAIL.ContactOrigin" | translate
                }}</mat-label>
                <mat-select
                  disableOptionCentering
                  panelClass="custom-panel-class"
                  formControlName="contactOrigin"
                  name="contactOrigin"
                >
                  <mat-optgroup>
                    {{ "LEAD_GEN_CONTACT_ORIGIN.OfflineActions" | translate }}
                    <mat-option
                      *ngFor="let origin of leadGenContactOrigin?.offLineAction"
                      [value]="origin.description"
                    >
                      {{
                        "LEAD_GEN_CONTACT_ORIGIN." + origin.description
                          | translate
                      }}
                    </mat-option>
                  </mat-optgroup>
                  <mat-optgroup>
                    {{ "LEAD_GEN_CONTACT_ORIGIN.Tools" | translate }}
                    <mat-option
                      *ngFor="let origin of leadGenContactOrigin?.tool"
                      [value]="origin.description"
                    >
                      {{
                        "LEAD_GEN_CONTACT_ORIGIN." + origin.description
                          | translate
                      }}
                    </mat-option>
                  </mat-optgroup>
                  <mat-optgroup>
                    {{ "LEAD_GEN_CONTACT_ORIGIN.OnlineActions" | translate }}
                    <mat-option
                      *ngFor="let origin of leadGenContactOrigin?.onLineAction"
                      [value]="origin.description"
                    >
                      {{
                        "LEAD_GEN_CONTACT_ORIGIN." + origin.description
                          | translate
                      }}
                    </mat-option>
                  </mat-optgroup>
                </mat-select>
                <mat-error>
                  {{
                    contactForm.get("contactOrigin").errors
                      | getErrorMessage: translations?.ContactOrigin
                  }}
                </mat-error>
              </mat-form-field>
              <!-- Contact origin input field -->
              <!-- <mat-form-field *ngIf="!isContactOriginFieldSelect" appearance="standard" class="col-4">
                <mat-label for="contactOrigin">{{ "CRM_DETAIL.ContactOrigin" | translate }}</mat-label>
                <input matInput #contactOriginInput type="text" formControlName="contactOrigin" name="contactOrigin" />
                <button
                  type="button"
                  class="only-icon-button primary"
                  matSuffix
                  (click)="changeToSelectField()"
                >
                  <mat-icon>arrow_drop_down</mat-icon>
                </button>
                <mat-error>
                  {{
                    contactForm.get("contactOrigin").errors
                      | getErrorMessage: translations?.ContactOrigin
                  }}
                </mat-error>
              </mat-form-field> -->
              <mat-form-field appearance="standard" class="col-8">
                <mat-label for="note">{{
                  "CRM_DETAIL.Note" | translate
                }}</mat-label>
                <textarea
                  rows="1"
                  matInput
                  type="text"
                  name="note"
                  formControlName="note"
                ></textarea>
                <mat-error>
                  {{
                    contactForm.get("note").errors
                      | getErrorMessage: translations?.Note
                  }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div formArrayName="contactReference" class="mt-30">
          <div
            *ngFor="let buyer of contactBuyers.controls; let buyerIndex = index"
            [formGroupName]="buyerIndex"
          >
            <div
              *ngIf="!buyer.get('deactivated').value"
              class="section-card mb-30"
            >
              <div class="header multi">
                <div class="title">
                  <span>
                    {{
                      buyer.get("main").value
                        ? ("CRM_DETAIL.MainBuyer" | translate)
                        : ("CRM_DETAIL.Buyer" | translate)
                    }}
                  </span>
                </div>
                <button
                  type="button"
                  class="only-icon-button error"
                  (click)="removeContactBuyer(buyer, buyerIndex)"
                  [disabled]="contactOperationInProgress || isReadonly"
                >
                  <svg-icon name="trash_icon"></svg-icon>
                </button>
              </div>
              <div class="content">
                <div class="row">
                  <div class="col-3 d-flex align-items-center">
                    <mat-slide-toggle
                      class="set-as-main"
                      color="primary"
                      [disabled]="
                        (mainReferentIndex !== null &&
                          mainReferentIndex !== buyerIndex) ||
                        contactOperationInProgress ||
                        isReadonly
                      "
                      [checked]="buyer.get('main').value"
                      (change)="checkMainBuyer($event, buyerIndex)"
                    >
                      <span class="text-color">{{
                        "CRM_DETAIL.SetAsMain" | translate
                      }}</span>
                    </mat-slide-toggle>
                  </div>
                  <mat-form-field appearance="standard" class="col-3">
                    <mat-label for="title">
                      {{ "IR_FORM.Title" | translate }}
                    </mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="title"
                      name="title"
                    >
                      <mat-option
                        *ngFor="let title of titleType"
                        [value]="title"
                      >
                        {{ "TITLE_TYPE." + title | translate }}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      {{
                        buyer.get("title").errors
                          | getErrorMessage: translations.Title2
                      }}
                    </mat-error>
                    <!-- <input
                    matInput
                    formControlName="title"
                    type="text"
                    name="title"
                    onKeyPress="if(this.value.length==6) return false;"
                  /> -->
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-3">
                    <mat-label for="name">{{
                      "COMMON.Name" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="name"
                      type="text"
                      name="name"
                    />
                    <mat-error>
                      {{
                        buyer.get("name").errors
                          | getErrorMessage: translations.Name
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-3">
                    <mat-label for="surname">{{
                      "COMMON.Surname" | translate
                    }}</mat-label>
                    <input
                      matInput
                      formControlName="surname"
                      type="text"
                      name="surname"
                    />
                    <mat-error>
                      {{
                        buyer.get("surname").errors
                          | getErrorMessage: translations.Surname
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field
                    appearance="standard"
                    class="col-3"
                    formGroupName="role"
                  >
                    <mat-label for="idCompanyRoleType">
                      {{ "IR_FORM.CompanyEmployment" | translate }}
                    </mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idCompanyRoleType"
                    >
                      <mat-option
                        *ngFor="let role of referentRoles"
                        [value]="role.idCompanyRoleType"
                      >
                        {{ role.description }}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      {{
                        buyer.get("role.idCompanyRoleType").errors
                          | getErrorMessage: translations.CompanyEmployment
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-3">
                    <mat-label for="email">
                      {{ "COMMON.Email" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="email"
                      type="email"
                      name="email"
                    />
                    <mat-error>
                      {{
                        buyer.get("email").errors
                          | getErrorMessage: translations.Email
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-12 col-md-3">
                    <mat-label for="personalTel">
                      {{ "COMMON.PersonalTelephone" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="personalTel"
                      type="tel"
                      name="personalTel"
                    />
                    <mat-error>
                      {{
                        buyer.get("personalTel").errors
                          | getErrorMessage: translations.PersonalTelephone
                      }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="standard" class="col-12 col-md-3">
                    <mat-label for="officeTel">
                      {{ "COMMON.OfficeTelephone" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="officeTel"
                      type="tel"
                      name="officeTel"
                    />
                    <mat-error>
                      {{
                        buyer.get("officeTel").errors
                          | getErrorMessage: translations.OfficeTelephone
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="center-buttons">
          <button
            type="button"
            class="rounded-button primary-border-button mr-20"
            (click)="addContactBuyer()"
            [disabled]="contactOperationInProgress || isReadonly"
          >
            {{ "CRM_DETAIL.AddBuyer" | translate }}
          </button>
          <button
            type="submit"
            class="rounded-button primary-bg-button"
            [disabled]="contactOperationInProgress || isReadonly"
          >
            <span *ngIf="editMode">{{ "COMMON.SaveChanges" | translate }}</span>
            <span *ngIf="!editMode">{{ "CRM.AddContact" | translate }}</span>
          </button>
        </div>
      </form>
    </mat-tab>
    <mat-tab [disabled]="!editMode">
      <ng-template matTabLabel>
        <span>{{ "CRM_DETAIL.Activities" | translate }}</span>
      </ng-template>
      <div class="row">
        <div class="col-9">
          <div class="section-card h100">
            <div class="header h70">
              <div class="title">
                <span>{{ "CRM_DETAIL.ActivitiesList" | translate }}</span>
                <span class="fw-700 ml-10">{{ contact?.businessName }}</span>
              </div>
            </div>
            <div class="content">
              <div class="activities-container">
                <div
                  *ngFor="let activity of activities"
                  class="activity-element"
                >
                  <div>
                    <div>
                      <span>{{ "CRM_DETAIL.DateInsert" | translate }}:</span>
                      <span class="fw-700 ml-05">
                        {{ activity.dateInsert | date: "dd/MM/yyyy - HH:mm" }}
                      </span>
                    </div>
                    <div>
                      <span>{{ "COMMON.Description" | translate }}:</span>
                      <span class="fw-700 ml-05">{{
                        activity.feedbackDescription
                      }}</span>
                    </div>
                    <div *ngIf="activity?.dateActivity" class="d-flex">
                      <span>{{ "CRM_DETAIL.ContactDate" | translate }}:</span>
                      <div class="fw-700 ml-05">
                        <span>{{
                          activity.dateActivity | date: "dd/MM/yyyy"
                        }}</span>
                        <span *ngIf="activity?.timeActivity">
                          {{ activity.dateActivity | date: "- HH:mm" }}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="icons">
                    <button
                      type="button"
                      class="only-icon-button primary"
                      (click)="openUpdateContactActivityDialog(activity)"
                    >
                      <svg-icon name="edit_icon" class="w20"></svg-icon>
                    </button>
                    <button
                      type="button"
                      class="only-icon-button error"
                      (click)="removeActivity(activity)"
                    >
                      <svg-icon name="trash_icon" class="w20"></svg-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="section-card h100">
            <div class="header h70">
              <div class="title">
                <span>{{ "CRM_DETAIL.AddActivity" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <form
                [formGroup]="activityForm"
                #fgDirective="ngForm"
                (ngSubmit)="saveActivity()"
              >
                <div class="row">
                  <mat-form-field class="col-12" appearance="standard">
                    <mat-label for="feedbackDescription">
                      {{ "CRM_DETAIL.FeedbackDescription" | translate }}
                    </mat-label>
                    <input
                      matInput
                      type="text"
                      name="feedbackDescription"
                      formControlName="feedbackDescription"
                    />
                    <mat-error>
                      {{
                        activityForm.get("feedbackDescription").errors
                          | getErrorMessage: translations?.FeedbackDescription
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-12" appearance="standard">
                    <mat-label for="dateActivity">{{
                      "CRM_DETAIL.DateActivity" | translate
                    }}</mat-label>
                    <input
                      matInput
                      [matDatepicker]="dateActivity"
                      formControlName="dateActivity"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="dateActivity"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #dateActivity></mat-datepicker>
                    <mat-error *ngIf="activityForm.get('dateActivity').invalid">
                      {{
                        activityForm.get("dateActivity").errors
                          | getErrorMessage: translations?.DateActivity
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-12" appearance="standard">
                    <mat-label for="timeActivity">{{
                      "CRM_DETAIL.TimeActivity" | translate
                    }}</mat-label>
                    <input
                      matInput
                      type="time"
                      formControlName="timeActivity"
                    />
                    <mat-error *ngIf="activityForm.get('timeActivity').invalid">
                      {{
                        activityForm.get("timeActivity").errors
                          | getErrorMessage: translations?.TimeActivity
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field class="col-12" appearance="standard">
                    <mat-label for="idContactBuyerStatusChange">
                      {{ "CRM_DETAIL.Status2" | translate }}
                    </mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idContactBuyerStatusChange"
                      name="idContactBuyerStatusChange"
                      (selectionChange)="
                        checkActivityFieldsValidatorsRelatedToStatus(
                          $event,
                          'new'
                        )
                      "
                    >
                      <mat-option [value]="null">--</mat-option>
                      <mat-option
                        *ngFor="let status of buyerStatus"
                        [value]="status.idBuyerStatus"
                      >
                        {{ "CRM_DETAIL." + status.description | translate }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div>
                  <mat-slide-toggle
                    #mandatoryActivityToggle
                    color="primary"
                    [disabled]="isReadonly || activityOperationInProgress"
                    (change)="checkMandatoryActivity($event)"
                  >
                    <span class="text-color">{{
                      "CRM_DETAIL.Mandatory" | translate
                    }}</span>
                  </mat-slide-toggle>
                </div>
                <div class="center-buttons mt-100">
                  <button
                    type="submit"
                    class="rounded-button primary-bg-button"
                    [disabled]="activityOperationInProgress"
                  >
                    {{ "CRM_DETAIL.AddActivity" | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab [disabled]="!editMode">
      <ng-template matTabLabel>
        <span>{{ "CRM_DETAIL.Competitors" | translate }}</span>
      </ng-template>
      <div class="row">
        <div class="col-9">
          <div class="section-card h100">
            <div class="content">
              <app-table
                [loader]="competitorsLoader"
                [columns]="competitorsTableColumns"
                [columnsButtons]="competitorsTableButtons"
                [data]="competitors"
              ></app-table>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="section-card h100 pt-20">
            <div class="header h70">
              <div class="title">
                <span>{{ "CRM_DETAIL.AddCompetitor" | translate }}</span>
              </div>
            </div>
            <div class="content">
              <form
                [formGroup]="competitorForm"
                #competitorFormDirective="ngForm"
                (ngSubmit)="saveCompetitor()"
              >
                <div class="row">
                  <mat-form-field appearance="standard" class="col-12">
                    <mat-label for="companyName">
                      {{ "CRM_DETAIL.CompetitorBusinessName" | translate }}
                    </mat-label>
                    <input
                      matInput
                      formControlName="companyName"
                      type="text"
                      name="companyName"
                      [matAutocomplete]="competitorsAuto"
                    />
                    <mat-autocomplete
                      #competitorsAuto="matAutocomplete"
                      autoActiveFirstOption
                    >
                      <mat-option
                        *ngFor="let option of filteredOptions"
                        [value]="option.companyName"
                      >
                        {{ option.companyName }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error>
                      {{
                        competitorForm.get("companyName").errors
                          | getErrorMessage: translations?.BusinessName
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="row">
                  <mat-form-field appearance="standard" class="col-12">
                    <mat-label for="buyerRevenueYear">{{
                      "CRM_DETAIL.AnnualPurchaseValue" | translate
                    }}</mat-label>
                    <input
                      matInput
                      name="buyerRevenueYear"
                      formControlName="buyerRevenueYear"
                      currencyMask
                      autocomplete="off"
                      [options]="currencyDefaultConfig"
                    />
                    <mat-error>
                      {{
                        competitorForm.get("buyerRevenueYear").errors
                          | getErrorMessage: translations?.AnnualPurchaseValue
                      }}
                    </mat-error>
                  </mat-form-field>
                </div>
                <!-- <div class="row">
                  <mat-form-field appearance="standard" class="col-12">
                    <mat-label for="valueRevenueYear">{{
                      "CRM_DETAIL.MarketShare" | translate
                    }}</mat-label>
                    <input
                      matInput
                      name="valueRevenueYear"
                      formControlName="valueRevenueYear"
                      currencyMask
                      autocomplete="off"
                      [options]="currencyDefaultConfig"
                    />
                    <mat-error>
                      {{
                        competitorForm.get("valueRevenueYear").errors
                          | getErrorMessage: translations?.MarketShare
                      }}
                    </mat-error>
                  </mat-form-field>
                </div> -->
                <!-- <div class="row">
                  <mat-form-field
                    appearance="standard"
                    class="col-12"
                    formGroupName="country"
                  >
                    <mat-label for="idCountry">{{
                      "CRM_DETAIL.Country" | translate
                    }}</mat-label>
                    <mat-select
                      disableOptionCentering
                      panelClass="custom-panel-class"
                      formControlName="idCountry"
                      name="idCountry"
                    >
                      <mat-option>
                        <ngx-mat-select-search
                          [formControl]="entityFilterCtrl"
                          [placeholderLabel]="'Cerca...'"
                          [noEntriesFoundLabel]="'Nessun risultato'"
                        >
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option
                        *ngFor="let country of filteredCountries"
                        [value]="country.idCountry"
                      >
                        {{ country.country }}
                      </mat-option>
                    </mat-select>
                    <mat-error>
                      {{
                        competitorForm.get("country.idCountry").errors
                          | getErrorMessage: translations?.Country
                      }}
                    </mat-error>
                  </mat-form-field>
                </div> -->
                <div class="center-buttons mt-100">
                  <button
                    type="submit"
                    class="rounded-button primary-bg-button"
                    [disabled]="competitorOperationInProgress"
                  >
                    {{ "CRM_DETAIL.AddCompetitor" | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab [disabled]="!editMode">
      <ng-template matTabLabel>
        <span>{{ "CRM_DETAIL.OffersOrders" | translate }}</span>
      </ng-template>
      <app-contact-deals
        *ngIf="translations"
        [translations]="translations"
        [commerceSectors]="commerceSectors"
        [dealStatus]="dealStatus"
        [contact]="contact"
        [unitsOfMeasure]="unitsOfMeasure"
        (refreshContactDetails)="getContactDetails()"
        (refreshUnitsOfMeasure)="getUnitsOfMeasure()"
      >
      </app-contact-deals>
    </mat-tab>
    <mat-tab [disabled]="!editMode">
      <ng-template matTabLabel>
        <span>{{ "CRM_DETAIL.Reports" | translate }}</span>
      </ng-template>
      <div class="row">
        <div class="col-9">
          <div class="section-card h100">
            <div class="content reports-table">
              <app-table
                [loader]="reportsTableLoader"
                [columns]="reportsTableColumns"
                [columnsButtons]="reportsTableButtons"
                [data]="reports"
              ></app-table>
            </div>
          </div>
        </div>
        <div class="col-3">
          <div class="section-card h100">
            <div *ngIf="!isReadonly" class="add-content">
              <button
                type="button"
                class="rounded-button primary-bg-button"
                (click)="openContactReportDialog()"
              >
                <span class="white-color">
                  {{ "CRM.AddContactReport" | translate }}
                </span>
              </button>
            </div>
            <div class="header h70 multi">
              <div class="title">
                <span>{{ "COMMON.Filters" | translate }}</span>
              </div>
              <div class="text-button">
                <span
                  class="primary-color"
                  (click)="resetReportsTableFilters()"
                >
                  {{ "COMMON.Reset" | translate | lowercase }}
                </span>
              </div>
            </div>
            <div class="content">
              <div class="row">
                <mat-form-field appearance="standard" class="col-12">
                  <mat-label>{{
                    "CRM_DETAIL.CommerceSector" | translate
                  }}</mat-label>
                  <mat-select
                    #reportCommerceSectorFilter
                    (selectionChange)="
                      filterReportsTable('commerceSector', $event)
                    "
                  >
                    <mat-option
                      *ngFor="let sector of commerceSectors"
                      [value]="sector.name"
                    >
                      {{ sector.name }}
                      <!-- {{ "COMMERCE_SECTORS." + sector.name | translate }} -->
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field appearance="standard" class="col-12">
                  <mat-label>{{
                    "CRM_DETAIL.ReportDate" | translate
                  }}</mat-label>
                  <mat-date-range-input [rangePicker]="reportsPicker">
                    <input
                      matStartDate
                      placeholder="{{ 'COMMON.StartDate' | translate }}"
                      [formControl]="startDateReportFilter"
                      readonly
                    />
                    <input
                      matEndDate
                      placeholder="{{ 'COMMON.EndDate' | translate }}"
                      readonly
                      [formControl]="endDateReportFilter"
                      (dateChange)="handleRangeDateReportTableFilter($event)"
                    />
                  </mat-date-range-input>
                  <mat-datepicker-toggle
                    matSuffix
                    [for]="reportsPicker"
                  ></mat-datepicker-toggle>
                  <mat-date-range-picker #reportsPicker></mat-date-range-picker>
                </mat-form-field>
              </div>

              <!-- <div class="row">
                <mat-form-field appearance="standard" class="col">
                  <mat-label>{{ "IR_FORM.Type" | translate }}</mat-label>
                  <mat-select
                    #typeFilter
                    (selectionChange)="filterTable('type', $event)"
                  >
                    <mat-option value="Acquistato">
                      {{ "IR_FORM.Bought" | translate }}
                    </mat-option>
                    <mat-option value="Venduto">
                      {{ "IR_FORM.Sold" | translate }}
                    </mat-option>
                    <mat-option value="Corr / Comp">
                      {{ "IR_FORM.RelatedComplementar" | translate }}
                    </mat-option>
                    <mat-option value="Concorrenza indiretta">
                      {{ "IR_FORM.IndirectCompetition" | translate }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="row">
                <mat-form-field appearance="standard" class="col">
                  <mat-label>{{ "IR_FORM.ImportanceLevel" | translate }}</mat-label>
                  <mat-select
                    #importanceLevelFilter
                    (selectionChange)="filterTable('importanceLevel', $event)"
                  >
                    <mat-option value="1">1</mat-option>
                    <mat-option value="2">2</mat-option>
                    <mat-option value="3">3</mat-option>
                    <mat-option value="4">4</mat-option>
                    <mat-option value="5">5</mat-option>
                    <mat-option value="6">6</mat-option>
                    <mat-option value="7">7</mat-option>
                    <mat-option value="8">8</mat-option>
                    <mat-option value="9">9</mat-option>
                    <mat-option value="10">10</mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<ng-template #activityDialog let-close="close" let-data>
  <div class="header">
    <div class="title">
      <span>{{ "CRM_DETAIL.Activities" | translate }}</span>
    </div>
    <button
      class="close-dialog"
      mat-dialog-close
      mat-icon-button
      (click)="clearAndCloseContactCompetitorDialog()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="content">
    <form
      [formGroup]="editActivityForm"
      #fgDirective="ngForm"
      (ngSubmit)="saveActivity(data?.activity)"
    >
      <div class="row">
        <mat-form-field class="col-12" appearance="standard">
          <mat-label for="feedbackDescription">
            {{ "CRM_DETAIL.FeedbackDescription" | translate }}
          </mat-label>
          <input
            matInput
            type="text"
            name="feedbackDescription"
            formControlName="feedbackDescription"
          />
          <mat-error>
            {{
              editActivityForm.get("feedbackDescription").errors
                | getErrorMessage: translations?.FeedbackDescription
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field
          class="col-8"
          appearance="standard"
          [ngStyle]="{
            'margin-bottom': editActivityForm.get('timeActivity').invalid
              ? '20px'
              : '0'
          }"
        >
          <mat-label for="dateActivity">{{
            "CRM_DETAIL.DateActivity" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="editDateActivity"
            formControlName="dateActivity"
            (dateChange)="checkIfDateExist()"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="editDateActivity"
          ></mat-datepicker-toggle>
          <mat-datepicker #editDateActivity></mat-datepicker>
          <mat-error *ngIf="editActivityForm.get('dateActivity').invalid">
            {{
              editActivityForm.get("dateActivity").errors
                | getErrorMessage: translations?.DateActivity
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-4" appearance="standard">
          <mat-label for="timeActivity">{{
            "CRM_DETAIL.TimeActivity" | translate
          }}</mat-label>
          <input matInput type="time" formControlName="timeActivity" />
          <mat-error *ngIf="editActivityForm.get('timeActivity').invalid">
            {{
              editActivityForm.get("timeActivity").errors
                | getErrorMessage: translations?.TimeActivity
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12" appearance="standard">
          <mat-label for="idContactBuyerStatusChange">
            {{ "CRM_DETAIL.Status2" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idContactBuyerStatusChange"
            name="idContactBuyerStatusChange"
            (selectionChange)="
              checkActivityFieldsValidatorsRelatedToStatus($event, 'edit')
            "
          >
            <mat-option [value]="null">--</mat-option>
            <mat-option
              *ngFor="let status of buyerStatus"
              [value]="status.idBuyerStatus"
            >
              {{ "CRM_DETAIL." + status.description | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-slide-toggle
          name="mandatoryActivityEditToggle"
          color="primary"
          [disabled]="isReadonly || activityOperationInProgress"
          [checked]="data?.activity?.mandatory"
          (change)="checkMandatoryActivity($event)"
        >
          <span class="text-color">{{
            "CRM_DETAIL.Mandatory" | translate
          }}</span>
        </mat-slide-toggle>
      </div>
      <div class="center-buttons mt-30">
        <button
          type="submit"
          class="rounded-button primary-bg-button"
          [disabled]="activityOperationInProgress"
        >
          {{ "COMMON.SaveChanges" | translate }}
        </button>
      </div>
    </form>
  </div>
</ng-template>
<ng-template #competitorDialog let-close="close" let-data>
  <div class="header">
    <div class="title">
      <span>{{ editCompetitorForm.get("companyName").value }}</span>
    </div>
    <button
      class="close-dialog"
      mat-dialog-close
      mat-icon-button
      [disabled]="competitorOperationInProgress"
      (click)="clearAndCloseContactCompetitorDialog()"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <form
    [formGroup]="editCompetitorForm"
    (ngSubmit)="saveCompetitor(data.competitor)"
  >
    <div class="content">
      <div class="row">
        <mat-form-field appearance="standard" class="col-4">
          <mat-label for="companyName">
            {{ "CRM_DETAIL.CompetitorBusinessName" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="companyName"
            type="text"
            name="companyName"
            autocomplete="off"
          />
          <mat-error>
            {{
              editCompetitorForm.get("companyName").errors
                | getErrorMessage: translations.BusinessName
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-4">
          <mat-label for="buyerRevenueYear">
            {{ "CRM_DETAIL.AnnualPurchaseValue" | translate }}
          </mat-label>
          <input
            matInput
            name="buyerRevenueYear"
            formControlName="buyerRevenueYear"
            currencyMask
            autocomplete="off"
            [options]="currencyDefaultConfig"
          />
          <mat-error>
            {{
              editCompetitorForm.get("buyerRevenueYear").errors
                | getErrorMessage: translations.AnnualPurchaseValue
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-4">
          <mat-label for="valueRevenueYear">
            {{ "CRM_DETAIL.MarketShare" | translate }}
          </mat-label>
          <input
            matInput
            name="valueRevenueYear"
            formControlName="valueRevenueYear"
            currencyMask
            autocomplete="off"
            [options]="currencyDefaultConfig"
          />
          <mat-error>
            {{
              editCompetitorForm.get("valueRevenueYear").errors
                | getErrorMessage: translations.MarketShare
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="buttons center">
      <button
        type="submit"
        class="rounded-button primary-bg-button"
        [disabled]="competitorOperationInProgress"
      >
        {{ "COMMON.SaveChanges" | translate }}
      </button>
    </div>
  </form>
</ng-template>
<ng-template #contactDealDialog let-close="close" let-data>
  <form [formGroup]="dealForm" (ngSubmit)="saveDeal()">
    <div class="header">
      <div class="title">
        <span>{{ "CRM_DETAIL.Deal" | translate }}</span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        (click)="resetDealForm()"
        [disabled]="dealsOperationInProgress"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field
          appearance="standard"
          class="col-6"
          formGroupName="commerceSector"
        >
          <mat-label for="idCommerceSector">
            {{ "IR_FORM.CommerceSector" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idCommerceSector"
            name="idCommerceSector"
          >
            <mat-option
              *ngFor="let sector of commerceSectors"
              [value]="sector.idCommerceSector"
            >
              {{ sector.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              !dealForm.controls.commerceSector.get('idCommerceSector').valid
            "
          >
            {{
              dealForm.controls.commerceSector.get("idCommerceSector").errors
                | getErrorMessage: translations.CommerceSector
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field
          appearance="standard"
          class="col-6"
          formGroupName="dealState"
        >
          <mat-label for="idDealState">{{
            "CRM_DETAIL.DealState" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idDealState"
            name="idDealState"
            (selectionChange)="checkSelectedDealState($event)"
            [disabled]="disabledForm"
          >
            <mat-option
              *ngFor="let dealState of dealStates"
              [value]="dealState.idDealState"
            >
              {{
                "DEAL_STATE_NAME." + dealStateNameEnum[dealState.name]
                  | translate
              }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="dealForm.get('dealState.idDealState').invalid">
            {{
              dealForm.get("dealState.idDealState").errors
                | getErrorMessage: translations.DealState
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col-6">
          <mat-label for="quantity">{{
            "CRM_DETAIL.Quantity" | translate
          }}</mat-label>
          <input
            currencyMask
            [options]="currencyQuantityConfig"
            matInput
            autocomplete="off"
            name="quantity"
            formControlName="quantity"
          />
          <mat-error *ngIf="dealForm.get('quantity').invalid">
            {{
              dealForm.get("quantity").errors
                | getErrorMessage: translations.Quantity
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-6">
          <mat-label for="revenue">{{
            "CRM_DETAIL.DealValue" | translate
          }}</mat-label>
          <input
            currencyMask
            [options]="currencyDefaultConfig"
            matInput
            autocomplete="off"
            name="revenue"
            formControlName="revenue"
          />
          <mat-error *ngIf="dealForm.get('revenue').invalid">
            {{
              dealForm.get("revenue").errors
                | getErrorMessage: translations.DealValue
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field
          *ngIf="showDealStateReason"
          appearance="standard"
          class="col-6"
          formGroupName="dealStateReason"
        >
          <mat-label for="idDealStateReason">{{
            "CRM_DETAIL.DealStateReason" | translate
          }}</mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idDealStateReason"
            name="idDealStateReason"
            [disabled]="disabledForm"
          >
            <mat-option
              *ngFor="let dealStateReason of dealStateReasons"
              [value]="dealStateReason.idDealStateReason"
            >
              {{
                "CRM_DETAIL." + dealStateReasonEnum[dealStateReason.name]
                  | translate
              }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="dealForm.get('dealStateReason.idDealStateReason').invalid"
          >
            {{
              dealForm.get("dealStateReason.idDealStateReason").errors
                | getErrorMessage: translations.DealStateReason
            }}
          </mat-error>
        </mat-form-field>
        <div *ngIf="showOpportunityFlag" class="col-6 mt-30 pl-100">
          <mat-checkbox
            color="primary"
            formControlName="isOpportunity"
            (change)="checkOpportunityFlag($event.checked)"
          >
            {{ "CRM_DETAIL.Opportunity" | translate }}
          </mat-checkbox>
        </div>
      </div>
      <div *ngIf="showOpportunityFields" class="row">
        <mat-form-field appearance="standard" class="col-6">
          <mat-label for="productName">{{
            "CRM_DETAIL.ProductName" | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="productName"
            name="productName"
          />
          <mat-error *ngIf="dealForm.get('productName').invalid">
            {{
              dealForm.get("productName").errors
                | getErrorMessage: translations.ProductName
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showOpportunityFields" class="row">
        <mat-form-field appearance="standard" class="col-12">
          <mat-label for="italianDescription">{{
            "CRM_DETAIL.ItalianDescription" | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="italianDescription"
            name="italianDescription"
          />
          <mat-error *ngIf="dealForm.get('italianDescription').invalid">
            {{
              dealForm.get("italianDescription").errors
                | getErrorMessage: translations.ItalianDescription
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="showOpportunityFields" class="row">
        <mat-form-field appearance="standard" class="col-12">
          <mat-label for="englishDescription">{{
            "CRM_DETAIL.EnglishDescription" | translate
          }}</mat-label>
          <input
            matInput
            type="text"
            formControlName="englishDescription"
            name="englishDescription"
          />
          <mat-error *ngIf="dealForm.get('englishDescription').invalid">
            {{
              dealForm.get("englishDescription").errors
                | getErrorMessage: translations.EnglishDescription
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field appearance="standard" class="col-3">
          <mat-label for="dateDeal">{{
            "CRM_DETAIL.DealDate" | translate
          }}</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dateDeal" />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error *ngIf="dealForm.get('dateDeal').invalid">
            {{
              dealForm.get("dateDeal").errors
                | getErrorMessage: translations.DealDate
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="standard" class="col-9">
          <mat-label for="note">{{ "CRM_DETAIL.Note" | translate }}</mat-label>
          <textarea
            matInput
            type="text"
            name="note"
            formControlName="note"
            rows="1"
          ></textarea>
        </mat-form-field>
      </div>
      <div class="ta-center mt-20 mb-60">
        <app-upload-file
          [fileToHandle]="uploadedDocument"
          [fileType]="'CRM_DETAIL.Deal' | translate"
          [downloadFileFromName]="true"
          [showDocumentName]="true"
          [uploadingFile]="uploadingDealDocument"
          (uploadFileEmitter)="uploadDealDocument($event)"
          (downloadFileEmitter)="viewDeal()"
          [disableButton]="isReadonly || savingDealLoader"
          [parentUploadError]="uploadError"
        ></app-upload-file>
        <!-- <mat-error *ngIf="firstStepAddDeal && dealStateSelected !== 4">
          {{ 'CRM_DETAIL.FirstSaveDeal' | translate }}
        </mat-error> -->
        <mat-error *ngIf="fileRequired()">
          {{ "CRM_DETAIL.UploadDealDocument" | translate }}
        </mat-error>
        <mat-error *ngIf="showNotDirtyMessage">
          {{ "COMMON.NoDirty" | translate }}
        </mat-error>
      </div>
      <div class="ta-center mb-10">
        <button
          class="rounded-button primary-bg-button m-0-auto"
          type="submit"
          (click)="resetDealForm()"
          [disabled]="
            savingDealLoader ||
            uploadingDealDocument ||
            isReadonly ||
            fileRequired() ||
            dealForm?.invalid
          "
        >
          <mat-spinner
            *ngIf="savingDealLoader"
            class="d-inline-block mr-10"
            diameter="20"
          ></mat-spinner>
          <span>{{ "IR_FORM.Save" | translate }}</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #contactReportDialog let-close="close" let-data>
  <form [formGroup]="reportForm" (ngSubmit)="saveReport()">
    <div class="header">
      <div class="title">
        <span>{{ "CRM_DETAIL.Report" | translate }}</span>
      </div>
      <button
        class="close-dialog"
        mat-dialog-close
        mat-icon-button
        [disabled]="reportOperationInProgress"
        (click)="resetReportForm()"
      >
        <mat-icon>clear</mat-icon>
      </button>
    </div>
    <div class="content">
      <div class="row">
        <mat-form-field class="col-6" formGroupName="commerceSector">
          <mat-label for="idCommerceSector">
            {{ "IR_FORM.CommerceSector" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idCommerceSector"
            name="idCommerceSector"
          >
            <mat-option
              *ngFor="let sector of commerceSectors"
              [value]="sector.idCommerceSector"
            >
              {{ sector.name }}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="
              !reportForm.controls.commerceSector.get('idCommerceSector').valid
            "
          >
            {{
              reportForm.controls.commerceSector.get("idCommerceSector").errors
                | getErrorMessage: translations.CommerceSector
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6" formGroupName="contactReference">
          <mat-label for="idContactReference">
            {{ "CRM_DETAIL.Buyer" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idContactReference"
            name="idContactReference"
          >
            <mat-option
              *ngFor="let contact of checkWichContactReferencesToShow(data)"
              [value]="contact.idContactReference"
            >
              {{ contact.name }} {{ contact.surname }}
            </mat-option>
          </mat-select>
          <mat-error>
            {{
              reportForm.controls.contactReference.get("idContactReference")
                .errors | getErrorMessage: translations.Buyer
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label for="productName">
            {{ "IR_FORM.ProductName" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="productName"
            type="text"
            name="productName"
            autocomplete="off"
          />
          <mat-error *ngIf="reportForm.get('productName').invalid">
            {{
              reportForm.get("productName").errors
                | getErrorMessage: translations.Name
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12">
          <mat-label for="productDescription">
            {{ "IR_FORM.ExtendedDescription" | translate }}
          </mat-label>
          <textarea
            matInput
            formControlName="productDescription"
            type="text"
            name="productDescription"
            autocomplete="off"
          ></textarea>
          <mat-error *ngIf="reportForm.get('productDescription').invalid">
            {{
              reportForm.get("productDescription").errors
                | getErrorMessage: translations.ExtendedDescription
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-12">
          <mat-label for="productEnglishDescription">
            {{ "IR_FORM.EnglishExtendedDescription" | translate }}
          </mat-label>
          <textarea
            matInput
            formControlName="productEnglishDescription"
            type="text"
            name="productEnglishDescription"
            autocomplete="off"
          ></textarea>
          <mat-error
            *ngIf="reportForm.get('productEnglishDescription').invalid"
          >
            {{
              reportForm.get("productEnglishDescription").errors
                | getErrorMessage: translations.EnglishExtendedDescription
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-6" formGroupName="unitOfMeasure">
          <mat-label for="idUom">
            {{ "IR_FORM.UnitOfMeasure" | translate }}
          </mat-label>
          <mat-select
            disableOptionCentering
            panelClass="custom-panel-class"
            formControlName="idUom"
            name="idUom"
          >
            <mat-option
              *ngFor="let unit of unitsOfMeasure"
              [value]="unit.idUom"
            >
              {{ unit.name }}
            </mat-option>
          </mat-select>
          <button
            type="button"
            class="only-icon-button primary"
            matSuffix
            [disabled]="isReadonly"
            (click)="showUomManagementDialog($event)"
          >
            <svg-icon name="list_icon_2" class="w20"></svg-icon>
          </button>
          <mat-error
            *ngIf="reportForm.controls.unitOfMeasure.get('idUom').invalid"
          >
            {{
              reportForm.controls.unitOfMeasure.get("idUom").errors
                | getErrorMessage: translations.UnitOfMeasure
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label for="quantity">
            {{ "CRM_DETAIL.EstimatedQuantityOrder" | translate }}
          </mat-label>
          <input
            currencyMask
            matInput
            formControlName="quantity"
            [options]="currencyQuantityConfig"
            autocomplete="off"
            name="quantity"
          />
          <mat-error *ngIf="reportForm.get('quantity').invalid">
            {{
              reportForm.get("quantity").errors
                | getErrorMessage: translations.EstimatedQuantityOrder
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-6">
          <mat-label for="valueOfUnit">
            {{ "IR_FORM.ValueOfUnit" | translate }}
          </mat-label>
          <input
            matInput
            name="valueOfUnit"
            currencyMask
            formControlName="valueOfUnit"
            autocomplete="off"
            [options]="currencyDefaultConfig"
            (keyup)="updateValueYear(reportForm)"
          />
          <mat-error *ngIf="reportForm.get('valueOfUnit').invalid">
            {{
              reportForm.get("valueOfUnit").errors
                | getErrorMessage: translations.ValueOfUnit
            }}
          </mat-error>
        </mat-form-field>
        <mat-form-field class="col-6">
          <mat-label for="quantityYear">
            {{ "CRM_DETAIL.EstimatedQuantityYear" | translate }}
          </mat-label>
          <input
            currencyMask
            matInput
            formControlName="quantityYear"
            [options]="currencyQuantityConfig"
            name="quantityYear"
            autocomplete="off"
            (keyup)="updateValueYear(reportForm)"
          />
          <mat-error *ngIf="reportForm.get('quantityYear').invalid">
            {{
              reportForm.get("quantityYear").errors
                | getErrorMessage: translations.EstimatedQuantityYear
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="col-6">
          <mat-label for="valueYear">
            {{ "CRM_DETAIL.EstimatedValueYear" | translate }}
          </mat-label>
          <input
            matInput
            formControlName="valueYear"
            readonly
            name="valueYear"
            autocomplete="off"
            [options]="currencyDefaultConfig"
            currencyMask
          />
          <mat-error *ngIf="reportForm.get('valueYear').invalid">
            {{
              reportForm.get("valueYear").errors
                | getErrorMessage: translations.EstimatedValueYear
            }}
          </mat-error>
        </mat-form-field>
      </div>
      <div formArrayName="reportMatching">
        <div
          *ngFor="let matching of reportMatching.controls; let i = index"
          [formGroupName]="i"
        >
          <button type="button" mat-button>
            <mat-icon
              class="delete-note"
              (click)="removeReportMatching(i)"
              [ngClass]="disabled - color"
              >clear</mat-icon
            >
          </button>
          <mat-form-field class="note-description-field">
            <mat-label for="wordKey">{{
              "COMMON.WordKey" | translate
            }}</mat-label>
            <input
              matInput
              type="text"
              formControlName="wordKey"
              name="wordKey"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </div>
      <div class="ta-center mb-10">
        <button
          class="rounded-button primary-bg-button m-0-auto"
          type="submit"
          [disabled]="isReadonly"
        >
          <span>{{ "IR_FORM.Save" | translate }}</span>
        </button>
      </div>
    </div>
  </form>
</ng-template>
